import React, { useState, useEffect } from "react";
//import "react-simple-keyboard/build/css/index.css";
import Sidebar from "components/sidebar";
import { map } from "lodash";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import Input from "../../components/custom/Input";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";
import { useDispatch, useSelector } from "react-redux";
import SelectOption from "../../components/custom/SelectOption";
import {
  setActiveTab,
  setBusy,
  setDownlineQuery,
  setDownlineSearchText,
  setIdle,
  setPageSize,
  setY,
  showResponseMessage,
} from "../../redux/AppAction";
import { ApiKey, ApiUrl, SessionKey, WebUrl } from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";
import { withRouter } from "react-router";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  createFormBody,
  createMultiPartFormBody,
  stringIsNullOrEmpty,
} from "../../util/Util";
import ToggleButton from "react-toggle-button";
import { Controller, useForm } from "react-hook-form";
import StyledSelect from "../../components/custom/StyledSelect";
import ContentHeader from "components/content/header";
import moment from "moment";

const Downline = () => {
  const { t, i18n } = useTranslation();
  const _dispatch = useDispatch();
  const _history = useHistory();
  const _location = useLocation();
  const [categories, setCategories] = useState([]);
  const [memberData, setMemberData] = useState({});
  const [products, setProducts] = useState([]);
  const [productOption, setProductOption] = useState([]);
  const [downlineGroups, setDownlineGroups] = useState([]);
  const [downlineMemberData, setDownlineMemberData] = useState({});
  const [totalUngroupedMembers, setTotalUngroupedMembers] = useState(0);
  const [selectedGroupToDelete, setSelectedGroupToDelete] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [username, setUsername] = useState([]);
  const activeTab = useSelector((state) => state.appState.activeTab);
  let _savedData = useSelector((state) => state.appState.savedText);
  let _savedPageSize = useSelector((state) => state.appState.pageSize);
  let _savedQuery = useSelector((state) => state.appState.savedQuery);
  let _userData = useSelector((state) => state.authState.userData);
  var selectedLanguage = localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ? 
  localStorage.getItem(SessionKey._LANGUAGE) : 'en' ;
  const { register, handleSubmit, errors, setValue, watch, control, trigger } =
    useForm();
  const [downlineData, setDownlineData] = useState({});
  const tabHeadings = [
    {
      title: "DOWNLINE",
      tab_id: 0,
    },
    {
      title: "DOWNLINE_GROUPS",
      tab_id: 1,
    },
    //{
    //  title: "DOWNLINE_GAMES",
    //  tab_id: 2,
    //},
  ];

  useEffect(() => {
    init();
    _dispatch(setActiveTab(0));
  }, []);

  async function init() {
    _dispatch(setBusy());
    try {
      await getMemberDetails();
    } finally {
      _dispatch(setIdle());
    }
  }

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      _dispatch(setActiveTab(tab));
      //if (tab == 2) {
      //  getMemberProductShowList();
      //}
    }
  };

  async function getDownlineGames(product) {
    _dispatch(setBusy());
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_MEMBER_DOWNLINE_GAMES_BY_MASTERPRODUCT +
        "?masterProductId=" +
        product
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setUsername(responseJson[ApiKey._API_DATA_KEY]);
    }
    _dispatch(setIdle());
  }

  function getText(e) {
    _dispatch(setDownlineSearchText(e.target.value));
    _dispatch(
      setDownlineQuery(
        ApiUrl._API_GET_USER_MEMBER_LIST +
          "?keyword=" +
          e.target.value +
          "&directUserOnly=true"
      )
    );
    _dispatch(setPageSize(10));
    _dispatch(setY(0));
  }

  const deleteGroup = async (groupId) => {
    let params = {
      id: groupId,
    };
    let formBody = createFormBody(params);
    var responseJson = await ApiEngine.post(
      ApiUrl._API_DELETE_DOWNLINE_GROUP,
      formBody
    );
    _dispatch(
      showResponseMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        responseJson[ApiKey._API_MESSAGE_KEY]
      )
    );
    getDownlineGroups(memberData);
  };

  async function getMemberDetails() {
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_MEMBER_DETAILS_BY_ID
    );

    let categoryJson = await ApiEngine.get(
      ApiUrl._API_GET_PRODUCT_CATEGORY_DESKTOP +
        "?selectedLanguage=" +
        selectedLanguage
    );
    if (categoryJson[ApiKey._API_SUCCESS_KEY]) {
      setCategories(categoryJson[ApiKey._API_DATA_KEY]);
    }
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setMemberData(responseJson[ApiKey._API_DATA_KEY]);
      getDownlineGroups(responseJson[ApiKey._API_DATA_KEY]);
    } else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }
    //if (
    //  activeTab === tabHeadings.find((x) => x.title === "DOWNLINE_GAMES").tab_id
    //) {
    //  await getMemberProductShowList();
    //}
  }

  async function getMemberDetailsbyId(memberId) {
    _dispatch(setBusy());
    try {
      var responseJson = await ApiEngine.get(
        `${ApiUrl._API_GET_MEMBER_DETAILS_BY_ID}?memberId=` + memberId
      );

      if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
        throw responseJson[ApiKey._API_MESSAGE_KEY];
      }

      setDownlineData(responseJson[ApiKey._API_DATA_KEY]);
    } catch (errorMessage) {
      _dispatch(
        showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
      );
    } finally {
      _dispatch(setIdle());
    }
  }

  // async function getMemberProductShowList() {
  //   _dispatch(setBusy());
  //   var productResponseJson = await ApiEngine.get(
  //     ApiUrl._API_GET_ASSIGNED_MEMBER_PRODUCT_SHOW_LIST
  //   );
  //   if (productResponseJson[ApiKey._API_SUCCESS_KEY]) {
  //     let data = productResponseJson[ApiKey._API_DATA_KEY]["products"];
  //     setProducts(data);
  //     let productList = [];
  //     data.map((data) => {
  //       productList.push({ label: data.productName, value: data.productId });
  //     });
  //     setProductOption(productList);
  //   }
  //   _dispatch(setIdle());
  // }

  const getDownlineGroups = async (memberData) => {
    var responseJson = await ApiEngine.get(ApiUrl._API_GET_DOWNLINE_GROUPS);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      let downlineGroupList = [];
      let totalMembersInEachGroup = [];
      downlineGroupList.push({ label: "Default", value: 0 });
      let totalMembersInDefault = memberData["totalDirectDownlines"];
      data["totalMemberInGroup"].map((total, index) => {
        totalMembersInDefault -= total;
        totalMembersInEachGroup.push(total);
      });
      setTotalUngroupedMembers(totalMembersInDefault);
      totalMembersInEachGroup.unshift(totalMembersInDefault);
      data["data"].map((group, index) => {
        downlineGroupList.push({ label: group["name"], value: group["id"] });
      });
      setDownlineGroups(downlineGroupList);
      setDownlineMemberData(totalMembersInEachGroup);
    }
  };

  async function toggleShowValue(
    value,
    index,
    downlineUsername,
    product,
    showStatus
  ) {
    _dispatch(setBusy());
    let responseJson = await ApiEngine.post(
      ApiUrl._API_UPDATE_DOWNLINE_MEMBER_GAME +
        "?username=" +
        downlineUsername +
        "&masterProductId=" +
        product +
        "&enable=" +
        !value
    );
    let tempProducts = username;
    let tempValue = !value;
    let newArr = tempProducts[index];
    if (tempValue === true) {
      newArr.show = true;
    } else {
      newArr.show = false;
    }
    tempProducts[index] = { ...newArr };
    _dispatch(setIdle());
  }

  async function onSubmit(data, e) {
    _dispatch(setBusy());
    let params = {
      id: _location["state"]["id"],
    };

    Object.keys(data).map((key, value) => {
      params[key] = data[key];
    });

    let formBody = createMultiPartFormBody(params);
    //let tempformBody = createMultiPartFormBody({
    //    accountProductId: product["accountProductId"],
    //    commission: product['commission'],
    //    bonus: product['bonus']
    //});
    let responseJson = await ApiEngine.post(
      ApiUrl._API_UPDATE_MEMBER_PRODUCT_RATE,
      formBody
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      init();
    }
    _dispatch(
      showResponseMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        responseJson[ApiKey._API_MESSAGE_KEY]
      )
    );
    _dispatch(setIdle());
  }

  return (
    <>
      <section className="section-padding" id="downline-section">
        <div className="container">
          <div className="row">
            <div className="col-3" id="account-menu">
              <Sidebar activePage={"DOWNLINE"} />
            </div>
            <div className="col-9">
              <ContentHeader
                title={t("DOWNLINE")}
                // escButtonOnClick={() => window.history.go(-1)}
              />
              {
                <Nav id="my-tabs" className="mt-4">
                  {tabHeadings &&
                    tabHeadings.length &&
                    map(tabHeadings, (item, index) => {
                      return (
                        <NavItem
                          key={"downline-tab-" + index}
                          onClick={() => toggleTab(item.tab_id)}
                        >
                          <NavLink
                            className={
                              "font-12 " +
                              classnames({ active: activeTab === item.tab_id })
                            }
                          >
                            <span>{t(item.title)}</span>
                          </NavLink>
                        </NavItem>
                      );
                    })}
                </Nav>
              }
              {
                <TabContent key={"tab_content"} activeTab={activeTab}>
                  {tabHeadings &&
                    tabHeadings.length &&
                    map(tabHeadings, (item, index) => {
                      return (
                        <TabPane
                          key={"downline-tab-content-" + index}
                          tabId={item.tab_id}
                        >
                          {item.tab_id == 0 && (
                            <>
                              <div
                                className="template-box mt-3"
                                id="downline-search-box-main"
                              >
                                <Input
                                  placeholder={t("ALIAS_NAME_PHONE")}
                                  defaultValue={_savedData}
                                  onInput={(e) => {
                                    getText(e);
                                  }}
                                  inputClassName="downline-search-input"
                                  inputGroupType="append"
                                  inputGroupContent={
                                    <i
                                      className="fas fa-search"
                                      style={{
                                        color: "white",
                                        fontSize: "15px",
                                      }}
                                    ></i>
                                  }
                                />
                                <div className="border-bot-black"></div>
                                <div className="row">
                                  <div className="col-lg-7 col-md-8">
                                    <div
                                      className="template-box bg-darkblue"
                                      id="downline-col"
                                    >
                                      <div className="first">
                                        <span className="mb-0 font-15 text-white">
                                          {t("NEW_MEMBER_TODAY", {
                                            value:
                                              memberData[
                                                "totalNewDownlineToday"
                                              ],
                                          })}
                                        </span>
                                      </div>
                                      <div className="second">
                                        <span className="mb-0 font-15 text-white">
                                          <div>
                                            {t("TOTAL")} &nbsp;{" "}
                                            <span>
                                              {memberData["totalDownlines"]}
                                            </span>
                                          </div>
                                          <div>
                                            {t("LEVEL")} &nbsp;{" "}
                                            <span>
                                              {memberData["maxDownlineLevel"]}
                                            </span>
                                          </div>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-5 col-md-4">
                                    <div className="template-box bg-darkblue text-center">
                                      <span className="text-gold font-semi font-20 mb-1">
                                        {memberData["totalDirectDownlines"]}
                                      </span>
                                      <br />
                                      <span className="mb-0 font-15 text-white">
                                        {t("DIRECT_DOWNLINE")}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <DynamicLoadTable
                                pageSize={_savedPageSize}
                                apiQuery={_savedQuery}
                                searchEnabled={true}
                                autoscrollEnabled={true}
                                render={(item, index) => {
                                  return (
                                    <div
                                      key={"dynamic_load_table_" + index}
                                      className="downline-main-boxes"
                                    >
                                      <div
                                        className="downline-accordion-title font-14 text-white"
                                        data-toggle="collapse"
                                        data-target={"#downline-drop" + index}
                                        onClick = {() => {
                                          getMemberDetailsbyId(item.id);
                                        }}
                                      >
                                        <span className="font-14 text-white font-semi mb-0">
                                          {item.username} {!stringIsNullOrEmpty(item.fullname) ? " - " + item.fullname : ""}
                                          <br />
                                          {!stringIsNullOrEmpty(
                                            item.nickname
                                          ) && (
                                            <small className="d-block">
                                              ({item.nickname})
                                            </small>
                                          )}
                                          {item.phoneNumber}
                                        </span>

                                        <div className="acc-arrows">
                                          <img
                                            src={"/img/assets/next-page.png"}
                                            className="img-responsive img1"
                                            alt="accordion"
                                          />
                                          <img
                                            src={"/img/assets/drop.png"}
                                            className="img-responsive img2"
                                            alt="accordion"
                                          />
                                        </div>
                                      </div>
                                      <div
                                        id={"downline-drop" + index}
                                        className="downline-accordion-wrapper collapse text-white mt-3"
                                      >
                                        {/*redundant?*/
                                        /*<div className="downline-accordion-details">
                                          <span className="font-14 text-white font-semi">Username</span>
                                          <div className="d-item font-14 text-white">
                                            <span>{item.username}</span>
                                          </div>
                                        </div>*/}
                                        <div className="downline-accordion-details">
                                          <span className="font-14 text-white font-semi">
                                            {t("CREATED_DATE")}
                                          </span>
                                          <div className="d-item font-14 text-white">
                                            <span>{item.createdDate}</span>
                                          </div>
                                        </div>
                                        <div className="downline-accordion-details">
                                          <span className="font-14 text-white font-semi">
                                            {t("COMM_RATE")}
                                          </span>
                                          <div className="d-item font-14 text-red font-semi">
                                            <Link
                                              to={{
                                                pathname:
                                                  WebUrl._URL_PROGRESS_COMM_RATE,
                                                state: { id: item.id },
                                              }}
                                              className="text-red"
                                            >
                                              {t("SEE_MORE")}
                                            </Link>
                                          </div>
                                        </div>
                                        <div className="downline-accordion-details">
                                          <span className="font-14 text-white font-semi">
                                            {t("POINTS")}
                                          </span>
                                          <div className="d-item font-14 text-white">
                                            <span>
                                              {
                                                "0" /*item.points*/ /*Jason - Not sure where to get data for this Points*/
                                              }
                                            </span>
                                          </div>
                                        </div>
                                        <div className="downline-accordion-details">
                                          <span className="font-14 text-white font-semi">
                                            {t("DIRECT_DOWNLINE")}
                                          </span>
                                          <div className="d-item font-14 text-white">
                                            <span>
                                              {/* {item.totalDirectDownline} */}
                                              {downlineData["totalDirectDownlines"]}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="downline-accordion-details">
                                          <span className="font-14 text-white font-semi">
                                            {t("TOTAL_DOWNLINES")}
                                          </span>
                                          <div className="d-item font-14 text-white">
                                            <span>
                                              {/* {item.maxDownlineLevel} */}
                                              {downlineData["totalDownlines"]}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="downline-accordion-details">
                                          <span className="font-14 text-white font-semi">
                                            {t("LAST_LOGIN")}
                                          </span>
                                          <div className="d-item font-14 text-white">
                                            <span>
                                              {/* {item.maxDownlineLevel} */}
                                              {!stringIsNullOrEmpty(downlineData["lastLogin"]) ? moment(downlineData["lastLogin"]).format("YYYY-MM-DD HH:mm:ss") : t("NULL")}
                                            </span>
                                          </div>
                                        </div>
                                        {/*<div className="downline-accordion-details">
                                          <span className="font-14 text-white font-semi">Level</span>
                                          <div className="d-level font-14 font-semi">
                                            <span>Bonus: {item.bonus}</span>
                                          </div>
                                        </div>
                                        <div className="downline-accordion-details">
                                          <span></span>
                                          <div className="d-level font-14 font-semi">
                                            <span>System: {item.system}</span>
                                          </div>
                                        </div>*/}
                                        <div className="downline-accordion-details">
                                          <Link
                                            to={{
                                              pathname:
                                                WebUrl._URL_EDIT_DOWNLINE,
                                              state: { id: item.id },
                                            }}
                                            className="d-edit-details font-14 text-gold cursor-pointer"
                                          >
                                            {t("EDIT_DOWNLINE_DETAILS")}
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }}
                              />
                            </>
                          )}
                          {item.tab_id == 1 && (
                            <>
                              {downlineGroups.map((downlineGroup, index) => {
                                return (
                                  <div key={index}>
                                    <div className="wallet-main-boxes downline-group">
                                      <div
                                        className="d-flex align-items-center"
                                        style={{
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {downlineGroup.label !== "Default" && (
                                          <i
                                            id="delGroup"
                                            className="fas fa-trash d-flex align-items-center"
                                            style={{ color: "white" }}
                                            onClick={() => {
                                              setSelectedGroupToDelete(
                                                downlineGroup
                                              );
                                              setConfirmDelete(true);
                                            }}
                                          />
                                        )}
                                        <strong
                                          className="d-block"
                                          style={{
                                            paddingLeft:
                                              downlineGroup.label !== "Default"
                                                ? "15px"
                                                : "25px",
                                            color: "white",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            width: "125px",
                                          }}
                                        >
                                          {downlineGroup.label}
                                        </strong>
                                        <strong style={{ color: "white" }}>
                                          {" (" +
                                            t("TOTAL_MEMBER") +
                                            ": " +
                                            (downlineGroup.label !== "Default"
                                              ? downlineMemberData[index]
                                              : totalUngroupedMembers) +
                                            ")"}
                                        </strong>
                                      </div>
                                      <div className="group-row-right">
                                        {downlineGroup.label !== "Default"
                                          ? downlineMemberData[index] !== 0 ? //custom group has member
                                            <Link
                                              to={{
                                                pathname:
                                                  WebUrl._URL_Edit_Downline_Group_Detail,
                                                state: {
                                                  id: downlineGroup.value,
                                                  groupName: downlineGroup.label,
                                                },
                                              }}
                                            >
                                              <button
                                                style={{
                                                  whiteSpace: "nowrap",
                                                  marginTop: "0",
                                                  padding: "5px 15px",
                                                  fontSize: "12px",
                                                }}
                                                className="custom-btn-style-1"
                                              >
                                                {t("EDIT_%")}
                                              </button>
                                            </Link> : //custom group has no member
                                            <div className="no-downline-div">
                                              <button
                                                style={{
                                                  whiteSpace: "nowrap",
                                                  marginTop: "0",
                                                  padding: "5px 15px",
                                                  fontSize: "12px",
                                                }}
                                                className="custom-btn-style-1"
                                                onClick={() => _dispatch(showResponseMessage(false, t("NO_DOWNLINE_IN_GROUP")))}
                                              >
                                                {t("EDIT_%")}
                                              </button>
                                            </div>
                                          : totalUngroupedMembers !== 0 ? //default group has member
                                            <Link
                                              to={{
                                                pathname:
                                                  WebUrl._URL_Edit_Downline_Group_Detail,
                                                state: {
                                                  id: downlineGroup.value,
                                                  groupName: downlineGroup.label,
                                                },
                                              }}
                                            >
                                              <button
                                                style={{
                                                  whiteSpace: "nowrap",
                                                  marginTop: "0",
                                                  padding: "5px 15px",
                                                  fontSize: "12px",
                                                }}
                                                className="custom-btn-style-1"
                                              >
                                                {t("EDIT_%")}
                                              </button>
                                            </Link> : //default group has no member
                                            <div className="no-downline-div">
                                              <button
                                                style={{
                                                  whiteSpace: "nowrap",
                                                  marginTop: "0",
                                                  padding: "5px 15px",
                                                  fontSize: "12px",
                                                }}
                                                className="custom-btn-style-1"
                                                onClick={() => _dispatch(showResponseMessage(false, t("NO_DOWNLINE_IN_GROUP")))}
                                              >
                                                {t("EDIT_%")}
                                              </button>
                                            </div>
                                        }
                                        <Link
                                          to={{
                                            pathname:
                                              WebUrl._URL_DOWNLINE_GROUP_DETAIL,
                                            state: { id: downlineGroup.value },
                                          }}
                                        >
                                          <i
                                            className="fas fa-chevron-right "
                                            style={{
                                              fontSize: "20px",
                                              marginLeft: "50px",
                                              color: "white",
                                            }}
                                          ></i>
                                        </Link>
                                      </div>
                                    </div>
                                    {index === downlineGroups.length - 1 && (
                                      <div className="container create-group-btn">
                                        <button
                                          type="button"
                                          className="custom-btn-style-1"
                                          onClick={() => {
                                            _history.push({
                                              pathname:
                                                WebUrl._URL_DOWNLINE_GROUP_DETAIL,
                                            });
                                          }}
                                        >
                                          {t("CREATE")}
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                              <SweetAlert
                                show={confirmDelete}
                                type="warning"
                                title={
                                  t("DELETE_GROUP") +
                                  selectedGroupToDelete.label +
                                  "?"
                                }
                                showCancel
                                cancelBtnText={t("CANCEL")}
                                confirmBtnText={t("CONFIRM")}
                                onConfirm={() => {
                                  deleteGroup(selectedGroupToDelete.value);
                                  setConfirmDelete(false);
                                }}
                                onCancel={() => {
                                  setConfirmDelete(false);
                                }}
                              />
                            </>
                          )}
                          {/*{item.tab_id == 2 && (*/}
                          {/*  <>*/}
                          {/*    <div className="form-group m-b-0">*/}
                          {/*      <div className="form-group m-b-15">*/}
                          {/*        <label className="col-form-label">*/}
                          {/*          <b style={{ color: "white" }}>*/}
                          {/*            {t("GAMES")}*/}
                          {/*          </b>*/}
                          {/*        </label>*/}
                          {/*        <div className="downline-games-contoller">*/}
                          {/*        <Controller*/}
                          {/*          control={control}*/}
                          {/*          name="productId"*/}
                          {/*          defaultValue=""*/}
                          {/*          render={({ onChange, value }) => {*/}
                          {/*            return (*/}
                          {/*              <SelectOption*/}
                          {/*              options={productOption}*/}
                          {/*              placeholder={t(*/}
                          {/*                "PLEASE_SELECT_GAME"*/}
                          {/*              )}*/}
                          {/*              value={value}*/}
                          {/*              onChange={(e) => {*/}
                          {/*                onChange(e.value);*/}
                          {/*                getDownlineGames(e.value);*/}
                          {/*              }}*/}
                          {/*              />*/}
                          {/*            );*/}
                          {/*          }}*/}
                          {/*          rules={{ required: "PLEASE_SELECT_BANK" }}*/}
                          {/*        />*/}
                          {/*         {errors.bankId && (*/}
                          {/*            <div className="invalid-feedback">*/}
                          {/*              {t(errors.bankId.message)}*/}
                          {/*            </div>*/}
                          {/*          )}*/}
                          {/*      </div>*/}
                          {/*      </div>*/}
                          {/*      <table className="edit-downline-table">*/}
                          {/*        <thead></thead>*/}
                          {/*        <tbody>*/}
                          {/*          {username.map((u, index) => {*/}
                          {/*            return (*/}
                          {/*              <tr*/}
                          {/*                key={index}*/}
                          {/*                className={*/}
                          {/*                  "data-row" +*/}
                          {/*                  ((index + 1) % 2 !== 0*/}
                          {/*                    ? " bg-color-1" +*/}
                          {/*                      (_userData.selectedTheme === 0*/}
                          {/*                        ? " classic"*/}
                          {/*                        : " modern")*/}
                          {/*                    : "")*/}
                          {/*                }*/}
                          {/*              >*/}
                          {/*                <td*/}
                          {/*                  className="row-data-1"*/}
                          {/*                  style={{ color: "white" }}*/}
                          {/*                >*/}
                          {/*                  <b>{u.username}</b>*/}
                          {/*                </td>*/}
                          {/*                <td className="row-data-2"></td>*/}
                          {/*                <td className="row-data-3"></td>*/}
                          {/*                <td className="row-data-4">*/}
                          {/*                  <ToggleButton*/}
                          {/*                    value={*/}
                          {/*                      u.show === true ? true : false*/}
                          {/*                    }*/}
                          {/*                    onToggle={(value) => {*/}
                          {/*                      toggleShowValue(*/}
                          {/*                        value,*/}
                          {/*                        index,*/}
                          {/*                        u.username,*/}
                          {/*                        u.product,*/}
                          {/*                        u.show*/}
                          {/*                      );*/}
                          {/*                    }}*/}
                          {/*                  />*/}
                          {/*                </td>*/}
                          {/*              </tr>*/}
                          {/*            );*/}
                          {/*          })}*/}
                          {/*        </tbody>*/}
                          {/*      </table>*/}
                          {/*    </div>*/}
                          {/*  </>*/}
                          {/*)}*/}
                        </TabPane>
                      );
                    })}
                </TabContent>
              }
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default withRouter(Downline);

import React, { useState, useEffect, useRef } from "react";
//import "react-simple-keyboard/build/css/index.css";
import { useTranslation } from "react-i18next";
import { ApiKey, ApiUrl, SessionKey } from "../../util/Constant";
import { stringIsNullOrEmpty } from "../../util/Util";
import GameMenu from "components/game-list/game-menu";
import ApiEngine from "../../util/ApiEngine";
import { useDispatch } from "react-redux";
import { setBusy, setIdle, showResponseMessage } from "../../redux/AppAction";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

const ContactForm = (props) => {
  const { t } = useTranslation();
  const [channels, setChannels] = useState([]);
  // const [channels, setChannels] = useState([
  //   {
  //     name: "WhatsApp",
  //     description: "+60 11 2652 0118",
  //     actionUrl:
  //       "https://api.whatsapp.com/send/?phone=601126520118&text=Interested+to+Play+i8&app_absent=0",
  //     image: require("../../assets/img/icon/whatsapp.png"),
  //     attachment: null,
  //   },
  //   {
  //     name: "WeChat ID",
  //     description: "ID: i8CS01",
  //     actionUrl: "",
  //     image: require("../../assets/img/icon/wechat.png"),
  //     attachment:
  //       "https://cdn.i8global.com/i8app/download-202205231208375256.png",
  //   },
  //   {
  //     name: "Telegram",
  //     description: "@I8CS01",
  //     actionUrl: "http://t.me/I8CS2021",
  //     image: require("../../assets/img/icon/telegram.png"),
  //     attachment: null,
  //   },
  //   {
  //     name: "Telegram Bot",
  //     description: "@i8livebot",
  //     actionUrl: "http://t.me/I8CS2021",
  //     image: require("../../assets/img/icon/i8livebot.png"),
  //     attachment: null,
  //   },
  // ]);

  const [selectedChannel, setSelectedChannel] = useState();
  const [toggleModal, setToggleModal] = useState();
  const name = useRef("");
  const email = useRef("");
  const phone = useRef("");
  const message = useRef("");
  const [emailLink, setEmailLink] = useState("");
  const [functionListener, setFunctionListener] = useState("");
  const [displayGameMenu, setDisplayGameMenu] = useState(true);
  const [displayBody, setDisplayBody] = useState(true);
  let _dispatch = useDispatch();

  useEffect(() => { 
    if (selectedChannel) {
      if (!stringIsNullOrEmpty(selectedChannel.attachment)) {
        setToggleModal(true);
      }
    }
  }, [selectedChannel]);

  useEffect(() => {
    init();
  }, [(localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
      localStorage.getItem(SessionKey._LANGUAGE) : 'en')]);

  async function init() {
    _dispatch(setBusy());
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_COMMUNICATION_CHANNELS + "?all=true&languageCode=" + 
      (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
      localStorage.getItem(SessionKey._LANGUAGE) : 'en')
      // localStorage.getItem(SessionKey._LANGUAGE) 
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setChannelData(responseJson[ApiKey._API_DATA_KEY]);
      setDisplayBody(false);
      setTimeout(() => setDisplayBody(true), 1);
    } 
    // else {
    //   _dispatch(
    //     showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
    //   );
    // }

    _dispatch(setIdle());
  }

  useEffect(() => {
    setDisplayGameMenu(false);
    setTimeout(() => setDisplayGameMenu(true), 1);
  }, [
    // localStorage.getItem(SessionKey._LANGUAGE)
    (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
      localStorage.getItem(SessionKey._LANGUAGE) : 'en')
  ]);

  function setChannelData(data) {
    var channels = [];
    data.map((d, index) => {
      var channel = {
        channelName: d.channelName,
        name: d.channelDescription,
        description: "",
        actionUrl: d.cta,
        image: d.channelImage,
        attachment: d.attachment,
      };

      channels.push(channel);
    });
    setChannels(channels);
  }

  useEffect(() => {
    setEmailLink(functionListener);
  }, [functionListener]);

  function setContactDetails() {
    var savedName = name.current.value;
    var savedEmail = email.current.value;
    var savedPhone = phone.current.value;
    var savedMessage = message.current.value;
    // setEmailLink("mailto:johnralph.olayvar@gmail.com?subject=i8 desktop contact form&body=Fullname:" + savedName + "Email:" + savedEmail);
    setFunctionListener(
      "mailto:johnralph.olayvar@gmail.com?subject=i8 desktop contact form&body=Fullname:" +
        savedName +
        "%0d%0aEmail:" +
        savedEmail +
        "%0d%0aPhone:" +
        savedPhone +
        "%0d%0aMessage:" +
        savedMessage
    );
  }

  return (
    <>
      {displayGameMenu && (
        <>
          <section className='category-section'>
            <GameMenu />
          </section>

          <section id='contactus' className='contactus-section'>
            <div className='container'>
              <div className='template-box'>
                <div className='banner'>
                  <img
                    src={"/img/ContactUs.png"}
                    className='img-100'
                    alt='ContactUs'
                  />
                </div>

                <div className='contact container'>
                  <div className='contact-header text-center'>
                    <span className='text-1 text-white font-semi'>
                      {t("WE_ARE_HERE_TO_HELP")}
                    </span>
                    <br />
                    <span className='font-14 text-white font-semi'>
                      {t("SEND_US_MESSAGE")}
                    </span>
                  </div>

                  {displayBody && <><div className='contact-body'>
                    <div className='row d-flex justify-content-start mb-5 text-white'>
                      {channels.map((channel, index) => {
                        return (
                          <div className='col-md-3 col-lg-3 col help-centre-margin' key={index}>
                          <div className='d-flex justify-content-between contactus-list cursor-pointer' 
                             onClick={() => { 
                              if (channel.channelName === "Livechat" ) {
                                // window.open(channel.actionUrl, "_blank")
                                window.open(channel.actionUrl, "_blank", 'live-chat')

                              }
                              else if (!stringIsNullOrEmpty(channel.actionUrl)) {
                                window.location.href = channel.actionUrl
                              } 
                              else if (channel.channelName.match("WeChat")) {
                                setSelectedChannel(channel);
                                setToggleModal(true);
                              } else  {
                                setToggleModal(false)
                              } 
                              }}>
                            <div>
                              <div className='channel-content d-flex flex-row align-items-center'>
                                <img
                                  className='channel-logo'
                                  src={channel.image}
                                />
                                <div>
                                  <span className='font-11 text-white font-semi'>
                                    {channel.name}
                                  </span>
                                  <br />
                                  <span className='font-18 text-white font-semi'>
                                    {channel.description}
                                  </span>
                                </div>
                              </div>
                            </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div></>}
                </div>
              </div>
            </div>
          </section>

          {/* <LiveChat license={12685218} /> */}
          {/* <LiveChat license={12685218} onChatLoaded={ref => setLiveChat(ref)} /> */}
          <Modal fade={false} isOpen={toggleModal} toggle={() => { setToggleModal(false) }} centered>
            <ModalHeader toggle={() => { setToggleModal(false) }}>{selectedChannel?.name}</ModalHeader>
            <ModalBody>
                <img src={selectedChannel?.attachment} className="w-100" />
            </ModalBody>
          </Modal>
        </>
      )}
    </>
  );
};

export default ContactForm;

import React, { useState, useEffect } from "react";
//import "react-simple-keyboard/build/css/index.css";
import Sidebar from "components/sidebar";
import ContentHeader from "components/content/header";
import moment from "moment";
import { useTranslation } from "react-i18next";
import DateRangeFilter from "../../components/custom/DateRangeFilter"; 
import { stringIsNullOrEmpty, numberWithCurrencyFormat } from "../../util/Util";
import { ApiUrl } from "../../util/Constant";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";

const RewardHistory = (props) => {
  const { t, i18n } = useTranslation();

  const [rewardUrl, setRewardUrl] = useState("");
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [selectedDate, setSelectedDate] = useState("");

  useEffect(() => {
    getRewardHistory(startDate, endDate);
  }, []);

  async function getRewardHistory(start, end, selected = "") {
    // let apiUrl =
    //   ApiUrl._API_GET_MEMBER_REWARD_HISTORY +
    //   "?StartDate=" +
    //   moment(start).format("YYYY-MM-DD") +
    //   "&EndDate=" +
    //   moment(end).format("YYYY-MM-DD") +
    //   "&v=" +
    //   Date.now();

    let apiUrl =
    ApiUrl._API_GET_MEMBER_REWARD_HISTORY +
    "?StartDate=" +
    // moment(start).format("YYYY-MM-DD HH:mm:ss") +
    moment(start).startOf('day').format("YYYY-MM-DD") +
    "&EndDate=" +
    // moment(end).format("YYYY-MM-DD") +
    moment(end).endOf('day').format("YYYY-MM-DD") +
    "&v=" + moment(start).startOf('day').format("YYYY-MM-DD");

    if (!stringIsNullOrEmpty(selected)) {
      // apiUrl += "&SelectedDate=" + moment(selected).format("YYYY-MM-DD");
      apiUrl += "&SelectedDate=" + moment(selected).format("YYYY-MM-DD");
    }
    setRewardUrl(apiUrl);
  }

  useEffect(() => {
    if (!stringIsNullOrEmpty(selectedDate)) {
      getRewardHistory(startDate, endDate, selectedDate);
    }
  }, [selectedDate]);

  return (
    <>
      <section className="section-padding" id="reward-history-section">
        <div className="container">
          <div className="row">
            <div className="col-3" id="account-menu">
              <Sidebar activePage={"WALLET"} />
            </div>
            <div className="col-9">
              <ContentHeader enableBackButton title={t("REWARDS_RECORD")} />
              <hr className="right-title-hr" />
              <div className="template-box mt-3" id="reward-history-box">
                <DateRangeFilter
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  onSave={(start, end) => {
                    setStartDate(moment(start));
                    setEndDate(moment(end));
                    getRewardHistory(start, end);
                    setSelectedDate("");
                  }}
                  options={true}
                />

                <div className="thc-result-mainbox font-12">
                  <table className="table reportTable">
                    <thead>
                      <tr>
                        <th>{t("DATE")}</th>
                        <th>{t("PRODUCT_NAME")}</th>
                        <th>{t("CATEGORY")}</th>
                        <th>{t("COMMISSION_RATE")}</th>
                        <th>{t("VALID_TURNOVER")}</th>
                        <th>{t("AMOUNT")}</th>
                        <th>{t("CLAIMED_DATE")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <DynamicLoadTable
                        pageSize={20}
                        apiQuery={rewardUrl}
                        searchEnabled={true}
                        isTable={true}
                        render={(item, i) => {
                          return (
                            <tr key={"rewards_history" + i}>
                              <td>{item["date"]}</td>
                              <td>{item["productName"]}</td>
                              <td>{t(item["categoryName"])}</td>
                              <td>{numberWithCurrencyFormat(parseFloat(item["commissionPercent"]), 2)}</td>
                              <td>{numberWithCurrencyFormat(parseFloat(item["sales"]), 2)}</td>
                              <td>{numberWithCurrencyFormat(parseFloat(item["amount"]), 2)}</td>
                              <td className="">{item["claimedDate"]}</td>
                            </tr>
                          );
                        }}
                      />
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default RewardHistory;

import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  showResponseMessage,
  setBusy,
  setIdle,
} from "../../redux/AppAction.js";
import { ApiKey, ApiUrl, WebUrl, Role } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import moment from "moment";
import ApiEngine from "../../util/ApiEngine.js";
import {
  stringIsNullOrEmpty,
  isObjectEmpty,
  numberWithCurrencyFormat,
  createMultiPartFormBody
} from "../../util/Util";
import StyledSelect from "../../components/custom/StyledSelect";
import DateRangeFilter from "../../components/custom/DateRangeFilter";
import { useForm, Controller } from "react-hook-form";
import { Nav, NavItem, NavLink } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Icon from "@mdi/react";
import { mdiHistory } from "@mdi/js";
import Sidebar from "components/sidebar";
import ContentHeader from "components/content/header";

const UnclaimLoyaltyPoints = () => {
  let _history = useHistory();
  const { t, i18n } = useTranslation();
  var _dispatch = useDispatch();
  var _userData = useSelector((state) => state.authState.userData);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [monthOptions, setMonthOptions] = useState([]);
  const [data, setData] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState("");

  const { handleSubmit, control, watch, setValue } = useForm();
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [selectedDate, setSelectedDate] = useState(moment());
  const [queryProduct, setQueryProduct] = useState("");
  const [queryStatus, setQueryStatus] = useState("");
  const [searchTrigger, setSearchTrigger] = useState(Date.now());

  const [memberData, setMemberData] = useState({});
  const [unclaimLoyaltyPoints, setUnclaimLoyaltyPoints] = useState("");

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (!stringIsNullOrEmpty(selectedMonth)) {
      setSelectedIndex(
        data.bonusDetail.findIndex((bd) => bd.month == selectedMonth)
      );
    }
  }, [selectedMonth]);

  async function init() {
    _dispatch(setBusy());
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_MEMBER_DETAILS_BY_ID
    );
    if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
      throw responseJson[ApiKey._API_MESSAGE_KEY];
    }
    setMemberData(responseJson[ApiKey._API_DATA_KEY]);
    getUnclaimLoyaltyPoints();

    _dispatch(setIdle());
  }

  const getUnclaimLoyaltyPoints = async () => {
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_UNCLAIM_LOYALTY_POINTS
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      setUnclaimLoyaltyPoints(data);
    }
  };

  function preSubmit() {
    setShowConfirmation(true);
  }

  async function submitForm() {
    _dispatch(setBusy());
    let responseJson = await ApiEngine.post(
      ApiUrl._API_CLAIM_LOYALTY_POINTS,
      createMultiPartFormBody({})
    );

    _dispatch(
      showResponseMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        responseJson[ApiKey._API_MESSAGE_KEY]
      )
    );
    _dispatch(setIdle());

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      init();
    }
  }

  return (
    <section className="section-padding" id="loyalty-section">
      <div className="container">
        <div className="row">
          <div className="col-3 account-menu">
            <Sidebar activePage={"GAME_POINTS"} />
          </div>
          <div className="col-9">
            <ContentHeader title={t("GAME_POINTS_DASHBOARD")} />
            <hr className="right-title-hr" />

            {showConfirmation && (
              <SweetAlert
                info
                showCancel
                confirmBtnText={t("CONFIRM")}
                cancelBtnText={t("CANCEL")}
                confirmBtnBsStyle="info"
                title={t("CONFIRM_CLAIM")}
                onConfirm={() => {
                  setShowConfirmation(false);
                  handleSubmit(submitForm)();
                }}
                onCancel={() => {
                  setShowConfirmation(false);
                }}
              ></SweetAlert>
            )}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="text-gold ml-4" style={{ fontFamily: "averta-semibold" }}>
                {t("CURRENT_BALANCE")}
              </div>
            </div>
            <div className="loyalty-wrapper">
              <div className="loyal-wrapper">
                <div className="text-bold text-white" style={{ fontFamily: "averta-semibold" }}>
                  {unclaimLoyaltyPoints == ""
                    ? t("LOADING") + "... "
                    : " " +
                    numberWithCurrencyFormat(
                      parseFloat(unclaimLoyaltyPoints.totalClaimable),
                      2
                    )}
                </div>
                <div className="d-flex align-items-center">
                  <button
                    type="submit"
                    className="claim-btn"
                    disabled={unclaimLoyaltyPoints == "" || unclaimLoyaltyPoints["totalClaimable"] == 0}
                    onClick={() => unclaimLoyaltyPoints["totalClaimable"] != 0 && preSubmit()}
                  >
                    {t("CLAIM")}
                  </button>
                </div>
              </div>
            </div>
            <div className="text-gold  ml-4 mb-3" style={{ fontFamily: "averta-semibold" }}>
              {t("CURRENT_SUMMARY")}
            </div>
            {unclaimLoyaltyPoints["data"] && unclaimLoyaltyPoints["data"].length > 0 && (
              <div className="card card-package loyal-summary-table-wrapper">
                <div className="card-body p-0">
                  <div className="card-table table-responsive">
                    <table className="table table-kpi-summary loyal-summary-table">
                      <thead>
                        <tr>
                          <th style={{ textAlign: "center" }}>{t("DATE")}</th>
                          <th style={{ textAlign: "center" }}>{t("CLAIMABLE")}</th>
                          <th style={{ textAlign: "center" }}>{t("STATUS")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {unclaimLoyaltyPoints["data"].map((row, i) => {
                          return (
                            <tr key={i}>
                              <td style={{ textAlign: "center" }}>
                                {moment(row["loyaltyPointsDate"]).format("YYYY-MM-DD")}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {numberWithCurrencyFormat(
                                  parseFloat(row["totalLoyaltyPoints"]),
                                  2,
                                  true
                                )}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <span
                                  className="badge badge-no text-black"
                                  style={{ fontSize: "12px", fontFamily: "averta-semibold", padding: "0.25rem 0.5rem" }}
                                >
                                  {t("UNCLAIMED")}
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
            {unclaimLoyaltyPoints["data"] && unclaimLoyaltyPoints["data"].length == 0 && (
              <div className="card card-package loyal-summary-table-wrapper">
                <div className="card-body p-0">
                  <div className="card-table table-responsive">
                    <table className="table table-kpi-summary loyal-summary-table">
                      <thead>
                        <tr>
                          <th style={{ textAlign: "center" }}>{t("DATE")}</th>
                          <th style={{ textAlign: "center" }}>{t("CLAIMABLE")}</th>
                          <th style={{ textAlign: "center" }}>{t("STATUS")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colSpan="5" style={{ textAlign: "left" }}>
                            {t("NO_UNCLAIMED_DATA")}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default UnclaimLoyaltyPoints;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { AlertTypes, ApiUrl, WebUrl } from "util/Constant";
import { useTranslation } from "react-i18next";
import { showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";  

const GameCategory = ({ item, active }) => {
  const _dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const availableGameCategory = useSelector((state) => (state.gameState.gameProviders));
  const { isLoggedIn } = useSelector(state => state.authState);

    const [link, setLink] = useState("");
    useEffect(() => {
      if (item.originalTitle === "All") {
        setLink(WebUrl._URL_MAIN);
      } else {
        setLink("/" + item.originalTitle.toLowerCase());
      }
    }, []);
  return (
    (availableGameCategory && availableGameCategory.filter(
        (n) => n.categoryTitle === item.originalTitle
      ).length > 0 ||
      item.title === "All") && 
    <li onClick={() => {
        //if (item.originalTitle === "Connect") {
        //  if (isLoggedIn) {
        //    window.open(ApiUrl._API_START_GAME + "?gameId=3&isApp=0&device=d");
        //  }
        //  else {
        //    _dispatch(
        //      showMessage({
        //        type: AlertTypes._INFO,
        //        content: t("PLEASE_LOGIN_FIRST"),
        //        onConfirm: () => {
        //          //console.log("hi");
        //        },
        //      })
        //    );
        //  }
        //}
    }}>
              <Link
                  // to={WebUrl._URL_GAME_PROVIDER.replace(":title", item.originalTitle)}
                  to={link}
                  className={(item.originalTitle === "Togel" ? "Togel " : "") + `cat-item ${active && "active"}`}
              >
                  {item.originalTitle === "Togel" ? <img
                      src={require(`../../assets/img/assets/category/head/fire-flame.gif`)}
                      className='fireflame' alt=''
                  /> : ""}

        <div className='cat-img'>
              <img
                          src={`https://cdn.i8global.com/i8app/category-icon/${(item.originalTitle === "Togel" ? "Togel" : item.originalTitle === "eSports" ? "e-Sports" : item.originalTitle === "SlotMix" ? "Slot Mix" : item.originalTitle).toLowerCase().replace(" ", "-")}.svg`}
                className='img-responsive'
                alt={item.originalTitle.toLowerCase().replace(" ", "-")}
                width="30"
                height="30"
              />
        </div>
          <div className={'cat-details'+(item.originalTitle == "Connect"?" connect-font": "")}>{t(item.originalTitle)}</div>
      </Link>
    </li>
  );
};

GameCategory.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  active: PropTypes.bool,
};

GameCategory.defaultProps = {
  active: false,
};

export default GameCategory;

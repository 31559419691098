import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { map } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  setBusy,
  setIdle,
  showMessage,
  showResponseMessage,
} from "../../redux/AppAction";
import {
  AlertTypes,
  ApiKey,
  ApiUrl,
  WebUrl,
  _MAIN_WALLET_ID
} from "../../util/Constant";
import { useTranslation } from "react-i18next";
import Button from "components/button";
import { useHistory, useLocation } from "react-router-dom";
import ApiEngine from "../../util/ApiEngine";
import { numberWithCurrencyFormat } from "../../util/Util";
import UMIcon from "../../assets/img/icon/dashboardv2_um.png";
import CSIcon from "../../assets/img/icon/coming-soon.png";
import moment from "moment";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const GameListGrid = ({
  filterList,
  isFilterDropdownVisible,
  gameList,
  title,
  dropdownFilterOnClick,
  handleFilterByProvider,
  type,
  page,
  providerId,
}) => {
  const { t } = useTranslation();
  const { isLoggedIn } = useSelector((state) => ({
    isLoggedIn: state.authState.isLoggedIn,
  }));
  const _history = useHistory();
  const _dispatch = useDispatch();
  const _userData = useSelector((state) => state.authState.userData);
  const [id, setId] = useState([]);

  const getProviderId = page === "home" ? [] : providerId;
  const [checked, setChecked] = useState(false);

  const [selectedGameName, setSelectedGameName] = useState("");
  const [productGameId, setProductGameId] = useState(0);
  const [memberMainWalletBalance, setMemberMainWalletBalance] = useState("");
  const [walletList, setWalletList] = useState([]);
  const [fromOptions, setFromOptions] = useState([]);
  const [toOptions, setToOptions] = useState([]);
  const [showPregamePopup, setShowPregamePopup] = useState(false);
  const [popupBannerImage, setPopupBannerImage] = useState("");
  const [cnyEndTime, setCnyEndTime] = useState(moment("2023-02-05 23:59:59:999").format("YYYY-MM-DD HH:mm:ss:SSS"));
  const [currentTime, setCurrentTime] = useState(moment().format("YYYY-MM-DD HH:mm:ss:SSS"));
  const [isSpecialRollover, setIsSpecialRollover] = useState(false);
  const [playableCategory, setPlayableCategory] = useState([]);
  const [productCategory, setProductCategory] = useState([]);

  const location = useLocation();
  const comingSoonProductGameCode = ["MEGATOGEL"]; // key in the product game code
  function handleChange(e, index) {
    const checkedId = e.target.value;
    if (e.target.checked) {
      setId((arr) => [...arr, checkedId]);
    } else {
      setId(id.filter((item) => item !== checkedId));
    }
  }

  useEffect(() => {
    setChecked(false);
  }, [location]);

  useEffect(() => {
    if (isLoggedIn) {
      getProductCategory();
      getMemberSpecialRollover();
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (isSpecialRollover) {
      getBonusDepositProductCategory();
    }
  }, [isSpecialRollover])

  async function startGame(gameId, hasOwnLobby, masterProductId, gameName, isSeamless, isNewWindow, isApp, categoryTitle, appUrl, appDeepLink, gameCode, parameter_1) {
    if (gameCode === 'MEGATOGEL' && moment(parameter_1).isValid() && moment(parameter_1).isAfter(moment())) {
      _dispatch(
        showMessage({
          type: AlertTypes._INFO,
          content: t("COMING_SOON"),
          onConfirm: () => {
          },
        })
      );
    } else {
      if (isSpecialRollover) {
        let productCategoryListArr = [...playableCategory];
        if (productCategoryListArr.includes(categoryTitle)) {
          setSelectedGameName(gameName);
          setProductGameId(gameId);
          if (isSeamless == false) {
            _dispatch(setBusy());
            let member = [];
            var responseJson = await ApiEngine.get(
              ApiUrl._API_GET_MEMBER_DETAILS_BY_ID
            );
            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
              _dispatch(
                showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
              );
              return;
            }
            member = responseJson[ApiKey._API_DATA_KEY];
            setMemberMainWalletBalance(
              isNaN(parseFloat(member["primaryCredit"]), 3)
                ? "0.00"
                : numberWithCurrencyFormat(parseFloat(member["primaryCredit"]), 2)
            );
            responseJson = await ApiEngine.get(
              ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE
            );
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
              setWalletList(responseJson[ApiKey._API_DATA_KEY]);

              let tempFromOptions = [];
              let tempToOptions = [];

              responseJson[ApiKey._API_DATA_KEY].map((product) => {
                let option = {
                  label:
                    product.productName +
                    "   " +
                    numberWithCurrencyFormat(parseFloat(product.balance), 2),
                  value: product.productId,
                };
                if (product.productId === _MAIN_WALLET_ID) {
                  tempFromOptions.push(option);
                }
                //else if (product.productId === masterProductId) {
                //  setBonus(product.bonus);
                //  setCommission(product.commission);
                //  setGameWalletBalanceBeforeTransfer(product.balance);
                //  tempToOptions.push(option);
                //}
              });
              setFromOptions(tempFromOptions);
              setToOptions(tempToOptions);
            }
            setShowPregamePopup(true);
            _dispatch(setIdle());
          } else {
            if (!window.ReactNativeWebView) {
              window.open(
                `${process.env.REACT_APP_ENDPOINT_URL || ""}${ApiUrl._API_START_GAME
                }?gameId=${gameId}&isApp=0&device=d`
              );
            } else {
              if (isNewWindow === true) {
                window.ReactNativeWebView.postMessage(
                  JSON.stringify({
                    url: `${process.env.REACT_APP_ENDPOINT_URL || window.location.origin
                      }${ApiUrl._API_START_GAME}?gameId=${gameId}`,
                  })
                );
              } else {
                window.ReactNativeWebView.postMessage(
                  JSON.stringify({
                    url: `${process.env.REACT_APP_ENDPOINT_URL || window.location.origin
                      }${WebUrl._URL_GAME_PAGE}?gameId=${gameId}`,
                  })
                );
              }
            }
          }
        }
        else {
          _dispatch(
            showMessage({
              type: AlertTypes._INFO,
              content: t("ROLLOVER_EXIST"),
              onConfirm: () => {
              },
            })
          );
        }
      }
      else {
        setSelectedGameName(gameName);
        setProductGameId(gameId);
        if (isSeamless == false) {
          _dispatch(setBusy());
          let member = [];
          var responseJson = await ApiEngine.get(
            ApiUrl._API_GET_MEMBER_DETAILS_BY_ID
          );
          if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
            _dispatch(
              showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
            );
            return;
          }
          member = responseJson[ApiKey._API_DATA_KEY];
          setMemberMainWalletBalance(
            isNaN(parseFloat(member["primaryCredit"]), 3)
              ? "0.00"
              : numberWithCurrencyFormat(parseFloat(member["primaryCredit"]), 2)
          );
          responseJson = await ApiEngine.get(
            ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE
          );
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setWalletList(responseJson[ApiKey._API_DATA_KEY]);

            let tempFromOptions = [];
            let tempToOptions = [];

            responseJson[ApiKey._API_DATA_KEY].map((product) => {
              let option = {
                label:
                  product.productName +
                  "   " +
                  numberWithCurrencyFormat(parseFloat(product.balance), 2),
                value: product.productId,
              };
              if (product.productId === _MAIN_WALLET_ID) {
                tempFromOptions.push(option);
              }
              //else if (product.productId === masterProductId) {
              //  setBonus(product.bonus);
              //  setCommission(product.commission);
              //  setGameWalletBalanceBeforeTransfer(product.balance);
              //  tempToOptions.push(option);
              //}
            });
            setFromOptions(tempFromOptions);
            setToOptions(tempToOptions);
          }
          setShowPregamePopup(true);
          _dispatch(setIdle());
        } else {
          if (!window.ReactNativeWebView) {
            window.open(
              `${process.env.REACT_APP_ENDPOINT_URL || ""}${ApiUrl._API_START_GAME
              }?gameId=${gameId}&isApp=0&device=d`
            );
          } else {
            if (isNewWindow === true) {
              window.ReactNativeWebView.postMessage(
                JSON.stringify({
                  url: `${process.env.REACT_APP_ENDPOINT_URL || window.location.origin
                    }${ApiUrl._API_START_GAME}?gameId=${gameId}`,
                })
              );
            } else {
              window.ReactNativeWebView.postMessage(
                JSON.stringify({
                  url: `${process.env.REACT_APP_ENDPOINT_URL || window.location.origin
                    }${WebUrl._URL_GAME_PAGE}?gameId=${gameId}`,
                })
              );
            }
          }
        }
      }
    }
  }

  const getMemberSpecialRollover = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_IS_SPECIAL_ROLLOVER);
    let data = "";

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      data = responseJson[ApiKey._API_DATA_KEY];
      setIsSpecialRollover(data);
    }
  }

  const getBonusDepositProductCategory = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_BONUS_DEPOSIT_PRODUCT_CATEGORY);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      let productCategoryArr = [];

      if (data.includes(",")) {
        productCategoryArr = responseJson[ApiKey._API_DATA_KEY].split(",");
      }
      else {
        productCategoryArr.push(data);
      }

      let tempArr = [];
      [...productCategory].forEach((x) => {
        if (productCategoryArr.includes(x.value)) {
          tempArr.push(x.label);
        }
      })

      setPlayableCategory(tempArr);
    }
  }

  const getProductCategory = async () => {
    let tempArr = [];
    var productCategoryJson = await ApiEngine.get(ApiUrl._API_GET_PRODUCT_CATEGORY);
    if (productCategoryJson[ApiKey._API_SUCCESS_KEY]) {
      let data = productCategoryJson[ApiKey._API_DATA_KEY];
      data.forEach(x => {
        if (x.id > 0) {
          tempArr.push({ value: x.id.toString(), label: x.title });
        }
      });
      setProductCategory(tempArr);
    }
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="title-with-filter mb-4 mt-5">
            <div>
              <h2 className="mb-0">
                {title}
              </h2>
            </div>
            <div className="filters">
              <div className="filter mr-4">
                <div className="filter-cover all-dropdown">
                  <div
                    className={
                      isFilterDropdownVisible
                        ? "dropdown1 w-100 open"
                        : "dropdown1 w-100"
                    }
                  >
                    <div
                      className="filter-section"
                      onClick={() => {
                        dropdownFilterOnClick();
                      }}
                    >
                      <div className="caption dropdownbtn">
                        {t("FILTER_BY_PROVIDER")}
                      </div>
                      <div className="arrow-dropdownbtn"></div>
                    </div>

                    <div className="list">
                      <div className="scrollable-menu white-scroll">
                        {filterList &&
                          filterList.length &&
                          map(filterList, (item, index) => {
                            return (
                              <div
                                key={index + "-" + item.masterProductId}
                                className="item"
                              >
                                <div className="form-checkbox">
                                  <input
                                    id={
                                      "checkiko_" + type + item.masterProductId
                                    }
                                    type="checkbox"
                                    name="customertype"
                                    value={item.masterProductId}
                                    onChange={(e) => {
                                      handleChange(e, index);
                                      setChecked(e.target.checked);
                                    }}
                                    defaultChecked={false}
                                    checked={checked[index]}
                                  ></input>
                                  <label
                                    htmlFor={
                                      "checkiko_" + type + item.masterProductId
                                    }
                                  >
                                    <i className="icon-box"></i>
                                    <div className="filter-provider-name">
                                      {item.gameName}
                                    </div>
                                  </label>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                      <div style={{ padding: "10px 0" }} className="d-flex justify-content-center">
                        <Button
                          text={t("APPLY")}
                          type="primary"
                          width={130}
                          onClick={() => handleFilterByProvider(id, 42, type)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="filter">
                <div
                  onClick={() =>
                    handleFilterByProvider(getProviderId, 70, type)
                  }
                  className="see-all-btn"
                >
                  {t("SEE_ALL")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row text-center seven-cols">
        {gameList &&
          gameList.length &&
          map(gameList, (game, index) => {
            return (
              <div
                key={index}
                className={
                  isLoggedIn
                    ? game["gameUm"] == true
                      ? "col um-wrapper"
                      : "col"
                    : "col"
                }
              >
                <div
                  className={
                    isLoggedIn ? (game["gameUm"] == true ? "gameum" : "") : ""
                  }
                >
                  <div
                    className={
                      isLoggedIn
                        ? game["gameUm"] == true
                          ? "desktop-new-games"
                          : ""
                        : ""
                    }
                  >
                    <div
                      className={
                        isLoggedIn
                          ? game["gameUm"] == true
                            ? "game-box gameum cursor-pointer"
                            : "game-box cursor-pointer"
                          : "game-box cursor-pointer"
                      }
                      style={{
                        backgroundImage:
                          (isLoggedIn
                            ? game["gameUm"] == true
                              ? "linear-gradient(rgba(0,0,0,.8), rgba(0,0,0,.8)), "
                              : " "
                            : " ") + `url(${encodeURI(game.image)})`,
                      }}
                      onClick={() => {
                        if (isLoggedIn) {
                          setPopupBannerImage(game["popupBannerImage"]);
                          if (game["gameUm"] !== true) {
                            startGame(
                              game["id"],
                              game["hasOwnLobby"],
                              game["masterProductId"],
                              game["gameName"],
                              game["masterProductIsSeamless"],
                              game["isNewWindow"],
                              game["isApp"],
                              game["categoryTitle"],
                              game["appUrl"],
                              game["appDeepLink"],
                              game["gameCode"],
                              game["parameter_1"]
                            );
                          }
                        }
                        else if (!isLoggedIn && game.hasOwnLobby && !game.masterProductIsSeamless) {
                          _dispatch(
                            showMessage({
                              type: AlertTypes._INFO,
                              content: t("PLEASE_LOGIN_FIRST"),
                              onConfirm: () => {
                                //console.log("hi");
                              },
                            })
                          );
                        }
                        else if (game.hasOwnLobby && !game.masterProductIsSeamless) {
                          _history.push(WebUrl._URL_GAME_LIST.replace(
                            ":provider",
                            game.gameName));
                        }
                        else if (!game.hasOwnLobby) {
                          _dispatch(
                            showMessage({
                              type: AlertTypes._INFO,
                              content: t("PLEASE_LOGIN_FIRST"),
                              onConfirm: () => {
                                //console.log("hi");
                              },
                            })
                          );
                        } else if(game.gameUm !== true && !(comingSoonProductGameCode.includes(game.gameCode) && moment(game.parameter_1).isValid() && moment(game.parameter_1).isAfter(moment()))){
                          _dispatch(
                            showMessage({
                              type: AlertTypes._INFO,
                              content: t("COMING_SOON"),
                              onConfirm: () => {
                                //console.log("hi");
                              },
                            })
                          ); 
                        }
                      }}
                    >
                      {(
                        <div className="tag">
                          <LazyLoadImage
                            src={
                              title == t("NEW_GAMES")
                                ? "/img/new.svg"
                                : title == t("POPULAR_GAMES") ? "/img/hot.svg" : ""
                            }
                            className="img-responsive"
                            style={{
                              opacity: isLoggedIn
                                ? game["gameUm"] == true
                                  ? "0.3"
                                  : "1"
                                : "1",
                            }}
                            alt={game.isNewGame ? "new" : "hot"}
                            width="50"
                            height="50"
                          />
                        </div>
                      )}
                      {/* <img
                        src={game.image}
                        className="img-responsive img-radius"
                        alt={game.text}
                      /> */}
                      {/* <h6 className="text-white">{game.gameName}</h6> */}
                    </div>
                    {isLoggedIn && game["gameUm"] == true ? (
                      <>
                        {/* <div
                          className="v2-um-tag game-category-label"
                        ></div> */}
                        <div
                          style={{
                            backgroundImage: `url(${UMIcon})`,
                          }}
                          className="v2-um-tag-icon"
                        ></div>
                      </>
                    ) : (game.gameCode === 'MEGATOGEL' && moment(game.parameter_1).isValid() && moment(game.parameter_1).isAfter(moment())) ?
                    <div className="coming-soon-icon" style={{ backgroundImage: `url(${CSIcon})` }}></div>: null}
                  </div>
                </div>
                <div className="text-break text-white font-semi font-14 mt-3 mb-4">
                  {game.gameName + (Object.keys(gameList).length > 0 && ((title === t("NEW_GAMES")) || (title === t("POPULAR_GAMES"))) && game.gameName !== game.providerName ? " - " + game.providerName : "")}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

GameListGrid.propTypes = {
  filterList: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  isFilterDropdownVisible: PropTypes.bool.isRequired,
  dropdownFilterOnClick: PropTypes.func.isRequired,
  handleFilterByProvider: PropTypes.func,
  type: PropTypes.string,
  page: PropTypes.string,
  providerId: PropTypes.array,
};

export default GameListGrid;

import React, { useEffect, useState } from "react";
//import "react-simple-keyboard/build/css/index.css";
import Sidebar from "components/sidebar";
import { setBusy, setIdle, showResponseMessage } from "../../redux/AppAction";
import ApiEngine from "../../util/ApiEngine";
import {
  ApiKey,
  ApiUrl,
  WebUrl,
  DEFAULT_PAGE_SIZE,
  SessionKey,
} from "../../util/Constant";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import ContentHeader from "components/content/header";
import { mdiPin } from '@mdi/js';
import Icon from '@mdi/react';

const Notification = (props) => {
  var { t } = useTranslation();
  var _dispatch = useDispatch();
  const [apiQuery, setApiQuery] = useState(
    ApiUrl._API_GET_NOTIFICATION_LIST +
      "?languageCode=" +

      (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
      localStorage.getItem(SessionKey._LANGUAGE) : 'en')

      // localStorage.getItem(SessionKey._LANGUAGE)
  );
  const [showDynamicTable, setShowDynamicTable] = useState(true);
  const [isReadNotification, setIsReadNotification] = useState(false);
  const [notificationLength, setNotificationLength] = useState(false);
  const _SELECTIONS = {
    "Deposit SMS": "DEPOSIT",
    "Withdrawal SMS": "WITHDRAWAL",
    "Reset Password SMS": "RESET_PASSWORD",
    "Phone Verfication SMS": "PHONE_VERIFICATION",
    "PIN Reset": "RESET_PIN",
  };

  async function readAllAnnouncement() {
    _dispatch(setBusy());
    var responseJson = await ApiEngine.post(
      `${ApiUrl._API_MARK_ANNOUNCEMENT_AS_READ}`
    );

    _dispatch(
      showResponseMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        responseJson[ApiKey._API_MESSAGE_KEY]
      )
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setShowDynamicTable(false);
      setTimeout(() => setShowDynamicTable(true), 1);
      setIsReadNotification(true);
      localStorage.setItem('isReadAllNotification', true);
    }
    _dispatch(setIdle());
    }

    useEffect(() => {
    setApiQuery(
      ApiUrl._API_GET_NOTIFICATION_LIST +
      "?languageCode=" +

      (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
      localStorage.getItem(SessionKey._LANGUAGE) : 'en'));

      // localStorage.getItem(SessionKey._LANGUAGE));
    setShowDynamicTable(false);
    setTimeout(() => setShowDynamicTable(true), 1);
    getDataList();
  }, [(localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
        localStorage.getItem(SessionKey._LANGUAGE) : 'en')]);

    async function getDataList() {
        let responseJson = await ApiEngine.get(apiQuery);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            
            if (responseJson[ApiKey._API_DATA_KEY].totalCount > 0) {
                setNotificationLength(true);
            }
        }
    }
  return (
    <>
      <section className="section-padding" id="notification-section">
        <div className="container">
          <div className="row">
            <div className="col-3" id="account-menu">
              <Sidebar activePage={"NOTIFICATION"} isRead = {isReadNotification}/>
            </div>
            <div className="col-9">
              <ContentHeader
                title={t("NOTIFICATION")}
                // escButtonOnClick={() => window.history.go(-1)}
               />
               <hr className="right-title-hr" />
              {notificationLength && 
                <div className="text-right mb-3">
                    <a
                        href="#"
                        onClick={() => readAllAnnouncement()}
                        className="font-14 text-gold font-semi"
                    >
                        {t("MARK_ALL_READ")}
                    </a>
                </div>
              }
              {showDynamicTable && (
                <div className="ul-news-list">
                  <DynamicLoadTable
                    pageSize={DEFAULT_PAGE_SIZE}
                    apiQuery={apiQuery}
                    searchEnabled={true}
                    loadingColor={"white"}
                    render={(announcement, index) => {
                      return (
                        <div
                          key={index}
                          className="template-box mb-4"
                          id="notif-box"
                          >
                          <Link
                            to={{
                              pathname: WebUrl._URL_NOTIFICATION_DETAIL,
                              state: { data: JSON.stringify(announcement) },
                            }}
                          >
                            {!announcement["isRead"] ? (
                              <div className="text-white position-relative notification-box">
                                <h4 className="font-20 text-gold mb-2 display-flex">
                                  <span>{announcement["title"] == "Deposit SMS" ||
                                  announcement["title"] == "Withdrawal SMS"
                                    ? t(_SELECTIONS[announcement["title"]])
                                    : announcement["title"]}</span>{/*<div className="ml-3">{announcement["pinOnTop"] && <Icon path={mdiPin} size={0.8} color="white" />}</div>*/}
                                </h4>
                                <p
                                  className="brief-annoucement font-15 mb-2"
                                  dangerouslySetInnerHTML={{
                                    __html: announcement["description"],
                                  }}
                                ></p>
                                <p className="font-12 mb-0">
                                  {moment(announcement["date"]).format(
                                    "DD-MM-YYYY HH:mm:ss"
                                  )}
                                </p>
                                <span className="circle-orange"></span>
                              </div>
                            ) : (
                              <div className="text-white position-relative notification-box">
                                <h4 className="font-20 text mb-2 display-flex">
                                  <span>{announcement["title"] == "Deposit SMS" ||
                                  announcement["title"] == "Withdrawal SMS"
                                    ? t(_SELECTIONS[announcement["title"]])
                                    : announcement["title"]}</span>{/*<div className="ml-3">{announcement["pinOnTop"] && <Icon path={mdiPin} size={0.8} color="white"/>}</div>*/}
                                </h4>
                                <p
                                  className="brief-annoucement font-15 mb-2"
                                  dangerouslySetInnerHTML={{
                                    __html: announcement["description"],
                                  }}
                                ></p>
                                <p className="font-12 mb-0">
                                  {moment(announcement["date"]).format(
                                    "DD-MM-YYYY HH:mm:ss"
                                  )}
                                </p>
                              </div>
                            )}
                          </Link>
                        </div>
                      );
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Notification;

import React, { useState, useEffect } from "react";
//import "react-simple-keyboard/build/css/index.css";
import Sidebar from "components/sidebar";
import moment from "moment";
import {
  ApiKey,
  ApiUrl,
  LanguageKey,
  SessionKey,
  _TICKET_STATUS,
} from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine";
import { stringIsNullOrEmpty, numberWithCurrencyFormat } from "../../util/Util";
import { useTranslation } from "react-i18next";
import StyledSelect from "../../components/custom/StyledSelect";
import { useForm, Controller } from "react-hook-form";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";
import ContentHeader from "components/content/header";
import DateRangeFilter from "../../components/custom/DateRangeFilter";
import { map } from "lodash";

const GameRecord = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const [gameRecord, setGameRecord] = useState([]);
  const [startDate, setStartDate] = useState(
    moment().startOf("week").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("week").format("YYYY-MM-DD")
  );
  const [products, setProducts] = useState([]);
  const [ticketStatus, setTicketStatus] = useState([]);
  const { control, watch, setValue } = useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [gameDetails, setGameDetails] = useState({});
  const [recordUrl, setRecordUrl] = useState("");
  const [selectedDate, setSelectedDate] = useState(moment());
  const [queryStatus, setQueryStatus] = useState("1");
  const [queryProduct, setQueryProduct] = useState("");
  const [searchTrigger, setSearchTrigger] = useState(Date.now());
  const [isGrouped, setIsGrouped] = useState(false);

  useEffect(() => {
    init();
    getGameRecords(startDate, endDate);
  }, [(localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
  localStorage.getItem(SessionKey._LANGUAGE) : 'en')]);

  async function init() {
    var responseJson = await ApiEngine.get(
      `${ApiUrl._API_GET_ASSIGNED_MEMBER_PRODUCT}?ignoreAccountProductStatus=true`
    );

    const productList = [
      {
        label: t("ALL"),
        value: null,
      },
    ];

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      responseJson[ApiKey._API_DATA_KEY].products.forEach(function (product) {
        productList.push({
          label: product.productName,
          value: product.productId,
        });
      });
    }

    setProducts(productList);
    setTicketStatus(
      map(_TICKET_STATUS, (item) => {
        return {
          ...item,
          label: t(item.label),
        };
      })
    );
  }

  async function getGameRecords(startDate, endDate, selected = "") {
    let apiUrl =
      ApiUrl._API_GET_GAME_RECORD_BY_MEMBER +
      "?startDate=" +
      moment(startDate).format("YYYY-MM-DD") +
      " 00:00:00" +
      "&endDate=" +
      moment(endDate).format("YYYY-MM-DD") +
      " 23:59:59" +
      "&v=" +
      Date.now() +
      "&GroupDateRequired=true";

    if (!stringIsNullOrEmpty(queryProduct)) {
      apiUrl += "&productId=" + queryProduct;
    }

    if (!stringIsNullOrEmpty(queryStatus)) {
      apiUrl += "&ticketStatus=" + queryStatus;
    }

    if (!stringIsNullOrEmpty(selected)) {
      apiUrl += "&SelectedDate=" + moment(selected).format("YYYY-MM-DD");
    }

    setValue(
      "productId",
      stringIsNullOrEmpty(queryProduct) ? null : queryProduct
    );
    setValue(
      "ticketStatus",
      stringIsNullOrEmpty(queryStatus) ? null : queryStatus
    );
    setRecordUrl(apiUrl);
  }

  function showGameDetails(data) {
    setGameDetails(data);
    setIsModalVisible(true);
  }

  useEffect(() => {
    getGameRecords(startDate, endDate);
  }, [searchTrigger]);

  useEffect(() => {
    setIsGrouped(
      !moment(endDate, "YYYY-MM-DD").isSame(
        moment(startDate, "YYYY-MM-DD"),
        "day"
      ) && stringIsNullOrEmpty(selectedDate)
    );

    if (!stringIsNullOrEmpty(selectedDate)) {
      getGameRecords(startDate, endDate, selectedDate);
    }
  }, [selectedDate, searchTrigger]);

  function getRecordFromDynamicLoad(data) {
    setGameRecord(data);
  }

  return (
    <div id="game-record">
      <section className="section-padding" id="game-record-section">
        <div className="container">
          <div className="row">
            <div className="col-3" id="account-menu">
              <Sidebar activePage={"REPORTS"} />
            </div>
            <div className="col-9">
              <ContentHeader enableBackButton title={t("GAME_RECORD")} />
              <hr className="right-title-hr" />
              <div className="template-box mt-3" id="game-record-box">
                <DateRangeFilter
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  onSave={(start, end) => {
                    setQueryProduct(watch()["productId"]);
                    setQueryStatus(watch()["ticketStatus"]);
                    setSelectedDate("");
                    setSearchTrigger(Date.now());
                  }}
                  options={true}
                />
                <div className="form-row">
                  <div className="col">
                    <label className="col-form-label">
                      <b>{t("GAME")}</b>
                    </label>
                    <div className="form-group p-0">
                      <Controller
                        control={control}
                        name="productId"
                        render={({ onChange, value }) => {
                          return (
                            <StyledSelect
                              options={products}
                              value={value}
                              onChange={(e) => {
                                onChange(e.value);
                              }}
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label className="col-form-label">
                      <b>{t("TICKET_STATUS")}</b>
                    </label>
                    <div className="form-group p-0">
                      <Controller
                        control={control}
                        name="ticketStatus"
                        render={({ onChange, value }) => {
                          return (
                            <StyledSelect
                              options={ticketStatus}
                              value={value}
                              onChange={(e) => {
                                onChange(e.value);
                              }}
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="card card-package">
                  <div className="card-body p-0" style={{ overflowX: "auto" }}>
                    <table className="table reportTable">
                      <thead>
                        <tr>
                          <th style={{ textAlign: "left" }}>{t("DATE")}</th>
                          <th style={{ textAlign: "center" }}>{t("GAME")}</th>
                          <th style={{ textAlign: "center" }}>
                            {t("TICKET_ID")}
                          </th>
                          <th style={{ textAlign: "center" }}>{t("STATUS")}</th>
                          <th style={{ textAlign: "right" }}>
                            {t("VTURNOVER")}
                          </th>
                          <th style={{ textAlign: "right" }}>{t("AMOUNT")}</th>
                          <th style={{ textAlign: "right" }}>
                            {t("NET_PROFIT")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <DynamicLoadTable
                          returnRecord={getRecordFromDynamicLoad}
                          pageSize={20}
                          apiQuery={recordUrl}
                          isTable={true}
                          searchEnabled={true}
                          render={(item, i) => {
                            var style = {
                              color:
                                parseFloat(item["winAmount"]) -
                                  parseFloat(item["amount"]) >
                                0
                                  ? "green"
                                  : "red",
                            };
                            style["textAlign"] = "right";

                            return (
                              <tr
                                key={"game_record_" + i}
                                style={{
                                  borderBottom: "0.5px solid lightgray",
                                }}
                              >
                                <td
                                  style={{ textAlign: "left" }}
                                  className={isGrouped ? "hoverable" : ""}
                                  onClick={() => {
                                    if (isGrouped) {
                                      setSelectedDate(item["date"]);
                                    }
                                  }}
                                >
                                  <a className="cursor-pointer">
                                    {item["date"]}
                                  </a>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {!stringIsNullOrEmpty(item["gameName"]) &&
                                  item["gameName"] != "ALL"
                                    ? t(item["gameName"])
                                    : t(item["productName"])}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {item["id"]}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {t(item["statusString"].toUpperCase())}
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  {numberWithCurrencyFormat(
                                    parseFloat(item["validTurnover"])
                                  )}
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  {numberWithCurrencyFormat(
                                    parseFloat(item["amount"])
                                  )}
                                </td>
                                <td style={style}>
                                  {isGrouped
                                    ? numberWithCurrencyFormat(
                                        parseFloat(item["winAmount"]) -
                                          parseFloat(item["amount"])
                                      )
                                    : item["status"] == 1
                                    ? numberWithCurrencyFormat(
                                        parseFloat(item["winAmount"]) -
                                          parseFloat(item["amount"])
                                      )
                                    : "0.00"}
                                </td>
                              </tr>
                            );
                          }}
                        />
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan="4">
                            <b>{t("TOTAL")}</b>
                          </td>
                          <td style={{ textAlign: "right" }}>
                            <b>
                              {numberWithCurrencyFormat(
                                parseFloat(
                                  gameRecord.reduce(
                                    (totalvt, data) =>
                                      totalvt + data.validTurnover,
                                    0
                                  )
                                )
                              )}
                            </b>
                          </td>
                          <td style={{ textAlign: "right" }}>
                            <b>
                              {numberWithCurrencyFormat(
                                parseFloat(
                                  gameRecord.reduce(
                                    (totala, data) => totala + data.amount,
                                    0
                                  )
                                )
                              )}
                            </b>
                          </td>
                          <td style={{ textAlign: "right" }}>
                            <b>
                              {numberWithCurrencyFormat(
                                parseFloat(
                                  gameRecord.reduce(
                                    (totalp, data) =>
                                      totalp +
                                      (isGrouped
                                        ? data.winAmount - data.amount
                                        : data.status == 1
                                        ? data.winAmount - data.amount
                                        : 0),
                                    0
                                  )
                                )
                              )}
                            </b>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <Modal
                  isOpen={isModalVisible}
                  toggle={() => {
                    setIsModalVisible(false);
                  }}
                  centered
                >
                  <ModalHeader
                    toggle={() => {
                      setIsModalVisible(false);
                    }}
                  >
                    {t("GAME_DETAILS")}
                  </ModalHeader>
                  <ModalBody className="game-detail-wrapper">
                    <div className="game-detail-head">
                      <strong className="text-brand f-s-25">
                        {gameDetails["betId"]}
                      </strong>
                      <div className="text-right">
                        <span className="badge badge-brand">
                          {gameDetails["statusString"]}
                        </span>
                        <div className="game-detail-timestamp">
                          {gameDetails["dateStr"]}
                        </div>
                      </div>
                    </div>
                    <div className="game-detail-list">
                      <label>{t("AMOUNT")}</label>
                      <div>
                        {numberWithCurrencyFormat(gameDetails["amount"])}
                      </div>
                    </div>
                    <div className="game-detail-list">
                      <label>{t("NET_PROFIT")}</label>
                      <div>
                        {numberWithCurrencyFormat(
                          parseFloat(gameDetails["winAmount"]) -
                            parseFloat(gameDetails["amount"])
                        )}
                      </div>
                    </div>
                    <div className="game-detail-list">
                      <label>{t("DESCRIPTION")}</label>
                      <pre className="game-detail-desc">
                        {gameDetails["remark"]}
                      </pre>
                    </div>
                  </ModalBody>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default GameRecord;

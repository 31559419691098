import React, { useState, useEffect } from "react";
import "react-simple-keyboard/build/css/index.css";
import Sidebar from "components/sidebar";
import { map, filter } from "lodash";
import { rewardsFilter } from "../../config/game-configs";
import Button from "components/button";
import ContentHeader from "components/content/header";
import DayRangeFilter from "../../components/custom/DayRangeFilter";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { ApiKey, ApiUrl, LanguageKey, SessionKey, WebUrl } from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine";
import { useDispatch } from "react-redux";
import {
  showResponseMessage,
  setBusy,
  setIdle,
} from "../../redux/AppAction.js";
import { useForm } from "react-hook-form";
import { createMultiPartFormBody, numberWithCurrencyFormat, numberWithCurrencyFormat2 } from "../../util/Util";
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { keys, first, last } from 'lodash';
import { updateUserData } from "../../redux/AuthAction";

/// <summary>
/// Author: - 
/// </summary>
const Rewards = (props) => {
  var _history = useHistory();
  var _dispatch = useDispatch();
  var _location = useLocation();
  var { t } = useTranslation(LanguageKey._PRIMARY);
  var selectedLanguage = localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
    localStorage.getItem(SessionKey._LANGUAGE) : 'en';

  const { handleSubmit, control, watch, setValue } = useForm();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [animating, setAnimating] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [bannerData, setBannerData] = useState([]);
  const [gameCategories, setGameCategories] = useState([]);
  const [currentDateTime, setCurrentDateTime] = useState(moment().startOf('day'));
  const [selectedDate, setSelectedDate] = useState(moment().add(-1, 'days'));
  const [rewardsData, setRewardData] = useState([]);
  const [claimData, setClaimData] = useState([]);
  const [activeKeyList, setActiveKeyList] = useState([]);
  const [dropdownDataList, setDropdownDataList] = useState([]);
  const [showPageData, setShowPageData] = useState(false);
  const [isClaimAll, setIsClaimAll] = useState(false);
  const [scheduleData, setScheduleData] = useState([]);
  const [dateList, setDateList] = useState([]);
  const activeTheme = useSelector(
    (state) => state.authState.userData.selectedTheme
  );
  const [grandTotalRewards, setGrandTotalRewards] = useState(0);
  const [gameCategoryData, setGameCategoryData] = useState([]);
  ;
  const [tabIndex, setTabIndex] = useState(0);
  const [activeCatTitle, setActiveCatTitle] = useState("All");
  const _DAY_OF_WEEK = [
    { label: t("MON_L"), value: 1 },
    { label: t("TUE_L"), value: 2 },
    { label: t("WED_L"), value: 3 },
    { label: t("THU_L"), value: 4 },
    { label: t("FRI_L"), value: 5 },
    { label: t("SAT_L"), value: 6 },
    { label: t("SUN_L"), value: 0 },
  ];

  const DEFAULT_CAROUSEL_ITEMS = [
    {
      src: "/img/banner.png",
      altText: "",
      caption: "",
      header: "",
      key: "1",
    },
  ];

  /// <summary>
  /// Author : -
  /// </summary>
  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === bannerData.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? bannerData.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const setActiveCategory = (category, index) => {
    setActiveCatTitle(category['originalTitle']);
    setTabIndex(index);
  }

  async function init() {
    _dispatch(setBusy());
    apiGetUserRewardBanner();
    apiGetProductCategoryDesktop();
    apiGetCategoryRewardRates();
    _dispatch(setIdle());
  }

  async function apiGetUserRewardBanner() {
    var responseJson = await ApiEngine.get(ApiUrl._API_GET_USER_REWARD_BANNER);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setBannerData(responseJson[ApiKey._API_DATA_KEY]);
    } else {
      _dispatch(
        showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
      );
    }
  }

  async function apiGetProductCategoryDesktop() {
    let categoryData = [];

    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_PRODUCT_CATEGORY_DESKTOP +
      "?selectedLanguage=" +
      selectedLanguage
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      categoryData = responseJson[ApiKey._API_DATA_KEY].filter(x => x['title'] !== "V.Sports");
      let tempActiveKeyList = [];
      categoryData.map((category, index) => {
        tempActiveKeyList.push({ id: category.id, active: false });
      });
      setActiveKeyList(tempActiveKeyList);
      setGameCategories(categoryData);
    } else {
      _dispatch(
        showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
      );
    }
  }

  async function apiGetCategoryRewardRates() {
    setShowPageData(false);
    var responseJson = await ApiEngine.get(ApiUrl._API_GET_REWARDS_SCHEDULES);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let tempScheduleData = responseJson[ApiKey._API_DATA_KEY];
      let tempSchDataObj = {};
      tempScheduleData.map((schData) => {
        if (!tempSchDataObj[schData["date"]]) {
          tempSchDataObj[schData["date"]] = {};
        }
        if (!tempSchDataObj[schData["date"]][schData["productName"]]) {
          tempSchDataObj[schData["date"]][schData["productName"]] = { rate: schData["comm"], productImage: schData["productImage"], categoryTitle: schData["categoryTitle"] };
        }
      })
      let tempDateList = Object.keys(tempSchDataObj).sort();
      setDateList(tempDateList);
      setScheduleData(tempSchDataObj);
      responseJson = await ApiEngine.get(
        ApiUrl._API_GET_CATEGORY_REWARD_RATES + "?all=true"
      );

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        let data = responseJson[ApiKey._API_DATA_KEY];
        let tempObj = {};
        let grandTotalReward = 0;
        let categoryTotal = {};
        data.map((rewardData, index) => {
          if (!tempObj[rewardData["date"]]) {
            tempObj[rewardData["date"]] = { totalAmount: 0, totalAmountClaimable: 0 };
          }
          if (!tempObj[rewardData["date"]][rewardData["categoryTitle"]]) {
            tempObj[rewardData["date"]][rewardData["categoryTitle"]] = { totalAmount: 0, totalAmountClaimable: 0 };
          }
          if (!tempObj[rewardData["date"]][rewardData["categoryTitle"]][rewardData["providerName"]]) {
            tempObj[rewardData["date"]][rewardData["categoryTitle"]][rewardData["providerName"]] = { productImage: rewardData["productImage"], rate: rewardData["rewardRate"] };
          }
          tempObj[rewardData["date"]].totalAmount += rewardData["amount"];
          tempObj[rewardData["date"]][rewardData["categoryTitle"]].totalAmount += rewardData["amount"];
          if (rewardData["claimed"] !== "True") {
            tempObj[rewardData["date"]].totalAmountClaimable += rewardData["amount"];
            tempObj[rewardData["date"]][rewardData["categoryTitle"]].totalAmountClaimable += rewardData["amount"];
            grandTotalReward += rewardData["amount"];

            if (!categoryTotal[rewardData["categoryTitle"]]) {
              categoryTotal[rewardData["categoryTitle"]] =
              {
                category: rewardData["categoryTitle"],
                totalAmount: rewardData["amount"]
              };
            }
            else if (categoryTotal[rewardData["categoryTitle"]]) {
              categoryTotal[rewardData["categoryTitle"]].totalAmount += rewardData["amount"];
            }
            else if (categoryTotal[rewardData["categoryTitle"]]) {
              categoryTotal[rewardData["categoryTitle"]] =
              {
                category: rewardData["categoryTitle"],
                totalAmount: rewardData["amount"]
              };
            }
          }

        });
        let temp = Object.keys(tempObj).sort();
        var chosenDate = moment().startOf('day');
        for (var i = 0; i < temp.length; i++) {
          if (data.filter(x => x.date === temp[i] && x.claimed === "False").length > 0) {
            chosenDate = moment(temp[i]);
            break; //exit for loop
          }
        }
        setSelectedDate(chosenDate);
        setRewardData(tempObj);
        setGrandTotalRewards(grandTotalReward);
        setGameCategoryData(categoryTotal);
      } else {
        _dispatch(
          showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
        );
      }
    }
    setShowPageData(true);
  }

  useEffect(() => {
    init();
  }, [(localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
    localStorage.getItem(SessionKey._LANGUAGE) : 'en')]);

  /// <summary>
  /// Author : -
  /// </summary>
  function preSubmit(all) {
    setIsClaimAll(all);

    setShowConfirmation(true);
  }

  /// <summary>
  /// Author: -
  /// </summary>
  async function submitForm() {
    _dispatch(setBusy());
    let responseJson = await ApiEngine.post(
      ApiUrl._API_CLAIM_REWARD,
      createMultiPartFormBody({
        date: selectedDate.clone().format("YYYY-MM-DD"),
        category: activeCatTitle,
        all: isClaimAll
      })
    );

    _dispatch(
      showResponseMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        responseJson[ApiKey._API_MESSAGE_KEY]
      )
    );
    _dispatch(setIdle());

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      _dispatch(updateUserData());
      init();
    }
  }

  const handleSelectedDate = (days) => {
    _dispatch(setBusy());

    let tempSelectedDate = selectedDate.clone().add(days, "days");
    if (
      tempSelectedDate.isSameOrBefore(currentDateTime.clone().add(1, "weeks")) &&
      tempSelectedDate.isSameOrAfter(currentDateTime.clone().add(-1, "weeks")) &&
      tempSelectedDate.isSameOrAfter(moment(dateList[0]).startOf('day')) &&
      tempSelectedDate.isSameOrBefore(moment(dateList[dateList.length - 1]).startOf('day'))
    ) {
      setSelectedDate(tempSelectedDate);
    }
    _dispatch(setIdle());
  };

  return (
    <div id="rewards">
      {showConfirmation && (
        <SweetAlert
          info
          showCancel
          confirmBtnText={t("CONFIRM")}
          cancelBtnText={t("CANCEL")}
          confirmBtnBsStyle="info"
          title={t("CONFIRM_CLAIM")}
          onConfirm={() => {
            setShowConfirmation(false);
            handleSubmit(submitForm)();
          }}
          onCancel={() => {
            setShowConfirmation(false);
          }}
        ></SweetAlert>
      )}

      <section className="section-padding" id="rewards-section">
        <div className="container">
          <div className="row">
            <div className="col-3" id="account-menu">
              <Sidebar activePage={"REWARDS"} />
            </div>
            <div className="col-9">
              <ContentHeader enableBackButton={ _location.state && _location.state.from ? true: false }title={t("REWARDS")} />
              <div className="reward-divider"></div>
              <div className="reward-card">
                <div
                  className="first mb-2 reward-card-info"
                  id="rewardinfo "
                  data-toggle="dropdown"
                  aria-expanded="true"
                  aria-haspopup="true"
                >
                  <div className="text-white"  >
                    <h4 className="mb-2">{t("GRAND_TOTAL_REWARD_CLAIMABLE")}</h4>


                  </div>
                  <div className="text-white"   >
                    <p className="font-14 mb-0" style={{ float: "left" }}
                    >
                      {" "}
                      {numberWithCurrencyFormat2(grandTotalRewards, 2, true)}
                    </p>
                    <div className="reward-button" style={{ float: "right", position: "absolute", left: "24%" }}>
                      <img
                        src={"/img/circle-up.svg"}
                        className="img-responsive img1"
                        alt="reward"
                      />
                      <img
                        src={"/img/circle-down.svg"}
                        className="img-responsive img2"
                        alt="reward"
                      />
                      <div className="tooltip-text2">
                        {t("CLICK_TO") +
                          " " +
                          t("VIEW_REWARDS")}
                      </div>
                    </div>

                  </div>
                </div>
                {/*Grand total reward claim*/}
                <div className="button-wrpper" style={{ paddingTop: "10px", width: "50%" }}>
                  <div className="second pt-3">
                    <div>
                      {rewardsData && grandTotalRewards > 0 && <button
                        className="green-btn small-btn"
                        disabled={!rewardsData || grandTotalRewards === 0 || numberWithCurrencyFormat2(grandTotalRewards, 2, true) == "0.00"}
                        onClick={() => rewardsData && rewardsData[selectedDate.format('YYYY-MM-DD')]['totalAmountClaimable'] !== 0 && numberWithCurrencyFormat2(grandTotalRewards, 2, true) != "0.00" && preSubmit()}
                      >
                        {t("CLAIM")}
                      </button>}
                    </div>
                  </div>
                </div>
                <div className=" dropdown-menu main-wallet-card font-15 reward-info-card " id="mw-info-card" aria-labelledby="mainwalletinfo" >
                  <div className="container">
                    <p style={{ paddingTop: "5%" }}>{t("REWARD_INFO")}</p>
                    <hr className="walltet-info-divider" />

                    {!gameCategoryData && (
                      <div
                        style={{
                          textAlign: "center",
                          marginTop: "25px",
                          marginBottom: "20px",
                        }}
                      >
                        <Spinner
                          animation="border"
                          role="status"
                          size="sm"
                          style={{
                            verticalAlign: "middle",
                            width: "2rem",
                            height: "2rem",
                          }}
                        >
                          <span className="sr-only">{t("LOADING")}...</span>
                        </Spinner>
                      </div>
                    )}
                    {gameCategories.filter(x => x.originalTitle !== "All" && x.originalTitle !== "Virtual-Sports" && x.originalTitle !== "Fish Games" && x.originalTitle !== "RNG").map((category, index) => {
                      return (
                        <div key={index} className="row wallet-info">
                          <p className="text-brand col-6 col-form-label">{t(category.originalTitle)}</p>
                          <p className="text-brand col-6 col-form-label text-right">
                            {
                              category.originalTitle == "All" ? numberWithCurrencyFormat2(grandTotalRewards, 2, true) :
                                numberWithCurrencyFormat2(gameCategoryData[category.originalTitle] ? gameCategoryData[category.originalTitle]['totalAmount'] : "0", 2, true)}
                          </p>
                        </div>
                      )
                    })}
                  </div>

                </div>

              </div>
              <div className="mt-5">
                {showPageData ? <>
                  {(
                    <ul className="nav nav-tabs mt-5" id="my-tabs" >
                      {gameCategories.map((category, catIndex) => {
                        return (
                          <li key={catIndex}>
                            <a
                              data-toggle="tab"
                              onClick={() => setActiveCategory(category, catIndex)}
                              href={`#reward${catIndex + 1}`}
                              className={
                                catIndex == tabIndex ? "font-13 active" : "font-13"
                              }
                              key={catIndex}
                            >
                              {t(category.originalTitle)}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                  <div className="template-box mt-3">
                    <div className="reward-box font-11 second text-white ">
                      <div style={{ color: "#ffc159" }}>
                        <span className="font-14 font-semi">
                          {t("EARNED")}:
                        </span>
                        <span className="font-15 font-semi ml-2">
                          {" "}
                          {numberWithCurrencyFormat2(activeCatTitle !== "All" && Object.keys(rewardsData).length > 0 && Object.keys(rewardsData).filter(x => x === selectedDate.format('YYYY-MM-DD')).length > 0 && Object.keys(rewardsData[selectedDate.format('YYYY-MM-DD')]).filter(x => x === activeCatTitle).length > 0
                            ? rewardsData[selectedDate.format('YYYY-MM-DD')][activeCatTitle]['totalAmount']
                            : activeCatTitle === "All" && Object.keys(rewardsData).length > 0 && Object.keys(rewardsData).filter(x => x === selectedDate.format('YYYY-MM-DD')).length > 0 ? rewardsData[selectedDate.format('YYYY-MM-DD')]['totalAmount'] : "0", 2, true)}
                        </span>
                      </div>
                      <div style={{ color: "#ffc159" }}>
                        <span className="font-14 font-semi">
                          {t("CLAIMABLE")}:
                        </span>
                        <span className="font-15 font-semi ml-2">
                          {" "}
                          {numberWithCurrencyFormat2(activeCatTitle !== "All" && Object.keys(rewardsData).length > 0 && Object.keys(rewardsData).filter(x => x === selectedDate.format('YYYY-MM-DD')).length > 0 && Object.keys(rewardsData[selectedDate.format('YYYY-MM-DD')]).filter(x => x === activeCatTitle).length > 0
                            ? rewardsData[selectedDate.format('YYYY-MM-DD')][activeCatTitle]['totalAmountClaimable']
                            : activeCatTitle === "All" && Object.keys(rewardsData).length > 0 && Object.keys(rewardsData).filter(x => x === selectedDate.format('YYYY-MM-DD')).length > 0 ? rewardsData[selectedDate.format('YYYY-MM-DD')]['totalAmountClaimable'] : "0", 2, true)}
                        </span>
                      </div>
                      <div>
                        {
                          (
                            (
                              activeCatTitle === "All" &&
                              Object.keys(rewardsData).length > 0 &&
                              Object.keys(rewardsData).filter(x => x === selectedDate.format('YYYY-MM-DD')).length > 0 &&
                              rewardsData[selectedDate.format('YYYY-MM-DD')]['totalAmountClaimable'] > 0 &&
                              numberWithCurrencyFormat2(rewardsData[selectedDate.format('YYYY-MM-DD')]['totalAmountClaimable'], 2, true) != "0.00"
                            )
                            ||
                            (
                              Object.keys(rewardsData).length > 0 &&
                              Object.keys(rewardsData).filter(x => x === selectedDate.format('YYYY-MM-DD')).length > 0 &&
                              Object.keys(rewardsData[selectedDate.format('YYYY-MM-DD')]).filter(x => x === activeCatTitle).length > 0 &&
                              rewardsData[selectedDate.format('YYYY-MM-DD')][activeCatTitle]['totalAmountClaimable'] > 0 &&
                              numberWithCurrencyFormat2(rewardsData[selectedDate.format('YYYY-MM-DD')][activeCatTitle]['totalAmountClaimable'], 2, true) != "0.00"
                            )
                          ) &&
                          <button
                            className="green-btn small-btn"
                            disabled={
                              (activeCatTitle === "All" &&
                                Object.keys(rewardsData).filter(x => x === selectedDate.format('YYYY-MM-DD')).length > 0 &&
                                rewardsData[selectedDate.format('YYYY-MM-DD')]['totalAmountClaimable'] === 0 &&
                                numberWithCurrencyFormat2(rewardsData[selectedDate.format('YYYY-MM-DD')]['totalAmountClaimable'], 2, true) == "0.00")
                              ||
                              (activeCatTitle !== "All" &&
                                (!rewardsData
                                  ||
                                  (
                                    Object.keys(rewardsData).length > 0 &&
                                    Object.keys(rewardsData).filter(x => x === selectedDate.format('YYYY-MM-DD')).length > 0 &&
                                    Object.keys(rewardsData[selectedDate.format('YYYY-MM-DD')]).filter(x => x === activeCatTitle).length > 0 &&
                                    rewardsData[selectedDate.format('YYYY-MM-DD')][activeCatTitle]['totalAmountClaimable'] === 0 **
                                    numberWithCurrencyFormat2(rewardsData[selectedDate.format('YYYY-MM-DD')][activeCatTitle]['totalAmountClaimable'], 2, true) == "0.00")
                                )
                              )
                            }
                            onClick={() => (
                              (
                                activeCatTitle === "All" &&
                                Object.keys(rewardsData).length > 0 &&
                                Object.keys(rewardsData).filter(x => x === selectedDate.format('YYYY-MM-DD')).length > 0 &&
                                rewardsData[selectedDate.format('YYYY-MM-DD')]['totalAmountClaimable'] > 0 &&
                                numberWithCurrencyFormat2(rewardsData[selectedDate.format('YYYY-MM-DD')]['totalAmountClaimable'], 2, true) != "0.00"
                              )
                              ||
                              (
                                activeCatTitle !== "All" &&
                                Object.keys(rewardsData).length > 0 &&
                                Object.keys(rewardsData).filter(x => x === selectedDate.format('YYYY-MM-DD')).length > 0 &&
                                Object.keys(rewardsData[selectedDate.format('YYYY-MM-DD')]).filter(x => x === activeCatTitle).length > 0 &&
                                rewardsData[selectedDate.format('YYYY-MM-DD')][activeCatTitle]['totalAmountClaimable'] > 0 &&
                                numberWithCurrencyFormat2(rewardsData[selectedDate.format('YYYY-MM-DD')][activeCatTitle]['totalAmountClaimable'], 2, true) != "0.00"
                              )
                            ) && preSubmit(false)
                            }
                          >
                            {t("CLAIM")}
                          </button>
                        }
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="reward-note text-white font-12 font-bold justify-content-start">
                        {t("NOTICE_REWARDS", { startHour: 15, endHour: 16 })}
                      </div>

                      <div className="reward-tnc font-12 font-bold justify-content-end cursor-pointer"
                        onClick={() => _history.push(WebUrl._URL_REWARDS_TNC)}
                      >
                        {t("REWARDS_TNC")}
                      </div>
                    </div>
                    <div className="rewards-calendar card card-package">
                      <div className="card-body p-0">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div className="rewards-calendar-week font-14" onClick={() => handleSelectedDate(-7)}
                              // style={{
                              //     cursor: selectedDate.clone().add(-7, 'days').isBefore(currentDateTime.clone().add(-2, 'weeks')) ? "not-allowed" : "pointer",
                              //     color: selectedDate.clone().add(-7, 'days').isBefore(currentDateTime.clone().add(-2, 'weeks')) ? "grey" : "white"
                              // }}

                              style={{
                                cursor: (selectedDate
                                  .clone()
                                  .add(-7, "days")
                                  .isBefore(currentDateTime.clone().add(-1, "weeks"))
                                  || selectedDate
                                    .clone()
                                    .add(-7, "days")
                                    .isBefore(moment(dateList[0]).startOf('day')))
                                  ? "not-allowed"
                                  : "pointer",
                                color: (selectedDate
                                  .clone()
                                  .add(-7, "days")
                                  .isBefore(currentDateTime.clone().add(-1, "weeks"))
                                  || selectedDate
                                    .clone()
                                    .add(-7, "days")
                                    .isBefore(moment(dateList[0]).startOf('day')))
                                  ? "grey"
                                  : "white",
                              }}
                            >
                              {t("LAST_WEEK")}
                            </div>
                            <div className="rewards-calendar-month text-white font-20 font-bold">{t(selectedDate.format("MMMM"))}</div>
                            <div className="rewards-calendar-week font-14" onClick={() => handleSelectedDate(7)}

                              // style={{
                              //     cursor: selectedDate.clone().add(7, 'days').isAfter(currentDateTime.clone().add(2, 'weeks')) ? "not-allowed" : "pointer",
                              //     color: selectedDate.clone().add(7, 'days').isAfter(currentDateTime.clone().add(2, 'weeks')) ? "grey" : "white"
                              // }}

                              style={{
                                cursor: selectedDate
                                  .clone()
                                  .add(7, "days")
                                  .isAfter(currentDateTime.clone().add(1, "weeks")
                                    || selectedDate
                                      .clone()
                                      .add(7, "days")
                                      .isAfter(moment(dateList[dateList.length - 1]).startOf('day')))
                                  ? "not-allowed"
                                  : "pointer",
                                color: selectedDate
                                  .clone()
                                  .add(7, "days")
                                  .isAfter(currentDateTime.clone().add(1, "weeks")
                                    || selectedDate
                                      .clone()
                                      .add(7, "days")
                                      .isAfter(moment(dateList[dateList.length - 1]).startOf('day')))
                                  ? "grey"
                                  : "white",
                              }}
                            >
                              {t("NEXT_WEEK")}
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "10px 0px 10px 0px",
                            }}
                          >
                            <div
                              className="rewards-calendar-day font-bold font-18" //selectedDay - 3 days
                              style={{
                                cursor:
                                  selectedDate
                                    .clone()
                                    .add(-3, "days")
                                    .isBefore(
                                      currentDateTime.clone().add(-1, "weeks")
                                    ) || selectedDate
                                      .clone()
                                      .add(-3, "days")
                                      .isBefore(moment(dateList[0]).startOf('day'))
                                    ? "not-allowed"
                                    : "pointer",
                                color:
                                  selectedDate
                                    .clone()
                                    .add(-3, "days")
                                    .isBefore(
                                      currentDateTime.clone().add(-1, "weeks")
                                    ) || selectedDate
                                      .clone()
                                      .add(-3, "days")
                                      .isBefore(moment(dateList[0]).startOf('day'))
                                    ? "grey"
                                    : "white",
                              }}
                              onClick={() => handleSelectedDate(-3)}
                            >
                              <div>
                                {
                                  _DAY_OF_WEEK.find(
                                    (x) =>
                                      x.value ===
                                      (7 + (selectedDate.day() - 3)) % 7
                                  ).label
                                }
                              </div>
                              <div>
                                {moment(selectedDate)
                                  .add(-3, "days")
                                  .format("DD")}
                              </div>
                            </div>

                            <div
                              className="rewards-calendar-day font-bold font-18" //selectedDay - 2 days
                              style={{
                                cursor:
                                  selectedDate
                                    .clone()
                                    .add(-2, "days")
                                    .isBefore(
                                      currentDateTime.clone().add(-1, "weeks")
                                    ) || selectedDate
                                      .clone()
                                      .add(-2, "days")
                                      .isBefore(moment(dateList[0]).startOf('day'))
                                    ? "not-allowed"
                                    : "pointer",
                                color:
                                  selectedDate
                                    .clone()
                                    .add(-2, "days")
                                    .isBefore(
                                      currentDateTime.clone().add(-1, "weeks")
                                    ) || selectedDate
                                      .clone()
                                      .add(-2, "days")
                                      .isBefore(moment(dateList[0]).startOf('day'))
                                    ? "grey"
                                    : "white",
                              }}
                              onClick={() => handleSelectedDate(-2)}
                            >
                              <div>
                                {
                                  _DAY_OF_WEEK.find(
                                    (x) =>
                                      x.value ===
                                      (7 + (selectedDate.day() - 2)) % 7
                                  ).label
                                }
                              </div>
                              <div>
                                {moment(selectedDate)
                                  .add(-2, "days")
                                  .format("DD")}
                              </div>
                            </div>

                            <div
                              className="rewards-calendar-day font-bold font-18" //selectedDay - 1 day
                              style={{
                                cursor:
                                  selectedDate
                                    .clone()
                                    .add(-1, "days")
                                    .isBefore(
                                      currentDateTime.clone().add(-1, "weeks")
                                    ) || selectedDate
                                      .clone()
                                      .add(-1, "days")
                                      .isBefore(moment(dateList[0]).startOf('day'))
                                    ? "not-allowed"
                                    : "pointer",
                                color:
                                  selectedDate
                                    .clone()
                                    .add(-1, "days")
                                    .isBefore(
                                      currentDateTime.clone().add(-1, "weeks")
                                    ) || selectedDate
                                      .clone()
                                      .add(-1, "days")
                                      .isBefore(moment(dateList[0]).startOf('day'))
                                    ? "grey"
                                    : "white",
                              }}
                              onClick={() => handleSelectedDate(-1)}
                            >
                              <div>
                                {
                                  _DAY_OF_WEEK.find(
                                    (x) =>
                                      x.value ===
                                      (7 + (selectedDate.day() - 1)) % 7
                                  ).label
                                }
                              </div>
                              <div>
                                {moment(selectedDate)
                                  .add(-1, "days")
                                  .format("DD")}
                              </div>
                            </div>

                            <div
                              className="rewards-calendar-day font-bold font-18"
                            /*selectedDay*/ style={{
                                background:
                                  "linear-gradient(180deg, #FE9F00, #FFCF7D)",
                              }}
                            >
                              <div>
                                {
                                  _DAY_OF_WEEK.find(
                                    (x) =>
                                      x.value === (7 + selectedDate.day()) % 7
                                  ).label
                                }
                              </div>
                              <div>{moment(selectedDate).format("DD")}</div>
                            </div>

                            <div
                              className="rewards-calendar-day font-bold font-18" //selectedDay + 1 day
                              style={{
                                cursor:
                                  selectedDate
                                    .clone()
                                    .add(1, "days")
                                    .isAfter(
                                      currentDateTime.clone().add(1, "weeks")
                                    ) || selectedDate
                                      .clone()
                                      .add(1, "days")
                                      .isAfter(moment(dateList[dateList.length - 1]).startOf('day'))
                                    ? "unset"
                                    : "pointer",
                                color:
                                  selectedDate
                                    .clone()
                                    .add(1, "days")
                                    .isAfter(
                                      currentDateTime.clone().add(1, "weeks")
                                    ) || selectedDate
                                      .clone()
                                      .add(1, "days")
                                      .isAfter(moment(dateList[dateList.length - 1]).startOf('day'))
                                    ? "grey"
                                    : "white",
                              }}
                              onClick={() => handleSelectedDate(1)}
                            >
                              <div>
                                {
                                  _DAY_OF_WEEK.find(
                                    (x) =>
                                      x.value ===
                                      (7 + (selectedDate.day() + 1)) % 7
                                  ).label
                                }
                              </div>
                              <div>
                                {moment(selectedDate)
                                  .add(1, "days")
                                  .format("DD")}
                              </div>
                              {/* </>
                              )} */}
                            </div>

                            <div
                              className="rewards-calendar-day font-bold font-18" //selectedDay + 2 days
                              style={{
                                cursor:
                                  selectedDate
                                    .clone()
                                    .add(2, "days")
                                    .isAfter(
                                      currentDateTime.clone().add(1, "weeks")
                                    ) || selectedDate
                                      .clone()
                                      .add(2, "days")
                                      .isAfter(moment(dateList[dateList.length - 1]).startOf('day'))
                                    ? "unset"
                                    : "pointer",
                                color:
                                  selectedDate
                                    .clone()
                                    .add(2, "days")
                                    .isAfter(
                                      currentDateTime.clone().add(1, "weeks")
                                    ) || selectedDate
                                      .clone()
                                      .add(2, "days")
                                      .isAfter(moment(dateList[dateList.length - 1]).startOf('day'))
                                    ? "grey"
                                    : "white",
                              }}
                              onClick={() => handleSelectedDate(2)}
                            >
                              <div>
                                {
                                  _DAY_OF_WEEK.find(
                                    (x) =>
                                      x.value ===
                                      (7 + (selectedDate.day() + 2)) % 7
                                  ).label
                                }
                              </div>
                              <div>
                                {moment(selectedDate)
                                  .add(2, "days")
                                  .format("DD")}
                              </div>
                              {/* </>
                              )} */}
                            </div>

                            <div
                              className="rewards-calendar-day font-bold font-18" //selectedDay + 3 days
                              style={{
                                cursor:
                                  selectedDate
                                    .clone()
                                    .add(3, "days")
                                    .isAfter(
                                      currentDateTime.clone().add(1, "weeks")
                                    ) || selectedDate
                                      .clone()
                                      .add(3, "days")
                                      .isAfter(moment(dateList[dateList.length - 1]).startOf('day'))
                                    ? "unset"
                                    : "pointer",
                                color:
                                  selectedDate
                                    .clone()
                                    .add(3, "days")
                                    .isAfter(
                                      currentDateTime.clone().add(1, "weeks")
                                    ) || selectedDate
                                      .clone()
                                      .add(3, "days")
                                      .isAfter(moment(dateList[dateList.length - 1]).startOf('day'))
                                    ? "grey"
                                    : "white",
                              }}
                              onClick={() => handleSelectedDate(3)}
                            >
                              <div>
                                {
                                  _DAY_OF_WEEK.find(
                                    (x) =>
                                      x.value ===
                                      (7 + (selectedDate.day() + 3)) % 7
                                  ).label
                                }
                              </div>
                              <div>
                                {moment(selectedDate)
                                  .add(3, "days")
                                  .format("DD")}
                              </div>
                              {/* </>
                              )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="tab-content">
                    {activeCatTitle === "All" ?
                      <div id="reward0" className="tab-pane in active">
                        <div className="" id="reward-box">
                          <div className="row text-white" id="reward-results-bottom">
                            {(scheduleData &&
                              Object.keys(scheduleData).length > 0 &&
                              scheduleData[selectedDate.format('YYYY-MM-DD')] &&
                              Object.keys(scheduleData[selectedDate.format('YYYY-MM-DD')]).length > 0) ?
                              Object.keys(scheduleData[selectedDate.format('YYYY-MM-DD')]).sort().map((productName, index) => {
                                if (scheduleData[moment(selectedDate).format("YYYY-MM-DD")][productName].rate > 0) {
                                  return (
                                    <div className="col-md-3 col-lg-3">
                                      <a className="text-white">
                                        <div className="template-box reward-items" id="reward-box">
                                          <div>
                                            <div className="text-center">
                                              <img
                                                src={
                                                  scheduleData[moment(selectedDate).format("YYYY-MM-DD")][productName].productImage
                                                }
                                                className="img-responsive"
                                                alt={
                                                  productName
                                                }
                                                width="100px"
                                              />
                                            </div>
                                            <div className="font-18 font-semi mb-1 text-center gpn-container">
                                              {productName}
                                            </div>
                                            <div className="font-18 font-bold text-gold text-center">
                                              {scheduleData[moment(selectedDate).format("YYYY-MM-DD")][productName].rate}%
                                            </div>
                                          </div>
                                        </div>
                                      </a>
                                    </div>
                                  )
                                }
                              }) : <div>{t("NO_DATA_FOUND")}</div>}
                          </div>
                        </div>
                      </div> :
                      <div id="reward0" className="tab-pane in active">
                        <div className="" id="reward-box">
                          <div className="row text-white" id="reward-results-bottom">
                            {(scheduleData &&
                              Object.keys(scheduleData).length > 0 &&
                              scheduleData[selectedDate.format('YYYY-MM-DD')] &&
                              Object.keys(scheduleData[selectedDate.format('YYYY-MM-DD')]).length > 0) ?
                              Object.keys(scheduleData[selectedDate.format('YYYY-MM-DD')]).sort().map((productName, index) => {
                                if (scheduleData[selectedDate.format('YYYY-MM-DD')][productName].rate > 0 && scheduleData[selectedDate.format('YYYY-MM-DD')][productName]["categoryTitle"] === activeCatTitle) {
                                  return (
                                    <div className="col-md-3 col-lg-3">
                                      <a className="text-white">
                                        <div className="template-box reward-items" id="reward-box">
                                          <div>
                                            <div className="text-center">
                                              <img
                                                src={
                                                  scheduleData[selectedDate.format('YYYY-MM-DD')][productName].productImage
                                                }
                                                className="img-responsive"
                                                alt={
                                                  productName
                                                }
                                                width="100px"
                                              />
                                            </div>
                                            <div className="font-18 font-semi mb-1 text-center gpn-container">
                                              {productName}
                                            </div>
                                            <div className="font-18 font-bold text-gold text-center">
                                              {scheduleData[selectedDate.format('YYYY-MM-DD')][productName].rate}%
                                            </div>
                                          </div>
                                        </div>
                                      </a>
                                    </div>
                                  )
                                }
                              })
                              : <div>{t("NO_DATA_FOUND")}</div>}
                          </div>
                        </div>
                      </div>}
                  </div>
                </> :
                  <div className="loading-spinner-wrapper">
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Rewards;
import {
  _IDLE,
  _BUSY,
  _ALERT_TYPES,
  _API_CALLED,
  _DOWNLINE_SEARCH,
  _DOWNLINE_QUERY,
  _SET_Y,
  _SET_PAGESIZE,
  _SET_ACTIVE_TAB,
  _IS_KEEPED_LOGIN,
  _GAME_SEARCH,
  _GAME_QUERY,
  _SET_IS_SHAREHOLDER,
  _RTP_URL,
  _BANNER_POPUP_MODAL,
  _BANNER_POPUP_IMAGE,
} from "./AppAction";
import { stringIsNullOrEmpty } from "../util/Util";
import i18n from "../localization/i18n";
import { AlertTypes } from '../util/Constant';

const _INITIAL_STATE = {
  isBusy: false,
  isKeepedLogin: false,
  message: i18n.t("LOADING"),
  alert: {},
  iconEnabled: true,
  apiCalledResponse: {},
  savedText: "",
  gameSavedText: "",
  savedQuery: "/Member/GetUserMemberList?directUserOnly=true",
  gameQuery: "/Game/GetMasterProductGroupedGame",
  y: 0,
  pageSize: 10,
  activeTab: 0,
  isShareholder: false,
  rtpUrl: "https://i8rtp.live/?lang=",
  bannerPopupModal: false,
  bannerPopupImage: "",
};

var _busyCount = 0;

/// <summary>
/// Author :
/// </summary>
export default (state = _INITIAL_STATE, action) => {
  switch (action.type) {
    case _BUSY:
      _busyCount++;
      return {
        ...state,
        isBusy: true,
        message: stringIsNullOrEmpty(action.message)
          ? i18n.t("LOADING")
          : action.message,
        iconEnabled:
          action.iconEnabled == null
            ? _INITIAL_STATE.iconEnabled
            : action.iconEnabled,
      };
      break;
    case _IDLE:
      _busyCount = Math.max(--_busyCount, 0);
      return {
        ...state,
        isBusy: _busyCount != 0,
      };
      break;
    case _ALERT_TYPES.SET_ALERT:
      state.alert.custom = true;
      state.alert.customIcon = action.alert.type == AlertTypes._INFO ? "/assets/img/sweetalert_info.png" : action.alert.type == AlertTypes._SUCCESS ? "/img/sweetalert_success.png" :" /img/sweetalert_error.png";
      state.alert.content = action.alert.content;
      state.alert.onConfirm = action.alert.onConfirm;
      state.alert.onCancel = action.alert.onCancel;
      state.alert.cancelBtnBsStyle = action.alert.cancelBtnBsStyle;
      state.alert.title = action.alert.title;
      return {
        ...state,
        alert: Object.keys(state.alert) == 0 ? action.alert : state.alert,
      };
      break;
    case _ALERT_TYPES.NONE:
      return {
        ...state,
        alert: {},
      };
      break;
    case _API_CALLED:
      return {
        ...state,
        apiCalledResponse: action.data,
      };
      break;
    case _DOWNLINE_SEARCH:
      return {
        ...state,
        savedText: action.data,
      };
      break;
    case _DOWNLINE_QUERY:
      return {
        ...state,
        savedQuery: action.data,
      };
      break;
    case _GAME_SEARCH:
      return {
        ...state,
        gameSavedText: action.data,
      };
      break;
    case _GAME_QUERY:
      return {
        ...state,
        gameQuery: action.data,
      };
      break;
    case _SET_Y:
      return {
        ...state,
        y: action.y,
      };
    case _SET_PAGESIZE:
      return {
        ...state,
        pageSize: action.data,
      };
      break;
    case _SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.data,
      };
      break;
    case _IS_KEEPED_LOGIN:
      return {
        ...state,
        isKeepedLogin: action.data,
      };
      break;
    case _SET_IS_SHAREHOLDER:
      return {
        ...state,
        isShareholder: action.data
      }
      break;
    case _RTP_URL:
      return {
        ...state,
        rtpUrl: action.data
      };
    case _BANNER_POPUP_MODAL:
      return {
        ...state,
        bannerPopupModal: action.data,
      };
      break;
    case _BANNER_POPUP_IMAGE:
      return {
        ...state,
        bannerPopupImage: action.data,
      };
      break;
    default:
      return state;
      break;
  }
};

import React, { useState, useEffect } from "react";
//import "react-simple-keyboard/build/css/index.css";
import Sidebar from "components/sidebar";
import { map } from "lodash";
import { ApiKey, ApiUrl, WebUrl } from "../../util/Constant";
import {
  setBusy,
  setIdle,
  showMessage,
  showResponseMessage,
} from "../../redux/AppAction";
import ApiEngine from "../../util/ApiEngine";
import { useDispatch } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import ContentHeader from "components/content/header";

const Referral = (props) => {
  const { t, i18n } = useTranslation();
  const _dispatch = useDispatch();
  const _history = useHistory();
  const [referralCodes, setReferralCodes] = useState([]);
  const [selectedReferral, setSelectedReferral] = useState({});
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [displayContent, setDisplayContent] = useState(true);

  useEffect(() => {
    init();
  }, []);

  async function deleteReferral(id) {
    try {
      let responseJson = await ApiEngine.get(
        ApiUrl._API_DEL_REFERRAL + "?id=" + id
      );
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        _dispatch(
          showResponseMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );
        init();
      } else {
        throw responseJson[ApiKey._API_MESSAGE_KEY];
      }
    } catch (errorMessage) {
      _dispatch(showMessage(false, t(errorMessage)));
    } finally {
      _dispatch(setIdle());
    }
  }

  async function init() {
    _dispatch(setBusy());
    setDisplayContent(false);
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_MEMBER_REFERRAL_CODES
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setReferralCodes(responseJson[ApiKey._API_DATA_KEY]);
    } else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }
    setDisplayContent(true);
    //setTimeout(() => setDisplayContent(true), 1);
    _dispatch(setIdle());
  }
  return (
    <div id="referral">
      <section className="section-padding" id="settings-section">
        <div className="container">
          <div className="row">
            <div className="col-3" id="account-menu">
              <Sidebar activePage={"REFERRAL"} />
            </div>
            <div className="col-9">
              <ContentHeader title={t("REFERRAL")} />
              <hr className="right-title-hr" />
              {displayContent && referralCodes && referralCodes.length ? (
                map(referralCodes, (referralCode, index) => {
                  return (
                    <div key={index}>
                      <div className="wallet-main-boxes">
                        <div className="first">
                          <i
                            id="delReferral"
                            className="fas fa-trash d-flex align-items-center"
                            style={{ color: "white" }}
                            onClick={() => {
                              setSelectedReferral(referralCode);
                              setConfirmDelete(true);
                            }}
                          ></i>
                        </div>
                        <Link
                          className="referral-link"
                          to={{
                            pathname: WebUrl._URL_REFERRAL_CODE,
                            state: { id: referralCode.id },
                          }}
                        >
                          <span className="font-18 text-white font-semi mb-0">
                            {referralCode.code + " - " + referralCode.remark}
                          </span>
                          <img
                            src={"/img/assets/next-page.png"}
                            className="img-responsive"
                            alt="next-page"
                          />
                        </Link>
                      </div>
                      {index === referralCodes.length - 1 && (
                        <div className="container create-group-btn">
                          <button
                            type="button"
                            className="custom-btn-style-1"
                            onClick={() => {
                              _history.push({
                                pathname: WebUrl._URL_REFERRAL_EDIT,
                              });
                            }}
                          >
                            {t("CREATE")}
                          </button>
                        </div>
                      )}
                    </div>
                  );
                })
              ): 
              (referralCodes.length == 0 &&
                <>
                  <div className="wallet-main-boxes">
                    <div className="font-18 text-white font-semi mb-0">
                        {t("NO_REFERRAL_CODE_YET")}
                    </div>
                  </div>

                  <div className="container create-group-btn">
                    <button
                      type="button"
                      className="custom-btn-style-1"
                      onClick={() => {
                        _history.push({
                          pathname: WebUrl._URL_REFERRAL_EDIT,
                        });
                      }}
                    >
                      {t("CREATE")}
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
      <SweetAlert
        show={confirmDelete}
        type="warning"
        title={t("DELETE_REFERRAL", selectedReferral.code) + "?"}
        showCancel
        cancelBtnText={t("CANCEL")}
        confirmBtnText={t("CONFIRM")}
        onConfirm={() => {
          deleteReferral(selectedReferral.id);
          setConfirmDelete(false);
        }}
        onCancel={() => {
          setConfirmDelete(false);
        }}
      />
    </div>
  );
};
export default Referral;

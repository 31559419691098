import React, { useState, useEffect } from "react";
//import "react-simple-keyboard/build/css/index.css";
import Sidebar from "components/sidebar";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { ApiKey, ApiUrl } from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine";
import { stringIsNullOrEmpty, numberWithCurrencyFormat } from "../../util/Util";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";
import { Controller, useForm } from "react-hook-form";
import ContentHeader from "components/content/header";
import DateRangeFilter from "../../components/custom/DateRangeFilter";
import StyledSelect from "../../components/custom/StyledSelect";

const BonusReport = (props) => {
  const { t } = useTranslation();
  const { control, setValue, watch} = useForm();
  const [products, setProducts] = useState([]);
  const [bonusUrl, setBonusUrl] = useState("");
  const [bonusData, setBonusData] = useState([]);
  const [queryProduct, setQueryProduct] = useState("");
  const [searchTrigger, setSearchTrigger] = useState(Date.now());

  const [startDate, setStartDate] = useState(moment().startOf("week").format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment().endOf("week").format("YYYY-MM-DD"));
  const [selectedDate, setSelectedDate] = useState("");

  useEffect(() => {
    init();
    getBonusData(startDate, endDate);
  }, []);

  async function init() {
    var responseJson = await ApiEngine.get(
      `${ApiUrl._API_GET_ASSIGNED_MEMBER_PRODUCT}?ignoreAccountProductStatus=true`
    );

    const productList = [
      {
        label: t("ALL"),
        value: null,
      },
    ];

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      responseJson[ApiKey._API_DATA_KEY].products.forEach(function (product) {
        productList.push({
          label: product.productName,
          value: product.productId,
        });
      });
    }

    setProducts(productList);
  }

  async function getBonusData(start, end, selected = "") {
    // var apiUrl =
    //   ApiUrl._API_GET_BONUS_HISTORY +
    //   "?StartDate=" +
    //   moment(start).format("YYYY-MM-DD") +
    //   "&EndDate=" +
    //   moment(end).format("YYYY-MM-DD") +
    //   "&v=" +
    //   Date.now();

    var apiUrl =
      ApiUrl._API_GET_BONUS_HISTORY +
      "?StartDate=" +
      // moment(start).format("YYYY-MM-DD HH:mm:ss") +
      moment(start).startOf('day').format("YYYY-MM-DD") +
      "&EndDate=" +
      // moment(end).format("YYYY-MM-DD HH:mm:ss") +
      moment(end).endOf('day').format("YYYY-MM-DD") +
      "&v=" + moment(start).startOf('day').format("YYYY-MM-DD");

    if (!stringIsNullOrEmpty(queryProduct)) {
      apiUrl += "&ProductId=" + queryProduct;
    }

    if (!stringIsNullOrEmpty(selected)) {
      // apiUrl += "&SelectedDate=" + moment(selected).format("YYYY-MM-DD");
      apiUrl += "&SelectedDate=" + moment(selected).format("YYYY-MM-DD");
    }

    setValue(
      "productId",
      stringIsNullOrEmpty(queryProduct) ? null : queryProduct
    );
    setBonusUrl(apiUrl);
  }

  useEffect(() => {
    if (!stringIsNullOrEmpty(selectedDate)) {
      getBonusData(startDate, endDate, selectedDate);
    }
  }, [selectedDate]);

  useEffect(() => {
    getBonusData(startDate, endDate);
  }, [searchTrigger]);

  function getRecordFromDynamicLoad(data) {
    setBonusData(data);
  }

  return (
    <>
      <section className="section-padding" id="game-record-section">
        <div className="container">
          <div className="row">
            <div className="col-3" id="account-menu">
              <Sidebar activePage={"REPORTS"} />
            </div>
            <div className="col-9">
              <ContentHeader enableBackButton title={t("BONUS_STATEMENT")} />
              <hr className="right-title-hr" />
              <div className="template-box mt-3" id="bonus-report-box">
                <DateRangeFilter
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  onSave={(start, end) => {
                    setQueryProduct(watch()["productId"]);
                    setStartDate(moment(start));
                    setEndDate(moment(end));
                    setSelectedDate("");
                    setSearchTrigger(Date.now());
                  }}
                  options={true}
                />

                <div className="form-group m-b-15">
                  <label className="col-form-label text-white">
                    <b>{t("GAME")}</b>
                  </label>
                  <div>
                    <Controller
                      control={control}
                      name="productId"
                      render={({ onChange, value }) => {
                        return (
                          <StyledSelect
                            options={products}
                            value={value}
                            onChange={(e) => {
                              onChange(e.value);
                            }}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="card card-package">
                  <div className="card-body p-0" style={{ overflowX: "auto" }}>
                    <table className="table reportTable">
                      <thead>
                        <tr>
                          <th>{t("DATE")}</th>
                          <th>{t("USERNAME")}</th>
                          <th>{t("GAME")}</th>
                          <th>{t("AMOUNT")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <DynamicLoadTable
                          returnRecord={getRecordFromDynamicLoad}
                          pageSize={20}
                          apiQuery={bonusUrl}
                          isTable={true}
                          searchEnabled={true}
                          render={(item, i) => {
                            let isGrouped =
                              moment(endDate, "YYYY-MM-DD").diff(
                                moment(startDate, "YYYY-MM-DD"),
                                "days"
                              ) >= 1 && stringIsNullOrEmpty(selectedDate);
                            return (
                              <tr key={"bonus_report_" + i}>
                                <td
                                  className={isGrouped ? "hoverable" : ""}
                                  onClick={() => {
                                    if (isGrouped) {
                                      // setSelectedDate(item["parsedTime"]);
                                      setSelectedDate(moment(item["parsedTime"], 'MM/DD/YYYY').format('YYYY-MM-DD'));
                                    }
                                  }}
                                >
                                  {item["parsedTime"]}
                                </td>
                                <td>{item["username"]}</td>
                                <td>{item["remark"]}</td>
                                <td>
                                  {numberWithCurrencyFormat(
                                    parseFloat(item["amount"]),
                                    2
                                  )}
                                </td>
                              </tr>
                            );
                          }}
                        />
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan="3">
                            <b>{t("TOTAL")}</b>
                          </td>
                          <td>
                            <b>
                              {numberWithCurrencyFormat(
                                parseFloat(
                                  bonusData.reduce(
                                    (total, data) => total + data.amount,
                                    0
                                  )
                                ),
                                2
                              )}
                            </b>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default BonusReport;

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
//import "react-simple-keyboard/build/css/index.css";
import { ApiUrl, ApiKey } from "../../util/Constant";
import Sidebar from "components/sidebar";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine";
import {
  showResponseMessage,
  setBusy,
  setIdle,
  setPageSize,
} from "../../redux/AppAction";
import { numberWithCurrencyFormat } from "../../util/Util";
import ContentHeader from "components/content/header";

const EditDownlineDetails = (props) => {
  var _history = useHistory();
  var { t } = useTranslation();
  var _dispatch = useDispatch();
  var _location = useLocation();

  const [downlineData, setDownlineData] = useState({});
  const [products, setProducts] = useState([]);
  const [nickname, setNickname] = useState("");

  useEffect(() => {
    if (_location["state"]) {
      init();
      _dispatch(setPageSize(_location["scrollIndex"]));
    } else {
      _history.goBack();
    }
  }, [_location["state"]]);

  async function init() {
    _dispatch(setBusy());
    try {
      var responseJson = await ApiEngine.get(
        `${ApiUrl._API_GET_MEMBER_DETAILS_BY_ID}?memberId=${_location["state"]["id"]}`
      );

      if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
        throw responseJson[ApiKey._API_MESSAGE_KEY];
      }

      setDownlineData(responseJson[ApiKey._API_DATA_KEY]);

      responseJson = await ApiEngine.get(
        `${ApiUrl._API_GET_ASSIGNED_MEMBER_PRODUCT}?memberId=${_location["state"]["id"]}&ignoreAccountProductStatus=true`
      );

      if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
        throw responseJson[ApiKey._API_MESSAGE_KEY];
      }

      setProducts(responseJson[ApiKey._API_DATA_KEY].products);
      setNickname(responseJson[ApiKey._API_DATA_KEY].nickname);
    } catch (errorMessage) {
      _dispatch(
        showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
      );
    } finally {
      _dispatch(setIdle());
    }
  }

  return (
    <>
      <section className="section-padding" id="downline-section">
        <div className="container">
          <div className="row">
            <div className="col-3" id="account-menu">
              <Sidebar activePage={"DOWNLINE"} />
            </div>
            <div className="col-9">
              <ContentHeader
                enableBackButton
                title={t("PRODUCT_COMM_RATE")}
              />
              <hr className="right-title-hr" />
              <div className="template-box" id="downline-edit">
                <div className="downline-edit-container">
                  {products
                    .filter((x) => !x["useGroupRate"])
                    .map((product, index) => {
                      return (
                        <div key={"products" + index}>
                          <table
                            id="commrate"
                            className="table table-borderless table-description"
                          >
                            <tbody>
                              <tr>
                                <th>{t("PRODUCT_NAME")}</th>
                                <td className="text-center">
                                  {product["productName"]}
                                </td>
                              </tr>
                              <tr>
                                <th>{t("BONUS_RATE")} (%)</th>
                                <td className="text-center">
                                  {numberWithCurrencyFormat(
                                    product["bonus"],
                                    2
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <th>{t("COMM_RATE")} (%)</th>
                                <td className="text-center">
                                  {numberWithCurrencyFormat(
                                    product["commission"],
                                    2
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          {index + 1 < products["length"] && (
                            <div className="border-bot-black"></div>
                          )}
                        </div>
                      );
                    })}
                  {products.filter((x) => x["useGroupRate"])["length"] > 0 && (
                    <div className="row groupRates">
                      <div style={{ width: "100%" }}>
                        <div>
                          <label className="col-6 col-form-label">
                            {t("PRODUCT_NAME")}
                          </label>
                          <span>{t("SLOT_GAMES")}</span>
                        </div>
                        <div>
                          <label className="col-6 col-form-label">
                            {t("COMM_RATE")} (%)
                          </label>
                          <span>
                            {
                              products.filter((x) => x["useGroupRate"])[0][
                                "commission"
                              ]
                            }
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default EditDownlineDetails;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import { map } from "lodash";
import { stringIsNullOrEmpty } from "../../util/Util";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "../../redux/AppAction";
import { AlertTypes, WebUrl } from "../../util/Constant";
import { useHistory } from "react-router-dom";

const PromoBanner = ({ promoBannerList }) => {
  const { t } = useTranslation();
  const _dispatch = useDispatch();
  let _history = useHistory();
  const { isLoggedIn } = useSelector(state => state.authState);
  //const maxBannerSliderToShow = 3;
  const [maxBannerSliderToShow, setMaxBannerSliderToShow] = useState(3);
  useEffect(() => {
    // console.log(promoBannerList.length)
    if (promoBannerList.length < 3) {
      setMaxBannerSliderToShow(promoBannerList.length);
    } else {
      setMaxBannerSliderToShow(3);
    }
  }, [promoBannerList]);
  
  const bannerSlider = {
    infinite: true,
    slidesToShow: maxBannerSliderToShow,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    lazyLoad: true,
    autoplay: true,
    autoPlaySpeed: 3000,
    responsive: [
			// {
			// 	breakpoint: 2000,
			// 	settings: {
			// 		slidesToShow: 3,
			// 	},
			// },
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 500,
				settings: {
					slidesToShow: 1,
				},
			},
		],
  };

  const _listOfUrlStrToIgnore = [
    "#",
    "undefined"
  ];

  return (
    <div className="container mt-4">
      {promoBannerList.length > 0 ?
        <div className="row">
          <div className="col-md-12">
            <h2 className="mb-4">{t("LIVE_PROMOTION")}</h2>
          </div>
          <div className="col-md-12">
            <Slider {...bannerSlider} className="promo-banner-slider">
              {promoBannerList.map((banner, index) => {
                return (
                  <>
                    <div key={index} style={{ textAlign: "center" }} onClick={() => {
                      _history.push(WebUrl._URL_PROMOTION);
                    }}>
                      <img src={banner.desktopImage} className={"promo-banner-img img-" + (index + 1)} />
                    </div>
                  </>
                )
              })}
            </Slider>
          </div>
        </div>
        :
        <div className="className2">
      </div>}
    </div>
  );
};

export default PromoBanner;

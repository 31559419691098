import React from "react";
//import "react-simple-keyboard/build/css/index.css";
import Sidebar from "components/sidebar";
import { map } from "lodash";
import ContentHeader from "components/content/header";
import Button from "components/button";
import { useTranslation } from "react-i18next";

const Account = (props) => {
  const { t } = useTranslation();

  const tabPinHeadings = [
    {
      title: "Withdrawal",
      link: "pin-w",
    },
    {
      title: "Shareholder",
      link: "pin-s",
    },
  ];
  const tabVerifyHeadings = [
    {
      title: "Phone Verification",
      link: "phone-verification",
    },
    {
      title: "Email Verification",
      link: "email-verification",
    },
  ];
  return (
    <>
      <section className="section-padding" id="account-section">
        <div className="container">
          <div className="row">
            <div className="col-3" id="account-menu">
              <Sidebar activePage={"ACCOUNT"} />
            </div>
            <div className="col-9">
              <ContentHeader title={t("ACCOUNT")} />
              <hr className="right-title-hr" />
              <div className="wallet-main-boxes" id="account-box1">
                <div
                  className="first"
                  data-toggle="collapse"
                  data-target="#account-general"
                >
                  <div className="img">
                    <img
                      src={"/img/acc-icon1.png"}
                      className="img-responsive"
                      alt="General Information"
                    />
                  </div>
                  <div>
                    <h3>General Information</h3>
                  </div>
                </div>
                <div className="collapse" id="account-general">
                  <hr />
                  <div className="pl-3 pr-3">
                    <div className="form-divider">
                      <div className="">
                        <h4 className="font-15 mb-3">Username</h4>
                        <input
                          type="text"
                          name="amount"
                          className="form-white-input"
                          placeholder="Please enter username"
                        />
                      </div>
                      <div></div>
                    </div>
                    <div className="form-divider mt-4">
                      <div>
                        <h4 className="font-15 mb-3">Country Code</h4>
                        <select className="form-white-select">
                          <option>Please select country code</option>
                          <option>code1</option>
                          <option>code2</option>
                        </select>
                      </div>
                      <div>
                        <h4 className="font-15 mb-3">Phone Number</h4>
                        <input
                          type="text"
                          name="amount"
                          className="form-white-input"
                          placeholder="Please enter phone number"
                        />
                      </div>
                    </div>
                    <div className="form-divider mt-4">
                      <div className="">
                        <h4 className="font-15 mb-3">Date of Birth</h4>
                        <input
                          type="date"
                          name="amount"
                          className="form-white-input"
                        />
                      </div>
                      <div></div>
                    </div>
                    <div className="form-divider mt-4">
                      <div>
                        <h4 className="font-15 mb-3">Country</h4>
                        <select className="form-white-select">
                          <option>Please select country</option>
                          <option>Malaysia</option>
                          <option>Japan</option>
                        </select>
                      </div>
                      <div></div>
                    </div>
                    <div className="form-divider mt-4">
                      <div>
                        <h4 className="font-15 mb-3">E-mail Address</h4>
                        <input
                          type="text"
                          name="amount"
                          className="form-white-input"
                          placeholder="Please enter email address"
                        />
                      </div>
                      <div></div>
                    </div>
                    <div className="form-divider pt-4">
                      <Button text="Save" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="wallet-main-boxes" id="account-box2">
                <div
                  className="first"
                  data-toggle="collapse"
                  data-target="#account-password"
                >
                  <div className="img">
                    <img
                      src={"/img/acc-icon2.png"}
                      className="img-responsive"
                      alt="Change Password"
                    />
                  </div>
                  <div>
                    <h3>Change Password</h3>
                  </div>
                </div>
                <div className="collapse" id="account-password">
                  <hr />
                  <div className="pl-3 pr-3">
                    <div className="form-divider">
                      <div className="">
                        <h4 className="font-15 mb-3">Enter Current Password</h4>
                        <input
                          type="text"
                          name="amount"
                          className="form-white-input"
                          placeholder="Please enter current password"
                        />
                      </div>
                      <div className="">
                        <h4 className="font-15 mb-3">Enter New Password</h4>
                        <input
                          type="text"
                          name="amount"
                          className="form-white-input"
                          placeholder="Please enter new password"
                        />
                      </div>
                    </div>
                    <div className="form-divider mt-4">
                      <div className="">
                        <h4 className="font-15 mb-3">Confirm New Password</h4>
                        <input
                          type="text"
                          name="amount"
                          className="form-white-input"
                          placeholder="Please confirm new password"
                        />
                      </div>
                      <div className=""></div>
                    </div>
                    <div className="form-divider pt-4">
                      <Button text="Change Password" />
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wallet-main-boxes" id="account-box3">
                <div
                  className="first"
                  data-toggle="collapse"
                  data-target="#account-pin"
                >
                  <div className="img">
                    <img
                      src={"/img/acc-icon3.png"}
                      className="img-responsive"
                      alt="Change PIN"
                    />
                  </div>
                  <div>
                    <h3>Change PIN</h3>
                  </div>
                </div>
                <div className="collapse" id="account-pin">
                  <hr />
                  <div className="pl-3 pr-3">
                    <ul className="nav nav-tabs" id="my-tabs">
                      {tabPinHeadings &&
                        tabPinHeadings.length &&
                        map(tabPinHeadings, (item, index) => {
                          return (
                            <li>
                              <a
                                data-toggle="tab"
                                href={"#" + item.link}
                                className={
                                  index == 0 ? "font-12 active" : "font-12"
                                }
                              >
                                {item.title}
                              </a>
                            </li>
                          );
                        })}
                    </ul>
                    <div className="tab-content mt-4">
                      <div id="pin-w" className="tab-pane in active">
                        <div className="form-divider">
                          <div className="">
                            <h4 className="font-15 mb-3">Current PIN</h4>
                            <input
                              type="text"
                              name="amount"
                              className="form-white-input"
                              placeholder="Please enter current pin"
                            />
                          </div>
                          <div className="">
                            <h4 className="font-15 mb-3">New PIN</h4>
                            <input
                              type="text"
                              name="amount"
                              className="form-white-input"
                              placeholder="Please enter new pin"
                            />
                          </div>
                        </div>
                        <div className="form-divider mt-4">
                          <div className="">
                            <h4 className="font-15 mb-3">Confirm New PIN</h4>
                            <input
                              type="text"
                              name="amount"
                              className="form-white-input"
                              placeholder="Please confirm new pin"
                            />
                          </div>
                          <div className=""></div>
                        </div>
                        <div className="form-divider">
                          <div className="form-divider pt-4">
                            <Button text="Change PIN" />
                            <div></div>
                          </div>
                        </div>
                      </div>
                      <div id="pin-s" className="tab-pane fade">
                        <div className="form-divider">
                          <div className="">
                            <h4 className="font-15 mb-3">Current PIN</h4>
                            <input
                              type="text"
                              name="amount"
                              className="form-white-input"
                              placeholder="Please enter current pin"
                            />
                          </div>
                          <div className="">
                            <h4 className="font-15 mb-3">New PIN</h4>
                            <input
                              type="text"
                              name="amount"
                              className="form-white-input"
                              placeholder="Please enter new pin"
                            />
                          </div>
                        </div>
                        <div className="form-divider mt-4">
                          <div className="">
                            <h4 className="font-15 mb-3">Confirm New PIN</h4>
                            <input
                              type="text"
                              name="amount"
                              className="form-white-input"
                              placeholder="Please confirm new pin"
                            />
                          </div>
                          <div className=""></div>
                        </div>
                        <div className="form-divider">
                          <div className="form-divider pt-4">
                            <Button text="Change PIN" />
                            <div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wallet-main-boxes" id="account-box4">
                <div
                  className="first"
                  data-toggle="collapse"
                  data-target="#account-verification"
                >
                  <div className="img">
                    <img
                      src={"/img/acc-icon4.png"}
                      className="img-responsive"
                      alt="Verify Account"
                    />
                  </div>
                  <div>
                    <h3>Verify Account</h3>
                  </div>
                </div>
                <div className="collapse" id="account-verification">
                  <hr />
                  <div className="pl-3 pr-3">
                    <ul className="nav nav-tabs" id="my-tabs">
                      {tabVerifyHeadings &&
                        tabVerifyHeadings.length &&
                        map(tabVerifyHeadings, (item, index) => {
                          return (
                            <li>
                              <a
                                data-toggle="tab"
                                href={"#" + item.link}
                                className={
                                  index == 0 ? "font-12 active" : "font-12"
                                }
                              >
                                {item.title}
                              </a>
                            </li>
                          );
                        })}
                    </ul>
                    <div className="tab-content mt-4">
                      <div id="phone-verification" className="tab-pane in active">
                        <div className="form-divider">
                          <div className="">
                            <h4 className="font-15 mb-3">Country</h4>
                            <select className="form-white-select">
                              <option>Please select country</option>
                              <option>Malaysia</option>
                              <option>Japan</option>
                            </select>
                          </div>
                          <div className="">
                            <h4 className="font-15 mb-3">Phone Number</h4>
                            <input
                              type="text"
                              name="amount"
                              className="form-white-input"
                              placeholder="Please enter phone number"
                            />
                          </div>
                        </div>
                        <div className="form-divider mt-4">
                          <div className="position-relative">
                            <h4 className="font-15 mb-3">Verification Code</h4>
                            <input
                              type="text"
                              name="amount"
                              className="form-white-input"
                              placeholder="Please enter code"
                            />
                            <span className="font-11 text-gold resend-code">
                              Resend Code
                            </span>
                          </div>
                          <div className=""></div>
                        </div>
                        <div className="pt-4">
                          <Button text="Send Verification Code" />
                        </div>
                      </div>
                      <div id="email-verification" className="tab-pane fade">
                        <div className="form-divider">
                          <div className="">
                            <h4 className="font-15 mb-3">E-mail Address</h4>
                            <input
                              type="text"
                              name="amount"
                              className="form-white-input"
                              placeholder="Please enter email address"
                            />
                          </div>
                          <div className="position-relative">
                            <h4 className="font-15 mb-3">Verification Code</h4>
                            <input
                              type="text"
                              name="amount"
                              className="form-white-input"
                              placeholder="Please enter code"
                            />
                            <span className="font-11 text-gold resend-code">
                              Resend Code
                            </span>
                          </div>
                        </div>
                        <div className="pt-4">
                          <Button text="Send Verification Code" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Account;

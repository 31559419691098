import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ApiEngine from '../../util/ApiEngine';
import { AlertTypes, ApiKey, ApiUrl, SessionKey, WebUrl } from '../../util/Constant';
import ContentHeader from "components/content/header";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import {
  Modal,
  ModalBody
} from "reactstrap";
import PromotionDetail from './PromotionDetail';
import { showMessage, showResponseMessage } from '../../redux/AppAction';
import { stringIsNullOrEmpty } from '../../util/Util';
import { Spinner } from 'react-bootstrap';

const Promotion = props => {
  let { t } = useTranslation();
  let _dispatch = useDispatch();
  let _history = useHistory();
  let _location = useLocation();
  const { isLoggedIn } = useSelector(state => state.authState);

  const [promotionData, setPromotionData] = useState([]);
  const [displayPromoData, setDisplayPromoData] = useState([]);
  const [vipFilterList, setVipFilterList] = useState([]);
  const [activeVipFilter, setActiveVipFilter] = useState("All");
  const [activePromoToView, SetActivePromoToView] = useState({});
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showRegisterPopup, setShowRegisterPopup] = useState(false);
  const [showCodeError, setShowCodeError] = useState(false);
  const [loadingPromoData, setLoadingPromoData] = useState(true);
  const [referralCode, setReferralCode] = useState("");

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    init();
  }, [localStorage.getItem(SessionKey._LANGUAGE)]);

  const init = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_COMPANY_PROMOTION + "?languageCode=" + localStorage.getItem(SessionKey._LANGUAGE));
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      setPromotionData(data);
      setDisplayPromoData(data);
      let tempVipFilterList = [];
      data.map((promo, i) => {
        if (!tempVipFilterList.includes(promo.vipModel.name)) {
          if (promo.vipModel.name === "All") {
            tempVipFilterList.unshift(promo.vipModel.name);
          }
          else {
            tempVipFilterList.push(promo.vipModel.name);
          }
        }
      });
      //if (tempVipFilterList.length > 1) {
      //  tempVipFilterList.unshift("All");
      //}
      setVipFilterList(tempVipFilterList);
    }
    setLoadingPromoData(false);
  }

  const filterPromo = async (vipName) => {
    setLoadingPromoData(true);
    if (activeVipFilter !== vipName) {
      setActiveVipFilter(vipName);
      let tempPromoData = promotionData;
      if (vipName === "All") {
        setDisplayPromoData(tempPromoData);
      }
      else {
        let tempDisplayPromoData = tempPromoData.filter(x => x.vipModel.name === vipName);
        setDisplayPromoData(tempDisplayPromoData);
      }
      setTimeout(() => setLoadingPromoData(false), 100);
    }
  }

  const handlePromoOnClick = async (promoData) => {
    SetActivePromoToView(promoData);
    setShowDetailModal(true);
  }

  const handleReferralCode = (code) => {
    setReferralCode(code);
  };

  async function checkIfReferralExist(code) {
    let referralCode = code;
    if (code.includes(" +")) {
      referralCode = code.substring(0, code.indexOf(" +"));
    }
    let apiUrl =
      ApiUrl._API_CHECK_IF_REFERRAL_CODE_EXIST + "?code=" + referralCode;
    let responseJson = await ApiEngine.get(apiUrl);
    console.log("here");
    if (!responseJson[ApiKey._API_SUCCESS_KEY] || responseJson[ApiKey._API_DATA_KEY] == null || stringIsNullOrEmpty(responseJson[ApiKey._API_DATA_KEY])) {
      console.log("here1");
      if ((responseJson[ApiKey._API_DATA_KEY] == null || stringIsNullOrEmpty(responseJson[ApiKey._API_DATA_KEY]))) {
        console.log("here2");
        _dispatch(
          showMessage({
            type: AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => console.log(" ")
          })
        );
      }
      else {
        _dispatch(
          showResponseMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );
      }
    }
    else {
      if (responseJson[ApiKey._API_DATA_KEY] !== code) {
        //_dispatch(showMessage({
        //    type: AlertTypes._INFO,
        //    content: t("REFERRAL_CODE_INVALID_USING_COMPANY_CODE")
        //}));
        code = responseJson[ApiKey._API_DATA_KEY];
      }
      setShowRegisterPopup(false);
      _history.push(WebUrl._URL_REGISTER + "?code=" + code);
    }
  }

  return (
    <>
      <section className="section-padding" id="promotion">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ContentHeader title={t("LIVE_PROMOTION")} />
              <hr className="right-title-hr" />
              {vipFilterList.length > 1 && <div className="desktop-vip-filter-container">
                {vipFilterList.map((vipName, index) => {
                  return (
                    <div key={index} className={"vip-level-name" + (activeVipFilter === vipName ? " active" : "")} onClick={() => filterPromo(vipName)}>
                      {vipName}
                    </div>
                  )
                })}
              </div>}
              {displayPromoData.length > 0 ? <div className="promotion-wrapper">
                {!loadingPromoData ? displayPromoData.map((promotion, index) => {
                  return (
                    <div key={index} className={"promotion-row row-" + (index + 1)}>
                      <div className="promo-img" onClick={() => handlePromoOnClick(promotion)}>
                        <img src={promotion.desktopImage} loading="lazy" />
                      </div>
                      <div className="promo-content">
                        <div className="promo-content-top">
                          <div className="promo-title">
                            {promotion.title}
                          </div>
                          <div className="promo-text">
                            {promotion.description}
                          </div>
                        </div>
                        <div classname="promo-content-bottom">
                          <div className="promo-btn">
                            <button className="btn-1 btn-block btn-primary" onClick={() => handlePromoOnClick(promotion)}>{t("MORE_INFO")}</button>
                            {!isLoggedIn && <button className="btn-2 custom-cancel-btn" onClick={() => setShowRegisterPopup(true)}>{t("JOIN_NOW")}</button>}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }) : <div className="loading-promo-spinner-wrapper"><Spinner className="loading-promo-spinner" animation="border" role="status" /></div>}
              </div> : <></>}
            </div>
          </div>
        </div>
      </section>

      {/*Register Modal*/}
      <Modal
        isOpen={showRegisterPopup}
        contentClassName="register-popup-modal-body-wrapper modal-brand"
        wrapClassName="register-popup-modal"
        toggle={() => {
          setShowRegisterPopup(false);
          setShowCodeError(false);
        }}
        centered
      >
        <ModalBody>
          <div className="register-popup-wrapper">
            <div className="referral-code-tips">
              <div className="mb-4">{t("PLEASE_ENTER_UPLINE_CODE")}</div>
              {/* <div>{t("EXAMPLE_SHORT") + "AbCd3fGH +"}</div> */}
            </div>
            <input
              className="custom-input-style-1 mb-3"
              type="text"
              placeholder={t("EG") + " AbCd3fGH +"}
              onChange={(e) => handleReferralCode(e.target.value)}
            />
            {showCodeError && (
              <div className="referral-code-error invalid-feedback">
                {t("REFERRAL_CODE_INVALID")}
              </div>
            )}
            <button
              className="custom-btn-style-1"
              type="button"
              onClick={() => {
                checkIfReferralExist(referralCode);
              }}
            >
              {t("REGISTER")}
            </button>
          </div>
        </ModalBody>
      </Modal>

      {/*Promotion Detail Modal*/}
      <Modal
        id="promo-modal"
        contentClassName="modal-brand modal-bottom promo-detail-modal"
        fade={true}
        isOpen={showDetailModal}
        toggle={() => {
          setShowDetailModal(false);
        }}
        centered
      >
        <div className="promo-detail-header">
          <div className="detail-title">{t("PROMOTION_DETAIL")}</div>
          <div className="detail-close-btn-wrapper" onClick={() => setShowDetailModal(false)}><div className="detail-close-btn">&#x2715;</div></div>
        </div>
        <ModalBody>
          <PromotionDetail promotion={activePromoToView} />
        </ModalBody>

      </Modal>
    </>
  )
}

export default Promotion;
import React, { useState, useEffect, useCallback, useRef, useMemo } from "react"; 
import { debounce, filter, map } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { performLogin, performLogout, updateUserData } from "../../redux/AuthAction.js";
import {
  Modal,
  ModalBody,
  Spinner,
  Carousel as ReactCarousel, 
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";    
import Popupi81 from "./../../assets/img/login_new.png";
import { useTranslation } from "react-i18next"; 
import { useForm } from "react-hook-form";
import SecureLS from "secure-ls"; 
import ApiEngine from "./../../util/ApiEngine.js";               
import {  
  LanguageOption, 
  ApiUrl,
  ApiKey, 
  SessionKey,
  WebUrl, 
  _MAIN_WALLET_ID, 
  AlertTypes,
  DisplayDevice,
  Language,
} from "./../../util/Constant.js";   
import {
  setActiveTab,
  setBusy,
  setIdle,
  showMessage, 
  showResponseMessage,
  setIsShareholder,
  setRtpUrl
} from "../../redux/AppAction.js";
import {
  createFormBody,
  createMultiPartFormBody,
  stringIsNullOrEmpty,
  numberWithCurrencyFormat2
} from "./../../util/Util.js";
import parse from 'react-html-parser';
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import { saveLanguageId } from "../../redux/LanguageAction.js";
import Input from "../../components/custom/Input";
import CustomSpinWheelContainer from "../../components/custom/CustomSpinWheelContainer"; 
import { LazyLoadImage } from 'react-lazy-load-image-component'; 
 
const Header = ({ className }) => {
  const { t, i18n } = useTranslation();
  const _dispatch = useDispatch();
  const _history = useHistory();

  const [popupBannerData, setPopupBannerData] = useState([]);
  const [showPopupBanner, setShowPopupBanner] = useState(false);
  const [animating, setAnimating] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const _MIN_PASSWORD_LENGTH = 6;
  const [isSettingsDropdownVisible, setIsSettingsDropdownVisible] =
    useState(false);
  const [isGameDropdownVisible, setIsGameDropdownVisible] = useState(false);
  const [isTxnDropdownVisible, setIsTxnDropdownVisible] = useState(false);
  const [isSearchInputVisible, setIsSearchInputVisible] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [showRegisterPopup, setShowRegisterPopup] = useState(false);
  const [loginFailCount, setLoginFailCount] = useState(0);
  const [loginAttemptUsername, setLoginAttemptUsername] = useState("");
  const [displayDateTime, setDisplayDateTime] = useState(
    moment().format("YYYY-MM-DD HH:mm:ss Z")
  );
  const [keptUsername, setKeptUsername] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [tempPhoneNum, setTempPhoneNum] = useState(""); 
  const [keptPassword, setKeptPassword] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [showCodeError, setShowCodeError] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [loadingSearchData, setLoadingSearchData] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("id");  
  const [selectedLanguageIcon, setSelectedLanguageIcon] = useState();
  const [isForgetPassword, setIsForgetPassword] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [verifyPhoneTAC, setVerifyPhoneTAC] = useState(false);
  const [isPasswordUpdated, setIsPasswordUpdated] = useState(false);
  const [hasPendingTxn, setHasPendingTxn] = useState(false); 
  const verifyState = {
    button: "1"
  };
  const selectedGameProvider = useSelector((state) => (state.gameState.gameProviders));
  const [isUpdatePassword, setIsUpdatePassword] = useState(false);
  const [username, setUsername] = useState("");
  const [showLanguageDropdown, setShowLanguageDropdown] = useState(false);
  const _TAC_VALID_TIMER = 300000;
  const _SECONDS_IN_MS = 1000;
  const walletBalanceInterval = useRef(null);
  const [countdownTAC, setCountdownTAC] = useState(false);
  const [enteredTAC, setEnteredTAC] = useState("");
  const [registerPopupContent, setRegisterPopupContent] = useState("");
  //const [companyContentList, setCompanyContentList] = useState([]);
  const {
    register: registerLogin,
    handleSubmit: handleSubmitLogin,
    setValue: setValueLogin,
    getValues: getValuesLogin,
    watch: watchLogin,
    errors: errorsLogin,
  } = useForm();
  const {
    register: forgotPasswordRegister,
    handleSubmit: forgotPasswordHandleSubmit,
    errors: forgotPasswordErrors,
    watch: forgotPasswordWatch,
    setValue: forgotPasswordSetValue,
    unregister: forgotPasswordUnregister,
  } = useForm();
  const {
    register: tacVerifyRegister,
    handleSubmit: tacVerifyHandleSubmit,
    errors: tacVerifyErrors,
    watch: tacVerifyWatch,
    setValue: tacVerifySetValue,
    unregister: tacVerifyUnregister,
  } = useForm();
  const {
    register: ResetPasswordRegister,
    handleSubmit: ResetPasswordHandleSubmit,
    errors: ResetPasswordErrors,
    watch: ResetPasswordWatch,
    setValue: ResetPasswordSetValue,
    unregister: ResetPasswordUnregister,
    watch,
  } = useForm();
  const {
    handleSubmit: PasswordUpdatedHandleSubmit,
  } = useForm();
  var { isLoggedIn } = useSelector((state) => state.authState);
  let isShareholder = useSelector((state) => state.appState.isShareholder);

  var _userData = useSelector((state) => state.authState.userData);
  var isSettingsDropdownVisibleSideBar = JSON.parse(localStorage.getItem('isSettingsDropdownVisibleSideBar'));

  const [currentWalletBalance, setCurrentWalletBalance] = useState(0);
  const gameCategories = useSelector((state) => {
    return filter(state.gameState.gameCategories, (item) => {
      return item.id > 0 && item.originalTitle != 'Virtual-Sports' && item.originalTitle != 'V.Sports';
    });
  });
  const txnList = [
    {
      name: "DEPOSIT",
      link: WebUrl._URL_DEPOSIT,
      image: "/img/Deposit.svg"
    },
    {
      name: "WITHDRAWAL",
      link: WebUrl._URL_WITHDRAWAL,
      image: "/img/Withdraw.svg"
    },
    {
      name: "TRANSFER",
      link: WebUrl._URL_TRANSFER,
      image: "/img/Transfer.svg"
    }
  ];
  const [languageCode, setLanguageCode] = useState("");
  const selectedLanguageId = useSelector(
    (state) => state.languageState.languageId
  );
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const [downloadURL, setDownloadURL] = useState("");
  const rtpUrl = useSelector((state) => state.appState.rtpUrl);

  var ls = new SecureLS();
  const [notificationUnread, setNotificationUnread] = useState(false);
  var isReadAllNotification = JSON.parse(localStorage.getItem('isReadAllNotification'));
  var unReadNotification = localStorage.getItem('unreadNotificationCount');
  const activeTab = useSelector((state) => state.appState.activeTab);
  const [selectedLanguageCode, setSelectedLanguageCode] = useState("id");
  let tempInterval;

  const countryList = [
    {
      name: "Indonesia",
      iso: "ID",
      icon: "/img/v3/id.svg",
      icon_circle: "/img/v3/id-circle.svg",
      url: window.location.origin,//(window.location.href).includes("staging") ? 'https://id-staging.huat8id.com/' : (window.location.href).includes("preproduction") ? 'https://id-preproduction.huat8id.com/' : 'https://i8id.live/',
      child_languages: [
        {
          name: "Indo",
          languageCode: Language._INDON,
          languageId: 7
        },
        {
          name: "English",
          languageCode: Language._ENGLISH,
          languageId: 1
        },
        //{
        //  name: "中文",
        //  languageCode: Language._CHINESE,
        //  languageId: 2
        //},
        //{
        //  name: "Malay",
        //  languageCode: Language._BAHASA_MELAYU,
        //  languageId: 3
        //},
        //{
        //  name: "ไทย",
        //  languageCode: Language._THAI,
        //  languageId: 5
        //},
        //{
        //  name: "Tiếng Việt",
        //  languageCode: Language._VIETNAMESE,
        //  languageId: 6
        //},
      ],
    },
    {
      name: "Malaysia",
      iso: "MYR",
      icon: "/img/v3/my.svg",
      icon_circle: "/img/v3/my-circle.svg",
      url: (window.location.href).includes("staging") ? 'https://my-staging-d.huat8my.com/' : (window.location.href).includes("preproduction") ? 'https://my-preproduction-d.huat8my.com/' : 'https://i8my.live/',
      child_languages: [
        {
          name: "English",
          languageCode: Language._ENGLISH,
          languageId: 1
        },
        {
          name: "中文",
          languageCode: Language._CHINESE,
          languageId: 2
        },
        {
          name: "Malay",
          languageCode: Language._BAHASA_MELAYU,
          languageId: 3
        },
        {
          name: "ไทย",
          languageCode: Language._THAI,
          languageId: 5
        },
        {
          name: "Tiếng Việt",
          languageCode: Language._VIETNAMESE,
          languageId: 6
        },
        {
          name: "Indo",
          languageCode: Language._INDON,
          languageId: 7
        }
      ],
    },
    {
      name: "Thailand",
      iso: "TH",
      icon: "/img/v3/th.svg",
      icon_circle: "/img/v3/th-circle.svg",
      url: (window.location.href).includes("staging") ? 'https://th-staging-d.huat8th.com' : (window.location.href).includes("preproduction") ? 'https://th-preproduction-d.huat8th.com' : 'https://i8th.live',
      child_languages: [
        {
          name: "ไทย",
          languageCode: Language._THAI,
          languageId: 5
        },
        {
          name: "English",
          languageCode: Language._ENGLISH,
          languageId: 1
        },
        {
          name: "中文",
          languageCode: Language._CHINESE,
          languageId: 2
        },
        {
          name: "Malay",
          languageCode: Language._BAHASA_MELAYU,
          languageId: 3
        },
        {
          name: "Tiếng Việt",
          languageCode: Language._VIETNAMESE,
          languageId: 6
        },
        {
          name: "Indo",
          languageCode: Language._INDON,
          languageId: 7
        }
      ],
    },
    {
      name: "Australia",
      iso: "AU",
      icon: "/img/v3/aud.svg",
      icon_circle: "/img/v3/aud-circle.svg",
      url: (window.location.href).includes("staging") ? 'https://au-staging-d.huat8au.com' : (window.location.href).includes("preproduction") ? 'https://au-preproduction-d.huat8au.com' : 'https://i8au.live',
      child_languages: [
        {
          name: "English",
          languageCode: Language._ENGLISH,
          languageId: 1
        },
        {
          name: "中文",
          languageCode: Language._CHINESE,
          languageId: 2
        },
        {
          name: "Malay",
          languageCode: Language._BAHASA_MELAYU,
          languageId: 3
        },
        {
          name: "ไทย",
          languageCode: Language._THAI,
          languageId: 5
        },
        {
          name: "Tiếng Việt",
          languageCode: Language._VIETNAMESE,
          languageId: 6
        },
        {
          name: "Indo",
          languageCode: Language._INDON,
          languageId: 7
        }
      ],
    },
  ];

  const settingsList = [
    {
      image: (unReadNotification === null ? notificationUnread : unReadNotification > 0 && !isReadAllNotification)
        ? "/img/nav/nav1.1.png" //with red dot
        : "/img/nav/notification-icon.svg", //without red dot
      text: t("NOTIFICATION"),
      redirectUrl: WebUrl._URL_NOTIFICATION,
    },
    {
      image: "/img/nav/nav2.svg",
      text: t("VIDEO_TUTORIAL"),
      redirectUrl: WebUrl._URL_VIDEO_TUTORIAL,
    },
    {
      image: "/img/nav/nav3.svg",
      text: t("KPI"),
      redirectUrl: WebUrl._URL_KPI,
    },
    {
      image: "/img/nav/nav4.svg",
      text: t("RELOAD"),
      redirectUrl: WebUrl._URL_DEPOSIT,
    },
    {
      image: "/img/nav/nav5.svg",
      text: t("TRANSFER"),
      redirectUrl: WebUrl._URL_TRANSFER,
    },
    {
      image: "/img/nav/nav8.svg",
      text: t("LIVE_CHAT"),
      redirectUrl: WebUrl._URL_CONTACT_US,
    },
    {
      image: "/img/nav/nav11.svg",
      text: t("MORE"),
      redirectUrl: WebUrl._URL_SETTINGS,
    },
  ];

  const headerDropdown = useRef(null);
  const headerTxnDropdown = useRef(null);
  const headerSearchBar = useRef(null);
  const headerSearchResult = useRef(null);

  useEffect(() => {
    // const value = LanguageOption.find((item) => localStorage.getItem(SessionKey._LANGUAGE) === item.code );
    if (params.get("languageCode") != null) {
      onChangeLanguage(params.get("languageCode"));
      localStorage.setItem(SessionKey._LANGUAGE, params.get("languageCode"));
    }
    else if (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined') {
      if (LanguageOption.find(
        (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)) != undefined) {
        setSelectedLanguage(
          LanguageOption.find(
            (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
          ).prefix
        );
        setSelectedLanguageCode(
          LanguageOption.find(
            (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
          ).code
        );

        let downloadURL = (window.location.href.includes("staging") || window.location.href.includes("localhost")) ?
          "http://download.i8global.com/?lang=" + localStorage.getItem(SessionKey._LANGUAGE) + "&country=id"
          :
          "http://download.i8dua.com/?lang=" + localStorage.getItem(SessionKey._LANGUAGE) + "&country=id"
          ;
        setDownloadURL(downloadURL);
        i18n.changeLanguage(localStorage.getItem(SessionKey._LANGUAGE));
      }
      else {
        i18n.changeLanguage("ID");
        setSelectedLanguage("ID");
        setSelectedLanguageCode("ID");
        let downloadURL = (window.location.href.includes("staging") || window.location.href.includes("localhost")) ?
          "http://download.i8global.com/?lang=" + localStorage.getItem(SessionKey._LANGUAGE) + "&country=id"
          :
          "http://download.i8dua.com/?lang=" + localStorage.getItem(SessionKey._LANGUAGE) + "&country=id"
          ;
        setDownloadURL(downloadURL);
        localStorage.setItem(SessionKey._LANGUAGE, "id");
      }
    } else {
      i18n.changeLanguage("ID");
      setSelectedLanguage("ID");
      setSelectedLanguageCode("ID");
      let downloadURL = (window.location.href.includes("staging") || window.location.href.includes("localhost")) ?
        "http://download.i8global.com/?lang=" + localStorage.getItem(SessionKey._LANGUAGE) + "&country=id"
        :
        "http://download.i8dua.com/?lang=" + localStorage.getItem(SessionKey._LANGUAGE) + "&country=id"
        ;
      setDownloadURL(downloadURL);
      localStorage.setItem(SessionKey._LANGUAGE, "id");
    }

    getRTPUrl();

  }, [(localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
    localStorage.getItem(SessionKey._LANGUAGE) : 'id')]);


  const checkIsShareholder = async () => {
    if (isLoggedIn) {
      var responseJson = await ApiEngine.get(
        ApiUrl._API_CHECK_IS_SHAREHOLDER
      );

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        let data = responseJson[ApiKey._API_DATA_KEY];
        if (data !== isShareholder) {
          _dispatch(setIsShareholder(data));
        }
      }
    }
  }

  const headerTab = (tab) => {
    if (activeTab !== tab) {
      _dispatch(setActiveTab(tab));
    }
  };

  const getRTPUrl = async () => {
    let rtpurl = await ApiEngine.get(ApiUrl._API_GET_RTP_URL);
    if (rtpurl[ApiKey._API_SUCCESS_KEY] && rtpurl[ApiKey._API_DATA_KEY] !== null) {
      _dispatch(setRtpUrl(localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
        rtpurl[ApiKey._API_DATA_KEY] + "?lang=" + localStorage.getItem(SessionKey._LANGUAGE) : rtpurl[ApiKey._API_DATA_KEY] +  "?lang=id"));
    }
  }

  useEffect(() => {
    _dispatch(setActiveTab(false));
    if (window.location.pathname == "/") {
      _dispatch(setActiveTab(1));
    } else if (window.location.pathname == "/download") {
      _dispatch(setActiveTab(4));
    }
    checkIsShareholder();
  }, [window.location.pathname]);

  /// <summary>
  /// Author: -
  /// </summary>
  useEffect(() => {
    //if (isLoggedIn) {
    //  if (!window.ReactNativeWebView) {
    //    if (userData["selectedTheme"] === 0) {
    //      _history.push(WebUrl._URL_MAIN);
    //    } else if (userData["selectedTheme"] === 1) {
    //      _history.push(WebUrl._URL_DASHBOARD_V2);
    //    }
    //  }
    //}

    if (ls.get("gR97lPRIn797")["PYTLczcty9uH"]) {
      setKeptUsername(ls.get("gR97lPRIn797")["Do3SwI4cuvqR"]);
      setKeptPassword(ls.get("gR97lPRIn797")["gR1IPGBN01dS"]);
      //  setValueLogin("loginUsername", ls.get("gR97lPRIn797")["Do3SwI4cuvqR"]);
      //  setValueLogin("loginPassword", ls.get("gR97lPRIn797")["gR1IPGBN01dS"]);
    }
    getCompanyContent();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setDisplayDateTime(moment().format("YYYY-MM-DD HH:mm:ss Z"));
    }, _SECONDS_IN_MS);
  }, [displayDateTime]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        isGameDropdownVisible &&
        headerDropdown.current &&
        !headerDropdown.current.contains(e.target)
      ) {
        setIsGameDropdownVisible(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isGameDropdownVisible]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        isTxnDropdownVisible &&
        headerTxnDropdown.current &&
        !headerTxnDropdown.current.contains(e.target)
      ) {
        setIsTxnDropdownVisible(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isTxnDropdownVisible]);


  useEffect(() => {
    const checkIfClickedOutsideSearch = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        isSearchInputVisible &&
        headerSearchBar.current &&
        !headerSearchBar.current.contains(e.target)
        // ||
        // (headerSearchResult.current &&
        //   !headerSearchResult.current.contains(e.target))
      ) {
        setIsSearchInputVisible(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutsideSearch);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutsideSearch);
    };
  }, [isSearchInputVisible]);

  useEffect(() => {
    const fetchData = async () => {
      if (isLoggedIn) {
        var responseJson = await ApiEngine.get(
          ApiUrl._API_GET_UNREAD_NOTIFICATION_LIST + "?languageCode=" +
          (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
            localStorage.getItem(SessionKey._LANGUAGE) : 'id')
          // localStorage.getItem(SessionKey._LANGUAGE)
        );
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          var allData =
            responseJson[ApiKey._API_DATA_KEY];

          if (parseInt(allData) > 0) {
            setNotificationUnread(false);
          } else {
            setNotificationUnread(true);
          }
        
        } else {
          _dispatch(
            showResponseMessage(
              responseJson[ApiKey._API_SUCCESS_KEY],
              responseJson[ApiKey._API_MESSAGE_KEY]
            )
          );
        }
      }
      //setCurrentWalletBalance(_userData['walletBalance']);
    };
    fetchData();
    if (isLoggedIn) {
      onChangeLanguage((localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
        localStorage.getItem(SessionKey._LANGUAGE) : 'id'));

      getPendingTxnDashboard();
    }
    else {
      clearInterval(walletBalanceInterval.current);
    }
  }, [isLoggedIn]);

  //useEffect(() => {
  //    if (selectedLanguageId) {
  //        const selectedItem = find(
  //            LanguageOption,
  //            (item) => item.code === selectedLanguageId
  //        );
  //        let downloadURL = "https://download.i8dua.com/?lang=" + selectedItem.code;
  //        setLanguageCode(selectedItem.code);
  //        setDownloadURL(downloadURL);
  //    } else {
  //        const selectedItem = find(
  //            LanguageOption,
  //            (item) => item.code === "th"
  //        );
  //        let downloadURL = "https://download.i8dua.com/?lang=" + selectedItem.code;
  //        setLanguageCode(selectedItem.code);
  //        setDownloadURL(downloadURL);
  //    }
  //}, [selectedLanguageId]);

  useEffect(() => {
    setSearchKeyword("");
  }, []);

  const getPendingTxnDashboard = async () => {
    //clearInterval(getBalanceInterval);
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_PENDING_TRANSACTION_DASHBOARD);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let tempHasPendingTxn = responseJson[ApiKey._API_DATA_KEY];
      if (tempHasPendingTxn) {
        setHasPendingTxn(tempHasPendingTxn);
      }
    }
    tempUpdateUserData();
  }

  const submitLoginForm = async (data, e) => {
    _dispatch(setBusy());

    localStorage.setItem(SessionKey._ISSHOWMINIGAMETRIGGER, true);

    setShowLoginPopup(false);
    setLoginAttemptUsername(data.username);
    if (data.isKeepedPassword) {
      ls.set("gR97lPRIn797", {
        Do3SwI4cuvqR: data.loginUsername,
        gR1IPGBN01dS: data.loginPassword,
        PYTLczcty9uH: data.isKeepedPassword,
      });
    } else {
      ls.set("gR97lPRIn797", {
        Do3SwI4cuvqR: "",
        gR1IPGBN01dS: "",
        PYTLczcty9uH: false,
      });
    }
    await _dispatch(
      performLogin(data.loginUsername, data.loginPassword, data.isKeepedLogin)
    );
    let params = {
      username: data.loginUsername,
    };
    var loginFailCountJson = await ApiEngine.post(
      ApiUrl._API_GET_LOGIN_FAILED_COUNT,
      createFormBody(params)
    );
    if (loginFailCountJson[ApiKey._API_SUCCESS_KEY]) {
      let data = loginFailCountJson[ApiKey._API_DATA_KEY];
      setLoginFailCount(data);
    }
    _dispatch(setIdle());
  };


  const onChangeLanguage = async (selectedLanguage) => {
    _dispatch(setBusy());
    setSelectedLanguage(
      LanguageOption.find((x) => x.code === selectedLanguage).prefix
    );
    i18n.changeLanguage(selectedLanguage);
    if (!isLoggedIn) {
      _dispatch(saveLanguageId(selectedLanguage, true));
    } else {
      _dispatch(saveLanguageId(selectedLanguage));
      let data = {};
      // data["id"] = _userData.userId;
      // data["selectedTheme"] = _userData.selectedTheme;
      data["languageSetting"] = selectedLanguage;
      // data["isMemberOperate"] = true;

      // Silently update user's language settings
      await ApiEngine.post(
        // ApiUrl._API_CREATE_OR_UPDATE_MEMBER,
        ApiUrl._API_UPDATE_MEMBER_LANGUAGE,
        createMultiPartFormBody(data)
      );

      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            action: "language",
            language: selectedLanguage,
          })
        );
      }

      ManageBanner();
    }
    getCompanyContent();
    _dispatch(setIdle());
  };

  const handleReferralCode = (code) => {
    setReferralCode(code);
  };

  async function checkIfReferralExist(code) {
    let referralCode = code;
    if (code.includes(" +")) {
      referralCode = code.substring(0, code.indexOf(" +"));
    }
    let apiUrl =
      ApiUrl._API_CHECK_IF_REFERRAL_CODE_EXIST + "?code=" + referralCode;
    let responseJson = await ApiEngine.get(apiUrl);
    if (!responseJson[ApiKey._API_SUCCESS_KEY] || responseJson[ApiKey._API_DATA_KEY] == null || stringIsNullOrEmpty(responseJson[ApiKey._API_DATA_KEY])) {
      if ((responseJson[ApiKey._API_DATA_KEY] == null || stringIsNullOrEmpty(responseJson[ApiKey._API_DATA_KEY]))) {
        _dispatch(
          showMessage({
            type: AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => _history.replace(WebUrl._URL_MAIN)
          })
        );
      }
      else {
        _dispatch(
          showResponseMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );
      }
    }
    else {
      if (responseJson[ApiKey._API_DATA_KEY] !== code) {
        //_dispatch(showMessage({
        //    type: AlertTypes._INFO,
        //    content: t("REFERRAL_CODE_INVALID_USING_COMPANY_CODE")
        //}));
        code = responseJson[ApiKey._API_DATA_KEY];
      }
      setShowRegisterPopup(false);
      _history.push(WebUrl._URL_REGISTER + "?code=" + code);
    }
  }


  function handleLoginBtn() {
    if (ls.get("gR97lPRIn797")["PYTLczcty9uH"]) {
      setKeptUsername(ls.get("gR97lPRIn797")["Do3SwI4cuvqR"]);
      setKeptPassword(ls.get("gR97lPRIn797")["gR1IPGBN01dS"]);
      //setValueLogin("loginUsername", ls.get("gR97lPRIn797")["Do3SwI4cuvqR"].toString());
      //setValueLogin("loginPassword", ls.get("gR97lPRIn797")["gR1IPGBN01dS"].toString());
    }
    setShowLoginPopup(true);
  }

  async function submitForgotPasswordForm(data) {

    _dispatch(setBusy());
    let formData = {
      "language": i18n.language,
      "username": data.username
    };
    let formBody = createMultiPartFormBody(formData);
    var responseJson = await ApiEngine.post(
      ApiUrl._API_SEND_RESET_PASSWORD_SMS,
      formBody
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setUsername(data.username)
      //setPhoneNumber(responseJson.data)
      setTempPhoneNum(responseJson.data)
      setVerifyPhoneTAC(true)
      setIsForgetPassword(false)
      setTimeout(() => {
        setCountdownTAC(true)
      }, 120000);
      //resetForm();
    }
    else {
      _dispatch(showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY]))
    }
    _dispatch(setIdle());
  }

  async function submitVerifyTAC(data) {
    _dispatch(setBusy());
    if (verifyState.button == 1) {

      let formData = {
        "taccode": data.taccode,
        "username": username
      };
      let formBody = createMultiPartFormBody(formData);
      let TACjson = await ApiEngine.post(
        ApiUrl._API_VERIFY_RESET_PASSWORD_SMS,
        formBody
      );
      if (TACjson[ApiKey._API_SUCCESS_KEY]) {
        setVerifyPhoneTAC(false)
        setIsUpdatePassword(true)
        //resetForm();
      }
    } else if (verifyState.button == "2") {

      let formData = {
        "language": i18n.language,
        "username": username
      };
      let formBody = createMultiPartFormBody(formData);
      //data["phoneNumber"] = formattedPhoneNumber;
      data["language"] = i18n.language;
      var responseJson = await ApiEngine.post(
        ApiUrl._API_SEND_RESET_PASSWORD_SMS,
        formBody
      );

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        setCountdownTAC(false)
        setUsername(data.username)
        //setPhoneNumber(responseJson.data)
        setTempPhoneNum(responseJson.data)
        setVerifyPhoneTAC(true)
        setIsForgetPassword(false)
        setTimeout(() => {
          setCountdownTAC(true)
        }, 120000);
        //resetForm();
      }
    }

    _dispatch(setIdle());
  }


  async function submitNewPassword(data) {
    _dispatch(setBusy());
    let formData = {
      "newpassword": data.newPassword,
      "confpassword": data.confPassword,
      "username": username
    };
    let formBody = createMultiPartFormBody(formData);

    var responseJson = await ApiEngine.post(
      ApiUrl._API_MEMBER_RESET_PASSWORD_BEFORE_LOGIN,
      formBody
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setIsUpdatePassword(false)
      setIsPasswordUpdated(true)
    }
    _dispatch(setIdle());
  }

  async function submitPasswordUpdated(data) {
    setIsUpdatePassword(false)
    setIsPasswordUpdated(false)
    setIsLogin(true);
  }

  const onChangeKeyword = useCallback(
    debounce(async (keyword) => {
      let responseJson = await ApiEngine.get(
        ApiUrl._API_GET_PRODUCT_GAME_LIST +
        "?keyword=" +
        keyword
      );
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        setSearchData(responseJson[ApiKey._API_DATA_KEY]);
      }
      setLoadingSearchData(false);
    }, 1000),
    []
  );

  useEffect(() => {
    if (isLoggedIn) {
      _dispatch(updateUserData());
      ManageBanner();
    }
  }, [isLoggedIn]);

  // sets/removes popup banners
  function ManageBanner(isRead) {
    let apiUrl = ApiUrl._API_MANAGE_BANNER_NOTIFICATION;
    if (typeof isRead != "undefined") {
      apiUrl += "?isRead=" + isRead + "&languageCode=" +
        (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
          localStorage.getItem(SessionKey._LANGUAGE) : 'id');
    }
    fetch(apiUrl, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          let data = responseJson[ApiKey._API_DATA_KEY];
          if (data.filter((x) => x["isDisplay"] === true).length > 0) {
            setShowPopupBanner(true);
          } else {
            setShowPopupBanner(false);
          }

          if (isLoggedIn) {
            fetch(ApiUrl._API_GET_USER_BANNER + "?page=" + window.location.pathname + "&isPopup=true&isDesktop=true&languageCode=" +
              (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
                localStorage.getItem(SessionKey._LANGUAGE) : 'id'), {
              method: ApiKey._API_GET,
              headers: {
                "Content-Type": ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
              },
            })
              .then((response) => response.json())
              .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                  let data = responseJson[ApiKey._API_DATA_KEY].filter(x => x.displayDevice === DisplayDevice._DESKTOP_POPUP && x.isDisplay === true && x.isRead === false);
                  setPopupBannerData(data);
                }
              });
          }

        }
      });
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === popupBannerData.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? popupBannerData.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const onClickGameHandler = (data) => {
    const getGameNameUm = selectedGameProvider.find(i => {
      return i.gameName === data.gameName && i.gameUm == true;
    });

    if (getGameNameUm === undefined) {
      window.open(
        `${process.env
          .REACT_APP_ENDPOINT_URL ||
        ""
        }${ApiUrl._API_START_GAME
        }?gameId=${data.gameId
        }&isApp=${data.isApp
          ? 1
          : 0
        }&device=d`
      )
    }
    else {
      _dispatch(
        showMessage({
          type: AlertTypes._INFO,
          content:
            t(
              "GAME_UM"
            ),
          onConfirm:
            () => {
            },
        })
      );
    }
  }

  const tempUpdateUserData = async () => {
    let walletResponse = await ApiEngine.get(ApiUrl._API_GET_MEMBER_WALLET_BALANCE);
    if (walletResponse[ApiKey._API_SUCCESS_KEY]) {
      let data = walletResponse[ApiKey._API_DATA_KEY];
      setCurrentWalletBalance(data["primaryCredit"]);
      if (data["count"] == false) {
        setGetBalanceInterval(30000);
      }
      else {
        setGetBalanceInterval(5000);
      }
    }
  }

  function setGetBalanceInterval(mmTimer) {
    clearInterval(walletBalanceInterval.current);
    walletBalanceInterval.current = setInterval(tempUpdateUserData, mmTimer);
    //clearTimeout(tempInterval);
    //tempInterval = setTimeout(() => {
    //  tempUpdateUserData();
    //}, mmTimer);
  }

  const handleCountryOnClick = async (countryItem, selectedLanguage) => {
    if (window.location.href.startsWith(countryItem.url) || (window.location.href.includes("localhost") && countryItem.iso == "ID")) {
      if (typeof (selectedLanguage) !== "undefined") {
        onChangeLanguage(selectedLanguage);
      }
    }
    else {
      let redirectUrl = countryItem.url + "?languageCode=" + (typeof (selectedLanguage) !== "undefined" ? selectedLanguage : "id");
      window.location.assign(redirectUrl);
    }
    setShowLanguageDropdown(false);
  }

  const getCompanyContent = async (/*page*/) => {
    let langId = LanguageOption.find(
      (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
    ).languageId;
    //let responseJson = await ApiEngine.get(ApiUrl._API_GET_COMPANY_CONTENT + "?page=" + page + "&languageId=" + selectedLanguageId);
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_COMPANY_CONTENT + "?page=RegisterPopup&languageId=" + langId);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY][0];
      setRegisterPopupContent(data["content"]);
      //let tempCompanyContentList = companyContentList;
      //if (tempCompanyContentList.length === 0 || tempCompanyContentList.filter(x => x.key === page).length === 0) {
      //  tempCompanyContentList.push(page, data["content"]);
      //  setCompanyContentList(tempCompanyContentList);
      //}
    }
  }

  return (
    <>
    <div id="header-wrapper" className={className} style={{ backgroundColor: `#001a3d42`, backgroundRepeat: "no-repeat" }}>
      <div id={isSettingsDropdownVisible && isSettingsDropdownVisibleSideBar ? "dropdown-visibile header" : "header"} className={className}>
        <header className="login"> 
          <h1 className="hidden">I8</h1>
          <h2 className="hidden">I8</h2>
          <h3 className="hidden">I8</h3>
          <h4 className="hidden">I8</h4>
          <h5 className="hidden">I8</h5>
          <h6 className="hidden">I8</h6>
          <div className="container">
            <div className="row mob-row">
              <div className="col-md-12">
                <div className="date-lang-box mb-3">
                  <div>
                    {displayDateTime.substring(0, displayDateTime.indexOf("+")) +
                      "GMT(" +
                      displayDateTime.substring(
                        displayDateTime.indexOf("+"),
                        displayDateTime.length
                      ) +
                      ")"}
                  </div>
                  <div onClick={() => setShowLanguageDropdown(!showLanguageDropdown)}>
                    <div className="lang-toggle">
                      <div className="lang-country-flag"><img src="/img/id-circle.svg" width="30" height="30" alt="country flag" /></div>
                      <div className="lang-name-short">{selectedLanguage}</div>
                      <div><i className="fas fa-caret-down"></i></div>
                    </div>
                    {showLanguageDropdown && <div className="lang-dropdown">
                      {countryList.filter(x => isLoggedIn ? x.iso === "ID" : true).map((country, index) => {
                        return (
                          <div className="header-dropdown-box" key={index}>
                            <div className="display-flex">
                              <div className="lang-square-flag">
                                <img
                                  src={country.icon_circle}
                                  width="30"
                                  height="30"
                                  alt="country flag"
                                />
                              </div>

                            </div>
                            <div>
                              <div className="lang-country-name">
                                {country.name}
                              </div>
                              <div className="display-flex">
                                {country.child_languages.map((lang, i) => {
                                  return (
                                    <div onClick={() => handleCountryOnClick(country, lang.languageCode)} className={"dropdown-lang" + (selectedLanguageCode.toLowerCase() === lang.languageCode ? " active" : "")} key={i}>
                                      {lang.name}
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>}
                  </div>
                </div>
                <div className="head-box">
                  <div className="logo">
                    <img
                      src="/img/i8.live_2024.svg"
                      className="img-responsive"
                      alt="logo"
                      style={{ cursor: "pointer", width: "160px" }}
                      onClick={() => _history.push(WebUrl._URL_MAIN)}
                      width="150"
                      height="50"
                    />
                  </div>
                  <div className="menu">
                    <nav className="navbar navbar-expand-md p-0 navbar-light">
                      <div className="">
                        <div className="mini-nav-btn">
                          <button
                            className="navbar-toggler"
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarNav"
                            aria-controls="navbarNav"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                          >
                            <span className="">
                              <i className="fa fa-bars"></i>
                            </span>
                          </button>
                        </div>
                        <div
                          className="collapse navbar-collapse text-lg-center"
                          id="navbarNav"
                        >
                          <ul className="navbar nav main-nav list-inline">
                              <li className="position-relative">
                                <div
                                  className={
                                    isSearchInputVisible
                                      ? searchKeyword !== ""
                                        ? "hsearch search-not-empty"
                                        : "hsearch"
                                      : "hsearch clicked"
                                  }
                                  ref={headerSearchBar}
                                >
                                  <input
                                    className="hsearch-input"
                                    id="hsearch-input"
                                    type="text"
                                    placeholder={t("SEARCH")}
                                    name="search"
                                    autoComplete="off"
                                    onInput={(e) => {
                                      let keyword = e.target.value;
                                      setLoadingSearchData(true);
                                      if (isSearchInputVisible) {
                                        setSearchKeyword(keyword);
                                      }
                                      onChangeKeyword(keyword);
                                    }}
                                  ></input>
                                  <a
                                    className="hsearch-btn"
                                    onClick={() => {
                                      setIsSearchInputVisible(
                                        !isSearchInputVisible
                                      );
                                    }}
                                  >
                                    <img
                                      src="/img/search.svg"
                                      className="img-responsive"
                                      alt="search"
                                      width="20"
                                      height="20"
                                    />
                                  </a>
                                  {isSearchInputVisible && searchKeyword !== "" && (
                                    <div
                                      className={
                                        "header-search-dropdown text-white" +
                                        (loadingSearchData ? " text-center" : "")
                                      }
                                      ref={headerSearchResult}
                                    >
                                      {!loadingSearchData ? (
                                        <>
                                          {gameCategories.map((category, index) => {
                                            if (
                                              searchData.filter((x) =>
                                                category.originalTitle !== "All"
                                                  ? x.categoryName ===
                                                  category.originalTitle
                                                  : true
                                              ).length > 0
                                            ) {
                                              return (
                                                <div key={index}>
                                                  <span className="font-12 text-gold"
                                                    key={index}>
                                                    {t(category.originalTitle)}
                                                  </span>
                                                  {searchData.length > 0 &&
                                                    searchData.filter((x) =>
                                                      category.originalTitle !== "All"
                                                        ? x.categoryName ===
                                                        category.originalTitle
                                                        : true
                                                    ).length > 0 &&
                                                    searchData
                                                      .filter((x) =>
                                                        category.originalTitle !==
                                                          "All"
                                                          ? x.categoryName ===
                                                          category.originalTitle
                                                          : true
                                                      )
                                                      .map((data, index) => {
                                                        return (
                                                          <div key={index}>
                                                            <ul className="list-inline"  >
                                                              <li>
                                                                <a
                                                                  onClick={() => {
                                                                    if (isLoggedIn) {
                                                                      onClickGameHandler(data);
                                                                      //         window.open(
                                                                      //             `${process.env
                                                                      //               .REACT_APP_ENDPOINT_URL ||
                                                                      //             ""
                                                                      //             }${ApiUrl._API_START_GAME
                                                                      //             }?gameId=${data.gameId
                                                                      //             }&isApp=${data.isApp
                                                                      //               ? 1
                                                                      //               : 0
                                                                      //             }&device=d`
                                                                      //           )
                                                                      //     }

                                                                    } else {
                                                                      _dispatch(
                                                                        showMessage({
                                                                          type: AlertTypes._INFO,
                                                                          content:
                                                                            t(
                                                                              "PLEASE_LOGIN_FIRST"
                                                                            ),
                                                                          onConfirm:
                                                                            () => {

                                                                            },
                                                                        })
                                                                      );
                                                                    }
                                                                  }}
                                                                  className="font-14"
                                                                >
                                                                  {data.gameName} - {data.provider}
                                                                </a>
                                                              </li>
                                                            </ul>
                                                          </div>
                                                        );
                                                      })}
                                                </div>
                                              );
                                            }
                                          })}
                                          <div className="text-gold font-14 mt-1">
                                            {t("END_OF_RESULT")}
                                          </div>
                                        </>
                                      ) : (
                                        <Spinner animation="border" role="status">
                                          {/* <span className="sr-only">Loading...</span> */}
                                        </Spinner>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </li>
                              {/*<li className="nav-item" onClick={() => localStorage.setItem(SessionKey._ISONLOAD, true)}>
                                    <a
                                      className={`nav-link ${activeTab == 1 && "active"
                                        }`}
                                      onClick={() => {
                                        headerTab(1);
                                        _history.push(WebUrl._URL_MAIN);  
                                      }}
                                    >
                                      {t("HOME")}
                                    </a>
                                  </li>*/}
                              <li className="nav-item parent" ref={headerDropdown}>
                                <a
                                  className={`nav-link ${activeTab == 2 &&
                                    isGameDropdownVisible &&
                                    "active"
                                    }`}
                                  id="menu-game"
                                  onClick={() => {
                                    setIsGameDropdownVisible(
                                      !isGameDropdownVisible
                                    );
                                    headerTab(2);
                                  }}
                                >
                                  {t("GAMES")}
                                  <span>
                                    <i
                                      className={
                                        activeTab == 2 && isGameDropdownVisible
                                          ? "icon-up-down-arrow fas fa-chevron-up"
                                          : "icon-up-down-arrow fas fa-chevron-down"
                                      }
                                    ></i>
                                  </span>
                                </a>
                                <ul
                                  className="list-unstyled child"
                                  onClick={() => localStorage.setItem(SessionKey._ISONLOAD, true)}
                                  id="sub-game"
                                  style={{
                                    display: isGameDropdownVisible
                                      ? "block"
                                      : "none",
                                  }}
                                >
                                  {gameCategories &&
                                    gameCategories.length &&
                                    map(
                                      gameCategories.filter(
                                        (x) =>
                                          x.originalTitle !== "Virtual-Sports" && x.originalTitle !== "V.Sports" &&
                                          x.originalTitle !== "Fish Games"
                                      ),
                                      (item, index) => {
                                        if (selectedGameProvider && selectedGameProvider.filter(
                                          (n) => n.categoryTitle === item.originalTitle
                                        ).length > 0) {
                                          return (
                                            <li
                                              style={{ userSelect: "none" }}
                                              key={"game_category_" + index}
                                            >
                                              <Link to={"/" + item.originalTitle.toLowerCase()}

                                                onClick={() => {
                                                  setIsGameDropdownVisible(false);
                                                }}
                                              >
                                                <div className="first">
                                                  <img
                                                    src={`/img/game-category/svg/${item.originalTitle}.svg`}
                                                    className="img-responsive"
                                                    style={{
                                                      maxWidth: 22,
                                                      maxHeight: 22,
                                                    }}
                                                    alt="games"
                                                  />
                                                </div>
                                                <div className={"second" + (item.originalTitle == "Connect" ? " connect-font" : "")}>
                                                  {t(item.originalTitle)}
                                                </div>
                                              </Link>
                                            </li>
                                          );
                                        }
                                      }
                                    )}
                                </ul>
                              </li>
                              {isLoggedIn && <li className="nav-item parent" ref={headerTxnDropdown}>
                                <a
                                  className={`nav-link ${activeTab == 2 &&
                                    isTxnDropdownVisible &&
                                    "active"
                                    }`}
                                  id="menu-game"
                                  onClick={() => {
                                    setIsTxnDropdownVisible(
                                      !isTxnDropdownVisible
                                    );
                                    headerTab(2);
                                  }}
                                >
                                  {t("TRANSACTION")}
                                  <span>
                                    <i
                                      className={
                                        activeTab == 2 && isTxnDropdownVisible
                                          ? "icon-up-down-arrow fas fa-chevron-up"
                                          : "icon-up-down-arrow fas fa-chevron-down"
                                      }
                                    ></i>
                                  </span>
                                </a>
                                <ul
                                  className="list-unstyled child"
                                  onClick={() => localStorage.setItem(SessionKey._ISONLOAD, true)}
                                  id="sub-game"
                                  style={{
                                    display: isTxnDropdownVisible
                                      ? "block"
                                      : "none",
                                  }}
                                >
                                  {txnList &&
                                    txnList.length &&
                                    map(txnList, (txn, index) => {
                                      return (
                                        <li
                                          style={{ userSelect: "none" }}
                                          key={"game_category_" + index}
                                        >
                                          <a
                                            className="nav-link"
                                            onClick={() => {
                                              setIsTxnDropdownVisible(false);
                                              _history.push(txn.link);
                                            }}
                                          >
                                            <div className="first">
                                              {<img
                                                src={txn.image}
                                                className="img-responsive"
                                                style={{
                                                  maxWidth: 22,
                                                  maxHeight: 22,
                                                }}
                                                alt="txn"
                                              />}
                                            </div>
                                            <div className="second">
                                              {t(txn.name)}
                                            </div>
                                          </a>
                                        </li>
                                      );
                                    }
                                    )}
                                </ul>
                              </li>}
                              <li className="nav-item parent" onClick={() => localStorage.setItem(SessionKey._ISONLOAD, true)}>
                                <a className="nav-link" onClick={() => _history.push(WebUrl._URL_PROMOTION)}>
                                  {t("LIVE_PROMOTION")}
                                </a>
                              </li>
                              <li className="nav-item parent" onClick={() => localStorage.setItem(SessionKey._ISONLOAD, true)}>
                                <a className="nav-link" href={rtpUrl} target="_blank">
                                  {t("RTP Live")}
                                </a>
                              </li>
                              <li className="nav-item parent" onClick={() => localStorage.setItem(SessionKey._ISONLOAD, true)}>
                                <a
                                  className={`nav-link ${activeTab == 4 && "active"
                                    }`}
                                  href={downloadURL}
                                  onClick={() => {
                                    headerTab(4);
                                  }}
                                >
                                  {t("DOWNLOAD")}
                                  <span>
                                    <img
                                      src="/img/mobile.svg"
                                      className="img-responsive"
                                      alt="mobile"
                                      width="15"
                                      height="23"
                                    />
                                  </span>
                                </a>
                              </li>
                              <li className="nav-item">
                                {!isLoggedIn ? (
                                  <div className="sign-register-box">
                                    <div className="sign-in-btn">
                                      <a
                                        className="nav-link"
                                        href="#"
                                        onClick={() => handleLoginBtn()}
                                      >
                                        {t("LOGIN")}
                                      </a>
                                    </div>
                                    <div className="register-btn">
                                      <a
                                        className="nav-link"
                                        href="#"
                                        onClick={() => setShowRegisterPopup(true)}
                                      >
                                        {t("SIGNUP")}
                                      </a>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="flex-login">
                                    <div className="header-account">
                                      <div className="refresh-wallet-btn" style={{ margin: "7.5px 10px 0 -10px", cursor: "pointer" }} onClick={() => tempUpdateUserData()}>
                                        <i className="fas fa-redo-alt" />
                                        <div className="tooltip-text">{t("CLICK_TO") + " " + t("REFRESH_WALLET_SMALL_CAP")}</div>
                                      </div>
                                      <div className="header-currency">
                                        <div className="font-11 mb-1 header-user-name">
                                          {_userData["username"]}
                                        </div>
                                        <div
                                          className="header-wallet-balance"
                                          onClick={() =>
                                            _history.push(WebUrl._URL_MAIN_WALLET)
                                          }
                                        >
                                          {numberWithCurrencyFormat2(
                                            (isNaN(currentWalletBalance) ? 0 : currentWalletBalance),
                                            2,
                                            true
                                          )}
                                          <div className="tooltip-text">
                                            {t("CLICK_TO") +
                                              " " +
                                              t("VIEW_WALLET_SMALL_CAP")}
                                          </div>
                                        </div>
                                        {/* <div 
                                        className="profile-picture" 
                                        onClick={() => {
                                            setIsSettingsDropdownVisible(
                                            !isSettingsDropdownVisible
                                            );
                                        }}
                                        >
                                        <img
                                            src={require("../../assets/img/assets/profile.png")}
                                            className="img-responsive"
                                            alt="profile"
                                        />
                                        </div> */}
                                        <div
                                          className="drop-shortcut"
                                          onClick={() => {
                                            setIsSettingsDropdownVisible(
                                              !isSettingsDropdownVisible
                                            );
                                            localStorage.setItem('isSettingsDropdownVisibleSideBar', true);
                                          }}
                                        >
                                          <img
                                            src="/img/arrow-down.png"
                                            className="img-responsive"
                                            alt="drop"
                                            width="13"
                                            height="10"
                                          />
                                        </div>
                                      </div>
                                      {/*<div
                                    className="login-dropdown-btn"
                                    
                                    >
                                    <span>
                                        <img
                                        src={require("../../assets/img/assets/arrow-down.png")}
                                        className="img-responsive"
                                        alt="profile"
                                        />
                                    </span>
                                    </div>*/}
                                    </div>
                                  </div>
                                )}
                              </li>
                          </ul>
                        </div>
                      </div>
                    </nav>
                  </div>
                </div>
                <div
                  className="settings-dropdown"
                  id="settings-dropdown"
                  style={{
                    display: isSettingsDropdownVisible && isSettingsDropdownVisibleSideBar ? "block" : "none",
                  }}
                >
                  <ul className="list-unstyled">
                    {settingsList &&
                      settingsList.length &&
                      map(settingsList, (item, index) => {
                        return (
                          <li key={"settings_list_" + index}>
                            <a
                              className="cursor-pointer"
                              onClick={() => {
                                _history.push(item["redirectUrl"]);
                                setIsSettingsDropdownVisible(false);
                              }}
                            >
                              <div className="nav-img">
                                <img
                                  src={item.image}
                                  className="img-responsive"
                                  alt="nav"
                                />
                              </div>
                              <p className="text-white">{t(item.text)}</p>
                            </a>
                          </li>
                        );
                      })}
                    <li>
                      <a
                        className="logout-btn"
                        onClick={() => {
                          _dispatch(performLogout());
                          clearTimeout(tempInterval);
                          _history.replace(WebUrl._URL_MAIN);
                          setIsSettingsDropdownVisible(false);
                          localStorage.removeItem('isReadAllNotification');
                          localStorage.removeItem('unreadNotificationCount');
                        }}
                      >
                        <div className="nav-img">
                          {/* <div className='logout-icon img-responsive'></div> */}
                          <img
                            src="/img/nav/Logout_white.svg"
                            className="logout-white"
                            alt="white nav"
                          />

                          <img
                            src="/img/nav/logout.svg"
                            className="logout-color "
                            alt="color nav"
                          />
                        </div>
                        <p>{t("LOGOUT")}</p>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </header>

        {showPopupBanner && popupBannerData.length > 0 ? (
          <div>
            <div
              onClick={() => ManageBanner()}
              className="banner-popup-wrapper"
            ></div>
            <i
              className="close-popup fa fa-times"
              onClick={() => ManageBanner(true)}
            />
            <div className="dashboard-carousel-wrapper">
              <ReactCarousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
                indicator={true}
                interval={3000}
              >
                <CarouselIndicators
                  items={popupBannerData}
                  activeIndex={activeIndex}
                  onClickHandler={goToIndex}
                />
                {popupBannerData.map((banner, index) => (
                  <CarouselItem
                    onExiting={() => setAnimating(true)}
                    onExited={() => setAnimating(false)}
                    key={index}
                  >
                    <a
                      href="#"
                      target="_blank"
                      onClick={(e) => {
                        e.preventDefault();
                        if (!stringIsNullOrEmpty(banner.url)) {
                          window.open(banner.url);
                          ManageBanner(true);
                        }
                      }}
                    >
                      <LazyLoadImage
                        className="carousel-img"
                        src={banner.desktopImage}
                        alt={banner.content}
                        width="1600"
                        height="900"
                      />
                    </a>
                  </CarouselItem>
                ))}
              </ReactCarousel>
            </div>
          </div>
        ) : <></>}

        {/*Register Modal*/}
        <Modal
          isOpen={showRegisterPopup}
          contentClassName="register-popup-modal-body-wrapper modal-brand"
          wrapClassName="register-popup-modal"
          toggle={() => {
            setShowRegisterPopup(false);
            setShowCodeError(false);
          }}
          centered
        >
          <ModalBody>
            <div className="register-popup-wrapper">
              <div className="referral-code-tips">
                <div className="mb-4">{t("PLEASE_ENTER_UPLINE_CODE")}</div>
                {/* <div>{t("EXAMPLE_SHORT") + "AbCd3fGH +"}</div> */}
              </div>
              <input
                className="custom-input-style-1 mb-3"
                type="text"
                placeholder={t("EG") + " AbCd3fGH +"}
                onChange={(e) => handleReferralCode(e.target.value)}
              />
              {showCodeError && (
                <div className="referral-code-error invalid-feedback">
                  {t("REFERRAL_CODE_INVALID")}
                </div>
              )}

              {!stringIsNullOrEmpty(registerPopupContent) && <div className="referral-code-text">
                <span className="color-red">*</span>{parse(registerPopupContent)}
              </div>}
              <button
                className="custom-btn-style-1"
                type="button"
                onClick={() => {
                  checkIfReferralExist(referralCode);
                }}
              >
                {t("REGISTER")}
              </button>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={showLoginPopup}
          contentClassName="login-popup-modal-body-wrapper modal-brand"
          wrapClassName="login-popup-modal"
          toggle={() => {
            setShowLoginPopup(false);
            setIsForgetPassword(false)
            setVerifyPhoneTAC(false)
            setIsUpdatePassword(false)
            setIsPasswordUpdated(false)
            setIsLogin(true)
          }}
          centered
        >
          <ModalBody>
            <div className="login-popup-wrapper">
              <div
                className="login-popup-left">
                <div className="login-popup-image"
                  style={{ backgroundImage: `url(${Popupi81})` }}
                ></div>
              </div>
              <div className="login-popup-right">

                {isLogin && (
                  <form onSubmit={handleSubmitLogin(submitLoginForm)}>
                    <Input
                      type="text"
                      name="loginUsername"
                      id="loginUsername"
                      className="login-username custom-input-style-1"
                      placeholder={t("USERNAME")}
                      autoComplete="off"
                      defaultValue={keptUsername}
                      ref={registerLogin({
                        required: true,
                      })}
                      errors={errorsLogin}
                    />
                    {errorsLogin.loginUsername && (
                      <div className="invalid-feedback">
                        {t(errorsLogin.loginUsername.message)}
                      </div>
                    )}
                    <Input
                      type="password"
                      name="loginPassword"
                      id="loginPassword"
                      className="login-password custom-input-style-1"
                      placeholder={t("PASSWORD")}
                      defaultValue={keptPassword}
                      autoComplete="off"
                      ref={registerLogin({
                        required: true,
                      })}
                      errors={errorsLogin}
                    />
                    {errorsLogin.loginPassword && (
                      <div className="invalid-feedback">
                        {t(errorsLogin.loginPassword.message)}
                      </div>
                    )}
                    <div className="login-checkbox-btn-wrapper">
                      <div className="login-keep-logged-in checkbox-wrapper">
                        <input
                          type="checkbox"
                          name="isKeepedLogin"
                          ref={registerLogin()}
                          errors={errorsLogin}
                        />
                        <span>{t("KEEP_LOGGED_IN")}</span>
                      </div>
                      <div className="login-remember-password checkbox-wrapper">
                        <input
                          type="checkbox"
                          name="isKeepedPassword"
                          ref={registerLogin()}
                          errors={errorsLogin}
                          defaultChecked={ls.get("gR97lPRIn797")["PYTLczcty9uH"]}
                        />
                        <span>{t("KEEP_PASSWORD")}</span>
                      </div>
                    </div>
                    <button className="custom-btn-style-1" type="submit">
                      {t("LOGIN")}
                    </button>
                    <div className="forgot_password_div">
                      <span className="forgot_password_txt_btn"
                        onClick={() => { setIsForgetPassword(true); setIsLogin(false) }}
                      >
                        {t("FORGOT_PASSWORD_QUES")}
                      </span>
                    </div>

                  </form>
                )}
                {isForgetPassword && (
                  <form onSubmit={forgotPasswordHandleSubmit(submitForgotPasswordForm)}>
                    <img
                      src="/img/back_icon.png"
                      className="forgot-password-back-btn"
                      onClick={() => { setIsForgetPassword(false); setIsLogin(true) }}
                    />
                    <span style={{ color: 'white', fontFamily: "averta-bold", size: '13px', paddingLeft: '20px' }}>{t("FORGOT_PASSWORD")}</span>

                    <div className="buttonIn">
                      <Input
                        type="text"
                        name="username"
                        id="username"
                        className="custom-input-style-1 input"
                        style={{ marginTop: '20px' }}
                        placeholder={t("USERNAME")}
                        autoComplete="off"
                        defaultValue={keptUsername}
                        ref={forgotPasswordRegister({
                          required: true,
                        })}
                        errors={forgotPasswordErrors}
                      />

                      <button className="custom-btn-style-1" type="submit">
                        {t("SEND_VIA_WHATSAPP")}
                      </button>
                    </div>

                    {errorsLogin.loginUsername && (
                      <div className="invalid-feedback">
                        {t(errorsLogin.loginUsername.message)}
                      </div>
                    )}
                  </form>)}
                {verifyPhoneTAC && (
                  <form onSubmit={tacVerifyHandleSubmit(submitVerifyTAC)}>
                    <span style={{ color: 'white', fontFamily: "averta-bold", size: '13px' }}>{t("VERIFY_YOUR_NUMBER")}</span>
                    <div>
                      <Input
                        type="text"
                        id="taccode"
                        name="taccode"
                        ref={tacVerifyRegister()}
                        className="custom-input-style-1 input"
                        style={{ marginTop: '20px' }}
                        placeholder={t("OTP_CODE")}
                        autoComplete="off"
                        onChange={(e) => setEnteredTAC(e.target.value)}
                      />
                      <div className="tac_send">
                        <span>{t("PHONE_OTP_SEND_TO")}{tempPhoneNum}</span>
                        <button className="custom-btn-style-1" type="submit" onClick={() => verifyState.button = "1"}>
                          {t("CONTINUE")}
                        </button>
                        {countdownTAC && (
                          <div className="tac_send_again">
                            <button type="submit" onClick={() => verifyState.button = "2"}>
                              {t("DIDNT_RECEIVE_A_CODE")}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                )}
                {isUpdatePassword && (
                  <form onSubmit={ResetPasswordHandleSubmit(submitNewPassword)}>
                    <span style={{ color: 'white', fontFamily: "averta-bold", size: '13px' }}>{t("RESET_YOUR_PASSWORD")}</span>
                    <div>
                      <span style={{ color: 'white', fontFamily: "averta-bold", size: '14px' }}>{t("NEW_PASSWORD")}</span>
                      <Input
                        type="password"
                        name="newPassword"
                        id="newPassword"
                        className="custom-input-style-1 input"
                        style={{ marginTop: '20px' }}
                        placeholder={t("NEW_PASSWORD")}
                        autoComplete="off"
                        defaultValue={keptUsername}
                        ref={ResetPasswordRegister({
                          required: true,
                          minLength: {
                            value: _MIN_PASSWORD_LENGTH,
                            message: t("MIN_CHARACTERS", {
                              length: _MIN_PASSWORD_LENGTH,
                            }),
                          },
                          validate: (value) =>
                            watch("confPassword") == "" ||
                            value == watch("confPassword") ||
                            "PASSWORD_MISMATCH",
                        })}
                        errors={ResetPasswordErrors}
                      />
                      <span style={{ color: 'white', fontFamily: "averta-bold", size: '14px' }}>{t("CONFIRM_NEW_PASSWORD")}</span>
                      <Input
                        type="password"
                        name="confPassword"
                        id="confPassword"
                        className="custom-input-style-1 input"
                        style={{ marginTop: '20px' }}
                        placeholder={t("CONFIRM_NEW_PASSWORD")}
                        autoComplete="off"
                        defaultValue={keptUsername}
                        ref={ResetPasswordRegister({
                          required: true,
                          minLength: {
                            value: _MIN_PASSWORD_LENGTH,
                            message: t("MIN_CHARACTERS", {
                              length: _MIN_PASSWORD_LENGTH,
                            }),
                          },
                          validate: (value) =>
                            watch("newPassword") == "" ||
                            value == watch("newPassword") ||
                            "PASSWORD_MISMATCH",
                        })}
                        errors={ResetPasswordErrors}
                      />
                      <button className="custom-btn-style-1" type="submit">
                        {t("RESET_PASSWORD")}
                      </button>
                    </div>
                  </form>
                )}
                {isPasswordUpdated && (
                  <form onSubmit={PasswordUpdatedHandleSubmit(submitPasswordUpdated)}> 
                    <div className="reset_password_success">
                      <img
                        src="/img/success_icon.svg"
                        className="logout-white"
                        alt="white nav"
                      />
                      <span style={{ fontSize: '14px' }}>{t("SUCCESS")}</span>
                      <br />
                      <span style={{ fontSize: '10px' }}>{t("RESET_PASSWORD_SUCCESS")}</span>
                      <button className="custom-btn-style-1" style={{ position: 'relative', top: '65px' }} type="submit">
                        {t("CLOSE")}
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
      </div>
      <CustomSpinWheelContainer />
  </>

  );
};

export default Header;

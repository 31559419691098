import React, { useState, useEffect } from "react";
//import "react-simple-keyboard/build/css/index.css";
import Sidebar from "components/sidebar";
import { map } from "lodash";
import { WebUrl, _MAIN_WALLET_ID } from "../../util/Constant";
import { useHistory } from "react-router-dom";
import MenuRowItem from "components/menu-row/item";
import Button from "components/button";
import { numberWithCurrencyFormat, numberWithCurrencyFormat2 } from "../../util/Util";
import { useSelector, useDispatch } from "react-redux";
import ContentHeader from "components/content/header";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { ApiKey, ApiUrl } from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine";
import SweetAlert from "react-bootstrap-sweetalert";
import { useForm } from "react-hook-form";
import {
    showResponseMessage,
    setBusy,
    setIdle,
} from "../../redux/AppAction.js";
import { createMultiPartFormBody } from "../../util/Util";
import Spinner from "react-bootstrap/Spinner";
import { updateUserData } from "redux/AuthAction";

const MainWallet = (props) => {
    const { t } = useTranslation();
    const _history = useHistory();
    const { isLoggedIn } = useSelector((state) => state.authState);
    var _userData = useSelector((state) => state.authState.userData);
    const [rewardData, setRewardData] = useState([]);
    const [todayDate, setTodayDate] = useState(moment());
    const { handleSubmit } = useForm();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showRewardConfirmation, setShowRewardConfirmation] = useState(false);
    const [showClaimConfirmation, setShowClaimConfirmation] = useState(false);
    var _dispatch = useDispatch();
    const [isCardShown, setIsCardShown] = useState(false);
    const [wallets, setWallets] = useState([]);
    const [loadingWalletFlag, setLoadingWalletFlag] = useState(true);
    const [commission, setCommission] = useState('');
    const [totalReward7Days, setTotalReward7Days] = useState(0);
    const [unclaimLoyaltyPoints, setUnclaimLoyaltyPoints] = useState(0);
    const [memberPoint, setMemberPoint] = useState(0);

  const walletButtons = [
    {
      image: "/img/assets/w1.svg",
      text: "TRANSFER",
      link: WebUrl._URL_TRANSFER,
      rightButton: (
        <Button
          text="TRANSFER_NOW"
          type="ghost"
          size="small"
          onClick={() => _history.push(WebUrl._URL_TRANSFER)}
        />
      ),
    },
    {
      image: "/img/assets/w2.svg",
      text: "PENDING_TRANSACTION",
      link: WebUrl._URL_PENDING_TRANSACTION,
    },
    {
      image: "/img/assets/w3.svg",
      text: "TRANSACTION_HISTORY",
      link: WebUrl._URL_TRANSACTION_HISTORY,
    },
    {
      image:"/img/assets/w4.svg",
      text: "REWARDS_RECORD",
      link: WebUrl._URL_REWARD_HISTORY,
    },
    {
      image:"/img/assets/w5.svg",
      text: "COMMISSION_HISTORY",
      link: WebUrl._URL_COMMISSION_HISTORY,
    },
  ];

    useEffect(() => {
        init();
    }, []);

    async function GET_WALLET_INFO() {
        updateMainWalletBallance();
        setLoadingWalletFlag(true);

        ApiEngine.get(ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE).then(
            (responseJson) => {
                if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                    _dispatch(
                        showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
                    );
                } else {
                    setWallets(responseJson[ApiKey._API_DATA_KEY]);
                    setLoadingWalletFlag(false);
                }
            }
        );
    }

  const getMemberLoyaltyPoints = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_LOYALTY_POINTS_BY_ID + "?id=" + _userData.userId);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setMemberPoint(responseJson[ApiKey._API_DATA_KEY]);
    }
  }

  async function getCommission() {
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_UNCLAIM_COMMISSION_AMT
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      setCommission(data);
    }
  }

  async function getRewardRate() {
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_CATEGORY_REWARD_RATES + "?all=true"
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      let tempObj = {};
      let tempTotal7Days = 0;
      data.map((tempData) => {
        if (!tempObj[tempData["date"]]) {
          tempObj[tempData["date"]] = { totalAmount: 0 };
        }
        if (!tempObj[tempData["date"]][tempData["categoryTitle"]]) {
          tempObj[tempData["date"]][tempData["categoryTitle"]] = { totalAmount: 0 };
        }
        if (!tempObj[tempData["date"]][tempData["categoryTitle"]][tempData["providerName"]]) {
          tempObj[tempData["date"]][tempData["categoryTitle"]][tempData["providerName"]] = { productImage: tempData["productImage"], rate: tempData["rewardRate"] };
        }
        tempObj[tempData["date"]].totalAmount += tempData["amount"];
        tempObj[tempData["date"]][tempData["categoryTitle"]].totalAmount += tempData["amount"];
        if (tempData["claimed"] !== "True") {
          tempTotal7Days += tempData["amount"];
        }
      });
      setTotalReward7Days(tempTotal7Days);
      let temp = Object.keys(tempObj).sort();
      setTodayDate(moment(temp[temp.length - 1]));
      setRewardData(tempObj);
      GET_WALLET_INFO();
    }
  }



async function init() {
  GET_WALLET_INFO();
  getRewardRate();
  getCommission();
  getMemberLoyaltyPoints();
}

function preSubmit() {
    setShowConfirmation(true);
}

function preRewardSubmit() {
    setShowRewardConfirmation(true);
}

  function prePointsClaimSubmit() {
    setShowClaimConfirmation(true);
  }

async function submitForm() {
    {
        _dispatch(setBusy());

        let responseJson = await ApiEngine.post(
            ApiUrl._API_CLAIM_REWARD,
            createMultiPartFormBody({
                date: todayDate.clone().format("YYYY-MM-DD"),
                category: "",
              all: true
            })
        );

        _dispatch(
            showResponseMessage(
                responseJson[ApiKey._API_SUCCESS_KEY],
                responseJson[ApiKey._API_MESSAGE_KEY]
            )
        );
        _dispatch(setIdle());

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let temp = rewardData;
            temp[todayDate.format("YYYY-MM-DD")] = 0;
            setRewardData(temp);
            getRewardRate();
        }
    }
  }

  async function submitCommissionForm() {
    _dispatch(setBusy());
    let responseJson = await ApiEngine.post(
      ApiUrl._API_CLAIM_COMMISSION,
      createMultiPartFormBody({})
    );

    _dispatch(
      showResponseMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        responseJson[ApiKey._API_MESSAGE_KEY]
      )
    );
    _dispatch(setIdle());

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      getCommission();
      GET_WALLET_INFO();
    }
  }

  async function submitPointsClaimForm() {
    _dispatch(setBusy());
    let responseJson = await ApiEngine.post(
      ApiUrl._API_CLAIM_LOYALTY_POINTS,
      createMultiPartFormBody({})
    );

    _dispatch(
      showResponseMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        responseJson[ApiKey._API_MESSAGE_KEY]
      )
    );
    _dispatch(setIdle());

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      init();
    }
  }

  async function updateMainWalletBallance() {
    await _dispatch(updateUserData());
  }

return (
    <>
        <section className="section-padding" id="main-wallet-section">
            <div className="container">
                <div className="row">
                    <div className="col-3" id="account-menu">
                        <Sidebar activePage={"WALLET"} />
                    </div>
                    <div className="col-9">
                        <ContentHeader title={t("WALLET")} />
                        <hr className="right-title-hr" />
                        <div className="deposit-and-claim-box text-white">
                            <div className="deposit-divider dropdown">
                                <div
                                    className="first"
                                    id="mainwalletinfo"
                                    data-toggle="dropdown"
                                    aria-expanded="true"
                                    aria-haspopup="true"
                                >
                                    <div>
                                        <h4 className="mb-2">{t("MAIN_WALLET")}</h4>
                                        <p className="font-14 mb-0"
                                        >
                                            {" "}
                                            {/* MYR{" "} */}
                                            {numberWithCurrencyFormat2(
                                                _userData["walletBalance"],
                                                2,
                                                true
                                            )}
                                        </p>
                                    </div>
                                    <div className="dac-icon">
                                        <img
                                            src={"/img/circle-up.svg"}
                                            className="img-responsive img1"
                                            alt="deposit"
                                        />
                                        <img
                                            src={"/img/circle-down.svg"}
                                            className="img-responsive img2"
                                            alt="deposit"
                                        />
                                        <div className="tooltip-text2">
                                            {t("CLICK_TO") +
                                                " " +
                                                t("VIEW_GAME_WALLET_SMALL_CAP")}
                                        </div>
                                    </div>                           
                                </div>
                                
                                
                                <div className="dropdown-menu main-wallet-card font-15" id="mw-info-card" aria-labelledby="mainwalletinfo">
                                    <div className="container">
                                        <p style={{ paddingTop: "5%" }}>{t("WALLET_INFO")}</p>
                                        <hr className="walltet-info-divider" />

                                        {loadingWalletFlag && (
                                            <div
                                                style={{
                                                    textAlign: "center",
                                                    marginTop: "25px",
                                                    marginBottom: "20px",
                                                }}
                                            >
                                                <Spinner
                                                    animation="border"
                                                    role="status"
                                                    size="sm"
                                                    style={{
                                                        verticalAlign: "middle",
                                                        width: "2rem",
                                                        height: "2rem",
                                                    }}
                                                >
                                                    <span className="sr-only">{t("LOADING")}...</span>
                                                </Spinner>
                                            </div>
                                        )}

                                        {wallets.filter((i) => i.productId != 0)
                                            .map((wallet, index) => {
                                                return (
                                                    <div key={index} className="row wallet-info">
                                                        <p className="text-brand col-6 col-form-label">{wallet["productName"]}</p>
                                                        <p className="text-brand col-6 col-form-label text-right">{numberWithCurrencyFormat2(
                                                            parseFloat(
                                                                wallet["balance"] != "N/A" ? wallet["balance"] : 0
                                                            ),
                                                            2
                                                        )}</p>
                                                    </div>
                                                )
                                            })}
                                    </div>

                                </div>
                                
                                <div className="button-wrpper">
                                    <div className="second">
                                        <div>
                                            <Button
                                                text={t("DEPOSIT")}
                                                type="secondary"
                                                size="small"
                                                onClick={() => _history.push(WebUrl._URL_DEPOSIT)}
                                            />
                                        </div>
                                        <div>
                                            <Button
                                                text={t("WITHDRAWAL")}
                                                size="small"
                                                type="ghost"
                                                onClick={() => _history.push(WebUrl._URL_WITHDRAWAL)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="deposit-divider">
                                <div className="first">
                                    <div>
                                        {showConfirmation && (
                                          <SweetAlert
                                            info
                                            showCancel
                                            confirmBtnText={t("CONFIRM")}
                                            cancelBtnText={t("CANCEL")}
                                            confirmBtnBsStyle="info"
                                            title={t("CONFIRM_CLAIM")}
                                            onConfirm={() => {
                                              setShowConfirmation(false);
                                              handleSubmit(submitCommissionForm)();
                                            }}
                                            onCancel={() => {
                                              setShowConfirmation(false);
                                            }}
                                          ></SweetAlert>
                                        )}
                                        <h4 className="mb-2">{t("COMMISSION")}</h4>
                                        <p className="font-14 mb-0">
                                            {/* MYR{" "} */}
                                            {commission == "" || commission['totalClaimable'] == 0 ? "_" : numberWithCurrencyFormat2(commission['totalClaimable'], 2, true)}
                                        </p>
                                    </div>
                                </div>
                                <div className="button-wrpper">
                                    <div className="second">
                                        <div>
                                          <Button
                                            text={t("CLAIM")}
                                            size="small"
                                            type="secondary"
                                            disabled={commission == "" || commission['totalClaimable'] == 0 || numberWithCurrencyFormat2(commission['totalClaimable'], 2, true) == "0.00"}
                                            onClick={() => {
                                                (commission['totalClaimable'] != 0 && commission['totalClaimable'] !== undefined && numberWithCurrencyFormat2(commission['totalClaimable'], 2, true) != "0.00") &&
                                                preSubmit()
                                                // commission['totalClaimable'] != 0 && preSubmit()
                                            }}
                                          />
                                        </div>
                                        <div>
                                          <Button
                                            text={t("VIEW")}
                                            size="small"
                                            type="ghost"
                                            onClick={() => _history.push(WebUrl._URL_UNCLAIM_COMMISSION_HISTORY)}
                                          />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="deposit-divider">
                                <div className="first">
                                    <div>
                                        <h4 className="mb-2">{t("PROMOTION")}</h4>
                                        <p className="font-14 mb-0">
                                            {/* MYR{" "} */}
                                            {totalReward7Days > 0
                                                ? numberWithCurrencyFormat2(totalReward7Days, 2, true)
                                                : "-"}
                                        </p>
                                    </div>
                                </div>
                                <div className="button-wrpper">
                                    <div
                                        className={
                                            totalReward7Days > 0
                                                ? "second"
                                                : "first-claim"
                                        }
                                    >
                                        <div>
                                            {showRewardConfirmation && (
                                                <SweetAlert
                                                    info
                                                    showCancel
                                                    confirmBtnText={t("CONFIRM")}
                                                    cancelBtnText={t("CANCEL")}
                                                    confirmBtnBsStyle="info"
                                                    title={t("CONFIRM_CLAIM")}
                                                    onConfirm={() => {
                                                        setShowRewardConfirmation(false);
                                                        handleSubmit(submitForm)();
                                                    }}
                                                    onCancel={() => {
                                                        setShowRewardConfirmation(false);
                                                    }}
                                                ></SweetAlert>
                                            )}

                                            {totalReward7Days > 0 && numberWithCurrencyFormat2(totalReward7Days, 2, true) != "0.00" && (
                                                    <Button
                                                        text={t("CLAIM")}
                                                        type="secondary"
                                                        size="small"
                                                        disabled={
                                                            totalReward7Days <= 0 || numberWithCurrencyFormat2(totalReward7Days, 2, true) == "0.00"
                                                        }
                                                        onClick={() =>
                                                            (totalReward7Days > 0 && numberWithCurrencyFormat2(totalReward7Days, 2, true) != "0.00") &&
                                                            preRewardSubmit()
                                                        }
                                                    />
                                                )}
                                        </div>
                                        <div>
                                            <Button
                                                text={t("VIEW")}
                                                size="small"
                                                type="ghost"
                                                onClick={() => _history.push(WebUrl._URL_REWARDS, { from: "/main-wallet" })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="deposit-divider">
                                <><div className="first">
                                    <div>
                                        <h4 className="mb-2">{t("POINTS")}</h4>
                                        <p className="font-14 mb-0">
                                            {/* MYR{" "} */}
                                            {numberWithCurrencyFormat(isLoggedIn ? memberPoint : 0, 2, true)}
                                        </p>
                                    </div>
                                </div>
                                <div className="button-wrpper">
                                    <div
                                        className={
                                            false
                                                ? "second"
                                                : "first-claim"
                                        }
                                    >
                                        <div>
                                            {showClaimConfirmation && (
                                                <SweetAlert
                                                    info
                                                    showCancel
                                                    confirmBtnText={t("CONFIRM")}
                                                    cancelBtnText={t("CANCEL")}
                                                    confirmBtnBsStyle="info"
                                                    title={t("CONFIRM_CLAIM")}
                                                    onConfirm={() => {
                                                        setShowClaimConfirmation(false);
                                                        submitPointsClaimForm();
                                                    }}
                                                    onCancel={() => {
                                                        setShowClaimConfirmation(false);
                                                    }}
                                                ></SweetAlert>
                                            )}

                                            {false && (
                                                    <Button
                                                        text={t("CLAIM")}
                                                        type="secondary"
                                                        size="small"
                                                        disabled={
                                                            unclaimLoyaltyPoints <= 0
                                                        }
                                                        onClick={() =>
                                                            unclaimLoyaltyPoints > 0 &&
                                                            prePointsClaimSubmit()
                                                        }
                                                    />
                                                )}
                                        </div>
                                        <div>
                                            <Button
                                                text={t("VIEW")}
                                                //text={t("COMING_SOON_LABEL")}
                                                //disabled
                                                size="small"
                                                type="ghost"
                                                onClick={() => _history.push(WebUrl._URL_LOYALTY_REDEMPTION, { from: "/main-wallet" })}
                                            />
                                        </div>
                                    </div>
                                </div></>
                            </div>

                            {/* will add on later */}
                            {/* <div className="deposit-divider">
                  <div className="first member-level">
                    <div>
                      <h4 className="mb-2">{t("MEMBER") + " " + t("LEVEL")}</h4>
                      <p className="font-14 mb-0 text-gold">{t("GOLD")}</p>
                    </div>
                  </div>
                </div> */}
                        </div>
                        {walletButtons &&
                            walletButtons.length &&
                            map(walletButtons, (item, index) => {
                                return (
                                    <MenuRowItem key={"main_wallet_" + index} item={item} />
                                );
                            })}
                    </div>
                </div>
            </div>
        </section>
    </>
);
};
export default MainWallet;

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { WebUrl } from "../../util/Constant";
import { useHistory } from "react-router-dom";

const ContentHeader = ({
  enableBackButton,
  title,
  backButtonOnClick,
  escButtonOnClick,
}) => {
  const _history = useHistory();

  useEffect(() => {
    window.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        _history.replace(WebUrl._URL_MAIN);
      }
    });
  }, []);

  return (
    <div className="right-title-box">
      <div className="first">
        <h3>
          {enableBackButton && (
            <>
              <a onClick={() => backButtonOnClick()}>
                <img
                  src="/img/back.svg"
                  className="img-responsive"
                  alt="back"
                />
              </a>
              &nbsp;
            </>
          )}
          {title}
        </h3>
      </div>
      <div className="second">
        {/* <div onClick={() => escButtonOnClick()}> */}
        <div onClick={() => _history.replace(WebUrl._URL_MAIN)}>
          <img
            src="/img/close.svg"
            className="img-responsive"
            alt="close"
          />
          <span>ESC</span>
        </div>
      </div>
    </div>
  );
};

ContentHeader.propTypes = {
  enableBackButton: PropTypes.bool,
  title: PropTypes.string.isRequired,
  backButtonOnClick: PropTypes.func,
  escButtonOnClick: PropTypes.func,
};

ContentHeader.defaultProps = {
  enableBackButton: false,
  backButtonOnClick: () => window.history.go(-1),
  escButtonOnClick: () => {},
};

export default ContentHeader;

import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DateTime from "react-datetime";
import moment from "moment";
import classNames from "classnames";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

const DayRangeFilter = (props) => {
  const { t, i18n } = useTranslation();

  const [sDate, setSDate] = useState();
  const [eDate, setEDate] = useState();
  const [selectedOption, setSelectedOption] = useState({});
  const [openCalendar, setOpenCalendar] = useState(false);
  const [optionClicked, setOptionClicked] = useState(false);
  var _dateRef = useRef(null);

  const {
    startDate = sDate,
    endDate = eDate,
    setStartDate,
    setEndDate,
    onStartDateChange,
    onEndDateChange,
    onSave,
    grandTotalReward,
  } = props;

  const _DAY_SELECTIONS = [
    {
      label: t("LAST_WEEK"),
      from: moment().subtract(1, "week").startOf("isoWeek"),
      to: moment().subtract(1, "week").endOf("isoWeek"),
    },
    {
      label: t("SUNDAY"),
      from: moment().day(0),
      to: moment().day(0),
    },
    {
      label: t("MONDAY"),
      from: moment().day(1),
      to: moment().day(1),
    },
    {
      label: t("TUESDAY"),
      from: moment().day(2),
      to: moment().day(2),
    },
    {
      label: t("WEDNESDAY"),
      from: moment().day(3),
      to: moment().day(3),
    },
    {
      label: t("THURSDAY"),
      from: moment().day(4),
      to: moment().day(4),
    },
    {
      label: t("FRIDAY"),
      from: moment().day(5),
      to: moment().day(5),
    },
    {
      label: t("SATURDAY"),
      from: moment().day(6),
      to: moment().day(6),
    },
  ];

  useEffect(() => {
    let startDateMoment;
    let endDateMoment;

    startDateMoment = selectedOption.from ? selectedOption.from : moment();
    endDateMoment = selectedOption.to ? selectedOption.to : moment();

    if (startDate === undefined || optionClicked) {
      setSDate(startDateMoment);
      if (typeof setStartDate !== "undefined") {
        props.setStartDate(startDateMoment);
      }
    } else setSDate(startDate);
    if (endDate === undefined || optionClicked) {
      setEDate(endDateMoment);
      if (typeof setEndDate !== "undefined") {
        props.setEndDate(endDateMoment);
      }
    } else setEDate(endDate);

    setOptionClicked(false);
  }, [selectedOption]);

  useEffect(() => {
    if (openCalendar) {
      _dateRef.current.openCalendar();
    }
  }, [openCalendar]);

  function handleStartDate(date) {
    setSDate(date);
    props.setStartDate(date);
    setOpenCalendar(true);
    if (onStartDateChange) {
      onStartDateChange(date);
    }
  }

  function handleEndDate(date) {
    setEDate(date);
    props.setEndDate(date);
    if (onEndDateChange) {
      onEndDateChange(date);
    }
  }

  function handleSave() {
    if (onSave) {
      onSave(sDate, eDate);
    }
  }

  return (
    <>
      <div className="reward-search-divider">
        <div className="transac-history-search-box font-11 first">
          <div className="rs-first-input">
            <DateTime
              inputProps={{
                className: "th-date-search",
                readOnly: true,
              }}
              name="startDate"
              dateFormat="YYYY-MM-DD"
              closeOnSelect={true}
              value={sDate}
              timeFormat={false}
              onChange={handleStartDate}
            />
          </div>

          <div className="text-white rs-dash">-</div>

          <div className="rs-second-input">
            <DateTime
              inputProps={{ className: "th-date-search", readOnly: true }}
              name="endDate"
              dateFormat="YYYY-MM-DD"
              closeOnSelect={true}
              timeFormat={false}
              value={eDate}
              ref={_dateRef}
              onChange={handleEndDate}
            />
          </div>

          <div className="th-search-btn">
            <a
              //   href=""
              onClick={() => {
                handleSave();
              }}
            >
              <img
                src="/img/search-white.svg"
                className="img-responsive"
                alt="search"
              />
            </a>
          </div>
        </div>
        <div className="transac-history-search-box font-11 second text-white">
          <div>
            {/* <span className="font-14 font-semi">Grand Total Reward :</span>
            <span className="font-15 font-semi ml-2">RM8888.00</span> */}

            <span className="font-14 font-semi">Grand Total Reward :</span>
            <span className="font-15 font-semi ml-2">RM{grandTotalReward}</span>
          </div>
          <div>
            <a href="" className="green-btn small-btn">
              Claim
            </a>
          </div>
        </div>
      </div>

      {
        <div className="filter-with-scroll-box gold-scroll mb-3">
          {_DAY_SELECTIONS.map((selection) => {
            return (
              <div key={selection.label} className="filter-items">
                <a
                  onClick={() => {
                    setOptionClicked(true);
                    setSelectedOption(selection);
                  }}
                  className={classNames({
                    active: selectedOption.label == selection.label,
                  })}
                >
                  {selection.label}
                </a>
              </div>
            );
          })}
        </div>
      }
    </>
  );
};

DayRangeFilter.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
  onStartDateChange: PropTypes.func,
  onEndDateChange: PropTypes.func,
  onSave: PropTypes.func,
  grandTotalReward: PropTypes.string,
};

export default DayRangeFilter;

import React, { useState, useEffect } from "react";
import "react-simple-keyboard/build/css/index.css";
import { ApiKey, ApiUrl, WebUrl, SessionKey } from "../../util/Constant";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { showResponseMessage, setBusy, setIdle } from "../../redux/AppAction.js";
import ApiEngine from "../../util/ApiEngine.js";
import { stringIsNullOrEmpty, isObjectEmpty, numberWithCurrencyFormat } from "../../util/Util";
import StyledSelect from "../../components/custom/StyledSelectDesktop";
import { useTranslation } from "react-i18next";
import GameMenu from "components/game-list/game-menu";
import { useHistory } from "react-router-dom";
import moment from 'moment';

const Kpi = (props) => {
  const { t } = useTranslation();
  var _dispatch = useDispatch();
  var _history = useHistory();

  const [monthOptions, setMonthOptions] = useState([]);
  const [kpiData, setKpiData] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [dailySummary, setDailySummary] = useState({});
  const [displayGameMenu, setDisplayGameMenu] = useState(true);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (!stringIsNullOrEmpty(selectedMonth)) {
      setSelectedIndex(
        kpiData.bonusDetail.findIndex((bd) => bd.month == selectedMonth)
      );
    }
  }, [selectedMonth]);

  useEffect(() => {
    setDisplayGameMenu(false);
    updateDateMonthlanguage()
    setTimeout(() => setDisplayGameMenu(true), 1);
  }, [(localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
    localStorage.getItem(SessionKey._LANGUAGE) : 'en')]);


  async function init() {
    var responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_DETAIL_KPI);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {

      let data = responseJson[ApiKey._API_DATA_KEY];

      setKpiData(data);
      setDailySummary(data["dailySummary"]);
      setSelectedMonth(data["bonusDetail"][0]["month"]);

      let _locale = "";
      switch (localStorage.getItem(SessionKey._LANGUAGE)) {
        case "en":
          _locale = "en-gb";
          break;
        case "zh":
          _locale = "zh-cn";
          break;
        case "th":
          _locale = "th";
          break;
        case "id":
          _locale = "id";
          break;
        case "vi":
          _locale = "vi";
          break;
        case "ms":
          _locale = "ms";
          break;
        default:
          _locale = "en-gb";
      }
      moment.locale(_locale);
      setMonthOptions(data.bonusDetail.map((bd) => { return { label: moment(bd.month).format('MMM YY'), value: bd.month } }));
    } else {
      _dispatch(
        showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
      );
    }
    _dispatch(setIdle());
  }

  function updateDateMonthlanguage() {
    if (Object.keys(kpiData).length > 0) {
      let _locale = "";
      switch (localStorage.getItem(SessionKey._LANGUAGE)) {
        case "en":
          _locale = "en-gb";
          break;
        case "zh":
          _locale = "zh-cn";
          break;
        case "th":
          _locale = "th";
          break;
        case "id":
          _locale = "id";
          break;
        case "vi":
          _locale = "vi";
          break;
        case "ms":
          _locale = "ms";
          break;
        default:
          _locale = "en-gb";
      }
      moment.locale(_locale);
      setMonthOptions(kpiData.bonusDetail.map((bd) => { return { label: moment(bd.month).format('MMM YY'), value: bd.month } }));
    }
  }


  if (isObjectEmpty(kpiData)) {
    return (null);
  }
  else {
    return (
      <>
        {displayGameMenu && (
          <>
            <section className="category-section">
              <GameMenu />
            </section>
            <section className="section-padding" id="downline-kpi-section">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <ul className="breadcrumbs list-unstyled">
                      <li>
                        <a
                          onClick={() => _history.replace(WebUrl._URL_MAIN)}
                          className="text-white"
                        >
                          {t("HOME")}
                        </a>
                      </li>
                      <li>
                        <a href="" className="text-white">
                          KPI
                        </a>
                      </li>
                    </ul>
                    <div className="template-box mt-3" id="downline-kpi">
                      <div
                        className="template-box bg-darkblue"
                        id="downline-accordions"
                      >
                        <div className="kpi-account">
                          <p className="font-15 text-white font-semi mb-0">
                            {kpiData["username"]}
                            <br />
                            {kpiData["phone"]}
                          </p>
                          <p className="font-15 text-gold font-semi mb-0">
                            {t("DOWNLINE")}: {kpiData["totalDownline"]}
                          </p>
                        </div>
                        <div className="border-bot-black mb-1"></div>
                        <div className="font-semi text-white font-15">
                          <StyledSelect
                            options={monthOptions}
                            value={selectedMonth}
                            onChange={(e) => {
                              setSelectedMonth(e.value);
                            }}
                          />
                        </div>
                        <div className="tag">
                          <div className="border-bot-black pb-1"></div>
                        </div>
                        <div>
                          <div
                            className="kpi-accordion-title font-15 text-gold font-semi font-semi mt-3"
                            data-toggle="collapse"
                            data-target="#kpi-drop1"
                            aria-expanded="true"
                          >
                            <span>{t("TOTAL_MONTHLY_BONUS")}</span>
                            <div className="acc-arrows">
                              <img
                                src={"/img/assets/drop-gold.svg"}
                                className="img-responsive img1"
                                alt="accordion"
                              />
                              <img
                                src={"/img/assets/up-gold.svg"}
                                className="img-responsive img2"
                                alt="accordion"
                              />
                            </div>
                          </div>

                          <div
                            id="kpi-drop1"
                            className="collapse show text-white mt-3"
                          >
                            <div className="kpi-accordion-details">
                              <span>{t("TOTAL_BONUS")}</span>
                              <span>
                                {numberWithCurrencyFormat(parseFloat(kpiData["data"][selectedIndex]["bonus"]), 2, true)}
                              </span>
                            </div>
                            <div className="kpi-accordion-details">
                              <span>{t("DOWNLINE")}</span>
                              <span>
                                {numberWithCurrencyFormat(parseFloat(kpiData["data"][selectedIndex]["downlineBonus"]), 2, true)}
                              </span>
                            </div>
                            <div className="kpi-accordion-details">
                              <span>{t("OWN_BONUS")}</span>
                              <span>
                                {numberWithCurrencyFormat(parseFloat(kpiData["data"][selectedIndex]["ownBonus"]), 2, true)}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div
                            className="kpi-accordion-title font-15 text-gold font-semi font-semi mt-3"
                            data-toggle="collapse"
                            data-target="#kpi-drop2"
                            aria-expanded="true"
                          >
                            <span>{t("TOTAL_MONTHLY_COMMISSION")}</span>
                            <div className="acc-arrows">
                              <img
                                src={"/img/assets/drop-gold.svg"}
                                className="img-responsive img1"
                                alt="accordion"
                              />
                              <img
                                src={"/img/assets/up-gold.svg"}
                                className="img-responsive img2"
                                alt="accordion"
                              />
                            </div>
                          </div>

                          <div
                            id="kpi-drop2"
                            className="collapse show text-white mt-3"
                          >
                            <div className="kpi-accordion-details">
                              <span>{t("TOTAL_COMMISSION")}</span>
                              <span>
                                {numberWithCurrencyFormat(parseFloat(kpiData["data"][selectedIndex]["commission"]), 2, true)}
                              </span>
                            </div>
                            <div className="kpi-accordion-details">
                              <span>{t("DOWNLINE")}</span>
                              <span>
                                {numberWithCurrencyFormat(parseFloat(kpiData["data"][selectedIndex]["downlineCommission"]), 2, true)}
                              </span>
                            </div>
                            <div className="kpi-accordion-details">
                              <span>{t("OWN_COMMISSION")}</span>
                              <span>
                                {numberWithCurrencyFormat(parseFloat(kpiData["data"][selectedIndex]["ownCommission"]), 2, true)}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div
                            className="kpi-accordion-title font-15 text-gold font-semi font-semi mt-3"
                            data-toggle="collapse"
                            data-target="#kpi-drop3"
                            aria-expanded="true"
                          >
                            <span>{t("TOTAL_SALES")}</span>
                            <div className="acc-arrows">
                              <img
                                src={"/img/assets/drop-gold.svg"}
                                className="img-responsive img1"
                                alt="accordion"
                              />
                              <img
                                src={"/img/assets/up-gold.svg"}
                                className="img-responsive img2"
                                alt="accordion"
                              />
                            </div>
                          </div>

                          <div
                            id="kpi-drop3"
                            className="collapse show text-white mt-3"
                          >
                            <div className="kpi-accordion-details">
                              <span>{t("TOTAL_SALES")}</span>
                              <span>
                                {numberWithCurrencyFormat(parseFloat(kpiData["data"][selectedIndex]["sales"]), 2, true)}
                              </span>
                            </div>
                            <div className="kpi-accordion-details">
                              <span>{t("DOWNLINE")}</span>
                              <span>
                                {numberWithCurrencyFormat(parseFloat(kpiData["data"][selectedIndex]["downlineSales"]), 2, true)}
                              </span>
                            </div>
                            <div className="kpi-accordion-details">
                              <span>{t("OWN_SALES")}</span>
                              <span>
                                {numberWithCurrencyFormat(parseFloat(kpiData["data"][selectedIndex]["ownSales"]), 2, true)}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="template-box bg-darkblue mt-4"
                        id="downline-kpi-table"
                      >
                        <div className="thc-result-mainbox font-12">
                          <div className="transac-history-result border-bot-black">
                            <div className="thr-titles">{t("DATE")}</div>
                            <div className="thr-titles">{t("SALES")}</div>
                            <div className="thr-titles">
                              {t("COMMISSION_SHORT")}.
                            </div>
                            <div className="thr-titles">{t("DOWNLINE")}</div>
                            <div className="thr-titles">{t("YOURS")}</div>
                          </div>
                          {dailySummary &&
                            dailySummary.length &&
                            dailySummary
                              .filter((i) => i.month == moment(selectedMonth).lang("en-gb").format('MMM YY'))
                              .map((summary, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="transac-history-result"
                                  >
                                    <div className="thr-titles">
                                      <p>{moment(summary["date"]).format(moment().startOf('year').format("YYYY") + "-MM-DD")}</p>
                                    </div>
                                    <div className="thr-titles">
                                      <p>
                                        {numberWithCurrencyFormat(
                                          summary.sales,
                                          2,
                                          true
                                        )}
                                      </p>
                                    </div>
                                    <div className="thr-titles">
                                      <p>
                                        {numberWithCurrencyFormat(
                                          summary.commission,
                                          2,
                                          true
                                        )}
                                      </p>
                                    </div>
                                    <div className="thr-titles">
                                      <p>
                                        {numberWithCurrencyFormat(
                                          summary.downlineCommission,
                                          2,
                                          true
                                        )}
                                      </p>
                                    </div>
                                    <div className="thr-titles">
                                      <p>
                                        {numberWithCurrencyFormat(
                                          summary.ownCommission,
                                          2,
                                          true
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                );
                              })}
                          <div className="transac-history-result-total">
                            <div className="thr-titles">
                              <p>{t("TOTAL")}</p>
                            </div>
                            <div className="thr-titles">
                              <p>
                                {dailySummary &&
                                  dailySummary.length &&
                                  numberWithCurrencyFormat(
                                    parseFloat(
                                      dailySummary
                                        .filter((i) => i.month == moment(selectedMonth).lang("en-gb").format('MMM YY'))
                                        .reduce(
                                          (total, data) => total + data.sales,
                                          0
                                        )
                                    ),
                                    2,
                                    true
                                  )}
                              </p>
                            </div>
                            <div className="thr-titles">
                              <p>
                                {dailySummary &&
                                  dailySummary.length &&
                                  numberWithCurrencyFormat(
                                    parseFloat(
                                      dailySummary
                                        .filter((i) => i.month == moment(selectedMonth).lang("en-gb").format('MMM YY'))
                                        .reduce(
                                          (total, data) =>
                                            total + data.commission,
                                          0
                                        )
                                    ),
                                    2,
                                    true
                                  )}
                              </p>
                            </div>
                            <div className="thr-titles">
                              <p>
                                {dailySummary &&
                                  dailySummary.length &&
                                  numberWithCurrencyFormat(
                                    parseFloat(
                                      dailySummary
                                        .filter((i) => i.month == moment(selectedMonth).lang("en-gb").format('MMM YY'))
                                        .reduce(
                                          (total, data) =>
                                            total + data.downlineCommission,
                                          0
                                        )
                                    ),
                                    2,
                                    true
                                  )}
                              </p>
                            </div>
                            <div className="thr-titles">
                              <p>
                                {dailySummary &&
                                  dailySummary.length &&
                                  numberWithCurrencyFormat(
                                    parseFloat(
                                      dailySummary
                                        .filter((i) => i.month == moment(selectedMonth).lang("en-gb").format('MMM YY'))
                                        .reduce(
                                          (total, data) =>
                                            total + data.ownCommission,
                                          0
                                        )
                                    ),
                                    2,
                                    true
                                  )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </>
    );
  }
};

export default Kpi;
import React, { useState, useEffect } from "react";
//import "react-simple-keyboard/build/css/index.css";
import Sidebar from "components/sidebar";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { ApiKey, ApiUrl } from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine";
import { stringIsNullOrEmpty, numberWithCurrencyFormat } from "../../util/Util";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";
import { Controller, useForm } from "react-hook-form";
import ContentHeader from "components/content/header";
import DateRangeFilter from "../../components/custom/DateRangeFilter";
import StyledSelect from "../../components/custom/StyledSelect";

const DepositBonusHistory = (props) => {
  const { t } = useTranslation();
  const { control, setValue, watch } = useForm();
  const [products, setProducts] = useState([]);
  const [bonusUrl, setBonusUrl] = useState("");
  const [bonusData, setBonusData] = useState([]);
  const [queryProduct, setQueryProduct] = useState("");
  const [searchTrigger, setSearchTrigger] = useState(Date.now());

  const [startDate, setStartDate] = useState(moment().startOf("week").format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().endOf("week").format("YYYY-MM-DD"));
  const [selectedDate, setSelectedDate] = useState("");

  useEffect(() => {
    init();
  }, []);

  async function init() {
    getDepositBonusData(startDate, endDate);
  }

  async function getDepositBonusData(start, end, selected = "") {
    var apiUrl =
      ApiUrl._API_GET_DEPOSIT_BONUS_HISTORY +
      "?StartDate=" +
      moment(start).startOf('day').format("YYYY-MM-DD") +
      "&EndDate=" +
      moment(end).endOf('day').format("YYYY-MM-DD") +
      "&v=" + moment(start).startOf('day').format("YYYY-MM-DD");

    if (!stringIsNullOrEmpty(queryProduct)) {
      apiUrl += "&ProductId=" + queryProduct;
    }

    if (!stringIsNullOrEmpty(selected)) {
      apiUrl += "&SelectedDate=" + moment(selected).format("YYYY-MM-DD");
    }

    setValue(
      "productId",
      stringIsNullOrEmpty(queryProduct) ? null : queryProduct
    );
    setBonusUrl(apiUrl);
  }

  useEffect(() => {
    if (!stringIsNullOrEmpty(selectedDate)) {
      getDepositBonusData(startDate, endDate, selectedDate);
    }
  }, [selectedDate]);

  useEffect(() => {
    getDepositBonusData(startDate, endDate);
  }, [searchTrigger]);

  function getRecordFromDynamicLoad(data) {
    setBonusData(data);
  }

  return (
    <>
      <section className="section-padding" id="game-record-section">
        <div className="container">
          <div className="row">
            <div className="col-3" id="account-menu">
              <Sidebar activePage={"REPORTS"} />
            </div>
            <div className="col-9">
              <ContentHeader enableBackButton title={t("DEPOSIT_BONUS_HISTORY")} />
              <hr className="right-title-hr" />
              <div className="template-box mt-3" id="bonus-report-box">
                <DateRangeFilter
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  onSave={(start, end) => {
                    setQueryProduct(watch()["productId"]);
                    setStartDate(moment(start));
                    setEndDate(moment(end));
                    setSelectedDate("");
                    setSearchTrigger(Date.now());
                  }}
                  options={true}
                />

                <div className="card card-package">
                  <div className="card-body p-0" style={{ overflowX: "auto" }}>
                    <table className="table reportTable">
                      <thead>
                        <tr>
                          <th>{t("DATE")}</th>
                          <th>{t("DEPOSIT_ID")}</th>
                          <th>{t("BONUS_AMOUNT")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <DynamicLoadTable
                          returnRecord={getRecordFromDynamicLoad}
                          pageSize={20}
                          apiQuery={bonusUrl}
                          isTable={true}
                          searchEnabled={true}
                          render={(item, i) => {
                            let isGrouped =
                              moment(endDate, "YYYY-MM-DD").diff(
                                moment(startDate, "YYYY-MM-DD"),
                                "days"
                              ) >= 1 && stringIsNullOrEmpty(selectedDate);
                            return (
                              <tr key={"bonus_report_" + i}>
                                <td>
                                  {moment(item["date"]).format("YYYY-MM-DD HH:mm:ss")}
                                </td>
                                <td>{item["depositId"]}</td>
                                <td>
                                  {numberWithCurrencyFormat(
                                    parseFloat(item["bonusAmount"]),
                                    2
                                  )}
                                </td>
                              </tr>
                            );
                          }}
                        />
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan="2">
                            <b>{t("TOTAL")}</b>
                          </td>
                          <td>
                            <b>
                              {numberWithCurrencyFormat(
                                parseFloat(
                                  bonusData.reduce(
                                    (total, data) => total + data.bonusAmount,
                                    0
                                  )
                                ),
                                2
                              )}
                            </b>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default DepositBonusHistory;

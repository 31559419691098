import React, { Component } from "react";
import i18n from "../../localization/i18n";

class Clock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    };
  }
  componentWillMount() {
    this.getTimeUntil(this.props.deadline);
  }
  componentDidMount() {
    setInterval(() => this.getTimeUntil(this.props.deadline), 1000);
  }
  leading0(num) {
    return num < 10 ? "0" + num : num;
  }
  getTimeUntil(deadline) {
    const time = Date.parse(deadline) - Date.parse(new Date());
    if (time < 0) {
      this.setState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
    } else {
      const seconds = Math.floor((time / 1000) % 60);
      const minutes = Math.floor((time / 1000 / 60) % 60);
      const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
      const days = Math.floor(time / (1000 * 60 * 60 * 24));
      this.setState({ days, hours, minutes, seconds });
    }
  }
  render() {
    const SplitText = React.memo(({ str }) => {
        var num = str;
        var digits = num.toString().split('');
        var realDigits = digits.map(Number)
        return (
          <div>
            {realDigits.map((item, index) => {
              return (<div key={index} className="per-time-box">{item}</div>);
            })}
          </div>
        );
      });
    return (
      <div className="timer-box">
        <div className="Clock-days clock-box">
            <SplitText str={this.leading0(this.state.days)} />
            <div className="font-12 timer-text">{i18n.t("DAYS")}</div>
        </div>
        <div className="timer-diamond">
            <img
            src={"/img/sports/diamond.png"}
            className='img-100'
            alt='diamond icon'
            />
        </div>
        <div className="Clock-hours clock-box">
            <SplitText str={this.leading0(this.state.hours)} /> 
            <div className="font-12 timer-text">{i18n.t("HOURS")}</div>
        </div>
        <div className="timer-diamond">
            <img
            src={"/img/sports/diamond.png"}
            className='img-100'
            alt='diamond icon'
            />
        </div>
        <div className="Clock-minutes clock-box">
            <SplitText str={this.leading0(this.state.minutes)} />
            <div className="font-12 timer-text">{i18n.t("MINUTES")}</div>
        </div>
        <div className="timer-diamond">
          <img
            src={"/img/sports/diamond.png"}
            className='img-100'
            alt='diamond icon'
          />
        </div>
        <div className="Clock-seconds clock-box">
          <SplitText str={this.leading0(this.state.seconds)} />
          <div className="font-12 timer-text">{i18n.t("SECONDS")}</div>
        </div>
      </div>
    );
  }
}
export default Clock;

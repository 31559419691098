import React from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { WebUrl } from "../../util/Constant";

const RewardsTnC = (props) => {
  let _history = useHistory();
  const { t } = useTranslation();

  return (
    <>
        <section className="section-padding" id="terms-section">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                <ul className="breadcrumbs list-unstyled">
                    <li>
                      <a
                        onClick={() => _history.push(WebUrl._URL_REWARDS)}
                        className="text-white"
                      >
                        {t("PROMOTION")}
                      </a>
                    </li>
                    <li>
                      <a className="text-white">{t('TNC')}</a>
                    </li>
                  </ul>

                  <div className="template-box mt-3" id="terms-box">
                    <div
                      className="template-box bg-darkblue text-white font-semi"
                      id="terms-content"
                    >
                        <h3 class="mb-3">{t('TNC')}</h3>
                        <div className="tnc-list"> 1.<div className="col-12">{t("REWARDS_1")}</div></div>
                        <div className="tnc-list"> 2.<div className="col-12">{t("REWARDS_2")}</div></div>
                        <div className="tnc-list"> 3.<div className="col-12">{t("REWARDS_3")}</div></div>
                        <div className="tnc-list"> 4.<div className="col-12">{t("REWARDS_4")}</div></div>
                        <div className="tnc-list"> 5.<div className="col-12">{t("REWARDS_5")}</div></div>
                        <div className="tnc-list"> 6.<div className="col-12">{t("REWARDS_6")}</div></div>
                        <div className="tnc-list"> 7.<div className="col-12">{t("REWARDS_7")}</div></div>
                        <div className="tnc-list"> 8.<div className="col-12">{t("REWARDS_8")}</div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </section>
    </>
  )
}

export default RewardsTnC;

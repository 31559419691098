import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
//import "react-simple-keyboard/build/css/index.css";
import Sidebar from "components/sidebar";
import Button from "components/button";
import { useForm, Controller } from "react-hook-form";
import { checkIsLoggedIn } from "../../redux/AuthAction";
import {
    stringIsNullOrEmpty,
    createMultiPartFormBody,
    createFormBody,
    numberWithCurrencyFormat,
    numberWithCommas,
    numberWithCurrencyFormat2
} from "../../util/Util";
import ApiEngine from "../../util/ApiEngine";
import {
    ApiKey,
    ApiUrl,
    TransactionType,
    PanelType,
    _MAIN_WALLET_ID,
    DEFAULT_PAGE_SIZE,
    FixedDepositAmount,
    FixedDepositAmount2
} from "../../util/Constant";
import { useSelector, useDispatch } from "react-redux";
import { setBusy, setIdle, showResponseMessage } from "../../redux/AppAction";
import moment from "moment";
import BottomSuccessModal from "../../components/custom/BottomSuccessModal";
import { AsyncPaginate } from "react-select-async-paginate";
import { Alert, Modal, ModalBody } from "reactstrap";
import PinInput from "react-pin-input";
import Keyboard from "react-simple-keyboard";
import ReactHtmlParser from "react-html-parser";
import ContentHeader from "components/content/header";
import SelectOption from "../../components/custom/SelectOption";

const Transfer = (props) => {
    let _dispatch = useDispatch();
    const {
        handleSubmit: handleTransfer,
        control,
        register,
        setValue,
        errors,
        setError,
        clearErrors
    } = useForm();
    const {
        handleSubmit: handleInternalTransfer,
        register: registerInternalTransfer,
        control: controlInternalTransfer,
        errors: errorsInternalTransfer,
        setError: setErrorInternalTransfer,
        clearErrors: clearErrorsInternalTransfer
    } = useForm();
    const {
        handleSubmit: handleFullNameSubmit,
        register: registerFullName,
        getValues: getValuesFullName,
        errors: errorsFullName,
        setValue: setValueFullName,
    } = useForm();
    const [fromOptions, setFromOptions] = useState([]);
    const [currentInput, setCurrentInput] = useState();
    let _userData = useSelector((state) => state.authState.userData);
    const { t } = useTranslation();
    const [selectedFromOptions, setSelectedFromOptions] = useState("");
    const [bonus, setBonus] = useState(0);
    const [isTransferBackToMainWallet, setIsTransferBackToMainWallet] =
        useState(false);
    const [transcactionNumber, setTranscactionNumber] = useState();
    const [isTransferSuccess, setIsTransferSuccess] = useState(false);
    const [walletList, setWalletList] = useState([]);
    const [paidAmount, setPaidAmount] = useState();
    const [deductedAmount, setDeductedAmount] = useState();
    const [showTaxWarning, setShowTaxWarning] = useState(false);
    const [transferDate, setTransferDate] = useState();
    const [toOptions, setToOptions] = useState([]);
    const [transferTo, setTransferTo] = useState("");
    const [transferToLabel, setTransferToLabel] = useState("");
    const [transferFrom, setTransferFrom] = useState("");
    const [selectedMember, setSelectedMember] = useState();
    const [member, setMember] = useState("");
    const elePin = useRef(null);
    const eleConfirm = useRef(null);
    const eleFinalPin = useRef(null);
    const [showPinEntry, setShowPinEntry] = useState(false);
    const [pinEntered, setPinEntered] = useState(false);
    const [pinVerified, setPinVerified] = useState(false);
    const [pinNumber, setPinNumber] = useState("");
    const [enteredPinNumber, setEnteredPinNumber] = useState("");
    const [pinNumberFocused, setPinNumberFocused] = useState(0);
    const [pinNumberConfirm, setPinNumberConfirm] = useState("");
    const [enteredPinNumberConfirm, setEnteredPinNumberConfirm] = useState("");
    const [pinNumberConfirmFocused, setPinNumberConfirmFocused] = useState(0);
    const [pinNumberFinal, setPinNumberFinal] = useState("");
    const [enteredPinNumberFinal, setEnteredPinNumberFinal] = useState("");
    const [pinNumberFinalFocused, setPinNumberFinalFocused] = useState(0);
    const [forgetPin, setForgetPin] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [theInterval, setTheInterval] = useState(false);
    const [showFullnamePopup, setShowFullnamePopup] = useState(false);
    const [invalidPin, setInvalidPin] = useState(false);
    const [createPinEntry, setCreatePinEntry] = useState(false);
    const [countdownPeriod, setCountdownPeriod] = useState(0);
    const [transferFromOptions, setTransferFromOptions] = useState("");
    const [internalTransferStatus, setInternalTransferStatus] = useState(false);
    const [isTestAccount, setIsTestAccount] = useState(false);
    const [amountValue1, setAmountValue1] = useState("0.00");
    const [amountValue2, setAmountValue2] = useState("0.00");
    const keyboard = useRef();
    const _COUNTDOWN_PERIOD = 120;
    const _ALERT_TIMEOUT = 3000;
    const _SECONDS_IN_MS = 1000;
    const _SELECT_STYLES = {
        menu: (styles, state) => ({
            ...styles,
        }),
        control: (styles, state) => ({
            ...styles,
            padding: "0px 20px",
            fontSize: "11px",
            borderRadius: "8px",
            border: "1px solid #fff",
            color: "#fff",
            background: "#02214A",
            width: "100%",
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            if (isFocused)
                return {
                    ...styles,
                    paddingLeft: "1rem",
                    fontSize: "14px",
                    background: "#2684ff",
                };
            else
                return {
                    ...styles,
                    paddingLeft: "1rem",
                    fontSize: "14px",
                    background: "transparent",
                };
        },
        singleValue: (styles) => ({
            ...styles,
            fontSize: "14px",
            fontWeight: "bold",
            color: "#fff",
        }),
        placeholder: (styles) => ({
            ...styles,
            fontSize: "14px",
            color: "#fff",
        }),
        input: (styles) => ({
            ...styles,
            fontSize: "14px",
            color: "#fff",
            background: "#02214A",
        }),
        menu: (styles) => ({
            ...styles,
            fontSize: "14px",
            color: "#fff",
            background: "#02214A",
        }),
        indicatorSeparator: (styles) => ({
            ...styles,
            display: "none",
        }),
    };
    const [internalTransferLimit, setInternalTransferLimit] = useState("0");
    const [totalInternalTransferLimit, setTotalInternalTransferLimit] = useState("0");

    useEffect(() => {
        init();
        initInternalTransfer();
    }, []);

    useEffect(() => {
        if (countdownPeriod > 0) {
            setTimeout(() => {
                let tempPeriod = countdownPeriod - 1;
                setCountdownPeriod(tempPeriod);
            }, _SECONDS_IN_MS);
        }
    }, [countdownPeriod]);

    const handleTransferTo = (value) => {
        setTransferTo(value);
    };
    const handleTransferFrom = (value) => {
        setTransferFrom(value);
    };

    async function init() {
        let testAccountStatusJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_TEST_ACCOUNT_STATUS);
        if (testAccountStatusJson[ApiKey._API_SUCCESS_KEY]) {
            let isTestAccount = testAccountStatusJson[ApiKey._API_DATA_KEY] === true || testAccountStatusJson[ApiKey._API_DATA_KEY] === 'true';
            setIsTestAccount(isTestAccount);
        }

        let transferStatusJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_INTERNAL_TRANSFER_STATUS);
        let slotRolloverJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_IS_SPECIAL_ROLLOVER);
        if (transferStatusJson[ApiKey._API_SUCCESS_KEY] && slotRolloverJson[ApiKey._API_SUCCESS_KEY]) {
            setInternalTransferStatus(transferStatusJson[ApiKey._API_DATA_KEY] === true && slotRolloverJson[ApiKey._API_DATA_KEY] === false);
        }
        let responseJson = await ApiEngine.get(
            ApiUrl._API_GET_FLAG_TRANSFER_BACK_MAIN_WALLET
        );
        if (responseJson[ApiKey._API_DATA_KEY] == 1) {
            setIsTransferBackToMainWallet(true);
            let responseJson = await ApiEngine.get(
                ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE
            );
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setWalletList(responseJson[ApiKey._API_DATA_KEY]);

                let tempFromOptions = [];

                responseJson[ApiKey._API_DATA_KEY].map((product) => {
                    let option = {
                        label:
                            product.productName +
                            " - " +
                            numberWithCurrencyFormat2(parseFloat(product.balance), 2, true),
                        value: product.productId,
                        bonus: product.bonus,
                    };
                    tempFromOptions.push(option);
                    //if (product.productId !== selectedFromOptions) {
                    //    tempToOptions.push(option);
                    //}
                });

                setFromOptions(tempFromOptions);
            }
        } else {
            let responseJson = await ApiEngine.get(
                ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE
            );
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setWalletList(responseJson[ApiKey._API_DATA_KEY]);

                let tempFromOptions = [];
                let tempToOptions = [];

                responseJson[ApiKey._API_DATA_KEY].map((product) => {
                    let option = {
                        label:
                            product.productName +
                            " - " +
                            numberWithCurrencyFormat2(parseFloat(product.balance), 2, true),
                        value: product.productId,
                    };
                    if (product.category == "Lottery") {
                        tempToOptions.push(option);
                    } else {
                        tempFromOptions.push(option);
                    }
                });

                setFromOptions(tempFromOptions);
                setToOptions(tempToOptions);
            }
        }

        //var transferLimitJson = await ApiEngine.get(
        //    ApiUrl._API_CHECK_MEMBER_INTERNAL_TRANSFER_LIMIT_BALANCE
        //);

        //if (transferLimitJson[ApiKey._API_SUCCESS_KEY]) {
        //    console.log(transferLimitJson[ApiKey._API_DATA_KEY])
        //    setInternalTransferLimit(transferLimitJson[ApiKey._API_DATA_KEY]);
        //} else {
        //    throw transferLimitJson[ApiKey._API_MESSAGE_KEY];
        //}

        _dispatch(checkIsLoggedIn());
    }

    async function initInternalTransfer() {
        _dispatch(setBusy());
        try {
            let tempMemberData = {};
            var memberJson = await ApiEngine.get(
                ApiUrl._API_GET_MEMBER_DETAILS_BY_ID
            );

            if (memberJson[ApiKey._API_SUCCESS_KEY]) {
                setMember(memberJson[ApiKey._API_DATA_KEY]);
                tempMemberData = memberJson[ApiKey._API_DATA_KEY];
                //setphoneNumber(memberJson[ApiKey._API_DATA_KEY]['phoneNumber']);
            } else {
                throw memberJson[ApiKey._API_MESSAGE_KEY];
            }
            if (tempMemberData.internalTransferLimit > 0) {
                var limitJson = await ApiEngine.get(ApiUrl._API_CHECK_MEMBER_INTERNAL_TRANSFER_LIMIT_BALANCE);
                if (limitJson[ApiKey._API_SUCCESS_KEY]) {
                    let limitData = limitJson[ApiKey._API_DATA_KEY];
                    setInternalTransferLimit(limitData["memberInternalTransferLimitBalance"])
                    setTotalInternalTransferLimit(limitData["memberInternalTransferLimit"])
                }
            }

            if (stringIsNullOrEmpty(tempMemberData["userFullName"])) {
                setShowFullnamePopup(true);
            } else {
                let params = {};
                let pinJson = await ApiEngine.post(
                    ApiUrl._API_CHECK_MEMBER_PIN_NUMBER,
                    createFormBody(params)
                );
                if (!pinJson[ApiKey._API_SUCCESS_KEY]) {
                    var theInterval = setInterval(() => {
                        if (elePin["current"] == undefined) return;

                        if (
                            elePin["current"]["values"][0] == "" ||
                            elePin["current"]["values"][0] == undefined
                        ) {
                            elePin["current"]["elements"][0].focus();
                        } else if (
                            elePin["current"]["values"][1] == "" ||
                            elePin["current"]["values"][1] == undefined
                        ) {
                            elePin["current"]["elements"][1].focus();
                        } else if (
                            elePin["current"]["values"][2] == "" ||
                            elePin["current"]["values"][2] == undefined
                        ) {
                            elePin["current"]["elements"][2].focus();
                        } else if (
                            elePin["current"]["values"][3] == "" ||
                            elePin["current"]["values"][3] == undefined
                        ) {
                            elePin["current"]["elements"][3].focus();
                        } else if (
                            eleConfirm["current"]["values"][0] == "" ||
                            eleConfirm["current"]["values"][0] == undefined
                        ) {
                            eleConfirm["current"]["elements"][0].focus();
                        } else if (
                            eleConfirm["current"]["values"][1] == "" ||
                            eleConfirm["current"]["values"][1] == undefined
                        ) {
                            eleConfirm["current"]["elements"][1].focus();
                        } else if (
                            eleConfirm["current"]["values"][2] == "" ||
                            eleConfirm["current"]["values"][2] == undefined
                        ) {
                            eleConfirm["current"]["elements"][2].focus();
                        } else if (
                            eleConfirm["current"]["values"][3] == "" ||
                            eleConfirm["current"]["values"][3] == undefined
                        ) {
                            eleConfirm["current"]["elements"][3].focus();
                        } else {
                            var currpin =
                                elePin["current"]["values"][0] +
                                elePin["current"]["values"][1] +
                                elePin["current"]["values"][2] +
                                elePin["current"]["values"][3];
                            var currconfpin =
                                eleConfirm["current"]["values"][0] +
                                eleConfirm["current"]["values"][1] +
                                eleConfirm["current"]["values"][2] +
                                eleConfirm["current"]["values"][3];
                            if (currpin != currconfpin) {
                                setInvalidPin(true);
                                elePin["current"].clear();
                                eleConfirm["current"].clear();
                                elePin["current"].focus();
                            } else {
                                setInvalidPin(false);
                                clearInterval(theInterval);
                                submitPin(currpin, currconfpin);
                            }
                        }
                    }, 50);
                    setTheInterval(theInterval);
                    setCreatePinEntry(true);
                }
            }
            _dispatch(checkIsLoggedIn());
        } catch (err) {
            _dispatch(showResponseMessage(false, err));
        }
        _dispatch(setIdle());
    }

    function onVerifyPinKeyPress(key) {
        let currentIndex = 0;

        let pinStr = enteredPinNumberFinal;
        if (key !== "{bksp}") {
            if (pinNumberFinal === "") {
                currentIndex = pinNumberFinalFocused;
                // eleFinalPin["current"]["elements"][currentIndex].state.value = key;
                pinStr = key;
                setEnteredPinNumberFinal(pinStr);
                if (currentIndex !== 3 && pinStr.length != 4) {
                    currentIndex++;
                    setPinNumberFinalFocused(currentIndex);
                    eleFinalPin["current"]["elements"][currentIndex].focus();
                }
                else if (pinStr.length < 4) {
                    setPinNumberFinal("");
                    setPinEntered(false);
                    setShowPinEntry(true);
                    setEnteredPinNumberFinal("");
                    setPinNumberFinalFocused(0);
                }
                else {
                    setPinNumberFinal(pinStr);
                    setPinEntered(true);
                    setShowPinEntry(false);
                    verifyPin(pinStr);
                    eleFinalPin["current"] && eleFinalPin["current"].clear();
                    setEnteredPinNumberFinal("");
                    setPinNumberFinalFocused(0);
                    eleFinalPin["current"] &&
                        eleFinalPin["current"]["elements"][0].focus();
                }
            }
        } else {
            eleFinalPin["current"].clear();
            setPinNumberFinal("");
            setEnteredPinNumberFinal("");
            setPinNumberFinalFocused(currentIndex);
            eleFinalPin["current"]["elements"][currentIndex].focus();
        }
    }

    async function verifyPin(pin) {
        let params = {
            pinNumber: pin,
        };
        let apiUrl = ApiUrl._API_CHECK_MEMBER_PIN_NUMBER;
        let responseJson = await ApiEngine.post(apiUrl, createFormBody(params));
        if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
            setPinVerified(true);
            handleInternalTransfer(onSubmitB);
        } else {
            setPinVerified(false);
            setPinNumberFinal("");
        }
    }

    function onCreatePinKeyPress(value) {
        // 1st: have to know if entering for elePin or eleConfirm
        // 2nd: have to know which index is currently focused for altering the state.value
        // 3rd: have to check if at elePin.index(3), if yes then convert value to string and focus on eleConfirm.index(0)
        // 4th: have to check if at eleConfirm.index(3), if yes then convert value to string, and submitPin

        let currentIndex = 0;
        let pinStr = enteredPinNumber;
        let pinConfirmStr = enteredPinNumberConfirm;
        if (value !== "{bksp}") {
            if (pinNumber === "") {
                currentIndex = pinNumberFocused;
                // elePin["current"]["elements"][currentIndex].state.value = value;
                pinStr = value;
                setEnteredPinNumber(pinStr);
                if (currentIndex !== 3) {
                    currentIndex++;
                    setPinNumberFocused(currentIndex);
                    elePin["current"]["elements"][currentIndex].focus();
                } else {
                    setPinNumber(pinStr);
                }
            } else {
                currentIndex = pinNumberConfirmFocused;
                // eleConfirm["current"]["elements"][currentIndex].state.value = value;
                pinConfirmStr = value;
                setEnteredPinNumberConfirm(pinConfirmStr);
                currentIndex++;
                setPinNumberConfirmFocused(currentIndex);
                if (currentIndex < 3) {
                    eleConfirm["current"]["elements"][currentIndex].focus();
                } else {
                    setPinNumberConfirm(pinConfirmStr);
                    //submitPin(pinNumber, pinConfirmStr);
                }
            }
        } else {
            elePin["current"].clear();
            eleConfirm["current"].clear();
            setPinNumber("");
            setEnteredPinNumber("");
            setPinNumberFocused(currentIndex);
            setPinNumberConfirm("");
            setEnteredPinNumberConfirm("");
            setPinNumberConfirmFocused(currentIndex);
            elePin["current"]["elements"][currentIndex].focus();
        }
    }

    async function submitPin(pin, conf) {
        try {
            let params = {
                pinNumber: pin,
                confirmPinNumber: conf,
            };
            let responseJson = await ApiEngine.post(
                ApiUrl._API_SET_MEMBER_PIN_NUMBER,
                createFormBody(params)
            );
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setCreatePinEntry(false);
            }
            _dispatch(
                showResponseMessage(
                    responseJson[ApiKey._API_SUCCESS_KEY],
                    responseJson[ApiKey._API_MESSAGE_KEY]
                )
            );

            elePin["current"].clear();
            eleConfirm["current"].clear();
            setPinNumber("");
            setEnteredPinNumber("");
            setPinNumberFocused(0);
            setPinNumberConfirm("");
            setEnteredPinNumberConfirm("");
            setPinNumberConfirmFocused(0);
        } catch (err) {
            _dispatch(showResponseMessage(false, err));
        }
    }

    async function sendPinCode() {
        if (countdownPeriod < 1) {
            let responseJson = await ApiEngine.get(
                ApiUrl._API_CHECK_IF_PHONE_VERIFIED
            );
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                var hpNum = responseJson[ApiKey._API_DATA_KEY];
                let params = {
                    phoneNumber: hpNum,
                    isShareholder: "false",
                };
                let smsJson = await ApiEngine.post(
                    ApiUrl._API_SEND_PIN_CODE,
                    createFormBody(params)
                );
                if (smsJson[ApiKey._API_SUCCESS_KEY]) {
                    setForgetPin(false);
                    setCountdownPeriod(_COUNTDOWN_PERIOD);
                }
            }
        } else {
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), [_ALERT_TIMEOUT]);
        }
    }

    const onSubmitB = async (data, e) => {
        if (!pinEntered || !pinVerified) {
            setShowPinEntry(true);
        } else {
            setShowPinEntry(false);
        }
        if (pinEntered && pinNumberFinal !== "") {
            submitTransfer(data, e, pinNumberFinal);
            resetEnteredPin();
        }
    };

    async function submitTransfer(data, e, pin) {
        try {
            _dispatch(setBusy());

            let params = {
                transactionTypeId: TransactionType._TRANSFER,
                pinNumber: pin,
                amount: amountValue2,
                targetMember: selectedMember.value,
                channel: "",
                panel: PanelType._MEMBERSITE,
            };

            let responseJson = await ApiEngine.post(
                ApiUrl._API_CREATE_TRANSACTION,
                createMultiPartFormBody(params)
            );

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                _dispatch(checkIsLoggedIn());
                e.target.reset();
                setSelectedMember(null);
                setValue("userId", "");
                setShowPinEntry(false);

                var limitJson = await ApiEngine.get(ApiUrl._API_CHECK_MEMBER_INTERNAL_TRANSFER_LIMIT_BALANCE);
                if (limitJson[ApiKey._API_SUCCESS_KEY]) {
                    let limitData = limitJson[ApiKey._API_DATA_KEY];
                    setInternalTransferLimit(limitData["memberInternalTransferLimitBalance"])
                    setTotalInternalTransferLimit(limitData["memberInternalTransferLimit"])
                }

            }
            if (responseJson[ApiKey._API_MESSAGE_KEY] === "TRANSFER_LIMIT_ERROR") {
                _dispatch(showResponseMessage(false, t("TRANSFER_LIMIT_EXCEEDED", { value: member["internalTransferLimit"] })));
            }
            else {
                _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
            }
        } catch (err) {
            _dispatch(showResponseMessage(false, err));
        }
        _dispatch(setIdle());
    }

    function resetEnteredPin() {
        setPinNumberFinal("");
        setPinNumberFocused(0);
        setPinNumberFinalFocused(0);
        setEnteredPinNumberFinal("");
        setPinEntered(false);
    }

    async function submitFullName(data) {
        let params = {
            userFullName: data.userFullName,
            // id: member["id"],
        };
        let responseJson = await ApiEngine.post(
            // ApiUrl._API_CREATE_OR_UPDATE_MEMBER,
            ApiUrl._API_UPDATE_MEMBER_USERFULLNAME,
            createFormBody(params)
        );
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            _dispatch(
                showResponseMessage(
                    responseJson[ApiKey._API_SUCCESS_KEY],
                    responseJson[ApiKey._API_MESSAGE_KEY]
                )
            );
            setShowFullnamePopup(false);
            let params = {};
            let pinJson = await ApiEngine.post(
                ApiUrl._API_CHECK_MEMBER_PIN_NUMBER,
                createFormBody(params)
            );
            if (!pinJson[ApiKey._API_SUCCESS_KEY]) {
                var theInterval = setInterval(() => {
                    if (elePin["current"] == undefined) return;

                    if (
                        elePin["current"]["values"][0] == "" ||
                        elePin["current"]["values"][0] == undefined
                    ) {
                        elePin["current"]["elements"][0].focus();
                    } else if (
                        elePin["current"]["values"][1] == "" ||
                        elePin["current"]["values"][1] == undefined
                    ) {
                        elePin["current"]["elements"][1].focus();
                    } else if (
                        elePin["current"]["values"][2] == "" ||
                        elePin["current"]["values"][2] == undefined
                    ) {
                        elePin["current"]["elements"][2].focus();
                    } else if (
                        elePin["current"]["values"][3] == "" ||
                        elePin["current"]["values"][3] == undefined
                    ) {
                        elePin["current"]["elements"][3].focus();
                    } else if (
                        eleConfirm["current"]["values"][0] == "" ||
                        eleConfirm["current"]["values"][0] == undefined
                    ) {
                        eleConfirm["current"]["elements"][0].focus();
                    } else if (
                        eleConfirm["current"]["values"][1] == "" ||
                        eleConfirm["current"]["values"][1] == undefined
                    ) {
                        eleConfirm["current"]["elements"][1].focus();
                    } else if (
                        eleConfirm["current"]["values"][2] == "" ||
                        eleConfirm["current"]["values"][2] == undefined
                    ) {
                        eleConfirm["current"]["elements"][2].focus();
                    } else if (
                        eleConfirm["current"]["values"][3] == "" ||
                        eleConfirm["current"]["values"][3] == undefined
                    ) {
                        eleConfirm["current"]["elements"][3].focus();
                    } else {
                        var currpin =
                            elePin["current"]["values"][0] +
                            elePin["current"]["values"][1] +
                            elePin["current"]["values"][2] +
                            elePin["current"]["values"][3];
                        var currconfpin =
                            eleConfirm["current"]["values"][0] +
                            eleConfirm["current"]["values"][1] +
                            eleConfirm["current"]["values"][2] +
                            eleConfirm["current"]["values"][3];
                        if (currpin != currconfpin) {
                            setInvalidPin(true);
                            elePin["current"].clear();
                            eleConfirm["current"].clear();
                            elePin["current"].focus();
                        } else {
                            setInvalidPin(false);
                            clearInterval(theInterval);
                            submitPin(currpin, currconfpin);
                        }
                    }
                }, 50);
                setTheInterval(theInterval);
                setCreatePinEntry(true);
            }
        }
        else {
            _dispatch(
                showResponseMessage(
                    responseJson[ApiKey._API_SUCCESS_KEY],
                    responseJson[ApiKey._API_MESSAGE_KEY]
                )
            )
        }
        init();
    }

    function setTransfertoToption(option) {
        let tempToOptions = [];
        let tempOption = fromOptions.filter(i => i.value === option)[0];
        let checkFromBalance = tempOption.label.substring(tempOption.label.indexOf(" - "), tempOption.label.length);
        setTransferFromOptions(checkFromBalance.slice(checkFromBalance.indexOf('-') + 2));
        if (isTransferBackToMainWallet) {
            if (option == 0) {
                walletList.map((product, index) => {
                    if (product.productId != option) {
                        let option = {
                            label:
                                product.productName +
                                " - " +
                                numberWithCurrencyFormat2(parseFloat(product.balance), 2, true),
                            value: product.productId,
                        };
                        tempToOptions.push(option);
                    }
                });
            } else {
                walletList.map((product, index) => {
                    if (product.productId == 0) {
                        let option = {
                            label:
                                product.productName +
                                " - " +
                                numberWithCurrencyFormat2(parseFloat(product.balance), 2, true),
                            value: product.productId,
                        };
                        tempToOptions.push(option);
                    }
                });
            }
        } else {
            setShowTaxWarning(false);
            if (option == 0) {
                walletList.map((product, index) => {
                    if (product.productId != option) {
                        let option = {
                            label:
                                product.productName +
                                " - " +
                                numberWithCurrencyFormat2(parseFloat(product.balance), 2, true),
                            value: product.productId,
                        };
                        tempToOptions.push(option);
                    }
                });
            } else {
                walletList.map((product, index) => {
                    if (product.productId == 0) {
                        let option = {
                            label:
                                product.productName +
                                " - " +
                                numberWithCurrencyFormat2(parseFloat(product.balance), 2, true),
                            value: product.productId,
                        };
                        tempToOptions.push(option);
                    }
                });
            }
        }
        setToOptions(tempToOptions);
    }

    function resetFormField() {
        // setTransferTo("");
        // setTransferFrom("");
        // setCurrentInput();
        setValue("fromWallet", null);
        setValue("toWallet", null);
        setCurrentInput();
    }

    const onSubmitA = async (data, e) => {
        _dispatch(setBusy());

        let params = {
            fromWallet: data.fromWallet,
            toWallet: data.toWallet,
            amount: amountValue1,
        };

        // let params = {
        //   fromWallet: transferFrom,
        //   toWallet: transferTo,
        //   amount: data.amount,
        // };

        let responseJson = await ApiEngine.post(
            ApiUrl._API_CREATE_WALLET_TRANSFER_TRANSACTION,
            createFormBody(params)
        );
        let responseMsg = responseJson[ApiKey._API_MESSAGE_KEY];
        responseMsg = responseMsg.replace("Amount", t("AMOUNT"));
        responseMsg = responseMsg.replace("From", t("FROM"));
        responseMsg = responseMsg.replace("Wallet", t("MAIN_WALLET"));
        responseMsg = responseMsg.replace("to", t("TO"));
        if (responseMsg.includes("Success")) {
            responseMsg = responseMsg.replace("Success", t("SUCCESS"));
        }
        else {
            responseMsg = responseMsg.replace("Failed", t("FAILED"));
            if (responseMsg.includes("Transaction")) {
                responseMsg = responseMsg.replace("Transaction", t("TRANSACTION"));
            }
        }
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            _dispatch(setIdle());

            setPaidAmount(responseJson[ApiKey._API_DATA_KEY].amount);
            setDeductedAmount(responseJson[ApiKey._API_DATA_KEY].deductedAmount);
            setTransferDate(moment().format("DD MMM YYYY, hh:mm A"));
            setIsTransferSuccess(true);
            setTranscactionNumber(responseJson[ApiKey._API_DATA_KEY].transactionId);

            init();
            //e.target.reset();
            resetFormField();
        } else {
            _dispatch(
                showResponseMessage(
                    responseJson[ApiKey._API_SUCCESS_KEY],
                    responseMsg
                )
            );
        }
        _dispatch(setIdle());
    };

    async function getMemberList(search, loadOptions, { page }) {
        var responseJson = await ApiEngine.get(
            `${ApiUrl._API_GET_USER_MEMBER_LIST}?start=${(page - 1) * DEFAULT_PAGE_SIZE
            }&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data = responseJson[ApiKey._API_DATA_KEY].data;
            let formattedData = data.map((m) => {
                return { label: m.username, value: m.id };
            });

            return {
                options: formattedData,
                hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
                additional: {
                    page: page + 1,
                },
            };
        }
    }

  const checkValueOnKeyDown1 = async (e) => {
    clearErrors();
    let tempAmountValue = amountValue1;
    if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) { //keyCode of 0 to 9, numpad 0 to 9
      tempAmountValue = amountValue1 != "0.00" ? (amountValue1.substring(0, amountValue1.indexOf(".")) + "" + e.key + ".00") : e.key + ".00";
      setAmountValue1(tempAmountValue);
    }
    else if (e.keyCode == 8 || e.keyCode == 46) //keyCode of backSpace and Del
    {
      let charBeforeDot = amountValue1.substring(0, amountValue1.indexOf("."));
      tempAmountValue = charBeforeDot.length > 1 && charBeforeDot != "0" ? amountValue1.substring(0, amountValue1.indexOf(".")).slice(0, -1) + ".00" : "0.00";
      setAmountValue1(tempAmountValue);
    }
    let min = 1;
    let max = transferFromOptions;
    if (max.includes(",")) { max = max.replace(",", ""); }
    let temp1 = parseFloat(max);
    let temp = parseFloat(tempAmountValue);
    if (tempAmountValue == "") {
      setError("amount", {
        message: t('PLEASE_ENTER_AMOUNT'),
      });
    }
    else if (parseFloat(tempAmountValue) < parseFloat(min)) {
      setError("amount", {
        message: t('PLEASE_ENTER_VALUE_NOT_LESS_THAN_VARIABLE', { amount: "1.00" }),
      });
    }
    else if (parseFloat(tempAmountValue) > parseFloat(max)) {
      setError("amount", {
        message: t('PLEASE_ENTER_VALUE_NOT_EXCEED_YOUR_WALLET_BALANCE'),
      });
    }
  }

  const checkValueOnKeyDown2 = async (e) => {
    clearErrorsInternalTransfer();
    let tempAmountValue = amountValue2;
    if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) { //keyCode of 0 to 9, numpad 0 to 9
      tempAmountValue = amountValue2 != "0.00" ? (amountValue2.substring(0, amountValue2.indexOf(".")) + "" + e.key + ".00") : e.key + ".00";
      setAmountValue2(tempAmountValue);
    }
    else if (e.keyCode == 8 || e.keyCode == 46) //keyCode of backSpace and Del
    {
      let charBeforeDot = amountValue2.substring(0, amountValue2.indexOf("."));
      tempAmountValue = charBeforeDot.length > 1 && charBeforeDot != "0" ? amountValue2.substring(0, amountValue2.indexOf(".")).slice(0, -1) + ".00" : "0.00";
      setAmountValue2(tempAmountValue);
    }
    let min = 1;
    let max = totalInternalTransferLimit > 0 ? _userData.walletBalance > internalTransferLimit ? internalTransferLimit : _userData.walletBalance : _userData.walletBalance;
    let msgIfOverMax = ReactHtmlParser(
      t(
        "PLEASE_ENTER_VALUE_NOT_MORE_THAN_VARIABLE",
        { amount: totalInternalTransferLimit > 0 ? _userData.walletBalance > internalTransferLimit ? internalTransferLimit : _userData.walletBalance : _userData.walletBalance }
      )
    );
    if (tempAmountValue == "") {
      setErrorInternalTransfer("internalamount", {
        message: t('PLEASE_ENTER_AMOUNT'),
      });
    }
    else if (parseFloat(tempAmountValue) < min) {
      setErrorInternalTransfer("internalamount", {
        message: ReactHtmlParser(
          t(
            "PLEASE_ENTER_VALUE_NOT_LESS_THAN_VARIABLE",
            { amount: 1 }
          )
        ),
      });
    }
    else if (parseFloat(tempAmountValue) > max) {
      setErrorInternalTransfer("internalamount", {
        message: msgIfOverMax
      });
    }
  }

  const checkValueOnSelectFixedAmt = async (fixedAmt, activeInput) => {
    let activeValue = activeInput == 1 ? amountValue1 : amountValue2;
    let updatedValue = (parseFloat(fixedAmt) + parseFloat(stringIsNullOrEmpty(activeValue) ? 0 : activeValue)).toString() + ".00";
    if (activeInput == 1) { setAmountValue1(updatedValue); } else if (activeInput == 2) { setAmountValue2(updatedValue); }
    let tempAmountValue = updatedValue;
    if (activeInput == 1) {
      clearErrors();
      let min = 1;
      let max = transferFromOptions;
      if (tempAmountValue == "") {
        setError("amount", {
          message: t('PLEASE_ENTER_AMOUNT'),
        });
      }
      else if (parseFloat(tempAmountValue) < min) {
        setError("amount", {
          message: t('PLEASE_ENTER_VALUE_NOT_LESS_THAN_VARIABLE', { amount: "1.00" }),
        });
      }
      else if (parseFloat(tempAmountValue) > max) {
        setError("amount", {
          message: t('PLEASE_ENTER_VALUE_NOT_EXCEED_YOUR_WALLET_BALANCE'),
        });
      }
    }
    else if (activeInput == 2) {
      clearErrorsInternalTransfer();
      let min = 1;
      let max = totalInternalTransferLimit > 0 ? _userData.walletBalance > internalTransferLimit ? internalTransferLimit : _userData.walletBalance : _userData.walletBalance;
      let msgIfOverMax = ReactHtmlParser(
        t(
          "PLEASE_ENTER_VALUE_NOT_MORE_THAN_VARIABLE",
          { amount: totalInternalTransferLimit > 0 ? _userData.walletBalance > internalTransferLimit ? internalTransferLimit : _userData.walletBalance : _userData.walletBalance }
        )
      );
      if (tempAmountValue == "") {
        setErrorInternalTransfer("internalamount", {
          message: t('PLEASE_ENTER_AMOUNT'),
        });
      }
      else if (parseFloat(tempAmountValue) < min) {
        setErrorInternalTransfer("internalamount", {
          message: ReactHtmlParser(
            t(
              "PLEASE_ENTER_VALUE_NOT_LESS_THAN_VARIABLE",
              { amount: 1 }
            )
          ),
        });
      }
      else if (parseFloat(tempAmountValue) > max) {
        setErrorInternalTransfer("internalamount", {
          message: msgIfOverMax
        });
      }
    }
  }

    return (
        <>
            <section className="section-padding" id="transfer-section">
                <div className="container">
                    <div className="row">
                        <div className="col-3" id="account-menu">
                            <Sidebar activePage={"WALLET"} />
                        </div>
                        <div className="col-9">
                            <ContentHeader enableBackButton title={t("TRANSFER")} />
                            {internalTransferStatus && !isTestAccount && <ul className="nav nav-tabs mt-5" id="my-tabs">
                                <li>
                                    <a
                                        data-toggle="tab"
                                        href="#transfer"
                                        className="font-12 active"
                                    >
                                        {t("TRANSFER")}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        data-toggle="tab"
                                        href="#internal-transfer"
                                        className="font-12"
                                    >
                                        {t("INTERNAL_TRANSFER")}
                                    </a>
                                </li>
                            </ul>}
                            <div className="template-box mt-3" id="payment-method-box">
                                <div className="tab-content">
                                    <div id="transfer" className="tab-pane in active">
                                        <form onSubmit={handleTransfer(onSubmitA)}>
                                            <div className="form-divider">
                                                <div>
                                                    <h4 className="font-15 mb-3">{t("TRANSFER_FROM")}</h4>

                                                    <Controller
                                                        control={control}
                                                        name="fromWallet"
                                                        defaultValue={null}
                                                        render={({ onChange, value }) => {
                                                            return (
                                                                <SelectOption
                                                                    options={fromOptions}
                                                                    placeholder={t("PLEASE_SELECT_WALLET")}
                                                                    value={value}
                                                                    onChange={(e) => {
                                                                        onChange(e.value);
                                                                        setSelectedFromOptions(e.value);
                                                                        setBonus(e.bonus);
                                                                        setTransfertoToption(e.value);
                                                                        handleTransferFrom(e.value);
                                                                    }}
                                                                />
                                                            );
                                                        }}
                                                        rules={{
                                                            validate: {
                                                                required: (value) =>
                                                                    value !== null || "PLEASE_SELECT_WALLET",
                                                            },
                                                        }}
                                                    />
                                                    {errors.fromWallet && (
                                                        <div className="invalid-feedback">
                                                            {t(errors.fromWallet.message)}
                                                        </div>
                                                    )}
                                                </div>
                                                <div>
                                                    <h4 className="font-15 mb-3">{t("TRANSFER_TO")}</h4>

                                                    <Controller
                                                        control={control}
                                                        name="toWallet"
                                                        defaultValue={null}
                                                        render={({ onChange, value }) => {
                                                            return (
                                                                <SelectOption
                                                                    options={toOptions}
                                                                    placeholder={t("PLEASE_SELECT_WALLET")}
                                                                    value={value}
                                                                    onChange={(e) => {
                                                                        onChange(e.value);
                                                                        handleTransferTo(e.value);
                                                                        setTransferToLabel(e.label);
                                                                    }}
                                                                    isDisabled={stringIsNullOrEmpty(transferFrom) && transferFrom !== 0}
                                                                />
                                                            );
                                                        }}
                                                        rules={{
                                                            validate: {
                                                                required: (value) =>
                                                                    value !== null || "PLEASE_SELECT_WALLET",
                                                            },
                                                        }}
                                                    />
                                                    {errors.toWallet && (
                                                        <div className="invalid-feedback">
                                                            {t(errors.toWallet.message)}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="mt-4">
                                                <h4 className="font-15 mb-3">{t("AMOUNT")}</h4>
                                                <div className="position-relative">
                                                    <input
                                                        id="amount"
                                                        key="amount"
                                                        type="text"
                                                        name="amount"
                                                        className="form-white-input"
                                                        placeholder={"0.00"}
                                                        value={numberWithCommas(amountValue1)}
                                                        disabled={stringIsNullOrEmpty(transferFrom) && transferFrom !== 0 && stringIsNullOrEmpty(transferToLabel)}
                                                        onKeyDown={(e) => {
                                                            if (stringIsNullOrEmpty(transferFrom) && transferFrom !== 0 && stringIsNullOrEmpty(transferToLabel)) {
                                                            } else {
                                                                checkValueOnKeyDown1(e);
                                                            }
                                                        }}
                                                    />
                                                    <div className="clear-amount" onClick={() => { setAmountValue1("0.00"); clearErrors(); }}>
                                                        <img
                                                            src={'/img/v3/reload/close.png'}
                                                            alt="i8 banks"
                                                            className="img-responsive"
                                                        />
                                                    </div>
                                                </div>
                                                {errors.amount && (
                                                    <div className="invalid-feedback">
                                                        {t(errors.amount.message)}
                                                    </div>
                                                )}
                                            </div>
                                            {member["transferBackMainWallet"] === 0 && (transferToLabel.includes('JJ4D') || transferToLabel.includes('JJGD')) ?
                                                <span className="text-red ml-2" style={{ fontSize: "10px", fontFamily: "averta-semibold-italic" }}>{t("NOTICE_UNABLE_TRANSFER_BACK")}</span>
                                                :
                                                ""
                                            }
                                            <br />
                                            <div className="amount-row">
                                                {FixedDepositAmount2.map((amount, index) => {
                                                    return (
                                                        <button
                                                            key={index}
                                                            type="button"
                                                            disabled={stringIsNullOrEmpty(transferFrom) && transferFrom !== 0 && stringIsNullOrEmpty(transferToLabel)}
                                                            onClick={() => {
                                                                if (stringIsNullOrEmpty(transferFrom) && transferFrom !== 0 && stringIsNullOrEmpty(transferToLabel)) {
                                                                } else {
                                                                    checkValueOnSelectFixedAmt(amount, 1);
                                                                }
                                                            }}
                                                            className="btn custom-button"
                                                        >
                                                            + {numberWithCommas(amount)}
                                                        </button>
                                                    );
                                                })}
                                            </div>
                                            <button type="submit" className="custom-btn-style-1 btn">
                                                {t("SUBMIT")}
                                            </button>
                                        </form>
                                    </div>
                                    <div id="internal-transfer" className="tab-pane fade">
                                        <form onSubmit={handleInternalTransfer(onSubmitB)}>
                                            <div className="form-divider">
                                                <div>
                                                    <h4 className="font-15 mb-3">{t("MEMBER")}</h4>
                                                    <Controller
                                                        className="form-white-select"
                                                        control={controlInternalTransfer}
                                                        name="userId"
                                                        render={({ onChange, value }) => {
                                                            return (
                                                                <AsyncPaginate
                                                                    styles={_SELECT_STYLES}
                                                                    placeholder={t("PLEASE_SELECT_MEMBER")}
                                                                    debounceTimeout={250}
                                                                    loadOptions={getMemberList}
                                                                    additional={{
                                                                        page: 1,
                                                                    }}
                                                                    value={selectedMember}
                                                                    onChange={(e) => {
                                                                        onChange(e.value);
                                                                        setSelectedMember(e);
                                                                    }}
                                                                />
                                                            );
                                                        }}
                                                        rules={{ required: "PLEASE_SELECT_MEMBER" }}
                                                    />
                                                    {errorsInternalTransfer.userId && (
                                                        <div className="invalid-feedback">
                                                            {t(errorsInternalTransfer.userId.message)}
                                                        </div>
                                                    )}
                                                </div>
                                                <div>
                                                    <h4 className="font-15 mb-3">{t("AMOUNT")}</h4>
                                                    <div className="position-relative">
                                                        <input
                                                            type="text"
                                                            id="internalamount"
                                                            key="internalamount"
                                                            name="internalamount"
                                                            className="form-white-input"
                                                            placeholder={"0.00"}
                                                            value={numberWithCommas(amountValue2)}
                                                            disabled={selectedMember == null}
                                                            onKeyDown={(e) => {
                                                                if (selectedMember == null) {
                                                                } else {
                                                                    checkValueOnKeyDown2(e);
                                                                }
                                                            }}
                                                        />
                                                        <div className="clear-amount" onClick={() => { setAmountValue2("0.00"); clearErrorsInternalTransfer(); }}>
                                                            <img
                                                                src={'/img/v3/reload/close.png'}
                                                                alt="i8 banks"
                                                                className="img-responsive"
                                                            />
                                                        </div>
                                                    </div>
                                                    {errorsInternalTransfer.internalamount && (
                                                        <div className="invalid-feedback">
                                                            {t(errorsInternalTransfer.internalamount.message)}
                                                        </div>
                                                    )}
                                                    {totalInternalTransferLimit != "0" && (
                                                        <div className="col-md-12">
                                                            <span className="reminder font-11 text-red font-semi-italic">
                                                                {t("AVAILABLE_TRANSFER_LIMIT")} :  {numberWithCurrencyFormat2(
                                                                    internalTransferLimit,
                                                                    2,
                                                                    true
                                                                )} / {numberWithCurrencyFormat2(
                                                                    totalInternalTransferLimit,
                                                                    2,
                                                                    true
                                                                )}
                                                            </span>
                                                        </div>
                                                    )}
                                                    <div className="amount-row">
                                                        {FixedDepositAmount2.map((amount, index) => {
                                                            return (
                                                                <button
                                                                    key={index}
                                                                    type="button"
                                                                    disabled={selectedMember == null}
                                                                    onClick={() => {
                                                                        if (selectedMember == null){
                                                                        } else {
                                                                            checkValueOnSelectFixedAmt(amount, 2);
                                                                        }
                                                                    }}
                                                                    className="btn custom-button"
                                                                >
                                                                    + {numberWithCommas(amount)}
                                                                </button>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            {pinEntered && (
                                                <div
                                                    className={pinVerified ? "text-green" : "text-red"}
                                                >
                                                    {pinVerified
                                                        ? t("PIN_VERIFIED") +
                                                        " " +
                                                        t("CLICK_SUBMIT_TO_CONTINUE")
                                                        : t("INVALID_PIN")}
                                                </div>
                                            )}
                                            <button type="submit" className="custom-btn-style-1 btn">
                                                {t("SUBMIT")}
                                            </button>
                                        </form>
                                        {showPinEntry && (
                                            <div>
                                                <div className="pin-wrapper"></div>
                                                <div className="pin-number">
                                                    <button
                                                        type="button"
                                                        style={{
                                                            alignSelf: "flex-end",
                                                            backgroundColor: "transparent",
                                                            border: "none",
                                                            marginRight: "15px",
                                                            fontSize: "2rem",
                                                            color: "#002e6c",
                                                            marginTop: "-15px",
                                                            float: "right",
                                                            fontWeight: "bold",
                                                        }}
                                                        onClick={() => {
                                                            clearInterval(theInterval);
                                                            // resetEnteredPin();
                                                            setShowPinEntry(false);
                                                        }}
                                                        data-dismiss="modal"
                                                        aria-hidden="true"
                                                    >
                                                        &times;
                                                    </button>
                                                    <strong
                                                        className="reload-title text-gold mb-3 d-block font-semi font-20"
                                                        style={{ marginLeft: "40px" }}
                                                    >
                                                        {t("ENTER_YOUR_PIN")}
                                                    </strong>
                                                    <div
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Backspace') {
                                                                onVerifyPinKeyPress("{bksp}");
                                                            }
                                                        }}
                                                    >
                                                        <PinInput
                                                            style={{ marginBottom: "35px" }}
                                                            ref={eleFinalPin}
                                                            length={4}
                                                            initialValue=""
                                                            secret={true}
                                                            focus={showPinEntry}
                                                            autoSelect={false}
                                                            // disabled={showPinEntry}
                                                            // onChange={(value, index) => {
                                                            //   setPinNumberFinalFocused(index);
                                                            // }}
                                                            onChange={(value, index) => {
                                                                onVerifyPinKeyPress(value, index);
                                                            }}
                                                            type="numeric"
                                                            inputMode="number"
                                                            inputStyle={{
                                                                border: "0",
                                                                margin: "0px 10px",
                                                                width: "40px",
                                                                height: "40px",
                                                                backgroundColor: "transparent",
                                                                borderBottom: "2px solid #002e6c",
                                                            }}
                                                            inputFocusStyle={{
                                                                borderBottom: "2px solid #FFC159",
                                                            }}
                                                            regexCriteria={/^[ 0-9_@./#&+-]*$/}
                                                        />
                                                    </div>

                                                    <strong
                                                        className="text-brand font-bold font-12"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setForgetPin(true);
                                                            sendPinCode();
                                                        }}
                                                    >
                                                        {t("RESET_PIN")}
                                                    </strong>
                                                    {/* <Keyboard
                            layout={{
                              default: ["1 2 3 4 5 6 7 8 9 . 0 {bksp}"],
                            }}
                            display={{
                              "{bksp}": "<i class='fas fa-backspace'></i>",
                            }}
                            theme={`keyboard keyboard-numpad`}
                            keyboardRef={(r) => (keyboard.current = r)}
                            onKeyPress={onVerifyPinKeyPress}
                            disableButtonHold
                            disableCaretPositioning
                          /> */}
                                                    {forgetPin && (
                                                        <p
                                                            className="text-brand"
                                                            style={{
                                                                textAlign: "center",
                                                                marginTop: "5px",
                                                            }}
                                                        >
                                                            {t("PIN_SENT")} {t("RESEND_IN")} {countdownPeriod}{" "}
                                                            {t("SECOND")}
                                                        </p>
                                                    )}
                                                    {showAlert && (
                                                        <div
                                                            className="content content-full-width"
                                                            style={{
                                                                position: "fixed",
                                                                top: "10px",
                                                                left: "0",
                                                                width: "100%",
                                                                zIndex: 9999,
                                                            }}
                                                        >
                                                            <div className="container">
                                                                <Alert color="danger">
                                                                    {t("WAIT_TO_RESEND")}
                                                                </Alert>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div
                                                        className="invalid-feedback"
                                                        style={{ fontSize: "12px", marginTop: "1rem" }}
                                                    >
                                                        <span>&nbsp;</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    fade={false}
                    contentClassName="modal-brand modal-bottom modal-numpad"
                    isOpen={createPinEntry}
                    centered
                >
                    <ModalBody>
                        <form>
                            <div className="display-wrapper" style={{ textAlign: "center" }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Backspace') {
                                        onCreatePinKeyPress("{bksp}");
                                    }
                                }}
                            >
                                <strong
                                    className="reload-title text-yellow mb-3 d-block"
                                    style={{ fontSize: "20px" }}
                                >
                                    {t("ENTER_YOUR_PIN")}
                                </strong>
                                <strong
                                    className="reload-title mb-3 d-block"
                                    style={{
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                        color: "#002e6c",
                                    }}
                                >
                                    {t("ENTER_YOUR_PIN")}
                                </strong>
                                <PinInput
                                    ref={elePin}
                                    length={4}
                                    initialValue=""
                                    secret={true}
                                    focus={true}
                                    autoSelect={false}
                                    // disabled={createPinEntry}
                                    type="numeric"
                                    onChange={(value, index) => {
                                        setPinNumberFocused(index);
                                        onCreatePinKeyPress(value);
                                    }}
                                    inputMode="numeric"
                                    //inputStyle={{ border: "0", margin: "0px 10px", width: "40px", height: "40px", backgroundColor: "transparent", borderBottom: "2px solid #002e6c" }}
                                    //inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                                    inputStyle={{
                                        border: "0",
                                        margin: "0px 10px",
                                        width: "40px",
                                        height: "40px",
                                        backgroundColor: "transparent",
                                        borderBottom: "2px solid #002e6c",
                                    }}
                                    inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                                    regexCriteria={/^[ 0-9_@./#&+-]*$/}
                                />
                                <strong
                                    className="reload-title mb-3 d-block"
                                    style={{
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                        color: "#002e6c",
                                        marginTop: "30px",
                                    }}
                                >
                                    {t("CONFIRM_YOUR_PIN")}
                                </strong>
                                <PinInput
                                    ref={eleConfirm}
                                    length={4}
                                    initialValue=""
                                    secret={true}
                                    focus={true}
                                    autoSelect={false}
                                    // disabled={createPinEntry}
                                    type="numeric"
                                    onChange={(value, index) => {
                                        setPinNumberConfirmFocused(index);
                                        onCreatePinKeyPress(value);
                                    }}
                                    inputMode="numeric"
                                    inputStyle={{
                                        border: "0",
                                        margin: "0px 10px",
                                        width: "40px",
                                        height: "40px",
                                        backgroundColor: "transparent",
                                        borderBottom: "2px solid #002e6c",
                                    }}
                                    inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                                    regexCriteria={/^[ 0-9_@./#&+-]*$/}
                                />
                                <div
                                    className="invalid-feedback"
                                    style={{ fontSize: "12px", marginTop: "1rem" }}
                                >
                                    {invalidPin && t("PIN_DOES_NOT_MATCH")}
                                    {!invalidPin && <span>&nbsp;</span>}
                                </div>
                                {/* <Keyboard
                  layout={{
                    default: ["1 2 3 4 5 6 7 8 9 . 0 {bksp}"],
                  }}
                  display={{
                    "{bksp}": "<i class='fas fa-backspace'></i>",
                  }}
                  theme={`keyboard keyboard-numpad`}
                  keyboardRef={(r) => (keyboard.current = r)}
                  onKeyPress={onCreatePinKeyPress}
                  disableButtonHold
                  disableCaretPositioning
                /> */}
                            </div>
                        </form>
                    </ModalBody>
                </Modal>
                {
                    //CREATE NEW USER FULL NAME
                }
                <Modal
                    fade={false}
                    contentClassName="modal-brand modal modal-numpad"
                    isOpen={showFullnamePopup}
                    centered
                >
                    <ModalBody>
                        <form onSubmit={handleFullNameSubmit(submitFullName)}>
                            <div className="display-wrapper text-center">
                                <strong
                                    className="reload-title text-yellow d-block"
                                    style={{ fontSize: "20px" }}
                                >
                                    {t("ENTER_YOUR_FULL_NAME")}
                                </strong>
                                <strong
                                    className="reload-title text-yellow mb-3 d-block"
                                    style={{ fontSize: "12px", fontStyle: "italic" }}
                                >
                                    {t("IN_YOUR_BANK_ACCOUNT")}
                                </strong>
                                <input
                                    type="text"
                                    name="userFullName"
                                    className="withdrawal-fullName-input"
                                    ref={registerFullName({
                                        required: "FIELD_REQUIRED",
                                        pattern: { value: /^[a-z A-Z]+$/, message: "INVALID_NAME" },
                                    })}
                                    onChange={(e) =>
                                        setValueFullName("userFullName", e.target.value)
                                    }
                                />
                                {errorsFullName.userFullName && (
                                    <div className="invalid-feedback">
                                        {t(errorsFullName.userFullName.message)}
                                    </div>
                                )}
                                <button
                                    type="submit"
                                    className="btn btn-block btn-brand-gradient mx-auto"
                                    style={{ width: "200px" }}
                                >
                                    {t("SUBMIT")}
                                </button>
                            </div>
                        </form>
                    </ModalBody>
                </Modal>
            </section>

            <BottomSuccessModal
                isOpen={isTransferSuccess}
                setIsOpen={setIsTransferSuccess}
                title={"TRANSFER_SUCCESS"}
                amount={paidAmount}
                deductedAmount={deductedAmount}
                bonus={bonus}
                date={transferDate}
                transactionNumber={transcactionNumber}
            />
        </>
    );
};
export default Transfer;

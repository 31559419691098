import React, { useState, useEffect } from "react";
//import "react-simple-keyboard/build/css/index.css";
import Sidebar from "components/sidebar";
import { ApiUrl } from "../../util/Constant";
import moment from "moment";
import { stringIsNullOrEmpty, numberWithCurrencyFormat } from "../../util/Util";
import { useTranslation } from "react-i18next";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";
import ContentHeader from "components/content/header";
import DateRangeFilter from "../../components/custom/DateRangeFilter";

const WalletStatement = (props) => {
    const { t } = useTranslation();
    const [walletUrl, setWalletUrl] = useState("");
    const [startDate, setStartDate] = useState(moment().startOf("week").format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().endOf("week").format('YYYY-MM-DD'));
    const [selectedDate, setSelectedDate] = useState("");

    useEffect(() => {
        getWalletTransfer(startDate, endDate);
    }, []);

    async function getWalletTransfer(start, end, selected = "") {

        var apiUrl = ApiUrl._API_GET_WALLET_TRANSFER + "?dateFrom=" + moment(start).format('YYYY-MM-DD HH:mm:ss') + "&dateTo=" + moment(end).endOf('day').format('YYYY-MM-DD HH:mm:ss')

        if (!stringIsNullOrEmpty(selected)) {
            apiUrl += "&SelectedDate=" + moment(selected).format("YYYY-MM-DD HH:mm:ss");
        }
        setWalletUrl(apiUrl);
    }
    useEffect(() => {
        if (!stringIsNullOrEmpty(selectedDate)) {
            getWalletTransfer(startDate, endDate, selectedDate);
        }
    }, [selectedDate]);

    return (
        <>
            <section className="section-padding" id="game-record-section">
                <div className="container">
                    <div className="row">
                        <div className="col-3" id="account-menu">
                            <Sidebar activePage={"REPORTS"} />
                        </div>
                        <div className="col-9">
                            <ContentHeader enableBackButton title={t("WALLET_STATEMENT")} />
                            <hr className="right-title-hr" />
                            <div className="template-box mt-3" id="wallet-statement-box">
                                <DateRangeFilter
                                    startDate={startDate}
                                    endDate={endDate}
                                    setStartDate={setStartDate}
                                    setEndDate={setEndDate}
                                    onSave={(start, end) => {
                                        setStartDate(moment(start));
                                        setEndDate(moment(end));
                                        getWalletTransfer(start, end);
                                        setSelectedDate("");
                                    }}
                                    options={true}
                                />
                                <div className="card card-package">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table reportTable">
                                                <thead>
                                                    <tr>
                                                        <th>{t("DATE")}</th>
                                                        <th>{t("AMOUNT")}</th>
                                                        <th>{t("FROMWALLET")}</th>
                                                        <th>{t("TOWALLET")}</th>
                                                        <th>{t("BONUS")}</th>
                                                        <th>{t("FROMWALLETBALANCEBEFORE")}</th>
                                                        <th>{t("FROMWALLETBALANCEAFTER")}</th>
                                                        <th>{t("TOWALLETBALANCEBEFORE")}</th>
                                                        <th>{t("TOWALLETBALANCEAFTER")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <DynamicLoadTable
                                                        pageSize={20}
                                                        apiQuery={walletUrl}
                                                        searchEnabled={true}
                                                        isTable={true}
                                                        render={(item, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>
                                                                        {moment(item["createdTime"]).format("D MMMM YYYY")}
                                                                    </td>
                                                                    <td>
                                                                        {numberWithCurrencyFormat(
                                                                            parseFloat(item["depositAmount"]),
                                                                            2
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {item["transferPerson"]}
                                                                    </td>
                                                                    <td>
                                                                        {item["productName"]}
                                                                    </td>
                                                                    <td>
                                                                        {item["bonus"]}
                                                                    </td>
                                                                    <td>
                                                                        {numberWithCurrencyFormat(
                                                                            parseFloat(item["walletBefore"]),
                                                                            2
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {numberWithCurrencyFormat(
                                                                            parseFloat(item["walletAfter"]),
                                                                            2
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {numberWithCurrencyFormat(
                                                                            parseFloat(item["gameWalletBefore"]),
                                                                            2
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {numberWithCurrencyFormat(
                                                                            parseFloat(item["gameWalletAfter"]),
                                                                            2
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }}
                                                    />
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
export default WalletStatement;
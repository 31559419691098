import React, { useState, useEffect } from "react";
import GameMenu from "components/game-list/game-menu";
import ApiEngine from "../../util/ApiEngine";
import { ApiKey, ApiUrl, SessionKey, _MAIN_WALLET_ID, AlertTypes } from "../../util/Constant";
import { useDispatch } from "react-redux";
import { showMessage } from "../../redux/AppAction";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const CockFighting = (props) => {
  const [gameData, setGameData] = useState({});
  const { isLoggedIn } = useSelector((state) => state.authState);
  const _dispatch = useDispatch();
  const { t } = useTranslation();
  const [isSpecialRollover, setIsSpecialRollover] = useState(false);
  const [playableCategory, setPlayableCategory] = useState([]);
  const [productCategory, setProductCategory] = useState([]);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      getProductCategory();
      getMemberSpecialRollover();
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (isSpecialRollover) {
      getBonusDepositProductCategory();
    }
  }, [isSpecialRollover, productCategory])

  const init = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_PRODUCT_GAME_LIST_BY_CATEGORY_ID + "?categoryName=CockFighting&languageCode=" +
      (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
        localStorage.getItem(SessionKey._LANGUAGE) : 'en'));
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY][0];
      setGameData(data);
    }
  }

  async function startGame(
    gameId,
    isApp,
    categoryTitle
  ) {
    if (isSpecialRollover) {
      let productCategoryListArr = [...playableCategory];

      if (productCategoryListArr.includes(categoryTitle)) {
        window.open(ApiUrl._API_START_GAME + "?gameId=" + gameId + "&isApp=" + (isApp == true ? "1" : "0") + "&device=d");
      }
      else {
        _dispatch(
          showMessage({
            type: AlertTypes._INFO,
            content: t("ROLLOVER_EXIST"),
            onConfirm: () => {
            },
          })
        );
      }
    }
    else {
      window.open(ApiUrl._API_START_GAME + "?gameId=" + gameId + "&isApp=" + (isApp == true ? "1" : "0") + "&device=d");
    }
  }

  const getMemberSpecialRollover = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_IS_SPECIAL_ROLLOVER);
    let data = "";

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      data = responseJson[ApiKey._API_DATA_KEY];
      setIsSpecialRollover(data);
    }
  }

  const getBonusDepositProductCategory = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_BONUS_DEPOSIT_PRODUCT_CATEGORY);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      let productCategoryArr = [];

      if (data.includes(",")) {
        productCategoryArr = responseJson[ApiKey._API_DATA_KEY].split(",");
      }
      else {
        productCategoryArr.push(data);
      }

      let tempArr = [];
      [...productCategory].forEach((x) => {
        if (productCategoryArr.includes(x.value)) {
          tempArr.push(x.originalTitle);
        }
      })

      setPlayableCategory(tempArr);
    }
  }

  const getProductCategory = async () => {
    let tempArr = [];
    var productCategoryJson = await ApiEngine.get(ApiUrl._API_GET_PRODUCT_CATEGORY);
    if (productCategoryJson[ApiKey._API_SUCCESS_KEY]) {
      let data = productCategoryJson[ApiKey._API_DATA_KEY];
      data.forEach(x => {
        if (x.id > 0) {
          tempArr.push({ value: x.id.toString(), label: x.title, originalTitle: x.originalTitle });
        }
      });
      setProductCategory(tempArr);
    }
  }

  return (
    <>
      <section className='category-section'>
        <GameMenu />
      </section>
      <section id='cockfighting-section1'>

      </section>
      <section id='cockfighting-section2'>
        {/*<img*/}
        {/*  src={require("../../assets/img/providers/Backdrop_RNG.png")}*/}
        {/*  className='img-100'*/}
        {/*  alt='rng-icon'*/}
        {/*/>*/}
        <a
          onClick={() => {
            if (isLoggedIn) {
              startGame(
                gameData['id'],
                gameData['isApp'],
                "CockFighting"
              );
            }
            else {
              _dispatch(
                showMessage({
                  type: AlertTypes._INFO,
                  content: t("PLEASE_LOGIN_FIRST"),
                  onConfirm: () => {
                    console.log("hi");
                  },
                })
              );
            }
          }
          }
          className={'cock-fighting' + (gameData['gameUm'] === true ? ' game-um' : '')}
        >
          <img
            src={gameData["desktopImage"]}
            className='img-responsive cock-fighting-icon'
            alt='cock-fighting-icon'
          />
          {isLoggedIn && <div className="um-icon">
            <img
              src={"/img/maintenance.png"}
              className='img-responsive'
              alt='poker-icon'
            />
          </div>}
          {/*<img*/}
          {/*  src={require("../../assets/img/rng/girl-hover.png")}*/}
          {/*  className='img-responsive rng-girl-hover'*/}
          {/*  alt='rng-icon'*/}
          {/*/>*/}
        </a>
      </section>
    </>
  );
};
export default CockFighting;

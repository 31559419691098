import React from "react";
import Select from "react-select";

const SelectOption = (props) => {
  const { options, placeholder = "", value, onChange, ...otherProps } = props;
  const _SELECT_STYLES = {
    menu: (styles, state) => ({
      ...styles,
      position: 'absolute !important',
      zIndex: 2,
      background: '#00142E !important;',
      fontSize: '16px !important',
      cursor: 'pointer',
      color: '#FDBE2F',
    }),
    control: (styles, state) => ({
      ...styles,
      background: "#FFFFFF !important",
      border: "0px !important",
      outline: "unset!important",
      width: "100%",
      color: "#6B88B0",
      fontFamily: "montserrat",
      fontSize: "16px",
      boxShadow: 'none !important',
      borderRadius: '5px !important',
      minHeight: '35px',
      padding: '0px 10px',
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {

      if (isSelected || isFocused) {
        return {
          ...styles,
          background: "#FDBE2F",
          color: '#00142E',
          cursor: 'pointer'
        };
      } else {
        return {
          ...styles
        };
      }
    },
    singleValue: styles => ({
      ...styles,
      fontSize: '16px',
      color: "#001A3D",
    }),
    placeholder: styles => ({
      ...styles,
      fontSize: '16px'
    }),
    indicatorSeparator: styles => ({
      ...styles,
      display: 'none',
      background: 'red',
    }),
    dropdownIndicator: styles => ({
      ...styles,
      color: '#001A3D', // Always dark
      '&:hover': {
        color: '#001A3D' // Always dark on hover
      }
    }),
  }

  return (
    <Select
      options={options}
      isSearchable={false}
      styles={_SELECT_STYLES}
      placeholder={
        options.filter((option) => option.value == value)[0] !== undefined
          ? options.filter((option) => option.value == value)[0].label
          : placeholder
      }
      value={options.filter((option) => option.value == value)}
      onChange={(e) => {
        onChange(e);
      }}
      {...otherProps}
    />
  );
};

export default SelectOption;

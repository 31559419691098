import React, { useState, useEffect, useRef } from "react";
//import "react-simple-keyboard/build/css/index.css";
import Sidebar from "components/sidebar";
import { map } from "lodash";
import { ApiKey, ApiUrl, WebUrl, PhoneNumberPrefix } from "../../util/Constant";
import {
  createFormBody,
  createMultiPartFormBody,
  formatNumber,
  stringIsNullOrEmpty,
} from "../../util/Util";
import {
  setBusy,
  setIdle,
  showMessage,
  showResponseMessage,
} from "../../redux/AppAction";
import { updateUserData } from "../../redux/AuthAction";
import { useDispatch } from "react-redux";
import ApiEngine from "../../util/ApiEngine";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import MenuRowItem from "components/menu-row/item";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import Keyboard from "react-simple-keyboard";
//import "react-simple-keyboard/build/css/index.css";
import PinInput from "react-pin-input";
import Button from "components/button";
import ContentHeader from "components/content/header";
import DateTime from 'react-datetime';
import moment from 'moment';
import DatePicker from "react-datepicker";

const Settings = (props) => {
  const { t } = useTranslation();
  const _dispatch = useDispatch();
  const [member, setMember] = useState();
  const [memberFullName, setMemberFullName] = useState("");
  // const [memberEmail, setMemberEmail] = useState("");
  const [formattedPhone, setFormattedPhone] = useState("");
  const [showFormattedPhone, setshowFormattedPhone] = useState("");
  const [TACphoneNumber, setTACphoneNumber] = useState("");
  const [showThemeAlert, setShowThemeAlert] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState(0);
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const [checkPinExist, setCheckPinExist] = useState(true);
  const [checkVipPinExist, setCheckVipPinExist] = useState(true);
  const [oldPinNumber, setOldPinNumber] = useState("");
  const [pinNumber, setPinNumber] = useState("");
  const [pinNumberConfirm, setPinNumberConfirm] = useState("");

  const [oldVipPinNumber, setOldVipPinNumber] = useState("");
  const [vipPinNumber, setVipPinNumber] = useState("");
  const [vipPinNumberConfirm, setVipPinNumberConfirm] = useState("");

  const [tempPinNumber, setTempPinNumber] = useState("");

  const [oldPinNumberFocused, setOldPinNumberFocused] = useState(0);
  const [pinNumberFocused, setPinNumberFocused] = useState(0);
  const [pinNumberConfirmFocused, setPinNumberConfirmFocused] = useState(0);

  const [oldVipPinNumberFocused, setOldVipPinNumberFocused] = useState(0);
  const [vipPinNumberFocused, setVipPinNumberFocused] = useState(0);
  const [vipPinNumberConfirmFocused, setVipPinNumberConfirmFocused] =
    useState(0);

  const [tempPinNumberFocused, setTempPinNumberFocused] = useState(0);
  const [showPinEntry, setShowPinEntry] = useState(false);
  const [showVipPinEntry, setShowVipPinEntry] = useState(false);
  const [allowPinClick, setAllowPinClick] = useState(true);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [isShareholder, setIsShareholder] = useState(false);
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [showGeneralInfoModal, setShowGeneralInfoModal] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [showChangePinModal, setShowChangePinModal] = useState(false);
  const [showVerifyAccountModal, setShowVerifyAccountModal] = useState(false);
  const [selectedPhonePrefix, setSelectedPhonePrefix] = useState( 
    ""
    // PhoneNumberPrefix[0].value
  );
  const [countdownPeriod, setCountdownPeriod] = useState(0);
  const [sentCode, setSentCode] = useState(false);
  const [enteredCode, setEnteredCode] = useState("");
  const _COUNTDOWN_PERIOD = 120;
  const _SECONDS_IN_MS = 1000;
  const _MIN_PASSWORD_LENGTH = 6;
  const [activeTab, setActiveTab] = useState(0);
  var _userData = useSelector((state) => state.authState.userData);

  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [birthModal, setBirthModal] = useState(false);
  const [birthdayValue, setBirthdayValue] = useState("");
  const [confirmMessage, setConfirmMessage] = useState(false);
  const [memberId, setMemberId] = useState("");
    const [formattedEmail, setFormattedEmail] = useState("");
    const [TACemail, setTACemail] = useState("");
    const [countdownPeriodEmail, setCountdownPeriodEmail] = useState(0);
    const [sentCodeEmail, setSentCodeEmail] = useState(false);
    const [enteredCodeEmail, setEnteredCodeEmail] = useState("");
    const [disabledDate, setDisabledDate] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [birthdayString, setBirthdayString] = useState("");

  const [currentPinRef, setCurrentPinRef] = useState(0);
  const pin_1 = useRef(null);
  const pin_2 = useRef(null);
  const pin_3 = useRef(null);
  const pin_vip_1 = useRef(null);
  const pin_vip_2 = useRef(null);
  const pin_vip_3 = useRef(null);
  const pin_temp = useRef(null);
  const pin_vip_temp = useRef(null);

  const {
    register: profileRegister,
    errors: profileErrors,
    handleSubmit: handleProfileSubmit,
    setValue: setProfileValue,
    getValues: getProfileValues,
    clearErrors: clearProfileErrors,
    watch: profileWatch,
  } = useForm();

  const {
    errors: resetPasswordErrors,
    handleSubmit: handleResetPasswordSubmit,
    watch: resetPasswordWatch,
    setValue: setResetPasswordValue,
    setError: setResetPasswordError,
    clearErrors: clearResetPasswordErrors,
    register: resetPasswordRegister,
  } = useForm();

    const {
        handleSubmit, register, errors
    } = useForm();

  const { handleSubmit: handlePinSubmit } = useForm();

  const tabPinHeadings = [
    {
      title: "WITHDRAWAL",
      tab_id: 0,
    },
    {
      title: "SHAREHOLDER",
      tab_id: 1,
    },
  ];

  const settingList = [
    {
      image: "/img/acc-icon1.svg",
      text: "GENERAL_INFO",
      link: WebUrl._URL_TERMS,
      onClick: () => setShowGeneralInfoModal(true),
    },
    {
      image: "/img/acc-icon2.svg",
      text: "CHANGE_PASSWORD",
      onClick: () => setShowChangePasswordModal(true),
    },
    {
      image: "/img/acc-icon3.svg",
      text: "CHANGE_WITHDRAWAL_OR_SHAREHOLDER_PIN",
      onClick: () => setShowChangePinModal(true),
    },
    {
      image: "/img/acc-icon4.svg",
      text: "TNC",
      link: WebUrl._URL_TERMS,
    },
  ];

  useEffect(() => {
    init();

    return () => {
      setIsContentLoaded(false);
    };
  }, []);

  useEffect(() => {
    if (countdownPeriod > 0) {
      setTimeout(() => {
        let tempPeriod = countdownPeriod - 1;
        setCountdownPeriod(tempPeriod);
      }, _SECONDS_IN_MS);
    } else {
      setSentCode(false);
    }
  }, [countdownPeriod]);


    useEffect(() => {
            if (countdownPeriodEmail > 0) {
                setTimeout(() => {
                    let tempPeriod = countdownPeriodEmail - 1;
                    setCountdownPeriodEmail(tempPeriod);
                }, _SECONDS_IN_MS);
            } else {
                setSentCodeEmail(false);
            }
  }, [countdownPeriodEmail]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
    };

  async function init() {
    let params = {};
    let responseJson = await ApiEngine.post(
      ApiUrl._API_CHECK_MEMBER_PIN_NUMBER,
      createFormBody(params)
    );
    if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
      setCheckPinExist(false);
    }
    responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_DETAILS_BY_ID);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            
      if (responseJson[ApiKey._API_DATA_KEY]["isShareHolder"] === true) {
        let responseJson2 = await ApiEngine.post(
          ApiUrl._API_CHECK_SHAREHOLDER_PIN_NUMBER,
          createFormBody(params)
        );

        if (!responseJson2[ApiKey._API_SUCCESS_KEY]) {
          setCheckVipPinExist(false);
        }
      }

      setMember(responseJson[ApiKey._API_DATA_KEY]);
      setIsShareholder(responseJson[ApiKey._API_DATA_KEY]["isShareHolder"]);
      setSelectedTheme(responseJson[ApiKey._API_DATA_KEY]["selectedTheme"]);
        setIsPhoneVerified(responseJson[ApiKey._API_DATA_KEY]["isPhoneVerify"]);
        setIsEmailVerified(responseJson[ApiKey._API_DATA_KEY]["isEmailVerify"]);
      setIsContentLoaded(true);
      setProfileValue(
        "username",
        responseJson[ApiKey._API_DATA_KEY]["username"]
      );
      setProfileValue(
        "phoneNumber",
        "+" + responseJson[ApiKey._API_DATA_KEY]["phoneNumber"]
      );
      setFormattedPhone(responseJson[ApiKey._API_DATA_KEY]["phoneNumber"]);
      setMemberFullName(responseJson[ApiKey._API_DATA_KEY]["userFullName"]);
      // setMemberEmail(responseJson[ApiKey._API_DATA_KEY]["email"]);
      setFormattedEmail(responseJson[ApiKey._API_DATA_KEY]["email"]);
      setMemberId(responseJson[ApiKey._API_DATA_KEY]['id']);
      setBirthdayValue(responseJson[ApiKey._API_DATA_KEY]["birthDateString"]);

      var tempPhoneNumber = responseJson[ApiKey._API_DATA_KEY]["phoneNumber"];
      setSelectedPhonePrefix(
        PhoneNumberPrefix.filter((prefix) =>
          tempPhoneNumber.includes(prefix.value)
        ).length > 0
          ? PhoneNumberPrefix.filter((prefix) =>
              tempPhoneNumber.includes(prefix.value)
            )[0].value
          : PhoneNumberPrefix[0].value
      );
      
      setshowFormattedPhone(
        responseJson[ApiKey._API_DATA_KEY]["phoneNumber"].substring(
          //responseJson[ApiKey._API_DATA_KEY]["phoneNumber"].indexOf(
          //    selectedPhonePrefix
          //) + 2,
          PhoneNumberPrefix.filter((x) => tempPhoneNumber.includes(x.value))[0].value.length,
          responseJson[ApiKey._API_DATA_KEY]["phoneNumber"].length
        )
        );
        checkENB(responseJson[ApiKey._API_DATA_KEY]);
    } else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }
  }
   
  const handleToggleTheme = async (isModernTheme) => {
    let themeId = isModernTheme ? 1 : 0;
    let params = {
      selectedTheme: themeId,
    };
    var responseJson = await ApiEngine.post(
      ApiUrl._API_UPDATE_MEMBER_THEME,
      createFormBody(params)
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      _dispatch(setBusy());
      setSelectedTheme(themeId);
      await _dispatch(updateUserData());
      setShowThemeAlert(true);
      _dispatch(setIdle());
      setTimeout(() => setShowThemeAlert(false), 3000);
    }
  };

  const sendVerificationCode = async () => {
    await ApiEngine.post(
      ApiUrl._API_SEND_VERIFICATION_CODE,
      createMultiPartFormBody({ phoneNumber: formattedPhone })
    ).then((response) => {
      if (response[ApiKey._API_SUCCESS_KEY]) {
        let tempTACphoneNumber = response[ApiKey._API_DATA_KEY];
        setTACphoneNumber(tempTACphoneNumber);
        setSentCode(true);
        setCountdownPeriod(_COUNTDOWN_PERIOD);
      }
    });
  };

    const sendVerificationCodeEmail = async () => {
        _dispatch(setBusy());
        await ApiEngine.post(
            ApiUrl._API_SEND_EMAIL_VERIFICATION_CODE,
            createMultiPartFormBody({ email: formattedEmail })
        ).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                let tempTACEmail = response[ApiKey._API_DATA_KEY];
                setTACemail(tempTACEmail);
                setSentCodeEmail(true);
                setCountdownPeriodEmail(_COUNTDOWN_PERIOD);
                setDisabledDate(true);
            } else {
                _dispatch(showResponseMessage(response[ApiKey._API_SUCCESS_KEY], response[ApiKey._API_MESSAGE_KEY]));
            }
            _dispatch(setIdle());
        });
    };

  const submitProfileForm = async (data, e) => {
    e.preventDefault();
    _dispatch(setBusy());

    if (!isPhoneVerified) {
      // if (formattedPhone !== TACphoneNumber) {
      //   await _dispatch(
      //     showResponseMessage(false, t("PHONE_NUMBER_TAC_MISMATCH"))
      //   );
      // } else 
      {
        let verifyJson = await ApiEngine.post(
          ApiUrl._API_VERIFY_PHONE_NUMBER,
          createMultiPartFormBody({
            verificationCode: data.profileVerifyCode,
          })
        );
        if (verifyJson[ApiKey._API_SUCCESS_KEY]) {
          if (!member.userFullName) {
            submitFullName(data);
          }
          submitPhoneNumber();

          _dispatch(
            showResponseMessage(
              verifyJson[ApiKey._API_SUCCESS_KEY],
              verifyJson[ApiKey._API_MESSAGE_KEY]
            )
          );
        } else {
          _dispatch(
            showResponseMessage(
              verifyJson[ApiKey._API_SUCCESS_KEY],
              verifyJson[ApiKey._API_MESSAGE_KEY]
            )
          );
        }
      }
    } else {
      if (!member.userFullName) {
        submitFullName(data);
      }
    }
    setSentCode(false);
    setShowGeneralInfoModal(false);
    _dispatch(setIdle());
  };

  async function submitFullName(data) {
    let params = {
      userFullName: data.profileFullName,
    };
    let responseJson = await ApiEngine.post(
      ApiUrl._API_UPDATE_MEMBER_USERFULLNAME,
      createFormBody(params)
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }
    init();
  }

  async function submitPhoneNumber() {
    // let params = {
    //   phoneNumber: formattedPhone
    // };

    let data = [];

    data["phoneNumber"] = selectedPhonePrefix + showFormattedPhone;
    data["isMemberOperate"] = true;

    var responseJson = await ApiEngine.post(
      ApiUrl._API_UPDATE_MEMBER_PHONE_NUMBER,
      createMultiPartFormBody(data)
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }

    if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
      _dispatch(
        showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
      );
    }
    init();
  }

  const submitResetPasswordForm = async (data) => {
    //data["memberId"] = "";
    data["memberId"] = _userData.userId;

    var responseJson = await ApiEngine.post(
      ApiUrl._API_MEMBER_RESET_PASSWORD,
      createMultiPartFormBody(data)
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setResetPasswordValue("currentPassword", "");
      setResetPasswordValue("password", "");
      setResetPasswordValue("confirmPassword", "");
      clearResetPasswordErrors();
    }
    _dispatch(
      showResponseMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        responseJson[ApiKey._API_MESSAGE_KEY]
      )
    );
  };

  const changePin = async () => {
    try {
      let params = {
        //oldPinExist: checkPinExist,
        oldPinNumber: oldPinNumber,
        pinNumber: pinNumber,
        confirmPinNumber: pinNumberConfirm,
      };

      //console.log(params);

      var responseJson = await ApiEngine.post(
        ApiUrl._API_SET_MEMBER_PIN_NUMBER,
        createFormBody(params)
      );

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        setCheckPinExist(true);
      }

      checkPinExist && pin_1["current"].clear();
      pin_2["current"].clear();
      pin_3["current"].clear();
      setOldPinNumber("");
      setPinNumber("");
      setPinNumberConfirm("");
      setTempPinNumber("");
      setOldPinNumberFocused(0);
      setPinNumberFocused(0);
      setPinNumberConfirmFocused(0);

      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          t(responseJson[ApiKey._API_MESSAGE_KEY])
        )
      );
    } catch (err) {
      _dispatch(showResponseMessage(false, err));
    }
  };

  const changeShareholderPin = async () => {
    try {
      let params = {
        oldPinNumber: oldVipPinNumber,
        pinNumber: vipPinNumber,
        confirmPinNumber: vipPinNumberConfirm,
      };

      // console.log(params);

      var responseJson = await ApiEngine.post(
        ApiUrl._API_SET_SHAREHOLDER_PIN_NUMBER,
        createFormBody(params)
      );

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        setCheckVipPinExist(true);
      }

      checkVipPinExist && isShareholder && pin_vip_1["current"].clear();
      pin_vip_2["current"].clear();
      pin_vip_3["current"].clear();
      setOldVipPinNumber("");
      setVipPinNumber("");
      setVipPinNumberConfirm("");
      setTempPinNumber("");
      setOldVipPinNumberFocused(0);
      setVipPinNumberFocused(0);
      setVipPinNumberConfirmFocused(0);

      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    } catch (err) {
      _dispatch(showResponseMessage(false, err));
    }
  };

  function onKeyPress(key) {
    setAllowPinClick(false);
    // 1st: have to check currentPinRef
    // 2nd: have to know which index is currently focused for altering the state.value
    // 3rd: have to check if at elePin.index(3), if yes then convert value to string and focus on eleConfirm.index(0)
    // 4th: have to check if at eleConfirm.index(3), if yes then convert value to string, and submitPin

    let currentIndex = 0;
    let pinStr = tempPinNumber;
    let keyPressed = '';

    if (currentPinRef === 1) {
      if (key !== "{bksp}") {
        currentIndex = tempPinNumberFocused;
        // pin_temp["current"]["elements"][currentIndex].focus();
        keyPressed = pin_temp["current"]["elements"][currentIndex].state.value;
        pin_1["current"]["elements"][currentIndex].state.value = key.slice(-1);
        pin_temp["current"]["elements"][currentIndex].state.value = keyPressed;
        pinStr = key;
        setTempPinNumber(pinStr);
        if (currentIndex !== 3 && pinStr.length != 4) {
          currentIndex++;
          setTempPinNumberFocused(currentIndex);
        } 
        else if(pinStr.length < 4) {
          setOldPinNumber("");
          setTempPinNumber("");
          setTempPinNumberFocused(0);
          setShowPinEntry(true);
        }
        else {
          setOldPinNumber(pinStr);
          setTempPinNumber("");
          pin_temp["current"].clear();
          setTempPinNumberFocused(0);
          setShowPinEntry(false);
          setTimeout(function () {
            setAllowPinClick(true);
          }, 1000);
        }
      } else {
        if (!stringIsNullOrEmpty(pin_1["current"])) pin_1["current"].clear();
        pin_temp["current"].clear();
        setOldPinNumber("");
        setTempPinNumber("");
        setOldPinNumberFocused(0);
        setTempPinNumberFocused(0);
      }
    } else if (currentPinRef === 2) {
      if (key !== "{bksp}") {
        currentIndex = tempPinNumberFocused;
        // pin_temp["current"]["elements"][currentIndex].focus();
        keyPressed = pin_temp["current"]["elements"][currentIndex].state.value;
        pin_2["current"]["elements"][currentIndex].state.value = key.slice(-1);
        pin_temp["current"]["elements"][currentIndex].state.value = keyPressed;
        pinStr = key;
        setTempPinNumber(pinStr);
        if (currentIndex !== 3 && pinStr.length != 4) {
          currentIndex++;
          setTempPinNumberFocused(currentIndex);
        } else if(pinStr.length < 4) {
          setPinNumber("");
          setTempPinNumber("");
          setTempPinNumberFocused(0);
          setShowPinEntry(true);
        } else {
          setPinNumber(pinStr);
          setTempPinNumber("");
          pin_temp["current"].clear();
          setTempPinNumberFocused(0);
          setShowPinEntry(false);
          setTimeout(function () {
            setAllowPinClick(true);
          }, 1000);
        }
      } else {
        if (!stringIsNullOrEmpty(pin_2["current"])) 
        pin_2["current"].clear();
        pin_temp["current"].clear();
        setPinNumber("");
        setTempPinNumber("");
        setPinNumberFocused(0);
        setTempPinNumberFocused(0);
      }
    } else {
      if (key !== "{bksp}") {
        currentIndex = tempPinNumberFocused;
        // pin_temp["current"]["elements"][currentIndex].focus();
        keyPressed = pin_temp["current"]["elements"][currentIndex].state.value;
        pin_3["current"]["elements"][currentIndex].state.value = key.slice(-1);
        pin_temp["current"]["elements"][currentIndex].state.value = keyPressed;
        pinStr = key;
        setTempPinNumber(pinStr);
        if (currentIndex !== 3 && pinStr.length != 4) {
          currentIndex++;
          setTempPinNumberFocused(currentIndex);
        } else if(pinStr.length < 4) {
          setPinNumberConfirm("");
          setTempPinNumber("");
          setTempPinNumberFocused(0);
          setShowPinEntry(true);
        } else {
          setPinNumberConfirm(pinStr);
          setTempPinNumber("");
          pin_temp["current"].clear();
          setTempPinNumberFocused(0);
          setShowPinEntry(false);
          setTimeout(function () {
            setAllowPinClick(true);
          }, 1000);
        }
      } else {
        if (!stringIsNullOrEmpty(pin_3["current"])) 
        pin_3["current"].clear();
        pin_temp["current"].clear();
        setPinNumberConfirm("");
        setTempPinNumber("");
        setPinNumberConfirmFocused(0);
        setTempPinNumberFocused(0);
      }
    }
  }

  function onVipKeyPress(key) {
    setAllowPinClick(false);
    // 1st: have to check currentPinRef
    // 2nd: have to know which index is currently focused for altering the state.value
    // 3rd: have to check if at elePin.index(3), if yes then convert value to string and focus on eleConfirm.index(0)
    // 4th: have to check if at eleConfirm.index(3), if yes then convert value to string, and submitPin

    let currentIndex = 0;
    let pinStr = tempPinNumber;
    let keyPressed = '';

    if (currentPinRef === 1) {
      if (key !== "{bksp}") {
        currentIndex = tempPinNumberFocused;
        // pin_vip_temp["current"]["elements"][currentIndex].focus();
        keyPressed = pin_vip_temp["current"]["elements"][currentIndex].state.value;
        pin_vip_1["current"]["elements"][currentIndex].state.value = key.slice(-1);;
        pin_vip_temp["current"]["elements"][currentIndex].state.value = keyPressed;
        pinStr = key;
        setTempPinNumber(pinStr);
        if (currentIndex !== 3) {
          currentIndex++;
          setTempPinNumberFocused(currentIndex);
        } 
        else if(pinStr.length < 4) {
          setOldVipPinNumber("");
          setTempPinNumber("");
          setTempPinNumberFocused(0);
          setShowVipPinEntry(true);
        }
        else {
          setOldVipPinNumber(pinStr);
          setTempPinNumber("");
          pin_vip_temp["current"].clear();
          setTempPinNumberFocused(0);
          setShowVipPinEntry(false);
          setTimeout(function () {
            setAllowPinClick(true);
          }, 1000);
        }
      } else {
        if (!stringIsNullOrEmpty(pin_vip_1["current"]))
        pin_vip_1["current"].clear();
        pin_vip_temp["current"].clear();
        setOldVipPinNumber("");
        setTempPinNumber("");
        setOldVipPinNumberFocused(0);
        setTempPinNumberFocused(0);
      }
    } else if (currentPinRef === 2) {
      if (key !== "{bksp}") {
        currentIndex = tempPinNumberFocused;
        // pin_vip_temp["current"]["elements"][currentIndex].focus();
        keyPressed = pin_vip_temp["current"]["elements"][currentIndex].state.value;
        pin_vip_2["current"]["elements"][currentIndex].state.value = key.slice(-1);
        pin_vip_temp["current"]["elements"][currentIndex].state.value = keyPressed;
        pinStr = key;
        setTempPinNumber(pinStr);
        if (currentIndex !== 3) {
          currentIndex++;
          setTempPinNumberFocused(currentIndex);
        } 
        else if(pinStr.length < 4) {
          setVipPinNumber("");
          setTempPinNumber("");
          setTempPinNumberFocused(0);
          setShowVipPinEntry(true);
        }
        else {
          setVipPinNumber(pinStr);
          setTempPinNumber("");
          pin_vip_temp["current"].clear();
          setTempPinNumberFocused(0);
          setShowVipPinEntry(false);
          setTimeout(function () {
            setAllowPinClick(true);
          }, 1000);
        }
      } else {
        if (!stringIsNullOrEmpty(pin_vip_2["current"]))
          pin_vip_2["current"].clear();
        pin_vip_temp["current"].clear();
        setVipPinNumber("");
        setTempPinNumber("");
        setVipPinNumberFocused(0);
        setTempPinNumberFocused(0);
      }
    } else {
      if (key !== "{bksp}") {
        currentIndex = tempPinNumberFocused;
        // pin_vip_temp["current"]["elements"][currentIndex].focus();
        keyPressed = pin_vip_temp["current"]["elements"][currentIndex].state.value;
        pin_vip_3["current"]["elements"][currentIndex].state.value = key.slice(-1);
        pin_vip_temp["current"]["elements"][currentIndex].state.value = keyPressed;
        pinStr = key;
        setTempPinNumber(pinStr);
        if (currentIndex !== 3) {
          currentIndex++;
          setTempPinNumberFocused(currentIndex);
        } 
        else if(pinStr.length < 4) {
          setVipPinNumberConfirm("");
          setTempPinNumber("");
          setTempPinNumberFocused(0);
          setShowVipPinEntry(true);
        }
        else {
          setVipPinNumberConfirm(pinStr);
          setTempPinNumber("");
          pin_vip_temp["current"].clear();
          setTempPinNumberFocused(0);
          setShowVipPinEntry(false);
          setTimeout(function () {
            setAllowPinClick(true);
          }, 1000);
        }
      } else {
        if (!stringIsNullOrEmpty(pin_vip_3["current"]))
        pin_vip_3["current"].clear();
        pin_vip_temp["current"].clear();
        setVipPinNumberConfirm("");
        setTempPinNumber("");
        setVipPinNumberConfirmFocused(0);
        setTempPinNumberFocused(0);
      }
    }
  }

  const validatePhoneNumber = (e) => {
    var regexp = /^\d+$/;
    const { value } = e.target;

    if (e.nativeEvent.data === null || regexp.test(e.nativeEvent.data)) {
      // setFormattedPhone(e.target.value);

      if (value.startsWith(selectedPhonePrefix)) {
        const repValue = value.replace(selectedPhonePrefix, ""); //replace to space
        const newValue = repValue.replace(/ /g, ""); //replace white space
        setshowFormattedPhone(newValue);

        setFormattedPhone(selectedPhonePrefix + newValue); //177277883
      }
    }
  };

  
    /// <summary>
    /// Author:
    /// </summary>
    async function checkENB(data) {
        if (!data.isEmailVerify || stringIsNullOrEmpty(data["birthDateString"])) {
           
            setBirthModal(true);
        }
    }

    /// <summary>
    /// Author:
    /// </summary>
    const confirmMessageClick = async () => {
         var a = startDate.toString();
        let parts = a.split(" ");
        let months = {
            Jan: "01",
            Feb: "02",
            Mar: "03",
            Apr: "04",
            May: "05",
            Jun: "06",
            Jul: "07",
            Aug: "08",
            Sep: "09",
            Oct: "10",
            Nov: "11",
            Dec: "12"
        };
        let date = (parts[2] + "-" + months[parts[1]] + "-" + parts[3]);
        setBirthdayString(date);
        if (stringIsNullOrEmpty(enteredCodeEmail) || stringIsNullOrEmpty(formattedEmail)) {
            let success = false
            let message = 'PLS_VERIFIFY_THE_CODE_FIRST'
            _dispatch(showResponseMessage(success, message));
        } else {
            setConfirmMessage(true);

        }
    }

    const closeForm = async () => {
        setConfirmMessage(false);
        setSentCodeEmail(true);
    }
    /// <summary>
    /// Author: 
    /// </summary>
    const verifyEmail = async () => {
        let returnBool = [];
        await ApiEngine.post(ApiUrl._API_VERIFY_EMAIL, createMultiPartFormBody({ verificationCode: enteredCodeEmail }))
            .then(async (response) => {
                if (response[ApiKey._API_SUCCESS_KEY]) {
                    returnBool = [
                        {
                            success: response[ApiKey._API_SUCCESS_KEY],
                            message: response[ApiKey._API_MESSAGE_KEY]
                        }]
                } else {
                    returnBool = [
                        {
                            success: response[ApiKey._API_SUCCESS_KEY],
                            message: response[ApiKey._API_MESSAGE_KEY]
                        }]
                }
            })
        return returnBool;
    }

    const submitForm = async () => {
        let success = '';
        let message = '';
        if (countdownPeriodEmail > 0) {
            let verifyIsSuccess = await verifyEmail();

            verifyIsSuccess.map((option) => {
                success = option["success"]
                message = option["message"]
            });

            if (success) {
                var event = new Date(startDate);
                let date = JSON.stringify(event);
                date = date.slice(1, 11);
                let params = {
                    "email": formattedEmail,
                    "birthDate": birthdayString
                }
                //if (!stringIsNullOrEmpty(memberId)) {
                //    params["id"] = memberId;
                //}
                let formBody = createFormBody(params);
                _dispatch(setBusy());

                fetch(ApiUrl._API_UPDATE_MEMBER_EMAIL_AND_BIRTH_DATE, {
                    method: ApiKey._API_POST,
                    headers: {
                        'Content-Type': ApiKey._API_FORM_URLENCODED,
                        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
                    },
                    body: formBody
                }).then((response) => response.json()).then((responseJson) => {
                    _dispatch(setIdle());
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        setBirthModal(false);
                        setConfirmMessage(false);
                        init();
                    }
                    _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));

                });
            } else {
                _dispatch(showResponseMessage(success, message));

            }
        } else {
            success = false
            message = 'Verification Code Expire Already!'
            _dispatch(showResponseMessage(success, message));
        }
}
  return (
    <div id="settings">
      <section className="section-padding" id="settings-section">
        <div className="container">
          <div className="row">
            <div className="col-3" id="account-menu">
              <Sidebar activePage={"SETTINGS"} />
            </div>
            <div className="col-9">
              <ContentHeader title={t("SETTINGS")} />
              <hr className="right-title-hr" />

              {settingList &&
                settingList.length &&
                map(settingList, (item, index) => {
                  return <MenuRowItem key={"settings_" + index} item={item} />;
                })}
              {/*<div className="setting-bottom mt-4">
                <div className="text-white">
                  <h6 className="">{t("THEME")}</h6>
                </div>
                <div>
                  <label className="switch switch-blue">
                    <input
                      type="checkbox"
                      className="switch-input"
                      defaultChecked={_userData.selectedTheme === 1}
                      onChange={(e) => handleToggleTheme(e.target.checked)}
                    />
                    <span className="font-11 am-label">AM</span>
                    <span className="am-icon">
                      <img
                        src={require("../../assets/img/am.svg")}
                        className="img-responsive"
                        alt="am"
                      />
                    </span>
                    <span className="pm-icon">
                      <img
                        src={require("../../assets/img/pm.svg")}
                        className="img-responsive"
                        alt="pm"
                      />
                    </span>
                    <span className="font-11 pm-label">PM</span>
                    <span
                      className="switch-label"
                      data-on=""
                      data-off=""
                    ></span>
                    <span className="switch-handle"></span>
                  </label>
                </div>
              </div>*/}
            </div>
          </div>
        </div>
      </section>

      {/*General Information Modal*/}
      <Modal
        isOpen={showGeneralInfoModal}
        toggle={() => setShowGeneralInfoModal(!showGeneralInfoModal)}
        wrapClassName="settings-modal"
        centered
      >
        <ModalHeader>
          <div className="modal-header-wrapper">
            <h3>{t("GENERAL_INFO")}</h3>
            <button
              type="button"
              className="modal-X-btn"
              onClick={() => setShowGeneralInfoModal(!showGeneralInfoModal)}
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span>&times;</span>
            </button>
          </div>
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleProfileSubmit(submitProfileForm)}>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <input
                    type="text"
                    name="profileFullName"
                    className="register-first-name custom-input-style-1"
                    placeholder={t("FULLNAME")}
                    autoComplete="off"
                    autoFocus={true}
                    readOnly={member && member.userFullName ? true : false}
                    defaultValue={member ? member.userFullName : ""}
                    ref={profileRegister({
                      // required: true,
                      required: "FIELD_REQUIRED",
                      // pattern: {
                      //   value: /^[a-z A-Z]*$/i,
                      //   message: "PLEASE_REMOVE_NUMBER_AND_SPECIAL_CHARACTERS",
                      // },
                    })}
                    onChange={(e) => setMemberFullName(e.target.value)}
                    errors={profileErrors}
                  />
                  <div className="invalid-feedback">
                    {profileErrors.profileFullName
                      ? t(profileErrors.profileFullName.message)
                      : ""}
                  </div>
                </div>
                <div className="col-12">
                  <div className="register-phone-number">
                    <select
                      className="phone-number-prefix custom-input-style-1"
                      onChange={(e) => setSelectedPhonePrefix(e.target.value)}
                      disabled={isPhoneVerified ? true : false}
                      value={selectedPhonePrefix}
                    >
                      {PhoneNumberPrefix.map((prefix, index) => {
                        return (
                          <option
                            key={index}
                            className={"phone-prefix-" + (index + 1)}
                            value={prefix.value}
                          >
                            {prefix.label}
                          </option>
                        );
                      })}
                    </select>
                    <input
                      name="profilePhoneNumber"
                      className="register-phone-number custom-input-style-1"
                      placeholder={t("PHONE_NUMBER")}
                      autoComplete="off"
                      readOnly={isPhoneVerified}
                      // defaultValue={
                      //   member && !stringIsNullOrEmpty(member.phoneNumber)
                      //     ? member.phoneNumber.substring(
                      //         member.phoneNumber.indexOf(selectedPhonePrefix) +
                      //           2,
                      //         member.phoneNumber.length
                      //       )
                      //     : ""
                      // }
                      maxLength={20}
                      // value={formattedPhone.substring(
                      //   formattedPhone.indexOf(selectedPhonePrefix) + 2,
                      //   formattedPhone.length
                      // )}
                      defaultValue={`${showFormattedPhone}`}
                      ref={profileRegister({
                        // required: true,
                        required: "FIELD_REQUIRED",
                        minLength: {
                          value: 8,
                          message: t("MIN_CHARACTERS", { length: 8 }),
                        },
                        pattern: {
                          value: /^[0-9]+$/g,
                          message:
                            t("MIN_CHARACTERS", { length: 8 }) +
                            " " +
                            t("AND") +
                            " " +
                            t("ONLY_NUMBERS"),
                        },
                      })}
                      errors={profileErrors}
                      onChange={(e) => {
                        setshowFormattedPhone(e.target.value);
                      }}
                      onInput={(e) => validatePhoneNumber(e)}
                    />
                    {!isPhoneVerified ? (
                      <button
                        type="button"
                        className="send-tac-btn"
                        onClick={() => sendVerificationCode()}
                        disabled={
                          // formattedPhone.length < 11 ||
                          formattedPhone.length < 8 ||
                          (sentCode && countdownPeriod > 0)
                        }
                      >
                        {sentCode
                          ? t("VERIFICATION_CODE_SENT")
                          : t("SEND_VERIFICATION_CODE")}
                      </button>
                    ) : (
                      <div className="badge bg-gradient-green badge-verified settings-phone-verified">
                        <i className="fa fa-check"></i>
                      </div>
                    )}
                  </div>
                  <div
                    className={
                      "invalid-feedback" +
                      (phoneNumberError == true ? " phonenumber-error" : "")
                    }
                  >
                    {profileErrors.profilePhoneNumber
                      ? t(profileErrors.profilePhoneNumber.message)
                      : phoneNumberError == true
                      ? t("NUMBER_NOT_AVAILABLE")
                      : ""}
                  </div>
                </div>
                <div className="col-12">
                  {sentCode && (
                    <div className="register-tac-wrapper">
                      <input
                        type="text"
                        name="profileVerifyCode"
                        className="register-tac custom-input-style-1"
                        placeholder={t("ENTER_VERIFICATION_CODE")}
                        autoComplete="off"
                        ref={profileRegister({
                          // required: true,
                          required: "FIELD_REQUIRED",
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "INVALID_VERIFICATION_CODE",
                          },
                        })}
                        errors={profileErrors}
                        onChange={(e) => setEnteredCode(e.target.value)}
                      />

                      {sentCode && countdownPeriod != 0 && (
                        <p className="tac-countdown">
                          {t("VERIFICATION_CODE_SENT")} {t("VALID_FOR")}{" "}
                          {countdownPeriod}s
                        </p>
                      )}

                      <div className={"invalid-feedback"}></div>
                    </div>
                  )}
                </div>
                <div className="col-12" style={{ height:"55px" }}>
                <input
                      name="birthday"
                      className="register-phone-number custom-input-style-1"
                      placeholder={t("BIRTHDATE")}
                      autoComplete="off"
                      readOnly={true}
                      maxLength={20}
                      defaultValue={`${birthdayValue}`}
                      
                    />
                </div>
                
                <div className="col-12">
                  <input
                    name="profileEmail"
                    className="register-phone-number custom-input-style-1"
                    placeholder={t("EMAIL")}
                    autoComplete="off"
                    readOnly={isEmailVerified}
                    maxLength={20}
                    defaultValue={`${formattedEmail}`}
                    ref={register({
                        required: "PLEASE_ENTER_EMAIL",
                        pattern: {
                            value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                            message: "INVALID_EMAIL"
                        }
                    })}
                    errors={profileErrors}
                    onChange={(e) => {
                      setFormattedEmail(e.target.value);
                    }}
                    onInput={(e) => validatePhoneNumber(e)}
                  />
                  {!isEmailVerified ? (
                    <button
                      type="button"
                      className="send-tac-email-btn-1"
                      onClick={() => sendVerificationCodeEmail()}
                      disabled={
                        formattedEmail.length < 1 ||
                        (sentCodeEmail && countdownPeriodEmail > 0)
                      }
                    >
                      {sentCodeEmail
                        ? t("VERIFICATION_CODE_SENT")
                        : t("SEND_VERIFICATION_CODE")}
                    </button>
                  ) : (
                    <div className="badge bg-gradient-green badge-verified settings-email-verified-1">
                      <i className="fa fa-check"></i>
                    </div>
                  )}
                </div>
                {/* <div className="col-12">
                  <input
                    type="text"
                    name="profileEmail"
                    className="register-first-name custom-input-style-1"
                    placeholder={t("EMAIL") + " (" + t("OPTIONAL") + ")"}
                    autoComplete="off"
                    ref={profileRegister({
                      pattern: {
                        value:
                          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                        message: "INVALID_EMAIL",
                      },
                    })}
                    value={memberEmail}
                    onChange={(e) => setMemberEmail(e.target.value)}
                    errors={profileErrors}
                  />
                  <div className="invalid-feedback">
                    {profileErrors.profileEmail
                      ? t(profileErrors.profileEmail.message)
                      : ""}
                  </div>
                </div> */}
                {(memberFullName == "" || 
                !isPhoneVerified ||
                  formattedPhone == "") && (
                    <div className="col-12">
                      <button
                        type="submit"
                        className="custom-btn-style-1"
                        disabled={
                          stringIsNullOrEmpty(memberFullName) ||
                          // formattedPhone.length < 11 ||
                          formattedPhone.length < 8 ||
                          (!isPhoneVerified && stringIsNullOrEmpty(enteredCode))
                        }
                      >
                        {t("SAVE")}
                      </button>
                    </div>
                  )}
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>

      {/*Change Password Modal*/}
      <Modal
        isOpen={showChangePasswordModal}
        toggle={() => setShowChangePasswordModal(!showChangePasswordModal)}
        wrapClassName="settings-modal"
        centered
      >
        <ModalHeader>
          <div className="modal-header-wrapper">
            <h3>{t("CHANGE_PASSWORD")}</h3>
            <button
              type="button"
              className="modal-X-btn"
              onClick={() =>
                setShowChangePasswordModal(!showChangePasswordModal)
              }
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span>&times;</span>
            </button>
          </div>
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleResetPasswordSubmit(submitResetPasswordForm)}>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <input
                    type="password"
                    name="currentPassword"
                    className="custom-input-style-1"
                    placeholder={t("OLD_PASSWORD")}
                    autoComplete="off"
                    ref={resetPasswordRegister({
                      required: "REQUIRED_OLD_PASSWORD",
                    })}
                    value={resetPasswordWatch("currentPassword") || ""}
                    onInput={(e) => {
                      if (e.target.value === resetPasswordWatch("password")) {
                        setResetPasswordError("password", {
                          message: "NEW_PASSWORD_SAME_AS_OLD_PASSWORD",
                        });
                      } else {
                        clearResetPasswordErrors("password");
                      }
                      setResetPasswordValue("currentPassword", e.target.value);
                    }}
                    errors={resetPasswordErrors}
                  />
                  <div className="invalid-feedback">
                    {resetPasswordErrors.currentPassword
                      ? t(resetPasswordErrors.currentPassword.message)
                      : ""}
                  </div>
                </div>

                <div className="col-12">
                  <input
                    type="password"
                    name="password"
                    className="custom-input-style-1"
                    placeholder={t("NEW_PASSWORD")}
                    autoComplete="off"
                    ref={resetPasswordRegister({
                      required: "REQUIRED_NEW_PASSWORD",
                    })}
                    value={resetPasswordWatch("password") || ""}
                    onInput={(e) => {
                      if (
                        e.target.value === resetPasswordWatch("currentPassword")
                      ) {
                        setResetPasswordError("password", {
                          message: "NEW_PASSWORD_SAME_AS_OLD_PASSWORD",
                        });
                      } else {
                        clearResetPasswordErrors("password");
                      }
                      setResetPasswordValue("password", e.target.value);
                    }}
                    errors={resetPasswordErrors}
                  />
                  <div className="invalid-feedback">
                    {resetPasswordErrors.password
                      ? t(resetPasswordErrors.password.message)
                      : ""}
                  </div>
                </div>

                <div className="col-12">
                  <input
                    type="password"
                    name="confirmPassword"
                    className="custom-input-style-1"
                    placeholder={t("CONFIRM_NEW_PASSWORD")}
                    autoComplete="off"
                    ref={resetPasswordRegister({
                      required: "REQUIRED_CONFIRM_NEW_PASSWORD",
                      validate: (value) =>
                        value == resetPasswordWatch("password") ||
                        "PASSWORD_MISMATCH",
                    })}
                    value={resetPasswordWatch("confirmPassword") || ""}
                    onInput={(e) => {
                      if (e.target.value !== resetPasswordWatch("password")) {
                        setResetPasswordError("password", {
                          message: "PASSWORD_MISMATCH",
                        });
                      } else {
                        clearResetPasswordErrors("password");
                      }
                      setResetPasswordValue("confirmPassword", e.target.value);
                    }}
                    errors={resetPasswordErrors}
                  />
                  <div className="invalid-feedback">
                    {resetPasswordErrors.confirmPassword
                      ? t(resetPasswordErrors.confirmPassword.message)
                      : ""}
                  </div>
                </div>
                <div className="col-12">
                  <button
                    className="custom-btn-style-1"
                    type="submit"
                    disabled={Object.keys(resetPasswordErrors).length > 0}
                  >
                    {t("SAVE")}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>

      {/*Change PIN Modal*/}
      <Modal
        isOpen={showChangePinModal}
        toggle={() => setShowChangePinModal(!showChangePinModal)}
        wrapClassName="settings-modal"
        centered
      >
        <ModalHeader>
          <div className="modal-header-wrapper">
            <h3>{t("CHANGE_PIN")}</h3>
            <button
              type="button"
              className="modal-X-btn"
              onClick={() => setShowChangePinModal(!showChangePinModal)}
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span>&times;</span>
            </button>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="pl-3 pr-3">
            <Nav id="my-tabs">
              {tabPinHeadings &&
                tabPinHeadings.length &&
                tabPinHeadings
                  .filter((x) => (!isShareholder ? x.tab_id === 0 : x))
                  .map((item, index) => {
                    return (
                      <NavItem
                        key={index}
                        onClick={() => toggleTab(item.tab_id)}
                      >
                        <NavLink
                          className={
                            "font-12 " +
                            classnames({ active: activeTab === item.tab_id })
                          }
                        >
                          <span>{t(item.title)}</span>
                        </NavLink>
                      </NavItem>
                    );
                  })}
            </Nav>
            <TabContent activeTab={activeTab}>
              {tabPinHeadings &&
                tabPinHeadings.length &&
                map(tabPinHeadings, (item, index) => {
                  return (
                    <TabPane key={index} tabId={item.tab_id}>
                      {item.tab_id == 0 && (
                        <form
                          className="settings-pin-form"
                          onSubmit={handlePinSubmit(changePin)}
                        >
                          {checkPinExist && (
                            <div
                              className="pin-row"
                              onClick={() => {
                                if (!allowPinClick) return false;
                                setCurrentPinRef(1);
                                setShowPinEntry(true);
                                setTempPinNumberFocused(0);
                              }}
                            >
                              <label className="change-pin-label">
                                <b>{t("OLD_PIN")}</b>
                              </label>
                              <PinInput
                                ref={pin_1}
                                length={4}
                                style={{
                                  paddingLeft: "10px",
                                  marginBottom: "20px",
                                }}
                                initialValue=""
                                secret
                                type="numeric"
                                disabled={true}
                                onChange={(value, index) => {
                                  setOldPinNumberFocused(index);
                                }}
                                inputMode="numeric"
                                inputStyle={{ border: "0", margin: "0px 10px" }}
                                regexCriteria={/^[ 0-9_@./#&+-]*$/}
                                focus={showPinEntry}
                              />
                            </div>
                          )}
                          <div
                            onClick={() => {
                              if (!allowPinClick) return false;
                              setCurrentPinRef(2);
                              setShowPinEntry(true);
                              setTempPinNumberFocused(0);
                            }}
                          >
                            <label className="change-pin-label">
                              <b>{checkPinExist ? t("NEW_PIN") : t("PIN")}</b>
                            </label>
                            <PinInput
                              ref={pin_2}
                              length={4}
                              style={{
                                paddingLeft: "10px",
                                marginBottom: "20px",
                              }}
                              initialValue=""
                              secret
                              type="numeric"
                              disabled={true}
                              onChange={(value, index) => {
                                setPinNumberFocused(index);
                              }}
                              inputMode="numeric"
                              inputStyle={{ border: "0", margin: "0px 10px" }}
                              regexCriteria={/^[ 0-9_@./#&+-]*$/}
                              focus={showPinEntry}
                            />
                          </div>
                          <div
                            onClick={() => {
                              if (!allowPinClick) return false;
                              setCurrentPinRef(3);
                              setShowPinEntry(true);
                              setTempPinNumberFocused(0);
                            }}
                          >
                            <label className="change-pin-label">
                              <b>{t("CONFIRM_PIN")}</b>
                            </label>
                            <PinInput
                              ref={pin_3}
                              style={{
                                marginBottom: "45px",
                                paddingLeft: "10px",
                              }}
                              length={4}
                              initialValue=""
                              secret
                              type="numeric"
                              disabled={true}
                              onChange={(value, index) => {
                                setPinNumberConfirmFocused(index);
                              }}
                              inputMode="numeric"
                              inputStyle={{ border: "0", margin: "0px 10px" }}
                              regexCriteria={/^[ 0-9_@./#&+-]*$/}
                              focus={showPinEntry}
                            />
                          </div>
                          <button className="custom-btn-style-1" type="submit">
                            {t("SAVE")}
                          </button>
                        </form>
                      )}
                      {item.tab_id == 1 && (
                        <form
                          className="settings-pin-form"
                          onSubmit={handlePinSubmit(changeShareholderPin)}
                        >
                          {isShareholder && checkVipPinExist && (
                            <div
                              onClick={() => {
                                if (!allowPinClick) return false;
                                setCurrentPinRef(1);
                                setShowVipPinEntry(true);
                                setTempPinNumberFocused(0);
                              }}
                            >
                              <label className="change-pin-label">
                                <b>{t("OLD_SHAREHOLDER_PIN")}</b>
                              </label>
                              <PinInput
                                ref={pin_vip_1}
                                length={4}
                                style={{
                                  paddingLeft: "10px",
                                  marginBottom: "20px",
                                }}
                                initialValue=""
                                secret
                                type="numeric"
                                disabled={true}
                                onChange={(value, index) => {
                                  setOldVipPinNumberFocused(index);
                                }}
                                inputMode="numeric"
                                inputStyle={{ border: "0", margin: "0px 10px" }}
                                regexCriteria={/^[ 0-9_@./#&+-]*$/}
                              />
                            </div>
                          )}

                          <div
                            onClick={() => {
                              if (!allowPinClick) return false;
                              setCurrentPinRef(2);
                              setShowVipPinEntry(true);
                              setTempPinNumberFocused(0);
                            }}
                          >
                            {/* <label className="change-pin-label">
                              <b>{t("NEW_SHAREHOLDER_PIN")}</b>
                            </label> */}

                            <label className="change-pin-label">
                              <b>
                                {checkVipPinExist
                                  ? t("NEW_SHAREHOLDER_PIN")
                                  : t("SHAREHOLDER_PIN")}
                              </b>
                            </label>
                            <PinInput
                              ref={pin_vip_2}
                              length={4}
                              style={{
                                paddingLeft: "10px",
                                marginBottom: "20px",
                              }}
                              initialValue=""
                              secret
                              type="numeric"
                              disabled={true}
                              onChange={(value, index) => {
                                setVipPinNumberFocused(index);
                              }}
                              inputMode="numeric"
                              inputStyle={{ border: "0", margin: "0px 10px" }}
                              regexCriteria={/^[ 0-9_@./#&+-]*$/}
                            />
                          </div>
                          <div
                            onClick={() => {
                              if (!allowPinClick) return false;
                              setCurrentPinRef(3);
                              setShowVipPinEntry(true);
                              setTempPinNumberFocused(0);
                            }}
                          >
                            <label className="change-pin-label">
                              <b>{t("CONFIRM_SHAREHOLDER_PIN")}</b>
                            </label>
                            <PinInput
                              ref={pin_vip_3}
                              style={{
                                marginBottom: "45px",
                                paddingLeft: "10px",
                              }}
                              length={4}
                              initialValue=""
                              secret
                              type="numeric"
                              disabled={true}
                              onChange={(value, index) => {
                                setVipPinNumberConfirmFocused(index);
                              }}
                              inputMode="numeric"
                              inputStyle={{ border: "0", margin: "0px 10px" }}
                              regexCriteria={/^[ 0-9_@./#&+-]*$/}
                            />
                          </div>
                          <button className="custom-btn-style-1" type="submit">
                            {t("SAVE")}
                          </button>
                        </form>
                      )}
                    </TabPane>
                  );
                })}
            </TabContent>
          </div>
        </ModalBody>
      </Modal>

      {/*PIN Keyboard Modals*/}
      <Modal
        centered
        fade={false}
        contentClassName="modal-brand modal-bottom settings-pin"
        isOpen={showPinEntry}
        toggle={() => {
          setAllowPinClick(true);
          setShowPinEntry(false);
        }}
      >
        <ModalHeader
          className="modal-position"
          toggle={() => {
            setAllowPinClick(true);
            setShowPinEntry(false);
          }}
        ></ModalHeader>
        <div className="changePin-modal-header text-gold font-semi font-20">
          {currentPinRef === 1
            ? t("OLD_PIN")
            : currentPinRef === 2
            ? t("NEW_PIN")
            : t("CONFIRM_PIN")}
        </div>

        <input type="password" 
          style={{ 
            height: 0,
            width: 0,
            position: 'absolute',
            zIndex: -1
          }}
        />
        <ModalBody style={{ minHeight: "unset" }}>
          <div
            style={{ textAlign: "center", paddingBottom: "30px" }}
            onKeyDown={(e) => {
              if (e.key === "Backspace") {
                onKeyPress("{bksp}");
              }
            }}
          >
            <PinInput
              ref={pin_temp}
              length={4}
              initialValue=""
              secret
              autoSelect={false}
              type="numeric"
              onChange={(value, index) => {
                onKeyPress(value);
                // handleKeyDown(value, index);
                setTempPinNumberFocused(index);
              }}
              inputMode="numeric"
              inputStyle={{ border: "0", margin: "0px 10px" }}
              regexCriteria={/^[ 0-9_@./#&+-]*$/}
              focus={showPinEntry}
            />
          </div>
        </ModalBody>
      </Modal>
      <Modal
        centered
        fade={false}
        contentClassName="modal-brand modal-bottom settings-pin"
        isOpen={showVipPinEntry}
        toggle={() => {
          setAllowPinClick(true);
          setShowVipPinEntry(false);
        }}
      >
        <ModalHeader
          className="modal-position"
          toggle={() => {
            setAllowPinClick(true);
            setShowVipPinEntry(false);
          }}
        ></ModalHeader>
        <div className="changePin-modal-header text-gold font-semi font-20">
          {currentPinRef === 1
            ? t("OLD_SHAREHOLDER_PIN")
            : currentPinRef === 2
            ? t("NEW_SHAREHOLDER_PIN")
            : t("CONFIRM_SHAREHOLDER_PIN")}
        </div>

        <input type="password" 
          style={{ 
            height: 0,
            width: 0,
            position: 'absolute',
            zIndex: -1
          }}
        />

        <ModalBody style={{ minHeight: "unset" }}>
          <div style={{ textAlign: "center", paddingBottom: "30px" }}
            onKeyDown={(e) => {
              if (e.key === "Backspace") {
                onVipKeyPress("{bksp}");
              }
            }}
          >
            <PinInput
              ref={pin_vip_temp}
              length={4}
              initialValue=""
              secret
              autoSelect={false}
              type="numeric"
              onChange={(value, index) => {
                onVipKeyPress(value);
                setTempPinNumberFocused(index);
              }}
              inputMode="numeric"
              inputStyle={{ border: "0", margin: "0px 10px" }}
              regexCriteria={/^[ 0-9_@./#&+-]*$/}
              focus={showPinEntry}
            />
          </div>
        </ModalBody>
      </Modal>
      <Modal
              isOpen={birthModal}
              toggle={() => setBirthModal(false)}
              wrapClassName="settings-modal"
              centered
          >
              <ModalHeader>
                  <div className="modal-header-wrapper">
                      <h3>{t("UPDATE_EMAIL_AND_BIRTH_DATE")}</h3>
                      <div className="modal-close-btn" onClick={() => setBirthModal(false)}><span>&times;</span></div>
                  </div>
              </ModalHeader>
              <ModalBody>
              <div className="col-12">
                      <label style={{ color: "white" }}>{t("BIRTHDATE")}</label>
              <br />
                      <DatePicker
                          className="register-phone-number custom-input-style-2"
                          placeholderText={t("ENTER_BIRTH_DATE")}
                          selected={startDate}
                          maxDate={moment().subtract(18, 'years')._d}
                          onChange={date => setStartDate(date)}
                          dateFormat="dd-MM-yyyy"
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          required
                      />
                </div>
                  <br />
                <div className="birthdate-datepicker col-12">
                <label className="label-text">{t("EMAIL")}</label>
                <input
                      name="profileEmail"
                      className="register-phone-number custom-input-style-1"
                      placeholder={t("ENTER_EMAIL")}
                      autoComplete="off"
                      maxLength={100}
                      value={formattedEmail }
                      ref={register({
                          required: "PLEASE_ENTER_EMAIL",
                          pattern: {
                              value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                              message: "INVALID_EMAIL"
                          }
                      })}
                      errors={profileErrors}
                      onChange={(e) => {
                        setFormattedEmail(e.target.value);
                      }}
                    />
                    {!isEmailVerified ? (
                      <button
                        type="button"
                        className="send-tac-email-btn"
                        onClick={() => sendVerificationCodeEmail()}
                        disabled={

                      formattedEmail.length < 1 ||
                    (sentCodeEmail && countdownPeriodEmail > 0)
                  }
                >
                  {sentCodeEmail
                    ? t("VERIFICATION_CODE_SENT")
                    : t("SEND_VERIFICATION_CODE")}
                </button>
              ) : (
                <div className="badge bg-gradient-green badge-verified settings-email-verified">
                  <i className="fa fa-check"></i>
                </div>
              )}
          </div>
          <br/>
            <div className="col-12">
            {sentCodeEmail && (
              <div className="register-tac-wrapper">
              <label className="label-text">{t("TAC Code")}</label>
                <input
                  type="text"
                  name="profileVerifyCodeEmail"
                  className="register-tac custom-input-style-1"
                  placeholder={t("ENTER_VERIFICATION_CODE_EMAIL")}
                  autoComplete="off"
                  ref={profileRegister({
                    // required: true,
                    required: "FIELD_REQUIRED",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "INVALID_VERIFICATION_CODE",
                    },
                  })}
                  errors={profileErrors}
                  onChange={(e) => setEnteredCodeEmail(e.target.value)}
                />

                {sentCodeEmail && countdownPeriodEmail != 0 && (
                  <p className="tac-countdown">
                    {t("VERIFICATION_CODE_SENT")} {t("VALID_FOR")}{" "}
                    {countdownPeriodEmail}s
                  </p>
                )}
                <div className={"invalid-feedback"}></div>
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-12">
            <button
              type="submit"
              className="custom-btn-style-1"
              disabled={
                stringIsNullOrEmpty(formattedEmail) || stringIsNullOrEmpty(enteredCodeEmail) || enteredCodeEmail.length < 6 || enteredCodeEmail.length > 6
              }
              onClick={() => confirmMessageClick()}
            >
              {t("SAVE")}
            </button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={confirmMessage}
        wrapClassName="settings-modal"
        centered
      >
        <ModalHeader>
            <div className="modal-header-wrapper">
                <h3>{t("CONFIRM_MESSAGE")}</h3>
            </div>
        </ModalHeader>
        <ModalBody>
            <div style={{ color: "white", textAlign: "center" }}>
                {t("ARE_YOU_CONFIRM_TO_SELECT_AS_YOUR_BIRTHDAY", { value: birthdayString })} 
                <br />
                {t("CANNOT_BE_CHANGED_AFTER_CONFIRMATION")}
            </div>
        </ModalBody>
        <ModalFooter>
            <div>
                <button type="submit" className="btn btn-block btn-brand-gradient" onClick={() => closeForm()}>{t("CLOSE")} </button>
            </div>
            <div>
                <button type="submit" className="btn btn-block btn-brand-gradient" onClick={() => submitForm()}>{t("SUBMIT")} </button>
            </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default Settings;

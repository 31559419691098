import React, { useState, useEffect } from "react";
//import "react-simple-keyboard/build/css/index.css";
import Sidebar from "components/sidebar";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine";
import { ApiKey, ApiUrl } from "../../util/Constant";
import ContentHeader from "components/content/header";

const Withdrawal = (props) => {
  const { t } = useTranslation();
  const [transactionData, setTransactionData] = useState([]);
  const [notActiveRollOverCount, setNotActiveRollOverCount] = useState(0);

  useEffect(() => {
    getBonusData();
  }, []);

  async function getBonusData() {
    var responseJson = await ApiEngine.get(ApiUrl._API_GET_ROLLOVER_RECORD);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      setTransactionData(data);

      let count = 0;
      data.forEach(item => {
        if (item.status !== "Active") {
          count++;
        }
      })
      setNotActiveRollOverCount(count);
    }
  }

  return (
    <>
      <section className="section-padding" id="rollover-statement-section">
        <div className="container">
          <div className="row">
            <div className="col-3" id="account-menu">
              <Sidebar activePage={"REPORTS"} />
            </div>
            <div className="col-9">
              <ContentHeader enableBackButton title={t("ROLLOVER_STATEMENT")} />
              <hr className="right-title-hr" />
              {transactionData && (transactionData.length === 0 || transactionData.length === notActiveRollOverCount)
                  ? <div className="text-white">{t("NO_DATA_FOUND")}</div>:
                  transactionData.map((item, i) => {
                    if (item["status"] === "Active") {
                      var statusHtml = "";
                      statusHtml =
                        item["isLatestRecord"] == 1 ? (
                          <span
                            className={
                              item["RolloverProgress"] == item["RolloverTarget"]
                                ? "badge badge-secondary badge-green"
                                : "badge badge-secondary badge-danger"
                            }
                          >
                            {t(item["status"])}
                          </span>
                        ) : (
                          <span className="badge badge-secondary badge-warning">
                            {t(item["status"])}
                          </span>
                        );
                      return (
                        <div
                          key={"rollover_statement_" + i}
                          className="template-box"
                          id="rollover-statement-box"
                        >
                          <div className="text-white rollover-box mb-2">
                            <div>
                              <span className="font-14">{item["date"]}</span>
                            </div>
                            <div>{statusHtml}</div>
                          </div>
                          <div className="text-white rollover-box">
                            <div>
                              <span className="font-20 font-semi">{t("AMOUNT")}</span>
                            </div>
                            <div>
                              <span className="font-20 font-semi">
                                {item["amount"]}
                              </span>
                            </div>
                          </div>
                          <div className="text-white rollover-box">
                            <div>
                              <span className="font-20 font-semi">
                                {t("ROLLOVER_PROGRESS")}
                              </span>
                            </div>
                            <div>
                              <span className="font-20 font-semi">
                                {item["isLatestRecord"] == 1
                                  ? item["rolloverProgress"]
                                  : item["rolloverTarget"]}{" "}
                                / {item["rolloverTarget"]}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    }
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Withdrawal;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import parse from 'react-html-parser';

const PromotionDetail = (props) => {
  return (
    <>
      <div id="promotion-detail">
        <div className="promotion-detail-wrapper">
          <div className="detail-img">
            <img src={props.promotion.desktopImage} />
          </div>
          <div className="detail-title">
            {props.promotion.title}
          </div>
          <div className="detail-content">
            {parse(props.promotion.content)}
          </div>
        </div>
      </div>
    </>
  )
}

export default PromotionDetail;
import React, { useState, useEffect } from "react";
//import "react-simple-keyboard/build/css/index.css";
import Sidebar from "components/sidebar";
import ContentHeader from "components/content/header";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { ApiKey, ApiUrl } from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine"
import { useForm, Controller } from "react-hook-form";
import StyledSelect from "../../components/custom/StyledSelect";
import { stringIsNullOrEmpty } from "../../util/Util";
import DateRangeFilter from "../../components/custom/DateRangeFilter";
import { numberWithCurrencyFormat } from "../../util/Util";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";

const LoyaltyPointsHistory = (props) => {
  const { t, i18n } = useTranslation();
  const [transactionData, setTransactionData] = useState([]);
  const { handleSubmit, register, control, watch, setValue, errors, reset } = useForm();
  const [products, setProducts] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf('week').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf('week').format('YYYY-MM-DD'));
  const [loyaltyPointsUrl, setLoyaltyPointsUrl] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [queryProduct, setQueryProduct] = useState("");
  const [searchTrigger, setSearchTrigger] = useState(Date.now());

  useEffect(() => {
    init();
    getLoyaltyPointsData(startDate, endDate);
  }, [])

  async function init() {
    var responseJson = await ApiEngine.get(`${ApiUrl._API_GET_ASSIGNED_MEMBER_PRODUCT}?ignoreAccountProductStatus=true`);

    const productList = [{
      label: t("ALL"),
      value: null
    }];

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      responseJson[ApiKey._API_DATA_KEY].products.forEach(function (product) {
        productList.push({ label: product.productName, value: product.productId });
      });
    }

    setProducts(productList);
  }

  async function getLoyaltyPointsData(startDate, endDate, selected = '') {
    var apiUrl = ApiUrl._API_GET_LOYALTY_POINTS_HISTORY + "?StartDate=" + moment(startDate).startOf('day').format('YYYY-MM-DD') + "&EndDate=" + moment(endDate).endOf('day').format('YYYY-MM-DD') + "&v=" + Date.now();

    if (!stringIsNullOrEmpty(watch()["productId"])) {
      apiUrl += "&productId=" + watch()["productId"];
    }

    if (!stringIsNullOrEmpty(selected)) {
      apiUrl += "&SelectedDate=" + moment(selected).format('YYYY-MM-DD');
    }

    setValue("productId", stringIsNullOrEmpty(queryProduct) ? null : queryProduct);
    setLoyaltyPointsUrl(apiUrl);
  }

  useEffect(() => {
    if (!stringIsNullOrEmpty(selectedDate)) {
      getLoyaltyPointsData(startDate, endDate, selectedDate)
    }
  }, [selectedDate])

  useEffect(() => {
    getLoyaltyPointsData(startDate, endDate);
  }, [searchTrigger])

  function getRecordFromDynamicLoad(data) {
    setTransactionData(data);
  }

  return (
    <>
      <section className="section-padding" id="loyalty-history-section">
        <div className="container">
          <div className="row">
            <div className="col-3" id="account-menu">
              <Sidebar activePage={"GAME_POINTS"} />
            </div>
            <div className="col-9">
              <ContentHeader enableBackButton title={t("GAME_POINTS_HISTORY")} />
              <hr className="right-title-hr" />
              <div className="template-box mt-3" id="loyal-history-box">
                <DateRangeFilter
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  onSave={(start, end) => {
                    setStartDate(moment(start));
                    setEndDate(moment(end));
                    getLoyaltyPointsData(start, end);
                    setSelectedDate("");
                  }}
                  options={true}
                />

                <div className="form-row">
                  <div className="col">
                    <label className="col-form-label">
                      <b>{t("GAME")}</b>
                    </label>
                    <div className="form-group p-0">
                      <Controller
                        control={control}
                        name="productId"
                        render={({ onChange, value }) => {
                          return (
                            <StyledSelect
                              options={products}
                              value={value}
                              onChange={(e) => {
                                onChange(e.value);
                              }}
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="thc-result-mainbox font-12">
                  <table className="table reportTable">
                    <thead>
                      <tr>
                        <th>{t("DATE")}</th>
                        <th>{t("USERNAME")}</th>
                        <th>{t("GAME")}</th>
                        <th style={{ textAlign: "right" }}>{t("VTURNOVER")}</th>
                        <th style={{ textAlign: "right" }}>{t("LOYALTY_AMOUNT")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <DynamicLoadTable
                        returnRecord={getRecordFromDynamicLoad}
                        pageSize={20}
                        apiQuery={loyaltyPointsUrl}
                        isTable={true}
                        searchEnabled={true}
                        render={(item, i) => {
                          let isGrouped =
                            moment(endDate, "YYYY-MM-DD").diff(
                              moment(startDate, "YYYY-MM-DD"),
                              "days"
                            ) >= 1 && stringIsNullOrEmpty(selectedDate);
                          return (
                            <tr key={i}>
                              <td
                                className={isGrouped ? "hoverable" : ""}
                                onClick={() => {
                                  if (isGrouped) {
                                    setSelectedDate(item["parsedTime"]);
                                  }
                                }}
                              >
                                {item["parsedTime"]}
                              </td>
                              <td>{item["username"]}</td>
                              <td>{item["remark"]}</td>
                              <td style={{ textAlign: "right" }}>
                                {numberWithCurrencyFormat(
                                  parseFloat(item["turnover"]),
                                  2
                                )}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {numberWithCurrencyFormat(
                                  parseFloat(item["loyaltyPointsAmount"]),
                                  2
                                )}
                              </td>
                            </tr>
                          );
                        }}
                      />
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan="3">
                          <b>{t("TOTAL")}</b>
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <b>
                            {numberWithCurrencyFormat(
                              parseFloat(
                                transactionData.reduce(
                                  (totalvt, data) => totalvt + data.turnover,
                                  0
                                )
                              ),
                              2
                            )}
                          </b>
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <b>
                            {numberWithCurrencyFormat(
                              parseFloat(
                                transactionData.reduce(
                                  (total, data) => total + data.loyaltyPointsAmount,
                                  0
                                )
                              ),
                              2
                            )}
                          </b>
                        </td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default LoyaltyPointsHistory;

import React from "react";
import { getValidationMessage, stringIsNullOrEmpty } from "../../util/Util";
import { useTranslation } from "react-i18next";
import { ApiKey } from "../../util/Constant";

/**
 * Author: -
 */
const Input = React.forwardRef((props, ref) => {
  const {
    name,
    containerClassName = "",
    labelClassName = "",
    inputClassName = "",
    type = "text",
    label,
    small,
    placeholder = "",
    errors,
    disabled,
    inputGroupType,
    inputGroupContent,
    ...rest
  } = props;
  const { t, i18n } = useTranslation();

  return (
    <div className={"form-group" + containerClassName}>
      {label && (
        <label className={labelClassName}>
          <b>{label}</b>
        </label>
      )}
      {inputGroupType ? (
        <div className="input-group mb-3">
          {inputGroupType == "prepend" && (
            <div className={`input-group-${inputGroupType}`}>
              <span className="input-group-text">{inputGroupContent}</span>
            </div>
          )}
          <input
            name={name}
            ref={ref}
            type={type}
            className={"form-control " + inputClassName}
            placeholder={placeholder}
            {...rest}
          />
          {inputGroupType == "append" && (
            <div className={`input-group-append`}>
              <span className="input-group-text">{inputGroupContent}</span>
            </div>
          )}
        </div>
      ) : !disabled ? (
        <input
          name={name}
          ref={ref}
          type={type}
          className={"form-control " + inputClassName}
          placeholder={placeholder}
          {...rest}
        />
      ) : (
        <div className="form-disabled">{props.value}</div>
      )}
      {errors
        ? errors[name] && (
            <div className="invalid-feedback">
              {stringIsNullOrEmpty(errors[name][ApiKey._API_MESSAGE_KEY])
                ? t(getValidationMessage(errors[name]))
                : t(errors[name][ApiKey._API_MESSAGE_KEY])}
            </div>
          )
        : ""}
      {small && <div className="small">{small}</div>}
    </div>
  );
});

export default Input;

import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ContentHeader from "components/content/header";
import moment from "moment";
import { ApiKey, ApiUrl, WebUrl, TransactionStatus } from "../../util/Constant";
import DateRangeFilter from "../../components/custom/DateRangeFilter";
import { numberWithCurrencyFormat, stringIsNullOrEmpty } from "../../util/Util";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";
import { useDispatch } from "react-redux";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import Sidebar from "components/sidebar";
import {
    Button,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap";
import Icon from "@mdi/react";
import {
    mdiCheckCircle,
    mdiCloseCircle,
    mdiDotsHorizontalCircle,
} from "@mdi/js";


const WalletHistory = () => {
    let _history = useHistory();
    const { t, i18n } = useTranslation();
    const _dispatch = useDispatch();
    const [startDate, setStartDate] = useState(
        moment().startOf("week").format("YYYY-MM-DD")
    );
    const [endDate, setEndDate] = useState(
        moment().endOf("week").format("YYYY-MM-DD")
    );
    const [selectedDate, setSelectedDate] = useState("");
    const [withdrawalUrl, setWithdrawalUrl] = useState("");

    const [currentItem, setItem] = useState([]);
    const [modal, setModal] = useState(false);

    useEffect(() => {
        getTransactionData(startDate, endDate);
    }, []);

    async function getTransactionData(start, end, selected = "") {
        let apiUrl =
            ApiUrl._API_GET_TRANSACTION_BY_MEMBER_DEPOSIT_AND_WITHDRAWAL +
            "?StartDate=" +
            moment(start).format("YYYY-MM-DD") +
            "&EndDate=" +
            moment(end).format("YYYY-MM-DD") +
            "&type=" +
            "2";

        if (!stringIsNullOrEmpty(selected)) {
            apiUrl += "&SelectedDate=" + moment(selected).format("YYYY-MM-DD");
        }
        setWithdrawalUrl(apiUrl);
    }

    useEffect(() => {
        if (!stringIsNullOrEmpty(selectedDate)) {
            getTransactionData(startDate, endDate, selectedDate);
        }
    }, [selectedDate]);

    const toggle = () => {
        setModal(!modal);
    };

    return (
        <>
            <section className="section-padding" id="game-record-section">
                <div className="container">
                    <div className="row">
                        <div className="col-3" id="account-menu">
                            <Sidebar activePage={"WALLET"} />
                        </div>
                        <div className="col-9">
                            <ContentHeader title={t("WITHDRAWAL_RECORD")} />
                            <hr className="right-title-hr" />
                            <div className="template-box mt-3" id="wallet-statement-box">
                                <DateRangeFilter
                                    startDate={startDate}
                                    endDate={endDate}
                                    setStartDate={setStartDate}
                                    setEndDate={setEndDate}
                                    onSave={(start, end) => {
                                        getTransactionData(start, end);
                                        setSelectedDate("");
                                    }}
                                    options={true}
                                />
                                <div className="card card-package">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table reportTable">
                                                <thead>
                                                    <tr>
                                                        <th>{t("DATE")}</th>
                                                        <th>{t("STATUS")}</th>
                                                        <th>{t("AMOUNT")}</th>
                                                        <th>{t("DETAIL")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <DynamicLoadTable
                                                        pageSize={20}
                                                        apiQuery={withdrawalUrl}
                                                        searchEnabled={true}
                                                        isTable={true}
                                                        render={(item, i) => {
                                                            var statusHtml = "";
                                                            switch (item["approvalStatus"]) {
                                                                case TransactionStatus._APPROVED:
                                                                    statusHtml = (
                                                                        <span style={{ color: "#03BB3A" }}>
                                                                            {t("APPROVED")}
                                                                        </span>
                                                                    );
                                                                    break;
                                                                case TransactionStatus._REJECTED:
                                                                    statusHtml = (
                                                                        <span style={{ color: "red" }}>
                                                                            {t("REJECTED")}
                                                                        </span>
                                                                    );
                                                                    break;
                                                                case TransactionStatus._OPEN:
                                                                    statusHtml = (
                                                                        <span style={{ color: "#002e6c" }}>
                                                                            {t("PROCESSING")}
                                                                        </span>
                                                                    );
                                                                break
                                                                case TransactionStatus._IN_REVIEW:
                                                                  statusHtml = (
                                                                    <span style={{ color: "#002e6c" }}>
                                                                      {t("PROCESSING")}
                                                                    </span>
                                                                  );
                                                                break;
                                                                case TransactionStatus._AUTO_APPROVED:
                                                                    statusHtml = (
                                                                        <span style={{ color: "#03BB3A" }}>
                                                                            {t("APPROVED")}
                                                                        </span>
                                                                    );
                                                                    break;
                                                                default:
                                                                    statusHtml = (
                                                                        <span style={{ color: "#002e6c" }}>
                                                                            {t("PROCESSING")}
                                                                        </span>
                                                                    );
                                                                    break;
                                                            }
                                                            return (
                                                                <tr key={i}>
                                                                    <td>{item["date"]}</td>
                                                                    <td>{statusHtml}</td>
                                                                    <td>
                                                                        {numberWithCurrencyFormat(
                                                                            parseFloat(item["amount"]),
                                                                            2
                                                                        )}
                                                                    </td>
                                                                    <td
                                                                        style={{ color: "#FFC159", cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            setItem(item);
                                                                            toggle();
                                                                        }}
                                                                    >
                                                                        {" "}
                                                                        {t("VIEW")}{" "}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        }}
                                                    />
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal
                        isOpen={modal}
                        toggle={toggle}
                        wrapClassName="settings-modal"
                        centered
                    >
                        <ModalHeader  >
                            <div className="modal-header-wrapper">
                                <h3 className="customFontMontserratsansserif">{t("WITHDRAWAL_RECORD_DETAIL")}</h3>

                                <button
                                    type="button"
                                    className="modal-X-btn"
                                    onClick={() => setModal(!modal)}
                                    data-dismiss="modal"
                                    aria-hidden="true"
                                >
                                    <span>&times;</span>
                                </button>
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="container">

                                {currentItem["approvalStatus"] == TransactionStatus._APPROVED && (
                                    <div className="row">
                                        <div
                                            className=" col-12 d-flex align-items-center justify-content-center col-12 customFontMontserratsansserif mb-1"
                                        >
                                            <Icon path={mdiCheckCircle} size={2.4} color="#03BB3A" />
                                        </div>
                                        <div
                                            className=" col-12 d-flex align-items-center justify-content-center col-12 customFontMontserratsansserif mb-2"
                                        >

                                            <div style={{ fontSize: "18px", color: "#03BB3A", fontWeight: "bold" }} className="customFontMontserratsansserif">
                                                {t("APPROVED")}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {currentItem["approvalStatus"] == TransactionStatus._REJECTED && (
                                    <div className="row">
                                        <div
                                            className="d-flex align-items-center justify-content-center col-12 customFontMontserratsansserif mb-1"
                                        >
                                            <Icon path={mdiCloseCircle} size={2.4} color="red" />
                                        </div>
                                        <div
                                            className=" col-12 d-flex align-items-center justify-content-center col-12 customFontMontserratsansserif mb-2"
                                        >

                                            <div style={{ fontSize: "18px", color: "red", fontWeight: "bold" }} className="customFontMontserratsansserif">
                                                {t("REJECTED")}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {currentItem["approvalStatus"] == TransactionStatus._OPEN && (
                                    <div className="row ">
                                        <div
                                            className="d-flex align-items-center justify-content-center col-12 customFontMontserratsansserif mb-1"
                                        >
                                            <Icon path={mdiDotsHorizontalCircle} size={2.4} color="white" />
                                        </div>
                                        <div
                                            className=" col-12 d-flex align-items-center justify-content-center col-12 customFontMontserratsansserif mb-2"
                                        >

                                            <div style={{ fontSize: "18px", color: "white", fontWeight: "bold" }} className="customFontMontserratsansserif">
                                                {t("PROCESSING")}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {currentItem["approvalStatus"] == TransactionStatus._IN_REVIEW && (
                                    <div className="row ">
                                        <div
                                            className="d-flex align-items-center justify-content-center col-12 customFontMontserratsansserif mb-1"
                                        >
                                            <Icon path={mdiDotsHorizontalCircle} size={2.4} color="white" />
                                        </div>
                                        <div
                                            className=" col-12 d-flex align-items-center justify-content-center col-12 customFontMontserratsansserif mb-2"
                                        >

                                            <div style={{ fontSize: "18px", color: "white", fontWeight: "bold" }} className="customFontMontserratsansserif">
                                                {t("PROCESSING")}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="row">
                                    <div className="d-flex align-items-center justify-content-center col-12 customFontMontserratsansserif mb-3"
                                        style={{
                                            fontSize: "17px",
                                            color: "white"
                                        }}
                                    >
                                        {numberWithCurrencyFormat(parseFloat(currentItem["amount"]), 2)}
                                    </div>
                                    <div
                                        className="d-flex col-12 customFontMontserratsansserif mb-2"

                                    >
                                        <div style={{ width: "50%", textAlign: "left", color: "white", fontSize: "14px", }} className="customFontMontserratsansserif">
                                            {t("DATE_AND_TIME")}
                                        </div>
                                        <div style={{ width: "50%", textAlign: "right", color: "white", fontSize: "14px", }} className="customFontMontserratsansserif">
                                            {currentItem["dateTime"]}
                                        </div>
                                    </div>
                                    <div
                                        className="d-flex col-12 customFontMontserratsansserif"
                                    >
                                        <div style={{ width: "50%", textAlign: "left", color: "white", fontSize: "14px", }} className="customFontMontserratsansserif">
                                            {t("NEW_BALANCE")}
                                        </div>
                                        <div style={{ width: "50%", textAlign: "right", color: "white", fontSize: "14px", }} className="customFontMontserratsansserif">
                                            {numberWithCurrencyFormat(
                                                parseFloat(currentItem["postMemberPrimaryBalance"]),
                                                2
                                            )}
                                        </div>
                                    </div>
                                    <Button
                                        className="btn btn-block btn-brand-gradient customFontMontserratsansserif mt-3"
                                        onClick={toggle}
                                    >
                                        {t("DONE")}
                                    </Button>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                </div>
            </section>

        </>
    );
};

export default WalletHistory;

import React, { useState, useEffect } from "react";
//import "react-simple-keyboard/build/css/index.css";
import Sidebar from "components/sidebar";
import ContentHeader from "components/content/header";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setBusy, setIdle, showResponseMessage } from "../../redux/AppAction";
import { ApiKey, ApiUrl } from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine";
import { numberWithCurrencyFormat } from "../../util/Util";
import DateRangeFilter from "../../components/custom/DateRangeFilter";

const SummaryDownline = (props) => {
  const _dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  var url_string = window.location;
  var url = new URL(url_string);
  const [allData, setAllData] = useState({});
  const [selectedMember, setSelectedMember] = useState("");
  const [nodePathMembers, setNodePathMembers] = useState([]);
  const [memberDepthData, setMemberDepthData] = useState({});
  const _SELECTED_MEMBER_KEY = "selectedMemberKey";
  const tempUsername = url.searchParams.get("username");
  const [showHieachyUser, isShowHieachyUser] = useState(false);
  const [username, setUsername] = useState("");
  const [processedData, setProcessedData] = useState({});
  const [processedUserTotal, setProcessedUserTotal] = useState({});
  const [processedCategoryTotal, setProcessedCategoryTotal] = useState({});
  const [processedSubTotal, setProcessedSubTotal] = useState({});
  const [processedGrandTotal, setProcessedGrandTotal] = useState({});
  const [processedUsernameRows, setProcessedUsernameRows] = useState({});
  const [currentUsername, setCurrentUsername] = useState(tempUsername);
  const [nodePathData, setNodePathData] = useState([]);

  var _username = useSelector(
    (state) => state["authState"]["userData"]["username"]
  );

  const settlementToggle = (username) => {
    let tempObj = processedUserTotal;
    tempObj[username].showset = !tempObj[username].showset;
    setProcessedUserTotal({ ...tempObj });
  }

  const settlementCatToggle = (username, category) => {
    let tempObj = processedCategoryTotal;
    tempObj[username][category].showset = !tempObj[username][category].showset;
    setProcessedCategoryTotal({ ...tempObj });
  }

  const usernameToggle = (username) => {
    let tempObj = processedUserTotal;
    tempObj[username].show = !tempObj[username].show;
    setProcessedUserTotal({ ...tempObj });
  }
  const categoryToggle = (username, category) => {
    let tempObj = processedCategoryTotal;
    tempObj[username][category].show = !tempObj[username][category].show;
    setProcessedCategoryTotal({ ...tempObj });
  }

  useEffect(() => {
    let nodePath = [];
    if (nodePathData.memberNodePath) {
      if (nodePathData.memberNodePath.length > 0) {
        nodePath = nodePathData.memberNodePath;
      }
    }

    setNodePathMembers(nodePath);
  }, [allData]);

  /// <summary>
  /// Author: -
  /// </summary>
  useEffect(() => {
    getDownlineSales(tempUsername);
  }, []);

  const companySummary = [
    {
      username: "demo123",
      category: [
        {
          child: "Casino",
          game_list: ["AllBet", "WMCasino"],
          game_v_turnover_list: ["303,481.000", "303,481.000"],
          game_payout_list: ["303,481.000", "303,481.000"],
          game_net_profit_list: ["303,481.000", "303,481.000"],
        },
        {
          child: "Lottery",
          game_list: ["High Payout"],
          game_v_turnover_list: ["303,481.000"],
          game_payout_list: ["303,481.000"],
          game_net_profit_list: ["303,481.000"],
        },
        {
          child: "RNG",
          game_list: ["93Connect"],
          game_v_turnover_list: ["303,481.000"],
          game_payout_list: ["303,481.000"],
          game_net_profit_list: ["303,481.000"],
        },
        {
          child: "Slots",
          game_list: ["Jclub777"],
          game_v_turnover_list: ["303,481.000"],
          game_payout_list: ["303,481.000"],
          game_net_profit_list: ["303,481.000"],
        },
        {
          child: "Sport",
          game_list: ["SBOBET"],
          game_v_turnover_list: ["303,481.000"],
          game_payout_list: ["303,481.000"],
          game_net_profit_list: ["303,481.000"],
        },
      ],
      v_turnover: "209,881,682.520",
      v_turnover_list: [
        {
          child: "209,881,682.520",
        },
        {
          child: "155,166,199.780",
        },
        {
          child: "2550.00",
        },
        {
          child: "2550.00",
        },
        {
          child: "155,166,199.780",
        },
      ],
      payout: "247,503.320",
      payout_list: [
        {
          child: "155,166,199.780",
        },
        {
          child: "2550.00",
        },
        {
          child: "2550.00",
        },
        {
          child: "2550.00",
        },
        {
          child: "2550.00",
        },
      ],
      net_profit: "-272,000.770",
      net_profit_list: [
        {
          child: "155,166,199.780",
        },
        {
          child: "2550.00",
        },
        {
          child: "2550.00",
        },
        {
          child: "2550.00",
        },
        {
          child: "2550.00",
        },
      ],
    },
    {
      username: "demo2",
      category: [
        {
          child: "Casino",
          game_list: ["AllBet", "WMCasino"],
          game_v_turnover_list: ["303,481.000", "303,481.000"],
          game_payout_list: ["303,481.000", "303,481.000"],
          game_net_profit_list: ["303,481.000", "303,481.000"],
        },
        {
          child: "Lottery",
          game_list: ["High Payout"],
          game_v_turnover_list: ["303,481.000"],
          game_payout_list: ["303,481.000"],
          game_net_profit_list: ["303,481.000"],
        },
        {
          child: "RNG",
          game_list: ["93Connect"],
          game_v_turnover_list: ["303,481.000"],
          game_payout_list: ["303,481.000"],
          game_net_profit_list: ["303,481.000"],
        },
        {
          child: "Slots",
          game_list: ["Jclub777"],
          game_v_turnover_list: ["303,481.000"],
          game_payout_list: ["303,481.000"],
          game_net_profit_list: ["303,481.000"],
        },
        {
          child: "Sport",
          game_list: ["SBOBET"],
          game_v_turnover_list: ["303,481.000"],
          game_payout_list: ["303,481.000"],
          game_net_profit_list: ["303,481.000"],
        },
      ],
      v_turnover: "209,881,682.520",
      v_turnover_list: [
        {
          child: "209,881,682.520",
        },
        {
          child: "155,166,199.780",
        },
        {
          child: "2550.00",
        },
        {
          child: "2550.00",
        },
        {
          child: "155,166,199.780",
        },
      ],
      payout: "247,503.320",
      payout_list: [
        {
          child: "155,166,199.780",
        },
        {
          child: "2550.00",
        },
        {
          child: "2550.00",
        },
        {
          child: "2550.00",
        },
        {
          child: "2550.00",
        },
      ],
      net_profit: "-272,000.770",
      net_profit_list: [
        {
          child: "155,166,199.780",
        },
        {
          child: "2550.00",
        },
        {
          child: "2550.00",
        },
        {
          child: "2550.00",
        },
        {
          child: "2550.00",
        },
      ],
    },
  ];

  const myIncome = [
    {
      username: "demo2",
      category: [
        {
          child: "Casino",
          v_turnover: "111,369.220",
          percentage: "100/130",
          net_profit: "1000.00",
          game_list: ["AllBet", "WMCasino"],
          game_v_turnover_list: ["303,481.000", "303,481.000"],
          game_percentage_list: ["100/130", "100/130"],
          game_net_profit_list: ["303,481.000", "303,481.000"],
        },
        {
          child: "Slot",
          v_turnover: "111,369.220",
          percentage: "100/130",
          net_profit: "1000.00",
          game_list: ["AllBet", "WMCasino"],
          game_v_turnover_list: ["303,481.000", "303,481.000"],
          game_percentage_list: ["100/130", "100/130"],
          game_net_profit_list: ["303,481.000", "303,481.000"],
        },
      ],
      v_turnover: "111,369.220",
      percentage: "100/130",
      net_profit: "1000.00",
    },
    {
      username: "demonwoman",
      category: [
        {
          child: "Casino",
          v_turnover: "111,369.220",
          percentage: "100/130",
          net_profit: "1000.00",
          game_list: ["AllBet", "WMCasino"],
          game_v_turnover_list: ["303,481.000", "303,481.000"],
          game_percentage_list: ["100/130", "100/130"],
          game_net_profit_list: ["303,481.000", "303,481.000"],
        },
      ],
      v_turnover: "111,369.220",
      percentage: "100/130",
      net_profit: "1000.00",
    },
    {
      username: "demo1",
      category: [
        {
          child: "RNG",
          v_turnover: "111,369.220",
          percentage: "100/130",
          net_profit: "1000.00",
          game_list: ["93Connect"],
          game_v_turnover_list: ["303,481.000"],
          game_percentage_list: ["100/130"],
          game_net_profit_list: ["303,481.000"],
        },
      ],
      v_turnover: "111,369.220",
      percentage: "100/130",
      net_profit: "1000.00",
    },
  ];

  async function getDownlineSales(username) {
    _dispatch(setBusy());
    var responseJson = await ApiEngine.get(`${ApiUrl._API_GET_MEMBER_SHAREHOLDER_DETAIL}?&startdate=${url.searchParams.get("startDate")}&enddate=${url.searchParams.get("endDate")}&username=${username}`);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY]["shareholderData"];
      setAllData(data);
      let memberData = responseJson[ApiKey._API_DATA_KEY]["depthData"];
      setMemberDepthData(memberData);
      setNodePathData(responseJson[ApiKey._API_DATA_KEY]["nodePathData"]);
      setNodePathMembers(responseJson[ApiKey._API_DATA_KEY]["nodePathData"]["memberNodePath"]);
      //setCurrentUsername(memberData["username"]);
      setCurrentUsername(username);

      let tempRows = {};
      let dataRows = {};
      let userTotal = {};
      let categoryTotal = {};
      let subTotal = { sales: 0, vsales: 0, netProfit: 0 };
      let grandTotal = { sales: 0, vsales: 0, netProfit: 0 };

      data["downlineData"].map((e, i) => {
        if (!dataRows[e.username]) {
          dataRows[e.username] = {};
          tempRows[e.username] = { memberGuid: e.memberId, hasDownline: e.usernameHasDownlineData };
          userTotal[e.username] = { grossSales: 0, validTurnover: 0, netProfit: 0, profit: 0, settlement: 0, share: 0 };
          categoryTotal[e.username] = {};
        }

        if (!dataRows[e.username][e.categoryName]) {
          dataRows[e.username][e.categoryName] = {};
          categoryTotal[e.username][e.categoryName] = { grossSales: 0, validTurnover: 0, netProfit: 0, profit: 0, settlement: 0, share: 0 };
        }

        if (!dataRows[e.username][e.categoryName][e.productName]) {
          dataRows[e.username][e.categoryName][e.productName] = { grossSales: e.grossSales, validTurnover: e.validTurnover, netProfit: e.netProfit, profit: e.profit, settlement: e.settlement, share: e.share };
        }

        userTotal[e.username].show = false;
        userTotal[e.username].showset = false;
        userTotal[e.username].grossSales += e.grossSales;
        userTotal[e.username].validTurnover += e.validTurnover;
        userTotal[e.username].netProfit += e.netProfit;
        userTotal[e.username].profit += e.profit;
        userTotal[e.username].settlement += e.settlement;
        userTotal[e.username].share = e.share;


        categoryTotal[e.username][e.categoryName].show = false;
        categoryTotal[e.username][e.categoryName].showset = false;
        categoryTotal[e.username][e.categoryName].grossSales += e.grossSales;
        categoryTotal[e.username][e.categoryName].validTurnover += e.validTurnover;
        categoryTotal[e.username][e.categoryName].netProfit += e.netProfit;
        categoryTotal[e.username][e.categoryName].profit += e.profit;
        categoryTotal[e.username][e.categoryName].settlement += e.settlement;
        categoryTotal[e.username][e.categoryName].share = e.share;

        subTotal.sales += e.grossSales;
        subTotal.vsales += e.validTurnover;
        subTotal.netProfit += e.profit;

        grandTotal.sales += e.grossSales;
        grandTotal.vsales += e.validTurnover;
        grandTotal.netProfit += e.profit;
        grandTotal.netProfit += e.settlement;

      });
      setProcessedUserTotal(userTotal);
      setProcessedCategoryTotal(categoryTotal);
      setProcessedData(dataRows);
      setProcessedUsernameRows(tempRows);
      setProcessedSubTotal(subTotal);
      setProcessedGrandTotal(grandTotal);

    }
    else {
      _dispatch(showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY]));
    }
    _dispatch(setIdle());
  }

  return (
    <>
      <section className="section-padding" id="summary-downline-section">
        <div className="container">
          <div className="row">
            <div className="col-3" id="account-menu">
              <Sidebar activePage={"VIP_EVENT"}/>
            </div>
            <div className="col-9">
              <ContentHeader enableBackButton title={t("SUMMARY_DOWNLINE")} />
              <hr className="right-title-hr" />
              <div className="template-box mt-3" id="transaction-history-box">
                {/* <DateRangeFilter /> */}
                <div className="tab-content">
                  {/*<div className="transac-history-search-box font-11">
                    <div className="left-date text-white font-semi">
                      {url.searchParams.get("startDate")}
                    </div>
                    <div className="text-white">To</div>
                    <div className="right-date text-white font-semi">
                      {url.searchParams.get("endDate")}
                    </div>
                  </div>*/}
                  <div className="summary-downline-title font-semi text-gold font-15">
                    <div style={{ display: "flex", marginBottom: "10px" }}>
                      <b>{t("HIERARCHY") + " : "}</b>
                      <div className="hierarchy-line" style={{ marginLeft: "5px" }}>
                        <a
                          href="javascript:;"
                          onClick={() => {
                            getDownlineSales(_username)
                            setSelectedMember("");
                            sessionStorage.removeItem(_SELECTED_MEMBER_KEY);
                          }}
                        >
                          {_username}
                        </a>
                        {nodePathMembers.length > 0 ? ">" : ""}
                        {nodePathMembers.map((m, index) => {
                          return (
                            <>
                              <a
                                href="javascript:;"
                                onClick={() => getDownlineSales(m.username)}
                              >
                                {m.username}
                              </a>
                              {index != nodePathMembers.length - 1 && ">"}
                            </>
                          );
                        })}
                      </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", alignContent: "flex-start" }}>
                      <div style={{ marginBottom: "10px" }}><b>{t("USERNAME") + " : "}</b>{currentUsername}</div>
                      <div><b>{t("DATE") + " : "}</b>{url.searchParams.get("startDate") + " " + t("TO") + " " + url.searchParams.get("endDate")}</div>
                    </div>
                  </div>
                  {/*<p className="summary-downline-title font-semi text-gold font-15">
                    HIEACHY{showHieachyUser ? ": " + username : ""}
                  </p>*/}

                  {/*<p className="summary-downline-title font-semi text-gold font-15">
                    Username: demo2
                  </p>*/}

                  {Object.keys(processedData).length > 0 && <>
                    <div className="shareholder-my-income">
                      <div className="card-wrapper"><div className="card my-income-card">
                        <div className="my-income-card-content">
                          <div className="content-title">{t("DIRECT_DOWNLINE")}</div>
                          <div className="content-value">{memberDepthData["totalDirectDownlines"]}</div>
                        </div>
                      </div></div>
                      <div className="card-wrapper"><div className="card my-income-card">
                        <div className="my-income-card-content">
                          <div className="content-title">{t("NET_PROFIT")}</div>
                          <div className={"content-value" + (processedGrandTotal.netProfit < 0 ? " text-red" : "")}>{numberWithCurrencyFormat(parseFloat(processedGrandTotal.netProfit), 2, true)}</div>
                        </div>
                      </div></div>
                      <div className="card-wrapper"><div className="card my-income-card">
                        <div className="my-income-card-content">
                          <div className="content-title">{t("VALID_TURNOVER")}</div>
                          <div className={"content-value" + (processedGrandTotal.vsales < 0 ? " text-red" : "")}>{numberWithCurrencyFormat(parseFloat(processedGrandTotal.vsales), 2, true)}</div>
                        </div>
                      </div></div>
                    </div>
                    <div className="card-wrapper"><div className="card card-package">
                      <div className="card-body p-0">
                        <div className="card-table table-responsive">
                          <table className="table table-kpi-summary">
                            <thead style={{ borderBottom: "1px solid" }}>
                              <tr>
                                <th style={{ textAlign: "left", paddingLeft: "20px" }}>{t("USERNAME")}</th>
                                <th style={{ textAlign: "left" }}>{t("CATEGORY")}</th>
                                <th style={{ textAlign: "right" }}>{t("VTURNOVER")}</th>
                                <th style={{ textAlign: "right" }}>{t("%")}</th>
                                <th style={{ textAlign: "right", paddingRight: "20px" }}>{t("NET_PROFIT")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                Object.keys(processedData).length > 0 && Object.keys(processedData).map((_username, i) => {
                                  if (typeof (processedUserTotal[_username]) !== "undefined") {
                                    return (
                                      <>
                                        <tr key={"username_" + _username}>
                                          <td className={"has-downline" + ((Object.keys(processedUsernameRows).length > 0 && processedUsernameRows[_username] && processedUsernameRows[_username]["hasDownline"] && _username !== currentUsername) ? "" : "-false")} style={{ textAlign: "left", paddingLeft: "20px" }} onClick={() => { if (Object.keys(processedUsernameRows).length > 0 && processedUsernameRows[_username] && processedUsernameRows[_username]["hasDownline"]) { getDownlineSales(_username); } }}>{_username}</td>
                                          <td style={{ textAlign: "left" }} onClick={() => usernameToggle(_username)}>{t("ALL")} <i className={processedUserTotal[_username].show ? "shareholder-dropdown fas fa-caret-up" : "shareholder-dropdown fas fa-caret-down"} /></td>
                                          <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedUserTotal[_username].validTurnover), 2, true)}</td>
                                          <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedUserTotal[_username].share), 0, true)}/130</td>
                                          <td className={processedUserTotal[_username].profit < 0 ? "text-red" : ""} style={{ textAlign: "right", paddingRight: "20px" }}>{numberWithCurrencyFormat(parseFloat(processedUserTotal[_username].profit), 2, true)}</td>
                                        </tr>
                                        {
                                          processedUserTotal[_username].show && Object.keys(processedData[_username]).map((_category, j) => {
                                            return (
                                              <>
                                                <tr key={"category_" + _username + "_" + _category} className="child-row">
                                                  <td style={{ textAlign: "left" }}></td>
                                                  <td style={{ textAlign: "left" }} className="cat-prod" onClick={() => categoryToggle(_username, _category)}>- {t(_category)} <i className={processedCategoryTotal[_username][_category].show ? "shareholder-dropdown fas fa-caret-up" : "shareholder-dropdown fas fa-caret-down"} /></td>
                                                  <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedCategoryTotal[_username][_category].validTurnover), 2, true)}</td>
                                                  <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedCategoryTotal[_username][_category].share), 0, true)}/130</td>
                                                  <td className={processedCategoryTotal[_username][_category].profit < 0 ? "text-red" : ""} style={{ textAlign: "right", paddingRight: "20px" }}>{numberWithCurrencyFormat(parseFloat(processedCategoryTotal[_username][_category].profit), 2, true)}</td>
                                                </tr>
                                                {
                                                  processedCategoryTotal[_username][_category].show && Object.keys(processedData[_username][_category]).map((_product, k) => {
                                                    return (
                                                      <>
                                                        <tr key={"product_" + _username + "_" + _category + "_" + _product} className="child-row">
                                                          <td style={{ textAlign: "left" }}></td>
                                                          <td style={{ textAlign: "left" }} className="cat-prod">-- {_product}</td>
                                                          <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedData[_username][_category][_product].validTurnover), 2, true)}</td>
                                                          <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedData[_username][_category][_product].share), 0, true)}/130</td>
                                                          <td className={processedData[_username][_category][_product].profit < 0 ? "text-red" : ""} style={{ textAlign: "right", paddingRight: "20px" }}>{numberWithCurrencyFormat(parseFloat(processedData[_username][_category][_product].profit), 2, true)}</td>
                                                        </tr>
                                                      </>
                                                    )
                                                  })
                                                }
                                              </>
                                            )
                                          })
                                        }
                                      </>
                                    )
                                  }
                                })
                              }

                              <tr className="subtotal" key={"subtotal"}>
                                <td style={{ textAlign: "left", paddingLeft: "20px", fontFamily:"averta-semibold", }} colSpan="2">{t("SUB_TOTAL")}</td>
                                <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedSubTotal.vsales), 2, true)}</td>
                                <td style={{ textAlign: "right" }}></td>
                                <td className={processedSubTotal.netProfit < 0 ? "text-red" : ""} style={{ textAlign: "right", paddingRight: "20px" }}>{numberWithCurrencyFormat(parseFloat(processedSubTotal.netProfit), 2, true)}</td>
                              </tr>

                              {
                                Object.keys(processedData).length > 0 && Object.keys(processedData).map((_username, i) => {
                                  if (typeof (processedUserTotal[_username]) !== "undefined") {
                                    return (
                                      <>
                                        <tr key={"settlement_username_" + _username}>
                                          <td style={{ textAlign: "left", paddingLeft: "20px" }}>{_username}</td>
                                          <td style={{ textAlign: "left" }} colSpan="2" onClick={() => settlementToggle(_username)}>{t("SETTLEMENT")} <i className={processedUserTotal[_username].showset ? "shareholder-dropdown fas fa-caret-up" : "shareholder-dropdown fas fa-caret-down"} /></td>
                                          <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedUserTotal[_username].share), 0, true)}/130</td>
                                          <td className={processedUserTotal[_username].settlement < 0 ? "text-red" : ""} style={{ textAlign: "right", paddingRight: "20px" }}>{numberWithCurrencyFormat(parseFloat(processedUserTotal[_username].settlement), 2, true)}</td>
                                        </tr>
                                        {
                                          processedUserTotal[_username].showset && Object.keys(processedData[_username]).map((_category, j) => {
                                            return (
                                              <>
                                                <tr key={"category_" + _username + "_" + _category} className="child-row">
                                                  <td style={{ textAlign: "left" }}></td>
                                                  <td style={{ textAlign: "left" }} className="cat-prod" onClick={() => settlementCatToggle(_username, _category)}>-{t(_category)} <i className={processedCategoryTotal[_username][_category].show ? "shareholder-dropdown fas fa-caret-up" : "shareholder-dropdown fas fa-caret-down"} /></td>
                                                  <td style={{ textAlign: "right" }}></td>
                                                  <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedCategoryTotal[_username][_category].share), 0, true)}/130</td>
                                                  <td className={processedCategoryTotal[_username][_category].settlement < 0 ? "text-red" : ""} style={{ textAlign: "right", paddingRight: "20px" }}>{numberWithCurrencyFormat(parseFloat(processedCategoryTotal[_username][_category].settlement), 2, true)}</td>
                                                </tr>
                                                {
                                                  processedCategoryTotal[_username][_category].showset && Object.keys(processedData[_username][_category]).map((_product, k) => {
                                                    return (
                                                      <>
                                                        <tr key={"product_" + _username + "_" + _category + "_" + _product} className="child-row">
                                                          <td style={{ textAlign: "left" }}></td>
                                                          <td style={{ textAlign: "left" }} className="cat-prod">--{_product}</td>
                                                          <td style={{ textAlign: "right" }}></td>
                                                          <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedData[_username][_category][_product].share), 0, true)}/130</td>
                                                          <td className={processedData[_username][_category][_product].settlement < 0 ? "text-red" : ""} style={{ textAlign: "right", paddingRight: "20px" }}>{numberWithCurrencyFormat(parseFloat(processedData[_username][_category][_product].settlement), 2, true)}</td>
                                                        </tr>
                                                      </>
                                                    )
                                                  })
                                                }
                                              </>
                                            )
                                          })
                                        }
                                      </>
                                    )
                                  }
                                })
                              }

                              <tr className="grandtotal" key={"grandtotal"}>
                                <td style={{ textAlign: "left", paddingLeft: "20px", fontFamily:"averta-semibold", }} colSpan="2">{t("GRAND_TOTAL")}</td>
                                <td style={{ textAlign: "right" }}></td>
                                <td style={{ textAlign: "right" }}></td>
                                <td className={processedGrandTotal.netProfit < 0 && "text-red"} style={{ textAlign: "right", paddingRight: "20px" }}>{numberWithCurrencyFormat(parseFloat(processedGrandTotal.netProfit), 2, true)}</td>
                              </tr>

                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div></div>
                  </>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default SummaryDownline;

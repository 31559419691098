import React, { useState, useEffect } from "react";
//import "react-simple-keyboard/build/css/index.css";
import Sidebar from "components/sidebar";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { ApiKey, ApiUrl } from "../../util/Constant";
import { stringIsNullOrEmpty, numberWithCurrencyFormat } from "../../util/Util";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";
import ContentHeader from "components/content/header";
import DateRangeFilter from "../../components/custom/DateRangeFilter";
import ApiEngine from "../../util/ApiEngine";

const InternalTransferReport = (props) => {
  const { t } = useTranslation();
  const [initPage, setInitPage] = useState(true);
  const [claimData, setClaimData] = useState([]);
  const [transferUrl, setTransferUrl] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

  // useEffect(() => {
  //   // getTransferData();
  //   getTransferData(startDate, endDate);
  // }, []);

  async function getTransferData(start, end, selected = "") {
    var apiUrl =
      ApiUrl._API_GET_CLAIM_HISTORY +
      "?StartDate=" +
      moment(start).format("YYYY-MM-DD")+
      " 00:00:00" +
      "&EndDate=" +
      moment(end).format("YYYY-MM-DD")+
      " 23:59:59" +
      "&v=" +
      Date.now();

    if (!stringIsNullOrEmpty(selected)) {
      apiUrl +=
        "&SelectedDate=" + moment(selected).format("YYYY-MM-DD HH:mm:ss");
    }

    let responseJson = await ApiEngine.get(apiUrl);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      setClaimData(data["data"]);
    }
    setTransferUrl(apiUrl);
    setInitPage(false);
  }

  useEffect(() => {
    if (!stringIsNullOrEmpty(selectedDate)) {
      getTransferData(startDate, endDate, selectedDate);
    }
  }, [selectedDate]);

  return (
    <>
      <section className="section-padding" id="game-record-section">
        <div className="container">
          <div className="row">
            <div className="col-3" id="account-menu">
              <Sidebar activePage={"VIP_EVENT"} />
            </div>
            <div className="col-md-9">
              <ContentHeader enableBackButton title={t("CLAIM_HISTORY")} />
              <hr className="right-title-hr" />
              <div className="template-box mt-3" id="internal-transfer-box">
                <DateRangeFilter
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  onSave={(start, end) => {
                    setStartDate(moment(start));
                    setEndDate(moment(end));
                    getTransferData(start, end);
                    setSelectedDate("");
                  }}
                  options={true}
                />
                <div className="card card-package">
                  <div className="card-body p-0" style={{ overflowX: "auto" }}>
                    <table className="table reportTable">
                      <thead>
                        <tr>
                          <th>{t("DATE")}</th>
                          <th className="text-right">{t("AMOUNT")}</th>
                        </tr>
                      </thead>
                      {claimData.length > 0 ? (
                      <tbody>
                        <DynamicLoadTable
                          pageSize={20}
                          apiQuery={transferUrl}
                          isTable={true}
                          searchEnabled={true}
                          render={(item, i) => {
                            return (
                              <tr key={"claim_history_" + i}>
                                <td>
                                  {moment(item["date"]).format("DD-MM-YYYY")}
                                </td>
                                <td
                                  className={
                                    item["netProfit"] < 0
                                      ? "rejected text-right"
                                      : "approved text-right"
                                  }
                                >
                                  {numberWithCurrencyFormat(
                                    parseFloat(item["netProfit"]),
                                    2
                                  )}
                                </td>
                              </tr>
                            );
                          }}
                        />
                      </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan="2">
                              {initPage
                                ? t("CLICK_SEARCH_TO_CONTINUE")
                                : t("NO_DATA_FOUND")}
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default InternalTransferReport;

import React, { useState, useEffect } from "react";
//import "react-simple-keyboard/build/css/index.css";
import { WebUrl } from "../../util/Constant";
import { useHistory } from "react-router-dom";
import GameMenu from "components/game-list/game-menu";
import ApiEngine from "../../util/ApiEngine";
import {
  ApiKey,
  ApiUrl,
  SessionKey,
  LanguageOption,
} from "../../util/Constant";
import { useDispatch } from "react-redux";
import {
  showResponseMessage,
  setBusy,
  setIdle,
} from "../../redux/AppAction.js";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

const Terms = (props) => {
  const _history = useHistory();
  const [title, setTitle] = useState("");
  const [html, setHtml] = useState("");
  // const [lang, setLang] = useState("");
  const [displayGameMenu, setDisplayGameMenu] = useState(true);
  var _dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    init();

    // setLang(
    //   LanguageOption.find(
    //     (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
    //   ).languageId
    // );
  }, [

    (localStorage.getItem(SessionKey._LANGUAGE)&& localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ) ? 
    (LanguageOption.find(
      (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)) != undefined? 
      
      LanguageOption.find(
        (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
      ).languageId : LanguageOption[0].languageId) : LanguageOption[0].languageId

    // LanguageOption.find(
    //   (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
    // ).languageId,
  ]);

  useEffect(() => {
    setDisplayGameMenu(false);
    setTimeout(() => setDisplayGameMenu(true), 1);
  }, [(localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
  localStorage.getItem(SessionKey._LANGUAGE) : 'en')]);

  async function init() {
    _dispatch(setBusy());

    let apiUrl =
      ApiUrl._API_GET_COMPANY_CONTENT +
      "?page=" +
      "terms-and-conditions";

      // "&languageId=" +
      // LanguageOption.find(
      //   (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
      // ).languageId;

      if (localStorage.getItem(SessionKey._LANGUAGE)&& localStorage.getItem(SessionKey._LANGUAGE) != 'undefined') {
        if( LanguageOption.find(
          (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)) != undefined) {
            apiUrl += "&languageId=" + LanguageOption.find(
              (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
            ).languageId;
          }
          else{
            apiUrl += "&languageId=1";
          }
      } else {
        apiUrl += "&languageId=1";
      }

    var responseJson = await ApiEngine.get(apiUrl);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      const title = responseJson[ApiKey._API_DATA_KEY][0]["title"];
      const content = responseJson[ApiKey._API_DATA_KEY][0]["content"];
      setHtml(content);
      setTitle(title);
    } else {
      _dispatch(
        showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
      );
    }
    _dispatch(setIdle());
  }

  return (
    <>
      {displayGameMenu && (
        <>
          <section className="category-section">
            <GameMenu />
          </section>
          <section className="section-padding" id="terms-section">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <ul className="breadcrumbs list-unstyled">
                    <li>
                      <a
                        onClick={() => _history.push(WebUrl._URL_SETTINGS)}
                        className="text-white"
                      >
                        {t("SETTINGS")}
                      </a>
                    </li>
                    <li>
                      <a className="text-white">{title}</a>
                    </li>
                  </ul>
                  <div className="template-box mt-3" id="terms-box">
                    <div
                      className="template-box bg-darkblue text-white font-semi"
                      id="terms-content"
                    >
                      {parse(html)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};
export default Terms;

import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { ApiKey, ApiUrl } from "../../util/Constant";
import {
  stringIsNullOrEmpty,
  createFormBody,
  isObjectEmpty,
} from "../../util/Util";
import { useTranslation } from "react-i18next";
import Input from "../../components/custom/Input";
import ApiEngine from "../../util/ApiEngine";
import { Alert } from "reactstrap";
import {
  showResponseMessage,
  setBusy,
  setIdle,
  setActiveTab,
} from "../../redux/AppAction";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";
import Sidebar from "components/sidebar";
import ContentHeader from "components/content/header";

const DownlineGroupDetail = () => {
  const { t } = useTranslation();
  const _ALERT_TIMEOUT = 3000;
  let _history = useHistory();
  let _location = useLocation();
  const _dispatch = useDispatch();
  let _userData = useSelector((state) => state.authState.userData);
  let _savedPageSize = useSelector((state) => state.appState.pageSize);
  const [downlineGroupDetail, setDownlineGroupDetail] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [initCheck, setInitCheck] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [memberGroupQuery, setMemberGroupQuery] = useState("");
  const [showSubmitBtn, setShowSubmitBtn] = useState(false);
  const [isAddAllToGroup, setIsAddAllToGroup] = useState(true);

  const { register, handleSubmit } = useForm();

  useEffect(() => {
    if (_location.state && _location.state.id >= 0) {
      setMemberGroupQuery(
        ApiUrl._API_GET_UNGROUP_MEMBER_LIST + "?groupId=" + _location.state.id
      );
    }
    init();
    _dispatch(setActiveTab(1));
    setInitCheck(true);
  }, [_location.state]);

  async function init() {
    getGroupDetail();
  }

  async function getGroupDetail() {
    let apiUrl = ApiUrl._API_GET_DOWNLINE_GROUP_DETAIL_BY_ID;

    if (_location.state && _location.state.id >= 0) {
      apiUrl += "?groupId=" + _location.state.id;
    }

    let responseJson = await ApiEngine.get(apiUrl);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      if (data !== null) {
        setDownlineGroupDetail(data);
      }
      if (data === null || data["name"] !== "Default") {
        setShowSubmitBtn(true);
      }
    }
  }

  const addMemberIntoGroup = async (member, e) => {
    setInitCheck(false);
    clearTimeout();
    let params = {};
    if (typeof member === "undefined") {
      _dispatch(setBusy());
      params = { removeMember: !isAddAllToGroup };
      setIsAddAllToGroup(!isAddAllToGroup);
    } else {
      params = { memberGuid: member["id"], removeMember: !e.target.checked };
    }
    let formBody = createFormBody(params);
    let apiUrl = ApiUrl._API_ADD_OR_REMOVE_MEMBER_INTO_GROUP;
    if (_location.state && !stringIsNullOrEmpty(_location.state.id)) {
      apiUrl += "?groupId=" + _location.state.id;
    }
    let responseJson = await ApiEngine.post(apiUrl, formBody);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setAlertMsg(
        (typeof member === "undefined"
          ? t("ALL_CURRENT_AND_UNGROUPED_MEMBER")
          : member["username"]) +
          " " +
          t("SUCCESSFULLY_" + responseJson[ApiKey._API_MESSAGE_KEY] + "_GROUP")
      );
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), [_ALERT_TIMEOUT]);
      setInitCheck(true);
    }
    _dispatch(setIdle());
  };

  const submitForm = async (data) => {
    let params = { groupName: data.groupName };
    let formBody = createFormBody(params);
    let apiUrl = ApiUrl._API_CREATE_OR_UPDATE_DOWNLINE_GROUP;
    if (!stringIsNullOrEmpty(_location.state && _location.state.id)) {
      apiUrl += "?groupId=" + _location.state.id;
    }
    let responseJson = await ApiEngine.post(apiUrl, formBody);

    _dispatch(
      showResponseMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        t(responseJson[ApiKey._API_MESSAGE_KEY])
      )
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setModalVisible(false);

      if (!downlineGroupDetail || !downlineGroupDetail.length) {
        _history.replace({
          pathname: _location.pathname,
          state: {
            id: responseJson[ApiKey._API_DATA_KEY]["id"],
          },
        });
      } else {
        init();
      }
    }
  };

  const DownlineGroupDetailForm = () => {
    return (
      <form className="template-box mb-4">
        <div className="row" style={{ marginBottom: "1em" }}>
          <div className="col-lg-12 ">
            <label className="col-md-4 ml-3 col-form-label p-0 text-white text-bold">
              {t("GROUP_NAME")}
            </label>
            <div
              className="row"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="col-7">
                <Input
                  name="groupName"
                  containerClassName="mb-0"
                  className="custom-input-style-1 rounded"
                  defaultValue={
                    downlineGroupDetail && initCheck
                      ? downlineGroupDetail.name
                      : ""
                  }
                  ref={register()}
                  placeholder={t("ENTER_GROUP_NAME")}
                  readOnly={
                    !isObjectEmpty(downlineGroupDetail) &&
                    downlineGroupDetail.name === "Default"
                      ? "readonly"
                      : false
                  }
                />
              </div>
              {showSubmitBtn && (
                <div className="col-5">
                  <button
                    type="button"
                    className="custom-btn-style-1"
                    style={{ marginTop: "0" }}
                    disabled={
                      downlineGroupDetail &&
                      initCheck &&
                      downlineGroupDetail.name &&
                      downlineGroupDetail.name === "Default"
                        ? true
                        : false
                    }
                    onClick={() => handleSubmit(submitForm)()}
                  >
                    {t("SUBMIT")}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    );
  };

  const checkboxLabel = (i) => {
    if (
      downlineGroupDetail &&
      initCheck &&
      downlineGroupDetail.name &&
      downlineGroupDetail.name !== "Default"
    ) {
      return (
        <label
          htmlFor={"check-for-member-" + i}
          className="downline-group-checkbox"
        >
          <span style={{ position: "relative" }}>
            <img
              className="checked-icon"
              src={"/img/icon/custom-checkbox-1-checked.svg"}
            />
            <img
              className="unchecked-icon"
              src={"/img/icon/custom-checkbox-1-unchecked.svg"}
            />
          </span>
        </label>
      );
    }
  };

  return (
    <div>
      <section className="section-padding" id="downline-section">
        <div className="container">
          <div className="row">
            <div className="col-3" id="account-menu">
              <Sidebar activePage={"DOWNLINE"} />
            </div>
            <div className="col-9">
              <ContentHeader
                title={t("EDIT_DOWNLINE_DETAILS")}
                enableBackButton={true}
              />
              <hr className="right-title-hr" />
              <div
                id={
                  _userData.selectedTheme === 0
                    ? "downline-group"
                    : "downline-group-modern"
                }
                className="container mt-3"
                style={{ paddingBottom: "30px" }}
              >
                {DownlineGroupDetailForm()}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p className="f-w-800 text-brand text-white text-bold">
                    {t("DOWNLINE")}
                  </p>
                  {_location.state &&
                    _location.state.id >= 0 &&
                    downlineGroupDetail.name !== "Default" && (
                      <span
                        className="hover-underline cursor-pointer text-gold"
                        onClick={() => addMemberIntoGroup()}
                      >
                        {isAddAllToGroup ? t("SELECT_ALL") : t("DESELECT_ALL")}
                      </span>
                    )}
                </div>
                {_location.state && _location.state.id >= 0 && initCheck ? (
                  <>
                    <div className="row text-white">
                      <DynamicLoadTable
                        pageSize={_savedPageSize}
                        apiQuery={memberGroupQuery}
                        searchEnabled={true}
                        autoscrollEnabled={true}
                        render={(downline, i) => {
                          return (
                            <React.Fragment key={"downline_group_" + i}>
                              <div className="col-md-6">
                                <div
                                  className="template-box mt-3 d-flex align-items-center justify-content-between"
                                  id="downline-box"
                                >
                                  <div>
                                    <span className="d-block font-14 text-white font-semi">
                                      {downline["username"]} -{" "}
                                      {downline["fullname"]}
                                    </span>
                                    {downline["nickname"] != "" && (
                                      <small className="d-block font-14 text-white font-semi">
                                        ({downline["nickname"]})
                                      </small>
                                    )}
                                    <span className="font-14 text-white font-semi">
                                      {downline["phoneNumber"]}
                                    </span>
                                  </div>
                                  <input
                                    type="checkbox"
                                    id={"check-for-member-" + i}
                                    className="input-checkbox-custom"
                                    defaultChecked={downline["memberIsInGroup"]}
                                    onChange={(e) =>
                                      addMemberIntoGroup(downline, e)
                                    }
                                  />
                                  {initCheck &&
                                    !isObjectEmpty(downlineGroupDetail) &&
                                    downlineGroupDetail.name !== "Default" && (
                                      <label
                                        htmlFor={"check-for-member-" + i}
                                        className="downline-group-checkbox mb-0"
                                      >
                                        <span style={{ position: "relative" }}>
                                          <img
                                            className="checked-icon"
                                            src={"/img/icon/Success_Profile.svg"}
                                          />
                                          <img
                                            className="unchecked-icon"
                                            src={"/img/icon/custom-checkbox-1-unchecked.svg"}
                                          />
                                        </span>
                                      </label>
                                    )}
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="no-group-name font-thick">
                      {t("SAVE_GROUP_NAME_FIRST")}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {showAlert && (
        <div
          className="content content-full-width"
          style={{
            position: "fixed",
            top: "10px",
            width: "100%",
            zIndex: 9999,
          }}
        >
          <div className="container">
            <Alert color="green">{t(alertMsg)}</Alert>
          </div>
        </div>
      )}
    </div>
  );
};

export default DownlineGroupDetail;

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  ApiKey,
  ApiUrl,
  TransactionType,
  PanelType,
  _CURRENCY_RATIO,
  FixedDepositAmount
} from "../../util/Constant";
import { stringIsNullOrEmpty, createMultiPartFormBody, numberWithCommas, numberWithCurrencyFormat } from "../../util/Util";
import ApiEngine from "../../util/ApiEngine";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setBusy, setIdle, showResponseMessage } from "../../redux/AppAction";
import { map } from "lodash";
import classnames from "classnames";
import TickIcon from "../../assets/img/assets/tick.svg";

const PowerPayDeposit = (props) => {
  const _RECEIPT_REFERENCE = "Power Pay";
  const { t } = useTranslation();
  let _history = useHistory();
  let _dispatch = useDispatch();
  var _userData = useSelector(state => state.authState.userData);
  const { handleSubmit, register, control, setValue, errors, setError, clearErrors } =
    useForm();
  const [bankOption, setBankOption] = useState([]);
  const [maxDeposit, setMaxDeposit] = useState(0);
  const [minDeposit, setMinDeposit] = useState(0);
  const [serviceCharge, setServiceCharge] = useState();
  const [currentInput, setCurrentInput] = useState();
  const [gateways, setGateways] = useState(props.gatewayOption);
  const [amountValue, setAmountValue] = useState("");
  const [bankId, setBankId] = useState(props.bankId);


  useEffect(() => {
    init();
  }, []);

  async function init() {
    if (gateways["instantBankSettingModel"]) {
      setMaxDeposit(gateways["instantBankSettingModel"][0].maximumDeposit);
      setMinDeposit(gateways["instantBankSettingModel"][0].minimumDeposit);
      setBankOption(gateways["instantBankSettingModel"]);
      setServiceCharge(gateways["serviceCharge"]);
    } else {
      _history.goBack();
    }
  }

  async function onSubmit(data, e) {
    _dispatch(setBusy());
    var bankData = bankOption.filter(
      (option) => option.bankId === bankId
    )[0];
    let params = {
      transactionTypeId: TransactionType._DEPOSIT,
      bankId: data.bankId,
      amount: amountValue,
      bankCode: bankData.bankCode,
      channel: gateways["instantPayGatewayId"],
      panel: PanelType._MEMBERSITE,
      instantPayBankSettingId: bankData.id,
      currency: bankData.currency,
      instantPay: true,
      receiptReference: _RECEIPT_REFERENCE + "(" + gateways["instantPayGatewayString"] + ")",
      bankAccountId: bankData.bankId,
    };

    if (!stringIsNullOrEmpty(props.bonusDeposit) && props.bonusDeposit != "-1") {
      params["bonusId"] = props.bonusDeposit;
    } else if (props.bonusDeposit == "-1") {
      params["bonusId"] = "";
    }

    let responseJson = await ApiEngine.post(
      ApiUrl._API_CREATE_TRANSACTION,
      createMultiPartFormBody(params)
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      _dispatch(setBusy(true, t("PLEASE_WAIT_REDIRECTING")));

      e.target.reset();
      //props.setTriggerParentInit(true);
      props.parentInit();
      setValue("bankId", "");
      window.location.href = responseJson[ApiKey._API_DATA_KEY];
      _dispatch(setIdle());
    } else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }
    _dispatch(setIdle());
  }

  const checkValueOnKeyDown = async (e) => {
    clearErrors();
    let tempAmountValue = amountValue;
    if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) { //keyCode of 0 to 9, numpad 0 to 9
      tempAmountValue = amountValue + "" + e.key;
      setAmountValue(tempAmountValue);
    }
    else if (e.keyCode == 8 || e.keyCode == 46) //keyCode of backSpace and Del
    {
      tempAmountValue = amountValue.slice(0, -1);
      setAmountValue(tempAmountValue);
    }
    if (parseFloat(tempAmountValue) < minDeposit) {
      setError("amount", {
        message: "VALUE_LESS_THAN_MINIMUM",
      });
    }
    else if (parseFloat(tempAmountValue) > maxDeposit) {
      setError("amount", {
        message: "EXCEED_MAXIMUM_VALUE",
      });
    }
  }

  const checkValueOnSelectFixedAmt = async (fixedAmt) => {
    let updatedValue = parseFloat(fixedAmt) + parseFloat(stringIsNullOrEmpty(amountValue) ? 0 : amountValue);
    setAmountValue(
      updatedValue
    );
    setValue("amount", updatedValue);
    clearErrors();
    let tempAmountValue = updatedValue;
    if (tempAmountValue < minDeposit) {
      setError("amount", {
        message: "VALUE_LESS_THAN_MINIMUM",
      });
    }
    else if (tempAmountValue > maxDeposit) {
      setError("amount", {
        message: "EXCEED_MAXIMUM_VALUE",
      });
    }
  }

  return (
    <>
      {parseFloat(serviceCharge) > 0 && (
        <div>
          {parseFloat(serviceCharge) !== 0 && (
            <div className="col-md-12 px-0 pt-3">
              <div className="reminder font-15 text-white font-semi">
                {t("SERVICE_CHARGE_DISCLAIM") +
                  serviceCharge +
                  "%" +
                  t("SERVICE_CHARGE_DISCLAIM_BACK")}
              </div>
            </div>
          )}
        </div>
      )}
      <div className="row">
        <div className="col-md-12">
          <form id="spgdeposit" onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-4">
              <div className="form-group m-b-15 px-0">
                <label className="font-15 text-white font-semi mb-3">
                  {t("AMOUNT")}
                </label>
                <div className="deposit-input-group mb-3">
                  <span class="currency-prefix">IDR</span>
                  {/*<input
                    type="text"
                    className="form-white-input"
                    placeholder={t("PLEASE_ENTER_AMOUNT")}
                    name="amount"
                    onClick={(e) => {
                      setCurrentInput(e.target);
                    }}
                    ref={register({
                      required: "PLEASE_ENTER_AMOUNT",
                      validate: {
                        minValue: (value) =>
                          (parseFloat(value) > 0 && value >= minDeposit) ||
                          "VALUE_LESS_THAN_MINIMUM",
                        maxValue: (value) =>
                          parseFloat(value) <= maxDeposit ||
                          "EXCEED_MAXIMUM_VALUE",
                      },
                      pattern: {
                        value: /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\d{1,3})?$/,
                        message:
                          "INVALID_AMOUNT",
                      },
                    })}
                  />*/}
                  {<input
                    type="text"
                    className="form-white-input"
                    name="amount"
                    placeholder={t("PLEASE_ENTER_AMOUNT")}
                    value={numberWithCommas(amountValue)}
                    onKeyDown={(e) => checkValueOnKeyDown(e)}
                  />}
                  <div className="clear-amount" onClick={() => setAmountValue("")}>
                    <img
                      src={'/img/v3/reload/close.png'}
                      alt="i8 banks"
                      className="img-responsive"
                    />
                  </div>
                </div>
                {errors.amount && (
                  <div className="invalid-feedback">
                    {t(errors.amount.message)}
                  </div>
                )}
                {minDeposit != 0 && maxDeposit != 0 && (
                  <div className="font-11 amount-reminder">
                    <span className="reminder mr-2">
                      * {t("MIN")} : IDR {numberWithCommas(minDeposit)} / {t("MAX")} : IDR {numberWithCommas(maxDeposit)}
                    </span>
                  </div>
                )}
                <div className="font-11 color-white">
                  {parseFloat(amountValue) >= minDeposit ?
                    <>
                      <span>{t("MAIN_WALLET_NEW_BALANCE") + " : "}</span>
                      <span>{numberWithCurrencyFormat(parseFloat(_userData.walletBalance), 2, true) + " "}</span>
                      <i className="fas fa-arrow-right" />
                      <span>{" " + numberWithCurrencyFormat(parseFloat(_userData.walletBalance) + (amountValue > 0 ? parseFloat(amountValue / _CURRENCY_RATIO) : 0), 2, true)}</span>
                    </>
                    :
                    <>
                    </>
                  }
                </div>
                <span className="custom-color-yellow mr-2 font-11 font-bold text-nowrap">{"(1 : IDR 1,000)"}</span>
              </div>
            </div>
            <div className="amount-row">
              {FixedDepositAmount.map((amount, index) => {
                return (
                  <button
                    key={index}
                    type="button"
                    onClick={() => {
                      checkValueOnSelectFixedAmt(amount);
                    }}
                    className="btn custom-button"
                  >
                    + {numberWithCommas(amount)}
                  </button>
                );
              })}
            </div>
            <div className="form-group mt-3 col-md-12 px-0">
              <button type="submit" className="custom-btn-style-1">
                {t("SUBMIT")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default PowerPayDeposit;
import React, { useState, useEffect } from "react";
//import "react-simple-keyboard/build/css/index.css";
import Sidebar from "components/sidebar";
import { ApiUrl } from "../../util/Constant";
import moment from "moment";
import { stringIsNullOrEmpty, numberWithCurrencyFormat } from "../../util/Util";
import { useTranslation } from "react-i18next";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";
import ContentHeader from "components/content/header";
import DateRangeFilter from "../../components/custom/DateRangeFilter";

const WalletStatement = (props) => {
  const { t } = useTranslation();
  const [walletUrl, setWalletUrl] = useState("");
  const [startDate, setStartDate] = useState(moment().startOf("week").format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf("week").format('YYYY-MM-DD'));
  const [selectedDate, setSelectedDate] = useState("");

  useEffect(() => {
    getWalletStatement(startDate, endDate);
  }, []);

  async function getWalletStatement(start, end, selected = "") {
    // let apiUrl =
    //   ApiUrl._API_GET_MEMBER_WALLET_STATEMENT +
    //   "?StartDate=" +
    //   moment(start).format("YYYY-MM-DD") +
    //   "&EndDate=" +
    //   moment(end).format("YYYY-MM-DD") +
    //   "&v=" +
    //   Date.now();

    let apiUrl =
      ApiUrl._API_GET_MEMBER_WALLET_STATEMENT +
      "?StartDate=" +
      // moment(start).format("YYYY-MM-DD HH:mm:ss") +
      moment(start).startOf('day').format("YYYY-MM-DD") +
      "&EndDate=" +
      // moment(end).format("YYYY-MM-DD HH:mm:ss") +
      moment(end).endOf('day').format("YYYY-MM-DD") +
      "&v=" + moment(start).startOf('day').format("YYYY-MM-DD");
      
    if (!stringIsNullOrEmpty(selected)) {
      apiUrl += "&SelectedDate=" + moment(selected).format("YYYY-MM-DD");
      // apiUrl += "&SelectedDate=" + moment(selected).format("YYYY-MM-DD");
    }
    setWalletUrl(apiUrl);
  }

  useEffect(() => {
    if (!stringIsNullOrEmpty(selectedDate)) {
      getWalletStatement(startDate, endDate, selectedDate);
    }
  }, [selectedDate]);

  return (
    <>
      <section className="section-padding" id="game-record-section">
        <div className="container">
          <div className="row">
            <div className="col-3" id="account-menu">
              <Sidebar activePage={"REPORTS"} />
            </div>
            <div className="col-9">
              <ContentHeader enableBackButton title={t("WALLET_STATEMENT")} />
              <hr className="right-title-hr" />
              <div className="template-box mt-3" id="wallet-statement-box">
                <DateRangeFilter
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  onSave={(start, end) => {
                    setStartDate(moment(start));
                    setEndDate(moment(end));
                    getWalletStatement(start, end);
                    setSelectedDate("");
                  }}
                  options={true}
                />
                <div className="card card-package">
                  <div className="card-body p-0">
                    <div className="table-responsive">
                      <table className="table reportTable">
                        <thead>
                          <tr>
                            <th>{t("DATE")}</th>
                            <th>{t("REMARK")}</th>
                            <th style={{ textAlign: "right" }}>
                              {t("PRE_MEMBER_CREDIT")}
                            </th>
                            <th style={{ textAlign: "right" }}>
                              {t("AMOUNT")}
                            </th>
                            <th style={{ textAlign: "right" }}>
                              {t("POST_MEMBER_CREDIT")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <DynamicLoadTable
                            pageSize={20}
                            apiQuery={walletUrl}
                            searchEnabled={true}
                            isTable={true}
                            render={(item, i) => {
                              let isGrouped =
                                moment(endDate, "YYYY-MM-DD").diff(
                                  moment(startDate, "YYYY-MM-DD"),
                                  "days"
                                ) >= 1 && stringIsNullOrEmpty(selectedDate);
                              return (
                                <tr key={"wallet_statement_" + i}>
                                  <td
                                    className={isGrouped ? "hoverable" : ""}
                                    onClick={() => {
                                      if (isGrouped) {
                                        // setSelectedDate(item["parsedTime"]);
                                        setSelectedDate(moment(item["parsedTime"], 'MM/DD/YYYY').format('YYYY-MM-DD'));
                                      }
                                    }}
                                  >
                                    {item["parsedTime"]}
                                  </td>
                                  <td>
                                    {item["remark"]}
                                    {item["serviceCharge"] != "0"
                                      ? " - Fees " +
                                      (!isGrouped
                                        ? "@" +
                                        item["serviceChargePercentage"] +
                                        "%"
                                        : "") +
                                      "= " +
                                      numberWithCurrencyFormat(
                                        parseFloat(item["serviceCharge"]),
                                        2
                                      )
                                      : ""}
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    {numberWithCurrencyFormat(
                                      parseFloat(item["preMemberCredit"]),
                                      2
                                    )}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "right",
                                      color:
                                        item["postMemberCredit"] >
                                          item["preMemberCredit"]
                                          ? "green"
                                          : "red",
                                    }}
                                  >
                                    {numberWithCurrencyFormat(
                                      parseFloat(item["viewAmount"]),
                                      2
                                    )}
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    {numberWithCurrencyFormat(
                                      parseFloat(item["postMemberCredit"]),
                                      2
                                    )}
                                  </td>
                                </tr>
                              )
                            }}
                          />
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default WalletStatement;
import React, { useState, useEffect } from "react";
//import "react-simple-keyboard/build/css/index.css";
import Sidebar from "components/sidebar";
import { ApiUrl } from "../../util/Constant";
import moment from "moment";
import { stringIsNullOrEmpty, numberWithCurrencyFormat } from "../../util/Util";
import { useTranslation } from "react-i18next";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";
import ContentHeader from "components/content/header";
import DateRangeFilter from "../../components/custom/DateRangeFilter";
import LoyaltyRedemption from "./LoyaltyRedemption";

const LoyaltyRedemptionHistory = (props) => {
  const { t } = useTranslation();
  const [redeemUrl, setRedeemUrl] = useState("");
  const [startDate, setStartDate] = useState(moment().startOf("week").format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf("week").format('YYYY-MM-DD'));
  const [selectedDate, setSelectedDate] = useState("");

  useEffect(() => {
    getRedemptionRecord(startDate, endDate, selectedDate);
  }, []);

  async function getRedemptionRecord(start, end, selected = "") {
    let apiUrl =
      ApiUrl._API_GET_LOYALTY_REDEMPTION_HISTORY +
      "?StartDate=" +
      moment(start).format("YYYY-MM-DD") +
      "&EndDate=" +
      moment(end).format("YYYY-MM-DD") +
      "&v=" +
      Date.now();
    if (!stringIsNullOrEmpty(selected)) {
      apiUrl += "&SelectedDate=" + moment(selected).format("YYYY-MM-DD");
    }
    setRedeemUrl(apiUrl);
  }

  useEffect(() => {
    if (!stringIsNullOrEmpty(selectedDate)) {
      getRedemptionRecord(startDate, endDate, selectedDate);
    }
  }, [selectedDate]);

  return (
    <>
      <section className="section-padding" id="loyalty-section">
        <div className="container">
          <div className="row">
            <div className="col-3" id="account-menu">
              <Sidebar activePage={"GAME_POINTS"} />
            </div>
            <div className="col-9">
              <ContentHeader enableBackButton title={t("GAME_POINTS_REDEMPTION_RECORD")} />
              <hr className="right-title-hr" />
              <div className="template-box mt-3" id="loyalty-history-box">
                <DateRangeFilter
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  onSave={(start, end) => {
                    setStartDate(moment(start));
                    setEndDate(moment(end));
                    getRedemptionRecord(start, end);
                    setSelectedDate("");
                  }}
                  options={true}
                />
                <div className="card card-package">
                  <div className="card-body p-0">
                    <div className="table-responsive">
                      <table className="table reportTable">
                        <thead>
                          <tr>
                            <th>{t("ITEM_NAME")}</th>
                            <th>{t("POINTS_NEEDED")}</th>
                            <th>{t("POINTS_BEFORE_REDEEM")}</th>
                            <th>{t("POINTS_AFTER_REDEEM")}</th>
                            <th>{t("REDEEM_DATE")}</th>
                            <th>{t("REDEEM_STATUS")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <DynamicLoadTable
                            pageSize={20}
                            apiQuery={redeemUrl}
                            searchEnabled={true}
                            isTable={true}
                            render={(item, i) => {
                              return (
                                <tr key={i}>
                                  <td>{item["itemName"]}</td>
                                  <td style={{ textAlign: "right" }}>
                                    {numberWithCurrencyFormat(
                                      parseFloat(item["pointsNeeded"]),
                                      2
                                    )}
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    {numberWithCurrencyFormat(
                                      parseFloat(item["pointsBeforeRedeem"]),
                                      2
                                    )}
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    {numberWithCurrencyFormat(
                                      parseFloat(item["pointsAfterRedeem"]),
                                      2
                                    )}
                                  </td>
                                  <td>{moment(item["redeemTime"]).format("YYYY-MM-DD HH:mm:ss")}</td>
                                  {item["itemReceived"] == true ?
                                    <td>
                                      <span style={{ color: "#03BB3A" }}>{t("RECEIVED")}</span>
                                    </td>
                                    :
                                    <td></td>
                                  }
                                </tr>
                              )
                            }}
                          />
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default LoyaltyRedemptionHistory;
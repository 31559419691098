import React, { useState, useEffect } from "react"; 
import { filter, find, map } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  LanguageOption,
  ApiUrl,
  WebUrl,
  SessionKey,
  Language,
} from "./../../util/Constant.js";
import { setBusy, setIdle } from "../../redux/AppAction.js";
import { useTranslation } from "react-i18next";
import Dropdown from "react-bootstrap/Dropdown";
import ApiEngine from "./../../util/ApiEngine.js";
import { createMultiPartFormBody } from "./../../util/Util.js";
import { saveLanguageId } from "../../redux/LanguageAction.js";
import moment from "moment";
import CnyFooter from "./../../assets/img/footer/CNY_Footer.png";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Footer = () => {
  const { t, i18n } = useTranslation();
  const _dispatch = useDispatch();
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const gameCategories = useSelector((state) => {
    return filter(state.gameState.gameCategories, (item) => {
      return item.id > 0;
    });
  });
    const [cnyEndTime, setCnyEndTime] = useState(moment("2023-02-05 23:59:59:999").format("YYYY-MM-DD HH:mm:ss:SSS"));
    const [currentTime, setCurrentTime] = useState(moment().format("YYYY-MM-DD HH:mm:ss:SSS"));

  const selectedLanguageId = useSelector(
    (state) => state.languageState.languageId
  );

  const isLoggedIn = useSelector((state) => state.authState.isLoggedIn);
  const _userData = useSelector((state) => state.authState.userData);

  useEffect(() => {
    if (selectedLanguageId) {
      const selectedItem = find(
        LanguageOption,
        (item) => item.code === selectedLanguageId
      );

      setSelectedLanguage(selectedItem.prefix);
    }
  }, [selectedLanguageId]);

  const aboutMenu = [
    {
      link: WebUrl._URL_ABOUT_US,
      text: t("ABOUT_US"),
    },
    {
      link: WebUrl._URL_HELP_CENTRE,
      text: t("HELP_CENTRE"),
    },
    {
      link: WebUrl._URL_TERMS_AND_CONDITIONS,
      text: t("TERMS_AND_CONDITIONS"),
    },
    {
      link: WebUrl._URL_PRIVACY_POLICY,
      text: t("PRIVACY_POLICY"),
    },
    {
      link: WebUrl._URL_AFFILIATE_PARTNER_PROGRAM,
      text: t("AFFILIATE_PARTNER_PROGRAM"),
    },
    {
      link: WebUrl._URL_RESPONSIBLE_GAMBLING,
      text: t("RESPONSIBLE_GAMBLING"),
    },
  ];

  const supportMenu = [
    /* TEMPORARILY HIDE PAYMENT OPTIONS UNTIL WE HAVE CONTENT */
    // {
    //   link: WebUrl._URL_HELP_CENTRE_CATEGORY.replace(":category", "Payment"),
    //   text: t("PAYMENT_OPTION"),
    // },
    {
      link: WebUrl._URL_HELP_CENTRE,
      text: t("HELP_CENTRE"),
    },
    {
      link: WebUrl._URL_HELP_CENTRE_SUB_CATEGORY
        .replace(":category", "Game")
        .replace(":sub_category", "How to Guide"),
      text: t("HOW_TO_GUIDE"),
    },
    {
      link: WebUrl._URL_HELP_CENTRE_SUB_CATEGORY
        .replace(":category", "Game")
        .replace(":sub_category", "General Betting Rules"),
      text: t("GENERAL_BETTING_RULES"),
    },
  ];

  const paymentMethods = [
    {
      className: "img-payment",
      alt: "BankTransfer",
      image: "/img/footer/LocalBankTransfer.png",
    },
    {
      className: "img-payment",
      alt: "EWallet",
      image: "/img/footer/Ewallet.png",
    },
    {
      className: "img-payment",
      alt: "Pulsa",
      image: "/img/footer/Pulsa.png",
    }
  ];

  const security = [
    {
      alt: "Iovation",
      image: "/img/footer/security1.svg",
    },
    {
      alt: "ThreatMetrix",
      image: "/img/footer/security2.svg",
    },
  ];
  const browser = [
    {
      alt: "Crome",
      image: "/img/footer/browser1.svg",
    },
    {
      alt: "Firefox",
      image: "/img/footer/browser2.svg",
    },
  ];

  const socialMedias = [
    {
      alt: "facebook",
      image: "/img/social-media/fb.svg",
      link: "https://www.facebook.com/i8OfficialTeam",
    },
    {
      alt: "youtube",
      image: "/img/social-media/yt.svg",
      link: "https://www.youtube.com/channel/UCxrKFaJx8jENt4hjrnKtYiQ",
    },
    //{
    //  alt: "twitter",
    //  image: require("../../assets/img/social-media/tw.png"),
    //  link: "https://twitter.com/?lang=en",
    //},
    {
      alt: "instagram",
      image: "/img/social-media/ig.svg",
      link: "https://instagram.com/i8.live",
    },
  ];

  const _LANG_NAME = {
    EN: "English",
    中文: "Chinese",
    BM: "Bahasa Melayu",
    BD: "Bangladesh",
    TH: "Thai",
    VI: "Vietnamese",
    ID: "Indonesia",
  };

  const onChangeLanguage = async (selectedLanguage) => {
    _dispatch(setBusy());
    setSelectedLanguage(
      LanguageOption.find((x) => x.code === selectedLanguage).prefix
    );
    i18n.changeLanguage(selectedLanguage);
    if (!isLoggedIn) {
      _dispatch(saveLanguageId(selectedLanguage, true));
    } else {
      _dispatch(saveLanguageId(selectedLanguage));
      let data = {};
      data["id"] = _userData.userId;
      data["selectedTheme"] = _userData.selectedTheme;
      data["languageSetting"] = selectedLanguage;
      data["isMemberOperate"] = true;

      // Silently update user's language settings
      await ApiEngine.post(
        ApiUrl._API_CREATE_OR_UPDATE_MEMBER,
        createMultiPartFormBody(data)
      );

      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            action: "language",
            language: selectedLanguage,
          })
        );
      }
    }
    _dispatch(setIdle());
  };

  useEffect(() => {
    // const value = LanguageOption.find((item) => localStorage.getItem(SessionKey._LANGUAGE) === item.code );
    if (localStorage.getItem(SessionKey._LANGUAGE)&& localStorage.getItem(SessionKey._LANGUAGE) != 'undefined') {
      if( LanguageOption.find(
        (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)) != undefined) {
          setSelectedLanguage(
            LanguageOption.find(
              (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
            ).prefix
          );
        }
        else{
          setSelectedLanguage("EN");
          localStorage.setItem(SessionKey._LANGUAGE, "en");
        }
    } else {
      setSelectedLanguage("EN");
      localStorage.setItem(SessionKey._LANGUAGE, "en");
    }
  }, []);

  return (
    <>
      <footer id='footer'>
        <div className='container'>
          <div className='row footer-first-row'>
            <div className='col-5'>
              <h1>{t("GAMING_LICENCE")}</h1>
              <LazyLoadImage
                src="/img/pagcorlogo2.svg"
                className='img-responsive'
                alt='pagcor'
                width="140"
                height="30"
              />
              <p className="footer-gld mt-3">{t("GAMING_LICENCE_DETAILS")}</p>
            </div>
            <div className='col-4'>
              <h1>{t("CERTIFICATION")}</h1>
              <LazyLoadImage
                src="/img/footer/certificate.svg"
                className='img-responsive'
                alt='certificate'
                width="260"
                height="80"
              />
            </div>
            <div className='col-3'>
              <h1>{t("SECURITY")}</h1>
              <ul className='list-unstyled footer-payment'>
                {security &&
                  security.length &&
                  map(security, (item, index) => {
                    return (
                      <li key={index}>
                        <LazyLoadImage
                          src={item.image}
                          className='img-responsive'
                          alt={item.alt}
                          width="120"
                          height="30"
                        />
                      </li>
                    );
                  })
                }
              </ul>
            </div>
          </div>
          <div className="row">
            <hr className="footer-hr"/>
          </div>
          <div className='row footer-second-row'>
            <div className='col-5'>
              <h1>{t("PAYMENT_METHOD")}</h1>
              <ul className='list-unstyled payment-method'>
                {paymentMethods &&
                  paymentMethods.length &&
                  map(paymentMethods, (item, index) => {
                    return (
                      <li key={index}>
                        <LazyLoadImage
                                src={item.image}
                                className={item.className}
                                width="120"
                                height="60"
                          alt={item.alt}
                        />
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className='col-4 footer-social-media'>
              <h1>{t("FOLLOW_US")}</h1>
              <ul className='list-unstyled'>
                {socialMedias &&
                  socialMedias.length &&
                  map(socialMedias, (item, index) => {
                    return (
                      <li key={index} className="cursor-pointer">
                        <a onClick={() => window.open(item.link)}>
                          <LazyLoadImage
                            src={item.image}
                            className='img-responsive'
                            alt={item.alt}
                            width="30"
                            height="30"
                          />
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className='col-3'>
              <h1>{t("SUGGESTED_BROWSER")}</h1>
              <ul className='list-unstyled footer-payment'>
                {browser &&
                  browser.length &&
                  map(browser, (item, index) => {
                    return (
                      <li key={index}>
                        <LazyLoadImage
                          src={item.image}
                          className='img-responsive'
                          alt={item.alt}
                          width="30"
                          height="30"
                        />
                      </li>
                    );
                  })
                }
              </ul>
            </div>
          </div>
          <div className="row">
            <hr className="footer-hr"/>
          </div>
          <div className="row">
              <div className="col-12">
                <ul className='list-unstyled f-links'>
                  {aboutMenu &&
                    aboutMenu.length &&
                    map(aboutMenu, (item, index) => {
                      return (
                        <li key={index}>
                          <a href={item.link}>{item.text}</a>
                        </li>
                      );
                    })
                  }
                </ul>
              </div>
          </div>
          <div className='row copy-right'>
            <div className='col' style={{ display: "flex", justifyContent: "space-between" }}>
              <h1 className='font-14'>
                {t("COPYRIGHT")} 
                <LazyLoadImage
                  src="/img/footer/18plus.svg"
                  className='img-responsive'
                  alt="18+"
                  width="17"
                  height="17"
                />
              </h1>
              <div className="version-number">Ver. {process.env.REACT_APP_VERSION}</div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

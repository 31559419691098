import React, { useEffect, useState } from "react";
//import "react-simple-keyboard/build/css/index.css";
import "react-owl-carousel2/src/owl.carousel.css";
import { map } from "lodash";
import Result4D from "components/result-4d";
import Result4DTable from "components/result-4d/table";
import { ApiKey, ApiUrl, SessionKey } from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine";
import {
  setBusy,
  setIdle,
  showResponseMessage,
} from "../../redux/AppAction.js";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import GameMenu from "components/game-list/game-menu";
import {
  _POOL_NAME,
  _TITLE_COLOR,
  _COLOR,
  _POOL_IMAGE,
  _SECTION_TYPE,
  _IS_5D_6D,
} from "config/game-configs";
import moment from "moment";
import DatePicker from "react-datepicker";

const Result = () => {
  const { t } = useTranslation();
  const [scrollTop, setScrollTop] = useState(0);
  const [displayGameMenu, setDisplayGameMenu] = useState(true);
  const [poolDate, setPoolDate] = useState(null);

  const DateCustomInput = ({ value, onClick }) => (
    <input
      className="form-control"
      onClick={onClick}
      value={value}
      type="text"
      readOnly={true}
    />
  );

  useEffect(() => {
    const scrollEvent = () => {
      window.addEventListener("scroll", (e) => {
        setScrollTop(e.target.documentElement.scrollTop);
      });
    };

    scrollEvent();

    return () => {
      window.removeEventListener("scroll", () => {});
    };
  }, []);

  useEffect(() => {
    // console.log(scrollTop);
  }, [scrollTop]);

  useEffect(() => {
    setDisplayGameMenu(false);
    setTimeout(() => setDisplayGameMenu(true), 1);
  }, [(localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
  localStorage.getItem(SessionKey._LANGUAGE) : 'en')]);

  useEffect(() => {
    init();
  }, [poolDate]);

  async function init() {
    _dispatch(setBusy());
    try {
      await getPoolResult();
    } finally {
      _dispatch(setIdle());
    }
    _dispatch(setIdle());
  }

  const [poolResult, setPoolResult] = useState([]);
  var _dispatch = useDispatch();

  async function getPoolResult() {
    var apiUrl = ApiUrl._API_GET_POOL_RESULT;

    if (poolDate != null) {
      apiUrl += "?date=" + moment(poolDate).format("YYYY-MM-DD");
    }

    var responseJson = await ApiEngine.get(apiUrl);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];

      if (data.length > 0) {
        if (poolDate == null)
        setPoolDate(moment(data[0]["drawDateString"], "DD/MM/YYYY").toDate());
        setPoolResult(data);
      } else {
        setPoolResult([]);
      }
    }
  }

  const [resultData, setResultData] = useState([]);
  const [allResultData, setAllResultData] = useState([]);

  useEffect(() => {
    const tempArray = map(poolResult, (item) => {
      return {
        ...item,
        name:
          item.poolKey != "kd"
            ? item.p_4d_1st != ""
              ? _POOL_NAME[item.poolKey] + " 4D"
              : item.p_5d_1st != ""
              ? _POOL_NAME[item.poolKey] + " 5D"
              : _POOL_NAME[item.poolKey] + " 6D"
            : _POOL_NAME[item.poolKey],
        titleColor: _TITLE_COLOR[item.poolKey],
        color: _COLOR[item.poolKey],
        icon: _POOL_IMAGE[item.poolKey],
        type: item.is5D6D
          ? _IS_5D_6D[item.poolKey]
          : _SECTION_TYPE[item.poolKey],
      };
    });

    setResultData(tempArray);
    setAllResultData(tempArray);
  }, [poolResult]);

  function getText(e) {
    const searchItemArray = allResultData.filter(function (item) {
      const regexName = item.name.replace(/ /g, "");
      const regexInputSearch = e.target.value.replace(/ /g, "");

      return regexName.toLowerCase().includes(regexInputSearch.toLowerCase());
    });
    setResultData(searchItemArray);
  }

  return (
    <>
      {displayGameMenu && (
        <>
          <section className="category-section">
            <GameMenu />
          </section>

          <section id="result4d" className="result-section">
            <div className="container background-container">
              <div className="sort-search-box mb-4 align-items-end">
                <div>
                  <div className="mb-3 title-4dresult">
                    {t("LATEST_4D_RESULT")}
                  </div>
                  <div
                    className="mb-0 title-4dresult form-white-search-input"
                    style={{ position: "relative" }}
                  >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <button
                        type="button"
                        className="btn btn-icon"
                        style={{
                          color: "#FFFFFF",
                          position: "absolute",
                          top: "5px",
                          left: "16px",
                          zIndex: "1",
                        }}
                        onClick={() => {
                          setPoolDate(
                            new Date(poolDate.setDate(poolDate.getDate() - 1))
                          );
                        }}
                      >
                        <i className="fa fa-chevron-left"></i>
                      </button>
                    </div>

                    <div
                      className="pool-calendar"
                      style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "center",
                      }}
                    >
                      <DatePicker
                        customInput={<DateCustomInput />}
                        dateFormat="dd/MM/yyyy, EEEE"
                        placeholder={t("SELECT_DATE")}
                        selected={poolDate}
                        onChange={(date) => {
                          setPoolDate(date);
                        }}
                      />
                    </div>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <button
                        type="button"
                        className="btn btn-icon"
                        style={{
                          color: "#FFFFFF",
                          position: "absolute",
                          top: "5px",
                          right: "16px",
                        }}
                        onClick={() =>
                          setPoolDate(
                            new Date(poolDate.setDate(poolDate.getDate() + 1))
                          )
                        }
                      >
                        <i className="fa fa-chevron-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="position-relative">
                  <input
                    type="text"
                    name="search"
                    className="form-white-search-input"
                    placeholder={t("SEARCH")}
                    onInput={(e) => {
                      getText(e);
                    }}
                  />
                  <div className="result4d-search-icon">
                    <img
                      src={"/img/assets/search.svg"}
                      className="img-100"
                      alt="search"
                    />
                  </div>
                </div>
              </div>

              {resultData && resultData.length > 0 && (
                // <div className="template-box">
                  <div className="row">
                    {map(
                      resultData.filter(
                        (x) => x.drawDateString !== "01/01/0001"
                      ),
                      (item, index) => {
                        return (
                          <Result4D
                            key={index}
                            title={item.name}
                            titleColor={item.titleColor}
                            color={item.color}
                            icon={item.icon}
                          >
                            <Result4DTable
                              key={index}
                              color={item.color}
                              type={item.type}
                              titleColor={item.titleColor}
                              poolData={item}
                            />
                          </Result4D>
                        );
                      }
                    )}
                  </div>
                // </div>
              )}

              {(resultData.filter((x) => x.drawDateString !== "01/01/0001").length === 0 ||
                resultData.length == 0) && (
                <div className="row">
                  <div className="col-md-12">
                    <div className="font-thick">{t("NO_DATA_FOUND")}</div>
                  </div>
                </div>
              )}
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Result;

import React, { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiHistory } from "@mdi/js";
import { useSelector, useDispatch } from "react-redux";
import Header from "../../components/custom/Header";
import { ApiKey, ApiUrl, WebUrl, LanguageKey, SessionKey, TransactionType, PanelType } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import ApiEngine from "../../util/ApiEngine.js";
import { createMultiPartFormBody, numberWithCurrencyFormat } from "../../util/Util";
import {
  showResponseMessage,
  setBusy,
  setIdle,
} from "../../redux/AppAction.js";
import { useForm, Controller } from "react-hook-form";
import {
  Modal,
  ModalBody,
  ModalHeader,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  UncontrolledCarousel,
  Carousel as ReactCarousel,
  CarouselItem,
  CarouselIndicators,
  CarouselCaption,
  Alert,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardText,
  CardSubtitle
} from "reactstrap";
import { Card as BTCard, Button, Col, Row } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Spinner from "react-bootstrap/Spinner";
import { keys, first, last } from 'lodash';
import ContentHeader from "../../components/content/header";
import Sidebar from "components/sidebar";
import parse from 'react-html-parser';
import InfoIcon from "../../assets/img/v3/icon.png";
import { updateUserData } from "../../redux/AuthAction";

const LoyaltyRedemption = (props) => {
  var _history = useHistory();
  var _dispatch = useDispatch();
  var _location = useLocation();
  var { t } = useTranslation(LanguageKey._PRIMARY);


  const { handleSubmit, control, watch, setValue } = useForm();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showClaimConfirmation, setShowClaimConfirmation] = useState(false);
  const [loyaltyItemData, setLoyaltyItemData] = useState([]);
  const [data, setData] = useState([]);
  const [memberPoint, setMemberPoint] = useState(0);
  const [unclaimLoyaltyPoints, setUnclaimLoyaltyPoints] = useState(0);
  const [desContent, setDesContent] = useState("");
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [getPointsDone, setGetPointsDone] = useState(false);
  const activeTheme = useSelector(
    (state) => state.authState.userData.selectedTheme
  );
  const userId = useSelector(
    (state) => state.authState.userData.userId
  );
  const loyaltyPoints = useSelector(
    (state) => state.authState.userData.loyaltyPoints
  );

  useEffect(() => {
    init();
  }, [(localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
    localStorage.getItem(SessionKey._LANGUAGE) : 'th')]);


  async function init() {
    _dispatch(setBusy());
    let languageCode = localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ? localStorage.getItem(SessionKey._LANGUAGE) : 'th';
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_ALL_LOYALTY_ITEM + "?languageCode=" + languageCode);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setLoyaltyItemData(responseJson[ApiKey._API_DATA_KEY]);
    }
    getDescriptionModalContent();
    getMemberLoyaltyPoints();
    getUnclaimLoyaltyPoints();
    _dispatch(setIdle());
  };

  const getDescriptionModalContent = async () => {
    _dispatch(setBusy());

    let responseJson = await ApiEngine.get(ApiUrl._API_GET_GAME_POINT_CONTENT + "?gamePointContentId=1&LanguageCode=" + localStorage.getItem(SessionKey._LANGUAGE));
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      responseJson[ApiKey._API_DATA_KEY].map((option) => {
        setDesContent(option["content"]);
      });
      //setDesContent(responseJson[ApiKey._API_DATA_KEY]["content"]);
    }
    _dispatch(setIdle());
  }

  const getMemberLoyaltyPoints = async () => {
    _dispatch(setBusy());
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_LOYALTY_POINTS_BY_ID + "?id=" + userId);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setMemberPoint(responseJson[ApiKey._API_DATA_KEY]);
    }
    _dispatch(setIdle());
    setGetPointsDone(true);
  }

  const getUnclaimLoyaltyPoints = async () => {
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_UNCLAIM_LOYALTY_POINTS
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY]["totalClaimable"];
      setUnclaimLoyaltyPoints(data);
    }
  };




  /// <summary>
  /// Author : -
  /// </summary>
  function preRedeem(e, item) {
    e.stopPropagation();
    setData(item);
    setShowConfirmation(true);
  }

  const handleRedemption = async (data) => {
    _dispatch(setBusy())

    if (data["productType"] === "Voucher") {
      let transactionJson = await ApiEngine.post(
        ApiUrl._API_REDEEM_VOUCHER + "?itemId=" + data.id
      );

      if (transactionJson[ApiKey._API_SUCCESS_KEY]) {
        _dispatch(updateUserData());
        _dispatch(
          showResponseMessage(
            transactionJson[ApiKey._API_SUCCESS_KEY],
            transactionJson[ApiKey._API_MESSAGE_KEY]
          )
        );
        init();
      }
      else {
        _dispatch(
          showResponseMessage(
            transactionJson[ApiKey._API_SUCCESS_KEY],
            transactionJson[ApiKey._API_MESSAGE_KEY]
          )
        );
      }
    }
    else {
      let responseJson = await ApiEngine.post(
        ApiUrl._API_REDEEM_LOYALTY_ITEM + "?itemId=" + data.id
      );

      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        init();
      }
    }
    _dispatch(setIdle());
  };

  function preSubmit() {
    setShowClaimConfirmation(true);
  }

  return (
    <div id="loyalty-redemption">
      {showConfirmation && (
        <SweetAlert
          info
          showCancel
          confirmBtnText={t("CONFIRM")}
          cancelBtnText={t("CANCEL")}
          confirmBtnBsStyle="info"
          title={t("CONFIRM_REDEEM")}
          onConfirm={() => {
            setShowConfirmation(false);
            handleRedemption(data);
          }}
          onCancel={() => {
            setShowConfirmation(false);
          }}
        ></SweetAlert>
      )}
      <section className="section-padding" id="loyalty-section">
        <div className="container">
          <div className="row">
            <div className="col-3" id="account-menu">
              <Sidebar activePage={"GAME_POINTS"} />
            </div>
            <div className="col-9">
              <ContentHeader enableBackButton={ _location.state && _location.state.from ? true: false }title={t("GAME_POINTS_REDEMPTION")} />
              <div className="loyalty-divider"></div>
              <div id="loyalty-redemption-homepage">
                <div style={{ padding: "20px 0" }}>
                  <div className="mb-2">
                    <div style={{ float: "left", marginBottom: "-10px", width: "40%" }}>
                      <img style={{ width: "20px", float: "left" }} src={"/img/v3/claim-ok.png"} alt="game-points" onClick={() => setDescriptionModal(true)} />
                      <div className="game-point-description-lbl" onClick={() => setDescriptionModal(true)} > {parse("&nbsp&nbsp") + t("TURNOVER_CALCULATION")} </div>
                    </div>
                    <div style={{ width: "30%", float: "right" }}>
                    <Link to={WebUrl._URL_LOYALTY_REDEMPTION_HISTORY}>
                      <div className="d-flex justify-content-end align-items-center deposit-record-btn">
                        <div style={{ marginRight: "5px", fontSize: "12px" }}> {t("GAME_POINTS_REDEMPTION_RECORD")} </div>
                        <Icon
                          path={mdiHistory}
                          title="User Profile"
                          size={1}
                        />
                      </div>
                      </Link>
                    </div>
                  </div>
                  <div className="game-points-container mb-3">
                    <div className="first">
                      <div className="custom-icon-box">
                        <img src={"/img/v3/points.svg"} alt="game-points" />
                      </div>
                      <div className="font12 text-white">
                        <b>
                          {t("GAME_POINTS_BALANCE")}
                        </b>
                      </div>
                    </div>


                    <div className="header-currency">
                      <div
                        className="header-wallet-balance"
                        onClick={() =>
                          _history.push(WebUrl._URL_LOYALTY_POINTS_HISTORY)
                        }
                      >
                        <div className="amount">
                          {getPointsDone
                            ? " " +
                            numberWithCurrencyFormat(
                              parseFloat(memberPoint),
                              2
                            )
                            : t("LOADING") + "... "}
                        </div>
                        <div className="tooltip-text">
                          {t("CLICK_TO") +
                            " " +
                            t("VIEW_EARNED_POINTS_HISTORY")}
                        </div>
                      </div>
                    </div>
                  </div>
                  <Row xs={4} md={4} className="g2 mb-1">
                    {loyaltyItemData &&
                      loyaltyItemData.length > 0 &&
                      loyaltyItemData.map((item, index) => {
                        return (
                          <>
                            <Col style={{ padding: "0 7px" }}>
                              <Card className="text-center my-1 game-pts-item-card" border="primary" onClick={() => { _history.push({ pathname: WebUrl._URL_LOYALTY_VOUCHER_DETAILS, state: { id: item.id } }) }} style={{ cursor: "pointer" }}>
                                <img
                                  variant="middle"
                                  src={item.image}
                                  className="game-pts-item-img"
                                  alt="images"
                                />
                                <CardBody className="game-pts-item-card-body">
                                  <CardTitle className="game-pts-item-title">
                                    {item.name}
                                    <img
                                      src={InfoIcon}
                                      className="dektop-info-icon"
                                      alt="info icon"
                                    />
                                  </CardTitle>
                                  <CardText className="game-pts-item-text">
                                    {numberWithCurrencyFormat(item.pointsNeeded, 2, true)}
                                  </CardText>
                                  {item.redeemable === true && item.stock > 0 && memberPoint >= item.pointsNeeded ?
                                    <Button style={{ width: "100%", borderRadius: "10px", color: "#fff" }} variant="primary" onClick={(e) => preRedeem(e, item)}>{t("REDEEM")}</Button>
                                    : item.redeemable === false && item.stock > 0 && (memberPoint > item.pointsNeeded || memberPoint < item.pointsNeeded) ? // claim limit reached
                                      <Button className="loyalty-disabled" style={{ width: "100%", borderRadius: "10px" }} disabled variant="light">{t("REDEEMED")}</Button>
                                      : item.redeemable === true && item.stock > 0 && memberPoint < item.pointsNeeded ? // not enough balance points
                                        <Button className="loyalty-disabled" style={{ width: "100%", borderRadius: "10px" }} disabled variant="light">{t("REDEEM")}</Button>
                                        :
                                        <Button className="loyalty-disabled" style={{ width: "100%", borderRadius: "10px" }} disabled variant="light">{t("OUT_OF_STOCK")}</Button>
                                  }
                                </CardBody>
                              </Card>
                            </Col>
                          </>
                        );
                      })}
                  </Row>

                  <Modal
                    id="custom-modal"
                    contentClassName="modal-brand modal-bottom redeem-modal"
                    isOpen={descriptionModal}
                    toggle={() => {
                      //closeModal();
                    }}
                    centered
                  >
                    <ModalBody>
                      <div className="redeem-modal-body">
                        <img className="testButton" src={"/img/sweetalert_error.png"} alt="test" onClick={() => setDescriptionModal(false)} />
                        {/*<div className="redeem-modal-image">*/}
                        {/*  <img className="testImg" src={require("../../assets/img/detailed-point-exchange-concept_52683-58706.avif")} alt="test" />*/}
                        {/*</div>*/}
                        <div className="gamepoint-content">
                          {parse(desContent)}
                        </div>

                      </div>
                    </ModalBody>

                  </Modal>  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}


export default LoyaltyRedemption;

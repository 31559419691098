import React, { useState, useEffect, useRef } from "react";
import { filter, map, isEmpty, find } from "lodash";
//import { bannerList } from "../../config/game-configs";
import GameListCarousel from "components/game-list/carousel"; 
import GameListGrid from "components/game-list/grid";
import GameListBanner from "components/game-list/banner"; 
import GameListRecommendMatch from "components/game-list/recommend-match";
import PromoBanner from "components/promo-banner/PromoBanner";
import { useSelector } from "react-redux";
import GameMenu from "components/game-list/game-menu";  
import ApiEngine from "../../util/ApiEngine";
import { ApiKey, ApiUrl, SessionKey, _MAIN_WALLET_ID, AlertTypes, LanguageOption } from "../../util/Constant"; 
import { useDispatch } from "react-redux";    
import {
    showResponseMessage,
    setBusy,
    setIdle,
  showMessage,
  setBannerPopupImage,
  setBannerPopupModal
} from "../../redux/AppAction.js"; 
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import {
    createFormBody, numberWithCurrencyFormat, stringIsNullOrEmpty,
} from "../../util/Util";
import PinInput from "react-pin-input";
import {
    Modal,
    ModalBody,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Spinner
} from "reactstrap";
import Keyboard from "react-simple-keyboard";
import Count from "components/timer/Count";
import Slider from "react-slick";
import parse from "html-react-parser";
import moment from "moment";
import Draggable from 'react-draggable';
import LiveChatModal from '../../components/custom/LiveChatModal';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import DownloadApp from 'components/custom/DownloadApp';
import BannerPopupModal from '../../components/custom/BannerPopupModal';

const Home = (props) => {
    const { t, i18n } = useTranslation();
    var _dispatch = useDispatch();
    const [newGames, setNewGames] = useState({});
    const [recommendedGames, setRecommendedGames] = useState({});
    const [displayGameMenu, setDisplayGameMenu] = useState(true);
    const [scrollTop, setScrollTop] = useState(0);
    const [isFilterNewDropdownVisible, setIsFilterNewDropdownVisible] =
        useState(false);
    const [isFilterPopularDropdownVisible, setIsFilterPopularDropdownVisible] =
        useState(false);
    const gameCategories = useSelector((state) =>
        filter(state.gameState.gameCategories, (item) => {
            return item.id > 0;
        })
    );
    const { isLoggedIn } = useSelector((state) => state.authState);
    const _userData = useSelector((state) => state.authState.userData);

    const [memberData, setMemberData] = useState({});
    const elePin = useRef(null);
    const eleConfirm = useRef(null);
    const keyboard = useRef();

    const [showCreateShareholderPin, setShowCreateShareholderPin] =
        useState(false);
    const [invalidPin, setInvalidPin] = useState(false);
    const [pinNumber, setPinNumber] = useState("");
    const [enteredPinNumber, setEnteredPinNumber] = useState("");
    const [pinNumberFocused, setPinNumberFocused] = useState(0);
    const [enteredPinNumberConfirm, setEnteredPinNumberConfirm] = useState("");
    const [pinNumberConfirmFocused, setPinNumberConfirmFocused] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");

    const [pinNumberConfirm, setPinNumberConfirm] = useState("");
    const [loadingMemberFlag, setLoadingMemberFlag] = useState(true);
    const [mainBannerData, setMainBannerData] = useState();
    const [mainBannerImg, setMainBannerImg] = useState("");
    const [sliderBannerList, setSliderBannerList] = useState([]);
    const [promoBannerList, setPromoBannerList] = useState([]);
    const [sportGames, setSportGames] = useState();
    const [sportGameCount, setSportGameCount] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const [gettingNewAndPopularGames, setGettingNewAndPopularGames] = useState(true);
    const [isSportEventUM, setIsSportEventUM] = useState(false);
    const [SBOSportsGameId, setSBOSportsGameId] = useState(0);

    const [displayBanner, setDisplayBanner] = useState(false);
    const bannerSlider = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        lazyLoad: true,
        autoplay: true
    };

    // const sportSlider = {
    //   slidesToShow: 3,
    //   dots: false,
    //   arrows: false,
    //   infinite: true,
    //   autoplay: true,
    //   responsive: [
    //     {
    //       breakpoint: 1200,
    //       settings: {
    //         slidesToShow: 3,
    //       },
    //     },
    //   ],
    // };

    const [bonus, setBonus] = useState(0);
    const [commission, setCommission] = useState(0);
    const [clubFee, setClubFee] = useState(0);
    const [selectedGameName, setSelectedGameName] = useState("");
    const [productGameId, setProductGameId] = useState(0);
    const [masterProductId, setMasterProductId] = useState(0);
    const [walletList, setWalletList] = useState([]);
    const [fromOptions, setFromOptions] = useState([]);
    const [toOptions, setToOptions] = useState([]);
    const [packageNameKeyword, setPackageNameKeyword] = useState("");
    const [isNewWindow, setIsNewWindow] = useState(false);
    const [isApp, setIsApp] = useState(false);
    const [toLobbyBtn, setToLobbyBtn] = useState(false);
    const [appUrl, setAppUrl] = useState("");
    const [appDeepLink, setAppDeepLink] = useState("");
    const [showPregamePopup, setShowPregamePopup] = useState(false);
    const [gameWalletBalanceBeforeTransfer, setGameWalletBalanceBeforeTransfer] =
        useState(0);
    const [mpData, setMpData] = useState([]);
    const [title, setTitle] = useState("");
    const [html, setHtml] = useState("");
    const [theInterval, setTheInterval] = useState(false);
    const [addRightCss, setAddRightCss] = useState("");
    const [dNone, setDNone] = useState("");
    const [showTrigger, setShowTrigger] = useState(true);
    const [cnyModal, setCnyModal] = useState(false);
    const [currentX, setCurrentX] = useState(0);
    const [currentY, setCurrentY] = useState(0);
    const [dragging, setDragging] = useState(false);
    const [cnyEndTime, setCnyEndTime] = useState(moment("2023-02-05 23:59:59:999").format("YYYY-MM-DD HH:mm:ss:SSS"));
    const [currentTime, setCurrentTime] = useState(moment().format("YYYY-MM-DD HH:mm:ss:SSS"));
    const [openMiniGameModal, setOpenMiniGameModal] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [currentActiveTab, setCurrentActiveTab] = useState("COINMINI");
    const [coinMiniGameUrl, setCoinMiniGameUrl] = useState("");
    const [kenoMiniGameUrl, setKenoMiniGameUrl] = useState("");
    const [taiXiuMiniGameUrl, setTaiXiuMiniGameUrl] = useState("");
    const [miniGamesFirstClicked, setMiniGamesFirstClicked] = useState(["COINMINI"]);
    const [showMiniGameTrigger, setShowMiniGameTrigger] = useState(JSON.parse(localStorage.getItem(SessionKey._ISSHOWMINIGAMETRIGGER)));
    const [controlledPosition, setControlledPosition] = useState({});
    const [startGameTime, setStartGameTime] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [games93Connect, setGames93Connect] = useState({});
    const delayTime = 4000;
    const [content, setContent] = useState("");
    const [languageCode, setlanguageCode] = useState();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const [recommendedMatches, setRecommendedMatches] = useState([]);
    const [isSpecialRollover, setIsSpecialRollover] = useState(false);
    const [currentRolloverBalance, setCurrentRolloverBalance] = useState(_userData.rolloverBalance);
    const [rolloverTarget, setRolloverTarget] = useState(0);
    const [playableCategory, setPlayableCategory] = useState([]);
    const [currentWalletBalance, setCurrentWalletBalance] = useState(0);
    const [productCategory, setProductCategory] = useState([]);

    const rolloverFlagInterval = useRef(null);
    const rolloverInterval = useRef(null);
    const tempInterval = useRef(null);

    useEffect(() => {
        getBannerLanguage();
    }, [(localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
        localStorage.getItem(SessionKey._LANGUAGE) : 'th')]);

    const onControlledDrag = (e, position) => {
        const { x, y } = position;
        setControlledPosition({ x, y });
    }
    const toggle = tab => {
        if (currentActiveTab !== tab) {
            if (startGameTime != "") {
                const withinSecond = new Date().getTime() - startGameTime < delayTime;
                if (miniGamesFirstClicked.indexOf(tab) == -1) {
                    if (!withinSecond) {
                        setCurrentActiveTab(tab);
                        setIsLoading(true);
                        startMiniGame(tab, 'd');
                        setTimeout(function () {
                            setMiniGamesFirstClicked([...miniGamesFirstClicked, tab]);
                            setIsLoading(false);
                        }, delayTime);
                    }
                }
                else if (miniGamesFirstClicked.indexOf(currentActiveTab) != -1) {
                    setCurrentActiveTab(tab);
                }
            }
        }
    }

    const [currentWindowWidth, setCurrentWindowWidth] = useState(
        Math.max(document.documentElement.clientWidth || 0,
            window.innerWidth || 0
        )
    );
    const [currentWindowHeight, setCurrentWindowHeight] = useState(
        Math.max(
            document.documentElement.clientHeight || 0,
            window.innerHeight || 0
        )
    );

    // const elementHeader = document.getElementById("header");
    const elementHeader = document.getElementById("header-wrapper");
    const headerHeight = elementHeader.offsetHeight * -1;

    async function submitPin(pin, conf) {
        try {
            let params = {
                pinNumber: pin,
                confirmPinNumber: conf,
            };
            let responseJson = await ApiEngine.post(
                ApiUrl._API_SET_SHAREHOLDER_PIN_NUMBER,
                createFormBody(params)
            );
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setShowCreateShareholderPin(false);
                setInvalidPin(false);
            }
            _dispatch(
                showResponseMessage(
                    responseJson[ApiKey._API_SUCCESS_KEY],
                    responseJson[ApiKey._API_MESSAGE_KEY]
                )
            );

            elePin["current"].clear();
            eleConfirm["current"].clear();
            setPinNumber("");
            setEnteredPinNumber("");
            setPinNumberFocused(0);
            setPinNumberConfirm("");
            setEnteredPinNumberConfirm("");
            setPinNumberConfirmFocused(0);
        } catch (err) {
            _dispatch(showResponseMessage(false, err));
        }
    }

    const adjustFocus = () => {
        if (eleConfirm["current"] !== null) {
            if (
                eleConfirm["current"]["values"][0] == "" ||
                eleConfirm["current"]["values"][0] == undefined
            ) {
                eleConfirm["current"]["elements"][0].focus();
            }
        }
    };

    function onCreatePinKeyPress(value) {
        // 1st: have to know if entering for elePin or eleConfirm
        // 2nd: have to know which index is currently focused for altering the state.value
        // 3rd: have to check if at elePin.index(3), if yes then convert value to string and focus on eleConfirm.index(0)
        // 4th: have to check if at eleConfirm.index(3), if yes then convert value to string, and submitPin

        let currentIndex = 0;
        let pinStr = enteredPinNumber;
        let pinConfirmStr = enteredPinNumberConfirm;
        if (value !== "{bksp}") {
            if (pinNumber === "") {
                currentIndex = pinNumberFocused;
                // elePin["current"]["elements"][currentIndex].state.value = value;
                pinStr = value;
                setEnteredPinNumber(pinStr);
                if (currentIndex !== 3) {
                    currentIndex++;
                    setPinNumberFocused(currentIndex);
                    elePin["current"]["elements"][currentIndex].focus();
                } else {
                    setPinNumber(pinStr);
                }
            } else {
                currentIndex = pinNumberConfirmFocused;
                // eleConfirm["current"]["elements"][currentIndex].state.value = value;
                pinConfirmStr = value;
                setEnteredPinNumberConfirm(pinConfirmStr);
                currentIndex++;
                setPinNumberConfirmFocused(currentIndex);
                if (currentIndex < 3) {
                    eleConfirm["current"]["elements"][currentIndex].focus();
                } else {
                    setPinNumberConfirm(pinConfirmStr);
                    // setTimeout(function () {
                    //   submitPin(pinNumber, pinConfirmStr);
                    // }, 1000);
                }
            }
        } else {
            elePin["current"].clear();
            eleConfirm["current"].clear();
            setPinNumber("");
            setEnteredPinNumber("");
            setPinNumberFocused(currentIndex);
            setPinNumberConfirm("");
            setEnteredPinNumberConfirm("");
            setPinNumberConfirmFocused(currentIndex);
            elePin["current"]["elements"][currentIndex].focus();
        }
    }

    const gameProviders = useSelector((state) =>
        filter(state.gameState.gameProviders, (item) => {
            return item.hasOwnLobby;
        })
    );

    const carouselGameList = useSelector(
        (state) => state.gameState.gameProviders
    );
    const languageId = useSelector((state) => state.languageState.languageId);

    useEffect(() => {
        // const value = LanguageOption.find((item) => localStorage.getItem(SessionKey._LANGUAGE) === item.code );
        if (params.get("languageCode") != null) {
            let tempLangCode = params.get("languageCode");
            i18n.changeLanguage(tempLangCode);
            localStorage.setItem(SessionKey._LANGUAGE, tempLangCode);
            getMarqueeTagContent(tempLangCode);
            setlanguageCode(
                LanguageOption.find(
                    (x) => x.code === tempLangCode
                ).languageId
            );
        }
        else if (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined') {
            if (LanguageOption.find(
                (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)) != undefined) {
                getMarqueeTagContent(localStorage.getItem(SessionKey._LANGUAGE));
                setlanguageCode(
                    LanguageOption.find(
                        (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
                    ).languageId
                );
                i18n.changeLanguage(localStorage.getItem(SessionKey._LANGUAGE));
            }
            else {
                i18n.changeLanguage("id");
                getMarqueeTagContent("id");
                setlanguageCode(7);
                localStorage.setItem(SessionKey._LANGUAGE, "id");
            }
        } else {
            i18n.changeLanguage("id");
            getMarqueeTagContent("id");
            setlanguageCode(5);
            localStorage.setItem(SessionKey._LANGUAGE, "id");
        }
    }, [(localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
        localStorage.getItem(SessionKey._LANGUAGE) : 'id')]);

    useEffect(() => {
        const scrollEvent = () => {
            window.addEventListener("scroll", (e) => {
                setScrollTop(e.target.documentElement.scrollTop);
            });
        };

        scrollEvent();

        return () => {
            //window.removeEventListener("scroll");
        };
    }, []);

    useEffect(() => {
    }, [scrollTop]);

    useEffect(() => {
        //_dispatch(showResponseMessage(false, "Testing Popup"));
        init();
        getSportGames();
        getSportEventUm();
        // setTimeout(() => {
        //   setAddRightCss('move-negative-right');
        // }, 800);

        setTimeout(() => {
            setDNone('d-block');
        }, 1300);

    }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      clearInterval(rolloverFlagInterval.current);
      clearInterval(rolloverInterval.current);
      clearTimeout(tempInterval.current);
      setPlayableCategory([]);
      setRolloverTarget(0);
    }

    if (isLoggedIn) {
      getProductCategory();
      getUserBalance(_userData.walletBalance)
    }

  }, [isLoggedIn])

  useEffect(() => {
    if (isLoggedIn) {
      getMemberSpecialRollover();
      getMemberCurrentRolloverBalance();
      rolloverFlagInterval.current = setInterval(getMemberSpecialRollover, 5000);
      rolloverInterval.current = setInterval(getMemberCurrentRolloverBalance, 5000);
    }
    else {
      // do nothing
    }
  }, [currentWalletBalance])

  useEffect(() => {
    if (isLoggedIn && isSpecialRollover) {
      getRolloverData();
      getBonusDepositProductCategory();
    }
    else {
      clearTimeout(tempInterval.current);
    }
  }, [isSpecialRollover, productCategory])

  useEffect(() => {
    if (isLoggedIn && isSpecialRollover) {
      getRolloverData();
    }
    else {
    }
  }, [currentRolloverBalance])

    const getNewAndPopularGames = async () => {
      setGettingNewAndPopularGames(true);
      //get all new game and recommended game
      let apiUrl =
        ApiUrl._API_GET_IS_NEW_MASTER_PRODUCT_GROUPED_GAME +
        "?languageId=" +
        languageId +
        "&Count=" +
        14;
      // if (isLoggedIn) {
      //   apiUrl += "&memberId=" + _userData.userId;
      // }
      var responseJson = await ApiEngine.get(apiUrl);

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        let newGamesData = responseJson[ApiKey._API_DATA_KEY]["isNewGame"];
        setNewGames(newGamesData);

        let recommendedGamesData =
          responseJson[ApiKey._API_DATA_KEY]["isReccommendedGame"];
        setRecommendedGames(recommendedGamesData);
      } else {
        _dispatch(
          showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
        );
      }
      setTimeout(() => setGettingNewAndPopularGames(false), 1);
    }

    useEffect(() => {
        if (!loadingMemberFlag && isLoggedIn) {
            getMemberInit();
        }
        setShowMiniGameTrigger(JSON.parse(localStorage.getItem(SessionKey._ISSHOWMINIGAMETRIGGER)));
        getNewAndPopularGames();
    }, [isLoggedIn]);

    useEffect(() => {
        if (JSON.parse(localStorage.getItem(SessionKey._ISONLOAD)) === true) {
            localStorage.setItem(SessionKey._ISONLOAD, false);
        }
        else if (JSON.parse(localStorage.getItem(SessionKey._ISONLOAD)) === false) {
            localStorage.setItem(SessionKey._ISSHOWMINIGAMETRIGGER, true);
            setShowMiniGameTrigger(true);
        }
    }, []);

  useEffect(() => {
    get568RecommendedMatches();
    setInterval(get568RecommendedMatches, 30000);
  }, [])


  const get568RecommendedMatches = async () => {
    try {
      let apiUrl = ApiUrl._URL_GET_568_RECOMMENDED_MATCHES + "?languageId=" + (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
        localStorage.getItem(SessionKey._LANGUAGE) : 'en');
      var responseJson = await ApiEngine.get(apiUrl);
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        let data = responseJson[ApiKey._API_DATA_KEY];
        data = data.sort(function (a, b) {
          var dateA = new Date(a.kickOffTimeGMT);
          var dateB = new Date(b.kickOffTimeGMT);
          return dateA - dateB;
        });
        setRecommendedMatches(data);
      } else {
        setRecommendedMatches([]);
      }
    } catch {
      setRecommendedMatches([]);
    }
  }

    window.onbeforeunload = function () {
        localStorage.setItem(SessionKey._ISONLOAD, true);
    };
    async function getMarqueeTagContent(languageId) {
        //"&languageCode=" +
        //    (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
        //        localStorage.getItem(SessionKey._LANGUAGE) : 'en');
        let responseJson = await ApiEngine.get(
            ApiUrl._API_GET_MARQUEE_TAG_CONTENT
            + "?MarqueeTagId=1"
            + "&LanguageCode=" +
            languageId
        );
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            responseJson[ApiKey._API_DATA_KEY].map((option) => {
                setContent(option["content"]);

            });
            //responseJson[ApiKey._API_DATA_KEY];
        }
    }

    async function init() {
        _dispatch(setBusy());
        //get all new game and recommended game
        let apiUrl =
            ApiUrl._API_GET_IS_NEW_MASTER_PRODUCT_GROUPED_GAME_DESKTOP +
            "?languageId=" +
            languageCode +
            "&Count=" +
            14;
        // if (isLoggedIn) {
        //   apiUrl += "&memberId=" + _userData.userId;
        // }
        var responseJson = await ApiEngine.get(apiUrl);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {

            let newGamesData = responseJson[ApiKey._API_DATA_KEY]["isNewGame"];
            setNewGames(newGamesData);

            let recommendedGamesData =
                responseJson[ApiKey._API_DATA_KEY]["isReccommendedGame"];
            setRecommendedGames(recommendedGamesData);
        } else {
            _dispatch(
                showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
            );
        }






        //for fifa world cup
        // var apiUrl2 = ApiUrl._API_GET_WEB_CONTENT + "?Name=FIFA World Cup 2022 Groups";
        // var responseJson2 = await ApiEngine.get(apiUrl2); 
        // if (responseJson2[ApiKey._API_SUCCESS_KEY]) {
        //   const name = responseJson2[ApiKey._API_DATA_KEY]["name"];
        //   const content = responseJson2[ApiKey._API_DATA_KEY]["content"];
        //   setTitle(name);
        //   setHtml(content);
        // }

        let currTime = Date.parse(currentTime);
        let cnyEndTimeNew = Date.parse(cnyEndTime);
        if (currTime > cnyEndTimeNew) {
            setShowTrigger(false);
        }

        _dispatch(setIdle());
    }

    const getSportEventUm = async () => {
      let SBOSports = carouselGameList.filter(x => x.gameName === "SBO Sports");
      if (SBOSports.length > 0) {
        setSBOSportsGameId(SBOSports[0].id);
        if (SBOSports[0].gameUm === true) {
          setIsSportEventUM(true);
        }
      }
    }

    const getBannerLanguage = async () => {
        let apiUrl = ApiUrl._API_GET_USER_BANNER + "?isDesktop=true&isPopup=false&languageCode=" +
            (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
                localStorage.getItem(SessionKey._LANGUAGE) : 'id');

        // localStorage.getItem(SessionKey._LANGUAGE);
        let responseJson = await ApiEngine.get(apiUrl);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data1 = responseJson[ApiKey._API_DATA_KEY]["banners"];
            if (data1.filter(x => x.displayDevice === 0).length > 0) {
                let tempMainBannerData = data1.filter(x => x.displayDevice === 0);
                // let tempMainBannerData = data1.filter(x => x.displayDevice === 0)[0];
                // setMainBannerData(tempMainBannerData);
                // setMainBannerImg(tempMainBannerData.desktopImage);
                if (tempMainBannerData.length > 0) {
                    setMainBannerData(tempMainBannerData);
                }
                else {
                    setMainBannerData();
                }
            }
            else {
                setMainBannerData();
            }
            let tempSliderBannerList = [];
            if (data1.filter(x => x.displayDevice === 1).length > 0) {
                data1.filter(x => x.displayDevice === 1).map((banner) => {
                    tempSliderBannerList.push({ image: banner.desktopImage, url: banner.url });
                });
            }
            setSliderBannerList(tempSliderBannerList);
            let data2 = responseJson[ApiKey._API_DATA_KEY]["promoBanners"];
            setPromoBannerList(data2);
            let data3 = responseJson[ApiKey._API_DATA_KEY]["popupBanners"];
            if (data3 != null) {
              _dispatch(setBannerPopupImage(data3));
              _dispatch(setBannerPopupModal(true));
            } else {
              _dispatch(setBannerPopupImage(""));
              _dispatch(setBannerPopupModal(false));
            }
            setIsLoaded(true);
        }
    }

    const getSportGames = async () => {
        let responseJson = await ApiEngine.get(ApiUrl._API_GET_PRODUCT_GAME_LIST_BY_CATEGORY_ID + "?categoryName=Sports&languageCode=" + localStorage.getItem(SessionKey._LANGUAGE));
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data = responseJson[ApiKey._API_DATA_KEY];
            let tempSportGames = [];
            data.filter(x => !stringIsNullOrEmpty(x['desktopImage'])).map((row, index) => {
                tempSportGames.push(row);
            });
            ///* currently number of slides is equal to slidesToShow, need to add the same slides one more time for the sliding to work */
            //data.filter(x => !stringIsNullOrEmpty(x['desktopImage'])).map((row, index) => {
            //  tempSportGames.push(row);
            //});
            setSportGames(tempSportGames);
            setSportGameCount(tempSportGames.length);
        }
    }

    //pass filter data
    async function handleFilterByProvider(id, count, type) {
        let apiUrl =
            ApiUrl._API_GET_IS_NEW_MASTER_PRODUCT_GROUPED_GAME_DESKTOP +
            "?id=" +
            id +
            "&languageId=" +
            languageCode +
            "&Count=" +
            count;
        // if (isLoggedIn) {
        //   apiUrl += "&memberId=" + _userData.userId;
        // }
        var responseJson = await ApiEngine.get(apiUrl);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            if (type === "newGames") {
                let newGamesData = responseJson[ApiKey._API_DATA_KEY]["isNewGame"];

                setNewGames(newGamesData);
                if (isFilterNewDropdownVisible) {
                    setIsFilterNewDropdownVisible(!isFilterNewDropdownVisible);
                }
            } else if (type === "popularGames") {
                let recommendedGamesData =
                    responseJson[ApiKey._API_DATA_KEY]["isReccommendedGame"];
                setRecommendedGames(recommendedGamesData);
                if (isFilterPopularDropdownVisible) {
                    setIsFilterPopularDropdownVisible(!isFilterPopularDropdownVisible);
                }
            }
        } else {
            _dispatch(
                showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
            );
        }
    }

    async function getMemberInit() {
        let member = [];
        try {
            let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_DETAILS_BY_ID);
            // setLoadingMemberFlag(false);
            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }
            setMemberData(responseJson[ApiKey._API_DATA_KEY]);
            member = responseJson[ApiKey._API_DATA_KEY];

            setLoadingMemberFlag(true);
            let params = {};
            if (member["isShareHolder"] === true) {
                responseJson = await ApiEngine.post(
                    ApiUrl._API_CHECK_SHAREHOLDER_PIN_NUMBER,
                    createFormBody(params)
                );
                if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                    var theInterval = setInterval(() => {
                        if (elePin["current"] == undefined) return;

                        if (
                            elePin["current"]["values"][0] == "" ||
                            elePin["current"]["values"][0] == undefined
                        ) {
                            elePin["current"]["elements"][0].focus();
                        } else if (
                            elePin["current"]["values"][1] == "" ||
                            elePin["current"]["values"][1] == undefined
                        ) {
                            elePin["current"]["elements"][1].focus();
                        } else if (
                            elePin["current"]["values"][2] == "" ||
                            elePin["current"]["values"][2] == undefined
                        ) {
                            elePin["current"]["elements"][2].focus();
                        } else if (
                            elePin["current"]["values"][3] == "" ||
                            elePin["current"]["values"][3] == undefined
                        ) {
                            elePin["current"]["elements"][3].focus();
                        } else if (
                            eleConfirm["current"]["values"][0] == "" ||
                            eleConfirm["current"]["values"][0] == undefined
                        ) {
                            eleConfirm["current"]["elements"][0].focus();
                        } else if (
                            eleConfirm["current"]["values"][1] == "" ||
                            eleConfirm["current"]["values"][1] == undefined
                        ) {
                            eleConfirm["current"]["elements"][1].focus();
                        } else if (
                            eleConfirm["current"]["values"][2] == "" ||
                            eleConfirm["current"]["values"][2] == undefined
                        ) {
                            eleConfirm["current"]["elements"][2].focus();
                        } else if (
                            eleConfirm["current"]["values"][3] == "" ||
                            eleConfirm["current"]["values"][3] == undefined
                        ) {
                            eleConfirm["current"]["elements"][3].focus();
                        } else {
                            var currpin =
                                elePin["current"]["values"][0] +
                                elePin["current"]["values"][1] +
                                elePin["current"]["values"][2] +
                                elePin["current"]["values"][3];
                            var currconfpin =
                                eleConfirm["current"]["values"][0] +
                                eleConfirm["current"]["values"][1] +
                                eleConfirm["current"]["values"][2] +
                                eleConfirm["current"]["values"][3];
                            if (currpin != currconfpin) {
                                setInvalidPin(true);
                                elePin["current"].clear();
                                eleConfirm["current"].clear();
                                elePin["current"].focus();
                            } else {
                                setInvalidPin(false);
                                clearInterval(theInterval);
                                submitPin(currpin, currconfpin);
                            }
                        }
                    }, 50);
                    setTheInterval(theInterval);
                    setShowCreateShareholderPin(true);
                }
            }

            // await _dispatch(checkIsLoggedIn());
        } catch (errorMessage) {
            _dispatch(showResponseMessage(false, errorMessage));
        } finally {
            _dispatch(setIdle());
        }
    }

    async function startGame(
        gameId,
        hasOwnLobby,
        masterProductId,
        gameName,
        isSeamless,
        isApp,
        appUrl,
        appDeepLink,
        isNewWindow,
        categoryTitle,
        packageNameKeyword
    ) {
        setSelectedGameName(gameName);
        setMasterProductId(masterProductId);
        setIsApp(isApp);
        setAppDeepLink(appDeepLink);
        setPackageNameKeyword(packageNameKeyword);
        if (isApp && !stringIsNullOrEmpty(appUrl)) {
            setAppUrl(appUrl);
        }
        let productCategoryListArr = [...playableCategory];

        if (!isSpecialRollover || (isSpecialRollover && productCategoryListArr.includes(categoryTitle))) {
            if (hasOwnLobby !== true) {
                if (isSeamless == false) {
                    _dispatch(setBusy());
                    var responseJson = await ApiEngine.get(
                        ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE +
                        "?masterProductId=" +
                        masterProductId
                    );
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        setWalletList(responseJson[ApiKey._API_DATA_KEY]);

                        let tempFromOptions = [];
                        let tempToOptions = [];

                        responseJson[ApiKey._API_DATA_KEY].map((product) => {
                            let option = {
                                label: product.productName,
                                value: product.productId,
                                amount: parseFloat(product.balance),
                            };
                            if (product.productId === _MAIN_WALLET_ID) {
                                tempFromOptions.push(option);
                            } else if (product.productId === masterProductId) {
                                setBonus(product.bonus);
                                setCommission(product.commission);
                                if (product.productName === "D2 Poker") {
                                    setClubFee(product.clubFee);
                                }
                                setGameWalletBalanceBeforeTransfer(product.balance);
                                // if (product.masterProductContent !== null) {
                                //   setMasterProductContent(product.masterProductContent);
                                // } else {
                                //   setMasterProductContent("");
                                // }
                                tempToOptions.push(option);
                            }
                        });
                        setFromOptions(tempFromOptions);
                        setToOptions(tempToOptions);

                        var mpJson = await ApiEngine(
                            ApiUrl._API_GET_MEMBER_PRODUCT_DETAILS +
                            "?masterProductId=" +
                            masterProductId
                        );
                        if (mpJson[ApiKey._API_SUCCESS_KEY]) {
                            let data = mpJson[ApiKey._API_DATA_KEY];
                            setMpData(data);
                            if (window.ReactNativeWebView && isApp) {
                                window.ReactNativeWebView.postMessage(
                                    JSON.stringify({
                                        action: "deeplink",
                                        url: appUrl,
                                        deeplink: appDeepLink
                                            .replace("[username]", data["username"])
                                            .replace("[password]", data["password"]),
                                        packageKeyword: packageNameKeyword,
                                    })
                                );
                            }
                        }
                    }
                    setProductGameId(gameId);
                    var isNewWindowJson = await ApiEngine(
                        ApiUrl._API_GET_GAME_IS_NEW_WINDOW + "?gameId=" + gameId
                    );
                    // setShowPregamePopup(true);

                    setIsNewWindow(isNewWindowJson[ApiKey._API_DATA_KEY]);
                    setShowPregamePopup(true);

                    _dispatch(setIdle());
                } else {
                    window.open(
                        `${process.env.REACT_APP_ENDPOINT_URL || ""}${ApiUrl._API_START_GAME
                        }?gameId=${gameId}&isApp=${isApp ? 1 : 0}&device=d`
                    );
                }
            } else {
                if (isSeamless == false) {
                    _dispatch(setBusy());
                    var responseJson = await ApiEngine.get(
                        ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE +
                        "?masterProductId=" +
                        masterProductId
                    );
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        setWalletList(responseJson[ApiKey._API_DATA_KEY]);

                        let tempFromOptions = [];
                        let tempToOptions = [];

                        responseJson[ApiKey._API_DATA_KEY].map((product) => {
                            let option = {
                                label: product.productName,
                                value: product.productId,
                                amount: parseFloat(product.balance),
                            };
                            if (product.productId === _MAIN_WALLET_ID) {
                                tempFromOptions.push(option);
                            } else if (product.productId === masterProductId) {
                                setBonus(product.bonus);
                                setCommission(product.commission);
                                if (product.productName === "D2 Poker") {
                                    setClubFee(product.clubFee);
                                }
                                setGameWalletBalanceBeforeTransfer(product.balance);
                                // if (product.masterProductContent !== null) {
                                //   setMasterProductContent(product.masterProductContent);
                                // } else {
                                //   setMasterProductContent("");
                                // }
                                tempToOptions.push(option);
                            }
                        });
                        setFromOptions(tempFromOptions);
                        setToOptions(tempToOptions);

                        var mpJson = await ApiEngine(
                            ApiUrl._API_GET_MEMBER_PRODUCT_DETAILS +
                            "?masterProductId=" +
                            masterProductId
                        );
                        if (mpJson[ApiKey._API_SUCCESS_KEY]) {
                            let data = mpJson[ApiKey._API_DATA_KEY];
                            setMpData(data);
                        }
                    }

                    var isNewWindowJson = await ApiEngine(
                        ApiUrl._API_GET_GAME_IS_NEW_WINDOW + "?gameId=" + gameId
                    );
                    setIsNewWindow(isNewWindowJson[ApiKey._API_DATA_KEY]);
                    setShowPregamePopup(true);
                    setProductGameId(gameId);
                    // setShowPregamePopup(true);
                    setToLobbyBtn(true);
                    _dispatch(setIdle());
                }
            }
        }
        else {
            _dispatch(
                showMessage({
                    type: AlertTypes._INFO,
                    content: t("ROLLOVER_EXIST"),
                    onConfirm: () => {
                    },
                })
            );
        }
    }


    useEffect(() => {
        setDisplayGameMenu(false);
        setTimeout(() => { setDisplayGameMenu(true); setLoadingMemberFlag(false); }, 1);

    }, [(localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
        localStorage.getItem(SessionKey._LANGUAGE) : 'th')]);

    const handleDraggingStart = (e, platform) => {
        e.target.style.opacity = 0.5;

    }

    // const setMousePosition = (e, platform) => {
    //   // const currentWidthWithButton = currentWindowWidth - 25;
    //   if (platform === 'desktop') {
    //     // console.log(e.clientX);
    //     setCurrentX(e.clientX - 13.5);
    //     setCurrentY(e.clientY - 25);
    //     if (e.clientX > currentWindowWidth || e.clientX < 0 || e.clientY > currentWindowHeight || e.clientY < 0) {
    //       setCurrentX(currentWindowWidth - 25);
    //       setCurrentY(50);
    //     }
    //   }
    //   else if (platform === 'mobile') {
    //     setCurrentX(e.changedTouches[0].clientX - 13.5);
    //     setCurrentY(e.changedTouches[0].clientY - 25);
    //     //setCurrentX(e.touches[0].clientX - 10.5);
    //     //setCurrentY(e.touches[0].clientY - 21);
    //     if (e.changedTouches[0].clientX > currentWindowWidth || e.changedTouches[0].clientX < 0 || e.changedTouches[0].clientY > currentWindowHeight || e.changedTouches[0].clientY < 0) {
    //       setCurrentX(currentWindowWidth - 25);
    //       setCurrentY(50);
    //     }
    //   }
    //   // console.log(e);
    //   // console.log(e.changedTouches[0].clientX);
    //   // console.log(e.changedTouches[0].clientY);
    // }

    async function startMiniGame(miniGameCode, device) {

        let languageCode = localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
        localStorage.getItem(SessionKey._LANGUAGE) : 'th';

        // mini game have their own lang id
        let miniGameLangId = 0;

        if (languageCode == "en" || languageCode == "id") {
            miniGameLangId = 0;
        }
        else if (languageCode == "zh") {
            miniGameLangId = 1;
        }
        else if (languageCode == "ms") {
            miniGameLangId = 2;
        }
        else if (languageCode == "vi") {
            miniGameLangId = 3;
        }
        else if (languageCode == "th") {
            miniGameLangId = 4;
        }

        setStartGameTime("");
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_MINI_GAME_URL +
            "?miniGameCode=" + miniGameCode +
            "&device=" + device + 
            "&languageId=" + miniGameLangId 
        );
        if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
            throw responseJson[ApiKey._API_MESSAGE_KEY];
        }
        else {
            let data = responseJson[ApiKey._API_DATA_KEY];
            if (miniGameCode == "COINMINI") {
                setCoinMiniGameUrl(data);
                setStartGameTime(moment());
                setTimeout(function () {
                    setIsLoading(false);
                }, 3000);
            }
            else if (miniGameCode == "KENOMINI") {
                setKenoMiniGameUrl(data);
                setStartGameTime(moment());
            }
            else {
                setTaiXiuMiniGameUrl(data);
                setStartGameTime(moment());
            }
        }
  }

  async function startGame568SportsBook(matchId) {
    if (isLoggedIn) {
      _dispatch(setBusy());
      if (isSportEventUM || SBOSportsGameId === 0) {
        _dispatch(
          showMessage({
            type: AlertTypes._INFO,
            content: t("GAME_UNDER_MAINTENANCE"),
            onConfirm: () => {
            },
          })
        );
      } else {
        if (isSpecialRollover) {
          let productCategoryListArr = [...playableCategory];
          if (productCategoryListArr.includes("Sports")) {
            window.open(ApiUrl._API_START_GAME + "?gameId=" + SBOSportsGameId + "&isApp=0&device=d&matchId=" + matchId);
            //const result = await ApiEngine(
            //  ApiUrl._API_GET_MEMBER_PRODUCT_DETAILS_SPORTS_BOOK +
            //  "?matchId=" + matchId + "&device=m"
            //);

            //if (result[ApiKey._API_SUCCESS_KEY]) {
            //  const data = result[ApiKey._API_DATA_KEY];


            //  if (window.ReactNativeWebView) {
            //    window.ReactNativeWebView.postMessage(
            //      JSON.stringify({
            //        url: data,
            //      })
            //    );
            //  } else {
            //    if (isSafari == true) {
            //      setSportEventUrl(data);
            //      setIsSportEvent(true);
            //    } else {
            //      window.open(data);
            //    }
            //  }
            //}
          }
          else {
            _dispatch(
              showMessage({
                type: AlertTypes._INFO,
                content: t("ROLLOVER_EXIST"),
                onConfirm: () => {
                },
              })
            );
          }
        }
        else {
          window.open(ApiUrl._API_START_GAME + "?gameId=" + SBOSportsGameId + "&isApp=0&device=d&matchId=" + matchId);
          //const result = await ApiEngine(
          //  ApiUrl._API_GET_MEMBER_PRODUCT_DETAILS_SPORTS_BOOK +
          //  "?matchId=" + matchId + "&device=m"
          //);

          //if (result[ApiKey._API_SUCCESS_KEY]) {
          //  const data = result[ApiKey._API_DATA_KEY];


        }
      }
      _dispatch(setIdle());
    }
    else {
      _dispatch(
        showMessage({
          type: AlertTypes._INFO,
          content: t("PLEASE_LOGIN_FIRST"),
          onConfirm: () => {
          },
        })
      );
    }
  }

  const getMemberSpecialRollover = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_IS_SPECIAL_ROLLOVER);
    let data = "";

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      data = responseJson[ApiKey._API_DATA_KEY];
      setIsSpecialRollover(data);

      if (data === false) {
        clearInterval(rolloverFlagInterval.current);
        clearInterval(rolloverInterval.current);
        setPlayableCategory([]);
        setRolloverTarget(0);
      }
    }
  }

  const getBonusDepositProductCategory = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_BONUS_DEPOSIT_PRODUCT_CATEGORY);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      let productCategoryArr = [];

      if (data.includes(",")) {
        productCategoryArr = responseJson[ApiKey._API_DATA_KEY].split(",");
      }
      else {
        productCategoryArr.push(data);
      }

      let tempArr = [];
      productCategory.forEach((x) => {
        if (productCategoryArr.includes(x.value)) {
          tempArr.push(x.originalTitle);
        }
      })

      setPlayableCategory(tempArr);
    }
  }

  const getMemberCurrentRolloverBalance = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_CURRENT_ROLLOVER_BALANCE);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      setCurrentRolloverBalance(data);
    }
  }

  async function getRolloverData() {
    var responseJson = await ApiEngine.get(ApiUrl._API_GET_ROLLOVER_RECORD);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      setRolloverTarget(data[0].rolloverTarget);
    }
  }

  const getUserBalance = async () => {
    let walletResponse = await ApiEngine.get(ApiUrl._API_GET_MEMBER_WALLET_BALANCE);
    if (walletResponse[ApiKey._API_SUCCESS_KEY]) {
      let data = walletResponse[ApiKey._API_DATA_KEY];
      setCurrentWalletBalance(data["primaryCredit"]);
      if (data["count"] == false) {
        setBalanceTimeout(30000);
      }
      else {
        setBalanceTimeout(5000);
        clearInterval(rolloverFlagInterval.current);
        clearInterval(rolloverInterval.current);
      }
    }
  }

  function setBalanceTimeout(mmTimer) {
    clearTimeout(tempInterval.current);
    tempInterval.current = setTimeout(() => {
      getUserBalance();
    }, mmTimer);
  }

  const getProductCategory = async () => {
    let tempArr = [];
    var productCategoryJson = await ApiEngine.get(ApiUrl._API_GET_PRODUCT_CATEGORY);
    if (productCategoryJson[ApiKey._API_SUCCESS_KEY]) {
      let data = productCategoryJson[ApiKey._API_DATA_KEY];
      data.forEach(x => {
        if (x.id > 0) {
          tempArr.push({ value: x.id.toString(), label: x.title, originalTitle: x.originalTitle });
        }
      });
      setProductCategory(tempArr);
    }
  }


    return (
        <>
        <LiveChatModal />
            {openMiniGameModal && (
                <div className="cny-video">
                    <div className="cny-video-box">
                        <div className="mini-games-modal">
                            <div className="mini-games-dialog">
                                <div className="mini-games-header">
                                    <img
                                        src={"/img/mini-games/header/web_"
                                        + (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined'
                                        ? localStorage.getItem(SessionKey._LANGUAGE)
                                        : 'en')
                                    + ".png"}
                                    />
                                    <button className="mini-close-button"
                                        onClick={() => {
                                            setOpenMiniGameModal(false);
                                            setCoinMiniGameUrl("");
                                            setKenoMiniGameUrl("");
                                            setTaiXiuMiniGameUrl("");
                                            setCurrentActiveTab("COINMINI");
                                            setMiniGamesFirstClicked(["COINMINI"]);
                                            setIsLoading(true);
                                        }}
                                    ></button>
                                </div>
                                <div className="mini-games-body">
                                    <Nav tabs className="mini-games-tab">
                                        <NavItem>
                                            <NavLink
                                                className={
                                                    "mini-games-items " +
                                                    classnames({
                                                        selected:
                                                            currentActiveTab === "KENOMINI"
                                                    })}
                                                onClick={() => {
                                                    toggle("KENOMINI");
                                                }}
                                            >
                                                <img
                                                    src={"/img/mini-games/gameIcon/KenoMini-Icon.png"}
                                                    loading="lazy" />
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={
                                                    "mini-games-items " +
                                                    classnames({
                                                        selected:
                                                            currentActiveTab === "COINMINI"
                                                    })}
                                                onClick={() => {
                                                    toggle("COINMINI");
                                                }}
                                            >
                                                <img
                                                    src={"/img/mini-games/gameIcon/CoinMini.png"}
                                                    loading="lazy" />
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={
                                                    "mini-games-items " +
                                                    classnames({
                                                        selected:
                                                            currentActiveTab === 'TAIXIUMINI'
                                                    })}
                                                onClick={() => {
                                                    toggle('TAIXIUMINI');
                                                }}
                                            >
                                                <img
                                                    src={"/img/mini-games/gameIcon/TaiXiumini.png"}
                                                    loading="lazy" />
                                            </NavLink>
                                        </NavItem>
                                    </Nav>

                                    <TabContent
                                        activeTab={currentActiveTab}
                                        className="mini-games-section"
                                    >
                                        <TabPane
                                            tabId="KENOMINI"
                                            className="mini-games-content"
                                            style={currentActiveTab === 'KENOMINI' ? { display: "block !important" } : { display: "none !important", opacity: 0 }
                                            }>
                                            <div id="KENOMINI" className="mini-games-content">
                                                {
                                                    isLoading &&
                                                    <div className="loading">
                                                        <Spinner animation="border" role="status"></Spinner>
                                                    </div>
                                                }
                                                <iframe
                                                    className="iframe"
                                                    loading="lazy"
                                                    frameBorder="0"
                                                    width="248"
                                                    height="227"
                                                    title="Keno Mini"
                                                    src={kenoMiniGameUrl}
                                                >
                                                </iframe>
                                            </div>
                                        </TabPane>
                                        <TabPane
                                            tabId="COINMINI"
                                            className="mini-games-content"
                                            style={currentActiveTab === 'COINMINI' ? { display: "block !important" } : { display: "none !important", opacity: 0 }}>
                                            <div id="COINMINI" className="mini-games-content">
                                                {
                                                    isLoading &&
                                                    <div className="loading">
                                                        <Spinner animation="border" role="status"></Spinner>
                                                    </div>
                                                }
                                                <iframe
                                                    className="iframe"
                                                    loading="lazy"
                                                    width="248"
                                                    height="227"
                                                    title="Coin Mini"
                                                    src={coinMiniGameUrl}
                                                >
                                                </iframe>
                                            </div>
                                        </TabPane>
                                        <TabPane
                                            tabId="TAIXIUMINI"
                                            className="mini-games-content"
                                            style={currentActiveTab === 'TAIXIUMINI' ? { display: "block !important" } : { display: "none !important", opacity: 0 }}>
                                            <div id="TAIXIUMINI" className="mini-games-content">
                                                {
                                                    isLoading &&
                                                    <div className="loading">
                                                        <Spinner animation="border" role="status"></Spinner>
                                                    </div>
                                                }
                                                <iframe
                                                    className="iframe"
                                                    loading="lazy"
                                                    frameBorder="0"
                                                    width="248"
                                                    height="227"
                                                    title="TaiXiu Mini"
                                                    src={taiXiuMiniGameUrl}
                                                >
                                                </iframe>
                                            </div>
                                        </TabPane>
                                    </TabContent>
                                    <div className="more-game-img"
                                        onClick={() => {
                                            if (!isEmpty(games93Connect)) {
                                                startGame(
                                                    games93Connect["id"],
                                                    games93Connect["hasOwnLobby"],
                                                    games93Connect["masterProductId"],
                                                    games93Connect["gameName"],
                                                    games93Connect["masterProductIsSeamless"],
                                                    games93Connect["isApp"],
                                                    games93Connect["appUrl"],
                                                    games93Connect["appDeepLink"],
                                                    games93Connect["isNewWindow"],
                                                    games93Connect["categoryTitle"]
                                                );
                                            }
                                        }}
                                    >
                                        <img
                                            src={"/img/mini-games/moreGames/more_games_"
                                            + (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined'
                                            ? localStorage.getItem(SessionKey._LANGUAGE)
                                            : 'en')
                                        + ".png"} 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showMiniGameTrigger &&
                <>
                    <Draggable
                        bounds={{ left: 0, top: headerHeight + 50, right: 0, bottom: 200 }}
                        onDrag={(e, position) => {
                            onControlledDrag(e, position);
                            setIsDragging(true);
                        }}
                        defaultPosition={{ x: 0, y: 0 }}
                        onStop={() => {
                            if (!isDragging) {
                                if (isLoggedIn) {
                                    setOpenMiniGameModal(true);
                                    startMiniGame(currentActiveTab, "d");
                                    setGames93Connect(carouselGameList.filter(x => x.gameName === "93Connect")[0]);
                                }
                                else if (!isLoggedIn) {
                                    _dispatch(
                                        showMessage({
                                            type: AlertTypes._INFO,
                                            content: t("PLEASE_LOGIN_FIRST"),
                                        })
                                    );
                                }
                            }
                            else {
                                setIsDragging(false);
                            }
                        }}
                    >
                        <div className="cny-pop-trigger">
                            <img
                                draggable="false"
                                src={"/img/mini-games/gif/web_"
                                + (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined'
                                ? localStorage.getItem(SessionKey._LANGUAGE)
                                : 'en')
                              + ".png"}
                                width="250"
                                height="270"
                                alt="mini game"
                            />
                        </div>
                    </Draggable>

                    <Draggable
                        bounds="body"
                        position={controlledPosition}
                        onStop={() => {
                            setShowMiniGameTrigger(false);
                            localStorage.setItem(SessionKey._ISSHOWMINIGAMETRIGGER, false);
                        }}
                    >
                        <div className="cny-pop-trigger">
                            <div className={'cny-trigger-close'}>
                                <img
                                    draggable="false"
                                    src={"/img/Close_Icon.svg"} style={{ width: "20px" }}
                                    width="20"
                                    height="20"
                                    alt="close button"
                                />
                            </div>
                        </div>
                    </Draggable>
                </>
            }

            {/*cnyModal && (
        <div className="cny-video">
          <div className="cny-video-box">
            <div
              className="cny-video-close"
              onClick={() => {
                setCnyModal(false);
              }}>
                <img src={require("../../assets/img/cny/Close_Icon.svg")} />
            </div>
            <video src={(localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) === "zh") ? require("../../assets/img/cny/i8_cny_video_zh.mp4") : require("../../assets/img/cny/i8_cny_video_en.mp4")} autoPlay="true" width="100%" loop="true" />
          </div>
        </div>
      )*/}
            <section id="home-banner" className="position-relative">
                {mainBannerData && mainBannerData.length <= 0 ?
                    <div className="category-banner">
                        <LazyLoadImage
                            src={mainBannerData[0].desktopImage}
                            style={displayBanner ? {} : { display: "none" }}
                            className="img-100 banner-img-radius"
                            alt="banner-main"
                            onLoad={() => setDisplayBanner(true)}
                            width="1600"
                            height="900"
                        />
                    </div>
                    :
                    <div className="banner-slider">
                        <Slider {...bannerSlider}>
                            {mainBannerData &&
                                mainBannerData.length &&
                                map(mainBannerData, (item, index) => {
                                    return (
                                        <div key={index} className="bs-item cursor-pointer">
                                            <LazyLoadImage
                                                src={item.desktopImage}
                                                className="img-100 banner-img-radius"
                                                alt="banner"
                                                onClick={() => {
                                                    if (isLoggedIn) {
                                                        window.open(item.url)
                                                    }
                                                    else if (!isLoggedIn) {
                                                        _dispatch(
                                                            showMessage({
                                                                type: AlertTypes._INFO,
                                                                content: t("PLEASE_LOGIN_FIRST"),
                                                            })
                                                        );
                                                    }
                                                }
                                                }
                                                width="1600"
                                                height="900"
                                            />
                                        </div>
                                    );
                                })}
                        </Slider>
                    </div>
                }
            </section>
            <section className="marquee-tag-backgroud">
                <div className="marquee-tag">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="marquee-tag-div">
                                    <img
                                        className="image"
                                        src={"/img/mic.svg"}
                                        alt="marquee tag"
                                    />
                                    <marquee width="95%" direction="left" scrollamount="3">
                                        {parse(content)}
                                    </marquee>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="category-section">
                <GameMenu isHome />
            </section>

            {isSpecialRollover && productCategory && rolloverTarget !== 0 && currentRolloverBalance !== 0 ?
              <section>
                <div className="special-rollover-container container">
                  <div className="text-n-icon">
                    <h1>{t("REMAINING_ROLLOVER")}</h1>
                    <div className="icon-container">
                      {productCategory.map((item, index) => {
                        return (
                          <div className={`inner-container ${[...playableCategory].includes(item.originalTitle) ? "playable" : ""}`}>
                            <img
                              src={'/img/assets/game-category/svg/'+ item.originalTitle +'.svg'}
                              className="img-responsive"
                              alt={item.label + " image"}
                              width="30"
                              height="30"
                            />
                            <span style={{ color: "#FFD928", fontSize: "13px", fontWeight: "700", letterSpacing: "1px" }}>{item.label}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="progress-bar-container">
                    <div className="progress-bar-outline">
                      <span style={{ position: "absolute", textShadow: "2px 2px 5px black" }}>{numberWithCurrencyFormat((Math.abs(rolloverTarget - currentRolloverBalance) / (currentRolloverBalance + (rolloverTarget - currentRolloverBalance))) * 100, 2, true)}%</span>
                      <div className="progress-bar-fill"
                        style={{
                          width: `${(Math.abs(rolloverTarget - currentRolloverBalance) / (currentRolloverBalance + (rolloverTarget - currentRolloverBalance))) * 100}%`
                        }}
                      >
                      </div>
                    </div>
                  </div>
                  <div className="rollover-text">
                    {t("AMOUNT")}: {numberWithCurrencyFormat(Math.abs(rolloverTarget - currentRolloverBalance), 2, true) + " / " + numberWithCurrencyFormat((currentRolloverBalance + (rolloverTarget - currentRolloverBalance)), 2, true)} <span style={{ color: "#FFD928" }}>IDR</span>
                  </div>
                </div>
              </section>
              :
              <></>
            }

            {displayGameMenu && (
          <>
            {<section>
              <PromoBanner promoBannerList={promoBannerList}/>
            </section>}
            {recommendedMatches.length > 0 && <section className="upcoming-live-match-section section-padding pb-0 mb-5">
              <GameListRecommendMatch recommendedMatches={recommendedMatches} startGame568SportsBook={startGame568SportsBook} />
            </section>}
                    <section className="newgames-section section-padding pb-0 mb-5">
                        <GameListCarousel
                            title={t("GAME_PROVIDER")}
                            gameList={carouselGameList}
                        />
                        {!gettingNewAndPopularGames ? <>{newGames.length > 0 ? <GameListGrid
                            title={t("NEW_GAMES")}
                            isFilterDropdownVisible={isFilterNewDropdownVisible}
                            dropdownFilterOnClick={() => {
                                setIsFilterNewDropdownVisible(!isFilterNewDropdownVisible);
                            }}
                            filterList={gameProviders}
                            gameList={newGames}
                            handleFilterByProvider={handleFilterByProvider}
                            type="newGames"
                            page="home"
                        /> : <></>}</> : <div className="text-center"><Spinner animation="border" role="status"/></div>}
                    </section>
                    {sliderBannerList.length > 0 && <section className="banner-slider-section">
                        <GameListBanner bannerList={sliderBannerList} />
                    </section>}
                    <section className="popular-section pb-0 mb-4">
                        {!gettingNewAndPopularGames ? <>{recommendedGames.length > 0 ? <GameListGrid
                            title={t("POPULAR_GAMES")}
                            isFilterDropdownVisible={isFilterPopularDropdownVisible}
                            dropdownFilterOnClick={() => {
                                setIsFilterPopularDropdownVisible(
                                    !isFilterPopularDropdownVisible
                                );
                            }}
                            filterList={gameProviders}
                            gameList={recommendedGames}
                            handleFilterByProvider={handleFilterByProvider}
                            type="popularGames"
                            page="home"
                        /> : <></>}</> : <></>}
                        </section>
                        <section>
                          <DownloadApp />
                        </section>
                </>
            )}

            <Modal
                fade={false}
                contentClassName="modal-brand modal-bottom modal-numpad"
                isOpen={isLoggedIn && showCreateShareholderPin}
                centered
            >
                <ModalBody>
                    <form>
                        <div className="display-wrapper" style={{ textAlign: "center" }}
                            onKeyDown={(e) => {
                                if (e.key === 'Backspace') {
                                    onCreatePinKeyPress("{bksp}");
                                }
                            }}
                        >
                            <strong
                                className="reload-title text-yellow mb-3 d-block"
                                style={{ fontSize: "20px" }}
                            >
                                {t("NO_SHAREHOLDER_PIN_FOUND")}
                            </strong>
                            <strong
                                className="reload-title mb-3 d-block"
                                style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    color: "#002e6c",
                                }}
                            >
                                {t("ENTER_YOUR_PIN")}
                            </strong>
                            <PinInput
                                ref={elePin}
                                length={4}
                                initialValue=""
                                secret={true}
                                focus={true}
                                autoSelect={false}
                                // disabled={showCreateShareholderPin}
                                type="numeric"
                                onChange={(value, index) => {
                                    setPinNumberFocused(index);
                                    onCreatePinKeyPress(value);
                                }}
                                onComplete={(value) => adjustFocus()}
                                inputMode="numeric"
                                inputStyle={{
                                    border: "0",
                                    margin: "0px 10px",
                                    width: "40px",
                                    height: "40px",
                                    backgroundColor: "transparent",
                                    borderBottom: "2px solid #002e6c",
                                }}
                                inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                                regexCriteria={/^[ 0-9_@./#&+-]*$/}
                            />
                            <strong
                                className="reload-title mb-3 d-block"
                                style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    color: "#002e6c",
                                    marginTop: "30px",
                                }}
                            >
                                {t("CONFIRM_YOUR_PIN")}
                            </strong>
                            <PinInput
                                ref={eleConfirm}
                                length={4}
                                initialValue=""
                                secret={true}
                                focus={false}
                                autoSelect={false}
                                // disabled={showCreateShareholderPin}
                                type="numeric"
                                onChange={(value, index) => {
                                    setPinNumberConfirmFocused(index);
                                    onCreatePinKeyPress(value);
                                }}
                                inputMode="numeric"
                                inputStyle={{
                                    border: "0",
                                    margin: "0px 10px",
                                    width: "40px",
                                    height: "40px",
                                    backgroundColor: "transparent",
                                    borderBottom: "2px solid #002e6c",
                                }}
                                inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                                regexCriteria={/^[ 0-9_@./#&+-]*$/}
                            />
                            <div
                                className="invalid-feedback"
                                style={{ fontSize: "12px", marginTop: "1rem" }}
                            >
                                {invalidPin && t("PIN_DOES_NOT_MATCH")}
                                {!invalidPin && <span>&nbsp;</span>}
                            </div>
                            {/* <Keyboard
                layout={{
                  default: ["1 2 3 4 5 6 7 8 9 . 0 {bksp}"],
                }}
                display={{
                  "{bksp}": "<i class='fas fa-backspace'></i>",
                }}
                theme={`keyboard keyboard-numpad`}
                keyboardRef={(r) => (keyboard.current = r)}
                onKeyPress={onCreatePinKeyPress}
                disableButtonHold
                disableCaretPositioning
              /> */}
                        </div>
                    </form>
                </ModalBody>
            </Modal>
        <BannerPopupModal />
        </>
    );
};
export default Home;

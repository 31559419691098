import React, { useState, useEffect } from "react";
//import "react-simple-keyboard/build/css/index.css";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { ApiKey, ApiUrl, SessionKey } from "../../util/Constant";
import {
  setBusy,
  setIdle,
  showResponseMessage,
} from "../../redux/AppAction.js";
import ApiEngine from "../../util/ApiEngine.js";
import Sidebar from "components/sidebar";
import { useTranslation } from "react-i18next";
import { createMultiPartFormBody } from "../../util/Util";
import ContentHeader from "components/content/header";
import PlusMinusMaxButton from "../../components/custom/PlusMinusMaxButton";

const EditDownlineGroupDetail = (props) => {
  var { t } = useTranslation();
  var _dispatch = useDispatch();
  var _location = useLocation();
  var selectedLanguage = localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ? 
  localStorage.getItem(SessionKey._LANGUAGE) : 'en' ;
  const selectedGroupId = _location.state.id;
  const selectedGroupName = _location.state.groupName;

  const { handleSubmit, errors, setValue, control, getValues } = useForm();
  const [initCheck, setInitCheck] = useState(false);
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState({});
  const [categories, setCategories] = useState([]);
  const [valueList, setValueList] = useState([]);
  
  const backgroundLightMaxButton = "rgb(255 193 89 / 50%)";
  const backgroundDarkMaxButton = "rgb(255 193 89)";

  useEffect(() => {
    init();
  }, []);

  async function init() {
    _dispatch(setBusy());
    try {
      await getMemberDetails();
    } finally {
      _dispatch(setIdle());
    }
    }

  const EditDownlineForm = () => {
    if (initCheck) {
      return (
        <div id="edit-downline-group" className="gold-scroll">
          {products.length > 0 ? (
            <>
              {categories.map((category, index) => {
                if (
                  products.filter(
                    (x) =>
                      x.categoryName === category.originalTitle &&
                      (x.maxBonus > 0 ||
                        x.maxCommission > 0 ||
                        x.maxClubFee > 0)
                  ).length > 0
                ) {
                  return (
                    <table
                      key={"dg_categories_" + index}
                      className="edit-downline-table"
                    >
                      <thead></thead>
                      <tbody>
                        {products.filter(
                          (x) =>
                            x.categoryName === category.originalTitle &&
                            x.maxBonus > 0
                        ).length > 0 && (
                          <>
                            {!products.filter(
                              (x) =>
                                x.categoryName === category.originalTitle &&
                                x.maxBonus > 0
                            )[0] && (
                              <tr className="border-bot-black">
                                <td colSpan="4">&nbsp;</td>
                              </tr>
                            )}
                            <tr className="data-row category">
                              <td className="row-data-1 font-16 text-gold font-semi">
                                <b>{t(category.originalTitle)}</b>
                              </td>
                              <td className="row-data-2">
                                <div className="font-12 text-white">
                                  {t("BONUS")}
                                </div>
                                <div className="font-12 text-white">
                                  {t("MAX") +
                                    " : " +
                                    valueList[
                                      category.originalTitle
                                    ].highestMaxBonus.toFixed(2) +
                                    "%"}
                                </div>
                              </td>
                              <td className="row-data-3">
                                <Controller
                                  control={control}
                                  name={"category-bonus-" + category.id}
                                  defaultValue={0}
                                  render={({ onChange, value }) => {
                                    let valueNotLowest =
                                      checkValueForLowest(value);
                                    let valueNotHighest = checkValueForHighest(
                                      value,
                                      category,
                                      "bonus"
                                    );

                                    if(document.getElementById("category-" + category.id + "-bonus") !== null) {
                                      if (!valueNotHighest) {
                                        document.getElementById("category-" + category.id + "-bonus").style.background = backgroundDarkMaxButton;
                                      }
                                      else {
                                        document.getElementById("category-" + category.id + "-bonus").style.background = backgroundLightMaxButton;
                                      }
                                    }
                                    return (
                                      <>
                                        <PlusMinusMaxButton
                                          styleValueMinus={valueNotLowest}
                                          category={category}
                                          onClickMinus={(e) => {
                                            if (valueNotLowest === true) {
                                              if (value !== "-") {
                                                value -= 0.1;
                                                value *= 100;
                                                value = Math.round(value);
                                                value /= 100;
                                              } else {
                                                value = 0.0;
                                              }
                                              setValue(
                                                "category-bonus-" + category.id,
                                                value
                                              );
                                              handleCatBtn(
                                                "bonus",
                                                category.originalTitle,
                                                value
                                              );
                                            }
                                          }}
                                          styleValuePlus={valueNotHighest}
                                          onClickPlus={(e) => {
                                            if (valueNotHighest === true) {
                                              if (value !== "-") {
                                                value += 0.1;
                                                value *= 100;
                                                value = Math.round(value);
                                                value /= 100;
                                              } else {
                                                value = 0.0;
                                              }
                                              setValue(
                                                "category-bonus-" + category.id,
                                                value
                                              );
                                              handleCatBtn(
                                                "bonus",
                                                category.originalTitle,
                                                value
                                              );
                                            }
                                          }}
                                          buttonSection="plusminus"
                                          handleSelectCatValue={
                                            handleSelectCatValue
                                          }
                                          optionValue={valueList[
                                            category.originalTitle
                                          ].bonusValues.map(
                                            (bonusValue, index) => {
                                              return (
                                                <option
                                                  key={
                                                    "dg_bonus_values" + index
                                                  }
                                                  className="bonusComm-option-value"
                                                  value={
                                                    bonusValue !== "-"
                                                      ? parseFloat(
                                                          bonusValue.toFixed(2)
                                                        )
                                                      : "-"
                                                  }
                                                >
                                                  {bonusValue !== "-"
                                                    ? bonusValue.toFixed(2)
                                                    : "-"}
                                                </option>
                                              );
                                            }
                                          )}
                                          product={product}
                                          value={value}
                                          handleSelectValue={handleSelectValue}
                                          errors={errors}
                                          type="bonus" // type for bonus/commission/clubfee/maxBonus/maxCommission/maxClubfee
                                        />
                                      </>
                                    );
                                  }}
                                />
                              </td>

                              <PlusMinusMaxButton
                                buttonSection="max"
                                text={t("MAX")}
                                id={"category-" + category.id + "-bonus"}
                                onClickMax={() =>
                                  handleMaxCatBtn(
                                    category.id,
                                    category.originalTitle,
                                    "bonus"
                                  )
                                }
                              />
                            </tr>
                          </>
                        )}
                        {products.filter(
                          (x) =>
                            x.categoryName === category.originalTitle &&
                            x.maxBonus > 0
                        ).length <= 0 && (
                          <tr className="border-bot-black">
                            <td colSpan="4">&nbsp;</td>
                          </tr>
                        )}
                        {products.filter(
                          (x) =>
                            x.categoryName === category.originalTitle &&
                            x.maxCommission > 0
                        ).length > 0 && (
                          <tr
                            className="data-row category"
                            style={{
                              marginTop:
                                products.filter(
                                  (x) =>
                                    x.categoryName === category.originalTitle &&
                                    x.maxBonus > 0
                                ).length > 0
                                  ? "0"
                                  : "25px",
                            }}
                          >
                            <td className="row-data-1 font-16 text-gold font-semi">
                              {products.filter(
                                (x) =>
                                  x.categoryName === category.originalTitle &&
                                  x.maxBonus > 0
                              ).length > 0 ? (
                                ""
                              ) : (
                                <b>{t(category.originalTitle)}</b>
                              )}
                            </td>
                            <td className="row-data-2">
                              <div className="font-12 text-white">
                                {t("COMMISSION_SHORT")}
                              </div>
                              <div className="font-12 text-white">
                                {t("MAX") +
                                  " : " +
                                  valueList[
                                    category.originalTitle
                                  ].highestMaxComm.toFixed(2) +
                                  "%"}
                              </div>
                            </td>
                            <td className="row-data-3">
                              {products.filter(
                                (x) =>
                                  x.categoryName === category.originalTitle &&
                                  x.maxCommission > 0
                              ).length > 0 && (
                                <>
                                  <Controller
                                    control={control}
                                    name={"category-commission-" + category.id}
                                    defaultValue={0}
                                    render={({ onChange, value }) => {
                                      let valueNotLowest =
                                        checkValueForLowest(value);
                                      let valueNotHighest =
                                        checkValueForHighest(
                                          value,
                                          category,
                                          "commission"
                                        );

                                        if(document.getElementById("category-" + category.id + "-commission") !== null) {
                                          if (!valueNotHighest) {
                                            document.getElementById("category-" + category.id + "-commission").style.background = backgroundDarkMaxButton;
                                          }
                                          else {
                                            document.getElementById("category-" + category.id + "-commission").style.background = backgroundLightMaxButton;
                                          }
                                        }
                                      return (
                                        <>
                                          <PlusMinusMaxButton
                                            styleValueMinus={valueNotLowest}
                                            category={category}
                                            onClickMinus={(e) => {
                                              if (valueNotLowest === true) {
                                                if (value !== "-") {
                                                  value -= 0.1;
                                                  value *= 100;
                                                  value = Math.round(value);
                                                  value /= 100;
                                                } else {
                                                  value = 0.0;
                                                }
                                                setValue(
                                                  "category-commission-" +
                                                    category.id,
                                                  parseFloat(value.toFixed(2))
                                                );
                                                handleCatBtn(
                                                  "commission",
                                                  category.id,
                                                  category.originalTitle,
                                                  value
                                                );
                                              }
                                            }}
                                            styleValuePlus={valueNotHighest}
                                            onClickPlus={(e) => {
                                              if (valueNotHighest === true) {
                                                if (value !== "-") {
                                                  value += 0.1;
                                                  value *= 100;
                                                  value = Math.round(value);
                                                  value /= 100;
                                                } else {
                                                  value = 0.0;
                                                }
                                                setValue(
                                                  "category-commission-" +
                                                    category.id,
                                                  parseFloat(value.toFixed(2))
                                                );
                                                handleCatBtn(
                                                  "commission",
                                                  category.id,
                                                  category.originalTitle,
                                                  value
                                                );
                                              }
                                            }}
                                            buttonSection="plusminus"
                                            handleSelectCatValue={
                                              handleSelectCatValue
                                            }
                                            optionValue={valueList[
                                              category.originalTitle
                                            ].commValues.map(
                                              (commValue, index) => {
                                                return (
                                                  <option
                                                    key={
                                                      "dg_comm_values" + index
                                                    }
                                                    className="bonusComm-option-value"
                                                    value={
                                                      commValue !== "-"
                                                        ? parseFloat(
                                                            commValue.toFixed(2)
                                                          )
                                                        : "-"
                                                    }
                                                  >
                                                    {commValue !== "-"
                                                      ? commValue.toFixed(2)
                                                      : "-"}
                                                  </option>
                                                );
                                              }
                                            )}
                                            product={product}
                                            value={value}
                                            // handleSelectValue={
                                            //   handleSelectValue
                                            // }
                                            errors={errors}
                                            type="commission" // type for bonus/commission/clubfee/maxBonus/maxCommission/maxClubfee
                                          />
                                        </>
                                      );
                                    }}
                                  />
                                </>
                              )}
                            </td>

                            <PlusMinusMaxButton
                              buttonSection="max"
                              // category={category}
                              // styleValueButton={getValues('commission-' + product.productId) == product.maxCommission}
                              id={"category-" + category.id + "-commission"}
                              text={t("MAX")}
                              onClickMax={() =>
                                handleMaxCatBtn(
                                  category.id,
                                  category.originalTitle,
                                  "commission"
                                )
                              }
                            />
                          </tr>
                        )}
                        {category.originalTitle === "Poker" &&
                          products.filter(
                            (x) =>
                              x.categoryName === category.originalTitle &&
                              x.maxClubFee > 0
                          ).length > 0 && (
                            <tr
                              className="data-row category"
                              style={{
                                marginTop:
                                  products.filter(
                                    (x) =>
                                      x.categoryName ===
                                        category.originalTitle &&
                                      x.maxCommission > 0
                                  ).length > 0
                                    ? "0"
                                    : "25px",
                              }}
                            >
                              <td className="row-data-1 font-16 text-gold font-semi">
                                {products.filter(
                                  (x) =>
                                    x.categoryName === category.originalTitle &&
                                    x.maxCommission > 0
                                ).length > 0 ? (
                                  ""
                                ) : (
                                  <b>{t(category.originalTitle)}</b>
                                )}
                              </td>
                              <td className="row-data-2">
                                <div className="font-12 text-white">
                                  {t("CLUB_FEE")}
                                </div>
                                <div className="font-12 text-white">
                                  {t("MAX") +
                                    " : " +
                                    valueList[
                                      category.originalTitle
                                    ].highestMaxClubFee.toFixed(2) +
                                    "%"}
                                </div>
                              </td>
                              <td className="row-data-3">
                                {products.filter(
                                  (x) =>
                                    x.categoryName === category.originalTitle &&
                                    x.maxClubFee > 0
                                ).length > 0 && (
                                  <>
                                    <Controller
                                      control={control}
                                      name={"category-clubFee-" + category.id}
                                      defaultValue={0}
                                      render={({ onChange, value }) => {
                                        let valueNotLowest =
                                          checkValueForLowest(value);
                                        let valueNotHighest =
                                          checkValueForHighest(
                                            value,
                                            category,
                                            "clubFee"
                                          );

                                          if(document.getElementById("category-" + category.id + "-clubFee") !== null) {
                                            if (!valueNotHighest) {
                                              document.getElementById("category-" + category.id + "-clubFee").style.background = backgroundDarkMaxButton;
                                            }
                                            else {
                                              document.getElementById("category-" + category.id + "-clubFee").style.background = backgroundLightMaxButton;
                                            }
                                          }
                                        return (
                                          <>
                                            <PlusMinusMaxButton
                                              styleValueMinus={valueNotLowest}
                                              category={category}
                                              onClickMinus={(e) => {
                                                if (valueNotLowest === true) {
                                                  if (value !== "-") {
                                                    value -= 5.0;
                                                    value *= 100;
                                                    value = Math.round(value);
                                                    value /= 100;
                                                  } else {
                                                    value = 0.0;
                                                  }
                                                  setValue(
                                                    "category-clubFee-" +
                                                      category.id,
                                                    parseFloat(value.toFixed(2))
                                                  );
                                                  handleCatBtn(
                                                    "clubFee",
                                                      category.id,
                                                      category.originalTitle,
                                                      value
                                                  );
                                                }
                                              }}
                                              styleValuePlus={valueNotHighest}
                                              onClickPlus={(e) => {
                                                if (valueNotHighest === true) {
                                                  if (value !== "-") {
                                                    value += 5.0;
                                                    value *= 100;
                                                    value = Math.round(value);
                                                    value /= 100;
                                                  } else {
                                                    value = 0.0;
                                                  }
                                                  setValue(
                                                    "category-clubFee-" +
                                                      category.id,
                                                    parseFloat(value.toFixed(2))
                                                  );
                                                  handleCatBtn(
                                                    "clubFee",
                                                    category.id,
                                                    category.originalTitle,
                                                    value
                                                  );
                                                }
                                              }}
                                              buttonSection="plusminus"
                                              handleSelectCatValue={
                                                handleSelectCatValue
                                              }
                                              optionValue={valueList[
                                                category.originalTitle
                                              ].clubFeeValues.map(
                                                (clubFeeValue, index) => {
                                                  return (
                                                    <option
                                                      key={
                                                        "dg_club_fee_values" +
                                                        index
                                                      }
                                                      className="bonusComm-option-value"
                                                      value={
                                                        clubFeeValue !== "-"
                                                          ? parseFloat(
                                                              clubFeeValue.toFixed(
                                                                2
                                                              )
                                                            )
                                                          : "-"
                                                      }
                                                    >
                                                      {clubFeeValue !== "-"
                                                        ? clubFeeValue.toFixed(
                                                            2
                                                          )
                                                        : "-"}
                                                    </option>
                                                  );
                                                }
                                              )}
                                              product={product}
                                              value={value}
                                              errors={errors}
                                              type="clubFee" // type for bonus/commission/clubfee/maxBonus/maxCommission/maxClubfee
                                            />
                                          </>
                                        );
                                      }}
                                    />
                                  </>
                                )}
                              </td>

                              <PlusMinusMaxButton
                                buttonSection="max"
                                text={t("MAX")}
                                id={"category-" + category.id + "-clubFee"}
                                onClickMax={() =>
                                  handleMaxCatBtn(
                                    category.id,
                                    category.originalTitle,
                                    "clubFee"
                                  )
                                }
                              />
                            </tr>
                          )}

                        {products.filter(
                          (x) => x.categoryName === category.originalTitle
                        ).length > 0 &&
                          products
                            .filter(
                              (product) =>
                                product.categoryName ===
                                  category.originalTitle &&
                                (product.maxBonus > 0 ||
                                  product.maxCommission > 0 ||
                                  product.maxClubFee > 0)
                            )
                            .map((product, i) => {
                              return (
                                <React.Fragment key={"product_group_" + i}>
                                  {product.maxBonus > 0 && (
                                    <tr
                                      key={"dg_products" + index}
                                      className="data-row category"
                                    >
                                      <td className="row-data-1 font-16 text-white font-semi">
                                        <div className="row-data-title">
                                          {product.productName}
                                        </div>
                                      </td>
                                      <td className="row-data-2">
                                        <div className="font-12 text-white">
                                          {t("BONUS")}
                                        </div>
                                        <div className="font-12 text-white">
                                          {t("MAX") +
                                            " : " +
                                            product.maxBonus.toFixed(2) +
                                            "%"}
                                        </div>
                                      </td>
                                      <td className="row-data-3">
                                        <Controller
                                          control={control}
                                          name={"bonus-" + product.productId}
                                          defaultValue={0}
                                          render={({ onChange, value }) => {
                                            if(value === product.maxBonus){
                                              if(document.getElementById("product-" + product.productId + "-bonus") !== null) {
                                                  document.getElementById("product-" + product.productId + "-bonus").style.background = backgroundDarkMaxButton;
                                              }
                                            }
                                            else {
                                              if(document.getElementById("product-" + product.productId + "-bonus") !== null) {
                                                document.getElementById("product-" + product.productId + "-bonus").style.background = backgroundLightMaxButton;
                                              }
                                            }
                                            return (
                                              <>
                                                <PlusMinusMaxButton
                                                  category={category}
                                                  styleValueMinus={
                                                    product.maxBonus > 0 &&
                                                    (value !== "-"
                                                      ? parseFloat(
                                                          value.toFixed(2)
                                                        ) > 0
                                                      : true)
                                                  }
                                                  onClickMinus={(e) => {
                                                    if (value !== "-") {
                                                      if (
                                                        product.maxBonus > 0 &&
                                                        parseFloat(value) > 0
                                                      ) {
                                                        value -= 0.1;
                                                        value *= 100;
                                                        value =
                                                          Math.round(value);
                                                        value /= 100;

                                                        document.getElementById("product-" + product.productId + "-bonus").style.background = backgroundLightMaxButton;
                                                      }
                                                      else if(value == product.maxBonus) {
                                                        document.getElementById("product-" + product.productId + "-bonus").style.background = backgroundDarkMaxButton;
                                                      }
                                                    } else {
                                                      value = 0.0;
                                                      document.getElementById("product-" + product.productId + "-bonus").style.background = backgroundLightMaxButton;
                                                    }
                                                    setValue(
                                                      "bonus-" +
                                                        product.productId,
                                                      value
                                                    );
                                                  }}
                                                  styleValuePlus={
                                                    product.maxBonus > 0 &&
                                                    (value !== "-"
                                                      ? parseFloat(
                                                          value.toFixed(2)
                                                        ) < product.maxBonus
                                                      : true)
                                                  }
                                                  onClickPlus={(e) => {
                                                    if (value !== "-") {
                                                      if (
                                                        product.maxBonus > 0 &&
                                                        parseFloat(value) <
                                                          product.maxBonus
                                                      ) {
                                                        value += 0.1;
                                                        value *= 100;
                                                        value =
                                                          Math.round(value);
                                                        value /= 100;

                                                        document.getElementById("product-" + product.productId + "-bonus").style.background = backgroundLightMaxButton;
                                                      }
                                                      else if(value == product.maxBonus) {
                                                        document.getElementById("product-" + product.productId + "-bonus").style.background = backgroundDarkMaxButton;
                                                      }
                                                    } else {
                                                      value = 0.0;
                                                      document.getElementById("product-" + product.productId + "-bonus").style.background = backgroundLightMaxButton;
                                                    }
                                                    setValue(
                                                      "bonus-" +
                                                        product.productId,
                                                      value
                                                    );
                                                  }}
                                                  buttonSection="plusminus"
                                                  handleSelectCatValue={
                                                    handleSelectCatValue
                                                  }
                                                  optionValue={valueList[
                                                    category.originalTitle
                                                  ][
                                                    product.productName
                                                  ].bonusValues.map(
                                                    (bonusValue, index) => {
                                                      return (
                                                        <option
                                                          key={
                                                            "dg_bonus_values_" +
                                                            index
                                                          }
                                                          className="bonusComm-option-value"
                                                          value={
                                                            bonusValue !== "-"
                                                              ? parseFloat(
                                                                  bonusValue.toFixed(
                                                                    2
                                                                  )
                                                                )
                                                              : "-"
                                                          }
                                                        >
                                                          {bonusValue !== "-"
                                                            ? bonusValue.toFixed(
                                                                2
                                                              )
                                                            : "-"}
                                                        </option>
                                                      );
                                                    }
                                                  )}
                                                  value={value}
                                                  id={"div-product-" + product.productId + "-bonus"}
                                                  product={product}
                                                  handleSelectValue={handleSelectValue}
                                                  errors={errors}
                                                  type="maxBonus" // type for bonus/commission/clubfee/maxBonus/maxCommission/maxClubfee
                                                />
                                              </>
                                            );
                                          }}
                                        />
                                      </td>

                                      <PlusMinusMaxButton
                                          buttonSection="max"
                                          text={t("MAX")}
                                          id={'product-' + product.productId + '-bonus'}
                                          onClick={() =>
                                            handleMaxBtn(
                                              product.productId,
                                              product.maxBonus,
                                              product.maxCommission,
                                              product.maxClubFee,
                                              "bonus"
                                            )
                                          }
                                        />
                                    </tr>
                                  )}
                                  {product.maxCommission > 0 && (
                                    <tr className="data-row category">
                                      <td className="row-data-1 font-16 text-white font-semi">
                                        {product.maxBonus > 0 ? (
                                          ""
                                        ) : (
                                          <div className="row-data-title">
                                            {product.productName}
                                          </div>
                                        )}
                                      </td>
                                      <td className="row-data-2">
                                        <div className="font-12 text-white">
                                          {t("COMMISSION_SHORT")}
                                        </div>
                                        <div className="font-12 text-white">
                                          {t("MAX") +
                                            " : " +
                                            product.maxCommission.toFixed(2) +
                                            "%"}
                                        </div>
                                      </td>
                                      <td className="row-data-3">
                                        {products.filter(x => x.categoryName === category.originalTitle && x.maxCommission > 0).length > 0 && (
                                          <>
                                            <Controller
                                              control={control}
                                              name={
                                                "commission-" +
                                                product.productId
                                              }
                                              defaultValue={0}
                                              render={({ onChange, value }) => {
                                                if(value === product.maxCommission){
                                                  if(document.getElementById("product-" + product.productId + "-commission") !== null) {
                                                      document.getElementById("product-" + product.productId + "-commission").style.background = backgroundDarkMaxButton;
                                                  }
                                                }
                                                else {
                                                  if(document.getElementById("product-" + product.productId + "-commission") !== null) {
                                                    document.getElementById("product-" + product.productId + "-commission").style.background = backgroundLightMaxButton;
                                                  }
                                                }
                                                return (
                                                  <>
                                                    <PlusMinusMaxButton
                                                      category={category}
                                                      styleValueMinus={
                                                        product.maxCommission >
                                                          0 &&
                                                        (value !== "-"
                                                          ? parseFloat(
                                                              value.toFixed(2)
                                                            ) > 0
                                                          : true)
                                                      }
                                                      onClickMinus={(e) => {
                                                        if (value !== "-") {
                                                          if (
                                                            product.maxCommission >
                                                              0 &&
                                                            parseFloat(value) >
                                                              0
                                                          ) {
                                                            value -= 0.1;
                                                            value *= 100;
                                                            value =
                                                              Math.round(value);
                                                            value /= 100;

                                                            document.getElementById("product-" + product.productId + "-commission").style.background = backgroundLightMaxButton;
                                                          }
                                                          else if(value == product.maxCommission) {
                                                            document.getElementById("product-" + product.productId + "-commission").style.background = backgroundDarkMaxButton;
                                                          }
                                                        } 
                                                        else {
                                                          document.getElementById("product-" + product.productId + "-commission").style.background = backgroundLightMaxButton;
                                                          value = 0.0;
                                                        }
                                                        
                                                        setValue(
                                                          "commission-" +
                                                            product.productId,
                                                          value
                                                        );
                                                      }}
                                                      styleValuePlus={
                                                        product.maxCommission >
                                                          0 &&
                                                        (value !== "-"
                                                          ? parseFloat(
                                                              value.toFixed(2)
                                                            ) <
                                                            product.maxCommission
                                                          : true)
                                                      }

                                                      onClickPlus={(e) => {
                                                        if (value !== "-") {
                                                        if (
                                                          product.maxCommission >
                                                            0 &&
                                                          parseFloat(value) <
                                                            product.maxCommission
                                                        ) {
                                                          value += 0.1;
                                                          value *= 100;
                                                          value =
                                                            Math.round(value);
                                                          value /= 100;

                                                          document.getElementById("product-" + product.productId + "-commission").style.background = backgroundLightMaxButton;
                                                        }

                                                        if(value == product.maxCommission) {
                                                          document.getElementById("product-" + product.productId + "-commission").style.background = backgroundDarkMaxButton;
                                                        }
                                                        else {
                                                          document.getElementById("product-" + product.productId + "-commission").style.background = backgroundLightMaxButton;
                                                        }
                                                      }
                                                      else {
                                                        document.getElementById("product-" + product.productId + "-commission").style.background = backgroundLightMaxButton;
                                                        value = 0.0;
                                                      }
                                                        setValue(
                                                          "commission-" +
                                                            product.productId,
                                                          value
                                                        );
                                                      }}
                                                      buttonSection="plusminus"
                                                      optionValue={valueList[
                                                        category.originalTitle
                                                      ][
                                                        product.productName
                                                      ].commValues.map(
                                                        (commValue, index) => {
                                                          return (
                                                            <option
                                                              key={
                                                                "dg_comm_values_" +
                                                                index
                                                              }
                                                              className="bonusComm-option-value"
                                                              value={
                                                                commValue !==
                                                                "-"
                                                                  ? parseFloat(
                                                                      commValue.toFixed(
                                                                        2
                                                                      )
                                                                    )
                                                                  : "-"
                                                              }
                                                            >
                                                              {commValue !== "-"
                                                                ? commValue.toFixed(
                                                                    2
                                                                  )
                                                                : "-"}
                                                            </option>
                                                          );
                                                        }
                                                      )}
                                                      product={product}
                                                      value={value}
                                                      handleSelectValue={
                                                        handleSelectValue
                                                      }
                                                      errors={errors}
                                                      type="maxCommission" // type for bonus/commission/clubfee/maxBonus/maxCommission/maxClubfee
                                                    />
                                                  </>
                                                );
                                              }}
                                            />
                                          </>
                                        )}
                                      </td>

                                      <PlusMinusMaxButton
                                          buttonSection="max"
                                          text={t("MAX")}
                                          id={'product-' + product.productId + '-commission'}
                                          onClickMax={() =>
                                            handleMaxBtn(
                                              product.productId,
                                              product.maxBonus,
                                              product.maxCommission,
                                              product.maxClubFee,
                                              "commission"
                                            )
                                          }
                                        />
                                    </tr>
                                  )}
                                  {product.productName === "D2 Poker" &&
                                    product.maxClubFee > 0 && (
                                      <tr className="data-row category">
                                        <td className="row-data-1 font-16 text-white font-semi">
                                          {product.maxCommission > 0 ? (
                                            ""
                                          ) : (
                                            <div className="row-data-title">
                                              {product.productName}
                                            </div>
                                          )}
                                        </td>
                                        <td className="row-data-2">
                                          <div className="font-12 text-white">
                                            {t("CLUB_FEE")}
                                          </div>
                                          <div className="font-12 text-white">
                                            {t("MAX") +
                                              " : " +
                                              product.maxClubFee.toFixed(2) +
                                              "%"}
                                          </div>
                                        </td>
                                        <td className="row-data-3">
                                          {product.maxClubFee > 0 && (
                                            <>
                                              <Controller
                                                control={control}
                                                name={
                                                  "clubFee-" + product.productId
                                                }
                                                defaultValue={0}
                                                render={({
                                                  onChange,
                                                  value,
                                                }) => {
                                                  if(value === product.maxClubFee){
                                                    if(document.getElementById("product-" + product.productId + "-clubFee") !== null) {
                                                        document.getElementById("product-" + product.productId + "-clubFee").style.background = backgroundDarkMaxButton;
                                                    }
                                                  }
                                                  else {
                                                    if(document.getElementById("product-" + product.productId + "-clubFee") !== null) {
                                                      document.getElementById("product-" + product.productId + "-clubFee").style.background = backgroundLightMaxButton;
                                                    }
                                                  }
                                                  return (
                                                    <>
                                                      <PlusMinusMaxButton
                                                        category={category}
                                                        styleValueMinus={
                                                          product.maxClubFee >
                                                            0 &&
                                                          (value !== "-"
                                                            ? parseFloat(
                                                                value.toFixed(2)
                                                              ) > 0
                                                            : true)
                                                        }
                                                        onClickMinus={(e) => {
                                                          if (value !== "-") {
                                                            if (
                                                              product.maxClubFee >
                                                                0 &&
                                                              parseFloat(
                                                                value
                                                              ) > 0
                                                            ) {
                                                              value -= 5.0;
                                                              value *= 100;
                                                              value =
                                                                Math.round(
                                                                  value
                                                                );
                                                              value /= 100;

                                                              document.getElementById("product-" + product.productId + "-clubFee").style.background = backgroundLightMaxButton;
                                                            }
                                                            else if(value == product.maxClubFee) {
                                                              document.getElementById("product-" + product.productId + "-clubFee").style.background = backgroundDarkMaxButton;
                                                            }
                                                          } else {
                                                            value = 0.0;
                                                            document.getElementById("product-" + product.productId + "-clubFee").style.background = backgroundLightMaxButton;
                                                          }
                                                          setValue(
                                                            "clubFee-" +
                                                              product.productId,
                                                            value
                                                          );
                                                        }}
                                                        styleValuePlus={
                                                          product.maxClubFee >
                                                            0 &&
                                                          (value !== "-"
                                                            ? parseFloat(
                                                                value.toFixed(2)
                                                              ) <
                                                              product.maxClubFee
                                                            : true)
                                                        }
                                                        onClickPlus={(e) => {
                                                          if (value !== "-") {
                                                            if (
                                                              product.maxClubFee >
                                                                0 &&
                                                              parseFloat(
                                                                value
                                                              ) <
                                                                product.maxClubFee
                                                            ) {
                                                              value += 5.0;
                                                              value *= 100;
                                                              value =
                                                                Math.round(
                                                                  value
                                                                );
                                                              value /= 100;

                                                              document.getElementById("product-" + product.productId + "-clubFee").style.background = backgroundDarkMaxButton;
                                                            }
                                                            else if(value == product.maxClubFee) {
                                                              document.getElementById("product-" + product.productId + "-clubFee").style.background = backgroundDarkMaxButton;
                                                            }
                                                          } else {
                                                            value = 0.0;
                                                            document.getElementById("product-" + product.productId + "-clubFee").style.background = backgroundLightMaxButton;
                                                          }
                                                          setValue(
                                                            "clubFee-" +
                                                              product.productId,
                                                            value
                                                          );
                                                        }}
                                                        buttonSection="plusminus"
                                                        handleSelectCatValue={
                                                          handleSelectCatValue
                                                        }
                                                        optionValue={valueList[
                                                          category.originalTitle
                                                        ][
                                                          product.productName
                                                        ].clubFeeValues.map(
                                                          (
                                                            clubFeeValue,
                                                            index
                                                          ) => {
                                                            return (
                                                              <option
                                                                key={
                                                                  "dg_club_fee_values_" +
                                                                  index
                                                                }
                                                                className="bonusComm-option-value"
                                                                value={
                                                                  clubFeeValue !==
                                                                  "-"
                                                                    ? parseFloat(
                                                                        clubFeeValue.toFixed(
                                                                          2
                                                                        )
                                                                      )
                                                                    : "-"
                                                                }
                                                              >
                                                                {clubFeeValue !==
                                                                "-"
                                                                  ? clubFeeValue.toFixed(
                                                                      2
                                                                    )
                                                                  : "-"}
                                                              </option>
                                                            );
                                                          }
                                                        )}
                                                        value={value}
                                                        id={"div-product-" + product.productId + "-clubFee"}
                                                        product={product}
                                                        handleSelectValue={handleSelectValue}
                                                        errors={errors}
                                                        type="maxClubFee" // type for bonus/commission/clubfee/maxBonus/maxCommission/maxClubFee
                                                      />
                                                    </>
                                                  );
                                                }}
                                              />
                                            </>
                                          )}
                                        </td>

                                        <PlusMinusMaxButton
                                          buttonSection="max"
                                          text={t("MAX")}
                                          id={'product-' + product.productId + '-clubFee'}
                                          onClickMax={() =>
                                            handleMaxBtn(
                                              product.productId,
                                              product.maxBonus,
                                              product.maxCommission,
                                              product.maxClubFee,
                                              "clubFee"
                                            )
                                          }
                                        />
                                      </tr>
                                    )}
                                </React.Fragment>
                              );
                            })}
                      </tbody>
                    </table>
                  );
                }
              })}
            </>
          ) : (
            <div className="card">
              <div className="text-white card-body text-center">
                {t("NO_ASSIGNED_PRODUCT")}
              </div>
            </div>
          )}
        </div>
      );
    }
  };

  async function getMemberDetails() {
    let tempMemberData = {};
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_MEMBER_DETAILS_BY_ID
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      tempMemberData = responseJson[ApiKey._API_DATA_KEY];
    } else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }

    responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_PRODUCT_RATES);
    let uplineProductRates = [];
    let processedProducts = [];
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      uplineProductRates = responseJson[ApiKey._API_DATA_KEY];
    } else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }

    var productResponseJson = await ApiEngine.get(
      ApiUrl._API_GET_ASSIGNED_MEMBER_PRODUCT
    );
    if (productResponseJson[ApiKey._API_SUCCESS_KEY]) {
      productResponseJson[ApiKey._API_DATA_KEY].products.map((product) => {
        let uplineProduct = uplineProductRates.filter(
          (i) => i.accountProductId == product.accountProductId
        )[0];
        processedProducts.push({
          id: product.id,
          productId: product.productId,
          accountProductId: product.accountProductId,
          productName: product.productName + (product.useGroupRate ? " *" : ""),
          assigned: product.assigned,
          bonus: product.bonus,
          commission: product.commission,
          clubFee: product.clubFee,
          categoryName: product.categoryName,
          maxBonus: uplineProduct != null ? uplineProduct.bonus : 0,
          maxCommission: uplineProduct != null ? uplineProduct.commission : 0,
          maxClubFee: uplineProduct != null ? uplineProduct.clubFee : 0,
          useGroupRate: product.useGroupRate,
        });
      });
    } else {
      throw productResponseJson[ApiKey._API_MESSAGE_KEY];
    }

    setProduct(
      products["length"] == 0
        ? processedProducts[0]
        : processedProducts.find((x) => x["id"] == product["id"])
    );
    setProducts(processedProducts);

    let categoryJson = await ApiEngine.get(
      ApiUrl._API_GET_PRODUCT_CATEGORY_DESKTOP +
        "?selectedLanguage=" +
        selectedLanguage
    );
    if (categoryJson[ApiKey._API_SUCCESS_KEY]) {
      let categoryData = categoryJson[ApiKey._API_DATA_KEY];
      setCategories(categoryData);
      let tempObj = {};
      let tempHighestMaxBonus = 0.0;
      let tempHighestMaxComm = 0.0;
      let tempHighestMaxClubFee = 0.0;
      categoryData.map((category, index) => {
        if (!tempObj[category.originalTitle]) {
          tempObj[category.originalTitle] = {
            bonusValues: [],
            commValues: [],
            clubFeeValues: [],
            highestMaxBonus: 0,
            highestMaxComm: 0,
            highestMaxClubFee: 0,
          };
        }
        processedProducts
          .filter((x) => x.categoryName === category.originalTitle)
          .map((product, i) => {
            if (i === 0) {
              tempHighestMaxBonus = product.maxBonus;
              tempHighestMaxComm = product.maxCommission;
              tempHighestMaxClubFee = product.maxClubFee;
            }
            if (!tempObj[category.originalTitle][product.productName])
              tempObj[category.originalTitle][product.productName] = {
                bonusValues: [],
                commValues: [],
                clubFeeValues: [],
              };
            if (product.maxBonus > tempHighestMaxBonus) {
              tempHighestMaxBonus = product.maxBonus;
            }
            if (product.maxCommission > tempHighestMaxComm) {
              tempHighestMaxComm = product.maxCommission;
            }
            if (product.maxClubFee > tempHighestMaxClubFee) {
              tempHighestMaxClubFee = product.maxClubFee;
            }
            if (product.maxBonus > 0) {
              for (
                var i = 0.0;
                parseFloat(i.toFixed(2)) <=
                parseFloat(product.maxBonus.toFixed(2));
                i += 0.1
              ) {
                i = i * 100;
                i = Math.round(i);
                i = i / 100;
                i = parseFloat(i.toFixed(2));
                if (i === 0.0) {
                  tempObj[category.originalTitle][
                    product.productName
                  ].bonusValues.push("-");
                }
                tempObj[category.originalTitle][
                  product.productName
                ].bonusValues.push(i);
              }
            }
            if (product.maxCommission > 0) {
              for (
                var i = 0.0;
                parseFloat(i.toFixed(2)) <=
                parseFloat(product.maxCommission.toFixed(2));
                i += 0.1
              ) {
                i = i * 100;
                i = Math.round(i);
                i = i / 100;
                i = parseFloat(i.toFixed(2));
                if (i === 0.0) {
                  tempObj[category.originalTitle][
                    product.productName
                  ].commValues.push("-");
                }
                tempObj[category.originalTitle][
                  product.productName
                ].commValues.push(i);
              }
            }
            if (product.maxClubFee > 0) {
              for (
                var i = 0.0;
                parseFloat(i.toFixed(2)) <=
                parseFloat(product.maxClubFee.toFixed(2));
                i += 5.0
              ) {
                i = i * 100;
                i = Math.round(i);
                i = i / 100;
                i = parseFloat(i.toFixed(2));
                if (i === 0.0) {
                  tempObj[category.originalTitle][
                    product.productName
                  ].clubFeeValues.push("-");
                }
                tempObj[category.originalTitle][
                  product.productName
                ].clubFeeValues.push(i);
              }
            }
          });

        tempObj[category.originalTitle]["highestMaxBonus"] =
          tempHighestMaxBonus;
        tempObj[category.originalTitle]["highestMaxComm"] = tempHighestMaxComm;
        tempObj[category.originalTitle]["highestMaxClubFee"] =
          tempHighestMaxClubFee;
        for (var i = 0.0; i <= tempHighestMaxBonus; i += 0.1) {
          i = i * 100;
          i = Math.round(i);
          i = i / 100;
          i = parseFloat(i.toFixed(2));
          if (i === 0.0) {
            tempObj[category.originalTitle].bonusValues.push("-");
          }
          tempObj[category.originalTitle].bonusValues.push(i);
        }
        for (var i = 0.0; i <= tempHighestMaxComm; i += 0.1) {
          i = i * 100;
          i = Math.round(i);
          i = i / 100;
          i = parseFloat(i.toFixed(2));
          if (i === 0.0) {
            tempObj[category.originalTitle].commValues.push("-");
          }
          tempObj[category.originalTitle].commValues.push(i);
        }
        for (var i = 0.0; i <= tempHighestMaxClubFee; i += 5.0) {
          i = i * 100;
          i = Math.round(i);
          i = i / 100;
          i = parseFloat(i.toFixed(2));
          if (i === 0.0) {
            tempObj[category.originalTitle].clubFeeValues.push("-");
          }
          tempObj[category.originalTitle].clubFeeValues.push(i);
        }
      });
  
      setValueList(tempObj);
    }
    setInitCheck(true);
  }

  async function onSaveGroupSubmit(data, e) {
    // console.log(data);
    _dispatch(setBusy());
    let params = {
      groupId: selectedGroupId,
    };

    Object.keys(data).map((key, value) => {
      params[key] = data[key];
    });

    let formBody = createMultiPartFormBody(params);
    let responseJson = await ApiEngine.post(
      ApiUrl._API_UPDATE_DOWNLINE_PRODUCT_RATE,
      formBody
    );

    // console.log(responseJson);
    _dispatch(
      showResponseMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        responseJson[ApiKey._API_MESSAGE_KEY]
      )
    );
    _dispatch(setIdle());
  }

  function checkValueForLowest(value) {
    if (value !== "-") {
      if (value === 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  function checkValueForHighest(value, category, bonusOrComm) {
    if (value !== "-") {
      if (bonusOrComm === "bonus") {
        if (value === valueList[category.originalTitle].highestMaxBonus) {
          return false;
        } else {
          return true;
        }
      } else if (bonusOrComm === "commission") {
        if (value === valueList[category.originalTitle].highestMaxComm) {
          return false;
        } else {
          return true;
        }
      } else if (bonusOrComm === "clubFee") {
        if (value === valueList[category.originalTitle].highestMaxClubFee) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return true;
    }
  }

  function handleCatBtn(bonusOrComm, categoryId, categoryTitle, value) {
    if (value !== "-") {
      value *= 100;
      value = Math.round(value);
      value /= 100;
    

      products.filter(x => x.categoryName === categoryTitle).map((product, index) => {
        let tempBonusOrComm = bonusOrComm.substring(0, 1).toUpperCase() + bonusOrComm.substring(1, bonusOrComm.length);
        if (value > product["max" + tempBonusOrComm]) {
            setValue(bonusOrComm + '-' + product.productId, product["max" + tempBonusOrComm]);
        }
        else if (value == product["max" + tempBonusOrComm]){
            setValue(bonusOrComm + '-' + product.productId, value);

            if (document.getElementById("product-" + product.productId + "-" + bonusOrComm) !== null) {
              document.getElementById("product-" + product.productId + "-" + bonusOrComm).style.background = backgroundDarkMaxButton;
              }
        }
        else {
            setValue(bonusOrComm + '-' + product.productId, value);

            if (document.getElementById("product-" + product.productId + "-" + bonusOrComm) !== null && document.getElementById("category-" + categoryId + "-" + bonusOrComm) !== null) {
              document.getElementById("product-" + product.productId+ "-" + bonusOrComm).style.background = backgroundLightMaxButton;
            }
        }
      });
    }
    else {
      products
      .filter((x) => x.categoryName === categoryTitle)
      .map((product, index) => {
        setValue(bonusOrComm + "-" + product.productId, "-");
      });
    }
  }


  function handleMaxCatBtn(categoryId, categoryTitle, bonusOrComm) {
    let tempHighestBonus = 0.0;
    let tempHighestComm = 0.0;
    let tempHighestMaxClubFee = 0.0;

    products
      .filter((x) => x.categoryName === categoryTitle)
      .map((product, index) => {

        if (document.getElementById("product-" + product.productId + '-' + bonusOrComm) !== null && document.getElementById("category-" + categoryId + "-" + bonusOrComm) !== null ) {
          document.getElementById("product-" + product.productId + '-' + bonusOrComm).style.background = backgroundDarkMaxButton;
        }

        if (bonusOrComm === "bonus") {
          if (product.maxBonus > tempHighestBonus) {
            tempHighestBonus = product.maxBonus;
          }
          if (100.0 > product.maxBonus) {
            setValue("bonus-" + product.productId, product["maxBonus"]);
          } else {
            setValue("bonus-" + product.productId, 100.0);
          }
        } else if (bonusOrComm === "commission") {
          if (product.maxCommission > tempHighestComm) {
            tempHighestComm = product.maxCommission;
          }
          if (100.0 > product.maxCommission) {
            setValue(
              "commission-" + product.productId,
              product["maxCommission"]
            );
          } else {
            setValue("commission-" + product.productId, 100.0);
          }
        } else if (bonusOrComm === "clubFee") {
          if (product.maxClubFee > tempHighestMaxClubFee) {
            tempHighestMaxClubFee = product.maxClubFee;
          }
          if (100.0 > product.maxClubFee) {
            setValue("clubFee-" + product.productId, product["maxClubFee"]);
          } else {
            setValue("clubFee-" + product.productId, 100.0);
          }
        }
      });

    if (bonusOrComm === "bonus") {
      setValue("category-bonus-" + categoryId, tempHighestBonus);
    } else if (bonusOrComm === "commission") {
      setValue("category-commission-" + categoryId, tempHighestComm);
    } else if (bonusOrComm === "clubFee") {
      setValue("category-clubFee-" + categoryId, tempHighestMaxClubFee);
    }
  }

  function handleMaxBtn(productId, maxBonus, maxComm, maxClubFee, bonusOrComm) {
    document.getElementById("product-" + productId + "-" + bonusOrComm).style.background = backgroundDarkMaxButton;

    if (bonusOrComm === "bonus") {
      setValue("bonus-" + productId, maxBonus);
    } else if (bonusOrComm === "commission") {
      setValue("commission-" + productId, maxComm);
    } else if (bonusOrComm === "clubFee") {
      setValue("clubFee-" + productId, maxClubFee);
    }
  }

  // function handleSelectValue(bonusOrComm, productId, value) {
  //   if (value !== "-") {
  //     value *= 100;
  //     value = Math.round(value);
  //     value /= 100;
  //   }
  //   //let tempValue = parseFloat(value);
  //   setValue("" + bonusOrComm + "-" + productId, value);
  // }

  function handleSelectValue(bonusOrComm, productId, value, maxValue) {
    if (value !== "-") {
      value *= 100;
      value = Math.round(value);
      value /= 100;
    }

    //let tempValue = parseFloat(value);
    if(value == maxValue){
      document.getElementById("product-" + productId + "-" + bonusOrComm).style.background = backgroundDarkMaxButton;
    }
    else {
      document.getElementById("product-" + productId + "-" + bonusOrComm).style.background = backgroundLightMaxButton;
    }
    
    setValue("" + bonusOrComm + "-" + productId, value);
  }


  function handleSelectCatValue(bonusOrComm, categoryId, categoryTitle, value) {
    if (value !== '-') {
        value *= 100;
        value = Math.round(value);
        value /= 100;

        products.filter(x => x.categoryName === categoryTitle).map((product, index) => {
            let tempBonusOrComm = bonusOrComm.substring(0, 1).toUpperCase() + bonusOrComm.substring(1, bonusOrComm.length);
            if (value > product["max" + tempBonusOrComm]) {
                setValue(bonusOrComm + '-' + product.productId, product["max" + tempBonusOrComm]);

                if (document.getElementById("product-" + product.productId + "-" + bonusOrComm) !== null) {
                    document.getElementById("product-" + product.productId + "-" + bonusOrComm).style.background = backgroundDarkMaxButton;
                  }

            }
            else if (value == product["max" + tempBonusOrComm]) {
                setValue(bonusOrComm + '-' + product.productId, value);

                if (document.getElementById("product-" + product.productId + "-" + bonusOrComm) !== null) {
                    document.getElementById("product-" + product.productId + "-" + bonusOrComm).style.background = backgroundDarkMaxButton;
                  }
            }
            else {
                setValue(bonusOrComm + '-' + product.productId, value);

                if (document.getElementById("product-" + product.productId + "-" + bonusOrComm) !== null) {
                    document.getElementById("product-" + product.productId + "-" + bonusOrComm).style.background = backgroundLightMaxButton;
                  }
            }
        });
    }
    else {
        products.filter(x => x.categoryName === categoryTitle).map((product, index) => {
            setValue(bonusOrComm + '-' + product.productId, '-');

            if (document.getElementById("product-" + product.productId + "-" + bonusOrComm) !== null) {
                document.getElementById("product-" + product.productId + "-" + bonusOrComm).style.background = backgroundLightMaxButton;
              }
        });
    }
    setValue('category-' + bonusOrComm + '-' + categoryId, value);
  }

  useEffect(() => {
    products.map((product, index) => {
      setValue("bonus-" + product.productId, "-");
      setValue("commission-" + product.productId, "-");
      setValue("clubFee-" + product.productId, "-");

      if(document.getElementById("product-" + product.productId + "-bonus") !== null) {
        document.getElementById("product-" + product.productId + "-bonus").style.background = backgroundLightMaxButton;
      }

      if(document.getElementById("product-" + product.productId + "-commission") !== null) {
        document.getElementById("product-" + product.productId + "-commission").style.background = backgroundLightMaxButton;
      }

      if(document.getElementById("product-" + product.productId + "-clubFee") !== null) {
        document.getElementById("product-" + product.productId + "-clubFee").style.background = backgroundLightMaxButton;
      }

    });
    categories.map((category, index) => {
      setValue("category-bonus-" + category.id, "-");
      setValue("category-commission-" + category.id, "-");
      setValue("category-clubFee-" + category.id, "-");
    });
  }, [initCheck]);

  return (
    <>
      <section className="section-padding" id="downline-section">
        <div className="container">
          <div className="row">
            <div className="col-3" id="account-menu">
              <Sidebar activePage={"DOWNLINE"} />
            </div>
            <div className="col-9">
              <ContentHeader
                enableBackButton
                title={t("EDIT_DOWNLINE_DETAILS")}
              />
              <hr className="right-title-hr" />
              <div className="edit-group-title">
                {t("GROUP_NAME") + " : " + selectedGroupName}
              </div>
              <div className="template-box" id="downline-edit">
                <div className="downline-edit-container">
                  <div id="downline-details" className="">
                    {
                      <form
                        id="downlinetableform"
                        onSubmit={handleSubmit(onSaveGroupSubmit)}
                      >
                        {initCheck && (
                          <>
                            {EditDownlineForm()}
                            <div className="edit-downline-submitbtn">
                              <button
                                type="submit"
                                className="submit font-16 font-bold"
                                //onClick={() => handleSubmit(onSubmit)()}
                              >
                                {t("SUBMIT")}
                              </button>
                            </div>
                          </>
                        )}
                      </form>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditDownlineGroupDetail;

import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import ApiEngine from "../../util/ApiEngine";
import SelectOption from "../../components/custom/SelectOption";
import Input from "../../components/custom/Input";
import PhoneNumber from "../../components/custom/PhoneNumber";
import Numpad from "../../components/custom/Numpad";
import PinInput from "react-pin-input";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { setBusy, setIdle, showResponseMessage } from "../../redux/AppAction";
import { checkIsLoggedIn, updateUserData } from "../../redux/AuthAction";
import { Modal, ModalBody } from "reactstrap";
import { Alert } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
    ApiKey,
    ApiUrl,
    WebUrl,
    TransactionType,
    PanelType,
    SessionKey,
    _CURRENCY_RATIO
} from "../../util/Constant";
import {
    stringIsNullOrEmpty,
    createMultiPartFormBody,
    createFormBody,
    numberWithCurrencyFormat2,
    numberWithCommas,
    formatNumber,
} from "../../util/Util";
import Keyboard from "react-simple-keyboard";
import BottomSuccessModal from "../../components/custom/BottomSuccessModal";
//import "react-simple-keyboard/build/css/index.css";
import Sidebar from "components/sidebar";
import ContentHeader from "components/content/header";
import { Link } from "react-router-dom";
import { mdiHistory } from "@mdi/js";
import Icon from "@mdi/react";
import BlueWarningIcon from "../../assets/img/v3/notice_blue.svg";

const Withdrawal = (props) => {
    const { t } = useTranslation();
    let _history = useHistory();
    let _userData = useSelector((state) => state.authState.userData);
    let _dispatch = useDispatch();
    const { handleSubmit, register, control, watch, setValue, errors, setError, clearErrors, trigger } =
        useForm();
    const {
        handleSubmit: handleVerifySubmit,
        register: registerVerify,
        getValues: getValuesVerify,
        errors: errorsVerify,
        clearErrors: clearErrorsVerify,
        watch: watchVerify,
        setValue: setValueVerify,
    } = useForm();

    const {
        handleSubmit: handlePinSubmit,
        register: registerPin,
        getValues: getValuesPin,
        errors: errorsPin,
        setValue: setValuePin,
    } = useForm();

    const {
        handleSubmit: handleFullNameSubmit,
        register: registerFullName,
        getValues: getValuesFullName,
        errors: errorsFullName,
        setValue: setValueFullName,
    } = useForm();
    const [headerMsg, setHeaderMsg] = useState("");
    const [bankOption, setBankOption] = useState([]);
    const [memberBankAccounts, setMemberBankAccounts] = useState([]);
    const [bankName, setBankName] = useState("");
    const [remember, setRemember] = useState(false);
    const [transcactionNumber, setTranscactionNumber] = useState();
    const [isWithdrawalSuccess, setIsWithdrawalSuccess] = useState(false);
    const [withdrawalAmount, setWithdrawalAmount] = useState(0);
    const [withdrawalDate, setWithdrawalDate] = useState();
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(0);
    const [currentInput, setCurrentInput] = useState();
    const [member, setMember] = useState("");
    const [showPinEntry, setShowPinEntry] = useState(false);
    const [showVerifyPhone, setShowVerifyPhone] = useState(false);
    const [formattedPhoneNumber, setFormattedPhoneNumber] = useState("");
    const _COUNTDOWN_PERIOD = 120;
    const _SECONDS_IN_MS = 1000;
    const _ALERT_TIMEOUT = 3000;
    const [sentCode, setSentCode] = useState(false);
    const [countdownPeriod, setCountdownPeriod] = useState(0);
    const mainForm = useRef(null);
    const [createPinEntry, setCreatePinEntry] = useState(false);
    const [forgetPin, setForgetPin] = useState(false);
    const [theInterval, setTheInterval] = useState(false);
    const [invalidPin, setInvalidPin] = useState(false);
    const [pinEntered, setPinEntered] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [transType, setTransType] = useState("");

    const [pinNumber, setPinNumber] = useState("");
    const [enteredPinNumber, setEnteredPinNumber] = useState("");
    const [pinNumberFocused, setPinNumberFocused] = useState(0);
    const [pinNumberConfirm, setPinNumberConfirm] = useState("");
    const [enteredPinNumberConfirm, setEnteredPinNumberConfirm] = useState("");
    const [pinNumberConfirmFocused, setPinNumberConfirmFocused] = useState(0);
    const [pinNumberFinal, setPinNumberFinal] = useState("");
    const [enteredPinNumberFinal, setEnteredPinNumberFinal] = useState("");
    const [pinNumberFinalFocused, setPinNumberFinalFocused] = useState(0);

    const [showBankAccEntry, setShowBankAccEntry] = useState(false);
    const [bankAccEntered, setBankAccEntered] = useState("");
    const [bankAccNumber, setBankAccNumber] = useState("");

    const [showFullnamePopup, setShowFullnamePopup] = useState(false);

    const [startOnlineTime, setStartOnlineTime] = useState("");
    const [endOnlineTime, setEndOnlineTime] = useState("");
    const [withdrawalStatus, setWithdrawalStatus] = useState(true);

    const [initDone, setInitDone] = useState(false);
    const [enteredWithdrawalData, setEnteredWithdrawalData] = useState({});
    const [hasRolloverBal, setHasRolloverBal] = useState(false);
    const [rolloverProgress, setRolloverProgress] = useState("");
    const [rolloverTarget, setRolloverTarget] = useState("");
    const [showHpErrorMessage, setShowHpErrorMessage] = useState(false);
    const [hpErrorMessage, setHpErrorMessage] = useState("");
    const [smsSent, setSmsSent] = useState(false);
    const _MAX_DIGITS_IN_BANK_ACC = 33;

    const elePin = useRef(null);
    const eleConfirm = useRef(null);
    const eleFinalPin = useRef(null);
    const keyboard = useRef();


    const [hasPendingTxn, setHasPendingTxn] = useState(false);
    const [countdownStr, setCountdownStr] = useState("");
    const [timeDiffSeconds, setTimeDiffSeconds] = useState(0);
    const [countdownTime, setCountdownTime] = useState();
    const [withdrawalLimit, setWithdrawalLimit] = useState("");
    const [showRolloverMessage, setShowRolloverMessage] = useState("");
    const [amountValue, setAmountValue] = useState("");
    const [currentWalletBalance, setCurrentWalletBalance] = useState(0);

    useEffect(() => {
        init();
        setInitDone(true);
    }, [(localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
        localStorage.getItem(SessionKey._LANGUAGE) : 'en')]);

  async function init() {
    try {
      let tempMemberData = {};
      var rolloverJson = await ApiEngine.get(ApiUrl._API_CHECK_MEMBER_ROLLOVER);
      if (rolloverJson[ApiKey._API_SUCCESS_KEY]) {
        var memberJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_DETAILS_BY_ID);
        if (memberJson[ApiKey._API_SUCCESS_KEY]) {
          setMember(memberJson[ApiKey._API_DATA_KEY]);
          tempMemberData = memberJson[ApiKey._API_DATA_KEY];
          //setphoneNumber(memberJson[ApiKey._API_DATA_KEY]['phoneNumber']);
        } else {
          throw memberJson[ApiKey._API_MESSAGE_KEY];
        }

        var withdrawalLimitJson = await ApiEngine.get(
          ApiUrl._API_CHECK_MEMBER_WITHDRAWAL_LIMIT_BALANCE
        );

        if (withdrawalLimitJson[ApiKey._API_SUCCESS_KEY]) {
          setWithdrawalLimit(withdrawalLimitJson[ApiKey._API_DATA_KEY]);
        } else {
          throw withdrawalLimitJson[ApiKey._API_MESSAGE_KEY];
        }

        if (stringIsNullOrEmpty(tempMemberData["userFullName"])) {
          setShowFullnamePopup(true);
        }
        //checkMemberPinNumber();
        let tempStatus = await checkSystemSettings();

        let responseJson = await ApiEngine.get(
          ApiUrl._API_GET_USER_BANK + "?type=WITHDRAWAL"
        );
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          let bankList = [];
          responseJson[ApiKey._API_DATA_KEY].map((bank) => {
            bankList.push({
              label: bank.bankName,
              value: bank.id,
              min: bank.minimumWithdrawal,
              max: bank.maximumWithdrawal,
            });
          });
          setBankOption(bankList);
        } else {
          throw responseJson[ApiKey._API_MESSAGE_KEY];
        }

        setHeaderMsg("");
        var pendingTransactionJson = await ApiEngine.get(
          ApiUrl._API_GET_PENDING_TRANSACTION
        );
        if (pendingTransactionJson[ApiKey._API_SUCCESS_KEY]) {
          if (pendingTransactionJson[ApiKey._API_DATA_KEY] != null) {
            clearTimeout(countdownTime);
            let tempS = parseInt(pendingTransactionJson[ApiKey._API_DATA_KEY]["timeSpan"]);
            setTransType(pendingTransactionJson[ApiKey._API_DATA_KEY]["transType"]);
            setTimeDiffSeconds(tempS);
            setCountdownPeriod(tempS < 0 ? 0 : tempS);
            setHasPendingTxn(true);
            setHeaderMsg(t("NEW_TXN_PROHIBITED"));
          }
        } else {
          _dispatch(
            showResponseMessage(false, pendingTransactionJson[ApiKey._API_MESSAGE_KEY])
          );
        }

        getMemberBankAccount();
        updateUserWalletBalance();
        _dispatch(checkIsLoggedIn());
      }
      else {
        setHasRolloverBal(true);
        throw rolloverJson[ApiKey._API_MESSAGE_KEY];
      }
    } catch (err) {
      setShowRolloverMessage(err);
    }
  }

  async function checkMemberPinNumber() {
    let params = {};
    let pinJson = await ApiEngine.post(
      ApiUrl._API_CHECK_MEMBER_PIN_NUMBER,
      createFormBody(params)
    );
    if (!pinJson[ApiKey._API_SUCCESS_KEY]) {
      var theInterval = setInterval(() => {
        if (elePin["current"] == undefined) return;

        if (
          elePin["current"]["values"][0] == "" ||
          elePin["current"]["values"][0] == undefined
        ) {
          elePin["current"]["elements"][0].focus();
        } else if (
          elePin["current"]["values"][1] == "" ||
          elePin["current"]["values"][1] == undefined
        ) {
          elePin["current"]["elements"][1].focus();
        } else if (
          elePin["current"]["values"][2] == "" ||
          elePin["current"]["values"][2] == undefined
        ) {
          elePin["current"]["elements"][2].focus();
        } else if (
          elePin["current"]["values"][3] == "" ||
          elePin["current"]["values"][3] == undefined
        ) {
          elePin["current"]["elements"][3].focus();
        } else if (
          eleConfirm["current"]["values"][0] == "" ||
          eleConfirm["current"]["values"][0] == undefined
        ) {
          eleConfirm["current"]["elements"][0].focus();
        } else if (
          eleConfirm["current"]["values"][1] == "" ||
          eleConfirm["current"]["values"][1] == undefined
        ) {
          eleConfirm["current"]["elements"][1].focus();
        } else if (
          eleConfirm["current"]["values"][2] == "" ||
          eleConfirm["current"]["values"][2] == undefined
        ) {
          eleConfirm["current"]["elements"][2].focus();
        } else if (
          eleConfirm["current"]["values"][3] == "" ||
          eleConfirm["current"]["values"][3] == undefined
        ) {
          eleConfirm["current"]["elements"][3].focus();
        } else {
          var currpin =
            elePin["current"]["values"][0] +
            elePin["current"]["values"][1] +
            elePin["current"]["values"][2] +
            elePin["current"]["values"][3];
          var currconfpin =
            eleConfirm["current"]["values"][0] +
            eleConfirm["current"]["values"][1] +
            eleConfirm["current"]["values"][2] +
            eleConfirm["current"]["values"][3];
          if (currpin != currconfpin) {
            setInvalidPin(true);
            elePin["current"].clear();
            eleConfirm["current"].clear();
            elePin["current"].focus();
          } else {
            setInvalidPin(false);
            clearInterval(theInterval);
            submitPin(currpin, currconfpin);
          }
        }
      }, 50);
      setTheInterval(theInterval);
      setCreatePinEntry(true);
    }
  }

    async function checkSystemSettings() {
        let settingsJson = await ApiEngine.get(
            ApiUrl._API_GET_USER_SYSTEM_SETTINGS + "?type=withdrawal"
        );
        if (
            settingsJson[ApiKey._API_SUCCESS_KEY] &&
            settingsJson[ApiKey._API_DATA_KEY] != null
        ) {
            let data = settingsJson[ApiKey._API_DATA_KEY];
            let startTime;
            let endTime;
            let status = false;
            data.map((keyValuePair, index) => {
                if (keyValuePair["key"] === "WithdrawalStartTime") {
                    startTime = keyValuePair["value"];
                }
                if (keyValuePair["key"] === "WithdrawalEndTime") {
                    endTime = keyValuePair["value"];
                }
                if (keyValuePair["key"] === "WithdrawalStatus") {
                    status = keyValuePair["value"] === "true";
                }
            });
            setStartOnlineTime(startTime);
            setEndOnlineTime(endTime);
            setWithdrawalStatus(status);

            return status;
        }
    }

    // useEffect(() => {
    //   if (countdownPeriod > 0) {
    //     setTimeout(() => {
    //       let tempPeriod = countdownPeriod - 1;
    //       setCountdownPeriod(tempPeriod);
    //     }, _SECONDS_IN_MS);
    //   }
    // }, [countdownPeriod]);

    useEffect(() => {
        if (countdownPeriod > 0) {
            clearTimeout(countdownTime);
            setCountdownTime(
                setTimeout(() => {
                    let tempPeriod = countdownPeriod - 1;
                    setCountdownPeriod(tempPeriod);
                    let tempX = moment.duration(tempPeriod);
                    let tempMM = Math.floor(tempX["_milliseconds"] / 60);
                    let tempSS = tempX["_milliseconds"] - tempMM * 60;
                    setTimeDiffSeconds(tempX["_milliseconds"]);
                    let tempStr =
                        (tempMM.toString().length > 1 ? tempMM : "0" + tempMM) +
                        " " +
                        t("MINUTES") +
                        " " +
                        (tempSS.toString().length > 1 ? tempSS : "0" + tempSS) +
                        " " +
                        t("SECOND");
                    //setCountdownStr(tempStr);
                }, _SECONDS_IN_MS)
            );
        }
    }, [countdownPeriod]);

    const sendVerificationCode = async () => {
        await ApiEngine.post(
            ApiUrl._API_SEND_VERIFICATION_CODE,
            createMultiPartFormBody({ phoneNumber: formattedPhoneNumber })
        ).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                setSentCode(true);
                setCountdownPeriod(_COUNTDOWN_PERIOD);
            }
        });
    };

    const verifyPhoneNumber = async () => {
        await ApiEngine.post(
            ApiUrl._API_VERIFY_PHONE_NUMBER,
            createMultiPartFormBody({
                verificationCode: getValuesVerify("verificationCode"),
            })
        ).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                setShowVerifyPhone(false);
            }

            _dispatch(
                showResponseMessage(
                    response[ApiKey._API_SUCCESS_KEY],
                    response[ApiKey._API_MESSAGE_KEY]
                )
            );
        });
    };

    async function getMemberBankAccount() {
        try {
            let responseJson = await ApiEngine.get(
                ApiUrl._API_GET_MEMBER_BANK_ACCOUNT_BY_MEMBER
            );
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                let bankAccountList = [];
                responseJson[ApiKey._API_DATA_KEY].map((bankAccount) => {
                    bankAccountList.push({
                        label: bankAccount.bankName + " - " + bankAccount.accountNumber,
                        value: bankAccount.id,
                        bankId: bankAccount.bankId,
                        accountHolderName: bankAccount.accountHolderName,
                        accNum: bankAccount.accountNumber,
                        bankName: bankAccount.bankName,
                    });
                });
                setMemberBankAccounts(bankAccountList);
            } else {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }
        } catch (err) {
            _dispatch(showResponseMessage(false, err));
        }
    }

    function setMinMax(e) {
        setMin(e.min);
        setMax(e.max);
    }

    function resetFormField() {
        setValue("bankId", "");
        setRemember(false);
        setValue("memberBankAccount", "");
    }

    async function submitPin(pin, conf) {
        _dispatch(setBusy());
        let params = {
            pinNumber: pin,
            confirmPinNumber: conf,
        };
        let responseJson = await ApiEngine.post(
            ApiUrl._API_SET_MEMBER_PIN_NUMBER,
            createFormBody(params)
        );
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setCreatePinEntry(false);
        }
        _dispatch(
            showResponseMessage(
                responseJson[ApiKey._API_SUCCESS_KEY],
                responseJson[ApiKey._API_MESSAGE_KEY]
            )
        );

        elePin["current"].clear();
        eleConfirm["current"].clear();
        setPinNumber("");
        setEnteredPinNumber("");
        setPinNumberFocused(0);
        setPinNumberConfirm("");
        setEnteredPinNumberConfirm("");
        setPinNumberConfirmFocused(0);

        _dispatch(setIdle());
    }

    async function submitWithdrawal(data, e) {
        _dispatch(setBusy());
        clearErrors();
        if (amountValue < min) {
            setError("amount", {
                message: "VALUE_LESS_THAN_MINIMUM",
            });
        }
        else if (amountValue > max) {
            setError("amount", {
                message: "EXCEED_MAXIMUM_VALUE",
            });
        }
        let params = {
            transactionTypeId: TransactionType._WITHDRAWAL,
            amount: amountValue,
            channel: "",
            panel: PanelType._MEMBERSITE,
            bankName: bankName,
            bankAccountNumber: data.bankAccountNumber,
            bankAccountHolderName: member["userFullName"],
        };

        let responseJson = await ApiEngine.post(
            ApiUrl._API_CREATE_TRANSACTION,
            createFormBody(params)
        );
        let message = responseJson[ApiKey._API_MESSAGE_KEY];
        let isSuccess = responseJson[ApiKey._API_SUCCESS_KEY];

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setShowPinEntry(false);
            let tempWithdrawalAmt = amountValue / _CURRENCY_RATIO;
            setWithdrawalAmount(tempWithdrawalAmt);
            setIsWithdrawalSuccess(true);
            setWithdrawalDate(moment().format("DD MMM YYYY, hh:mm A"));
            setTranscactionNumber(responseJson[ApiKey._API_DATA_KEY]);
            resetFormField();
            e.target.reset();
            _dispatch(checkIsLoggedIn());

            if (remember) {
                let saveAccountParams = {
                    bankId: data.bankId,
                    accountNumber: data.bankAccountNumber,
                    accountHolderName: data.bankAccountHolderName,
                };
                let saveResponseJson = await ApiEngine.post(
                    ApiUrl._API_CREATE_OR_UPDATE_MEMBER_BANK_ACCOUNT,
                    createFormBody(saveAccountParams)
                );

                if (saveResponseJson[ApiKey._API_SUCCESS_KEY]) {
                    getMemberBankAccount();
                } else {
                    message =
                        t("TRANSACTION_CREATED_BUT_SAVE_FAILED") +
                        "\n" +
                        saveResponseJson[ApiKey._API_MESSAGE_KEY];
                    isSuccess = false;
                }
            }
            init();
        } else {
            _dispatch(
                showResponseMessage(
                    responseJson[ApiKey._API_SUCCESS_KEY],
                    responseJson[ApiKey._API_MESSAGE_KEY]
                )
            );
        }
        setPinEntered(false);
        setPinNumberFinal("");
        setEnteredPinNumberFinal("");
        setPinNumberFinalFocused(0);
        setShowPinEntry(false);
        //_dispatch(showResponseMessage(isSuccess, message));
        _dispatch(setIdle());
    }

    async function onSubmit(data, e) {
        setEnteredWithdrawalData(data);
        let tempStatus = await checkSystemSettings();
        if (tempStatus) {
            //setShowPinEntry(true);
            submitWithdrawal(data, e);
            //if (pinEntered && pinNumberFinal !== '') {
            //    submitWithdrawal(data, e, pinNumberFinal);
            //}
        } else {
            _dispatch(
                showResponseMessage(
                    false,
                    t("WITHDRAWAL") + " " + t("CURRENTLY_OFFLINE")
                )
            );
            init();
        }
    }

    async function onPinSubmit(data, e) {
        let tempStatus = await checkSystemSettings();
        if (tempStatus) {
            if (pinEntered && pinNumberFinal !== "") {
                submitWithdrawal(enteredWithdrawalData, e, pinNumberFinal);
            }
        }
    }

    async function submitFullName(data) {
        let params = {
            userFullName: data.userFullName,
            // id: member["id"],
        };
        let responseJson = await ApiEngine.post(
            // ApiUrl._API_CREATE_OR_UPDATE_MEMBER,
            ApiUrl._API_UPDATE_MEMBER_USERFULLNAME,
            createFormBody(params)
        );
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            _dispatch(
                showResponseMessage(
                    responseJson[ApiKey._API_SUCCESS_KEY],
                    responseJson[ApiKey._API_MESSAGE_KEY]
                )
            );
            setShowFullnamePopup(false);
            let params = {};
            let pinJson = await ApiEngine.post(
                ApiUrl._API_CHECK_MEMBER_PIN_NUMBER,
                createFormBody(params)
            );
            if (!pinJson[ApiKey._API_SUCCESS_KEY]) {
                var theInterval = setInterval(() => {
                    if (elePin["current"] == undefined) return;

                    if (
                        elePin["current"]["values"][0] == "" ||
                        elePin["current"]["values"][0] == undefined
                    ) {
                        elePin["current"]["elements"][0].focus();
                    } else if (
                        elePin["current"]["values"][1] == "" ||
                        elePin["current"]["values"][1] == undefined
                    ) {
                        elePin["current"]["elements"][1].focus();
                    } else if (
                        elePin["current"]["values"][2] == "" ||
                        elePin["current"]["values"][2] == undefined
                    ) {
                        elePin["current"]["elements"][2].focus();
                    } else if (
                        elePin["current"]["values"][3] == "" ||
                        elePin["current"]["values"][3] == undefined
                    ) {
                        elePin["current"]["elements"][3].focus();
                    } else if (
                        eleConfirm["current"]["values"][0] == "" ||
                        eleConfirm["current"]["values"][0] == undefined
                    ) {
                        eleConfirm["current"]["elements"][0].focus();
                    } else if (
                        eleConfirm["current"]["values"][1] == "" ||
                        eleConfirm["current"]["values"][1] == undefined
                    ) {
                        eleConfirm["current"]["elements"][1].focus();
                    } else if (
                        eleConfirm["current"]["values"][2] == "" ||
                        eleConfirm["current"]["values"][2] == undefined
                    ) {
                        eleConfirm["current"]["elements"][2].focus();
                    } else if (
                        eleConfirm["current"]["values"][3] == "" ||
                        eleConfirm["current"]["values"][3] == undefined
                    ) {
                        eleConfirm["current"]["elements"][3].focus();
                    } else {
                        var currpin =
                            elePin["current"]["values"][0] +
                            elePin["current"]["values"][1] +
                            elePin["current"]["values"][2] +
                            elePin["current"]["values"][3];
                        var currconfpin =
                            eleConfirm["current"]["values"][0] +
                            eleConfirm["current"]["values"][1] +
                            eleConfirm["current"]["values"][2] +
                            eleConfirm["current"]["values"][3];
                        if (currpin != currconfpin) {
                            setInvalidPin(true);
                            elePin["current"].clear();
                            eleConfirm["current"].clear();
                            elePin["current"].focus();
                        } else {
                            setInvalidPin(false);
                            clearInterval(theInterval);
                            submitPin(currpin, currconfpin);
                        }
                    }
                }, 50);
                setTheInterval(theInterval);
                setCreatePinEntry(true);
            }
        }
        init();
    }

    async function sendPinCode() {
        if (countdownPeriod < 1) {
            let responseJson = await ApiEngine.get(
                ApiUrl._API_CHECK_IF_PHONE_VERIFIED
            );

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setShowHpErrorMessage(false);
                var hpNum = responseJson[ApiKey._API_DATA_KEY];
                let params = {
                    phoneNumber: hpNum,
                    isShareholder: "false",
                };
                let smsJson = await ApiEngine.post(
                    ApiUrl._API_SEND_PIN_CODE,
                    createFormBody(params)
                );
                if (smsJson[ApiKey._API_SUCCESS_KEY]) {
                    setForgetPin(false);
                    setSmsSent(true);
                    setCountdownPeriod(_COUNTDOWN_PERIOD);
                } else {
                    setHpErrorMessage(smsJson[ApiKey._API_MESSAGE_KEY]);
                    setShowHpErrorMessage(true);
                }
            } else {
                setHpErrorMessage(responseJson[ApiKey._API_MESSAGE_KEY]);
                setShowHpErrorMessage(true);
            }
        } else {
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), [_ALERT_TIMEOUT]);
        }
    }

    function onCreatePinKeyPress(value) {
        // 1st: have to know if entering for elePin or eleConfirm
        // 2nd: have to know which index is currently focused for altering the state.value
        // 3rd: have to check if at elePin.index(3), if yes then convert value to string and focus on eleConfirm.index(0)
        // 4th: have to check if at eleConfirm.index(3), if yes then convert value to string, and submitPin

        let currentIndex = 0;
        let pinStr = enteredPinNumber;
        let pinConfirmStr = enteredPinNumberConfirm;
        if (value !== "{bksp}") {
            if (pinNumber === "") {
                currentIndex = pinNumberFocused;
                // elePin["current"]["elements"][currentIndex].state.value = value;
                pinStr = value;
                setEnteredPinNumber(pinStr);
                if (currentIndex !== 3) {
                    currentIndex++;
                    setPinNumberFocused(currentIndex);
                    elePin["current"]["elements"][currentIndex].focus();
                } else {
                    setPinNumber(pinStr);
                }
            } else {
                currentIndex = pinNumberConfirmFocused;
                // eleConfirm["current"]["elements"][currentIndex].state.value = value;
                pinConfirmStr = value;
                setEnteredPinNumberConfirm(pinConfirmStr);
                currentIndex++;
                setPinNumberConfirmFocused(currentIndex);
                if (currentIndex < 3) {
                    eleConfirm["current"]["elements"][currentIndex].focus();
                } else {
                    setPinNumberConfirm(pinConfirmStr);
                    //submitPin(pinNumber, pinConfirmStr);
                }
            }
        } else {
            elePin["current"].clear();
            eleConfirm["current"].clear();
            setPinNumber("");
            setEnteredPinNumber("");
            setPinNumberFocused(currentIndex);
            setPinNumberConfirm("");
            setEnteredPinNumberConfirm("");
            setPinNumberConfirmFocused(currentIndex);
            elePin["current"]["elements"][currentIndex].focus();
        }
    }

    function onVerifyPinKeyPress(key) {
        let currentIndex = 0;

        let pinStr = enteredPinNumberFinal;
        if (key !== "{bksp}") {
            // if (key === "{enter}") {
            //   if (pinEntered) {
            //     setShowPinEntry(false);
            //   }
            // }
            if (pinNumberFinal === "") {
                currentIndex = pinNumberFinalFocused;
                // eleFinalPin["current"]["elements"][currentIndex].state.value = key;
                pinStr = key;
                setEnteredPinNumberFinal(pinStr);
                if (currentIndex !== 3) {
                    currentIndex++;
                    setPinNumberFinalFocused(currentIndex);
                    //eleFinalPin["current"]["elements"][currentIndex].focus();
                } else {
                    setPinNumberFinal(pinStr);
                    setPinEntered(true);
                    setShowPinEntry(true);
                    // verifyPin(pinStr);
                    // eleFinalPin["current"] && eleFinalPin["current"].clear();
                    setEnteredPinNumberFinal("");
                    setPinNumberFinalFocused(3);
                    // eleFinalPin["current"] &&
                    //   eleFinalPin["current"]["elements"][3].focus();
                }
            }
        } else {
            //eleFinalPin["current"].clear();
            setPinNumberFinal("");
            setEnteredPinNumberFinal("");
            setPinNumberFinalFocused(currentIndex);
            // eleFinalPin["current"]["elements"][currentIndex].focus();
        }
    }

    function onBankAccKeyPress(key) {
        let bankAccStr = bankAccEntered;
        if (key !== "{bksp}") {
            if (bankAccStr.length < _MAX_DIGITS_IN_BANK_ACC) {
                bankAccStr += key;
                setBankAccEntered(bankAccStr);
                setBankAccNumber(bankAccStr);
                if (bankAccStr.length === _MAX_DIGITS_IN_BANK_ACC) {
                    setTimeout(() => {
                        setShowBankAccEntry(false);
                    }, 1000);
                }
            }
        } else {
            bankAccStr = bankAccStr.slice(0, -1);
            setBankAccEntered(bankAccStr);
            setBankAccNumber(bankAccStr);
        }
    }
    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        getRolloverData();
    }, []);

    /// <summary>
    /// Author : -
    /// </summary>
    async function getRolloverData() {
        var status = null;
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_ROLLOVER_RECORD);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var data = responseJson[ApiKey._API_DATA_KEY];

            data.filter((x) => x.status === "Active")
                .map((value, index) => {
                    setRolloverProgress(value.rolloverProgress);
                    setRolloverTarget(value.rolloverTarget);
                    status = value.status;
                });
        }
        if (stringIsNullOrEmpty(status)) {
            setHasRolloverBal(false);
        } else {
            setHasRolloverBal(true);
        }
    }

    async function updateUserWalletBalance() {
        let walletResponse = await ApiEngine.get(ApiUrl._API_GET_MEMBER_WALLET_BALANCE);
        if (walletResponse[ApiKey._API_SUCCESS_KEY]) {
            let data = walletResponse[ApiKey._API_DATA_KEY];
            setCurrentWalletBalance(data["primaryCredit"]);
            _dispatch(updateUserData());
        }
    }

    return (
        <>
            <section className="section-padding" id="withdrawal-section">
                {initDone && (
                    <>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-3" id="account-menu">
                                                <Sidebar activePage={"WALLET"} />
                                            </div>
                                
                                            <div className="col-9">
                                                <ContentHeader
                                                    enableBackButton
                                                    // escButtonOnClick={() =>
                                                    //   _history.replace(WebUrl._URL_MAIN_WALLET)
                                                    // }
                                                    title={t("WITHDRAWAL")}
                                                />
                                                <hr className="right-title-hr" />
                                    {
                                        (withdrawalStatus || (withdrawalStatus && (moment().isSameOrAfter(moment(moment().format('YYYY-MM-DD') + " " + startOnlineTime)) && moment().isBefore(moment(moment().add(1, 'day').format('YYYY-MM-DD') + " " + endOnlineTime))))) ? (
                                            <>
                                                {hasPendingTxn && headerMsg && (
                                                    <div className="alert alert-warning">
                                                        {timeDiffSeconds > 0
                                                            ? t("PLEASE_WAIT") +
                                                            " " +
                                                            (!stringIsNullOrEmpty(countdownStr) ? countdownStr +
                                                            " " : "") +
                                                            (transType === "Deposit"
                                                                ? t("FOR_DEPOSIT_APPROVAL")
                                                                : t("FOR_WITHDRAWAL_APPROVAL"))
                                                            : headerMsg}
                                                    </div>
                                                )}

                                                <div className="template-box" id="withdrawal-box">

                                                    <div className="row mb-2" style={{ justifyContent: "space-between", padding: "0px 10px" }}>
                                                        <div></div>
                                                        <Link to={WebUrl._URL_WITHDRAWAL_RECORD}>
                                                            <div className="d-flex justify-content-end align-items-center deposit-record-btn">
                                                                <div style={{ marginRight: "5px", fontSize:"12px" }}> {t("WITHDRAWAL_RECORD")} </div>
                                                                <Icon
                                                                    path={mdiHistory}
                                                                    title="User Profile"
                                                                    size={1}
                                                                />
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    {!hasRolloverBal ? <> 
                                                    {!showPinEntry && (
                                                        <div className="withdrawal-cash card-brand mb-1 font-15 text-white font-semi">
                                                            <div className="member-card d-flex justify-content-between m-0">
                                                                <div className="text-brand text-bold">
                                                                    {t("CASH")}
                                                                </div>
                                                                <div className="d-flex align-items-center">
                                                                    <span className="text-yellow currency-left"></span>
                                                                    <strong className="text-brand ml-2">
                                                                        {numberWithCurrencyFormat2(
                                                                            parseFloat(_userData.walletBalance),
                                                                            3
                                                                        )}
                                                                    </strong>
                                                                </div>

                                                            </div>

                                                        </div>


                                                    )}
                                                    {withdrawalLimit && withdrawalLimit > 0 && (
                                                        <div className="col-md-12 d-flex justify-content-between m-0" >
                                                            <div></div>
                                                            <span className="reminder font-11 text-red font-semi-italic">
                                                                {t("AVAILABLE_WITHDRAWAL_LIMIT")} :  {numberWithCurrencyFormat2(
                                                                    withdrawalLimit["remainWithdrawalBalance"],
                                                                    2
                                                                )} / {numberWithCurrencyFormat2(
                                                                    withdrawalLimit["maxWithdrawalLimit"],
                                                                    2
                                                                )}
                                                            </span>
                                                        </div>
                                                    )}
                                                    <div className="row">
                                                        <div className="col-xs-12">
                                                            <form
                                                                onSubmit={handleSubmit(onSubmit)}
                                                                ref={mainForm}
                                                            >
                                                                {memberBankAccounts.length > 0 && <div className="form-group m-b-15">
                                                                    <label className="col-form-label font-15 text-white font-semi">
                                                                        {t("SAVED_BANK_ACCOUNT")}
                                                                    </label>
                                                                    <div>
                                                                        <Controller
                                                                            control={control}
                                                                            name="memberBankAccount"
                                                                            // defaultValue={""}
                                                                            render={({ onChange, value }) => {
                                                                                return (
                                                                                    <SelectOption
                                                                                        options={memberBankAccounts}
                                                                                        placeholder={t(
                                                                                            "YOU_MAY_CHOOSE_HERE_TO_SELECT_SAVED_BANK_ACCOUNT"
                                                                                        )}
                                                                                        value={value}
                                                                                        onChange={(e) => {
                                                                                            setRemember(false);
                                                                                            setBankName(e.bankName);
                                                                                            setBankAccNumber(e.accNum);
                                                                                            setValue(
                                                                                                "bankAccountNumber",
                                                                                                e.accNum
                                                                                            );
                                                                                            setValue(
                                                                                                "bankAccountHolderName",
                                                                                                e.accountHolderName
                                                                                            );
                                                                                            setValue("bankId", e.bankId);
                                                                                            setMinMax({
                                                                                                min: bankOption.find(
                                                                                                    (b) => b.value == e.bankId
                                                                                                )?.min,
                                                                                                max: bankOption.find(
                                                                                                    (b) => b.value == e.bankId
                                                                                                )?.max,
                                                                                            });
                                                                                            onChange(e.value);
                                                                                            trigger();
                                                                                        }}
                                                                                    />
                                                                                );
                                                                            }}
                                                                        />
                                                                        {errors.memberBankAccount && (
                                                                            <div className="invalid-feedback">
                                                                                {t(errors.memberBankAccount.message)}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>}

                                                                <div className="form-divider mt-4">
                                                                    <div className="form-group m-b-15">
                                                                        <label className="col-form-label font-15 text-white font-semi">
                                                                            {t("BANK")}
                                                                        </label>
                                                                        <div>
                                                                            <Controller
                                                                                control={control}
                                                                                name="bankId"
                                                                                //   defaultValue={""}
                                                                                render={({ onChange, value }) => {
                                                                                    return (
                                                                                        <SelectOption
                                                                                            options={bankOption}
                                                                                            isDisabled={
                                                                                                !stringIsNullOrEmpty(
                                                                                                    watch("memberBankAccount")
                                                                                                )
                                                                                            }
                                                                                            placeholder={t(
                                                                                                "PLEASE_SELECT_BANK"
                                                                                            )}
                                                                                            value={value}
                                                                                            onChange={(e) => {
                                                                                                setBankName(e.label);
                                                                                                onChange(e.value);
                                                                                                setMinMax(e);
                                                                                            }}
                                                                                        />
                                                                                    );
                                                                                }}
                                                                                rules={{
                                                                                    required: "PLEASE_SELECT_BANK",
                                                                                }}
                                                                            />
                                                                            {errors.bankId && (
                                                                                <div className="invalid-feedback">
                                                                                    {t(errors.bankId.message)}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group m-b-15">
                                                                        <label className="col-form-label font-15 text-white font-semi">
                                                                            {t("BANK_ACCOUNT_NUMBER")}
                                                                        </label>
                                                                        <div
                                                                        // onClick={() => setShowBankAccEntry(true)}
                                                                        >
                                                                            <input
                                                                                type="tel"
                                                                                // readOnly
                                                                                className="form-white-input"
                                                                                placeholder={t(
                                                                                    "PLEASE_ENTER_BANK_ACCOUNT_NUMBER"
                                                                                )}
                                                                                name="bankAccountNumber"
                                                                                //   defaultValue={""}
                                                                                // value={bankAccNumber}
                                                                                ref={register({
                                                                                    required:
                                                                                        "PLEASE_ENTER_BANK_ACCOUNT_NUMBER",
                                                                                    pattern: {
                                                                                        value: /^[0-9]+$/,
                                                                                        message:
                                                                                            "INVALID_BANK_ACCOUNT_NUMBER",
                                                                                    },
                                                                                })}
                                                                                onKeyPress={(event) => {
                                                                                    if (!/[0-9]/.test(event.key)) {
                                                                                        event.preventDefault();
                                                                                    }
                                                                                }}
                                                                            />
                                                                            {errors.bankAccountNumber && (
                                                                                <div className="invalid-feedback">
                                                                                    {t(errors.bankAccountNumber.message)}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="form-divider mt-4">
                                                                    <div className="form-group m-b-15">
                                                                        <label className="col-form-label font-15 text-white font-semi">
                                                                            {t("BANK_ACCOUNT_HOLDER_NAME")}
                                                                        </label>
                                                                        <div>
                                                                            <div
                                                                                className="form-white-input"
                                                                                style={{
                                                                                    paddingTop: 14,
                                                                                    paddingBottom: 14,
                                                                                }}
                                                                            >
                                                                                {member["userFullName"]}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group m-b-15">
                                                                        <label className="col-form-label font-15 text-white font-semi">
                                                                            {t("AMOUNT")}
                                                                        </label>
                                                                        <div className="deposit-input-group mb-3">
                                                                            <span class="currency-prefix">IDR</span>
                                                                            <input
                                                                                type="text"
                                                                                // readOnly
                                                                                className="form-white-input amount"
                                                                                placeholder={t("PLEASE_ENTER_AMOUNT")}
                                                                                name="amount"
                                                                                value={numberWithCommas(amountValue)}
                                                                                disabled={stringIsNullOrEmpty(bankName)}
                                                                                autoComplete="off"
                                                                                onKeyDown={(e) => {
                                                                                    if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) { //keyCode of 0 to 9, numpad 0 to 9
                                                                                        setAmountValue(amountValue + "" + e.key);
                                                                                    }
                                                                                    else if (e.keyCode == 8 || e.keyCode == 46) //keyCode of backSpace and Del
                                                                                    {
                                                                                        setAmountValue(amountValue.slice(0, -1));
                                                                                    }
                                                                                  }}
                                                                            />
                                                                            <div className="clear-amount" onClick={() => setAmountValue("")}>
                                                                                <img
                                                                                    src={'/img/v3/reload/close.png'}
                                                                                    alt="i8 banks"
                                                                                    className="img-responsive"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="display-flex flex-direction-column flex-align-baseline">
                                                                            {parseFloat(amountValue) >= min ?
                                                                                <div className="mt-1 color-white">
                                                                                    <span>{t("MAIN_WALLET_NEW_BALANCE") + " : "}</span>
                                                                                    <span>{numberWithCurrencyFormat2(parseFloat(currentWalletBalance), 2, true) + " "}</span>
                                                                                    <i className="fas fa-arrow-right" />
                                                                                    <span>{" " + numberWithCurrencyFormat2(parseFloat(currentWalletBalance) - (amountValue > 0 ? parseFloat(amountValue / _CURRENCY_RATIO) : 0), 2, true)}</span>
                                                                                </div>
                                                                                :
                                                                                <>
                                                                                </>
                                                                            }
                                                                            {/*<span className="custom-color-yellow mr-2 font-11 font-bold text-nowrap">{"(1 : IDR 1,000)"}</span>*/}
                                                                            {errors.amount && (
                                                                                <div className="invalid-feedback">
                                                                                    {t(errors.amount.message)}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        {min != 0 && max != 0 && (
                                                                            <div className="font-11 amount-reminder">
                                                                                <span className="reminder">
                                                                                    * {t("MIN")} : IDR {numberWithCommas(min)}/ {t("MAX")} : IDR {numberWithCommas(max)}
                                                                                </span>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="form-divider mt-4">
                                                                    <div className="withdrawal-name-alert alert alert-warning">
                                                                        <div className="withdrawal-warning-text">
                                                                            <img className="blue-warning-icon" src={BlueWarningIcon} />
                                                                            {t("FRINEDLY_REMINDER")}{t("WITHDRAWAL_NAME_WARNING")}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {stringIsNullOrEmpty(
                                                                    watch("memberBankAccount")
                                                                ) && (
                                                                        <div className="m-b-15">
                                                                            <div>
                                                                                <div className="form-checkbox circled-checkbox">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        name="remember"
                                                                                        id="remember"
                                                                                        onChange={(e) =>
                                                                                            setRemember(e.target.checked)
                                                                                        }
                                                                                        value={false}
                                                                                        checked={remember}
                                                                                        ref={register}
                                                                                    />
                                                                                    <label
                                                                                        htmlFor="remember"
                                                                                        className="font-15"
                                                                                    >
                                                                                        <i className="icon-box"></i>
                                                                                        {t("REMEMBER_BANK")}
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                <div>
                                                                    <button
                                                                        type="submit"
                                                                        className="depostit-submit-btn btn mb-2"
                                                                        disabled={hasRolloverBal}
                                                                    >
                                                                        {t("SUBMIT")}
                                                                    </button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                    </> : <>
                                                            <div className="withdrawal-cash card-brand mb-1 font-15 text-white font-semi">
                                                                <div className="member-card d-flex justify-content-between m-0">
                                                                    <div className="text-brand text-bold">
                                                                        {t("ROLLOVER_PROGRESS")}
                                                                    </div>
                                                                    <div className="d-flex align-items-center">
                                                                        <span className="text-yellow currency-left"></span>
                                                                        <strong className="text-brand ml-2" >{numberWithCurrencyFormat2(rolloverProgress, 2, true)} {t("/")} {numberWithCurrencyFormat2(rolloverTarget, 2, true)}</strong>

                                                                    </div>

                                                                </div>

                                                            </div>
                                                            <div className="offline-message">{t("WITHDRAWAL") + " " + t("ROLLOVER_NOT_REACH")}</div>
                                                        </>
                                                        }
                                                </div>
                                            </>
                                        ) :
                                            (
                                                <div className="offline-message">{t("WITHDRAWAL") + " " + t("OFFLINE_FROM") + " " + endOnlineTime + " " + t("TO_SMALL_LETTER") + " " + startOnlineTime + ". " + t("APOLOGIES_INCONVENIENCE")}</div>
                                            )
                                    }
                                            </div>
                                        
                                        </div>
                                    </div>
                    </>
                )}
            </section>
            {showPinEntry && (
                <form onSubmit={handlePinSubmit(onPinSubmit)}>
                    {/* <div> */}
                    <div className="pin-wrapper"></div>
                    <div className="pin-number">
                        <button
                            type="button"
                            style={{
                                alignSelf: "flex-end",
                                backgroundColor: "transparent",
                                border: "none",
                                marginRight: "15px",
                                fontSize: "2rem",
                                color: "#002e6c",
                                marginTop: "-15px",
                                float: "right",
                                fontWeight: "bold",
                            }}
                            onClick={() => {
                                clearInterval(theInterval);
                                setShowPinEntry(false);
                                // setPinNumberFinalFocused(0);
                            }}
                            data-dismiss="modal"
                            aria-hidden="true"
                        >
                            &times;
                        </button>
                        <strong
                            className="reload-title text-gold mb-3 d-block font-semi font-20"
                            style={{ marginLeft: "40px" }}
                        >
                            {t("ENTER_YOUR_PIN")}
                        </strong>
                        <div
                            onKeyDown={(e) => {
                                if (e.key === 'Backspace') {
                                    onVerifyPinKeyPress("{bksp}");
                                }
                            }}
                        >
                            <PinInput
                                style={{ marginBottom: "35px" }}
                                ref={eleFinalPin}
                                length={4}
                                initialValue=""
                                secret={true}
                                focus={true}
                                autoSelect={false}
                                // disabled={showPinEntry}
                                // onChange={(value, index) => {
                                //   setPinNumberFinalFocused(index);
                                // }}
                                onChange={(value) => {
                                    onVerifyPinKeyPress(value);
                                }}
                                type="numeric"
                                inputMode="number"
                                inputStyle={{
                                    border: "0",
                                    margin: "0px 10px",
                                    width: "40px",
                                    height: "40px",
                                    backgroundColor: "transparent",
                                    borderBottom: "2px solid #002e6c",
                                }}
                                inputFocusStyle={{
                                    borderBottom: "2px solid #FFC159",
                                }}
                                regexCriteria={/^[ 0-9_@./#&+-]*$/}
                            />
                        </div>

                        <strong
                            className="text-brand display-value"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setForgetPin(true);
                                sendPinCode();
                            }}
                        >
                            {t("RESET_PIN")}
                        </strong>
                        {/* <Keyboard
              layout={{
                default: ["1 2 3 4 5 6 7 8 9 . 0 {bksp}"],
              }}
              display={{
                "{bksp}": "<i class='fas fa-backspace'></i>",
              }}
              theme={`keyboard keyboard-numpad`}
              keyboardRef={(r) => (keyboard.current = r)}
              onKeyPress={onVerifyPinKeyPress}
              disableButtonHold
              disableCaretPositioning
            /> */}
                        {smsSent && (
                            <p
                                className="text-brand"
                                style={{
                                    textAlign: "center",
                                    marginTop: "5px",
                                }}
                            >
                                {t("PIN_SENT")} {t("RESEND_IN")} {countdownPeriod}{" "}
                                {t("SECOND")}
                            </p>
                        )}
                        {showHpErrorMessage && (
                            <p
                                className="text-brand text-red"
                                style={{ textAlign: "center", marginTop: "5px" }}
                            >
                                {t(hpErrorMessage)}
                            </p>
                        )}
                        {showAlert && (
                            <div
                                className="content content-full-width"
                                style={{
                                    position: "fixed",
                                    top: "10px",
                                    left: "0",
                                    width: "100%",
                                    zIndex: 9999,
                                }}
                            >
                                <div className="container">
                                    <Alert color="danger">
                                        {t("WAIT_TO_RESEND")}
                                    </Alert>
                                </div>
                            </div>
                        )}
                        <button
                            type="submit"
                            style={{
                                position: "relative",
                                marginTop: "30px",
                                // width: "250px",
                            }}
                            className="btn btn-block btn-brand-gradient btn-sticky-bottom"
                        >
                            {t("SUBMIT_TRANSACTION")}
                        </button>
                    </div>
                    {/* </div> */}
                </form>
            )}

            {
                //WITHDRAWAL STATUS
            }
            <Modal
              fade={false}
              contentClassName="modal-brand modal-bottom modal-reload-success"
              isOpen={isWithdrawalSuccess}
              toggle={() => {
                setIsWithdrawalSuccess(false);
              }}
              centered
            >
              <ModalBody>
                <div className="text-center">
                  <strong className="reload-title font-20 text-gold font-bold mb-3 d-block">
                    {t("WITHDRAWAL_SUCCESS")}
                  </strong>
                  <div className="text-bo font-16 text-darkblue font-semi">
                      {t("SUBMITTED_WITHDRAWAL", { amount: formatNumber(withdrawalAmount, 2) })}
                  </div>
                </div>
                <table className="table font-14 text-darkblue font-semi table-reload-detail">
                  <tr>
                    <th>{t("DATE")}</th>
                    <th style={{ textAlign: "right" }}>{withdrawalDate}</th>
                  </tr>
                  <tr>
                    <th>{t("REFERENCE_NUMBER")}</th>
                    <th style={{ textAlign: "right" }}>{transcactionNumber}</th>
                  </tr>
                </table>
                <button
                  className="btn btn-block btn-brand-gradient"
                  onClick={() => {
                    setIsWithdrawalSuccess(false);
                  }}
                >
                  {t("DONE")}
                </button>
              </ModalBody>
            </Modal>
            {
                //VERIFY PHONE
            }
            <Modal
                fade={false}
                contentClassName="modal-brand modal-bottom modal-numpad withdrawal"
                isOpen={showVerifyPhone}
                centered
            >
                <ModalBody style={{ minHeight: "30vh" }}>
                    <form onSubmit={handleVerifySubmit(verifyPhoneNumber)}>
                        <button
                            type="button"
                            style={{
                                backgroundColor: "transparent",
                                border: "none",
                                fontSize: "2rem",
                                color: "#002e6c",
                                marginTop: "-3%",
                                marginLeft: "90%",
                                fontWeight: "bold",
                            }}
                            onClick={() => {
                                clearInterval(theInterval);
                                setShowVerifyPhone(false);
                            }}
                            data-dismiss="modal"
                            aria-hidden="true"
                        >
                            &times;
                        </button>

                        <div className="display-wrapper withdrawal">
                            <strong
                                className="reload-title text-yellow mb-3 d-block"
                                style={{ fontSize: "20px" }}
                            >
                                {t("PHONE_VERIFICATION")}
                            </strong>
                            {!sentCode || countdownPeriod == 0 ? (
                                <strong
                                    className="text-brand display-value"
                                    style={{ fontSize: "10px", letterSpacing: "0.33px" }}
                                >
                                    {t("VERIFY_TO_CONTINUE")}
                                </strong>
                            ) : (
                                <p>
                                    {t("VERIFICATION_CODE_SENT")} {t("RESEND_IN")}{" "}
                                    {countdownPeriod}s
                                </p>
                            )}
                            <div
                                className="d-flex justify-content-center align-items-center"
                                style={{ width: "100%" }}
                            >
                                <div
                                    className="d-flex"
                                    style={{ marginTop: "20px", width: "80%" }}
                                >
                                    <PhoneNumber
                                        className="p-0"
                                        mainContainerStyle={{ marginBottom: "0", flex: 1 }}
                                        register={registerVerify}
                                        name={"phoneNumber"}
                                        value={watchVerify("phoneNumber")}
                                        errors={errorsVerify}
                                        onChange={(value, formattedValue) => {
                                            setValueVerify("phoneNumber", value);
                                            setFormattedPhoneNumber(formattedValue);
                                        }}
                                    />
                                </div>
                            </div>
                            <div style={{ width: "100%" }}>
                                <button
                                    type="button"
                                    className="btn btn-block btn-brand-gradient btn-done"
                                    onClick={() => sendVerificationCode()}
                                    disabled={sentCode ? true : false}
                                    style={{ width: "80%" }}
                                >
                                    {t("SEND_VERIFICATION_CODE")}
                                </button>
                            </div>
                        </div>
                        {sentCode ? (
                            <div style={{ width: "100%", textAlign: "-webkit-center" }}>
                                <div style={{ width: "80%" }}>
                                    <Input
                                        name={"verificationCode"}
                                        placeholder={t("ENTER_VERIFICATION_CODE")}
                                        type="tel"
                                        ref={registerVerify({
                                            required: "FIELD_REQUIRED",
                                            pattern: {
                                                value: /^[0-9]+$/,
                                                message: "INVALID_VERIFICATION_CODE",
                                            },
                                        })}
                                        errors={errorsVerify}
                                    />
                                </div>
                                <div style={{ width: "100%" }}>
                                    <button
                                        type="submit"
                                        className="btn btn-block btn-brand-gradient"
                                        style={{ width: "80%" }}
                                    >
                                        {t("SUBMIT")}
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div style={{ width: "100%", textAlign: "-webkit-center" }}>
                                <div style={{ width: "80%" }}>
                                    <Input
                                        name={"verificationCode"}
                                        placeholder={t("ENTER_VERIFICATION_CODE")}
                                        type="tel"
                                        ref={registerVerify({
                                            required: "FIELD_REQUIRED",
                                            pattern: {
                                                value: /^[0-9]+$/,
                                                message: "INVALID_VERIFICATION_CODE",
                                            },
                                        })}
                                        errors={errorsVerify}
                                        readOnly
                                    />
                                </div>
                            </div>
                        )}
                    </form>
                </ModalBody>
            </Modal>

            {
                //PHONE STATUS
            }
            {/* <Numpad
        currentBalance={_userData.walletBalance}
        currentInput={currentInput}
        setCurrentInput={(e) => {
          setCurrentInput(e);
        }}
      /> */}
            {
                //CREATE NEW PIN
            }
            <Modal
                fade={false}
                contentClassName="modal-brand modal-bottom modal-numpad"
                isOpen={createPinEntry}
                centered
            >
                <ModalBody>
                    <form>
                        <div className="display-wrapper" style={{ textAlign: "center" }}
                            onKeyDown={(e) => {
                                if (e.key === 'Backspace') {
                                    onCreatePinKeyPress("{bksp}");
                                }
                            }}
                        >
                            <strong
                                className="reload-title text-yellow mb-3 d-block"
                                style={{ fontSize: "20px" }}
                            >
                                {t("ENTER_YOUR_PIN")}
                            </strong>
                            <strong
                                className="reload-title mb-3 d-block"
                                style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    color: "#002e6c",
                                }}
                            >
                                {t("ENTER_YOUR_PIN")}
                            </strong>
                            <PinInput
                                ref={elePin}
                                length={4}
                                initialValue=""
                                secret={true}
                                focus={true}
                                autoSelect={false}
                                // disabled={createPinEntry}
                                type="numeric"
                                onChange={(value, index) => {
                                    setPinNumberFocused(index);
                                    onCreatePinKeyPress(value);
                                }}
                                inputMode="numeric"
                                inputStyle={{
                                    border: "0",
                                    margin: "0px 10px",
                                    width: "40px",
                                    height: "40px",
                                    backgroundColor: "transparent",
                                    borderBottom: "2px solid #002e6c",
                                }}
                                inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                                regexCriteria={/^[ 0-9_@./#&+-]*$/}
                            />
                            <strong
                                className="reload-title mb-3 d-block"
                                style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    color: "#002e6c",
                                    marginTop: "30px",
                                }}
                            >
                                {t("CONFIRM_YOUR_PIN")}
                            </strong>
                            <PinInput
                                ref={eleConfirm}
                                length={4}
                                initialValue=""
                                secret={true}
                                focus={true}
                                autoSelect={false}
                                // disabled={createPinEntry}
                                type="numeric"
                                onChange={(value, index) => {
                                    setPinNumberConfirmFocused(index);
                                    onCreatePinKeyPress(value);
                                }}
                                inputMode="numeric"
                                inputStyle={{
                                    border: "0",
                                    margin: "0px 10px",
                                    width: "40px",
                                    height: "40px",
                                    backgroundColor: "transparent",
                                    borderBottom: "2px solid #002e6c",
                                }}
                                inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                                regexCriteria={/^[ 0-9_@./#&+-]*$/}
                            />
                            <div
                                className="invalid-feedback"
                                style={{ fontSize: "12px", marginTop: "1rem" }}
                            >
                                {invalidPin && t("PIN_DOES_NOT_MATCH")}
                                {!invalidPin && <span>&nbsp;</span>}
                            </div>
                            {/* <Keyboard
                layout={{
                  default: ["1 2 3 4 5 6 7 8 9 . 0 {bksp}"],
                }}
                display={{
                  "{bksp}": "<i class='fas fa-backspace'></i>",
                }}
                theme={`keyboard keyboard-numpad`}
                keyboardRef={(r) => (keyboard.current = r)}
                onKeyPress={onCreatePinKeyPress}
                disableButtonHold
                disableCaretPositioning
              /> */}
                        </div>
                    </form>
                </ModalBody>
            </Modal>
            {
                //CREATE NEW USER FULL NAME
            }
            <Modal
                fade={false}
                contentClassName="modal-brand modal-bottom modal-numpad"
                isOpen={showFullnamePopup}
                centered
            >
                <ModalBody>
                    <form onSubmit={handleFullNameSubmit(submitFullName)}>
                        <div className="display-wrapper text-center">
                            <strong
                                className="reload-title text-yellow d-block"
                                style={{ fontSize: "20px" }}
                            >
                                {t("ENTER_YOUR_FULL_NAME")}
                            </strong>
                            <strong
                                className="reload-title text-yellow mb-3 d-block"
                                style={{ fontSize: "12px", fontStyle: "italic" }}
                            >
                                {t("IN_YOUR_BANK_ACCOUNT")}
                            </strong>
                            <input
                                type="text"
                                name="userFullName"
                                className="withdrawal-fullName-input"
                                ref={registerFullName({
                                    required: "FIELD_REQUIRED",
                                    pattern: { value: /^[a-z A-Z]+$/, message: "INVALID_NAME" },
                                })}
                                onChange={(e) =>
                                    setValueFullName("userFullName", e.target.value)
                                }
                            />
                            {errorsFullName.userFullName && (
                                <div className="invalid-feedback">
                                    {t(errorsFullName.userFullName.message)}
                                </div>
                            )}
                            <button
                                type="submit"
                                className="btn btn-block btn-brand-gradient mx-auto"
                                style={{ width: "200px" }}
                            >
                                {t("SUBMIT")}
                            </button>
                        </div>
                    </form>
                </ModalBody>
            </Modal>
            {
                //BANK ACCOUNT NUMBER KEYPAD
            }
            {/* <Modal
        fade={false}
        contentClassName="modal-brand modal-bottom modal-numpad"
        isOpen={showBankAccEntry}
        centered
      >
        <ModalBody>
          <div className="display-wrapper">
            <button
              type="button"
              style={{
                alignSelf: "flex-end",
                backgroundColor: "transparent",
                border: "none",
                marginRight: "15px",
                fontSize: "2rem",
                color: "#002e6c",
                marginTop: "-15px",
                fontWeight: "bold",
              }}
              onClick={() => setShowBankAccEntry(false)}
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span>&times;</span>
            </button>
            <div class="text-center">
              <strong style={{ color: "#002E6C" }}>
                {t("BANK_ACCOUNT_NUMBER")}
              </strong>
            </div>
            <br />
            {bankAccEntered.length !== 0 ? (
              <div class="text-center">
                <strong className="text-brand display-value">
                  {bankAccEntered}
                </strong>
              </div>
            ) : (
              <div class="text-center">
                <strong className="text-brand display-value"></strong>
              </div>
            )}
          </div>
          <Keyboard
            layout={{
              default: ["1 2 3 4 5 6 7 8 9 . 0 {bksp}"],
            }}
            display={{
              "{bksp}": "<i class='fas fa-backspace'></i>",
            }}
            theme={`keyboard keyboard-numpad`}
            keyboardRef={(r) => (keyboard.current = r)}
            onKeyPress={onBankAccKeyPress}
            disableButtonHold
            disableCaretPositioning
          />
          <div style={{ marginTop: "25px" }}>
            <button
              type="button"
              className="btn btn-block btn-brand"
              onClick={() => setShowBankAccEntry(false)}
            >
              {t("SUBMIT")}
            </button>
          </div>
        </ModalBody>
      </Modal> */}
        </>
    );
};

export default Withdrawal;

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  ApiKey,
  ApiUrl,
  TransactionType,
  PanelType,
} from "../../util/Constant";
import { createMultiPartFormBody, stringIsNullOrEmpty } from "../../util/Util";
import ApiEngine from "../../util/ApiEngine";
import { useForm, Controller } from "react-hook-form";
import SelectOption from "../../components/custom/SelectOption";
import { useDispatch } from "react-redux";
import { setBusy, setIdle, showResponseMessage } from "../../redux/AppAction";
import { isUndefined } from "lodash";

const FattyPayDeposit = (props) => {
  const _RECEIPT_REFERENCE = "Fatty Pay";
  const { t } = useTranslation();
  let _history = useHistory();
  let _dispatch = useDispatch();
  const { handleSubmit, register, control, watch, setValue, errors, reset } =
    useForm();
  const [bankOption, setBankOption] = useState([]);
  const [bankOption2, setBankOption2] = useState([]);
  const [maxDeposit, setMaxDeposit] = useState(0);
  const [minDeposit, setMinDeposit] = useState(0);
  const [serviceCharge, setServiceCharge] = useState();
  const [currentInput, setCurrentInput] = useState();
  const [gateways, setGateways] = useState(props.gatewayOption);
  const [paymentOption, setPaymentOption] = useState(props.paymentOption);
  const [defaultBank, setDefaultBank] = useState(0);

  useEffect(() => {
    init();
  }, []);

  async function init() {
    if (!isUndefined(gateways["provider"][1])) {
      if (gateways["provider"][1]["name"] === "OneWallet") {
        let bankList = [];
        let bankList2 = [];
        setValue("bankId", gateways["provider"][1]["instantBankSettingModel"][0].id);
        // setDefaultBank(gateways["instantBankSettingModel"][0].id);
        setMaxDeposit(gateways["provider"][1]["instantBankSettingModel"][0].maximumDeposit);
        setMinDeposit(gateways["provider"][1]["instantBankSettingModel"][0].minimumDeposit);
        gateways["provider"][1]["instantBankSettingModel"].map((bank) => {
          if (bank.id !== 13)
            bankList.push({ label: bank.name, value: bank.bankCode });
          //bankList2.push({ label: bank.name, value: bank.id, data: bank });
          bankList2.push({ label: bank.name, value: bank.bankCode, data: bank });
        })
        var temp = gateways["provider"][1]["parameter_1"].split(",");
        temp.map((param) => {
          let pairArr = param.split(":");
          bankList.push({ label: pairArr[0], value: pairArr[1] });
        });
        setBankOption(bankList);
        setBankOption2(bankList2);
        setServiceCharge(gateways["provider"][1]["serviceCharge"]);
      } else if (gateways["provider"][0]["name"] === "FattyPay") {
        let bankList = [];
        let bankList2 = [];
        setValue("bankId", gateways["provider"][0]["instantBankSettingModel"][0].id);
        // setDefaultBank(gateways["instantBankSettingModel"][0].id);
        setMaxDeposit(gateways["provider"][0]["instantBankSettingModel"][0].maximumDeposit);
        setMinDeposit(gateways["provider"][0]["instantBankSettingModel"][0].minimumDeposit);
        gateways["provider"][0]["instantBankSettingModel"].map((bank) => {
          if (bank.id !== 13)
            bankList.push({ label: bank.name, value: bank.bankCode });
          //bankList2.push({ label: bank.name, value: bank.id, data: bank });
          bankList2.push({ label: bank.name, value: bank.bankCode, data: bank });
        })
        var temp = gateways["provider"][0]["parameter_1"].split(",");
        temp.map((param) => {
          let pairArr = param.split(":");
          bankList.push({ label: pairArr[0], value: pairArr[1] });
        });
        setBankOption(bankList);
        setBankOption2(bankList2);
        setServiceCharge(gateways["provider"][0]["serviceCharge"]);
      }
    } else if (!isUndefined(gateways["provider"][0])) {
      if (gateways["provider"][0]["name"] === "FattyPay") {
        let bankList = [];
        let bankList2 = [];
        setValue("bankId", gateways["provider"][0]["instantBankSettingModel"][0].id);
        // setDefaultBank(gateways["instantBankSettingModel"][0].id);
        setMaxDeposit(gateways["provider"][0]["instantBankSettingModel"][0].maximumDeposit);
        setMinDeposit(gateways["provider"][0]["instantBankSettingModel"][0].minimumDeposit);
        gateways["provider"][0]["instantBankSettingModel"].map((bank) => {
          if (bank.id !== 13)
            bankList.push({ label: bank.name, value: bank.bankCode });
          //bankList2.push({ label: bank.name, value: bank.id, data: bank });
          bankList2.push({ label: bank.name, value: bank.bankCode, data: bank });
        })
        var temp = gateways["provider"][0]["parameter_1"].split(",");
        temp.map((param) => {
          let pairArr = param.split(":");
          bankList.push({ label: pairArr[0], value: pairArr[1] });
        });
        setBankOption(bankList);
        setBankOption2(bankList2);
        setServiceCharge(gateways["provider"][0]["serviceCharge"]);
      }
    } else {
      _history.goBack();
    }
  }

  async function onSubmit(data, e) {
    _dispatch(setBusy());
    var bankData = bankOption2[Math.floor(Math.random() * bankOption2.length)];
    // var bankData = bankOption.filter(
    //   (option) => option.value == data.bankId
    // )[0];

    let params = {
      transactionTypeId: TransactionType._DEPOSIT,
      bankId: data.bankId,
      amount: data.amount,
      // bankCode: bankData.data.bankCode,
      bankCode: data.bankId,
      //channel: gateways["provider"][1] !== undefined ? gateways["provider"][1]["instantPayGatewayId"] : gateways["provider"][0]["instantPayGatewayId"],
        channel: gateways["provider"].find(x => x.name == "OneWallet").instantPayGatewayId,
      panel: PanelType._MEMBERSITE,
      instantPayBankSettingId: bankData.data.id,
      currency: bankData.data.currency,
      instantPay: true,
      receiptReference:
            //_RECEIPT_REFERENCE + "(" + gateways["provider"][1] !== undefined ? gateways["provider"][1]["instantPayGatewayString"] : gateways["provider"][0]["instantPayGatewayString"] + ")",
          _RECEIPT_REFERENCE + "(" + gateways["provider"].find(x => x.name == "OneWallet").instantPayGatewayString + ")",
      bankAccountId: bankData.data.bankId,
    };

    if (!stringIsNullOrEmpty(props.bonusDeposit) && props.bonusDeposit != "-1") {
      params["bonusId"] = props.bonusDeposit;
    } else if (props.bonusDeposit == "-1") {
      params["bonusId"] = "";
    }

    let responseJson = await ApiEngine.post(
      ApiUrl._API_CREATE_TRANSACTION,
      createMultiPartFormBody(params)
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      _dispatch(setBusy(true, t("PLEASE_WAIT_REDIRECTING")));

      e.target.reset();
      //props.setTriggerParentInit(true);
      props.parentInit();
      setValue("bankId", "");
      window.location.href = responseJson[ApiKey._API_DATA_KEY];
      _dispatch(setIdle());
    } else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }
    _dispatch(setIdle());
  }

  return (
    <>
      {parseFloat(serviceCharge) > 0 && (
        <div>
          {parseFloat(serviceCharge) !== 0 && (
            <div className="col-md-12 px-0 pt-3">
              <div className="reminder font-15 text-white font-semi">
                {t("SERVICE_CHARGE_DISCLAIM") +
                  serviceCharge +
                  "%" +
                  t("SERVICE_CHARGE_DISCLAIM_BACK")}
              </div>
            </div>
          )}
        </div>
      )}
      <div className="row">
        <div className="col-md-12">
          <form id="spgdeposit" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-divider mt-4">
              <div className="form-group m-b-15 px-0">
                <label className="font-15 text-white font-semi mb-3">
                  {t("BANK")}
                </label>
                <div>
                  <Controller
                    control={control}
                    name="bankId"
                    defaultValue=""
                    render={({ onChange, value }) => {
                      return (
                        <SelectOption
                          options={bankOption}
                          placeholder={t("PLEASE_SELECT_BANK")}
                          value={value}
                          onChange={(e) => {
                            var bankData = bankOption.filter(
                              (option) => option.value == e.value
                            )[0];
                            //setMaxDeposit(bankData.data.maximumDeposit);
                            //setMinDeposit(bankData.data.minimumDeposit);
                            onChange(e.value);
                          }}
                        />
                      );
                    }}
                    rules={{ required: "PLEASE_SELECT_BANK" }}
                  />
                  {errors.bankId && (
                    <div className="invalid-feedback">
                      {t(errors.bankId.message)}
                    </div>
                  )}
                </div>
              </div>

              <div className="form-group m-b-15 px-0">
                <label className="font-15 text-white font-semi mb-3">
                  {t("AMOUNT")}
                </label>
                <div>
                  <input
                    type="text"
                    className="form-white-input"
                    placeholder={t("PLEASE_ENTER_AMOUNT")}
                    name="amount"
                    onClick={(e) => {
                      setCurrentInput(e.target);
                    }}
                    ref={register({
                      required: "PLEASE_ENTER_AMOUNT",
                      validate: {
                        minValue: (value) =>
                          (parseFloat(value) > 0 && value >= minDeposit) ||
                          "VALUE_LESS_THAN_MINIMUM",
                        maxValue: (value) =>
                          parseFloat(value) <= maxDeposit ||
                          "EXCEED_MAXIMUM_VALUE",
                      },
                      pattern: {
                        value: /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,3})?$/,
                        message:
                          "INVALID_AMOUNT",
                      },
                    })}
                  />
                  {errors.amount && (
                    <div className="invalid-feedback">
                      {t(errors.amount.message)}
                    </div>
                  )}
                  {minDeposit != 0 && maxDeposit != 0 && (
                    <div className="font-11 amount-reminder">
                      <span className="reminder">
                        * {t("MIN")} : {minDeposit}/ {t("MAX")} : {maxDeposit}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group mt-3 col-md-12 px-0">
              <button type="submit" className="custom-btn-style-1">
                {t("SUBMIT")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default FattyPayDeposit;
import React, { useState, useEffect } from "react";
//import "react-simple-keyboard/build/css/index.css";
import { isNull, map } from "lodash";
import ApiCms from "util/ApiCms";
import { useHistory, useRouteMatch } from "react-router-dom";
import { WebUrl, SessionKey, LanguageOption } from "../../util/Constant";
import {
  showResponseMessage,
  setBusy,
  setIdle,
} from "../../redux/AppAction.js";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const HelpCentre = (props) => {
  const { t, i18n } = useTranslation();
  const { params } = useRouteMatch(WebUrl._URL_HELP_CENTRE_CATEGORY);
  const { category: Parent_Category } = params;

  const [categoryID, setCategoryID] = useState("");
  const [scrollTop, setScrollTop] = useState(0);
  const [isShowHelpCentreCategory, setIsShowHelpCentreCategory] =
    useState(false);
  const [breadcrumbs, updateBreadcrumbs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [parentCategories, setParentCategories] = useState([]);
  // const [lang, setLang] = useState("");
  let _history = useHistory();
  var _dispatch = useDispatch();

  const lang = (localStorage.getItem(SessionKey._LANGUAGE)&& localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ) ? 
  (LanguageOption.find(
    (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)) != undefined? 
      LanguageOption.find(
      (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
      ).code : 
      LanguageOption[0].code
    ): 
    LanguageOption[0].code

  const onClick = (breadcrumbItems) => {
    updateBreadcrumbs((arr) => [...arr, breadcrumbItems]);
  };

  useEffect(() => {
    const scrollEvent = () => {
      window.addEventListener("scroll", (e) => {
        setScrollTop(e.target.documentElement.scrollTop);
      });
    };
    scrollEvent();

    return () => {
      // window.removeEventListener("scroll");
    };
  }, []);

  useEffect(() => {
    fetchData();

    // setLang(
    //   LanguageOption.find(
    //     (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
    //   ).code
    // );
  }, [

  //  (lang)
    // LanguageOption.find(
    //   (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
    // ).code,

    (localStorage.getItem(SessionKey._LANGUAGE)&& localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ) ? 
  (LanguageOption.find(
    (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)) != undefined? 
      LanguageOption.find(
      (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
      ).code : 
      LanguageOption[0].code
    ): 
    LanguageOption[0].code
  ]);

  async function fetchData() {
    _dispatch(setBusy());

    const { data } = await ApiCms.get(
      "/sub-categories" +
        "?locale=" +
        // LanguageOption.find(
        //   (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
        // ).code 
        
       ( lang )
        +
        "&populate=*"
    );
    const { data: categoriesList } = data;

    setCategories(categoriesList);

    _dispatch(setIdle());
  }

  useEffect(() => {
    const fetchParent = async () => {
      const { data } = await ApiCms.get(
        "/categories" +
          "?locale=" +
          // LanguageOption.find(
          //   (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
          // ).code 

         (lang)
          +
          "&populate=*"
      );
      const { data: parentCategoriesList } = data;
      setParentCategories(parentCategoriesList);

      {
        parentCategoriesList.length &&
          parentCategoriesList.map((item, index) => {
            if (item.attributes.slug === Parent_Category)
              setCategoryID(item.attributes.category_id);
          });
      }
    };
    fetchParent();
  }, [(localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
  localStorage.getItem(SessionKey._LANGUAGE) : 'en')]);

  useEffect(() => {
    setCategory(categoryID);
  }, [params]);

  async function GoToCategory(slug, category_id) {
    if (slug) {
      _history.push({
        pathname: WebUrl._URL_HELP_CENTRE_CATEGORY.replace(":category", slug),
        state: {
          category_id: category_id,
        },
      });
    }
  }

  async function GoToSubCategory(slug, sub_slug, category_id, sub_category_id) {
    if (slug) {
      _history.push({
        pathname: WebUrl._URL_HELP_CENTRE_SUB_CATEGORY
          .replace(":category", slug)
          .replace(":sub_category", sub_slug),
        state: {
          category_id: category_id,
          sub_category_id: sub_category_id,
        },
      });
    }
  }

  async function GoToFaq(
    slug,
    sub_slug,
    faq,
    category_id,
    sub_category_id,
    faq_id
  ) {
    if (sub_slug) {
      _history.push({
        pathname: WebUrl._URL_HELP_CENTRE_FAQ
          .replace(":category", slug)
          .replace(":sub_category", sub_slug)
          .replace(":faq", faq),
        state: {
          category_id: category_id,
          sub_category_id: sub_category_id,
          faq_id: faq_id,
        },
      });
    }
  }

  return (
    <>
      <section className="help-centre-section">
        <div className="container" id="help-centre-banner">
          {category && (
            <ul className="help-centre-breadcrumbs breadcrumbs list-unstyled ">
              <li>
                <a
                  href={WebUrl._URL_HELP_CENTRE}
                  className="text-white font-semi"
                >
                  {t("HELP_CENTRE")}
                </a>
              </li>
              <li>
                {parentCategories.length &&
                  parentCategories.map((item, index) => {
                    return (
                        !isNull(item.attributes.publishedAt) && ((!item.attributes.slug.includes("_")) || item.attributes.slug.match("_idr")) && 
                      item.attributes.category_id === category && (
                        <a className="text-white font-semi">
                          {item.attributes.title}
                        </a>
                      )
                    );
                  })}
              </li>
            </ul>
          )}
          <div className="help-centre-sub-list-container row px-5 d-flex justify-content-start">
            <div className="help-centre-sub-list-wrapper d-flex justify-content-between">
              {category &&
                categories.length &&
                categories.map((item, index) => {
                  return (
                      !isNull(item.attributes.publishedAt) && ((!item.attributes.slug.includes("_")) || item.attributes.slug.match("_idr")) && 
                    item.attributes.category.data.attributes.category_id ===
                      category && (
                      <>
                        {/* <div className="help-centre-sub-list-flexbox help-centre-margin"> */}
                        <div
                          //  onClick={() => GoToSubCategory(item.attributes.category.data.attributes.title, item.attributes.Title)}
                          className="d-flex justify-content-between help-centre-first-list help-centre-margin help-centre-sub-list-flexbox"
                        >
                          <div>
                            <div className="help-centre-list-title text-white font-semi">
                              {item.attributes.Title}
                            </div>
                            {item.attributes.faqs.data
                              .slice(0, 3)
                              .map((item1) => {
                                return (
                                    !isNull(item1.attributes.publishedAt) && ((!item1.attributes.slug.includes("_")) || item1.attributes.slug.match("_idr")) && (
                                    <div className="help-centre-list-item text-white">
                                      <a
                                        onClick={() =>
                                          GoToFaq(
                                            item.attributes.category.data
                                              .attributes.slug,
                                            item.attributes.slug,
                                            item1.attributes.slug,
                                            item.attributes.category.data
                                              .attributes.category_id,
                                            item.attributes.sub_category_id,
                                            item1.attributes.faq_id
                                          )
                                        }
                                        className="list-link cursor-pointer"
                                      >
                                        {item1.attributes.Title}
                                      </a>
                                    </div>
                                  )
                                );
                              })}
                            <div>
                              <a
                                onClick={() =>
                                  GoToSubCategory(
                                    item.attributes.category.data.attributes
                                      .slug,
                                    item.attributes.slug,
                                    item.attributes.category_id,
                                    item.attributes.sub_category_id
                                  )
                                }
                                className="text-gold font-11 cursor-pointer"
                              >
                                {t("SEE_ALL")}
                              </a>
                            </div>
                          </div>
                        </div>
                        {/* </div> */}
                      </>
                    )
                  );
                })}
            </div>
            <div className="col help-centre-side-list-margin">
              <div className="d-flex justify-content-center">
                <div>
                  <div className="help-centre-side-list list-group">
                    {category &&
                      parentCategories.length &&
                      parentCategories.map((item, index) => {
                        return (
                            !isNull(item.attributes.publishedAt) && ((!item.attributes.slug.includes("_")) || item.attributes.slug.match("_idr")) && (
                            <>
                              <a
                                href={WebUrl._URL_HELP_CENTRE_CATEGORY.replace(
                                  ":category",
                                  item.attributes.slug
                                )}
                                className={
                                  item.attributes.category_id === category
                                    ? "list-group-item list-group-item-action active font-semi text-white font-16 cursor-pointer"
                                    : "list-group-item list-group-item-action font-semi text-white font-16 cursor-pointer"
                                }
                              >
                                {item.attributes.title}
                              </a>

                              <div className="list-group">
                                {item.attributes.category_id === category &&
                                  item.attributes.sub_categories.data.length &&
                                  item.attributes.sub_categories.data.map(
                                    (item1) => {
                                      return (
                                        !isNull(
                                            item1.attributes.publishedAt) && ((!item1.attributes.slug.includes("_")) || item1.attributes.slug.match("_idr")) && (
                                          <a
                                            href={WebUrl._URL_HELP_CENTRE_SUB_CATEGORY
                                              .replace(
                                                ":category",
                                                item.attributes.slug
                                              )
                                              .replace(
                                                ":sub_category",
                                                item1.attributes.slug
                                              )}
                                            className="list-group-item font-12"
                                          >
                                            {item1.attributes.Title}
                                          </a>
                                        )
                                      );
                                    }
                                  )}
                              </div>
                            </>
                          )
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default HelpCentre;

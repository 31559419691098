import React from 'react';
import Select from 'react-select';

/// <summary>
/// Author : -
/// </summary>
const StyledSelect = props => {
    const { options, placeholder = "", value, onChange, ...otherProps } = props;
    const _SELECT_STYLES = {
        menu: (styles, state) => ({
            ...styles,
        }),
        control: (styles, state) => ({
            ...styles,
            borderRadius: '0px',
            background:"transparent",
            height: '40px',
        }),
        singleValue: styles => ({
            ...styles,
            color: '#fff',
            padding: "2px 0px"
        }),
        placeholder: styles => ({
            ...styles,
            color:'#fff'
        })
    }

    return (
       <div className='custom-date-select'>
         <Select
            options={options} isSearchable={false}
            styles={_SELECT_STYLES}
            placeholder={(options.filter(option => option.value == value)[0] !== undefined) ? (options.filter(option => option.value == value)[0].label) : placeholder}
            value={options.filter(option => option.value == value)}
            onChange={(e) => {
                onChange(e);
            }}
            {...otherProps} />
       </div>
    )
}

export default StyledSelect;
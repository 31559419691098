import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  ApiKey,
  ApiUrl,
  TransactionType,
  PanelType,
  _CURRENCY_RATIO,
  FixedDepositAmount
} from "../../util/Constant";
import {
  stringIsNullOrEmpty,
  createMultiPartFormBody,
  numberWithCurrencyFormat,
  numberWithCommas,
  numberWithCurrencyFormat2
} from "../../util/Util";
import ApiEngine from "../../util/ApiEngine";
import { useForm } from "react-hook-form";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { setBusy, setIdle, showResponseMessage } from "../../redux/AppAction";
import { Alert } from "reactstrap";
import Spinner from 'react-bootstrap/Spinner';
import classnames from "classnames";

//idr deposit image
import PowerPayIcon from "../../assets/img/icon/PowerPay.png";
import YPayIcon from "../../assets/img/icon/YPay.png";
import SmartPayIcon from "../../assets/img/icon/SmartPay.png";
import TickIcon from "../../assets/img/assets/tick.svg";
import PowerPayVAIcon from "../../assets/img/icon/PowerPay.png";
import StarPayIcon from "../../assets/img/icon/StarPay.png";
import LarkPayIcon from "../../assets/img/icon/Larkpay.png";

const InstantDeposit = (props) => {
  const { t } = useTranslation();
  let _dispatch = useDispatch();
  const {
    handleSubmit,
    errors,
    clearErrors,
    setError,
  } = useForm();
  const [showCopyAlert, setShowCopyAlert] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const _userData = useSelector(state => state.authState.userData);
  const [bankIsSelected, setBankIsSelected] = useState(false);
  const [selectedBank, setSelectedBank] = useState({});
  const fixedAmount = FixedDepositAmount;
  const [instantPaymentList, setInstantPaymentList] = useState([]);
  const [loadingPaymentData, setLoadingPaymentData] = useState(true);
  const [paymentOptionList, setPaymentOptionList] = useState([]);
  const [selectedProviderName, setSelectedProviderName] = useState("");
  const [amountValue, setAmountValue] = useState("");
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [serviceCharge, setServiceCharge] = useState(0);

  //useEffect 
  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  useEffect(() => {
    if (props.memberTransactionHistory.length > 0 && paymentOptionList.length > 0) {
      initMemberTransactionHistoryData();
    }
  }, [paymentOptionList, props.memberTransactionHistory]);

  //init/call function

  async function init() {
    getPaymentList();
  }

  const getPaymentList = async () => {
    let memberJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_DETAILS_BY_ID);
    let providerOptions = [];

    let responseJson = await ApiEngine.get(
      ApiUrl._API_GET_USER_PAYMENT_GATEWAY_INFO + "?all=false"
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data1 = [];

      if (memberJson[ApiKey._API_DATA_KEY]["vipId"] == 2)
        data1 = responseJson[ApiKey._API_DATA_KEY];
      else
        data1 = responseJson[ApiKey._API_DATA_KEY].filter((x) => !x.isVIPOnly);

      setInstantPaymentList(responseJson[ApiKey._API_DATA_KEY]);

      /*puishan test gateway preprod comment first*/
      //data1.filter(y => y.status === true && y.isUmStatus === false).map((option, index) => {
      //test starPay zy
      data1.filter(y => y.isUmStatus === false).map((option, index) => {
      //data1.map((option, index) => {
        option.instantBankSettingModel.map((option2, index2) => {
          let foundIndex = providerOptions.findIndex(x => x.bankId === option2.bankId && x.paymentType === option.paymentType);
          if (foundIndex === -1) {
            let temProvider = [];
            temProvider.push({
              name: option.instantPayGatewayString,
              image: option.instantPayGatewayString === "PowerPay88" || option.instantPayGatewayString === "PowerPay88 QR" ? PowerPayIcon : option.instantPayGatewayString === "YPay" || option.instantPayGatewayString === "YPay Ewallet" ? YPayIcon : option.instantPayGatewayString === "SmartPay" || option.instantPayGatewayString === "SmartPay Ewallet" ? SmartPayIcon : option.instantPayGatewayString === "PowerPay88 VA" ? PowerPayVAIcon : option.instantPayGatewayString == "StarPay" || option.instantPayGatewayString === "StarPay QR" ? StarPayIcon : option.instantPayGatewayString === "LarkPay" || option.instantPayGatewayString === "LarkPay Ewallet" ? LarkPayIcon
                : null,
            });
            providerOptions.push({
              bankId: option2.bankId,
              name: option2.name,
              status: option2.status,
              image: option2.bankLogo2,
              paymentType: option.paymentType,
              availableProvider: temProvider,
              bankInstantRank: option2.bankInstantRank
            });
          } else {
            providerOptions[foundIndex].availableProvider.push({
              name: option.instantPayGatewayString,
              image: option.instantPayGatewayString === "PowerPay88" || option.instantPayGatewayString === "PowerPay88 QR" ? PowerPayIcon : option.instantPayGatewayString === "YPay" || option.instantPayGatewayString === "YPay Ewallet" ? YPayIcon : option.instantPayGatewayString === "SmartPay" || option.instantPayGatewayString === "SmartPay Ewallet" ? SmartPayIcon : option.instantPayGatewayString === "PowerPay88 VA" ? PowerPayVAIcon : option.instantPayGatewayString == "StarPay" || option.instantPayGatewayString === "StarPay QR" ? StarPayIcon : option.instantPayGatewayString === "LarkPay" || option.instantPayGatewayString === "LarkPay Ewallet" ? LarkPayIcon
                : null,
            });
          }
        })
      });

      setPaymentOptionList(providerOptions.sort((a, b) => a.bankInstantRank - b.bankInstantRank));
      if (providerOptions.length <= 0) {
        props.setDisplayInstantDeposit(false);
      }
    }
    setLoadingPaymentData(false);
  }

  const initMemberTransactionHistoryData = async () => {
    let found = props.memberTransactionHistory.filter(x => x.isInstant && x.transactionTypeId === TransactionType._DEPOSIT);
    if (found.length > 0) {
      if (paymentOptionList.filter(x => x.bankId === found[0].instantBankId).length > 0) {
        setBankIsSelected(true);
        setSelectedBank(paymentOptionList.filter(x => x.bankId === found[0].instantBankId)[0]);
        handleProviderSelection(found[0].instantProviderName, paymentOptionList.filter(x => x.bankId === found[0].instantBankId)[0]);
      }
    }
  }

  //other fucntion
  function handleProviderSelection(providerName, bank) {
    let index = instantPaymentList.findIndex((x) => x.instantPayGatewayString === providerName);
    if (index !== -1) {
      if (instantPaymentList[index]["instantBankSettingModel"].filter(x => x.bankId === bank.bankId).length > 0) {
        setMin(instantPaymentList[index]["instantBankSettingModel"].filter(x => x.bankId === bank.bankId)[0].minimumDeposit);
        setMax(instantPaymentList[index]["instantBankSettingModel"].filter(x => x.bankId === bank.bankId)[0].maximumDeposit);
        setServiceCharge(instantPaymentList[index]["serviceCharge"]);
        setSelectedProviderName(providerName);
      }
    }
  }

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const handleBankSelection = async (bank) => {
    setBankIsSelected(true);
    setSelectedBank(bank);
    if (paymentOptionList.filter(x => x === bank && x.status === true)[0].availableProvider.length > 0) {
      handleProviderSelection(paymentOptionList.filter(x => x === bank && x.status === true)[0].availableProvider[0].name, bank);
    }
  }

  function resetFormField() {
    setSelectedProviderName("");
    setBankIsSelected(false);
    setSelectedBank({});
    setMin(0);
    setMax(0);
    setServiceCharge(0);
    setAmountValue("");
  }

  //const checkValueOnKeyDown = async (e) => {
  //  clearErrors();
  //  let tempAmountValue = amountValue;
  //  if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) { //keyCode of 0 to 9, numpad 0 to 9
  //    tempAmountValue = amountValue + "" + e.key;
  //    setAmountValue(tempAmountValue);
  //  }
  //  else if (e.keyCode === 8 || e.keyCode === 46) //keyCode of backSpace and Del
  //  {
  //    tempAmountValue = amountValue.slice(0, -1);
  //    setAmountValue(tempAmountValue);
  //  }
  //  if (parseFloat(tempAmountValue) < min) {
  //    setError("amount", {
  //      message: "VALUE_LESS_THAN_MINIMUM",
  //    });
  //  }
  //  else if (parseFloat(tempAmountValue) > max) {
  //    setError("amount", {
  //      message: "EXCEED_MAXIMUM_VALUE",
  //    });
  //  }
  //}

  const checkAmountForErrors = async (tempAmountValue) => {
    clearErrors();
    if (parseFloat(tempAmountValue) < min) {
      setError("amount", {
        message: "VALUE_LESS_THAN_MINIMUM",
      });
    }
    else if (parseFloat(tempAmountValue) > max) {
      setError("amount", {
        message: "EXCEED_MAXIMUM_VALUE",
      });
    }
  }

  const checkValueOnSelectFixedAmt = async (fixedAmt) => {
    let updatedValue = (parseFloat(fixedAmt) + parseFloat(stringIsNullOrEmpty(amountValue) ? 0 : amountValue)).toString();
    setAmountValue(
      updatedValue
    );
    clearErrors();
    let tempAmountValue = updatedValue;
    if (parseFloat(tempAmountValue) < min) {
      setError("amount", {
        message: "VALUE_LESS_THAN_MINIMUM",
      });
    }
    else if (parseFloat(tempAmountValue) > max) {
      setError("amount", {
        message: "EXCEED_MAXIMUM_VALUE",
      });
    }
  }


  async function onSubmit(data, e) {
    _dispatch(setBusy());
    let index = instantPaymentList.findIndex((x) => x.instantPayGatewayString === selectedProviderName);
    if (index !== -1) {
      let foundProvider = instantPaymentList[index];
      var bankData = foundProvider["instantBankSettingModel"].filter(
        (option) => option.bankId === selectedBank.bankId
      )[0];

      let params = {
        transactionTypeId: TransactionType._DEPOSIT,
        bankId: bankData.bankId,
        amount: parseFloat(amountValue),
        bankCode: bankData.bankCode,
        channel: foundProvider["instantPayGatewayId"],
        panel: PanelType._MEMBERSITE,
        instantPayBankSettingId: bankData.id,
        currency: bankData.currency,
        instantPay: true,
        receiptReference: selectedProviderName + "(" + foundProvider["instantPayGatewayString"] + ")",
        bankAccountId: bankData.bankId,
      };

      if (!stringIsNullOrEmpty(props.bonusDeposit) && props.bonusDeposit !== "-1") {
        params["bonusId"] = props.bonusDeposit;
      } else if (props.bonusDeposit === "-1") {
        params["bonusId"] = "";
      }

      let responseJson = await ApiEngine.post(
        ApiUrl._API_CREATE_TRANSACTION,
        createMultiPartFormBody(params)
      );

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        _dispatch(setBusy(true, t("PLEASE_WAIT_REDIRECTING")));

        e.target.reset();
        props.parentInit();
        if (selectedProviderName === "SmartPay" || selectedProviderName === "SmartPay Ewallet" || selectedProviderName === "StarPay" || selectedProviderName === "StarPay QR") {
          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                action: "payment",
                url: responseJson[ApiKey._API_DATA_KEY],
              })
            );
          } else {
            const a = document.createElement("a");
            a.setAttribute('href', responseJson[ApiKey._API_DATA_KEY]);
            a.setAttribute('target', '_blank');
            a.click();
            window.location.reload();
            setTimeout(() => {
              _dispatch(setIdle());
            }, 5000);
          }
        } else {
          window.location.href = responseJson[ApiKey._API_DATA_KEY];
        }
        _dispatch(setIdle());
      } else {
        _dispatch(
          showResponseMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            responseJson[ApiKey._API_MESSAGE_KEY]
          )
        );
      }
    }
    _dispatch(setIdle());
  }


  return (
    <>

      {showCopyAlert && (
        <div style={{ position: scrollPosition < 224 ? "relative" : "sticky", top: scrollPosition < 150 ? "107px" : scrollPosition >= 150 && scrollPosition < 160 ? "-65px" : scrollPosition >= 160 && scrollPosition < 170 ? "-55px" : scrollPosition >= 170 && scrollPosition < 180 ? "-45px" : scrollPosition >= 180 && scrollPosition < 190 ? "-35px" : scrollPosition >= 190 && scrollPosition < 200 ? "-25px" : scrollPosition >= 200 && scrollPosition < 210 ? "-15px" : scrollPosition >= 210 && scrollPosition < 224 ? "-5px" : "160px", left: "-55px", width: "100%", color: "white", zIndex: "99999" }}>
          <div
            className="content content-full-width"
            style={{
              position: "absolute",
              width: "200px",
              top: "10px",
              right: "-40px"
            }}
          >
            <Alert color="green" style={{ textAlign: "center" }}>
              {t("COPY_SUCCESS")}
            </Alert>
          </div>
        </div>
      )}
      <div className="normal-deposit-wrapper">
        {!loadingPaymentData ? <form onSubmit={handleSubmit(onSubmit)}>
          {bankIsSelected === false && (
            <>
              <div className="all-normal-banks-container">
                {paymentOptionList.filter(x => x.paymentType === "QR Payment" && x.status === true).length > 0 && (
                  <>
                    <div className="normal-bank-item-container">
                      <div className="font14 normal-parent-bank-title">
                        {t("EWALLET") + " / " + t("QR_PAY")}
                      </div>
                      <div className="deposit-normal-bank-list-container">
                        {paymentOptionList &&
                          paymentOptionList.filter(x => x.paymentType === "QR Payment" && x.status === true).length > 0 &&
                          paymentOptionList.filter(x => x.paymentType === "QR Payment" && x.status === true).map((child, index) => {
                            return (
                              <div
                                key={"qr-deposit-bank" + index}
                                className="deposit-normal-bank-list-items"
                                onClick={() => { handleBankSelection(child) }}
                              >
                                <img
                                  src={child.image}
                                  alt="instant payment banks"
                                  className="img-responsive"
                                />
                              </div>
                            );
                          })
                        }
                      </div>
                    </div>
                    <hr className="custom-hr" />
                  </>
                )}
                {paymentOptionList.filter(x => x.paymentType === "Instant Payment" && x.status === true).length > 0 && (
                  <>
                    <div className="normal-bank-item-container">
                      <div className="font14 normal-parent-bank-title">
                        {t("BANK_INSTANT")}
                      </div>
                      <div className="deposit-normal-bank-list-container">
                        {paymentOptionList &&
                          paymentOptionList.filter(x => x.paymentType === "Instant Payment" && x.status === true).length > 0 &&
                          paymentOptionList.filter(x => x.paymentType === "Instant Payment" && x.status === true).map((child, index) => {
                            return (
                              <div key={"instant-deposit-bank" + index}
                                className="deposit-normal-bank-list-items "
                                onClick={() => { handleBankSelection(child) }}
                              >
                                <img
                                  src={child.image}
                                  alt="i8 banks"
                                  className="img-responsive"
                                />
                              </div>
                            );
                          }
                          )
                        }

                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
          {bankIsSelected && (
            <>
              <div className="normal-bank-per-section-box">
                <div className="first">
                  {t("SELECTED_BANK_METHOD")}
                </div>
                <div className="second ">
                  <div className="normal-bank-selected-wrapper">
                    <div className="normal-bank-selected-img" onClick={() => { resetFormField(); }}>
                      <img src={selectedBank.image} alt="selected bank" />
                      <div className="normal-bank-tick-selected">
                        <img src={"/img/v3/reload/tick_1.png"} alt="active" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="custom-hr" />
              <div className="normal-bank-per-section-box">
                <div className="first">
                  {!stringIsNullOrEmpty(selectedProviderName) ? t("SELECTED_PROVIDER") : t("PLEASE_SELECT_PROVIDER")}
                </div>
                <div className="second ">
                  <div className="instant-provider-selected-wrapper">
                    {paymentOptionList &&
                      paymentOptionList.filter(x => x === selectedBank && x.status === true)[0].availableProvider.length > 0 &&
                      paymentOptionList.filter(x => x === selectedBank && x.status === true)[0].availableProvider.map((child, index) => {
                        return (

                          <div key={"instant-deposit-provider" + index} className={"instant-provider " + classnames({ active: selectedProviderName === child.name, })} onClick={() => { handleProviderSelection(child.name, selectedBank); }}>
                            {selectedProviderName === child.name &&
                              <img className="tick-image" src={TickIcon} alt={child.name} />
                            }
                            <img className="instant-provider-image" src={child.image} alt={child.title} />
                          </div>
                        );
                      })
                    }
                  </div>
                </div>
              </div>
            </>
          )}

          {bankIsSelected && selectedProviderName && (
            <>
              {selectedProviderName === "SmartPay" && (
                <>
                  <hr className="custom-hr" />
                  <div className="normal-bank-per-section-box">
                    <div className="first">
                      {t("REMINDER")}
                    </div>
                    <div className="second">
                      <div className="reminder font-15 text-white font-semi">
                        {t("SMART_PAY_REMINDER")}
                      </div>
                    </div>
                  </div>

                </>
              )}
              {parseFloat(serviceCharge) > 0 && parseFloat(serviceCharge) !== 0 && (
                <>
                  <hr className="custom-hr" />
                  <div className="normal-bank-per-section-box">
                    <div className="first">
                      {t("SERVICE_CHARGE")}
                    </div>
                    <div className="second">
                      <div className="reminder font-15 text-white font-semi">
                        {t("SERVICE_CHARGE_DISCLAIM") +
                          serviceCharge +
                          "%" +
                          t("SERVICE_CHARGE_DISCLAIM_BACK")}
                      </div>
                    </div>
                  </div>

                </>
              )}
              <hr className="custom-hr" />
              <div className="normal-bank-per-section-box">
                <div className="first">
                  {t("AMOUNT")}
                </div>
                <div className="second ">
                  <div className="form-group m-b-15 px-0">
                    <div className="deposit-input-group mb-3">
                      <span className="currency-prefix">IDR</span>
                      {<input
                        type="text"
                        className="form-white-input"
                        placeholder={t("PLEASE_ENTER_AMOUNT")}
                        value={numberWithCommas(amountValue)}
                        onChange={(event) => {
                          let tempValue = event.target.value.replace(/[^0-9]/g, '');
                          setAmountValue(tempValue);
                          checkAmountForErrors(tempValue);
                        }}
                        onPaste={(event) => {
                          if (!stringIsNullOrEmpty(window.event.clipboardData.getData('text')) && (/^[1-9]{1,}[0-9]{0,}$/).test(window.event.clipboardData.getData('text'))) {
                            setAmountValue(parseInt(window.event.clipboardData.getData('text')));
                            event.preventDefault();
                          } else {
                            setAmountValue('');
                            event.preventDefault();
                          }
                        }}
                      />}
                      <div className="clear-amount" onClick={() => setAmountValue("")}>
                        <img
                          src={'/img/v3/reload/close.png'}
                          alt="i8 banks"
                          className="img-responsive"
                        />
                      </div>
                    </div>
                    {errors.amount && (
                      <div className="invalid-feedback">{t(errors.amount.message)}</div>
                    )}
                    <div className="font-11 color-white">
                      {parseFloat(amountValue) >= min ?
                        <>
                          <span>{t("MAIN_WALLET_NEW_BALANCE") + " : "}</span>
                          <span>{numberWithCurrencyFormat2(parseFloat(_userData.walletBalance), 2, true) + " "}</span>
                          <i className="fas fa-arrow-right" />
                          <span>{" " + numberWithCurrencyFormat2(parseFloat(_userData.walletBalance) + (amountValue > 0 ? parseFloat(amountValue / _CURRENCY_RATIO) : 0), 2, true)}</span>
                        </>
                        :
                        <>
                        </>
                      }
                    </div>
                    <div className="instant-provider-reminder">
                      {min >= 0 && max >= 0 && (

                        <div className="font-11 amount-limit-reminder">
                          <span className="reminder mr-2">
                            * {t("MIN")} : IDR {numberWithCommas(min)} / {t("MAX")} : IDR {numberWithCommas(max)}
                          </span>
                        </div>
                      )}
                      {/*<span className="currency-reminder custom-color-yellow mr-2 font-11 font-bold text-nowrap">{"(1 : IDR 1,000)"}</span>*/}
                    </div>
                  </div>
                  <div className="amount-row">
                    {fixedAmount.map((amount, index) => {
                      return (
                        <button
                          key={"amount-row" + index}
                          type="button"
                          onClick={() => checkValueOnSelectFixedAmt(amount)}
                          className="btn custom-button"
                        >
                          + {numberWithCommas(amount)}
                        </button>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="form-group mt-3 col-md-12 px-0">
                <button type="submit" className="custom-btn-style-1">
                  {t("SUBMIT")}
                </button>
              </div>
            </>
          )}
        </form> :
          <div style={{ textAlign: "center" }}>
            <Spinner animation="border" role="status" size="sm"></Spinner>
          </div>
        }
      </div>
    </>
  );
};

export default InstantDeposit;

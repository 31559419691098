import React, { useState, useEffect, useRef, useMemo} from "react";
//import "react-simple-keyboard/build/css/index.css";
import Sidebar from "components/sidebar";
import { map } from "lodash";
import QRCode from "qrcode.react";
import { ApiKey, ApiUrl, SessionKey, WebUrl } from "../../util/Constant";
import { stringIsNullOrEmpty } from "../../util/Util";
import { Link, useHistory, useLocation } from "react-router-dom";
import ApiEngine from "../../util/ApiEngine";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Nav, NavItem, NavLink, TabContent, TabPane, Alert } from "reactstrap";
import classnames from "classnames";
import ContentHeader from "components/content/header";
import Icon from "@mdi/react";
import { mdiFileMultipleOutline } from "@mdi/js";
import { InlineShareButtons } from "sharethis-reactjs";

const ReferralCode = (props) => {
  const { t, i18n } = useTranslation();
  const _location = useLocation();
  const _history = useHistory();
  const [referralCodeDetail, setReferralCodeDetail] = useState([]);
  const [referralCodeId, setReferralCodeId] = useState();
  const [expiryDate, setExpiryDate] = useState(null);
  const [initCheck, setInitCheck] = useState(false);
  const [registerUrl, setRegisterUrl] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showAlert1, setShowAlert1] = useState(false);
  const [showCodeAlert, setShowCodeAlert] = useState(false);
  const [categories, setCategories] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const _APPEND_REGISTER_URL = "/register?code=";
  const _APPEND_REGISTER_WHATSAPP_URL = "?text=register%20";
  let _copyRef = useRef();
  let _copyRef1 = useRef();
  const _ALERT_TIMEOUT = 2000;
  var selectedLanguage = localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ? 
  localStorage.getItem(SessionKey._LANGUAGE) : 'en' ;
  const [registerWhatsAppUrl, setRegisterWhatsAppUrl] = useState("");
  useEffect(() => {
    init();
    setInitCheck(true);
    //setTimeout(() => setInitCheck(true), 1000);
  }, [_location.state]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  async function init() {
    let tempReferralCodeDetail = [];
    if (_location.state) {
      setReferralCodeId(_location.state.id);
      tempReferralCodeDetail = await getCodeDetail();
    }

    let responseJson = await ApiEngine.get(
      ApiUrl._API_GET_PRODUCT_CATEGORY_DESKTOP +
        "?selectedLanguage=" +
        selectedLanguage
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let categoryData = responseJson[ApiKey._API_DATA_KEY];
      setActiveTab(categoryData[0]["id"]);
      let tempAvailableCategories = [];
      categoryData.map((category, index) => {
        if (
          tempReferralCodeDetail.memberReferralInfos.filter(
            (x) =>
              x.productCategory === category.originalTitle &&
              (x.commission > 0 || x.bonus > 0 || x.clubFee > 0)
          ).length > 0 ||
          category.originalTitle === "All"
        ) {
          tempAvailableCategories.push(category);
        }
      });
      setCategories(tempAvailableCategories);
    }
    //}
  }

  async function getCodeDetail() {
    let apiUrl = ApiUrl._API_GET_MEMBER_REFERRAL_CODES;

    if (!stringIsNullOrEmpty(_location.state.id)) {
      apiUrl += "?id=" + _location.state.id;
    }
    let data = [];
    let responseJson = await ApiEngine.get(apiUrl);
    if (
      responseJson[ApiKey._API_SUCCESS_KEY] &&
      responseJson[ApiKey._API_DATA_KEY].length > 0
    ) {
      data = responseJson[ApiKey._API_DATA_KEY][0];
      setReferralCodeDetail(data);
      setExpiryDate(moment(data["expiryDate"]).format("YYYY-MM-DD"));

      //if(window.location.origin === "https://d.i8.live"){
      //  setRegisterUrl('https://i8.live'+ _APPEND_REGISTER_URL + data.code);
      //}
      //else{
      //  setRegisterUrl('https://staging.i8global.com'+ _APPEND_REGISTER_URL + data.code);
      //}
      setRegisterUrl(window.location.origin + _APPEND_REGISTER_URL + data.code);
      let apiUrl1 = ApiUrl._API_GET_USER_SYSTEM_SETTINGS + "?type=ChatSpherePhoneNumber";
      let settingJson = await ApiEngine.get(apiUrl1);

      if (settingJson[ApiKey._API_SUCCESS_KEY]) {
        let data1 = settingJson[ApiKey._API_DATA_KEY];
        setRegisterWhatsAppUrl("https://wa.me/" + data1 + _APPEND_REGISTER_WHATSAPP_URL + data.code);
      }
    }
    return data;
  }

  function copyUrl() {
    //_copyRef.current.select();    
    navigator.clipboard.writeText(registerUrl);
    document.execCommand("copy");
    setShowAlert(true);
    setTimeout(() => setShowAlert(false), [_ALERT_TIMEOUT]);
  }

  function copyUrl1() {
    //_copyRef1.current.select();
    navigator.clipboard.writeText(registerWhatsAppUrl);
    document.execCommand("copy");
    setShowAlert1(true);
    setTimeout(() => setShowAlert1(false), [_ALERT_TIMEOUT]);
  }

  /// <summary>
  /// Author: -
  /// </summary>
  function copyReferralCode() {
    navigator.clipboard.writeText(referralCodeDetail.code);
    setShowCodeAlert(true);
    setTimeout(() => setShowCodeAlert(false), [_ALERT_TIMEOUT]);
  }

  const ShareButton = useMemo(() => {
    if (!stringIsNullOrEmpty(registerUrl)) {
      return (
        <InlineShareButtons
          config={{
            alignment: 'center',
            color: 'social',
            enabled: true,
            language: 'en',
            networks: ['whatsapp', 'facebook', 'twitter', 'wechat', 'telegram'],
            padding: 12,
            radius: 35,
            size: 30,

            url: registerUrl,
          }}
        />
      )
    }
    else {
      return null;
    }

  }, [registerUrl])

  return (
    <div id="referral-code">
      <section className="section-padding" id="referral-code-section">
        <div className="container">
          <div className="row">
            <div className="col-3" id="account-menu">
              <Sidebar activePage={"REFERRAL"} />
            </div>
            <div className="col-9">
              <ContentHeader enableBackButton title={t("REFERRAL_CODE")} />
              <hr className="right-title-hr" />
              {initCheck &&
              categories.length > 0 &&
              Object.keys(referralCodeDetail).length > 0 ? (
                <div className="template-box" id="payment-method-box">
                  <div className="deposit-qr mt-3">
                    <div className="display-flex justify-center">
                      <div className="text-white font-16 font-bold display-flex position-relative">
                        {referralCodeDetail.code} +
                        <div
                          className="referral-code-clipboard"
                          onClick={() => copyReferralCode()}
                        >
                          <Icon
                            path={mdiFileMultipleOutline}
                            size={0.8}
                            color="white"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="qr-bg-box">
                      <img
                        className="qr-code-background"
                        src={"/img/referral_background.png"}
                      />
                      <QRCode
                        className="referral-qr-code"
                        value={registerUrl}
                      />
                    </div>
                    <p className="font-16 mt-3">
                      {t("REMARK")} :{" "}
                      {referralCodeDetail && referralCodeDetail.remark
                        ? referralCodeDetail.remark
                        : "--"}
                    </p>

                    <div className="referral-link-wrapper">
                      <div className="referral-code-div" style={{ marginBottom: "4px", paddingLeft: "20px" }}>
                        {t("REFERRAL_LINK")}
                      </div>
                      <input
                        className="font-12 custom-input-style-1"
                        style={{ borderRadius: "15px" }}
                        value={registerUrl}
                        ref={_copyRef}
                        readOnly
                      />
                      <div className="referral-copy">
                        <a onClick={() => copyUrl()}>
                          <img
                            src={"/img/copy.png"}
                            className="img-responsive"
                            alt="close"
                          />
                        </a>
                      </div>
                      <div className="text-green text-bold">
                        {showAlert ? t("COPY_SUCCESS") : ""}
                      </div>
                    </div>

                      <div className="referral-link-wrapper" style={{ paddingTop:"10px" }}>
                        <div className="referral-code-div" style={{ marginBottom: "4px", paddingLeft: "20px" }}>
                          {t("WHATSAPP_REFERRAL_LINK")}
                      </div>
                      <input
                        className="font-12 custom-input-style-1"
                        style={{ borderRadius: "15px" }}
                        value={registerWhatsAppUrl}
                        ref={_copyRef1}
                        readOnly
                        />
                        <div className="referral-copy" style={{paddingTop:"35px"} }>
                        <a onClick={() => copyUrl1()}>
                          <img
                            src={"/img/copy.png"}
                            className="img-responsive"
                            alt="close"
                          />
                        </a>
                      </div>
                      <div className="text-green text-bold">
                        {showAlert1 ? t("COPY_SUCCESS") : ""}
                      </div>
                    </div>

                      <div style={{ display: "flex", flexDirection: "column", alignItems: 'center' }}>
                        <div className="mb-2 mt-4 text-yellow pl-3" style={{ marginRight: "auto" }}>{t("RATE_FOR_DOWNLINE")}</div>
                        <div>
                          {ShareButton}
                        </div>
                    </div>
                  
                  </div>
                  <Nav className="nav nav-tabs mt-4" id="my-tabs">
                    {categories.length > 0 &&
                      categories.filter((x) => x.originalTitle !== "All")
                        .length > 1 &&
                      map(categories, (category, index) => {
                        if (
                          referralCodeDetail.memberReferralInfos.filter(
                            (x) =>
                              x.productCategory === category.originalTitle &&
                              (x.commission > 0 || x.bonus > 0 || x.clubFee > 0)
                          ).length > 0 ||
                          category.originalTitle === "All"
                        ) {
                          return (
                            <NavItem
                              key={index}
                              className={classnames({
                                active: activeTab === category.id,
                              })}
                            >
                              <NavLink
                                className={
                                  "font-14 " +
                                  classnames({
                                    active: activeTab === category.id,
                                  })
                                }
                                onClick={() => {
                                  toggleTab(category.id);
                                }}
                              >
                                {t(category.originalTitle)}
                              </NavLink>
                            </NavItem>
                          );
                        }
                      })}
                  </Nav>
                  {referralCodeDetail.memberReferralInfos.filter(
                    (x) => x.bonus > 0 || x.commission > 0 || x.clubFee > 0
                  ).length > 0 ? (
                    <TabContent activeTab={activeTab}>
                      {categories &&
                        map(categories, (category, index) => {
                          if (
                            referralCodeDetail.memberReferralInfos.filter(
                              (x) =>
                                x.productCategory === category.originalTitle
                            ).length > 0 ||
                            category.originalTitle === "All"
                          ) {
                            return (
                              <TabPane key={index} tabId={category.id}>
                                {referralCodeDetail.memberReferralInfos.map(
                                  (info, index) => {
                                    if (
                                      (info.commission > 0 ||
                                        info.bonus > 0 ||
                                        info.clubFee > 0) &&
                                      (info.productCategory ===
                                        category.originalTitle ||
                                        category.originalTitle === "All")
                                    ) {
                                      return (
                                        <div
                                          key={index}
                                          className="text-white mt-4"
                                        >
                                          <h6>
                                            {info.productName +
                                              " (" +
                                              t(
                                                categories.find(
                                                  (x) =>
                                                    x.originalTitle ===
                                                    info.productCategory
                                                ).originalTitle
                                              ) +
                                              ")"}
                                          </h6>
                                          <div className="referral-game-details font-14 mt-2">
                                            {info.commission > 0 && (
                                              <div className="product-rate-row">
                                                <div>
                                                  {t("COMMISSION_SHORT")}
                                                </div>
                                                <div>{info.commission}%</div>
                                              </div>
                                            )}
                                            {info.bonus > 0 && (
                                              <div className="product-rate-row">
                                                <div>{t("BONUS")}</div>
                                                <div>{info.bonus}%</div>
                                              </div>
                                            )}
                                            {info.productName === "D2 Poker" &&
                                              info.clubFee > 0 && (
                                                <div className="product-rate-row">
                                                  <div>{t("CLUB_FEE")}</div>
                                                  <div>{info.clubFee}%</div>
                                                </div>
                                              )}
                                          </div>
                                        </div>
                                      );
                                    }
                                  }
                                )}
                              </TabPane>
                            );
                          }
                        })}
                    </TabContent>
                  ) : (
                    <div className="no-data-text">{t("NO_DATA_FOUND")}</div>
                  )}
                  <Link
                    to={{
                      pathname: WebUrl._URL_REFERRAL_EDIT,
                      state: {
                        id: referralCodeId,
                        availableCategories: categories,
                        referralCodeDetail: referralCodeDetail,
                      },
                    }}
                  >
                    <button className="custom-btn-style-1">{t("EDIT")}</button>
                  </Link>
                </div>
              ) : (
                <div className="loading-spinner-wrapper">
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      {showCodeAlert && (
        <div
          className="content content-full-width"
          style={{
            position: "fixed",
            top: "10px",
            width: "100%",
            zIndex: 9999,
          }}
        >
          <div className="container">
            <Alert color="green">
              {t("CODE") +
                ":" +
                referralCodeDetail.code +
                " + | " +
                t("COPY_SUCCESS")}
            </Alert>
          </div>
        </div>
      )}
    </div>
  );
};
export default ReferralCode;

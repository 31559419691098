import React, { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiHistory } from "@mdi/js";
import { useSelector, useDispatch } from "react-redux";
import Header from "../../components/custom/Header";
import { ApiKey, ApiUrl, WebUrl, LanguageKey, SessionKey, TransactionType, PanelType } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import ApiEngine from "../../util/ApiEngine.js";
import { createMultiPartFormBody, numberWithCurrencyFormat } from "../../util/Util";
import {
  showResponseMessage,
  setBusy,
  setIdle,
} from "../../redux/AppAction.js";
import { useForm, Controller } from "react-hook-form";
import {
  Modal,
  ModalBody,
  ModalHeader,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  UncontrolledCarousel,
  Carousel as ReactCarousel,
  CarouselItem,
  CarouselIndicators,
  CarouselCaption,
  Alert,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardText,
  CardSubtitle
} from "reactstrap";
import { Card as BTCard, Button, Col, Row } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Spinner from "react-bootstrap/Spinner";
import { keys, first, last } from 'lodash';
import ContentHeader from "../../components/content/header";
import Sidebar from "components/sidebar";
import InfoIcon from "../../assets/img/v3/icon.png";
import ReactHtmlParser from "react-html-parser";

const LoyaltyVoucherDetails = (props) => {
  var _history = useHistory();
  var _location = useLocation();
  var _dispatch = useDispatch();
  var { t } = useTranslation(LanguageKey._PRIMARY);
  const userId = useSelector(
    (state) => state.authState.userData.userId
  );
  const languageCode = localStorage.getItem(SessionKey._LANGUAGE)
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loyaltyData, setLoyaltyData] = useState([]);
  const [memberPoint, setMemberPoint] = useState(0);

  useEffect(() => {
    init();
  }, [_location.state.id])

  const init = async () => {
    _dispatch(setBusy());
    if (_location.state.id) {
      let apiUrl = ApiUrl._API_GET_LOYALTY_ITEM_BY_ID + "?itemId=" + _location.state.id + "&languageCode=" + languageCode;
      let responseJson = await ApiEngine.get(apiUrl);

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        let data = responseJson[ApiKey._API_DATA_KEY];
        setLoyaltyData(data);
      }
    }
    getMemberLoyaltyPoints();
    _dispatch(setIdle());
  }

  const handleRedemption = async (data) => {
    _dispatch(setBusy());

    if (data["productType"] === "Voucher") {
      let transactionJson = await ApiEngine.post(
        ApiUrl._API_REDEEM_VOUCHER + "?itemId=" + data.id
      );

      if (transactionJson[ApiKey._API_SUCCESS_KEY]) {
        _dispatch(
          showResponseMessage(
            transactionJson[ApiKey._API_SUCCESS_KEY],
            transactionJson[ApiKey._API_MESSAGE_KEY]
          )
        );
        init();
      }
      else {
        _dispatch(
          showResponseMessage(
            transactionJson[ApiKey._API_SUCCESS_KEY],
            transactionJson[ApiKey._API_MESSAGE_KEY]
          )
        );
      }
    }
    else {
      let responseJson = await ApiEngine.post(
        ApiUrl._API_REDEEM_LOYALTY_ITEM + "?itemId=" + data.id
      );

      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        init();
      }
    }
    _dispatch(setIdle());
  };

  const getMemberLoyaltyPoints = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_LOYALTY_POINTS_BY_ID + "?id=" + userId);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setMemberPoint(responseJson[ApiKey._API_DATA_KEY]);
    }
  }

  return (
    <div>
      {showConfirmation && (
        <SweetAlert
          info
          showCancel
          confirmBtnText={t("CONFIRM")}
          cancelBtnText={t("CANCEL")}
          confirmBtnBsStyle="info"
          title={t("CONFIRM_REDEEM")}
          onConfirm={() => {
            setShowConfirmation(false);
            handleRedemption(loyaltyData);
          }}
          onCancel={() => {
            setShowConfirmation(false);
          }}
        ></SweetAlert>
      )}
      <section className="section-padding" id="loyalty-section">
        <div className="container">
          <div className="row">
            <div className="col-3" id="account-menu">
              <Sidebar activePage={"GAME_POINTS"} />
            </div>
            <div className="col-9">
              <ContentHeader enableBackButton title={t("VOUCHER_DETAILS")} />
              <hr className="right-title-hr" />
              <div className="pb-5">
                <div className="gamepoint-voucher-img-box">
                  <img src={loyaltyData.image} alt="gamepoints-details" className="w-100" />
                </div>
                <div className="font16 text-center mt-4 gamepoint-voucher-title">
                  {loyaltyData.name}
                </div>
                <div className="gamepoint-voucher-point-box mt-2">
                  <div className="first">
                    {t("POINTS")} :
                  </div>
                  <div className="second">
                    {numberWithCurrencyFormat(loyaltyData.pointsNeeded)} {t("POINTS")}
                  </div>
                </div>
                <div className="content-container">
                  {ReactHtmlParser(loyaltyData.desktopContent)}
                </div>
                <div className="btn-container mt-5">
                  {loyaltyData.redeemable === true && loyaltyData.stock > 0 && memberPoint >= loyaltyData.pointsNeeded ?
                    <button type="submit" className="btn btn-block btn-brand-gradient w-100" onClick={() => setShowConfirmation(true)}>{t("REDEEM")}</button>
                    : loyaltyData.redeemable === false && loyaltyData.stock > 0 && (memberPoint > loyaltyData.pointsNeeded || memberPoint < loyaltyData.pointsNeeded) ? // Claim limit reached
                      <button type="submit" className="btn btn-block btn-brand-gradient w-100" disabled>{t("REDEEMED")}</button>
                      : loyaltyData.redeemable === true && loyaltyData.stock > 0 && memberPoint < loyaltyData.pointsNeeded ? // Points not enough
                        <button type="submit" className="btn btn-block btn-brand-gradient w-100" disabled>{t("REDEEM")}</button>
                        :
                        <button type="submit" className="btn btn-block btn-brand-gradient w-100" disabled>{t("OUT_OF_STOCK")}</button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default LoyaltyVoucherDetails;
import React, { useState, useEffect } from "react";
import { filter, map } from "lodash";

const Header = () => {
    const [scrollTop, setScrollTop] = useState(0);

    
    useEffect(() => {
    const scrollEvent = () => {
        window.addEventListener("scroll", (e) => {
        setScrollTop(e.target.documentElement.scrollTop);
        });
    };

    scrollEvent();

    return () => {
        //window.removeEventListener("scroll");
    };
    }, []);

    return (
        <>
            <div id="header" className={scrollTop > 150 ? "download-header fixedme" : "download-header"}>
                <header className="login">
                    <h1 className="hidden">I8</h1>
                    <h2 className="hidden">I8</h2>
                    <h3 className="hidden">I8</h3>
                    <h4 className="hidden">I8</h4>
                    <h5 className="hidden">I8</h5>
                    <h6 className="hidden">I8</h6>
                    <div className="container">
                        <div className="row mob-row">
                            <div className="col-12">
                                <div className="download-header-box">
                                    <div className="head-box">
                                        <div className="logo">
                                            <img
                                                src={"/img/logo.png"}
                                                className="img-responsive"
                                                alt="logo"
                                            />
                                        </div>
                                    </div>
                                    <div className="menu">
                                        <nav className="navbar navbar-expand-md p-0 navbar-light">
                                            <div className="">
                                                <div className="mini-nav-btn">
                                                    <button
                                                    className="navbar-toggler"
                                                    type="button"
                                                    data-toggle="collapse"
                                                    data-target="#navbarNav"
                                                    aria-controls="navbarNav"
                                                    aria-expanded="false"
                                                    aria-label="Toggle navigation"
                                                    >
                                                    <span className="">
                                                        <i className="fa fa-bars"></i>
                                                    </span>
                                                    </button>
                                                </div>
                                                <div
                                                    className="collapse navbar-collapse text-lg-center"
                                                    id="navbarNav"
                                                >
                                                    <ul className="navbar nav main-nav list-inline show-desktop">
                                                        <li className="nav-item">
                                                            <a
                                                                href="#"
                                                                className="nav-link"
                                                                >
                                                                HOME
                                                            </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a
                                                                href="#"
                                                                className="nav-link"
                                                                >
                                                                GAME
                                                            </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a
                                                                href="#"
                                                                className="nav-link"
                                                                >
                                                                FEATURES
                                                            </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a
                                                                href="#"
                                                                className="nav-link"
                                                                >
                                                                GET THE GAME
                                                            </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a
                                                                href="#"
                                                                className="nav-link download-register-btn"
                                                                >
                                                                REGISTER
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <div className="mini-nav-btn menu-mobile-close mt-3 ml-2">
                                                        <button
                                                        className="navbar-toggler"
                                                        type="button"
                                                        data-toggle="collapse"
                                                        data-target="#navbarNav"
                                                        aria-controls="navbarNav"
                                                        aria-expanded="false"
                                                        aria-label="Toggle navigation"
                                                        >
                                                        <span className="">
                                                            <i className="fa fa-times"></i>
                                                        </span>
                                                        </button>
                                                    </div>
                                                    <ul className="navbar nav main-nav list-inline show-mobile">
                                                        <li className="nav-item">
                                                            <a
                                                                href="#"
                                                                className="nav-link download-register-btn"
                                                                >
                                                                REGISTER
                                                            </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a
                                                                href="#"
                                                                className="nav-link"
                                                                >
                                                                HOME
                                                                <img
                                                                    src={"/img/download/i-home.png"}
                                                                    className="img-responsive ml-3"
                                                                    alt="logo"
                                                                />
                                                            </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a
                                                                href="#"
                                                                className="nav-link"
                                                                >
                                                                GAME
                                                                <img
                                                                    src={"/img/download/i-game.png"}
                                                                    className="img-responsive ml-3"
                                                                    alt="logo"
                                                                />
                                                            </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a
                                                                href="#"
                                                                className="nav-link"
                                                                >
                                                                FEATURES
                                                                <img
                                                                    src={"/img/download/i-feature.png"}
                                                                    className="img-responsive ml-3"
                                                                    alt="logo"
                                                                />
                                                            </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a
                                                                href="#"
                                                                className="nav-link"
                                                                >
                                                                GET THE GAME
                                                                <img
                                                                    src={"/img/download/i-download.png"}
                                                                    className="img-responsive ml-3"
                                                                    alt="logo"
                                                                />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <div className="download-buttons">
                                                                <div>
                                                                    <a
                                                                        href="#"
                                                                        className="transaction-btn small-btn font-18 font-semi"
                                                                    >
                                                                        <div>
                                                                        <img
                                                                            src={"/img/apple.png"}
                                                                            className="img-responsive"
                                                                            alt="banner-main"
                                                                        />
                                                                        </div>
                                                                        <div>App Store</div>
                                                                    </a>
                                                                </div>
                                                                <div>
                                                                    <a
                                                                        href="#"
                                                                        className="transaction-btn small-btn font-18 font-semi"
                                                                    >
                                                                        <div>
                                                                        <img
                                                                            src={"/img/playstore.png"}
                                                                            className="img-responsive"
                                                                            alt="banner-main"
                                                                        />
                                                                        </div>
                                                                        <div>Google Play</div>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        </>
    );
}

export default Header;
import React, { useState, useEffect, useCallback } from "react";
//import "react-simple-keyboard/build/css/index.css";
import { map, debounce, isNull } from "lodash";
import ApiCms from "util/ApiCms";
import { ApiKey, ApiUrl, WebUrl, SessionKey, LanguageOption } from "../../util/Constant";
import { useHistory } from "react-router-dom";
import GameMenu from "components/game-list/game-menu";
import {
  showResponseMessage,
  setBusy,
  setIdle,
} from "../../redux/AppAction.js";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine";

const HelpCentre = (props) => {
  const { t, i18n } = useTranslation();
  const [searchText, setSearchText] = useState("");
  const [breadcrumbs, updateBreadcrumbs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [displayGameMenu, setDisplayGameMenu] = useState(true);
  const [channels, setChannels] = useState([]);
  let _history = useHistory();
  var _dispatch = useDispatch();

  const onClick = (breadcrumbItems) => {
    updateBreadcrumbs((arr) => [...arr, breadcrumbItems]);
  };

  useEffect(() => {
    fetchData();
    init();
    // setLang(
    //   LanguageOption.find(
    //     (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
    //   ).code
    // );
  }, [
    // lang
    (localStorage.getItem(SessionKey._LANGUAGE)&& localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ) ? 
  (LanguageOption.find(
    (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)) != undefined? 
      LanguageOption.find(
      (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
      ).code : 
      LanguageOption[0].code
    ): 
    LanguageOption[0].code

    // LanguageOption.find(
    //   (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
    // ).code,
  ]);

  useEffect(() => {
    setDisplayGameMenu(false);
    setTimeout(() => setDisplayGameMenu(true), 1);
  }, [(localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
  localStorage.getItem(SessionKey._LANGUAGE) : 'en')]);

  async function init() {
    _dispatch(setBusy());
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_COMMUNICATION_CHANNELS + "?all=true&languageCode=" + 
      (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
      localStorage.getItem(SessionKey._LANGUAGE) : 'en')
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setChannelData(responseJson[ApiKey._API_DATA_KEY]);
    } 

    _dispatch(setIdle());
  }

  async function fetchData() {
    _dispatch(setBusy());

    const { data } = await ApiCms.get(
      "/categories/query" +
        "?locale=" + 
        ((localStorage.getItem(SessionKey._LANGUAGE)&& localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ) ? 
        (LanguageOption.find(
          (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)) != undefined? 
            LanguageOption.find(
            (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
            ).code : 
            LanguageOption[0].code
          ): 
          LanguageOption[0].code )

        // LanguageOption.find(
        //   (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
        // ).code
    );

    //const enData = await ApiCms.get("/categories/query" + "?locale=en");

      const { data: categoriesList } = data;
    setCategories(categoriesList);

    _dispatch(setIdle());
  }

  async function GoToCategory(slug, category_id) {
    if (slug) {
      _history.push({
        pathname: WebUrl._URL_HELP_CENTRE_CATEGORY.replace(":category", slug),
        state: {
          category_id: category_id,
        },
      });
    }
  }

  async function GoToSubCategory(slug, sub_slug, category_id, sub_category_id) {
    if (slug) {
      _history.push({
        pathname: WebUrl._URL_HELP_CENTRE_SUB_CATEGORY
          .replace(":category", slug)
          .replace(":sub_category", sub_slug),
        state: {
          category_id: category_id,
          sub_category_id: sub_category_id,
        },
      });
    }
  }

  function setChannelData(data) {
    var channels = [];
    data.filter((i) => i.channelName.match(/Livechat/i)).map((d, index) => {
      var channel = {
        channelName: d.channelName,
        name: d.channelDescription,
        description: "",
        actionUrl: d.cta,
        image: d.channelImage,
        attachment: d.attachment,
      };

      channels.push(channel);
    });
    setChannels(channels);
  }

  const searchOnChange = useCallback(
    debounce(async (keyword) => {
      let url =
        "/categories/query" +
        "?locale=" + ( (localStorage.getItem(SessionKey._LANGUAGE)&& localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ) ? 
        (LanguageOption.find(
          (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)) != undefined? 
            LanguageOption.find(
            (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
            ).code : 
            LanguageOption[0].code
          ): 
          LanguageOption[0].code )

        // LanguageOption.find(
        //   (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
        // ).code;

      if (keyword.target.value.length) {
        keyword = keyword.target.value;
        url = url + "&search=" + keyword;
      }

      const { data } = await ApiCms.get(url);
      const { data: categoriesList } = data;

      setCategories(categoriesList);

      setSearchText(keyword);
    }, 500),
    []
  );

  return (
    <>
      {displayGameMenu && (
        <>
          <section className="category-section">
            <GameMenu />
          </section>
          <section className="help-centre-section">
            <div className="container" id="help-centre-banner">
              {/* parent layout*/}
              <div className="help-centre-banner-container row">
                <img
                  src={"/img/help-centre/help-centre-banner.png"}
                  className="img-100"
                  alt="banner-main"
                />

                <div className="help-centre-search-wrap ">
                  <div className="help-centre-title text-white font-semi">
                    {t("HOW_CAN_WE_HELP")}
                  </div>

                  <div className="help-centre-search help-centre-search-bar">
                    <a href="#" className="help-centre-search-btn">
                      <img
                        src={"/img/assets/search.png"}
                        className="img-responsive"
                        alt="search"
                      />
                    </a>
                    <input
                      className="help-centre-search-input"
                      id="hsearch-input"
                      type="text"
                      placeholder={t("SEARCH")}
                      name="search"
                      onChange={searchOnChange}
                    ></input>
                  </div>
                </div>
              </div>
              <div className="help-centre-list-container row px-5 d-flex justify-content-start mb-5 text-white">
                {categories && categories.length === 0
                  ? t("NO_DATA_FOUND")
                  : categories.map((item, index) => {
                      return (
                          !isNull(item.publishedAt) && ((!item.slug.includes("_")) || item.slug.match("_idr")) && (
                            <div key={index}
                              className="col-md-4 col-lg-4 col help-centre-margin"
                              // onClick={() => GoToCategory(item.attributes.title)}
                            >
                              <div className="d-flex justify-content-between help-centre-list">
                                <div>
                                  <div className="help-centre-list-title text-white font-semi">
                                    {item.title}
                                  </div>

                                  {item.sub_categories &&
                                    item.sub_categories.length &&
                                    item.sub_categories.map((item1, index) => {
                                      return (
                                          !isNull(item1.publishedAt) && ((!item1.slug.includes("_")) || item1.slug.match("_idr")) && (
                                          <div key={index} className="help-centre-list-item text-white">
                                            <a
                                              onClick={() =>
                                                GoToSubCategory(
                                                  item.slug,
                                                  item1.slug,
                                                  item.category_id,
                                                  item1.sub_category_id
                                                )
                                              }
                                              className="list-link cursor-pointer"
                                            >
                                              {item1.Title}
                                            </a>
                                          </div>
                                        )
                                      );
                                    })}

                                  <div>
                                    <a
                                      onClick={() =>
                                        GoToCategory(
                                          item.slug,
                                          item.category_id
                                        )
                                      }
                                      className="text-gold font-11 cursor-pointer"
                                    >
                                      {t("SEE_ALL")}
                                    </a>
                                  </div>
                                </div>

                                <div className="help-centre-icon-section">
                                  <img
                                    src={`${process.env.REACT_APP_CMS_URL}${item.icon.url}`}
                                    className="help-centre-icon"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                        )
                      );
                    })}
              </div>

              <div>
                <div className="help-centre-other-ways-container text-white font-bold font-16">
                  {t("OTHER_WAY_TO_GET_HELP")}
                </div>

                <div className="help-centre-other-ways-list-container row d-flex justify-content-start">
                  {channels.map((channel, index) => {
                    return (
                      <div key={index} className="col-md-5 col-lg-5 col"
                        onClick={() => { 
                            window.open(channel.actionUrl, "_blank", 'live-chat')
                        }}
                      >
                        <div className="d-flex justify-content-between help-centre-other-ways-list">
                          <div>
                            <div
                              className="help-centre-other-ways-title text-white font-semi font-16"
                              onClick={() =>
                                _history.push({
                                  pathname: WebUrl._URL_CONTACT_US,
                                })
                              }
                            >
                              {t("LIVE_CHAT")}
                            </div>

                            <div className="help-centre-list-desc text-white font-12">
                              {t("SUPPORTS_24")}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}

                  <div className="col-md-5 col-lg-5 col">
                    <div
                      className="d-flex justify-content-between help-centre-other-ways-list"
                      onClick={() =>
                        _history.push({
                          pathname: WebUrl._URL_CONTACT_US,
                        })
                      }
                    >
                      <div>
                        <div className="help-centre-other-ways-title text-white font-semi font-16">
                          {t("CONTACT")}
                        </div>

                        <div className="help-centre-list-desc text-white font-12">
                          {t("CUSTOMER_SERVICES")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};
export default HelpCentre;

import React, { useState, useEffect, useCallback} from "react";
import { debounce, find } from "lodash";
import ApiEngine from "../../util/ApiEngine.js";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  AlertTypes,
  ApiKey,
  ApiUrl,
  WebUrl,
  _MAIN_WALLET_ID,
  LanguageOption,
  SessionKey
} from "../../util/Constant";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";
import { setGameQuery, setGameSearchText } from "../../redux/GameAction.js";
import { showMessage } from "../../redux/AppAction.js";
import { stringIsNullOrEmpty } from "../../util/Util.js";
import UMIcon from "../../assets/img/icon/dashboardv2_um.png";

const GameList = (props) => {
  const { t } = useTranslation();
  const { params } = useRouteMatch(WebUrl._API_GET_MASTER_PRODUCT_BY_ID);
  const { provider } = params;
  var _dispatch = useDispatch();

  const [lobbyName, setLobbyName] = useState("PlayTech%20Slots");
  const [masterProductId, setMasterProductId] = useState(38);
  ///from mobile app
  const [games, setGames] = useState([]);
  const [initCheck, setInitCheck] = useState(false);
  const [providerLogo, setProviderLogo] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  const [iconImage, setIconImage] = useState("");
  const [gameList, setGameList] = useState([]);
  let _savedQuery = useSelector((state) => state.gameState.gameQuery);
  let _savedData = useSelector((state) => state.appState.gameSavedText);
  const [keyword, setKeyword] = useState("");
  const [isSpecialRollover, setIsSpecialRollover] = useState(false);
  const [productCategory, setProductCategory] = useState([]);

  let _history = useHistory();
  const selectedGameProvider = useSelector((state) =>
    find(state.gameState.gameProviders, (item) => item.gameName === provider)
  );
  const { isLoggedIn } = useSelector((state) => state.authState);
  const _userData = useSelector((state) => state.authState.userData);

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedGameProvider.hasOwnLobby) {
        let apiUrl = `${ApiUrl._API_GET_MASTER_PRODUCT_GROUP_GAME}?id=${selectedGameProvider.id}`;
                
        // &languageId=${ LanguageOption.find(
        //   (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
        // ).languageId}`;

        if (localStorage.getItem(SessionKey._LANGUAGE)&& localStorage.getItem(SessionKey._LANGUAGE) != 'undefined') {
          if( LanguageOption.find(
            (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)) != undefined) {
              apiUrl += "&languageId=" + LanguageOption.find(
                (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
              ).languageId;
            }
            else{
              apiUrl += "&languageId=1";
            }
        } else {
          apiUrl += "&languageId=1";
        }

        let apiUrl1 = `${ApiUrl._API_GET_MASTER_PRODUCT_BY_ID}?id=${selectedGameProvider.id}`;
        var responseJson = await ApiEngine.get(apiUrl1);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setBannerImage(
            responseJson[ApiKey._API_DATA_KEY]["bannerDesktopImage"]
          );
        }
        if (isLoggedIn) {
          apiUrl += "&memberId=" + _userData.userId;
        }

        const { data } = await ApiEngine.get(apiUrl);
        const { data: gameListData } = data;
        setGameList(gameListData);

        // _dispatch(setGameQuery(ApiUrl._API_GET_MASTER_PRODUCT_GROUP_GAME) +"?id=" + selectedGameProvider.id);
      }
    };

    if (selectedGameProvider) {
      fetchData();
    }

  }, [selectedGameProvider,  
    
    (localStorage.getItem(SessionKey._LANGUAGE)&& localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ) ? 
    (LanguageOption.find(
      (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)) != undefined? 
      
      LanguageOption.find(
        (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
      ).languageId : LanguageOption[0].languageId): LanguageOption[0].languageId

  //   LanguageOption.find(
  //   (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
  // ).languageId
]);

  async function startGame(gameId, isApp, categoryTitle) {
    if (isSpecialRollover) {
      let productCategoryListArr = await getBonusDepositProductCategory();
      if (productCategoryListArr.includes(categoryTitle)) {
        window.open(
          `${process.env.REACT_APP_ENDPOINT_URL || ""}${ApiUrl._API_START_GAME
          }?gameId=${gameId}&isApp=${isApp ? 1 : 0}&device=d`
        );
      }
      else {
        _dispatch(
          showMessage({
            type: AlertTypes._INFO,
            content: t("ROLLOVER_EXIST"),
            onConfirm: () => {
            },
          })
        );
      }
    }
    else {
      window.open(
        `${process.env.REACT_APP_ENDPOINT_URL || ""}${ApiUrl._API_START_GAME
        }?gameId=${gameId}&isApp=${isApp ? 1 : 0}&device=d`
      );
    }
  }

  // function getText(e) {
  //   _dispatch(setGameSearchText(e.target.value));
  //   _dispatch(setGameQuery(""));
  //   setGetTextFlag(true);
  //   _dispatch(
  //     setGameQuery(
  //       ApiUrl._API_GET_MASTER_PRODUCT_GROUP_GAME +
  //         "?keyword=" +
  //         e.target.value +
  //         "&id=" +
  //         selectedGameProvider.id
  //     )
  //   );
  // }

  const searchOnChange = useCallback(
    debounce(async (keyword) => {

    _dispatch(setGameSearchText(keyword.target.value));
    setKeyword(keyword.target.value);
    }, 500),
    []
  );
  const getMemberSpecialRollover = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_IS_SPECIAL_ROLLOVER);
    let data = "";

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      data = responseJson[ApiKey._API_DATA_KEY];
      setIsSpecialRollover(data);
    }
  }

  const getBonusDepositProductCategory = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_BONUS_DEPOSIT_PRODUCT_CATEGORY);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      let productCategoryArr = [];

      if (data.includes(",")) {
        productCategoryArr = responseJson[ApiKey._API_DATA_KEY].split(",");
      }
      else {
        productCategoryArr.push(data);
      }

      let tempArr = [];
      [...productCategory].forEach((x) => {
        if (productCategoryArr.includes(x.value)) {
          tempArr.push(x.label);
        }
      })

      return tempArr;
    }
  }

  const getProductCategory = async () => {
    let tempArr = [];
    var productCategoryJson = await ApiEngine.get(ApiUrl._API_GET_PRODUCT_CATEGORY);
    if (productCategoryJson[ApiKey._API_SUCCESS_KEY]) {
      let data = productCategoryJson[ApiKey._API_DATA_KEY];
      data.forEach(x => {
        if (x.id > 0) {
          tempArr.push({ value: x.id.toString(), label: x.title });
        }
      });
      setProductCategory(tempArr);
    }
  }

  useEffect(() => {
    if (isLoggedIn) {
      getProductCategory();
      getMemberSpecialRollover();
    }
  }, [isLoggedIn])

  return (
    <>
      <section className="text-white" id="sort-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {!stringIsNullOrEmpty(bannerImage) && (
                <img src={bannerImage} className="img-100" alt="banner-main" />
              )}

              <div className="sort-search-box mt-4">
                <div>
                  <h2 className="mb-0">{selectedGameProvider.gameName}</h2>
                </div>
                <div className="position-relative provider-search-bar">
                  <input
                    type="text"
                    name="search"
                    className="form-white-input"
                    placeholder={t("SEARCH")}
                    // defaultValue={_savedData}
                    // onInput={(e) => {
                    //   // getText(e);
                    // }}
                    onChange={searchOnChange}
                  />
                  <div className="video-search-icon">
                    <img
                      src={"/img/search.png"}
                      className="img-100"
                      alt="banner-main"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sort-games-section section-padding pb-0 mb-3 text-white">
        <div className="container">
          <div className="row text-center seven-cols">
            <DynamicLoadTable
              pageSize={24}
              apiQuery={
                keyword === ''
                ? _savedQuery + "?id=" + selectedGameProvider.id
                : _savedQuery + "?keyword=" + keyword + "&id=" + selectedGameProvider.id
              }
              searchEnabled={true}
              autoscrollEnabled={true}
              render={(game, i) => {
                return (
                  <div className="col" key={i}>
                    <a
                      onClick={() => {
                          if(isLoggedIn && game["gameUm"] !== true){
                            startGame(game["id"], game["isApp"], game["categoryTitle"])
                          }
                          else if (!isLoggedIn) {
                            _dispatch(
                              showMessage({
                                type: AlertTypes._INFO,
                                content: t("PLEASE_LOGIN_FIRST"),
                                onConfirm: () => {
                                  // console.log("hi");
                                },
                              })
                            );
                          }
                        }}
                      className={(isLoggedIn ? game["gameUm"] === true ? "game-um" : "cursor-pointer" : "")}
                    >
                      <>
                        <div className="game-box desktop">
                          {game["isNewGame"] || game["isRecommended"] ? (
                            <div className="tag">
                              <img
                                src={
                                  game["isRecommended"]
                                    ? require("../../assets/img/assets/hot.png")
                                    : require("../../assets/img/assets/new.png")
                                }
                                className="img-responsive"
                                alt={game["isRecommended"] ? "hot" : "new"}
                              />
                            </div>
                          ) : null}

                          <div
                            className="sort-game-image"
                            style={{
                              backgroundImage: 
                              (isLoggedIn
                                ? game["gameUm"] == true
                                  ? "linear-gradient(rgba(0,0,0,.8), rgba(0,0,0,.8)), "
                                  : " "
                                : " ") /*+ `url(${encodeURI(game["image"])})`*/,
                                position: "relative",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                                borderRadius: "16px",
                            }}
                          >
                          {isLoggedIn && game["gameUm"] == true && (
                            <>
                              <div
                                style={{
                                                        backgroundImage: `url(${UMIcon})`,
                                                        zIndex:1
                                }}
                                className="v2-um-tag-icon"
                              ></div>
                            </>
                          )}
                            <img
                                src={game["image"]}
                                alt={game["gameName"]}
                                className="img-100 logo-image-size"
                                style={{ 
                                  position: "relative",
                                  backgroundPosition: "center",
                                  backgroundRepeat: "no-repeat",
                                    borderRadius: "16px",
                                    opacity: game["gameUm"] == true ? "0.7" : "1"
                                }}
                              />
                          </div>
                          <h6 className="text-break text-white font-14">
                            {game["gameName"]}
                          </h6>
                        </div>
                      </>
                    </a>
                  </div>
                );
              }}
            ></DynamicLoadTable>
          </div>
        </div>
      </section>
    </>
  );
};
export default GameList;

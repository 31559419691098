import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const PlusMinusMaxButton = (props) => {
  let {
    onClickMinus,
    styleValueMinus,
    styleValuePlus,
    styleValueButton,
    onClickPlus,
    optionValue,
    value,
    category,
    handleSelectCatValue,
    handleSelectValue,
    errors,
    type,
    product,
    buttonSection,
    text,
    onClickMax,
    section,
    id
  } = props;

  const { t, i18n } = useTranslation();

  //default className because in referral page different px
  let maxClassName = "row-data-max";
  let addMinusClassName = "plus-minus-wrapper";
  let addMinusIconClassName = "edit-plus-minus-icon";
  let selectClassName = "bonusComm-option-select";

  if (section === "referral") {
    maxClassName = "referral-max-box";
    addMinusClassName = "referral-add-minus";
    addMinusIconClassName = "";
    selectClassName = "";
  }

  return (
    <>
      {buttonSection == "plusminus" && (
        <>
          <div className={addMinusClassName}>
            <div
              className={addMinusIconClassName}
              style={{
                opacity: styleValueMinus ? "1.0" : "0.5",
                cursor: styleValueMinus ? "pointer" : "not-allowed",
              }}
              onClick={() => onClickMinus()}
            >
              <span className="font-20">–</span>
              {/* - */}
            </div>

            <select
              className={selectClassName}
              value={value !== "-" ? parseFloat(value.toFixed(2)) : "-"}
              placeholder={value !== "-" ? value.toFixed(2) : "-"}
              onChange={(e) => {
                if (type === "clubfee") { type = "club" + "f".toUpperCase() + "ee"; }
                if (
                  type == "bonus" ||
                  type == "commission" ||
                  type == "clubFee"
                ) {
                  handleSelectCatValue(
                    type,
                    category.id,
                    category.originalTitle,
                    e.target.value
                  );
                } else if (
                  type == "maxBonus" ||
                  type == "maxCommission" ||
                  type == "maxClubFee"
                ) {
                  handleSelectValue(
                    type === "maxBonus"
                      ? "bonus"
                      : type === "maxCommission"
                      ? "commission"
                      : type === "maxClubFee"
                      ? "clubFee"
                      : "",
                    product.productId,
                    e.target.value,
                    type === "maxBonus"
                    ? product.maxBonus
                    : type === "maxCommission"
                    ? product.maxCommission
                    : type === "maxClubFee"
                    ? product.maxClubFee
                    : "",
                  );
                }
              }}
            >
              {optionValue}
            </select>

            <div
              className={addMinusIconClassName}
              id={id}
              style={{
                opacity: styleValuePlus ? "1.0" : "0.5",
                cursor: styleValuePlus ? "pointer" : "not-allowed",
              }}
              onClick={() => onClickPlus()}
            >
              <span className="font-20">+</span>
              {/* + */}
            </div>
          </div>

          {!type == "maxCommission" &&
            !type == "maxClubFee" &&
            errors.editPercent && (
              <div className="invalid-feedback">
                {t(errors.editPercent.message)}
              </div>
            )}
        </>
      )}

      {buttonSection == "max" && (
        <td
          id={id}
          className={maxClassName + " font-bold text-uppercase"}
          onClick={onClickMax}
        >
          {text}
        </td>
      )}
    </>
  );
};

PlusMinusMaxButton.propTypes = {
  styleValueMinus: PropTypes.bool,
  styleValuePlus: PropTypes.bool,
  onClickMinus: PropTypes.func,
  onClickPlus: PropTypes.func,
  onClickMax: PropTypes.func,
  optionValue: PropTypes.array,
  errors: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  category: PropTypes.object,
  product: PropTypes.object,
  buttonSection: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  handleSelectCatValue: PropTypes.func,
  handleSelectValue: PropTypes.func,
  section: PropTypes.string,
  id: PropTypes.string,
};

export default PlusMinusMaxButton;

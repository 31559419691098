import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  ApiKey,
  ApiUrl,
  WebUrl,
  SessionKey,
  TransactionType,
  BankingChannel,
  PanelType,
  _CURRENCY_RATIO,
  BankingDepositType,
  FixedDepositAmount
} from "../../util/Constant";
import {
  stringIsNullOrEmpty,
  createMultiPartFormBody,
  numberWithCurrencyFormat,
  numberWithCommas,
  numberWithCurrencyFormat2
} from "../../util/Util";
import ApiEngine from "../../util/ApiEngine";
import { useForm, Controller } from "react-hook-form";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import "bootstrap-daterangepicker/daterangepicker.css";
import Dropzone from "react-dropzone";
import SelectOption from "../../components/custom/SelectOption";
import { useDispatch, useSelector } from "react-redux";
import { setBusy, setIdle, showResponseMessage } from "../../redux/AppAction";
import { Modal, ModalBody, Alert } from "reactstrap";
import Icon from "@mdi/react";
import { mdiContentCopy, mdiClose } from "@mdi/js";
import BlueWarningIcon from "../../assets/img/v3/notice_blue.svg";

const NormalDeposit = (props) => {
  const { t } = useTranslation();
  let _dispatch = useDispatch();
  let _history = useHistory();
  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    getValues,
    errors,
    reset,
    clearErrors,
    setError,
  } = useForm();
  const [bankOption, setBankOption] = useState([]);
  const [bankAccountOption, setBankAccountOption] = useState([]);
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [bankAccountId, setBankAccountId] = useState("");
  const [receiptImage, setReceiptImage] = useState();
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [isReloadSuccess, setIsReloadSuccess] = useState(false);
  const [paidAmount, setPaidAmount] = useState(0);
  const [reloadDate, setReloadDate] = useState();
  const [transcactionNumber, setTranscactionNumber] = useState();
  const [currentInput, setCurrentInput] = useState();
  const [canDisplayBank, setCanDisplayBank] = useState(true);
  const [userVipLevel, setUserVipLevel] = useState(0);
  const [imageFileSize, setImageFileSize] = useState(0);
  const [bankActive, setBankActive] = useState(0);
  const ONE_MB_IN_BYTES = 1048576;
  const fileSizeLimit_InMB = 20;
  const [showCopyAlert, setShowCopyAlert] = useState(false);
  const _ALERT_TIMEOUT = 3000;
  const [scrollPosition, setScrollPosition] = useState(0);
  const _userData = useSelector(state => state.authState.userData);
  const [bankIsSelected, setBankIsSelected] = useState(false);
  const [selectedBank, setSelectedBank] = useState({});
  const [amountValue, setAmountValue] = useState("");
  const fixedAmount = FixedDepositAmount;

  const bankList = [
    {
      id: 1,
      title: "Bank",
    },
    {
      id: 2,
      title: "E-Wallet",
    },
    {
      id: 3,
      title: "Pulsa",
    },
  ];


  useEffect(() => {
    init();
  }, []);

  async function init() {
    getBankAccountByBankId();
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_USER_VIP_LEVEL);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setUserVipLevel(responseJson[ApiKey._API_DATA_KEY]);
    }
  }

  async function getBankAccountByBankId() {
    let responseJson = await ApiEngine.get(
      ApiUrl._API_GET_BANK_ACCOUNT +
      "?deposit=" +
      true
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let bankAccountList = [];
      responseJson[ApiKey._API_DATA_KEY].forEach(function (bankAccount) {
        bankAccountList.push({
          label: bankAccount.accountHolderName,
          value: bankAccount.id,
          accNumber: bankAccount.accountNumber,
          min: bankAccount.minimumDeposit,
          max: bankAccount.maximumDeposit,
          bankName: bankAccount.bankName,
          bankImg: bankAccount.bankImg,
          bankImg2: bankAccount.bankImg2,
          qrImage: bankAccount.qrImage,
          bankDepositTypeId: bankAccount.bankDepositTypeId,
          bankNormalRank: bankAccount.bankNormalRank
        });
      });

      setBankAccountOption(bankAccountList.sort((a, b) => a.bankNormalRank - b.bankNormalRank));
      if (responseJson[ApiKey._API_DATA_KEY].length <= 0) {
        props.setDisplayNormalDeposit(false);
      }

      let defaultBank = bankAccountList[0];
      setBankActive(defaultBank.value);
      setMinMaxButton(defaultBank.min, defaultBank.max, defaultBank.value);
    }
  }


  useEffect(() => {
    if (!stringIsNullOrEmpty(watch("bankId"))) {
      setValue("bankAccountId", null);
      setBankAccountNumber("");
    }
  }, [watch("bankId")]);

  const imageDrop = (acceptedFiles) => {
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];
      const fileSizeInMB = file.size / ONE_MB_IN_BYTES;
      setImageFileSize(fileSizeInMB);
      setValue("receipt", file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setReceiptImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const remove = () => {
    setReceiptImage("");
    setValue("receipt", null);
  };

  function resetFormField() {
    setBankAccountOption([]);
    setValue("bankId", "");
    setValue("bankAccountId", "");
    setValue("receipt", null);
    setReceiptImage("");
    setAmountValue("");
    setValue("depositDateTime", moment().format("YYYY-MM-DD HH:mm:ss"));
  }

  //const checkValueOnKeyDown = async (e) => {
  //  clearErrors();
  //  let tempAmountValue = amountValue;
  //  if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) { //keyCode of 0 to 9, numpad 0 to 9
  //    tempAmountValue = amountValue + "" + e.key;
  //    setAmountValue(tempAmountValue);
  //  }
  //  else if (e.keyCode == 8 || e.keyCode == 46) //keyCode of backSpace and Del
  //  {
  //    tempAmountValue = amountValue.slice(0, -1);
  //    setAmountValue(tempAmountValue);
  //  }
  //  if (parseFloat(tempAmountValue) < min) {
  //    setError("amount", {
  //      message: "VALUE_LESS_THAN_MINIMUM",
  //    });
  //  }
  //  else if (parseFloat(tempAmountValue) > max) {
  //    setError("amount", {
  //      message: "EXCEED_MAXIMUM_VALUE",
  //    });
  //  }
  //}

  const checkAmountForErrors = async (tempAmountValue) => {
    clearErrors();
    if (parseFloat(tempAmountValue) < min) {
      setError("amount", {
        message: "VALUE_LESS_THAN_MINIMUM",
      });
    }
    else if (parseFloat(tempAmountValue) > max) {
      setError("amount", {
        message: "EXCEED_MAXIMUM_VALUE",
      });
    }
  }

  const checkValueOnSelectFixedAmt = async (fixedAmt) => {
    let updatedValue = (parseFloat(fixedAmt) + parseFloat(stringIsNullOrEmpty(amountValue) ? 0 : amountValue)).toString();
    setAmountValue(
      updatedValue
    );
    clearErrors();
    let tempAmountValue = updatedValue;
    if (parseFloat(tempAmountValue) < min) {
      setError("amount", {
        message: "VALUE_LESS_THAN_MINIMUM",
      });
    }
    else if (parseFloat(tempAmountValue) > max) {
      setError("amount", {
        message: "EXCEED_MAXIMUM_VALUE",
      }); 
    }
  }


  async function onSubmit(data, e) {
    _dispatch(setBusy());

    var loginFailCountFlag = await ApiEngine.post(
      ApiUrl._API_GET_DEPOSIT_FAILED_COUNT,
      )
      console.log(loginFailCountFlag[ApiKey._API_DATA_KEY].depositFailedFlag)
    if (loginFailCountFlag[ApiKey._API_DATA_KEY].depositFailedFlag === true) {
      _dispatch(showResponseMessage(false, t("PLEASE_TRY_AGAIN_LATER_IN_MINUTES", { minutes: loginFailCountFlag[ApiKey._API_DATA_KEY].diffMin })));
    }
    else {
      let params = {
        transactionTypeId: TransactionType._DEPOSIT,
        amount: amountValue,
        channel: BankingChannel._CHANNEL_OFFLINE_BANKING,
        panel: PanelType._MEMBERSITE,
        bankAccountId: bankAccountId,
        receiptReference: data.reference,
        bankDepositTypeId: selectedBank.bankDepositTypeId
      };

      //if (!stringIsNullOrEmpty(bankAccountId)) {
      //  params["bankAccountId"] = bankAccountId;
      //}


      if (!stringIsNullOrEmpty(data.receipt)) {
        params["receipt"] = data.receipt;
      }

      if (!stringIsNullOrEmpty(props.bonusDeposit) && props.bonusDeposit != "-1") {
        params["bonusId"] = props.bonusDeposit;
      } else if (props.bonusDeposit == "-1") {
        params["bonusId"] = "";
      }

      let responseJson = await ApiEngine.post(
        ApiUrl._API_CREATE_TRANSACTION,
        createMultiPartFormBody(params)
      );
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        setPaidAmount(params.amount);
        setIsReloadSuccess(true);
        setReloadDate(moment().format("DD MMM YYYY, hh:mm A"));
        setTranscactionNumber(responseJson[ApiKey._API_DATA_KEY]);
        props.checkPendingTxn();
        e.target.reset();
        resetFormField();

        let defaultBank = bankAccountOption[0];
        setBankActive(defaultBank.value);
      } else {
        _dispatch(
          showResponseMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );
      }
    }
    _dispatch(setIdle());
  }

  const handleModalOnClick = () => {
    setIsReloadSuccess(false);
    //props.parentInit();
    init();
  };

  function setMinMaxButton(min, max, bankId) {
    setMin(min);
    setMax(max);
    setBankAccountId(bankId);
  }

  function copyText(label) {
    navigator.clipboard.writeText(label);
    document.execCommand("copy");
    setShowCopyAlert(true);
    setTimeout(() => setShowCopyAlert(false), [_ALERT_TIMEOUT]);
  }

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const handleBankSelection = async (bank) => {
    setBankIsSelected(true);
    setSelectedBank(bank);
    setMinMaxButton(bank.min, bank.max, bank.value);
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {showCopyAlert && (
          <div style={{ position: scrollPosition < 224 ? "relative" : "sticky", top: scrollPosition < 150 ? "107px" : scrollPosition >= 150 && scrollPosition < 160 ? "-65px" : scrollPosition >= 160 && scrollPosition < 170 ? "-55px" : scrollPosition >= 170 && scrollPosition < 180 ? "-45px" : scrollPosition >= 180 && scrollPosition < 190 ? "-35px" : scrollPosition >= 190 && scrollPosition < 200 ? "-25px" : scrollPosition >= 200 && scrollPosition < 210 ? "-15px" : scrollPosition >= 210 && scrollPosition < 224 ? "-5px" : "160px", left: "-55px", width: "100%", color: "white", zIndex: "99999" }}>
            <div
              className="content content-full-width"
              style={{
                position: "absolute",
                width: "200px",
                top: "10px",
                right: "-40px"
              }}
            >
              <Alert color="green" style={{ textAlign: "center" }}>
                {t("COPY_SUCCESS")}
              </Alert>
            </div>
          </div>
        )}
        <div className="normal-deposit-wrapper">

          {bankIsSelected == false && (
            <>
              <div className="all-normal-banks-container">
                {bankList &&
                  bankList.length &&
                  bankList.map((parent, index) => {
                    if (bankAccountOption.filter(x => x.bankDepositTypeId == parent.id).length > 0) {
                      return (
                        <div key={"normal-bank-parent-" + index}>
                          {index != 0 && (
                            <>
                              <hr className="custom-hr" />
                            </>
                          )}
                          <div className="normal-bank-item-container">
                            <div className="font14 normal-parent-bank-title">
                              {parent.title}<br />
                              {"(" + t("MANUAL_CHEQUE") + ")"}
                            </div>
                            <div className="deposit-normal-bank-list-container">
                              {bankAccountOption &&
                                bankAccountOption.filter(x => x.bankDepositTypeId == parent.id && !x.bankName.includes("PowerPay") && !x.bankName.includes("YPay")).length > 0 &&
                                bankAccountOption.filter(x => x.bankDepositTypeId == parent.id && !x.bankName.includes("PowerPay") && !x.bankName.includes("YPay")).map((child, index2) => {
                                  return (
                                    <>
                                      <div
                                        key={index2}
                                        className="deposit-normal-bank-list-items"
                                        onClick={() => { handleBankSelection(child) }}
                                      >
                                        <img
                                          src={child.bankImg2}
                                          alt="i8 banks"
                                          className="img-responsive"
                                        />
                                      </div>
                                    </>
                                  );
                                })
                              }
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })
                }
              </div>
            </>
          )}
          {bankIsSelected && (
            <>
              <div className="normal-bank-per-section-box">
                <div className="first">
                  {t("SELECTED_BANK_METHOD")}
                </div>
                <div className="second ">
                  <div className="normal-bank-selected-wrapper">
                    <div className="normal-bank-selected-img" onClick={() => { setBankIsSelected(false); setSelectedBank({}); }}>
                      <img src={selectedBank.bankImg2} alt="selected bank" />
                      <div className="normal-bank-tick-selected">
                        <img src={"/img/v3/reload/tick_1.png"} alt="active" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="custom-hr" />
              {!stringIsNullOrEmpty(selectedBank.qrImage) && <>
                <div className="normal-bank-per-section-box">
                  <div className="first">
                    {t("BANK_DEPOSIT_QR")}
                  </div>
                  <div className="second ">
                    <div className="deposit-qr-image-wrapper">
                      <div className="deposit-qr-image" style={{ backgroundImage: `url(${selectedBank.qrImage})` }}>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="custom-hr" />
              </>}
              <div className="normal-bank-per-section-box">
                <div className="first">
                  {t("BANK_ACCOUNT_DETAILS") + " (" + selectedBank.bankName + ")"}
                </div>
                <div className="second ">
                  {bankAccountOption &&
                    bankAccountOption.length &&
                    bankIsSelected &&
                    <div className="deposit-normal-bank-details-container">
                      <div className="deposit-normal-bank-details-item">
                        <div className="first">
                          {selectedBank.bankDepositTypeId == BankingDepositType._BANK ? t("BANK_NAME") : selectedBank.bankDepositTypeId == BankingDepositType._EWALLET ? t("EWALLET_ACCOUNT_NAME") : t("PULSA_ACCOUNT_NAME")}
                        </div>
                        <div className="second">
                          <div>
                            {selectedBank.bankName}
                          </div>
                          <div className="copy" onClick={() => copyText(selectedBank.bankName)}>
                            <img
                              src={'/img/v3/reload/copy.png'}
                              alt="i8 banks"
                              className="img-responsive"
                            />
                          </div>
                        </div>
                      </div>
                      {selectedBank.bankDepositTypeId != BankingDepositType._PULSA ? <div className="deposit-normal-bank-details-item mt-3">
                        <div className="first">
                          {t("BANK_ACCOUNT_NAME")}
                        </div>
                        <div className="second">
                          <div>
                            {selectedBank.label}
                          </div>
                          <div className="copy" onClick={() => copyText(selectedBank.label)}>
                            <img
                              src={'/img/v3/reload/copy.png'}
                              alt="i8 banks"
                              className="img-responsive"
                            />
                          </div>
                        </div>
                      </div> : <></>}
                      <div className="deposit-normal-bank-details-item mt-3">
                        <div className="first">
                          {selectedBank.bankDepositTypeId == BankingDepositType._BANK ? t("BANK_ACCOUNT_NUMBER") : selectedBank.bankDepositTypeId == BankingDepositType._EWALLET ? t("ACCOUNT_NUMBER") : t("PHONE_NUMBER")}
                        </div>
                        <div className="second">
                          <div>
                            {selectedBank.accNumber}
                          </div>
                          <div className="copy" onClick={() => copyText(selectedBank.accNumber)}>
                            <img
                              src={'/img/v3/reload/copy.png'}
                              alt="i8 banks"
                              className="img-responsive"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="deposit-normal-bank-details-item mt-3">
                        <div className="first">
                          {t("MIN")}/{t("MAX")} {t("DEPOSIT")}
                        </div>
                        <div className="second font-bold">
                          IDR {numberWithCommas(selectedBank.min)} - IDR {numberWithCommas(selectedBank.max)}
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
              <hr className="custom-hr" />
              <div className="normal-bank-per-section-box">
                <div className="first">
                  {t("AMOUNT")}
                </div>
                <div className="second ">
                  <div className="form-group m-b-15 px-0">
                    <div className="deposit-input-group mb-3">
                      <span className="currency-prefix">IDR</span>
                      {/*<input
                                                        type="text"
                                                        className="form-white-input"
                                                        placeholder={t("PLEASE_ENTER_AMOUNT")}
                                                        name="amount"
                                                        value={amountValue}
                                                        ref={register({
                                                          required: "PLEASE_ENTER_AMOUNT",
                                                          validate: {
                                                            minValue: (value) =>
                                                              (!canDisplayBank
                                                                ? value >= min
                                                                : value > 0 && value >= min) ||
                                                              "VALUE_LESS_THAN_MINIMUM",
                                                            maxValue: (value) =>
                                                              (!canDisplayBank ? true : value <= max) ||
                                                              "EXCEED_MAXIMUM_VALUE",
                                                          },
                                                        })}
                                                      />*/}
                      {<input
                        type="text"
                        className="form-white-input"
                        placeholder={t("PLEASE_ENTER_AMOUNT")}
                        value={numberWithCommas(amountValue)}
                        onChange={(event) => {
                          let tempValue = event.target.value.replace(/[^0-9]/g, '');
                          setAmountValue(tempValue);
                          checkAmountForErrors(tempValue);
                        }}
                        onPaste={(event) => {
                          if (!stringIsNullOrEmpty(window.event.clipboardData.getData('text')) && (/^[1-9]{1,}[0-9]{0,}$/).test(window.event.clipboardData.getData('text'))) {
                            setAmountValue(parseInt(window.event.clipboardData.getData('text')));
                            event.preventDefault();
                          } else {
                            setAmountValue('');
                            event.preventDefault();
                          }
                        }}
                      />}
                      <div className="clear-amount" onClick={() => setAmountValue("")}>
                        <img
                          src={'/img/v3/reload/close.png'}
                          alt="i8 banks"
                          className="img-responsive"
                        />
                      </div>
                    </div>
                    {errors.amount && (
                      <div className="invalid-feedback">{t(errors.amount.message)}</div>
                    )}
                    <div className="font-11 color-white">
                      {parseFloat(amountValue) >= min ?
                        <>
                          <span>{t("MAIN_WALLET_NEW_BALANCE") + " : "}</span>
                          <span>{numberWithCurrencyFormat2(parseFloat(_userData.walletBalance), 2, true) + " "}</span>
                          <i className="fas fa-arrow-right" />
                          <span>{" " + numberWithCurrencyFormat2(parseFloat(_userData.walletBalance) + (amountValue > 0 ? parseFloat(amountValue / _CURRENCY_RATIO) : 0), 2, true)}</span>
                        </>
                        :
                        <>
                        </>
                      }
                    </div>
                    <div class="instant-provider-reminder">
                        {min >= 0 && max >= 0 && (
                            <div className="font-11 amount-limit-reminder">
                                <span className="reminder mr-2">
                                    * {t("MIN")} : IDR {numberWithCommas(min)} / {t("MAX")} : IDR {numberWithCommas(max)}
                                </span>
                            </div>
                        )}
                    </div>
                                     

                    {/*<span className="custom-color-yellow mr-2 font-11 font-bold text-nowrap">{"(1 : IDR 1,000)"}</span>*/}
                  </div>
                  <div className="amount-row">
                    {fixedAmount.map((amount, index) => {
                      return (
                        <button
                          key={index}
                          type="button"
                          onClick={() => checkValueOnSelectFixedAmt(amount)}
                          className="btn custom-button"
                        >
                          + {numberWithCommas(amount)}
                        </button>
                      );
                    })}
                  </div>
                </div>
              </div>
             <hr className="custom-hr" />
              <div className="normal-bank-per-section-box">
                <div className="first">
                  {t("RECEIPT_REFERENCE")}
                </div>
                <div className="second ">
                  <div className="form-divider mt-4">
                    <div className="withdrawal-name-alert alert alert-warning mb-0">
                      <div className="withdrawal-warning-text">
                        <img className="blue-warning-icon" src={BlueWarningIcon} />
                        {t("FRINEDLY_REMINDER")}{t("DEPOSIT_RECEIPT_REFERENCE")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="custom-hr" />
              <div className="normal-bank-per-section-box">
                <div className="first">
                  {t("RECEIPT")}<br />
                  {" (" + t("MAX_FILE_SIZE") + ": " + fileSizeLimit_InMB + "MB)"}
                </div>
                <div className="second ">
                  <div>
                    <Controller
                      control={control}
                      name="receipt"
                      defaultValue={""}
                      render={({ onChange, value }) => {
                        return (
                          <Dropzone
                            accept={"image/*"}
                            onDrop={(acceptedFiles) => imageDrop(acceptedFiles)}
                            maxSize={fileSizeLimit_InMB * ONE_MB_IN_BYTES}
                            onDropRejected={() =>
                              _dispatch(
                                showResponseMessage(
                                  false,
                                  t("FILE_LARGER_THAN") +
                                  fileSizeLimit_InMB +
                                  "MB, " +
                                  t("PLEASE_TRY_AGAIN")
                                )
                              )
                            }
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div>
                                <section style={{ position: "relative" }}>
                                  {!stringIsNullOrEmpty(receiptImage) && (
                                    <div
                                      style={{
                                        background: "transparent",
                                        padding: "5px 10px",
                                        textAlign: "center",
                                        position: "absolute",
                                        right: "5px",
                                        top: "5px",
                                      }}
                                      onClick={() => remove()}
                                    >
                                      <i className="fa fa-trash text-red"></i>
                                    </div>
                                  )}
                                  <div
                                    className="dropzone"
                                    style={{ minHeight: "100px", textAlign: "center" }}
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    {stringIsNullOrEmpty(receiptImage) && (
                                      <h4 style={{ color: "grey" }}>
                                        {t(
                                          "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                                        )}
                                      </h4>
                                    )}
                                    {!stringIsNullOrEmpty(receiptImage) && (
                                      <aside className="thumbsContainer">
                                        <div className="thumb">
                                          <div className="thumbInner">
                                            <img
                                              src={receiptImage}
                                              className="dropzone-img"
                                            />
                                          </div>
                                        </div>
                                      </aside>
                                    )}
                                  </div>
                                </section>
                                {!stringIsNullOrEmpty(receiptImage) ? (
                                  <div style={{ color: "#002e6c", fontSize: "15px" }}>
                                    {t("UPLOADED_FILE_SIZE") +
                                      ": " +
                                      numberWithCurrencyFormat(imageFileSize, 2, true) +
                                      "MB"}
                                  </div>
                                ) : (
                                  <div></div>
                                )}
                              </div>
                            )}
                          </Dropzone>
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          {bankIsSelected && (
            <>


              <div className="form-group mb-4">
                <label className="font-15 text-white font-semi mb-3">

                </label>

              </div>
              <div className="form-group mt-3 col-md-12 px-0">
                <button type="submit" className="custom-btn-style-1">
                  {t("SUBMIT")}
                </button>
              </div>
            </>
          )}
        </div>
      </form>
      <Modal
        fade={false}
        contentClassName="modal-brand modal-bottom modal-reload-success"
        isOpen={isReloadSuccess}
        toggle={() => {
          setIsReloadSuccess(false);
        }}
        centered
      >
        <ModalBody>
          <div className="text-center">
            <strong className="reload-title text-yellow mb-3 d-block">
              {t("RELOAD_SUCCESS")}
            </strong>
            <div className="text-brand text-bo">
              {t("PAID_DEPOSIT", { amount: paidAmount })}
            </div>
          </div>
          <table className="table text-brand table-reload-detail">
            <tr>
              <th>{t("DEPOSIT_DATE_TIME")}</th>
              <th style={{ textAlign: "right" }}>{reloadDate}</th>
            </tr>
            <tr>
              <th>{t("REFERENCE_NUMBER")}</th>
              <th style={{ textAlign: "right" }}>{transcactionNumber}</th>
            </tr>
          </table>
          <button
            className="custom-btn-style-1"
            onClick={() => {
              _history.push(WebUrl._URL_MAIN);
            }}
          >
            {t("GO_TO_HOME")}
          </button>
        </ModalBody>
      </Modal>
    </>
  );
};

export default NormalDeposit;

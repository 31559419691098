import React, { useState, useEffect, useRef } from "react";
import NormalDeposit from "./NormalDeposit";
import TNGDeposit from "./TNGDeposit";
import InstantDeposit from "./InstantDeposit";
import IBayarDeposit from "./IBayarDeposit";
import SPGDeposit from "./SPGDeposit";
import VaderDeposit from "./VaderDeposit";
import SPGQRDeposit from "./SPGQRDeposit";
import VaderQRDeposit from "./VaderQRDeposit";
import FattyPayDeposit from "./FattyPayDeposit";
import FattyPayQRDeposit from "./FattyPayQRDeposit";
import PowerPayDeposit from "./PowerPayDeposit";
//import "react-simple-keyboard/build/css/index.css";
import Sidebar from "components/sidebar";
import { isEmpty, isNull, isUndefined, map } from "lodash";
import ContentHeader from "components/content/header";
import ApiEngine from "../../util/ApiEngine";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  ApiKey,
  ApiUrl,
  SessionKey,
  WebUrl,
  LanguageOption,
  TransactionType,
  BankingChannel,
  PanelType,
  _CURRENCY_RATIO,
  BankingDepositType,
  FixedDepositAmount
} from "../../util/Constant";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalBody,
  Alert
} from "reactstrap";
import { stringIsNullOrEmpty, createFormBody, createMultiPartFormBody, numberWithCurrencyFormat, numberWithCommas } from "../../util/Util";
import {
  showResponseMessage,
  showResponseMessageWithoutT,
  setBusy,
  setIdle
} from "../../redux/AppAction";
import classnames from "classnames";
import TngIcon from "../../assets/img/assets/normal1.svg";
import NormalIcon from "../../assets/img/assets/normal2.svg";
import PayTrustIcon from "../../assets/img/assets/payment2.svg";
import VaderPayIcon from "../../assets/img/assets/payment4.svg";
import VaderPayQRIcon from "../../assets/img/assets/VaderPay_QR.svg";
import SPGIcon from "../../assets/img/assets/SPG.svg";
import FattyPayIcon from "../../assets/img/assets/FattyPay.svg";
import FattyPayQRIcon from "../../assets/img/assets/truepayWhite.svg";
import PowerPayicon from "../../assets/img/icon/PowerPay.png";
import SPGQRIcon from "../../assets/img/assets/SPG_QR.svg";
import OfflineIcon from "../../assets/img/icon/Offline_Icon.png";
import BlueWarningIcon from "../../assets/img/v3/notice_blue.svg";
import { Link } from "react-router-dom";
import { mdiHistory } from "@mdi/js";
import Icon from "@mdi/react";
import { Accordion } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Slider from "react-slick";
import Spinner from "react-bootstrap/Spinner";
import Dropzone from "react-dropzone";
import parse from 'react-html-parser';

function CustomToggle({ children, eventKey }) {
  const wrapOnClick = useAccordionButton(eventKey, () =>
    console.log("totally custom!")
  );

  return <div onClick={wrapOnClick}>{children}</div>;
}


const DepositNew = () => {
  const { t } = useTranslation();
  const _dispatch = useDispatch();

  const {
    handleSubmit: handleFullNameSubmit,
    register: registerFullName,
    getValues: getValuesFullName,
    errors: errorsFullName,
    setValue: setValueFullName,
  } = useForm();
  const {
    handleSubmit,
    register,
    getValues,
    errors,
    setError,
    clearErrors,
    setValue,
    control
  } = useForm();
  const _INSTANT_DEPOSIT = 0;
  const _NORMAL_DEPOSIT = -1;
  const _TNG_DEPOSIT = -2;
  const _SPG_QR = -3;
  const _VADERPAY_QR = -4;
  const _INSTANT_PAYMENT = 1;
  const _NORMAL_UPLOAD_DEPOSIT = 2;
  const _QR_DEPOSIT = 3;
  const [headerMsg, setHeaderMsg] = useState("");

  const [instantPayOption, setInstantPayOption] = useState([]);
  const [tempInstantPayOption, setTempInstantPayOption] = useState([]);
  //const [tempNormalPayOption, setTempNormalPayOption] = useState([]);
  const [paymentOptionList, setPaymentOptionList] = useState([]);
  const [paymentOption, setPaymentOption] = useState(_INSTANT_PAYMENT);
  const [activeTab, setActiveTab] = useState(_INSTANT_PAYMENT);
  const [showFullnamePopup, setShowFullnamePopup] = useState(false);
  const [memberId, setMemberId] = useState("");
  const [tngStartTime, setTngStartTime] = useState(moment());
  const [tngEndTime, setTngEndTime] = useState(moment());
  const [activePaymentOption, setActivePaymentOption] = useState();
  const [tngStatus, setTngStatus] = useState(false);
  const [transType, setTransType] = useState("");
  const currentHourInUTC0 = moment();
  const [countdownPeriod, setCountdownPeriod] = useState(0);
  const [hasPendingTxn, setHasPendingTxn] = useState(false);
  const [countdownStr, setCountdownStr] = useState("");
  const [timeDiffSeconds, setTimeDiffSeconds] = useState(0);
  const [countdownTime, setCountdownTime] = useState();
  const _SECONDS_IN_MS = 1000;
  const [amountValue, setAmountValue] = useState("");
  const [displayAmountValue, setDisplayAmountValue] = useState("IDR 0");
  const [bonusDepositList, setBonusDepositList] = useState([]);
  const [bonusDeposit, setBonusDeposit] = useState("");
  const [bonusDepositName, setBonusDepositName] = useState("");
  const [showPaymentOption, setShowPaymentOption] = useState(false);
  const isMounted = useRef(false);
  let _userData = useSelector((state) => state.authState.userData);
  const [bankAccountOption, setBankAccountOption] = useState([]);
  const [bankActive, setBankActive] = useState(0);
  const [depositReference, setDepositReference] = useState("");
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [bankAccountId, setBankAccountId] = useState("");
  const [showCopyAlert, setShowCopyAlert] = useState(false);
  const _ALERT_TIMEOUT = 3000;
  const [isReloadSuccess, setIsReloadSuccess] = useState(false);
  const [canDisplayBank, setCanDisplayBank] = useState(true);
  const [paidAmount, setPaidAmount] = useState(0);
  const [reloadDate, setReloadDate] = useState();
  const [transcactionNumber, setTranscactionNumber] = useState();
  const fixedAmount = FixedDepositAmount;
  const [receiptImage, setReceiptImage] = useState();
  const [imageFileSize, setImageFileSize] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [bankCount, setBankCount] = useState(5);
  const [showAll, setShowAll] = useState(false);
  const [bankIsSelected, setBankIsSelected] = useState(false);
  const [normalDepositContent, setNormalDepositContent] = useState("");
  const [selectedBank, setSelectedBank] = useState({});
  const ONE_MB_IN_BYTES = 1048576;
  const fileSizeLimit_InMB = 20;
  var TNG_START_HOUR_LIMIT_IN_UTC0 = 0;
  var TNG_END_HOUR_LIMIT_IN_UTC0 = 23;
  //var vaderPayStartTime = moment(
  //  moment().format("YYYY-MM-DD") + " 00:30:00.000"
  //);
  //var vaderPayEndTime = moment(moment().format("YYYY-MM-DD") + " 23:30:00.000");

  const toggleTab = async (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
    //await togglePaymentOptionList(tab);
  };

  const bannerSlick = {
    slidesToShow: 3,
    dots: true,
    arrows: false,
    swipe: true,
    infinite: false,
    swipeToSlide: true,
    speed: 1000,
    // autoplay: true,
  };

  useEffect(() => {
    init();
  }, [
    localStorage.getItem(SessionKey._LANGUAGE) &&
      localStorage.getItem(SessionKey._LANGUAGE) != "undefined"
      ? localStorage.getItem(SessionKey._LANGUAGE)
      : "en",
  ]);

  useEffect(() => {
    if (countdownPeriod > 0) {
      clearTimeout(countdownTime);
      setCountdownTime(
        setTimeout(() => {
          let tempPeriod = countdownPeriod - 1;
          setCountdownPeriod(tempPeriod);
          let tempX = moment.duration(tempPeriod);
          let tempMM = Math.floor(tempX["_milliseconds"] / 60);
          let tempSS = tempX["_milliseconds"] - tempMM * 60;
          setTimeDiffSeconds(tempX["_milliseconds"]);
          let tempStr =
            (tempMM.toString().length > 1 ? tempMM : "0" + tempMM) +
            " " +
            t("MINUTES") +
            " " +
            (tempSS.toString().length > 1 ? tempSS : "0" + tempSS) +
            " " +
            t("SECOND");
          setCountdownStr(tempStr);
        }, _SECONDS_IN_MS)
      );
    }
  }, [countdownPeriod]);

  async function showTngOffline() {
    _dispatch(
      showResponseMessageWithoutT(
        false,
        t("TNG_OFFLINE", { startTime: tngStartTime, endTime: tngEndTime })
      )
    );
  }

  async function showTngUnavailable() {
    _dispatch(showResponseMessageWithoutT(false, t("TNG_UNAVAILABLE")));
  }

  const init = () => {
    getBankAccountByBankId();
    getDepositContent();
    (async function () {
      let memberJson = await ApiEngine.get(ApiUrl._API_GET_USER_FULLNAME);
      if (memberJson[ApiKey._API_SUCCESS_KEY]) {
        setMemberId(memberJson[ApiKey._API_DATA_KEY]["memberId"]);
        if (
          stringIsNullOrEmpty(memberJson[ApiKey._API_DATA_KEY]["userFullName"])
        ) {
          setShowFullnamePopup(true);
        }
      }
      let tempOptions = [];
      let responseJson = await ApiEngine.get(
        ApiUrl._API_GET_USER_PAYMENT_GATEWAY_INFO
      );
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        setInstantPayOption(responseJson[ApiKey._API_DATA_KEY]);

        let temp = responseJson[ApiKey._API_DATA_KEY].findIndex(
          (x) => x.instantPayGatewayString === activePaymentOption
        );

        setPaymentOption(temp);
        responseJson[ApiKey._API_DATA_KEY].map((option, index) => {
          option["status"] === true &&
            tempOptions.push({
              key: index,
              value: option.id,
              label: option.instantPayGatewayString,
              image: option.image,
              fee: option.serviceCharge,
              paymentType:
                option.instantPayGatewayString.endsWith(" QR") ||
                  option.instantPayGatewayString == "TruePay"
                  ? t("DUIT_NOW")
                  : t("INSTANT_PAYMENT"),
            });
        });
        setTempInstantPayOption(tempOptions);
        //let tempNormalOptions = [];
        let TnGresponseJson = await ApiEngine.get(
          ApiUrl._API_GET_COMPANY_TNG_SYSTEM_SETTINGS
        );
        let startTimeStr = "";
        let endTimeStr = "";
        let TnGStatus = false;

        if (
          TnGresponseJson[ApiKey._API_SUCCESS_KEY] &&
          TnGresponseJson[ApiKey._API_DATA_KEY].length > 0
        ) {
          let data = TnGresponseJson[ApiKey._API_DATA_KEY];

          data.map((keyValuePair, index) => {
            if (keyValuePair["key"] === "TnGStartTime") {
              startTimeStr = keyValuePair["value"];
              setTngStartTime(keyValuePair["value"]);
              TNG_START_HOUR_LIMIT_IN_UTC0 = moment(
                moment().format("YYYY-MM-DD") + " " + startTimeStr,
                "YYYY-MM-DD HH:mm:ss"
              );
            }
            if (keyValuePair["key"] === "TnGEndTime") {
              endTimeStr = keyValuePair["value"];
              setTngEndTime(keyValuePair["value"]);
              TNG_END_HOUR_LIMIT_IN_UTC0 = moment(
                moment().format("YYYY-MM-DD") + " " + endTimeStr,
                "YYYY-MM-DD HH:mm:ss"
              );
            }
            if (keyValuePair["key"] === "TnGStatus") {
              TnGStatus = keyValuePair["value"] === "true";
            }
          });
        }

        if (
          moment(TNG_END_HOUR_LIMIT_IN_UTC0).isBefore(
            TNG_START_HOUR_LIMIT_IN_UTC0
          )
        ) {
          TNG_END_HOUR_LIMIT_IN_UTC0 = moment(TNG_END_HOUR_LIMIT_IN_UTC0).add(
            1,
            "day"
          );
        }

        if (
          TnGStatus &&
          currentHourInUTC0.isAfter(TNG_START_HOUR_LIMIT_IN_UTC0) &&
          currentHourInUTC0.isBefore(TNG_END_HOUR_LIMIT_IN_UTC0)
        ) {
          //tempNormalOptions.push({ key: _TNG_DEPOSIT, value: _TNG_DEPOSIT, label: "Touch N Go (" + (moment(TNG_START_HOUR_LIMIT_IN_UTC0).format("HH:mm")) + "- " + (moment(TNG_END_HOUR_LIMIT_IN_UTC0).format("HH:mm")) + ") GMT+8", image: null, fee: 0 });
          tempOptions.push({
            key: _TNG_DEPOSIT,
            value: _TNG_DEPOSIT,
            label: "TnG",
            image: null,
            paymentType: t("NORMAL_DEPOSIT"),
            fee: 0,
          });
          setTngStatus(true);
        }

        tempOptions.push({
          key: _NORMAL_DEPOSIT,
          value: _NORMAL_DEPOSIT,
          label: "Normal",
          image: null,
          paymentType: t("NORMAL_DEPOSIT"),
          fee: 0,
        });

        // tempOptions.push({
        //   key: _SPG_QR,
        //   value: 3,
        //   label: "SPG QR",
        //   image: null,
        //   fee: 0,
        // });

        // tempOptions.push({
        //   key: _VADERPAY_QR,
        //   value: 4,
        //   label: "VaderPay QR",
        //   image: null,
        //   fee: 0,
        // });

        //setTempNormalPayOption(tempNormalOptions);
        if (
          tempOptions.filter((x) => x.paymentType === t("INSTANT_PAYMENT"))
            .length <= 0
        ) {
          setActiveTab(_NORMAL_DEPOSIT);
        }
        setValue("paymentMethod", tempOptions[0].value);
        setPaymentOptionList(tempOptions);
      }

      setHeaderMsg("");
      var pendingTransactionJson = await ApiEngine.get(
        ApiUrl._API_GET_PENDING_TRANSACTION
      );
      if (pendingTransactionJson[ApiKey._API_SUCCESS_KEY]) {
        if (pendingTransactionJson[ApiKey._API_DATA_KEY] != null) {
          clearTimeout(countdownTime);
          let tempS = parseInt(
            pendingTransactionJson[ApiKey._API_DATA_KEY]["timeSpan"]
          );
          setTransType(
            pendingTransactionJson[ApiKey._API_DATA_KEY]["transType"]
          );
          setTimeDiffSeconds(tempS);
          setCountdownPeriod(tempS < 0 ? 0 : tempS);
          setHasPendingTxn(true);
          setHeaderMsg(t("NEW_TXN_PROHIBITED"));
        }
      } else {
        _dispatch(
          showResponseMessage(
            false,
            pendingTransactionJson[ApiKey._API_MESSAGE_KEY]
          )
        );
      }

      var language =
        LanguageOption.filter(
          (option) => option.value == localStorage.getItem(SessionKey._LANGUAGE)
        )[0] !== undefined
          ? LanguageOption.filter(
            (option) =>
              option.value == localStorage.getItem(SessionKey._LANGUAGE)
          )[0].languageId
          : 7;
      var getBonusDeposit = await ApiEngine.get(
        ApiUrl._API_GET_BONUS_DEPOSIT +
        "?memberId=" +
        _userData.userId +
        "&languageId=" +
        language
      );
      if (getBonusDeposit[ApiKey._API_SUCCESS_KEY]) {
        let data = getBonusDeposit[ApiKey._API_DATA_KEY];
        let itemList = [];

        let item = null;
        data.map((row, index) => {
          item = {
            name: row["bonusName"],
            bonusId: row["id"],
            info: {
              bonus: row["bonus"],
              minimumDeposit: row["minimumDeposit"],
              rollover: row["rollover"],
              maxBonus: row["maxBonus"],
              freeSpinStatus: row["freeSpinStatus"],
              maxLimitPerMember: row["maxLimitPerMember"],
              dayLimit: row["dayLimit"],
              image: row["bonusImage"],
              popImage: row["popupImage"],
              remainingCount: row["remainingCount"],
              refreshDateTime:
                row["refreshDateTime"] !== ""
                  ? moment(row["refreshDateTime"], "YYYY-MM-DD").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                  : "",
            },
          };
          itemList.push(item);
          //by default select 1st bonus option
          if (index === 0) {
            updateBonusDeposit(item);
          }
        });
        setBonusDepositList(itemList);
      } else {
        _dispatch(
          showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
        );
      }
    })();
  };

  const togglePaymentOptionList = async (tab) => {
    if (tab === _INSTANT_PAYMENT) {
      setValue("paymentMethod", tempInstantPayOption[0].value);
      setPaymentOption(0);
      setPaymentOptionList(tempInstantPayOption);
    } else if (tab === _NORMAL_UPLOAD_DEPOSIT) {
      //setValue("paymentMethod", tempNormalPayOption[0].value);
      //setPaymentOption(tempNormalPayOption[0].value);
      //(tempNormalPayOption);
    }
  };

  const toggleActivePaymentOption = (selectedPaymentOption, tab) => {
    toggleTab(tab);
    setActivePaymentOption(selectedPaymentOption);
    let temp = paymentOptionList.find(
      (x) => x.label === selectedPaymentOption
    ).value;
    if (tab === _INSTANT_PAYMENT) {
      let tempId = instantPayOption.findIndex((x) => x.id === temp);
      setPaymentOption(tempId);
    } else if (tab === _QR_DEPOSIT) {
      let tempId = instantPayOption.findIndex((x) => x.id === temp);
      setPaymentOption(tempId);
    } else {
      setPaymentOption(temp);
    }
    setValue("paymentMethod", temp);
  };

  async function submitFullName(data) {
    let params = {
      userFullName: data.userFullName,
    };

    let responseJson = await ApiEngine.post(
      ApiUrl._API_UPDATE_MEMBER_USERFULLNAME,
      createFormBody(params)
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );

      setShowFullnamePopup(false);
    } else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }

    init();
  }

  async function updateBonusDeposit(menu) {
    setBonusDeposit(menu["bonusId"]);
    setBonusDepositName(menu["name"]);
    setShowPaymentOption(true);
    //toggleActivePaymentOption(
    //  paymentOptionList.filter((x) => x.paymentType === "Instant Payment")[0]
    //    .label,
    //  _INSTANT_PAYMENT
    //);
  }

  useEffect(() => {
    if (isMounted.current) {
      updateBonusDeposit(bonusDepositList[0]);
    } else {
      isMounted.current = true;
    }
  }, [
    paymentOptionList.length > 0 &&
    bonusDepositList.length > 0 &&
    !isUndefined(paymentOptionList) &&
    !isUndefined(bonusDepositList),
  ]);

  async function getBankAccountByBankId() {
    let responseJson = await ApiEngine.get(
      ApiUrl._API_GET_BANK_ACCOUNT +
      "?deposit=" +
      true
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let bankAccountList = [];
      responseJson[ApiKey._API_DATA_KEY].forEach(function (bankAccount) {
        bankAccountList.push({
          label: bankAccount.accountHolderName,
          value: bankAccount.id,
          accNumber: bankAccount.accountNumber,
          min: bankAccount.minimumDeposit,
          max: bankAccount.maximumDeposit,
          bankName: bankAccount.bankName,
          bankImg: bankAccount.bankImg,
          bankImg2: bankAccount.bankImg2,
          bankDepositTypeId: bankAccount.bankDepositTypeId
        });
      });

      setBankAccountOption(bankAccountList);

      let defaultBank = bankAccountList[0];
      setBankActive(defaultBank.value);
      setMinMaxButton(defaultBank.min, defaultBank.max, defaultBank.value);
    }

    let apiUrl = ApiUrl._API_GET_USER_SYSTEM_SETTINGS + "?type=depositReference";
    let settingJson = await ApiEngine.get(apiUrl);
    if (settingJson[ApiKey._API_SUCCESS_KEY] && settingJson[ApiKey._API_DATA_KEY] != null) {
      let data = settingJson[ApiKey._API_DATA_KEY];
      setDepositReference(data);
    }
  }

  function setMinMaxButton(min, max, bankId) {
    setMin(min);
    setMax(max);
    setBankAccountId(bankId);
  }

  function copyText(label) {
    navigator.clipboard.writeText(label);
    document.execCommand("copy");
    setShowCopyAlert(true);
    setTimeout(() => setShowCopyAlert(false), [_ALERT_TIMEOUT]);
  }

  async function onSubmit(data, e) {
    _dispatch(setBusy());
    let params = {
      transactionTypeId: TransactionType._DEPOSIT,
      amount: amountValue,
      channel: BankingChannel._CHANNEL_OFFLINE_BANKING,
      panel: PanelType._MEMBERSITE,
      bankAccountId: data.bankAccountId,
      receiptReference: data.reference,
    };
    if (!stringIsNullOrEmpty(bankAccountId)) {
      params["bankAccountId"] = bankAccountId;
    }
    if (!stringIsNullOrEmpty(data.receipt)) {
      params["receipt"] = data.receipt;
    }
    if (!stringIsNullOrEmpty(bonusDeposit) && bonusDeposit != "-1") {
      params["bonusId"] = bonusDeposit;
    } else if (bonusDeposit == "-1") {
      params["bonusId"] = "";
    }

    let responseJson = await ApiEngine.post(
      ApiUrl._API_CREATE_TRANSACTION,
      createMultiPartFormBody(params)
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setPaidAmount(params.amount);
      setIsReloadSuccess(true);
      setReloadDate(moment().format("DD MMM YYYY, hh:mm A"));
      setTranscactionNumber(responseJson[ApiKey._API_DATA_KEY]);
      //props.setTriggerParentInit(true);
      e.target.reset();
      resetFormField();

      let defaultBank = bankAccountOption[0];
      setBankActive(defaultBank.value);

    } else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          t(responseJson[ApiKey._API_MESSAGE_KEY])
        )
      );
    }
    _dispatch(setIdle());
  }

  function resetFormField() {
    setBankAccountOption([]);
    setValue("bankId", "");
    setValue("bankAccountId", "");
    setValue("receipt", null);
    setReceiptImage("");
    setAmountValue("");
    setValue("depositDateTime", moment().format("YYYY-MM-DD HH:mm:ss"));
  }
  const imageDrop = (acceptedFiles) => {
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];
      const fileSizeInMB = file.size / ONE_MB_IN_BYTES;
      setImageFileSize(fileSizeInMB);
      setValue("receipt", file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setReceiptImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const remove = () => {
    setReceiptImage("");
    setValue("receipt", null);
  };

  const handleModalOnClick = () => {
    setIsReloadSuccess(false);
    //props.parentInit();
    init();
  };

  async function showAllList() {
    if (!showAll) {
      let length = bankAccountOption.length;
      setBankCount(length);
      setShowAll(!showAll);
    } else {
      setBankCount(5);
      setShowAll(!showAll);
    }
  }
  const bankList = [
    {
      id: 1,
      title: "Bank",
    },
    {
      id: 2,
      title: "E-Wallet",
    },
    {
      id: 3,
      title: "Pulsa",
    },
  ];

  const handleBankSelection = async (bank) => {
    setBankIsSelected(true);
    setSelectedBank(bank);
  }

  const getDepositContent = async () => {
    let activeLanguageCode =
      (localStorage.getItem(SessionKey._LANGUAGE) != null ||
        localStorage.getItem(SessionKey._LANGUAGE) != undefined ||
        localStorage.getItem(SessionKey._LANGUAGE) != "undefined")
        ? localStorage.getItem(SessionKey._LANGUAGE) : "en";
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_COMPANY_CONTENT_LANGUAGE + "?title=NormalDepositTnCDesktop&languageCode=" + activeLanguageCode);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      setNormalDepositContent(data);
    }
  }

  const checkValueOnKeyDown = async (e) => {
    clearErrors();
    let tempAmountValue = amountValue;
    if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) { //keyCode of 0 to 9, numpad 0 to 9
      tempAmountValue = amountValue + "" + e.key;
      setAmountValue(tempAmountValue);
    }
    else if (e.keyCode == 8 || e.keyCode == 46) //keyCode of backSpace and Del
    {
      tempAmountValue = amountValue.slice(0, -1);
      setAmountValue(tempAmountValue);
    }
    if (parseFloat(tempAmountValue) < min) {
      setError("amount", {
        message: "VALUE_LESS_THAN_MINIMUM",
      });
    }
    else if (parseFloat(tempAmountValue) > max) {
      setError("amount", {
        message: "EXCEED_MAXIMUM_VALUE",
      });
    }
  }

  const checkValueOnSelectFixedAmt = async (fixedAmt) => {
    let updatedValue = (parseFloat(fixedAmt) + parseFloat(stringIsNullOrEmpty(amountValue) ? 0 : amountValue)).toString();
    setAmountValue(
      updatedValue
    );
    clearErrors();
    let tempAmountValue = updatedValue;
    if (parseFloat(tempAmountValue) < min) {
      setError("amount", {
        message: "VALUE_LESS_THAN_MINIMUM",
      });
    }
    else if (parseFloat(tempAmountValue) > max) {
      setError("amount", {
        message: "EXCEED_MAXIMUM_VALUE",
      });
    }
  }

  return (
    <section className="section-padding" id="deposit-section">
      <div className="container">
        <div className="row">
          <div className="col-3" id="account-menu">
            <Sidebar activePage={"WALLET"} />
          </div>
          <div className="col-9">
            <ContentHeader enableBackButton title={t("DEPOSIT")} />
            <hr className="right-title-hr" />

            {hasPendingTxn && headerMsg && (
              <div className="alert alert-warning">
                {timeDiffSeconds > 0
                  ? t("PLEASE_WAIT") +
                  " " +
                  countdownStr +
                  " " +
                  (transType === "Deposit"
                    ? t("FOR_DEPOSIT_APPROVAL")
                    : t("FOR_WITHDRAWAL_APPROVAL"))
                  : headerMsg}
              </div>
            )}

            <div defaultActiveKey="0" className="accordion-container">
              <div className="deposit-container-box">
                <div>
                  <div>
                    <h4 className="font-15 mb-0">
                      {t("BONUS_DEPOSIT")}
                      {!stringIsNullOrEmpty(bonusDeposit)
                        ? bonusDeposit != "-1"
                          ? ": " + bonusDepositName
                          : ": " + t("NONE")
                        : ""}
                    </h4>
                  </div>
                </div>
                <div>
                  <div className="bonusdeposit-menu">
                    <div className="font-11 mb-3" style={{ color: "#5c708a" }}>
                      {t("SELECT_A_PAYMENT")}
                    </div>
                    {bonusDepositList.length === 0 ? (
                      <div className="loading-spinner-wrapper">
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      </div>
                    ) : (
                      <Slider {...bannerSlick} className="deposit-slick">
                        {bonusDepositList.map((menu, index) => {
                          return (
                            <>
                              <CustomToggle eventKey="1">
                                <div
                                  key={index}
                                  className={
                                    "deposit-slick-item" +
                                    (bonusDeposit == menu["bonusId"]
                                      ? " active"
                                      : "")
                                  }
                                  onClick={() => updateBonusDeposit(menu)}
                                >
                                  <div className="deposit-bonus-image">
                                    <img
                                      src={menu["info"]["image"]}
                                      className="w-100"
                                      alt={menu["info"]["bonus"]}
                                    />
                                  </div>
                                  <div className="deposit-bonus-details">
                                    {menu["info"] && (
                                      <>
                                        <div>
                                          {" "}
                                          {t("BONUS") +
                                            " (%) : " +
                                            menu["info"]["bonus"]}
                                        </div>
                                        <div>
                                          {" "}
                                          {t("MINIMUM_DEPOSIT") +
                                            " : " +
                                            menu["info"]["minimumDeposit"]}
                                        </div>
                                        <div>
                                          {" "}
                                          {t("ROLLOVER") +
                                            " : x" +
                                            menu["info"]["rollover"]}
                                        </div>
                                        {menu["info"]["bonus"] > 0 &&
                                          menu["info"]["maxBonus"] && (
                                            <div>
                                              {" "}
                                              {t("MAX_BONUS") +
                                                " : " +
                                                menu["info"]["maxBonus"]}
                                            </div>
                                          )}
                                        {/* <div> {t("ELIGIBLE_FOR_FREE_SPIN") + " : " + (menu["info"]["freeSpinStatus"] ? t("YES") : t("NO"))}</div> */}
                                        <div>
                                          {" "}
                                          {menu["info"]["maxLimitPerMember"] !==
                                            0 &&
                                            t("CLAIM_LIMIT") +
                                            " : " +
                                            menu["info"][
                                            "maxLimitPerMember"
                                            ] +
                                            " " +
                                            t("TIMES") +
                                            (menu["info"]["remainingCount"] >
                                              0
                                              ? " (" +
                                              menu["info"][
                                              "remainingCount"
                                              ] +
                                              " " +
                                              t("LEFT") +
                                              ")"
                                              : "")}{" "}
                                        </div>
                                        <div>
                                          {" "}
                                          {menu["info"]["maxLimitPerMember"] !==
                                            0 &&
                                            menu["info"]["dayLimit"] !== 0 && (
                                              <span className="claim-limit-reminder">
                                                {"*" +
                                                  t(
                                                    "LIMIT_REFRESHES_EVERY_FEW_DAYS",
                                                    {
                                                      value:
                                                        menu["info"][
                                                        "dayLimit"
                                                        ],
                                                    }
                                                  )}
                                              </span>
                                            )}{" "}
                                        </div>
                                        <div>
                                          {" "}
                                          {menu["info"]["maxLimitPerMember"] !==
                                            0 &&
                                            menu["info"]["dayLimit"] !== 0 &&
                                            menu["info"]["refreshDateTime"] !==
                                            "" && (
                                              <span className="claim-limit-reminder">
                                                {menu["info"][
                                                  "refreshDateTime"
                                                ] !== "" &&
                                                  "*" +
                                                  t("REFRESHES_ON") +
                                                  " " +
                                                  menu["info"][
                                                  "refreshDateTime"
                                                  ]}
                                              </span>
                                            )}{" "}
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </CustomToggle>
                            </>
                          );
                        })}
                      </Slider>
                    )}
                  </div>
                </div>
              </div>

              <div className="deposit-container-box">
                <div
                  className="d-none"
                  onClick={() => {
                    toggleActivePaymentOption(
                      paymentOptionList.filter(
                        (x) => x.paymentType === "Instant Payment"
                      )[0].label,
                      _INSTANT_PAYMENT
                    );
                  }}
                >
                  <div class="font-15">{t("PAYMENT_METHOD")}</div>
                </div>
                <div>
                  <div className="payment-method-box">
                    <div
                      className="row"
                      style={{
                        justifyContent: "space-between",
                        padding: "0px 10px",
                      }}
                    >
                      <h4 className="font-15 mb-3">Reload Method</h4>
                      <Link to={WebUrl._URL_DEPOSIT_RECORD}>
                        <div className="d-flex justify-content-end align-items-center deposit-record-btn">
                          <div style={{ marginRight: "5px", fontSize: "12px" }}>
                            {" "}
                            {t("DEPOSIT_RECORD")}{" "}
                          </div>
                          <Icon
                            path={mdiHistory}
                            title="User Profile"
                            size={1}
                          />
                        </div>
                      </Link>
                    </div>

                    {paymentOptionList.length > 0 && (
                      <>
                        <Accordion
                          defaultActiveKey="0"
                          className="accordion-container"
                        >
                          <Accordion.Item
                            className="accordion-bonusdeposit"
                            eventKey="0"
                          >
                            <Accordion.Header
                              onClick={(e) => {
                                setActiveTab(_INSTANT_PAYMENT);
                                toggleActivePaymentOption(
                                  "PowerPay88",
                                  _INSTANT_PAYMENT
                                );
                              }}
                            >
                              <div className="font-14">
                                <div className="accordion-circle-icon"></div>
                                <div>{t("INSTANT_PAYMENT")}</div>
                              </div>
                            </Accordion.Header>

                            <Accordion.Body>
                              <div className="payment-methods-radio">
                                {paymentOptionList
                                  .filter(
                                    (x) =>
                                      x.paymentType === t("INSTANT_PAYMENT")
                                  )
                                  .map((option, index) => {
                                    return (
                                      <div key={index}>
                                        {option["label"].startsWith(
                                          "VaderPay"
                                        ) ? (
                                          //moment() > vaderPayStartTime &&
                                          //moment() < vaderPayEndTime && (
                                          <div
                                            key={
                                              "instant_payment_option" + index
                                            }
                                          >
                                            <div className="form-checkbox">
                                              <div
                                                id={"method" + index}
                                                className={
                                                  "option " +
                                                  classnames({
                                                    active:
                                                      activePaymentOption ===
                                                      option["label"],
                                                  })
                                                }
                                                onClick={() =>
                                                  toggleActivePaymentOption(
                                                    option["label"],
                                                    _INSTANT_PAYMENT
                                                  )
                                                }
                                              >
                                                <label>
                                                  <div>
                                                    <img
                                                      src={`${VaderPayIcon}`}
                                                      className="img-responsive"
                                                      alt="payment"
                                                    />
                                                  </div>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          /* )*/
                                          <div
                                            key={
                                              "instant_payment_option" + index
                                            }
                                          >
                                            <div className="form-checkbox">
                                              <div
                                                id={"method" + index}
                                                className={
                                                  "option " +
                                                  classnames({
                                                    active:
                                                      activePaymentOption ===
                                                      option["label"],
                                                  })
                                                }
                                                onClick={() =>
                                                  toggleActivePaymentOption(
                                                    option["label"],
                                                    _INSTANT_PAYMENT
                                                  )
                                                }
                                              >
                                                <label>
                                                  {
                                                    <div>
                                                      <img
                                                        src={`${option["label"] ===
                                                          "PayTrust"
                                                          ? PayTrustIcon
                                                          : option[
                                                            "label"
                                                          ] === "SPG"
                                                            ? SPGIcon
                                                            : FattyPayIcon
                                                          }`}
                                                        className="img-responsive img1"
                                                        alt="payment"
                                                      />
                                                      <img
                                                        src={`${option["label"] ===
                                                          "PayTrust"
                                                          ? PayTrustIcon
                                                          : option[
                                                            "label"
                                                          ] === "SPG"
                                                            ? SPGIcon
                                                            : option[
                                                              "label"
                                                            ] === "PowerPay88"
                                                              ? PowerPayicon
                                                              : FattyPayIcon
                                                          }`}
                                                        className="img-responsive img2"
                                                        alt="payment"
                                                      />
                                                    </div>
                                                  }
                                                  {/*option["label"] === "PayTrust" ? (
                                                    <div>
                                                    <img
                                                        src={
                                                        option["label"] === "SPG"
                                                            ? `${SPGIcon}`
                                                            : `${PayTrustIcon}`
                                                        }
                                                        className="img-responsive img1"
                                                        alt="payment"
                                                    />
                                                    <img
                                                        src={`${PayTrustIcon}`}
                                                        className="img-responsive img2"
                                                        alt="payment"
                                                    />
                                                    </div>
                                                ) : (
                                                    <div>
                                                    <img
                                                        src={
                                                        option["label"] === "SPG"
                                                            ? `${SPGIcon}`
                                                            : `${PayTrustIcon}`
                                                        }
                                                        className="img-responsive"
                                                        alt="payment"
                                                    />
                                                    </div>
                                                )*/}
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    );
                                  })}
                              </div>

                              <div className="">
                                <>
                                  {paymentOption >= 0 &&
                                    !isEmpty(instantPayOption) &&
                                    (activePaymentOption == "SPG" &&
                                      instantPayOption[paymentOption]
                                        .instantPayGatewayString == "SPG" ? (
                                      <SPGDeposit
                                        gatewayOption={
                                          instantPayOption[paymentOption]
                                        }
                                        parentInit={init}
                                        bonusDeposit={bonusDeposit}
                                      />
                                    ) : activePaymentOption == "iBayar" &&
                                      instantPayOption[paymentOption]
                                        .instantPayGatewayString == "iBayar" ? (
                                      <IBayarDeposit
                                        gatewayOption={
                                          instantPayOption[paymentOption]
                                        }
                                        parentInit={init}
                                        bonusDeposit={bonusDeposit}
                                      />
                                    ) : activePaymentOption == "VaderPay" &&
                                      instantPayOption[paymentOption]
                                        .instantPayGatewayString ==
                                      "VaderPay" ? (
                                      <VaderDeposit
                                        gatewayOption={
                                          instantPayOption[paymentOption]
                                        }
                                        // setTriggerParentInit={setTriggerParentInit}
                                        parentInit={init}
                                        bonusDeposit={bonusDeposit}
                                      />
                                    ) : activePaymentOption == "FattyPay" &&
                                      instantPayOption[paymentOption]
                                        .instantPayGatewayString ==
                                      "FattyPay" ? (
                                      <FattyPayDeposit
                                        gatewayOption={
                                          instantPayOption[paymentOption]
                                        }
                                        parentInit={init}
                                        bonusDeposit={bonusDeposit}
                                      />
                                    ) : activePaymentOption == "PowerPay88" &&
                                      instantPayOption[paymentOption]
                                        .instantPayGatewayString ==
                                      "PowerPay88" ? (
                                      <PowerPayDeposit
                                        gatewayOption={
                                          instantPayOption[paymentOption]
                                        }
                                        parentInit={init}
                                        bonusDeposit={bonusDeposit}
                                      />
                                    ) : (
                                      <InstantDeposit
                                        gatewayOption={
                                          instantPayOption[paymentOption]
                                        }
                                        parentInit={init}
                                        bonusDeposit={bonusDeposit}
                                      />
                                    ))}
                                </>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            className="accordion-bonusdeposit"
                            eventKey="1"
                          >
                            <Accordion.Header
                              onClick={(e) => {
                                setActiveTab(_NORMAL_UPLOAD_DEPOSIT);
                                toggleActivePaymentOption(
                                  "Normal",
                                  _NORMAL_UPLOAD_DEPOSIT
                                );
                              }}
                            >
                              <div className="font-14">
                                <div className="accordion-circle-icon"></div>
                                <div>{t("NORMAL_DEPOSIT")}</div>
                              </div>
                            </Accordion.Header>

                            <Accordion.Body>
                              <div className="payment-methods-radio">
                                {paymentOptionList
                                  .filter(
                                    (x) => x.paymentType === t("NORMAL_DEPOSIT")
                                  )
                                  .map((option, index) => {
                                    return (
                                      <div
                                        key={"normal_payment_option" + index}
                                      >
                                        <div className="form-checkbox">
                                          {(tngStatus == true &&
                                            option["label"] == "TnG") ||
                                            option["label"] == "Normal" ? (
                                            <div
                                              id={"methodnormal" + index}
                                              className={
                                                "option " +
                                                classnames({
                                                  active:
                                                    activePaymentOption ===
                                                    option["label"],
                                                })
                                              }
                                              onClick={() =>
                                                toggleActivePaymentOption(
                                                  option["label"],
                                                  _NORMAL_UPLOAD_DEPOSIT
                                                )
                                              }
                                            >
                                              <label>
                                                <div>
                                                  <img
                                                    src={
                                                      option["label"] === "TnG"
                                                        ? `${TngIcon}`
                                                        : `${NormalIcon}`
                                                    }
                                                    className="img-responsive"
                                                    alt="payment"
                                                  />
                                                </div>
                                              </label>
                                            </div>
                                          ) : (
                                            <div
                                              style={{ cursor: "not-allowed" }}
                                            >
                                              <div
                                                id={"methodnormal" + index}
                                                className={
                                                  "option " +
                                                  classnames({
                                                    active:
                                                      activePaymentOption ===
                                                      option["label"],
                                                  })
                                                }
                                                onClick={() => {
                                                  if (
                                                    moment().format(
                                                      "HH:mm:ss"
                                                    ) > tngStartTime &&
                                                    moment().format(
                                                      "HH:mm:ss"
                                                    ) < tngEndTime
                                                  ) {
                                                    showTngUnavailable();
                                                  } else {
                                                    showTngOffline();
                                                  }
                                                }}
                                              >
                                                <label>
                                                  <div>
                                                    <img
                                                      src={`${TngIcon}`}
                                                      className="img-responsive option-image offline-tng"
                                                      alt="payment"
                                                    />
                                                  </div>
                                                  <div
                                                    className="offline-icon"
                                                    style={{
                                                      backgroundImage: `url(${OfflineIcon})`,
                                                    }}
                                                  ></div>
                                                </label>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                              <form onSubmit={handleSubmit(onSubmit)}>
                                {showCopyAlert && (
                                  <div style={{ position: scrollPosition < 224 ? "relative" : "sticky", top: scrollPosition < 150 ? "107px" : scrollPosition >= 150 && scrollPosition < 160 ? "-65px" : scrollPosition >= 160 && scrollPosition < 170 ? "-55px" : scrollPosition >= 170 && scrollPosition < 180 ? "-45px" : scrollPosition >= 180 && scrollPosition < 190 ? "-35px" : scrollPosition >= 190 && scrollPosition < 200 ? "-25px" : scrollPosition >= 200 && scrollPosition < 210 ? "-15px" : scrollPosition >= 210 && scrollPosition < 224 ? "-5px" : "160px", left: "-55px", width: "100%", color: "white", zIndex: "99999" }}>
                                    <div
                                      className="content content-full-width"
                                      style={{
                                        position: "absolute",
                                        width: "200px",
                                        top: "10px",
                                        right: "-40px"
                                      }}
                                    >
                                      <Alert color="green" style={{ textAlign: "center" }}>
                                        {t("COPY_SUCCESS")}
                                      </Alert>
                                    </div>
                                  </div>
                                )}
                                <div className="normal-deposit-wrapper">
                                  <>
                                    {paymentOption !== 0 &&
                                      (paymentOption == _TNG_DEPOSIT &&
                                        tngStatus == true ? (
                                        <TNGDeposit
                                          parentInit={init}
                                          bonusDeposit={bonusDeposit}
                                        />
                                      ) : (
                                        // <NormalDeposit parentInit={init} bonusDeposit={bonusDeposit} />
                                        <>
                                          {bankIsSelected == false && (
                                            <>
                                              <div className="all-normal-banks-container">
                                                {bankList &&
                                                  bankList.length &&
                                                  bankList.map((parent, index) => {
                                                    if (bankAccountOption.filter(x => x.bankDepositTypeId == parent.id).length > 0) {
                                                      return (
                                                        <>
                                                          {index != 0 && (
                                                            <>
                                                              <hr class="custom-hr" />
                                                            </>
                                                          )}
                                                          <div className="normal-bank-item-container">
                                                            <div className="font14 normal-parent-bank-title">
                                                              {parent.title}<br />
                                                              {"(" + t("MANUAL_CHEQUE") + ")"}
                                                            </div>
                                                            <div className="deposit-normal-bank-list-container">
                                                              {bankAccountOption &&
                                                                bankAccountOption.filter(x => x.bankDepositTypeId == parent.id).length > 0 &&
                                                                bankAccountOption.filter(x => x.bankDepositTypeId == parent.id).map((child, index) => {
                                                                  return (
                                                                    <>
                                                                      <div
                                                                        key={index}
                                                                        className="deposit-normal-bank-list-items"
                                                                        onClick={() => { handleBankSelection(child) }}
                                                                      >
                                                                        <img
                                                                          src={child.bankImg2}
                                                                          alt="i8 banks"
                                                                          className="img-responsive"
                                                                        />
                                                                      </div>
                                                                    </>
                                                                  );
                                                                })
                                                              }
                                                            </div>
                                                          </div>
                                                        </>
                                                      );
                                                    }
                                                  })
                                                }
                                              </div>
                                            </>
                                          )}
                                          {bankIsSelected && (
                                            <>
                                              <div className="normal-bank-per-section-box">
                                                <div className="first">
                                                  {t("SELECTED_BANK_METHOD")}
                                                </div>
                                                <div className="second ">
                                                  <div className="normal-bank-selected-wrapper">
                                                    <div className="normal-bank-selected-img" onClick={() => { setBankIsSelected(false); setSelectedBank({}); }}>
                                                      <img src={selectedBank.bankImg2} alt="selected bank" />
                                                      <div className="normal-bank-tick-selected">
                                                        <img src={"/img/v3/reload/tick.png"} alt="active" />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <hr className="custom-hr" />
                                              <div className="normal-bank-per-section-box">
                                                <div className="first">
                                                  {t("BANK_ACCOUNT_DETAILS") + " (" + selectedBank.bankName + ")"}
                                                </div>
                                                <div className="second ">
                                                  {bankAccountOption &&
                                                    bankAccountOption.length &&
                                                    bankIsSelected &&
                                                    <div className="deposit-normal-bank-details-container">
                                                      <div className="deposit-normal-bank-details-item">
                                                        <div className="first">
                                                          {selectedBank.bankDepositTypeId == BankingDepositType._BANK ? t("BANK_NAME") : selectedBank.bankDepositTypeId == BankingDepositType._EWALLET ? t("EWALLET_ACCOUNT_NAME") : t("PULSA_ACCOUNT_NAME")}
                                                        </div>
                                                        <div className="second">
                                                          <div>
                                                            {selectedBank.bankName}
                                                          </div>
                                                          <div className="copy" onClick={() => copyText(selectedBank.bankName)}>
                                                            <img
                                                              src={'/img/v3/reload/copy.png'}
                                                              alt="i8 banks"
                                                              className="img-responsive"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="deposit-normal-bank-details-item mt-3">
                                                        <div className="first">
                                                          {t("BANK_ACCOUNT_NAME")}
                                                        </div>
                                                        <div className="second">
                                                          <div>
                                                            {selectedBank.label}
                                                          </div>
                                                          <div className="copy" onClick={() => copyText(selectedBank.label)}>
                                                            <img
                                                              src={'/img/v3/reload/copy.png'}
                                                              alt="i8 banks"
                                                              className="img-responsive"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="deposit-normal-bank-details-item mt-3">
                                                        <div className="first">
                                                          {selectedBank.bankDepositTypeId == BankingDepositType._BANK ? t("BANK_ACCOUNT_NUMBER") : t("EWALLET_ACCOUNT_NUMBER")}
                                                        </div>
                                                        <div className="second">
                                                          <div>
                                                            {selectedBank.accNumber}
                                                          </div>
                                                          <div className="copy" onClick={() => copyText(selectedBank.accNumber)}>
                                                            <img
                                                              src={'/img/v3/reload/copy.png'}
                                                              alt="i8 banks"
                                                              className="img-responsive"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="deposit-normal-bank-details-item mt-3">
                                                        <div className="first">
                                                          {t("MIN")}/{t("MAX")} {t("DEPOSIT")}
                                                        </div>
                                                        <div className="second font-bold">
                                                          IDR {numberWithCommas(selectedBank.min)} - IDR {numberWithCommas(selectedBank.max)}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  }
                                                </div>
                                              </div>
                                              <hr className="custom-hr" />
                                              <div className="normal-bank-per-section-box">
                                                <div className="first">
                                                  {t("AMOUNT")}
                                                </div>
                                                <div className="second ">
                                                  <div className="form-group m-b-15 px-0">
                                                    <div className="deposit-input-group mb-3">
                                                      <span class="currency-prefix">IDR</span>
                                                      {/*<input
                                                        type="text"
                                                        className="form-white-input"
                                                        placeholder={t("PLEASE_ENTER_AMOUNT")}
                                                        name="amount"
                                                        value={amountValue}
                                                        ref={register({
                                                          required: "PLEASE_ENTER_AMOUNT",
                                                          validate: {
                                                            minValue: (value) =>
                                                              (!canDisplayBank
                                                                ? value >= min
                                                                : value > 0 && value >= min) ||
                                                              "VALUE_LESS_THAN_MINIMUM",
                                                            maxValue: (value) =>
                                                              (!canDisplayBank ? true : value <= max) ||
                                                              "EXCEED_MAXIMUM_VALUE",
                                                          },
                                                        })}
                                                      />*/}
                                                      {<input
                                                        type="text"
                                                        className="form-white-input"
                                                        placeholder={t("PLEASE_ENTER_AMOUNT")}
                                                        value={numberWithCommas(amountValue)}
                                                        onKeyDown={(e) => checkValueOnKeyDown(e)}
                                                      />}
                                                      <div className="clear-amount" onClick={() => setAmountValue("")}>
                                                        <img
                                                          src={'/img/v3/reload/close.png'}
                                                          alt="i8 banks"
                                                          className="img-responsive"
                                                        />
                                                      </div>
                                                    </div>
                                                    {errors.amount && (
                                                      <div className="invalid-feedback">{t(errors.amount.message)}</div>
                                                    )}
                                                    <div className="font-11 color-white">
                                                      {parseFloat(amountValue) >= min ?
                                                        <>
                                                          <span>{t("MAIN_WALLET_NEW_BALANCE") + " : "}</span>
                                                          <span>{numberWithCurrencyFormat(parseFloat(_userData.walletBalance), 2, true) + " "}</span>
                                                          <i className="fas fa-arrow-right" />
                                                          <span>{" " + numberWithCurrencyFormat(parseFloat(_userData.walletBalance) + (amountValue > 0 ? parseFloat(amountValue / _CURRENCY_RATIO) : 0), 2, true)}</span>
                                                        </>
                                                        :
                                                        <>
                                                        </>
                                                      }
                                                    </div>
                                                    <span className="custom-color-yellow mr-2 font-11 font-bold text-nowrap">{"(1 : IDR 1,000)"}</span>
                                                  </div>
                                                  <div className="amount-row">
                                                    {fixedAmount.map((amount, index) => {
                                                      return (
                                                        <button
                                                          key={index}
                                                          type="button"
                                                          onClick={() => checkValueOnSelectFixedAmt(amount)}
                                                          className="btn custom-button"
                                                        >
                                                          + {numberWithCommas(amount)}
                                                        </button>
                                                      );
                                                    })}
                                                  </div>
                                                </div>
                                              </div>
                                              <hr className="custom-hr" />
                                              <div className="normal-bank-per-section-box">
                                                <div className="first">
                                                  {t("RECEIPT_REFERENCE")}
                                                </div>
                                                <div className="second ">
                                                  <div className="form-divider mt-4">
                                                    <div className="withdrawal-name-alert alert alert-warning mb-0">
                                                      <div className="withdrawal-warning-text">
                                                        <img className="blue-warning-icon" src={BlueWarningIcon} />
                                                        {t("DEPOSIT_RECEIPT_REFERENCE")}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <hr className="custom-hr" />
                                              <div className="normal-bank-per-section-box">
                                                <div className="first">
                                                  {t("RECEIPT")}<br />
                                                  {" (" + t("MAX_FILE_SIZE") + ": " + fileSizeLimit_InMB + "MB)"}
                                                </div>
                                                <div className="second ">
                                                  <div>
                                                    <Controller
                                                      control={control}
                                                      name="receipt"
                                                      defaultValue={""}
                                                      render={({ onChange, value }) => {
                                                        return (
                                                          <Dropzone
                                                            accept={"image/*"}
                                                            onDrop={(acceptedFiles) => imageDrop(acceptedFiles)}
                                                            maxSize={fileSizeLimit_InMB * ONE_MB_IN_BYTES}
                                                            onDropRejected={() =>
                                                              _dispatch(
                                                                showResponseMessage(
                                                                  false,
                                                                  t("FILE_LARGER_THAN") +
                                                                  fileSizeLimit_InMB +
                                                                  "MB, " +
                                                                  t("PLEASE_TRY_AGAIN")
                                                                )
                                                              )
                                                            }
                                                          >
                                                            {({ getRootProps, getInputProps }) => (
                                                              <div>
                                                                <section style={{ position: "relative" }}>
                                                                  {!stringIsNullOrEmpty(receiptImage) && (
                                                                    <div
                                                                      style={{
                                                                        background: "transparent",
                                                                        padding: "5px 10px",
                                                                        textAlign: "center",
                                                                        position: "absolute",
                                                                        right: "5px",
                                                                        top: "5px",
                                                                      }}
                                                                      onClick={() => remove()}
                                                                    >
                                                                      <i className="fa fa-trash text-red"></i>
                                                                    </div>
                                                                  )}
                                                                  <div
                                                                    className="dropzone"
                                                                    style={{ minHeight: "100px", textAlign: "center" }}
                                                                    {...getRootProps()}
                                                                  >
                                                                    <input {...getInputProps()} />
                                                                    {stringIsNullOrEmpty(receiptImage) && (
                                                                      <h4 style={{ color: "grey" }}>
                                                                        {t(
                                                                          "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                                                                        )}
                                                                      </h4>
                                                                    )}
                                                                    {!stringIsNullOrEmpty(receiptImage) && (
                                                                      <aside className="thumbsContainer">
                                                                        <div className="thumb">
                                                                          <div className="thumbInner">
                                                                            <img
                                                                              src={receiptImage}
                                                                              className="dropzone-img"
                                                                            />
                                                                          </div>
                                                                        </div>
                                                                      </aside>
                                                                    )}
                                                                  </div>
                                                                </section>
                                                                {!stringIsNullOrEmpty(receiptImage) ? (
                                                                  <div style={{ color: "#002e6c", fontSize: "15px" }}>
                                                                    {t("UPLOADED_FILE_SIZE") +
                                                                      ": " +
                                                                      numberWithCurrencyFormat(imageFileSize, 2, true) +
                                                                      "MB"}
                                                                  </div>
                                                                ) : (
                                                                  <div></div>
                                                                )}
                                                              </div>
                                                            )}
                                                          </Dropzone>
                                                        );
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          )}


                                          {bankIsSelected && (
                                            <>


                                              <div className="form-group mb-4">
                                                <label className="font-15 text-white font-semi mb-3">

                                                </label>

                                              </div>
                                              <div className="form-group mt-3 col-md-12 px-0">
                                                <button type="submit" className="custom-btn-style-1">
                                                  {t("SUBMIT")}
                                                </button>
                                              </div>
                                            </>
                                          )}
                                          {/* <div className="form-group m-b-15 px-0 mt-4 ">
                                            <label className="font-15 text-white font-semi mb-3">
                                              {t("AMOUNT")}
                                            </label>
                                            <div className="position-relative">
                                              <input
                                                type="text"
                                                className="form-white-input"
                                                placeholder={t("PLEASE_ENTER_AMOUNT")}
                                                name="amount"
                                                value={displayAmountValue}
                                                onChange={(e) => {
                                                  if (e.target.value.startsWith("IDR ")) {
                                                    let tempAmt = stringIsNullOrEmpty(e.target.value.substring(4)) ? 0 : parseFloat(e.target.value.substring(4));
                                                    setAmountValue(tempAmt);
                                                    setDisplayAmountValue(e.target.value);
                                                  }
                                                }}
                                                autoComplete="off"
                                              />
                                              <div className="clear-amount" onClick={() => { setAmountValue(0); setDisplayAmountValue("IDR 0"); }}>
                                                <img
                                                  src={require('../../assets/img/v3/reload/close.png')}
                                                  alt="i8 banks"
                                                  className="img-responsive"
                                                />
                                              </div>
                                            </div>
                                            <div className="col-md-12 mt-1">
                                              <div className="display-flex flex-align-baseline">
                                                {min != 0 && max != 0 && (
                                                  <span className="reminder text-red">
                                                    * {t("MIN")} : {min}/ {t("MAX")} : {max}
                                                  </span>
                                                )}
                                                <span className="custom-color-yellow ml-2 font-11">{"(1 : IDR " + numberWithCurrencyFormat(_CURRENCY_RATIO, 0, true) + ")"}</span>
                                              </div>
                                            </div>
                                            <div className="col-md-12 color-white">
                                              <span>{t("MAIN_WALLET_NEW_BALANCE") + " : " + numberWithCurrencyFormat(parseFloat(_userData.walletBalance) + (amountValue > 0 ? parseFloat(amountValue / _CURRENCY_RATIO) : 0), 2, true)}</span>
                                            </div>
                                          </div>

                                          <div className="amount-row">
                                            {fixedAmount.map((amount, index) => {
                                              return (
                                                <button
                                                  key={index}
                                                  type="button"
                                                  onClick={() => {
                                                    let amt = (parseFloat(amount) + parseFloat(amountValue));
                                                    setDisplayAmountValue("IDR " + amt);
                                                    setAmountValue(
                                                      amt.toFixed(2)
                                                    );
                                                  }}
                                                  className="btn custom-button"
                                                >
                                                  + {amount}
                                                </button>
                                              );
                                            })}
                                          </div>
                                          <div className="form-divider mt-4">
                                            <div className="withdrawal-name-alert alert alert-warning">
                                              <div className="withdrawal-warning-text">
                                                <img className="blue-warning-icon" src={BlueWarningIcon} />
                                                {t("DEPOSIT_RECEIPT_REFERENCE")}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="form-group mb-4">
                                            <label className="font-15 text-white font-semi mb-3">
                                              <b>{t("RECEIPT")}</b>
                                              {" (" + t("MAX_FILE_SIZE") + ": " + fileSizeLimit_InMB + "MB)"}
                                            </label>
                                            <div>
                                              <Controller
                                                control={control}
                                                name="receipt"
                                                defaultValue={""}
                                                render={({ onChange, value }) => {
                                                  return (
                                                    <Dropzone
                                                      accept={"image/*"}
                                                      onDrop={(acceptedFiles) => imageDrop(acceptedFiles)}
                                                      maxSize={fileSizeLimit_InMB * ONE_MB_IN_BYTES}
                                                      onDropRejected={() =>
                                                        _dispatch(
                                                          showResponseMessage(
                                                            false,
                                                            t("FILE_LARGER_THAN") +
                                                            fileSizeLimit_InMB +
                                                            "MB, " +
                                                            t("PLEASE_TRY_AGAIN")
                                                          )
                                                        )
                                                      }
                                                    >
                                                      {({ getRootProps, getInputProps }) => (
                                                        <div>
                                                          <section style={{ position: "relative" }}>
                                                            {!stringIsNullOrEmpty(receiptImage) && (
                                                              <div
                                                                style={{
                                                                  background: "transparent",
                                                                  padding: "5px 10px",
                                                                  textAlign: "center",
                                                                  position: "absolute",
                                                                  right: "5px",
                                                                  top: "5px",
                                                                }}
                                                                onClick={() => remove()}
                                                              >
                                                                <i className="fa fa-trash text-red"></i>
                                                              </div>
                                                            )}
                                                            <div
                                                              className="dropzone"
                                                              style={{ minHeight: "200px", textAlign: "center" }}
                                                              {...getRootProps()}
                                                            >
                                                              <input {...getInputProps()} />
                                                              {stringIsNullOrEmpty(receiptImage) && (
                                                                <h4 style={{ color: "grey" }}>
                                                                  {t(
                                                                    "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                                                                  )}
                                                                </h4>
                                                              )}
                                                              {!stringIsNullOrEmpty(receiptImage) && (
                                                                <aside className="thumbsContainer">
                                                                  <div className="thumb">
                                                                    <div className="thumbInner">
                                                                      <img
                                                                        src={receiptImage}
                                                                        className="dropzone-img"
                                                                      />
                                                                    </div>
                                                                  </div>
                                                                </aside>
                                                              )}
                                                            </div>
                                                          </section>
                                                          {!stringIsNullOrEmpty(receiptImage) ? (
                                                            <div style={{ color: "#002e6c", fontSize: "15px" }}>
                                                              {t("UPLOADED_FILE_SIZE") +
                                                                ": " +
                                                                numberWithCurrencyFormat(imageFileSize, 2, true) +
                                                                "MB"}
                                                            </div>
                                                          ) : (
                                                            <div>&nbsp;</div>
                                                          )}
                                                        </div>
                                                      )}
                                                    </Dropzone>
                                                  );
                                                }}
                                              />
                                            </div>
                                          </div>
                                          <div className="form-group mt-3 col-md-12 px-0">
                                            <button type="submit" className="custom-btn-style-1">
                                              {t("SUBMIT")}
                                            </button>
                                        </div> */}
                                        </>
                                      ))}
                                  </>
                                </div>
                              </form>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        {bankIsSelected == false && !stringIsNullOrEmpty(normalDepositContent) && (
                          <>
                            {parse(normalDepositContent)}
                          </>
                        )}

                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        fade={false}
        contentClassName="modal-brand modal-bottom modal-numpad"
        isOpen={showFullnamePopup}
        centered
      >
        <ModalBody>
          <form onSubmit={handleFullNameSubmit(submitFullName)}>
            <div className="display-wrapper text-center">
              <strong
                className="reload-title text-yellow d-block"
                style={{ fontSize: "20px" }}
              >
                {t("ENTER_YOUR_FULL_NAME")}
              </strong>
              <strong
                className="reload-title text-yellow mb-3 d-block"
                style={{ fontSize: "12px", fontStyle: "italic" }}
              >
                {t("IN_YOUR_BANK_ACCOUNT")}
              </strong>
              <input
                type="text"
                name="userFullName"
                className="withdrawal-fullName-input"
                ref={registerFullName({
                  required: "FIELD_REQUIRED",
                  pattern: {
                    value: /^[a-z A-Z]+$/,
                    message: "INVALID_NAME",
                  },
                })}
                onChange={(e) =>
                  setValueFullName("userFullName", e.target.value)
                }
              />
              {errorsFullName.userFullName && (
                <div className="invalid-feedback">
                  {t(errorsFullName.userFullName.message)}
                </div>
              )}
              <button
                type="submit"
                className="btn btn-block btn-brand-gradient mx-auto"
                style={{ width: "200px" }}
              >
                {t("SUBMIT")}
              </button>
            </div>
          </form>
        </ModalBody>
      </Modal>
      <Modal
        fade={false}
        contentClassName="modal-brand modal-bottom modal-reload-success"
        isOpen={isReloadSuccess}
        toggle={() => {
          setIsReloadSuccess(false);
        }}
        centered
      >
        <ModalBody>
          <div className="text-center">
            <strong className="reload-title text-yellow mb-3 d-block">
              {t("RELOAD_SUCCESS")}
            </strong>
            <div className="text-brand text-bo">
              {t("PAID_DEPOSIT", { amount: numberWithCommas(paidAmount) })}
            </div>
          </div>
          <table className="table text-brand table-reload-detail">
            <tr>
              <th>{t("DEPOSIT_DATE_TIME")}</th>
              <th style={{ textAlign: "right" }}>{reloadDate}</th>
            </tr>
            <tr>
              <th>{t("REFERENCE_NUMBER")}</th>
              <th style={{ textAlign: "right" }}>{transcactionNumber}</th>
            </tr>
          </table>
          <button
            className="custom-btn-style-1"
            onClick={() => {
              handleModalOnClick();
            }}
          >
            {t("DONE")}
          </button>
        </ModalBody>
      </Modal>
    </section>
  );
};

export default DepositNew;

import React, { useState, useEffect } from "react";
import GameMenu from "components/game-list/game-menu";
import Slider from "react-slick";
import { map } from "lodash";
import {
  MouseParallaxChild,
  MouseParallaxContainer,
} from "react-parallax-mouse";
import ApiEngine from "../../util/ApiEngine";
import { ApiKey, ApiUrl, SessionKey, _MAIN_WALLET_ID, AlertTypes, WebUrl } from "../../util/Constant";
import { stringIsNullOrEmpty } from "../../util/Util";
import { useDispatch } from "react-redux";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Count from "components/timer/Count";
import parse from "html-react-parser";
import { useHistory } from "react-router-dom";

const Sport = (props) => {
  const _SPORTS_CATEGORY_ID = 4;
  const _dispatch = useDispatch();
  const _history = useHistory();
  const { t, i18n } = useTranslation();


  const [sportGameCount, setSportGameCount] = useState(0);
  const [sportGames, setSportGames] = useState();
  const [popupBannerImage, setPopupBannerImage] = useState("");
  const [bonus, setBonus] = useState(0);
  const [commission, setCommission] = useState(0);
  const [clubFee, setClubFee] = useState(0);
  const [selectedGameName, setSelectedGameName] = useState("");
  const [productGameId, setProductGameId] = useState(0);
  const [masterProductId, setMasterProductId] = useState(0);
  const [walletList, setWalletList] = useState([]);
  const [fromOptions, setFromOptions] = useState([]);
  const [toOptions, setToOptions] = useState([]);
  const [packageNameKeyword, setPackageNameKeyword] = useState("");
  const [isNewWindow, setIsNewWindow] = useState(false);
  const [isApp, setIsApp] = useState(false);
  const [toLobbyBtn, setToLobbyBtn] = useState(false);
  const [appUrl, setAppUrl] = useState("");
  const [appDeepLink, setAppDeepLink] = useState("");
  const [showPregamePopup, setShowPregamePopup] = useState(false);
  const [gameWalletBalanceBeforeTransfer, setGameWalletBalanceBeforeTransfer] =
    useState(0);
  const [mpData, setMpData] = useState([]);
  const [sportsMainBanner, setSportsMainBanner] = useState("");
  const [sportsSubBannerList, setSportsSubBannerList] = useState([]);
  const { isLoggedIn } = useSelector((state) => state.authState);
  const [title, setTitle] = useState("");
  const [html, setHtml] = useState("");
  const [isSpecialRollover, setIsSpecialRollover] = useState(false);
  const [playableCategory, setPlayableCategory] = useState([]);
  const [productCategory, setProductCategory] = useState([]);
  
  // const sportSlider = {
  //   // centerMode: sportGameCount <=2 ? false : true,
  //   slidesToShow: sportGameCount,
  //   dots: false,
  //   arrows: false,
  //   infinite: true,
  //   // swipeToSlide: true,
  //   // centerPadding: "0",
  //   autoplay: false,
  //   swipe: sportGameCount <=2 ? false : true,
  //   responsive: [
  //     {
  //       breakpoint: 1200,
  //       settings: {
  //         slidesToShow: sportGameCount,
  //       },
  //     },
  //   ],
  // };

  const sportSlider = {
    slidesToShow: sportGameCount > 3 ? 3 : sportGameCount,
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: sportGameCount,
        },
      },
    ],
  };

  // Note: Temporary static url link until BO ready

  const sportGameList = [
    {
      image: "/img/sports/game1.png",
      link: "/Game/StartGame?gameId=25&isApp=0",
    },
    {
      image: "/img/sports/game2.png",
      link: "/Game/StartGame?gameId=28&isApp=0",
    },
    {
      image:"/img/sports/game3.png",
      link: "/Game/StartGame?gameId=33&isApp=0",
    },
  ];

  const fifaGroups = [
    {
      group_title: "Group A",
      group_list: [
        {
          title: "A1",
          flag: "/img/sports/a1.png",
          country: "Qatar",
        },
        {
          title: "A2",
          flag: "/img/sports/a2.png",
          country: "Ecuador",
        },
        {
          title: "A3",
          flag:"/img/sports/a3.png",
          country: "Senegal",
        },
        {
          title: "A4",
          flag: "/img/sports/a4.png",
          country: "Netherlands",
        },
      ]
    },
    {
      group_title: "Group B",
      group_list: [
        {
          title: "B1",
          flag: "/img/sports/b1.png",
          country: "England",
        },
        {
          title: "B2",
          flag: "/img/sports/b2.png",
          country: "IR Iran",
        },
        {
          title: "B3",
          flag: "/img/sports/b3.png",
          country: "USA",
        },
        {
          title: "B4",
          flag: "/img/sports/b4.png",
          country: "Wales",
        },
      ]
    },
    {
      group_title: "Group C",
      group_list: [
        {
          title: "C1",
          flag: "/img/sports/c1.png",
          country: "Argentina",
        },
        {
          title: "C2",
          flag: "/img/sports/c2.png",
          country: "Saudi Arabia",
        },
        {
          title: "C3",
          flag: "/img/sports/c3.png",
          country: "Mexico",
        },
        {
          title: "C4",
          flag: "/img/sports/c4.png",
          country: "Poland",
        },
      ]
    },
    {
      group_title: "Group D",
      group_list: [
        {
          title: "D1",
          flag: "/img/sports/d1.png",
          country: "France",
        },
        {
          title: "D2",
          flag: "/img/sports/d2.png",
          country: "Denmark",
        },
        {
          title: "D3",
          flag: "/img/sports/d3.png",
          country: "Tunisia",
        },
        {
          title: "D4",
          flag: "/img/sports/d4.png",
          country: "Australia",
        },
      ]
    },
    {
      group_title: "Group E",
      group_list: [
        {
          title: "E1",
          flag: "/img/sports/e1.png",
          country: "Spain",
        },
        {
          title: "E2",
          flag:"/img/sports/e2.png",
          country: "Germany",
        },
        {
          title: "E3",
          flag: "/img/sports/e3.png",
          country: "Japan",
        },
        {
          title: "E4",
          flag: "/img/sports/e4.png",
          country: "Costa Rica",
        },
      ]
    },
    {
      group_title: "Group F",
      group_list: [
        {
          title: "F1",
          flag: "/img/sports/f1.png",
          country: "Belgium",
        },
        {
          title: "F2",
          flag:"/img/sports/f2.png",
          country: "Canada",
        },
        {
          title: "F3",
          flag:"/img/sports/f3.png",
          country: "Morocco",
        },
        {
          title: "F4",
          flag: "/img/sports/f4.png",
          country: "Croatia",
        },
      ]
    },
    {
      group_title: "Group G",
      group_list: [
        {
          title: "G1",
          flag: "/img/sports/g1.png",
          country: "Brazil",
        },
        {
          title: "G2",
          flag: "/img/sports/g2.png",
          country: "Serbia",
        },
        {
          title: "G3",
          flag: "/img/sports/g3.png",
          country: "Switzerland",
        },
        {
          title: "G4",
          flag: "/img/sports/g4.png",
          country: "Cameroon",
        },
      ]
    },
    {
      group_title: "Group H",
      group_list: [
        {
          title: "H1",
          flag: "/img/sports/h1.png",
          country: "Portugal",
        },
        {
          title: "H2",
          flag: "/img/sports/h2.png",
          country: "Ghana",
        },
        {
          title: "H3",
          flag:"/img/sports/h3.png",
          country: "Uruguay",
        },
        {
          title: "H4",
          flag:"/img/sports/h4.png",
          country: "Korea Republic",
        },
      ]
    },
  ];
  

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      getProductCategory();
      getMemberSpecialRollover();
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (isSpecialRollover) {
      getBonusDepositProductCategory();
    }
  }, [isSpecialRollover, productCategory])

  const init = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_PRODUCT_GAME_LIST_BY_CATEGORY_ID + "?categoryName=Sports&languageCode=" + 
    (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
      localStorage.getItem(SessionKey._LANGUAGE) : 'en'));
    // localStorage.getItem(SessionKey._LANGUAGE));
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      let tempSportGames = [];
      data.filter(x => !stringIsNullOrEmpty(x['desktopImage'])).map((row, index) => {
        tempSportGames.push(row);
      });
      setSportGameCount(tempSportGames.length);
      
      ///* currently number of slides is equal to slidesToShow, need to add the same slides one more time for the sliding to work */
      //data.filter(x => !stringIsNullOrEmpty(x['desktopImage'])).map((row, index) => {
      //  tempSportGames.push(row);
      //});
      setSportGames(tempSportGames);
    }
    let apiUrl = ApiUrl._API_GET_USER_BANNER + "?isDesktop=true&languageCode=" +
      (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
        localStorage.getItem(SessionKey._LANGUAGE) : 'en') + "&page=/sports" 
        // + window.location.pathname;
    responseJson = await ApiEngine.get(apiUrl);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      let tempSliderBannerList = [];
      if (data.filter(x => x.displayDevice === 1).length > 0) {
        data.filter(x => x.displayDevice === 1).map((banner) => {
          tempSliderBannerList.push({ image: banner.desktopImage });
        });
        setSportsSubBannerList(tempSliderBannerList);
      }
      else {
          if (data.filter(x => x.displayDevice === 0).length > 0) {
              setSportsMainBanner(data.filter(x => x.displayDevice === 0)[0].desktopImage);
          }
      }
    }

    // var apiUrl2 = ApiUrl._API_GET_WEB_CONTENT + "?Name=FIFA World Cup 2022 Groups";
    // var responseJson2 = await ApiEngine.get(apiUrl2); 
    // if (responseJson2[ApiKey._API_SUCCESS_KEY]) {
    //   const name = responseJson2[ApiKey._API_DATA_KEY]["name"];
    //   const content = responseJson2[ApiKey._API_DATA_KEY]["content"];
    //   setTitle(name);
    //   setHtml(content);
    // }
  }

  async function startGame(
    gameId,
    hasOwnLobby,
    masterProductName,
    masterProductId,
    gameName,
    isSeamless,
    isApp,
    appUrl,
    appDeepLink,
    isNewWindow,
    packageNameKeyword,
    categoryTitle
  ) {
    if (isLoggedIn) {
      setSelectedGameName(gameName);
      setMasterProductId(masterProductId);
      setIsApp(isApp);
      setAppDeepLink(appDeepLink);
      setPackageNameKeyword(packageNameKeyword);
      if (isApp && !stringIsNullOrEmpty(appUrl)) {
        setAppUrl(appUrl);
      }

      if (isSpecialRollover) {
        let productCategoryListArr = [...playableCategory];

        if (hasOwnLobby !== true) {
          if (productCategoryListArr.includes(categoryTitle)) {
            if (isSeamless == false) {
              _dispatch(setBusy());
              var responseJson = await ApiEngine.get(
                ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE +
                "?masterProductId=" +
                masterProductId
              );
              if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setWalletList(responseJson[ApiKey._API_DATA_KEY]);

                let tempFromOptions = [];
                let tempToOptions = [];

                responseJson[ApiKey._API_DATA_KEY].map((product) => {
                  let option = {
                    label: product.productName,
                    value: product.productId,
                    amount: parseFloat(product.balance),
                  };
                  if (product.productId === _MAIN_WALLET_ID) {
                    tempFromOptions.push(option);
                  } else if (product.productId === masterProductId) {
                    setBonus(product.bonus);
                    setCommission(product.commission);
                    if (product.productName === "D2 Poker") {
                      setClubFee(product.clubFee);
                    }
                    setGameWalletBalanceBeforeTransfer(product.balance);
                    tempToOptions.push(option);
                  }
                });
                setFromOptions(tempFromOptions);
                setToOptions(tempToOptions);

                var mpJson = await ApiEngine(
                  ApiUrl._API_GET_MEMBER_PRODUCT_DETAILS +
                  "?masterProductId=" +
                  masterProductId
                );
                if (mpJson[ApiKey._API_SUCCESS_KEY]) {
                  let data = mpJson[ApiKey._API_DATA_KEY];
                  setMpData(data);
                  if (window.ReactNativeWebView && isApp) {
                    window.ReactNativeWebView.postMessage(
                      JSON.stringify({
                        action: "deeplink",
                        url: appUrl,
                        deeplink: appDeepLink
                          .replace("[username]", data["username"])
                          .replace("[password]", data["password"]),
                        packageKeyword: packageNameKeyword,
                      })
                    );
                  }
                }
              }
              setProductGameId(gameId);
              var isNewWindowJson = await ApiEngine(
                ApiUrl._API_GET_GAME_IS_NEW_WINDOW + "?gameId=" + gameId
              );
              setIsNewWindow(isNewWindowJson[ApiKey._API_DATA_KEY]);
              setShowPregamePopup(true);

              _dispatch(setIdle());
            } else {
              window.open(
                `${process.env.REACT_APP_ENDPOINT_URL || ""}${ApiUrl._API_START_GAME
                }?gameId=${gameId}&isApp=${isApp ? 1 : 0}&device=d`
              );
            }
          }
          else {
            _dispatch(
              showMessage({
                type: AlertTypes._INFO,
                content: t("ROLLOVER_EXIST"),
                onConfirm: () => {
                },
              })
            );
          }
        } else {
          if (isSeamless == false) {
            _dispatch(setBusy());
            var responseJson = await ApiEngine.get(
              ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE +
              "?masterProductId=" +
              masterProductId
            );
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
              setWalletList(responseJson[ApiKey._API_DATA_KEY]);

              let tempFromOptions = [];
              let tempToOptions = [];

              responseJson[ApiKey._API_DATA_KEY].map((product) => {
                let option = {
                  label: product.productName,
                  value: product.productId,
                  amount: parseFloat(product.balance),
                };
                if (product.productId === _MAIN_WALLET_ID) {
                  tempFromOptions.push(option);
                } else if (product.productId === masterProductId) {
                  setBonus(product.bonus);
                  setCommission(product.commission);
                  if (product.productName === "D2 Poker") {
                    setClubFee(product.clubFee);
                  }
                  setGameWalletBalanceBeforeTransfer(product.balance);
                  tempToOptions.push(option);
                }
              });
              setFromOptions(tempFromOptions);
              setToOptions(tempToOptions);

              var mpJson = await ApiEngine(
                ApiUrl._API_GET_MEMBER_PRODUCT_DETAILS +
                "?masterProductId=" +
                masterProductId
              );
              if (mpJson[ApiKey._API_SUCCESS_KEY]) {
                let data = mpJson[ApiKey._API_DATA_KEY];
                setMpData(data);
              }
            }

            var isNewWindowJson = await ApiEngine(
              ApiUrl._API_GET_GAME_IS_NEW_WINDOW + "?gameId=" + gameId
            );
            setIsNewWindow(isNewWindowJson[ApiKey._API_DATA_KEY]);
            setShowPregamePopup(true);
            setProductGameId(gameId);
            setShowPregamePopup(true);
            setToLobbyBtn(true);
            _dispatch(setIdle());
          }
          else {
            _history.push(WebUrl._URL_GAME_LIST.replace(
              ":provider",
              gameName));
          }
        }
      }
      else {
        if (hasOwnLobby !== true) {
          if (isSeamless == false) {
            _dispatch(setBusy());
            var responseJson = await ApiEngine.get(
              ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE +
              "?masterProductId=" +
              masterProductId
            );
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
              setWalletList(responseJson[ApiKey._API_DATA_KEY]);

              let tempFromOptions = [];
              let tempToOptions = [];

              responseJson[ApiKey._API_DATA_KEY].map((product) => {
                let option = {
                  label: product.productName,
                  value: product.productId,
                  amount: parseFloat(product.balance),
                };
                if (product.productId === _MAIN_WALLET_ID) {
                  tempFromOptions.push(option);
                } else if (product.productId === masterProductId) {
                  setBonus(product.bonus);
                  setCommission(product.commission);
                  if (product.productName === "D2 Poker") {
                    setClubFee(product.clubFee);
                  }
                  setGameWalletBalanceBeforeTransfer(product.balance);
                  tempToOptions.push(option);
                }
              });
              setFromOptions(tempFromOptions);
              setToOptions(tempToOptions);

              var mpJson = await ApiEngine(
                ApiUrl._API_GET_MEMBER_PRODUCT_DETAILS +
                "?masterProductId=" +
                masterProductId
              );
              if (mpJson[ApiKey._API_SUCCESS_KEY]) {
                let data = mpJson[ApiKey._API_DATA_KEY];
                setMpData(data);
                if (window.ReactNativeWebView && isApp) {
                  window.ReactNativeWebView.postMessage(
                    JSON.stringify({
                      action: "deeplink",
                      url: appUrl,
                      deeplink: appDeepLink
                        .replace("[username]", data["username"])
                        .replace("[password]", data["password"]),
                      packageKeyword: packageNameKeyword,
                    })
                  );
                }
              }
            }
            setProductGameId(gameId);
            var isNewWindowJson = await ApiEngine(
              ApiUrl._API_GET_GAME_IS_NEW_WINDOW + "?gameId=" + gameId
            );
            setIsNewWindow(isNewWindowJson[ApiKey._API_DATA_KEY]);
            setShowPregamePopup(true);

            _dispatch(setIdle());
          } else {
            window.open(
              `${process.env.REACT_APP_ENDPOINT_URL || ""}${ApiUrl._API_START_GAME
              }?gameId=${gameId}&isApp=${isApp ? 1 : 0}&device=d`
            );
          }
        } else {
          if (isSeamless == false) {
            _dispatch(setBusy());
            var responseJson = await ApiEngine.get(
              ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE +
              "?masterProductId=" +
              masterProductId
            );
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
              setWalletList(responseJson[ApiKey._API_DATA_KEY]);

              let tempFromOptions = [];
              let tempToOptions = [];

              responseJson[ApiKey._API_DATA_KEY].map((product) => {
                let option = {
                  label: product.productName,
                  value: product.productId,
                  amount: parseFloat(product.balance),
                };
                if (product.productId === _MAIN_WALLET_ID) {
                  tempFromOptions.push(option);
                } else if (product.productId === masterProductId) {
                  setBonus(product.bonus);
                  setCommission(product.commission);
                  if (product.productName === "D2 Poker") {
                    setClubFee(product.clubFee);
                  }
                  setGameWalletBalanceBeforeTransfer(product.balance);
                  tempToOptions.push(option);
                }
              });
              setFromOptions(tempFromOptions);
              setToOptions(tempToOptions);

              var mpJson = await ApiEngine(
                ApiUrl._API_GET_MEMBER_PRODUCT_DETAILS +
                "?masterProductId=" +
                masterProductId
              );
              if (mpJson[ApiKey._API_SUCCESS_KEY]) {
                let data = mpJson[ApiKey._API_DATA_KEY];
                setMpData(data);
              }
            }

            var isNewWindowJson = await ApiEngine(
              ApiUrl._API_GET_GAME_IS_NEW_WINDOW + "?gameId=" + gameId
            );
            setIsNewWindow(isNewWindowJson[ApiKey._API_DATA_KEY]);
            setShowPregamePopup(true);
            setProductGameId(gameId);
            setShowPregamePopup(true);
            setToLobbyBtn(true);
            _dispatch(setIdle());
          }
          else {
            _history.push(WebUrl._URL_GAME_LIST.replace(
              ":provider",
              masterProductName));
          }
        }
      }
    }
    else {
      if (hasOwnLobby !== true) {
        _dispatch(
          showMessage({
            type: AlertTypes._INFO,
            content: t("PLEASE_LOGIN_FIRST"),
            onConfirm: () => {
              console.log("i8.live");
            },
          })
        );
      }
      else {
        _history.push(WebUrl._URL_GAME_LIST.replace(
          ":provider",
          masterProductName));
      }
    }
  }

  const getMemberSpecialRollover = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_IS_SPECIAL_ROLLOVER);
    let data = "";

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      data = responseJson[ApiKey._API_DATA_KEY];
      setIsSpecialRollover(data);
    }
  }

  const getBonusDepositProductCategory = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_BONUS_DEPOSIT_PRODUCT_CATEGORY);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      let productCategoryArr = [];

      if (data.includes(",")) {
        productCategoryArr = responseJson[ApiKey._API_DATA_KEY].split(",");
      }
      else {
        productCategoryArr.push(data);
      }

      let tempArr = [];
      [...productCategory].forEach((x) => {
        if (productCategoryArr.includes(x.value)) {
          tempArr.push(x.originalTitle);
        }
      })

      setPlayableCategory(tempArr);
    }
  }

  const getProductCategory = async () => {
    let tempArr = [];
    var productCategoryJson = await ApiEngine.get(ApiUrl._API_GET_PRODUCT_CATEGORY);
    if (productCategoryJson[ApiKey._API_SUCCESS_KEY]) {
      let data = productCategoryJson[ApiKey._API_DATA_KEY];
      data.forEach(x => {
        if (x.id > 0) {
          tempArr.push({ value: x.id.toString(), label: x.title, originalTitle: x.originalTitle });
        }
      });
      setProductCategory(tempArr);
    }
  }

  return (
    <>
      <section className='category-section'>
        <GameMenu />
      </section>
      {/*<section id='sport-section' className="position-relative">
        <div className="text-center sport-timer">
          <div className="clock-title font-16 mb-2">Tournament starts in</div>
          <Count />
        </div>
        <img
          src={require("../../assets/img/sports/banner.png")}
          className='img-100'
          alt='Sports Banner'
        />
      </section>*/}
      <section id='sports-section' className="position-relative">
        <>{!stringIsNullOrEmpty(sportsMainBanner) && <img
          src={sportsMainBanner}
          className='img-100'
          alt='Sports Banner'
        />}</>
      </section>
      <section
        id='sports-section2'
        style={{
          backgroundImage: `url(${"/img/sports/background.png"})`,
        }}
      >
        <div className='container'>
          <div className='row align-items-center justify-content-center'>
            <div className="col text-center">
              <div className="sport-game-space">
                <img
                  src={"/img/sports/left.png"}
                  className='img-responsive'
                  alt='Sports Banner'
                />
              </div>
            </div>
            <div className="col-9">
              <div className="margin-negative">
                <Slider {...sportSlider}>
                  {sportGames &&
                    sportGames.length &&
                    map(sportGames, (game, index) => {
                      return (
                        <div className="hover-show-second-img sport-game-icon text-center" key={index}>
                          <a onClick={() => {
                            if (game["gameUm"] !== true) {
                              startGame(
                               game["id"],
                               game["masterProductHasOwnLobby"],
                               game["masterProductName"],
                               game["masterProductId"],
                               game["gameName"],
                               game["masterProductIsSeamless"],
                               game["isApp"],
                               game["appUrl"],
                               game["appDeepLink"],
                               game["isNewWindow"],
                               game["packageNameKeyword"],
                               "Sports"
                             );
                            }
                          }}
                          className={(isLoggedIn ? game["gameUm"] === true ? " game-um" : "" : "")}
                          >
                              <img
                                src={game["desktopImage"]}
                                className={'img-responsive show-img1'}
                                alt='Sports Banner'
                              />                              
                              <img
                                src={game["desktopImageHover"]}
                                className={'img-responsive show-img2'}
                                alt='Sports Banner'
                              />
                              {isLoggedIn && <div className="um-icon">
                                <img
                                  src={"/img/maintenance.png"}
                                  className='img-responsive'
                                  alt='poker-icon'
                                />
                              </div>}
                          </a>
                        </div>
                      );
                    })
                  }
                </Slider>
              </div>
            </div>
            
            <div className="col text-center">
              <div className="sport-game-space">
                <img
                  src={"/img/sports/right.png"}
                  className='img-responsive'
                  alt='Sports Banner'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section
        id='sports-section3'
        style={{
          backgroundImage: `url(${require("../../assets/img/sports/background3.png")})`,
        }}
      >
        <div className="text-center font-bold text-white font-18 mb-4 pb-3">
          {parse(title)}
        </div>
        <div className="container">
          <div className="row justify-content-center">
              {!stringIsNullOrEmpty(html) ?
                parse(html)
                : 
                <>
                  {fifaGroups &&
                    fifaGroups.length &&
                    fifaGroups.map((item1, index1) => {
                      return (
                        <div className="col-lg-3 col-4 mb-4" key={index1}>
                          <div
                            className="sport-group-box"
                            style={{ backgroundImage: `url(${require("../../assets/img/sports/group-bg.png")})` }}
                          >
                            <div className="font-18 sports-group-title mb-3">
                              <div>
                                <img
                                  src={require("../../assets/img/sports/title-left.png")}
                                  className='img-responsive'
                                  alt='icon'
                                />
                              </div>
                              <div>{item1.group_title}</div>
                              <div>
                                <img
                                  src={require("../../assets/img/sports/title-right.png")}
                                  className='img-responsive'
                                  alt='icon'
                                />
                              </div>
                            </div>
                            {item1.group_list.map((item2, index2) => {
                              return (
                                <div key={index2}>
                                  <div className="font-14 sport-grouplist-box" >
                                    <div className="sport-grouplist-title">
                                      {item2.title}
                                    </div>
                                    <div className="sport-grouplist-image">
                                      <img
                                        src={item2.flag}
                                        className='img-responsive'
                                        alt='icon'
                                      />
                                    </div>
                                    <div className="sport-grouplist-title">
                                      {item2.country}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })
                  }
                </>}
          </div>
        </div>
      </section> */}
    </>
  );
};
export default Sport;

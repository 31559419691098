import { fetch } from 'whatwg-fetch';
import { InputValidationKey } from './Constant';

/// <summary>
/// Author : 
/// To Check if it is empty or null string
/// </summary>
export function stringIsNullOrEmpty(data) {
    return data == null || data == '';
}

/// <summary>
/// Author : 
/// Convert array into form that use to post data
/// </summary>
export function createFormBody(params) {
    let formBody = [];

    for (let property in params) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(params[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }

    formBody = formBody.join("&");
    return formBody;
}

/// <summary>
/// Author : 
/// Convert array into form that use to post data
/// </summary>
export function createMultiPartFormBody(params) {
    var formBody = new FormData();

    for (const [key, value] of Object.entries(params)) {
        formBody.append(key, value);
    }

    return formBody;
}

/// <summary>
/// Author : 
/// Used to Navigation to certain path
/// </summary>
export function navigateTo(history, url, state) {
    if (state !== undefined) {
        history.push({
            pathname: url,
            state: state
        });
    } else {
        history.push(url);
    }
}

/// <summary>
/// Author : 
/// </summary>
export const dataTableFilterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    if (row[id] !== null && typeof row[id] === 'string') {
        return (
            row[id] !== undefined ?
            String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true
        )
    } else {
        return (
            String(row[filter.id]) === filter.value
        )
    }
}

/// <summary>
/// Author: -
/// </summary>
export function getValidationMessage(error) {
    var validationMsg = "";
    switch (error.type) {
        case InputValidationKey._REQUIRED_KEY:
            validationMsg = "INPUT_FIELD_REQUIRED";
            break;
        case InputValidationKey._MAX_LENGTH_KEY:
            validationMsg = "MAX_LENGTH_EXCEEDED";
            break;
        case InputValidationKey._MIN_LENGTH_KEY:
            validationMsg = "MIN_LENGTH_NO_MET";
            break;
        default:
            validationMsg = error.message
            break;
    }
    return validationMsg;
}

/// <summary>
/// Author: -
/// </summary>
export const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

/// <summary>
/// Author: -
/// </summary>
export function isObjectEmpty(data) {
    return Object.keys(data).length === 0;
}

/// <summary>
/// Author : 
/// </summary>
export async function imagePathToFileObject(imagePath) {
    const response = await fetch(imagePath);
    const blob = await response.blob();
    const file = new File([blob], imagePath);
    return file;
}

/// <summary>
/// Author : 
/// </summary>
export function formatNumber(data, decimalPlaces) {
    return parseFloat(data).toLocaleString(undefined, { minimumFractionDigits: decimalPlaces })
}

/// <summary>
/// Author : 
/// </summary>
export function arrayGroupByKey(array, key) {
    return array.reduce((result, currentValue) => {
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
            currentValue
        );

        return result;
    }, {});
};

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/// <summary>
/// Author : 
/// </summary>
export function numberWithCurrencyFormat(value, decimal = 2, force = false) {
    const CURRENCY_DECIMAL = 2;
    var convertedNumber = parseFloat((Math.round((value + Number.EPSILON) * Math.pow(10, decimal))) / Math.pow(10, decimal)).toFixed(decimal).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,');

    if (decimal > CURRENCY_DECIMAL) {
        let afterDecimalNumber = convertedNumber.split('.');
        let decimalDiff = decimal - CURRENCY_DECIMAL;
        if (afterDecimalNumber.length == 2 && convertedNumber.lastIndexOf('0') == (convertedNumber.length - 1)) {
            let processNumber = afterDecimalNumber[1];
            let beginNumber = !force ? processNumber.length - 1 : processNumber;
            for (let i = beginNumber; (i > 0 && decimalDiff > 0); i--) {
                if (processNumber.charAt(i) == '0') {
                    processNumber = processNumber.substr(0, i) + processNumber.substr(i + 1);
                } else {
                    break;
                }
                decimalDiff--;
            }
            convertedNumber = afterDecimalNumber[0] + "." + processNumber;
        }
    }

    return convertedNumber
}

export function numberWithCurrencyFormat2(value, decimal = 2, force = false) {
  const isNegativeValue = value < 0;
  var valueStr = value.toString();
  if (!valueStr.includes('.') && force) {
    valueStr += '.';
  }
  var finalValueStr = valueStr;
  if (valueStr.includes('.') || force) {
    let decimalPlace = valueStr.substring(
      valueStr.indexOf('.') + 1,
      valueStr.length
    ).length;
    if (decimalPlace < decimal) {
      while (decimalPlace != decimal) {
        finalValueStr += '0';
        decimalPlace++;
      }
    }
    finalValueStr =
      finalValueStr.substring(0, finalValueStr.indexOf('.')) +
      finalValueStr.substring(
        finalValueStr.indexOf('.'),
        finalValueStr.indexOf('.') + 1 + decimal
      );
  }
  let finalWholeNumber = finalValueStr.substring(isNegativeValue ? 1 : 0, finalValueStr.indexOf('.'));
  if (finalWholeNumber.length > 3) {
    let wholeNumberStr = '';
    let strListToAppend = [];
    let Last3Num = finalWholeNumber.substr(-3, 3);
    let NumBeforeLast3Num = finalWholeNumber.substring(
      0,
      finalWholeNumber.lastIndexOf(Last3Num)
    );
    strListToAppend.push(Last3Num);
    while (Last3Num.length == 3) {
      Last3Num = NumBeforeLast3Num.substr(-3, 3);
      if (!stringIsNullOrEmpty(Last3Num)) {
        strListToAppend.unshift(Last3Num);
      }
      NumBeforeLast3Num = NumBeforeLast3Num.substring(
        0,
        NumBeforeLast3Num.lastIndexOf(Last3Num)
      );
    }
    wholeNumberStr += strListToAppend[0];
    strListToAppend.map((digits, index) => {
      if (index > 0) {
        wholeNumberStr += ',' + digits;
      }
    });
    finalWholeNumber = wholeNumberStr;
  }
  finalValueStr =
    (isNegativeValue ? "-" : "") +
    finalWholeNumber +
    finalValueStr.substring(
      finalValueStr.indexOf('.'),
      finalValueStr.indexOf('.') + 1 + decimal
    );
  return finalValueStr;
}

/// <summary>
/// Author : 
/// </summary>
export function ignoreDecimal(e) {
    const _KEYCODE_DECIMAL = 190;
    const _KEYCODE_E = 69;
    let keyCode = e.keyCode;

    if (keyCode == _KEYCODE_DECIMAL || keyCode == _KEYCODE_E) {
        e.preventDefault();
    }
}

export function formatPercent(v) {
    return `${v}%`
}

export function abbrNum(number, decPlaces) {
  decPlaces = Math.pow(10, decPlaces);

  // Enumerate number abbreviations
  var abbrev = ["k", "m", "b", "t"];

  for (var i = abbrev.length - 1; i >= 0; i--) {

    var size = Math.pow(10, (i + 1) * 3);

    if (size <= number) {
      number = Math.round(number * decPlaces / size) / decPlaces;

      number += abbrev[i];

      break;
    }
  }

  return number;
}
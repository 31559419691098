import React, { useState, useEffect, useContext } from "react";
import Background_1 from "../assets/img/home/register_background.png";
import {
  Alert,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import { stringIsNullOrEmpty, createFormBody } from "../util/Util";
import { useHistory } from "react-router-dom";
import {
  ApiKey,
  ApiUrl,
  WebUrl,
  Role,
  PaymentType,
  Status,
  AlertTypes,
  Language,
  LanguageOption,
  LanguageSelectStyle,
  SessionKey,
  PhoneNumberPrefix,
  KEY
} from "../util/Constant";
import ApiEngine from "../util/ApiEngine";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  setBusy,
  setIdle,
  showMessage,
  showResponseMessage,
} from "../redux/AppAction";
import { useTranslation } from "react-i18next";
import Input from "../components/custom/Input";
import { useForm, Controller } from "react-hook-form";
import { performLogin, performRegisterLogin, createGamePointsBonusRecord } from "../redux/AuthAction.js";
import CryptoJS from "crypto-js";


const RegisterNew = (props) => {
  const _MIN_PASSWORD_LENGTH = 6;

  const _TAC_VALID_TIMER = 60000;
  const _SECONDS_IN_MS = 1000;
  const _TAC_TIMEOUT_IN_MINUTES = 1;
  const { t, i18n } = useTranslation();
  let _history = useHistory();
  const [registerSteps, setRegisterSteps] = useState(2);
  const [isOpen, setIsOpen] = useState(false);
  const [referralCode, setReferralCode] = useState("");
  const [uplineDetail, setUplineDetail] = useState({});
  const _dispatch = useDispatch();
  const [_fullname, _setFullname] = useState("");
  const [_username, _setUsername] = useState("");
  const [selectedPhonePrefix, setSelectedPhonePrefix] = useState(PhoneNumberPrefix[0].value);
  const [formattedPhone, setFormattedPhone] = useState("");
  const [countdownPeriod, setCountdownPeriod] = useState(0);
  const [TACtimedOut, setTACtimedOut] = useState(true);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [TACsent, setTACsent] = useState(false);
  const [showTacEntry, setShowTacEntry] = useState(false);
  const [TACBtnAble, setTACBtnAble] = useState(false);
  const [TACtimer, setTACtimer] = useState(setTimeout(() => { }, 100000));
  const [TACcountdownPeriod, setTACCountdownPeriod] = useState(0);
  const [formattedTac, setFormattedTac] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [agreedTnc, setAgreedTnC] = useState(false);
  const [agreedLegal, setAgreedLegal] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [registerPointsEarnedModal, setRegisterPointsEarnedModal] = useState(false);
  const [registerGamePoints, setRegisterGamePoints] = useState(0);
  const [depositGamePoints, setDepositGamePoints] = useState(0);
  const [registerUsername, setRegisterUsername] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [registerWhatsAppUrl, setRegisterWhatsAppUrl] = useState("");
  const _APPEND_REGISTER_WHATSAPP_URL = "?text=register%20";
  const [registerTelegramUrl, setRegisterTelegramUrl] = useState("");
  const {
    register,
    handleSubmit,
    errors,
    clearErrors,
    setError,
    setValue,
    getValues,
    watch,
    control,
    trigger,
    phoneNumberChk,
  } = useForm({
    mode: "onBlur",
  });
  const selectedLanguageId = useSelector(
    (state) => state.languageState.languageId
  );
  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const receivedRreferralCode = params.get("code");
    logSession(receivedRreferralCode);
    if (!stringIsNullOrEmpty(receivedRreferralCode)) {
      setReferralCode(receivedRreferralCode);
      getUpline(receivedRreferralCode);
    } else {
      _history.replace(WebUrl._URL_MAIN);
    }
    getRegisterPoints();
    getDepositPoints();
  }, []);

  async function logSession(code) {
    let params = {
      referralCode: code,
    };
    //let logJson = await ApiEngine.post(
    //  ApiUrl._API_ADD_OPERATION_LOG,
    //  createFormBody(params)
    //);
    //if (logJson[ApiKey._API_SUCCESS_KEY]) {
    //  console.log("done");
    //}
  }

  async function getUpline(code) {
    let responseJson = await ApiEngine.get(
      ApiUrl._API_GET_UPLINE_BY_REFERRAL_CODE + "?code=" + code
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setUplineDetail(responseJson[ApiKey._API_DATA_KEY]);
      let apiUrl1 = ApiUrl._API_GET_WHATSAPP_PHONE + "?type=ChatSpherePhoneNumber";
      let settingJson = await ApiEngine.get(apiUrl1);

      let apiUrl2 = ApiUrl._API_GET_TELEGRAM_LINK;
      let telegramJson = await ApiEngine.get(apiUrl2);

      if (settingJson[ApiKey._API_SUCCESS_KEY]) {
        let data1 = settingJson[ApiKey._API_DATA_KEY];
        setRegisterWhatsAppUrl("https://wa.me/" + data1 + _APPEND_REGISTER_WHATSAPP_URL + code);
      }

      if (telegramJson[ApiKey._API_SUCCESS_KEY]) {
        let data2 = telegramJson[ApiKey._API_DATA_KEY];
        setRegisterTelegramUrl(data2 + code);
      }
    } else {
      _dispatch(
        showMessage({
          type: AlertTypes._ERROR,
          content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
          onConfirm: () => _history.replace(WebUrl._URL_MAIN),
        })
      );
    }
  }

  async function checkUsername(username) {
    let avail = false;
    if (stringIsNullOrEmpty(username)) {
      avail = false;
    }
    else {
      let responseJson = await ApiEngine.get(
        ApiUrl._API_CHECK_USERNAME +
        "?username=" +
        username +
        "&code=" +
        referralCode
      );
      avail = responseJson[ApiKey._API_SUCCESS_KEY];
    }
    return avail;
  }

  const validateUsername = (e) => {
    var regexp = /^[\S]*^[^ \[\]`~\/!@#$%^&*()"':;?|{}=+_<>,.\\-]*$/g;
    if (e.nativeEvent.data === null || regexp.test(e.nativeEvent.data)) {
      _setUsername(e.target.value);
    }
  };

  function checkNumber(e) {
    //const regex = /^[0-9\b]+$/;
    //const key = String.fromCharCode(e.keyCode || e.charCode);
    let tempPhoneNum = formattedPhone;

    if (((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) && !e.shiftKey) { //keyCode of 0 to 9, numpad 0 to 9
      tempPhoneNum = formattedPhone + "" + e.key;
      setFormattedPhone(tempPhoneNum);
    }
    else if (e.keyCode == 8 || e.keyCode == 46) //keyCode of backSpace and Del
    {
      tempPhoneNum = formattedPhone.slice(0, -1);
      setFormattedPhone(tempPhoneNum);
    }
    //console.log(tempPhoneNum);

    //if (regex.test(key)) {
    //  // key pressed is a number
    //  console.log("IsNumber");
    //} else {
    //  // key pressed is not a number
    //  console.log("IsNotNumber");
    //  e.preventDefault();
    //}
  }

  useEffect(() => {
    if (countdownPeriod > 0) {
      setTimeout(() => {
        let tempPeriod = countdownPeriod - 1;
        setCountdownPeriod(tempPeriod);
      }, _SECONDS_IN_MS);
    }
  }, [countdownPeriod]);

  async function sendTACCode() {
    //if (
    //  (TACtimedOut || countdownPeriod > 0) &&
    //  typeof formattedPhone !== "undefined" &&
    //  (selectedPhonePrefix + formattedPhone).length >= 10
    //) {
    //  if (!errors.phoneNumber) {
    //    let params = {
    //      phoneNumber: selectedPhonePrefix + formattedPhone,
    //      referralCode: referralCode,
    //      languageCode: localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
    //        localStorage.getItem(SessionKey._LANGUAGE) : 'en',
    //      countdown: countdownPeriod,
    //      TACExist: !TACtimedOut,
    //    };
    //    let responseJson = await ApiEngine.post(
    //      ApiUrl._API_SEND_TAC_CODE,
    //      createFormBody(params)
    //    );
    //    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
    //      setPhoneNumberError(false);
    //      setTACsent(true);
    //      setCountdownPeriod(_TAC_VALID_TIMER / _SECONDS_IN_MS);
    //      setShowTacEntry(true);
    //      setTACBtnAble(false);
    //      setTimeout(() => {
    //        setTACBtnAble(true);
    //        setTACtimedOut(true);
    //      }, _TAC_VALID_TIMER);

    const isValid = await trigger(); // Trigger validation manually
    if (isValid) {
      setRegisterSteps(4); // If validation passes, submit the form
    }
    //clearErrors();
    //if (stringIsNullOrEmpty(_username)) {
    //  setError("username", {
    //    message: "FIELD_REQUIRED"
    //  });
    //}
    //else if (Object.keys(errors).length > 0) {
    //  //do nothing
    //}
    //else {
    //  setRegisterSteps(4);
    //}
    //    } else {
    //      _dispatch(showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY]));
    //    }
    //  }
    //} else {
    //  setError("registerPhone", { message: "PHONE_NUMBER_INVALID" });
    //}
    //clearTimeout(TACtimer);
    //setTACCountdownPeriod((_TAC_VALID_TIMER / _SECONDS_IN_MS));
  }

  const verifyTAC = async () => {
    _dispatch(setBusy());
    let TACparams = {
      "TAC": formattedTac,
      "phoneNumber": selectedPhonePrefix + formattedPhone,
      "referralCode": referralCode
    };
    let TACjson = await ApiEngine.post(ApiUrl._API_VERIFY_TAC_CODE, createFormBody(TACparams));
    if (TACjson[ApiKey._API_SUCCESS_KEY]) {
      setRegisterSteps(4);
    }
    else {
      _dispatch(showResponseMessage(false, TACjson[ApiKey._API_MESSAGE_KEY]));
    }
    _dispatch(setIdle());
  }

  async function submitForm() {
    _dispatch(setBusy());
    let params = {
      "userFullName": _fullname,
      "username": _username,
      "password": password,
      "phoneNumber": selectedPhonePrefix + formattedPhone,
      "referralCode": referralCode,
      "isMemberOperate": true,
      "languageSetting": selectedLanguageId
    };
    let formBody = createFormBody(params);
    let encryptedFormBody = encryptData(formBody, KEY);
    const dataToSend = {
      es: encryptedFormBody,
    };
    await fetch(ApiUrl._API_CREATE_OR_UPDATE_MEMBER_2, {
      method: ApiKey._API_POST,
      headers: {
        'Content-Type': ApiKey._API_APPLICATION_JSON,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
      },
      body: JSON.stringify(dataToSend)
    }).then(response => response.json()).then(responseJson => {
      _dispatch(
        showMessage({
          type: responseJson[ApiKey._API_SUCCESS_KEY]
            ? AlertTypes._SUCCESS
            : AlertTypes._ERROR,
          content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
          onConfirm: async () => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
              setTACBtnAble(false);
              clearTimeout();
              setRegisterUsername(_username);
              setRegisterPassword(password);
              setRegisterPointsEarnedModal(true);
            } else {
              _dispatch(setIdle());
            }
          },
        })
      );
    })
    _dispatch(setIdle());
  }

  const encryptData = (data, passphrase, iv) => {
    const encryptedData = CryptoJS.AES.encrypt(data, passphrase, { mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7, keySize: 256 / 32 }).toString();

    return encryptedData;
  }

  const goToDeposit = async (username, password) => {
    setRegisterPointsEarnedModal(false);
    _dispatch(setBusy());
    await _dispatch(performRegisterLogin(username, password));
    await _dispatch(createGamePointsBonusRecord());
    _dispatch(setIdle());
    _history.push(WebUrl._URL_DEPOSIT)
  }

  const goToHome = async (username, password) => {
    setRegisterPointsEarnedModal(false);
    _dispatch(setBusy());
    await _dispatch(performRegisterLogin(username, password));
    await _dispatch(createGamePointsBonusRecord());
    _dispatch(setIdle());
    _history.push(WebUrl._URL_MAIN)
  }

  const getRegisterPoints = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_REGISTER_GAME_POINTS);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setRegisterGamePoints(responseJson[ApiKey._API_DATA_KEY]);
    }
  }

  const getDepositPoints = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_DEPOSIT_GAME_POINTS);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setDepositGamePoints(responseJson[ApiKey._API_DATA_KEY]);
    }
  }

  return (
    <>
      <div id="register" style={{ backgroundImage: `url(${Background_1})` }}>
        <section id="register-section">
          <div className="container">
            <div className="row">
              <div className="col-12">
                {Object.keys(uplineDetail).length > 0 && <p className="mb-1 font14 text-white">
                  <b>{uplineDetail?.username ?? ""}</b> {t("HAS_INVITED_YOU")}
                </p>}
                <h3 className="register-page-header">
                  {t("REGISTER_OWN_ACCOUNT")}
                </h3>

                {registerSteps == 2 && (
                  <>
                    <div className="mt-5 pt-4 register-form-box">
                      <div className="whatsapp-telegram-wrapper">
                        <div className="whatsapp-btn-div " onClick={() => window.open(registerWhatsAppUrl)}>
                          <div className="whatsapp-btn">
                            <div>
                              {t("REGISTER_WITH_WHATSAPP")}
                            </div>
                            <div>
                              <img src={require("../assets/img/v3/ri_whatsapp-line.svg")} alt="back" className="img-responsive" />
                            </div>
                          </div>
                        </div>
                        <div className="whatsapp-btn-div " onClick={() => window.open(registerTelegramUrl)}>
                          <div className="whatsapp-btn">
                            <div>
                              {t("REGISTER_WITH_TELEGRAM")}
                            </div>
                            <div>
                              <img src={require("../assets/img/v3/register_telegram.svg")} alt="back" className="img-responsive" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="whatsapp-or-div mt-3 mb-3"><span>OR</span></div>
                      <br />
                      <div className="register-steps-container text-center mb-5">
                        <div className="steps-img">
                          <img src={"/img/v3/steps/Blue_1.svg"} alt="steps" className="img-responsive" />
                          <div className="color-mainBlue font12 mt-2">{t("REGISTER")}</div>
                        </div>
                        {/*<><div className="step-separator-gray"></div>
                        <div className="steps-img">
                          <img src={require("../assets/img/v3/steps/Grey_2.svg")} alt="steps" className="img-responsive" />
                          <div className="color-gray2 font12 mt-2">{t("VERIFY")}</div>
                        </div></>*/}
                        <div className="step-separator-gray"></div>
                        <div className="steps-img">
                          <img src={"/img/v3/steps/Grey_2.svg"} alt="steps" className="img-responsive" />
                          <div className="color-gray2 font12 mt-2">{t("PASSWORD")}</div>
                        </div>
                      </div>
                      <div className="custom-input-group">
                        <Input
                          id="fullname"
                          name="fullname"
                          ref={register({
                            required: "FIELD_REQUIRED",
                            pattern: {
                              value: /^[a-zA-Z]+[a-zA-Z ]*$/i,
                              message: "PLEASE_REMOVE_NUMBERS_AND_SPECIAL_CHARACTERS"
                            },
                          })}
                          value={_fullname}
                          errors={errors}
                          placeholder={t("PLACEHOLDER_FULLNAME")}
                          onChange={(e) => _setFullname(e.target.value)}
                        />
                        <div className="left-img">
                          <img
                            src={"/img/v3/username.png"}
                            className='img-responsive'
                            alt='register'
                          />
                        </div>
                      </div>
                      <div className="custom-input-group mt-4">
                        <Input
                          id="username"
                          name="username"
                          ref={register({
                            required: true,
                            validate: async (value) =>
                              (await checkUsername(value)) ||
                              (console.log("test"), "USERNAME_TAKEN"),
                            pattern: {
                              value: /^[a-zA-Z0-9]*$/i,
                              message: "Please remove spaces and special characters.",
                            },
                            minLength: {
                              value: 4,
                              message: t("MIN_CHARACTERS", { length: 4 }),
                            },
                          })}
                          autoFocus
                          value={_username}
                          onInput={(e) => validateUsername(e)}
                          errors={errors}
                          placeholder={t("USERNAME")}
                        />
                        <div className="left-img">
                          <img
                            src={"/img/v3/username.png"}
                            className='img-responsive'
                            alt='register'
                          />
                        </div>
                      </div>
                      <div className="custom-phone-group mt-4">
                        <select
                          onChange={(e) => setSelectedPhonePrefix(e.target.value)}>
                          {PhoneNumberPrefix.map((prefix, index) => {
                            return (
                              <option
                                key={index}
                                className={"phone-prefix-" + (index + 1)}
                                value={prefix.value}
                              >
                                {prefix.label}
                              </option>
                            );
                          })}
                        </select>
                        <Input
                          id="phoneNumber"
                          name="phoneNumber"
                          ref={register({
                            // required: true,
                            required: "FIELD_REQUIRED",
                            minLength: {
                              value: 8,
                              message: t("MIN_CHARACTERS", { length: 8 }),
                            },
                            pattern: {
                              value: /^[0-9]+$/g,
                              message:
                                t("MIN_CHARACTERS", { length: 8 }) +
                                " " +
                                t("AND") +
                                " " +
                                t("ONLY_NUMBERS"),
                            },
                          })}
                          style={{ color: '#FFFFFF' }}
                          value={formattedPhone}
                          errors={errors}
                          placeholder={t("EG") + " 9464224288"}
                          onKeyDown={(e) => checkNumber(e)}
                        />
                      </div>
                      <div className="mt-4">
                        <button className="btn custom-blue-btn" onClick={() => sendTACCode()}>{t("CONTINUE")}</button>
                      </div>
                      {/*<div className="or-box mt-5 mb-5">
                                                <div className="or-line"></div>
                                                <div>{t("OR")}</div>
                                                <div className="or-line"></div>
                                            </div>*/}
                      {/*<div className="line-box">
                                                <button style={{ width: "100%" }} type="button" className="btn line-btn">
                                                    <div>Register with Line</div>
                                                    <img src={require("../assets/img/v3/steps/line.png")} alt="username" className="img-responsive" />
                                                </button>
                                            </div>*/}
                    </div>
                  </>
                )}

                {/*registerSteps == 3 && (
                  <>
                    <div className="mt-5 pt-4 register-form-box">
                      <div className="register-steps-container text-center mb-5">
                        <div className="steps-img">
                          <img src={require("../assets/img/v3/steps/Blue_1.svg")} alt="steps" className="img-responsive" />
                          <div className="color-mainBlue font12 mt-2">{t("REGISTER")}</div>
                        </div>
                        <div className="step-separator-blue"></div>
                        <div className="steps-img">
                          <img src={require("../assets/img/v3/steps/Blue_2.svg")} alt="steps" className="img-responsive" />
                          <div className="color-mainBlue font12 mt-2">{t("VERIFY")}</div>
                        </div>
                        <div className="step-separator-gray"></div>
                        <div className="steps-img">
                          <img src={require("../assets/img/v3/steps/Grey_3.svg")} alt="steps" className="img-responsive" />
                          <div className="color-gray2 font12 mt-2">{t("PASSWORD")}</div>
                        </div>
                      </div>
                      <div className="custom-input-group normal">
                        <Input
                          name="tacInput"
                          ref={register({
                            minLength: {
                              value: 6,
                              message: t("MIN_CHARACTERS", { length: 6 })
                            },
                            maxLength: {
                              value: 6,
                              message: t("MAX_CHARACTERS", { length: 6 })
                            }
                          })}
                          errors={errors}
                          placeholder={t("TAC")}
                          onChange={(e) => setFormattedTac(e.target.value)}
                        />

                      </div>
                      <div className="register-mini-text mt-2">
                        {TACsent && countdownPeriod != 0 ? (
                          <div>
                            {t("TAC_SENT")}  {t("VALID_FOR")}  {countdownPeriod + " " + t("SECOND")}
                          </div>
                        ) : (
                          <div className="resend-tac" onClick={() => sendTACCode()}>
                            {t("RESEND_TAC_CODE")}
                          </div>
                        )}

                      </div>

                      <div className="mt-5">
                        <button disabled={formattedTac.length != 6} className="btn custom-blue-btn" onClick={() => verifyTAC()}>{t("CONFIRM")}</button>
                      </div>
                    </div>
                  </>
                )*/}
                {registerSteps == 4 && (
                  <>
                    <div className="mt-5 pt-4 register-form-box">
                      <div className="register-steps-container text-center mb-5">
                        <div className="steps-img">
                          <img src={"/img/v3/steps/Blue_1.svg"} alt="steps" className="img-responsive" />
                          <div className="color-mainBlue font12 mt-2">{t("REGISTER")}</div>
                        </div>
                        {/*<><div className="step-separator-blue"></div>
                        <div className="steps-img">
                          <img src={require("../assets/img/v3/steps/Blue_2.svg")} alt="steps" className="img-responsive" />
                          <div className="color-mainBlue font12 mt-2">{t("VERIFY")}</div>
                        </div></>*/}
                        <div className="step-separator-blue"></div>
                        <div className="steps-img">
                          <img src={"/img/v3/steps/Blue_2.svg"} alt="steps" className="img-responsive" />
                          <div className="color-mainBlue font12 mt-2">{t("PASSWORD")}</div>
                        </div>
                      </div>
                      <div className="custom-input-group">
                        <Input name="password"
                          type={passwordVisible ? "text" : "password"}
                          ref={register({
                            required: true,
                            minLength: {
                              value: _MIN_PASSWORD_LENGTH,
                              message: t("MIN_CHARACTERS", { length: _MIN_PASSWORD_LENGTH })
                            },
                            validate: value => (watch("confirmPassword") == '' || value == watch("confirmPassword")) || "PASSWORD_MISMATCH"
                          })}
                          errors={errors}
                          placeholder={t("PASSWORD")}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <div className="left-img">
                          <img
                            src={"/img/v3/key.png"}
                            className='img-responsive'
                            alt='register'
                          />
                        </div>
                        <div className="right-img" onClick={() => setPasswordVisible(!passwordVisible)}>
                          <img
                            src={passwordVisible ? "/img/v3/unhide.png" : "/img/v3/hide.png"}
                            className='img-responsive'
                            alt='register'
                          />
                        </div>
                      </div>
                      <div className="custom-input-group mt-4">
                        <Input name="confirmPassword"
                          type={confirmPasswordVisible ? "text" : "password"}
                          ref={register({
                            required: true,
                            minLength: {
                              value: _MIN_PASSWORD_LENGTH,
                              message: t("MIN_CHARACTERS", { length: _MIN_PASSWORD_LENGTH })
                            },
                            validate: value => (watch("password") == '' || value == watch("password")) || "PASSWORD_MISMATCH"
                          })}
                          errors={errors}
                          placeholder={t("CONFIRM_PASSWORD")}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <div className="left-img">
                          <img
                            src={"/img/v3/lock.png"}
                            className='img-responsive'
                            alt='register'
                          />
                        </div>
                        <div className="right-img" onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}>
                          <img
                            src={confirmPasswordVisible ? "/img/v3/unhide.png" : "/img/v3/hide.png"}
                            className='img-responsive'
                            alt='register'
                          />
                        </div>
                      </div>
                      <label className="custom-checkbox mt-4">
                        <input
                          type="checkbox"
                          className="form-checkbox-label"
                          onChange={(e) => setAgreedTnC(!agreedTnc)}
                        />
                        <span className="checkmark"></span>
                        <div className="register-mini-text">
                          {t("I_AGREE_TO")} {t("TNC_i8")} <a href={WebUrl._URL_TERMS} rel="noreferrer" target="_blank" >{t("TNC")}</a>
                        </div>
                      </label>
                      <label className="custom-checkbox mt-3">
                        <input
                          type="checkbox"
                          className="form-checkbox-label"
                          onChange={(e) => setAgreedLegal(!agreedLegal)}
                        />
                        <span className="checkmark"></span>
                        <div className="register-mini-text">
                          {t("I_AGREE_LEGAL_AGE_18")}
                        </div>
                      </label>

                      <div className="mt-5" >
                        <button className="btn custom-blue-btn" disabled={(password != confirmPassword) || !agreedLegal || !agreedTnc} onClick={() => submitForm()}>{t("REGISTER")}</button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <Modal
            id="success-modal"
            fade={true}
            isOpen={registerPointsEarnedModal}
            toggle={() => {
              setRegisterPointsEarnedModal(false);
            }}
            centered
          >
            <ModalBody>
              <div className="modal-success-container">
                <img src={"/img/v3/successful.png"} alt="waving" className="img-responsive" />
                <h4 className="modal-success-title mt-4">
                  {t("CONGRATULATIONS")}
                </h4>
                <p className="modal-success-details mb-0">{t("EARNED_GAME_POINTS", { points: registerGamePoints })} <br />{t("DEPOSIT_NOW_FOR_POINTS", { points: depositGamePoints })}</p>
                <div className="btn-container">
                  <a className="btn custom-btn mt-4" onClick={() => goToDeposit(registerUsername, registerPassword)}>
                    {t("DEPOSIT_NOW")}
                  </a>
                  <a className="btn custom-btn mt-4" onClick={() => goToHome(registerUsername, registerPassword)}>
                    {t("GO_TO_HOME")}
                  </a>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </section>
      </div>

    </>
  );
};

export default RegisterNew;
import React, { useEffect } from "react";
//import "react-simple-keyboard/build/css/index.css";
import Sidebar from "components/sidebar";
import { map } from "lodash";
import { WebUrl, _MAIN_WALLET_ID } from "../../util/Constant";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import MenuRowItem from "components/menu-row/item";
import ContentHeader from "components/content/header";
import { useTranslation } from "react-i18next";

const Report = (props) => {
  const { t } = useTranslation();
  const _history = useHistory();
  var { isLoggedIn } = useSelector((state) => state.authState);

  useEffect(() => {
    if (!isLoggedIn) {
      _history.replace(WebUrl._URL_MAIN);
    }

    return () => {
      //window.removeEventListener("scroll");
    };
  }, []);

  const reportList = [
    {
      text: "WALLET_STATEMENT",
      image: "/img/icon/WALLET_STATEMENT.svg",
      link: WebUrl._URL_WALLET_STATEMENT,
    },
    //{
    //  text: "BONUS_STATEMENT",
    //  image: require("../../assets/img/icon/BONUS_REPORT.svg"),
    //  link: WebUrl._URL_BONUS_REPORT,
    //},
    {
      text: "DEPOSIT_BONUS_HISTORY",
      image: "/img/icon/BONUS_REPORT.svg",
      link: WebUrl._URL_DEPOSIT_BONUS_HISTORY,
    },
    {
      text: "ROLLOVER_STATEMENT",
      image: "/img/icon/ROLLOVER_STATEMENT.svg",
      link: WebUrl._URL_ROLLOVER_STATEMENT,
    },
    {
      text: "GAME_RECORD",
      image:"/img/icon/GAME_RECORD.svg",
      link: WebUrl._URL_GAME_RECORD,
    },
  ];

  return (
    <>
      <section className="section-padding" id="main-report-section">
        <div className="container">
          <div className="row">
            <div className="col-3" id="account-menu">
              <Sidebar activePage={"REPORTS"} />
            </div>
            <div className="col-9">
              <ContentHeader title={t("REPORTS")} />
              <hr className="right-title-hr" />
              {reportList &&
                reportList.length &&
                map(reportList, (item, index) => {
                  return (
                      <MenuRowItem
                        key={"report_list_" + index}
                        item={item}
                        iconCustomClass="report-list-icon"
                      />
                   
                  );
                })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Report;
